import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  SelectGroupModuleList,
  SelectGroupScreensList,
} from "../../Services/SecurityAPI";
import ExpenseReport_Modal from "../Reports/ExpenseReports/ExpenseReport_Modal";
import FinanceReport_Modal from "../Reports/FinanceReports/FinanceReport_Modal";
import InventoryReport_Modal from "../Reports/InventoryReports/InventoryReport_Modal";
import PLReports_Modal from "../Reports/PL/PLReports_Modal";
import PurchaseReport_Modal from "../Reports/PurchaseReports/PurchaseReport_Modal";
import SaleReport_Modal from "../Reports/SaleReports/SaleReport_Modal";
import DistributionReport_Modal from "../Reports/DistributionReports/DistributionReport_Modal";
import DSRFiles from "../Reports/DSRReports/DSRFiles";
import TaxReportsModal from "../Reports/TaxReports/TaxReportsModal";
import ComparisionReports_Modal from "../Reports/ComparisionReports/ComparisionReports_Modal";
import { SelectDataForBackup } from "../../Services/AreasAPI";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import JSZipUtils from "jszip-utils";
import { GetCurrentDate } from "../../Services/Common";
import Loader from "../Loader";
import JSZip from "jszip";
import { set_ClaimPolicyType } from "../../React_Redux/Actions";
const zip = new JSZip();
function Sidebar({ Group_Id, Comp_Id, ProjectID, set_ClaimPolicyType, User_Info }) {
  const [IsLoader, setIsLoader] = useState(false);
  const [ShowSaleReport_modal, setShowSaleReport_modal] = useState(false);
  const [DSRFilesModal, setDSRFilesModal] = useState(false);
  const [ShowTaxReport_modal, setShowTaxReport_modal] = useState(false);
  const [ShowPurchaseReport_modal, setShowPurchaseReport_modal] =
    useState(false);
  const [ShowComparisionReport_modal, setShowComparisionReport_modal] =
    useState(false);
  const [ShowInventoryReport_modal, setShowInventoryReport_modal] =
    useState(false);
  const [ShowPLReport_modal, setShowPLReport_modal] = useState(false);
  const [ShowExpenseReport_modal, setShowExpenseReport_modal] = useState(false);
  const [ShowFinanceReport_modal, setShowFinanceReport_modal] = useState(false);
  const [ShowDistributionReport_modal, setShowDistributionReport_modal] =
    useState(false);
  var [ReportPath, setReportPath] = useState("#");
  const [ReportTitle, setReportTitle] = useState("");
  const [Tr_Type, setTr_Type] = useState(null);

  var [SaleReport_ReadOnlyProp, setSaleReport_ReadOnlyProp] = useState({
    FromDate: false,
    ToDate: false,
    finYear: false,
    Project: false,
    Customer: false,
    ToCustomer: false,
    ToItem: false,
    FromItem: false,
    NoOfItems: false,
    Supplier: false,
    WareHouse: false,
    Classification: false,
    E_type: false,
    Bank: false,
    User: false,
  });
  var [DistributionReportReadOnlyProp, setDistributionReportReadOnlyProp] =
    useState({
      FromDate: false,
      ToDate: false,
      finYear: false,
      Project: false,
      ManufacturedBy: false,
      FromItem: false,
      City: false,
      Area: false,
      BatchNo: false,
      Customer: false,
      Delivery: true,
      CGID: true,
      SaleInvNoFrom: true,
      SaleInvNoTo: true,
      SaleRate: true,

      ToCustomer: false,
      ToItem: false,
      NoOfItems: false,
      Supplier: false,
      WareHouse: false,
      Classification: false,
      E_type: false,
      Bank: false,
      User: false,
    });
  var [TaxReport_ReadOnlyProp, setTaxReport_ReadOnlyProp] = useState({
    FromDate: false,
    ToDate: false,
    Project: false,
    Export: false,
  });
  // Modules
  const [Control_Head, setControl_Head] = useState(false);
  const [DASHBOARD, setDASHBOARD] = useState(false);
  const [SECURITY, setSECURITY] = useState(false);
  const [RECORDS, setRECORDS] = useState(false);
  const [EXPENSE_MODULE, setEXPENSE_MODULE] = useState(false);
  const [SALE_MODULE, setSALE_MODULE] = useState(false);
  const [PURCHASE_MODULE, setPURCHASE_MODULE] = useState(false);
  const [DELIVERY_MODULE, setDELIVERY_MODULE] = useState(false);
  const [BOOKING_MODULE, setBOOKING_MODULE] = useState(false);
  const [SALE_REPORT, setSALE_REPORT] = useState(false);
  const [PURCHASE_REPORT, setPURCHASE_REPORT] = useState(false);
  const [DISTRIBUTION_REPORT, setDISTRIBUTION_REPORT] = useState(false);
  const [INVENTORY_REPORTS, setINVENTORY_REPORTS] = useState(false);
  const [EXPENSE_REPORTS, setEXPENSE_REPORTS] = useState(false);
  const [FINANCE_REPORTS, setFINANCE_REPORTS] = useState(false);
  const [PROFITANDLOSS_REPORTS, setPROFITANDLOSS_REPORTS] = useState(false);
  const [MAIN_FORM, setMAIN_FORM] = useState(false);
  const [Reports, setReports] = useState(false);
  const [CLAIMS_MODULE, SETCLAIMS_MODULE] = useState(false);
  const [DSR_MODULE, setDSR_MODULE] = useState(false);
  const [BACKUP_MODULE, setBACKUP_MODULE] = useState(false);
  //USER SCURITY
  const [USERS, setUSERS] = useState(false);
  const [USER_SECURITY, setUSER_SECURITY] = useState(false);
  const [CONTROL_PANEL, setCONTROL_PANEL] = useState(false);

  // DASHBOARD
  const [Admin_Dashboard, setAdmin_Dashboard] = useState(false);

  // RECORDS
  const [Unit_Of_Measurement, setUnit_Of_Measurement] = useState(false);
  const [Item_Main_Type, setItem_Main_Type] = useState(false);
  const [Item_Sub_Type, setItem_Sub_Type] = useState(false);
  const [WareHouse, setWareHouse] = useState(false);
  const [DISTRICTS, setDISTRICTS] = useState(false);
  const [AREAS, setAREAS] = useState(false);
  const [City, setCity] = useState(false);
  const [Menufacture_By, setMenufacture_By] = useState(false);
  const [Bank, setBank] = useState(false);
  const [COMPANYGROUP, setCOMPANYGROUP] = useState(false);
  const [PRODUCTGROUP, setPRODUCTGROUP] = useState(false);
  const [
    ASSIGNNING_GRPS_TO_MARKTNG_STAFF,
    setASSIGNNING_GRPS_TO_MARKTNG_STAFF,
  ] = useState(false);
  const [SCHEME, setSCHEME] = useState(false);
  const [DRIVER, setDRIVER] = useState(false);
  const [VEHICLE, setVEHICLE] = useState(false);
  const [MEDIUM, setMEDIUM] = useState(false);
  const [GROUPS, setGROUPS] = useState(false);
  const [REASONS, setREASONS] = useState(false);
  const [DISCOUNTPOLICYFORCG, setDISCOUNTPOLICYFORCG] = useState(false);
  // EXPENSES
  const [Expense_Classification, setExpense_Classification] = useState(false);
  const [Expense_Type, setExpense_Type] = useState(false);
  const [Expense, setExpense] = useState(false);

  // MAIN FORMS
  const [Items, setItems] = useState(false);
  const [Supplier, setSupplier] = useState(false);
  const [Customers, setCustomers] = useState(false);
  const [Opening, setOpening] = useState(false);
  const [Stock_Adjustment, setStock_Adjustment] = useState(false);
  const [Receipt, setReceipt] = useState(false);
  const [Payment, setPayment] = useState(false);
  const [Stock_Transfer, setStock_Transfer] = useState(false);
  const [AUTOMAILER, setAUTOMAILER] = useState(false);
  // PURCHASE MODULE
  const [Purchase_Order, setPurchase_Order] = useState(false);
  const [Purchase_Invoice, setPurchase_Invoice] = useState(false);
  const [Purchase_Return, setPurchase_Return] = useState(false);
  const [Pending_PO, setPending_PO] = useState(false);
  // SALE MODULE
  const [Sale_Order, setSale_Order] = useState(false);
  const [Sale_Invoice, setSale_Invoice] = useState(false);
  const [Sale_Return, setSale_Return] = useState(false);

  // DELIVERY MODULE
  const [DELIVERY, setDELIVERY] = useState(false);

  // BOOKING MODULE
  const [ORDERS, setORDERS] = useState(false);
  const [ORDERBOOKING, setORDERBOOKING] = useState(false);
  // CLAIMS
  const [BONUSCLAIMS, setBONUSCLAIMS] = useState(false);
  const [DISCOUNTCLAIMS, setDISCOUNTCLAIMS] = useState(false);
  const [OPENCLAIM, setOPENCLAIM] = useState(false);
  const [CLAIMADJUSTMENT, setCLAIMADJUSTMENT] = useState(false);
  //DSR Files
  const [DSR_Files, setDSR_Files] = useState(false);
  //Backup
  const [Backup, setBackup] = useState(false);
  // SALE REPORTS
  const [CUSTOMER_LEDGER, setCUSTOMER_LEDGER] = useState(false);
  const [SALE_SUMMARY_CUSTOMER_WISE, setSALE_SUMMARY_CUSTOMER_WISE] =
    useState(false);
  const [SALE_SUMMARY_SAELMAN_WISE, setSALE_SUMMARY_SAELMAN_WISE] =
    useState(false);
  const [SALE_SUMMARY_ITEMS_WISE, setSALE_SUMMARY_ITEMS_WISE] = useState(false);
  const [
    SALE_RETURN_LEDGER_CUSTOMER_WISE,
    setSALE_RETURN_LEDGER_CUSTOMER_WISE,
  ] = useState(false);
  const [SALE_MARGIN_CUSTOMER_WISE, setSALE_MARGIN_CUSTOMER_WISE] =
    useState(false);
  const [SALE_MARGIN_SUPPLIER_WISE, setSALE_MARGIN_SUPPLIER_WISE] =
    useState(false);
  const [MOST_SOLD_ITEMS, setMOST_SOLD_ITEMS] = useState(false);
  const [MINIMUM_SOLD_ITEMS, setMINIMUM_SOLD_ITEMS] = useState(false);
  const [DAILY_SALES_OF_LAST_MONTH, setDAILY_SALES_OF_LAST_MONTH] =
    useState(false);
  const [RECEIVABLES, setRECEIVABLES] = useState(false);

  // PURCHASE REPORTS
  const [SUPPLIER_LEDGER, setSUPPLIER_LEDGER] = useState(false);
  const [PURCHASE_SUMMARY_CATEGORY_WISE, setPURCHASE_SUMMARY_CATEGORY_WISE] =
    useState(false);
  const [PURCHASE_LEDGER_SUPPLIER_WISE, setPURCHASE_LEDGER_SUPPLIER_WISE] =
    useState(false);
  const [PURCHASE_LEDGER_ITEMS_WISE, setPURCHASE_LEDGER_ITEMS_WISE] =
    useState(false);
  const [PAYABLES, setPAYABLES] = useState(false);

  // INVENTORY REPORTS
  const [STOCK_LEDGER, setSTOCK_LEDGER] = useState(false);
  const [MAX_INVENTORY_ITEMS, setMAX_INVENTORY_ITEMS] = useState(false);
  const [SHORT_INVENTORY_ITEMS, setSHORT_INVENTORY_ITEMS] = useState(false);
  const [STOCK_BALANCE_BATCH_WISE, setSTOCK_BALANCE_BATCH_WISE] =
    useState(false);
  const [STOCK_BALANCE_WAREHOUSE_WISE, setSTOCK_BALANCE_WAREHOUSE_WISE] =
    useState(false);

  // FINANCE REPORTS
  const [CASH_IN_BANK, setCASH_IN_BANK] = useState(false);
  const [CHASH_IN_HAND, setCHASH_IN_HAND] = useState(false);

  // EXPENSE REPORTS
  const [EXPENSES_TYPE_WISE, setEXPENSES_TYPE_WISE] = useState(false);
  const [EXPENSES_CLASSIFICATION_WISE, setEXPENSES_CLASSIFICATION_WISE] =
    useState(false);
  const [EXPENSES_REPORT, setEXPENSES_REPORT] = useState(false);

  // P & L REPORTS
  const [PROFIT_AND_LOSS, setPROFIT_AND_LOSS] = useState(false);
  const [DAILY_ACTIVITIES, setDAILY_ACTIVITIES] = useState(false);

  // DISTRIBUTION REPORTS
  const [CURRENT_STOCK, setCURRENT_STOCK] = useState(false);
  const [BATCH_WISE_SALE, setBATCH_WISE_SALE] = useState(false);
  const [NEAR_EXPIRY_STOCK, setNEAR_EXPIRY_STOCK] = useState(false);
  const [PRODUCT_PURCHASE_LEDGER, setPRODUCT_PURCHASE_LEDGER] = useState(false);
  const [PRODUCT_SALE_LEDGER, setPRODUCT_SALE_LEDGER] = useState(false);
  const [
    PRODUCT_SALE_LEDGER_INVOICE_WISE,
    setPRODUCT_SALE_LEDGER_INVOICE_WISE,
  ] = useState(false);
  const [SALE_SUMMARY, setSALE_SUMMARY] = useState(false);
  const [ACCOUNT_LEDGER, setACCOUNT_LEDGER] = useState(false);
  const [TOWN_WISE_SALE, setTOWN_WISE_SALE] = useState(false);
  const [RECOVERY_ANALYSIS, setRECOVERY_ANALYSIS] = useState(false);
  const [SALE_REGISTER, setSALE_REGISTER] = useState(false);
  const [CUSTOMER_WISE_SALE_REGISTER, setCUSTOMER_WISE_SALE_REGISTER] =
    useState(false);
  const [SALE_AND_STOCK_STATEMENT, setSALE_AND_STOCK_STATEMENT] =
    useState(false);
  const [SALE_RETURN_REGISTER, setSALE_RETURN_REGISTER] = useState(false);
  const [AREAWISESALE, setAREAWISESALE] = useState(false);
  const [MONTHWISESALE, setMONTHWISESALE] = useState(false);
  const [GROUPWISESALE, setGROUPWISESALE] = useState(false);
  const Show_SaleReport_Modal = () => {
    setShowSaleReport_modal(true);
  };
  const Hide_SaleReport_Modal = () => {
    setShowSaleReport_modal(false);
  };
  const Show_TaxReport_Modal = () => {
    setShowTaxReport_modal(true);
  };
  const Hide_TaxReport_Modal = () => {
    setShowTaxReport_modal(false);
  };
  const show_Modal = () => {
    setDSRFilesModal(true);
  };

  const hide_Modal = () => {
    setDSRFilesModal(false);
  };
  const Show_ComparisonReport_Modal = () => {
    setShowComparisionReport_modal(true);
  };
  const Hide_ComparisonReport_Modal = () => {
    setShowComparisionReport_modal(false);
  };
  const Show_PurchaseReport_Modal = () => {
    setShowPurchaseReport_modal(true);
  };
  const Hide_PurchaseReport_Modal = () => {
    setShowPurchaseReport_modal(false);
  };
  const Show_InventoryReport_Modal = () => {
    setShowInventoryReport_modal(true);
  };
  const Hide_InventoryReport_Modal = () => {
    setShowInventoryReport_modal(false);
  };
  const Show_PLReport_Modal = () => {
    setShowPLReport_modal(true);
  };
  const Hide_PLReport_Modal = () => {
    setShowPLReport_modal(false);
  };
  const Show_ExpenseReport_Modal = () => {
    setShowExpenseReport_modal(true);
  };
  const Hide_ExpenseReport_Modal = () => {
    setShowExpenseReport_modal(false);
  };
  const Show_FinanceReport_Modal = () => {
    setShowFinanceReport_modal(true);
  };
  const Hide_FinanceReport_Modal = () => {
    setShowFinanceReport_modal(false);
  };
  const Show_DistributionReport_Modal = () => {
    setShowDistributionReport_modal(true);
  };
  const Hide_DistributionReport_Modal = () => {
    setShowDistributionReport_modal(false);
  };
  const Excel_Converstion = (mData) => {
    const ws = XLSX.utils.json_to_sheet(mData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    return data;
  };
  useEffect(() => {
    if (Group_Id != null && Group_Id != undefined) {
      SelectGroupModuleList(Group_Id, (GM_Data) => {
        GM_Data.map(async (MValue, MIndex) => {
          if (MValue.Mod_Id === 1) {
            if (MValue.Allow_Access === 1) {
              setDASHBOARD(true);
            } else {
              setDASHBOARD(false);
            }
          } else if (MValue.Mod_Id === 2) {
            if (MValue.Allow_Access === 1) {
              setSECURITY(true);
            } else {
              setSECURITY(false);
            }
          } else if (MValue.Mod_Id === 13) {
            if (MValue.Allow_Access === 1) {
              setRECORDS(true);
            } else {
              setRECORDS(false);
            }
          } else if (MValue.Mod_Id === 11) {
            if (MValue.Allow_Access === 1) {
              setMAIN_FORM(true);
            } else {
              setMAIN_FORM(false);
            }
          } else if (MValue.Mod_Id === 15) {
            if (MValue.Allow_Access === 1) {
              setPURCHASE_MODULE(true);
            } else {
              setPURCHASE_MODULE(false);
            }
          } else if (MValue.Mod_Id === 14) {
            if (MValue.Allow_Access === 1) {
              setEXPENSE_MODULE(true);
            } else {
              setEXPENSE_MODULE(false);
            }
          } else if (MValue.Mod_Id === 16) {
            if (MValue.Allow_Access === 1) {
              setSALE_MODULE(true);
            } else {
              setSALE_MODULE(false);
            }
          } else if (MValue.Mod_Id === 17) {
            if (MValue.Allow_Access === 1) {
              setDELIVERY_MODULE(true);
            } else {
              setDELIVERY_MODULE(false);
            }
          } else if (MValue.Mod_Id === 18) {
            if (MValue.Allow_Access === 1) {
              setBOOKING_MODULE(true);
            } else {
              setBOOKING_MODULE(false);
            }
          } else if (MValue.Mod_Id === 19) {
            if (MValue.Allow_Access === 1) {
              setSALE_REPORT(true);
            } else {
              setSALE_REPORT(false);
            }
          } else if (MValue.Mod_Id === 20) {
            if (MValue.Allow_Access === 1) {
              setPURCHASE_REPORT(true);
            } else {
              setPURCHASE_REPORT(false);
            }
          } else if (MValue.Mod_Id === 21) {
            if (MValue.Allow_Access === 1) {
              setDISTRIBUTION_REPORT(true);
            } else {
              setDISTRIBUTION_REPORT(false);
            }
          } else if (MValue.Mod_Id === 23) {
            if (MValue.Allow_Access === 1) {
              setINVENTORY_REPORTS(true);
            } else {
              setINVENTORY_REPORTS(false);
            }
          } else if (MValue.Mod_Id === 24) {
            if (MValue.Allow_Access === 1) {
              setEXPENSE_REPORTS(true);
            } else {
              setEXPENSE_REPORTS(false);
            }
          } else if (MValue.Mod_Id === 25) {
            if (MValue.Allow_Access === 1) {
              setFINANCE_REPORTS(true);
            } else {
              setFINANCE_REPORTS(false);
            }
          } else if (MValue.Mod_Id === 26) {
            if (MValue.Allow_Access === 1) {
              setPROFITANDLOSS_REPORTS(true);
            } else {
              setPROFITANDLOSS_REPORTS(false);
            }
          } else if (MValue.Mod_Id === 27) {
            if (MValue.Allow_Access === 1) {
              SETCLAIMS_MODULE(true);
            } else {
              SETCLAIMS_MODULE(false);
            }
          } else if (MValue.Mod_Id === 28) {
            if (MValue.Allow_Access === 1) {
              setDSR_MODULE(true);
            } else {
              setDSR_MODULE(false);
            }
          } else if (MValue.Mod_Id === 29) {
            if (MValue.Allow_Access === 1) {
              setBACKUP_MODULE(true);
            } else {
              setBACKUP_MODULE(false);
            }
          }

          await new Promise((resolve) => {
            SelectGroupScreensList(Group_Id, MValue.Mod_Id, (ScreenData) => {
              ScreenData.map((SValue, SIndex) => {
                if (MValue.Mod_Id === 2) {
                  if (SValue.Screen_Id === 6) {
                    if (SValue.Allow_Access === 1) {
                      setUSER_SECURITY(true);
                    } else {
                      setUSER_SECURITY(false);
                    }
                  } else if (SValue.Screen_Id === 7) {
                    if (SValue.Allow_Access === 1) {
                      setUSERS(true);
                    } else {
                      setUSERS(false);
                    }
                  } else if (SValue.Screen_Id === 133) {
                    if (SValue.Allow_Access === 1) {
                      setCONTROL_PANEL(true);
                    } else {
                      setCONTROL_PANEL(false);
                    }
                  }
                }
                if (MValue.Mod_Id === 1) {
                  if (SValue.Screen_Id === 1) {
                    if (SValue.Allow_Access === 1) {
                      setAdmin_Dashboard(true);
                    } else {
                      setAdmin_Dashboard(false);
                    }
                  }
                } else if (MValue.Mod_Id === 13) {
                  if (SValue.Screen_Id === 81) {
                    if (SValue.Allow_Access === 1) {
                      setUnit_Of_Measurement(true);
                    } else {
                      setUnit_Of_Measurement(false);
                    }
                  } else if (SValue.Screen_Id === 82) {
                    if (SValue.Allow_Access === 1) {
                      setItem_Main_Type(true);
                    } else {
                      setItem_Main_Type(false);
                    }
                  } else if (SValue.Screen_Id === 83) {
                    if (SValue.Allow_Access === 1) {
                      setItem_Sub_Type(true);
                    } else {
                      setItem_Sub_Type(false);
                    }
                  } else if (SValue.Screen_Id === 84) {
                    if (SValue.Allow_Access === 1) {
                      setWareHouse(true);
                    } else {
                      setWareHouse(false);
                    }
                  } else if (SValue.Screen_Id === 85) {
                    if (SValue.Allow_Access === 1) {
                      setCity(true);
                    } else {
                      setCity(false);
                    }
                  } else if (SValue.Screen_Id === 86) {
                    if (SValue.Allow_Access === 1) {
                      setMenufacture_By(true);
                    } else {
                      setMenufacture_By(false);
                    }
                  } else if (SValue.Screen_Id === 87) {
                    if (SValue.Allow_Access === 1) {
                      setBank(true);
                    } else {
                      setBank(false);
                    }
                  } else if (SValue.Screen_Id === 134) {
                    if (SValue.Allow_Access === 1) {
                      setDISTRICTS(true);
                    } else {
                      setDISTRICTS(false);
                    }
                  } else if (SValue.Screen_Id === 135) {
                    if (SValue.Allow_Access === 1) {
                      setAREAS(true);
                    } else {
                      setAREAS(false);
                    }
                  } else if (SValue.Screen_Id === 136) {
                    if (SValue.Allow_Access === 1) {
                      setCOMPANYGROUP(true);
                    } else {
                      setCOMPANYGROUP(false);
                    }
                  } else if (SValue.Screen_Id === 137) {
                    if (SValue.Allow_Access === 1) {
                      setPRODUCTGROUP(true);
                    } else {
                      setPRODUCTGROUP(false);
                    }
                  } else if (SValue.Screen_Id === 138) {
                    if (SValue.Allow_Access === 1) {
                      setASSIGNNING_GRPS_TO_MARKTNG_STAFF(true);
                    } else {
                      setASSIGNNING_GRPS_TO_MARKTNG_STAFF(false);
                    }
                  } else if (SValue.Screen_Id === 139) {
                    if (SValue.Allow_Access === 1) {
                      setSCHEME(true);
                    } else {
                      setSCHEME(false);
                    }
                  } else if (SValue.Screen_Id === 140) {
                    if (SValue.Allow_Access === 1) {
                      setDRIVER(true);
                    } else {
                      setDRIVER(false);
                    }
                  } else if (SValue.Screen_Id === 141) {
                    if (SValue.Allow_Access === 1) {
                      setVEHICLE(true);
                    } else {
                      setVEHICLE(false);
                    }
                  } else if (SValue.Screen_Id === 158) {
                    if (SValue.Allow_Access === 1) {
                      setMEDIUM(true);
                    } else {
                      setMEDIUM(false);
                    }
                  } else if (SValue.Screen_Id === 159) {
                    if (SValue.Allow_Access === 1) {
                      setGROUPS(true);
                    } else {
                      setGROUPS(false);
                    }
                  } else if (SValue.Screen_Id === 161) {
                    if (SValue.Allow_Access === 1) {
                      setREASONS(true);
                    } else {
                      setREASONS(false);
                    }
                  } else if (SValue.Screen_Id === 162) {
                    if (SValue.Allow_Access === 1) {
                      setDISCOUNTPOLICYFORCG(true);
                    } else {
                      setDISCOUNTPOLICYFORCG(false);
                    }
                  }
                } else if (MValue.Mod_Id === 14) {
                  if (SValue.Screen_Id === 88) {
                    if (SValue.Allow_Access === 1) {
                      setExpense_Classification(true);
                    } else {
                      setExpense_Classification(false);
                    }
                  } else if (SValue.Screen_Id === 89) {
                    if (SValue.Allow_Access === 1) {
                      setExpense_Type(true);
                    } else {
                      setExpense_Type(false);
                    }
                  } else if (SValue.Screen_Id === 90) {
                    if (SValue.Allow_Access === 1) {
                      setExpense(true);
                    } else {
                      setExpense(false);
                    }
                  }
                } else if (MValue.Mod_Id === 11) {
                  if (SValue.Screen_Id === 91) {
                    if (SValue.Allow_Access === 1) {
                      setItems(true);
                    } else {
                      setItems(false);
                    }
                  } else if (SValue.Screen_Id === 92) {
                    if (SValue.Allow_Access === 1) {
                      setSupplier(true);
                    } else {
                      setSupplier(false);
                    }
                  } else if (SValue.Screen_Id === 93) {
                    if (SValue.Allow_Access === 1) {
                      setCustomers(true);
                    } else {
                      setCustomers(false);
                    }
                  } else if (SValue.Screen_Id === 94) {
                    if (SValue.Allow_Access === 1) {
                      setOpening(true);
                    } else {
                      setOpening(false);
                    }
                  } else if (SValue.Screen_Id === 95) {
                    if (SValue.Allow_Access === 1) {
                      setStock_Adjustment(true);
                    } else {
                      setStock_Adjustment(false);
                    }
                  } else if (SValue.Screen_Id === 96) {
                    if (SValue.Allow_Access === 1) {
                      setReceipt(true);
                    } else {
                      setReceipt(false);
                    }
                  } else if (SValue.Screen_Id === 97) {
                    if (SValue.Allow_Access === 1) {
                      setPayment(true);
                    } else {
                      setPayment(false);
                    }
                  } else if (SValue.Screen_Id === 98) {
                    if (SValue.Allow_Access === 1) {
                      setStock_Transfer(true);
                    } else {
                      setStock_Transfer(false);
                    }
                  } else if (SValue.Screen_Id === 160) {
                    if (SValue.Allow_Access === 1) {
                      setAUTOMAILER(true);
                    } else {
                      setAUTOMAILER(false);
                    }
                  }
                } else if (MValue.Mod_Id === 15) {
                  if (SValue.Screen_Id === 99) {
                    if (SValue.Allow_Access === 1) {
                      setPurchase_Order(true);
                    } else {
                      setPurchase_Order(false);
                    }
                  } else if (SValue.Screen_Id === 100) {
                    if (SValue.Allow_Access === 1) {
                      setPurchase_Invoice(true);
                    } else {
                      setPurchase_Invoice(false);
                    }
                  } else if (SValue.Screen_Id === 101) {
                    if (SValue.Allow_Access === 1) {
                      setPurchase_Return(true);
                    } else {
                      setPurchase_Return(false);
                    }
                  } else if (SValue.Screen_Id === 163) {
                    if (SValue.Allow_Access === 1) {
                      setPending_PO(true);
                    } else {
                      setPending_PO(false);
                    }
                  }
                } else if (MValue.Mod_Id === 16) {
                  if (SValue.Screen_Id === 102) {
                    if (SValue.Allow_Access === 1) {
                      setSale_Order(true);
                    } else {
                      setSale_Order(false);
                    }
                  } else if (SValue.Screen_Id === 103) {
                    if (SValue.Allow_Access === 1) {
                      setSale_Invoice(true);
                    } else {
                      setSale_Invoice(false);
                    }
                  } else if (SValue.Screen_Id === 104) {
                    if (SValue.Allow_Access === 1) {
                      setSale_Return(true);
                    } else {
                      setSale_Return(false);
                    }
                  }
                } else if (MValue.Mod_Id === 17) {
                  if (SValue.Screen_Id === 142) {
                    if (SValue.Allow_Access === 1) {
                      setDELIVERY(true);
                    } else {
                      setDELIVERY(false);
                    }
                  }
                } else if (MValue.Mod_Id === 18) {
                  if (SValue.Screen_Id === 143) {
                    if (SValue.Allow_Access === 1) {
                      setORDERS(true);
                    } else {
                      setORDERS(false);
                    }
                  } else if (SValue.Screen_Id === 144) {
                    if (SValue.Allow_Access === 1) {
                      setORDERBOOKING(true);
                    } else {
                      setORDERBOOKING(false);
                    }
                  }
                } else if (MValue.Mod_Id === 19) {
                  if (SValue.Screen_Id === 105) {
                    if (SValue.Allow_Access === 1) {
                      setCUSTOMER_LEDGER(true);
                    } else {
                      setCUSTOMER_LEDGER(false);
                    }
                  } else if (SValue.Screen_Id === 106) {
                    if (SValue.Allow_Access === 1) {
                      setSALE_SUMMARY_CUSTOMER_WISE(true);
                    } else {
                      setSALE_SUMMARY_CUSTOMER_WISE(false);
                    }
                  } else if (SValue.Screen_Id === 107) {
                    if (SValue.Allow_Access === 1) {
                      setSALE_SUMMARY_SAELMAN_WISE(true);
                    } else {
                      setSALE_SUMMARY_SAELMAN_WISE(false);
                    }
                  } else if (SValue.Screen_Id === 108) {
                    if (SValue.Allow_Access === 1) {
                      setSALE_SUMMARY_ITEMS_WISE(true);
                    } else {
                      setSALE_SUMMARY_ITEMS_WISE(false);
                    }
                  } else if (SValue.Screen_Id === 109) {
                    if (SValue.Allow_Access === 1) {
                      setSALE_RETURN_LEDGER_CUSTOMER_WISE(true);
                    } else {
                      setSALE_RETURN_LEDGER_CUSTOMER_WISE(false);
                    }
                  } else if (SValue.Screen_Id === 110) {
                    if (SValue.Allow_Access === 1) {
                      setSALE_MARGIN_CUSTOMER_WISE(true);
                    } else {
                      setSALE_MARGIN_CUSTOMER_WISE(false);
                    }
                  } else if (SValue.Screen_Id === 111) {
                    if (SValue.Allow_Access === 1) {
                      setSALE_MARGIN_SUPPLIER_WISE(true);
                    } else {
                      setSALE_MARGIN_SUPPLIER_WISE(false);
                    }
                  } else if (SValue.Screen_Id === 112) {
                    if (SValue.Allow_Access === 1) {
                      setMOST_SOLD_ITEMS(true);
                    } else {
                      setMOST_SOLD_ITEMS(false);
                    }
                  } else if (SValue.Screen_Id === 113) {
                    if (SValue.Allow_Access === 1) {
                      setMINIMUM_SOLD_ITEMS(true);
                    } else {
                      setMINIMUM_SOLD_ITEMS(false);
                    }
                  } else if (SValue.Screen_Id === 114) {
                    if (SValue.Allow_Access === 1) {
                      setDAILY_SALES_OF_LAST_MONTH(true);
                    } else {
                      setDAILY_SALES_OF_LAST_MONTH(false);
                    }
                  } else if (SValue.Screen_Id === 115) {
                    if (SValue.Allow_Access === 1) {
                      setRECEIVABLES(true);
                    } else {
                      setRECEIVABLES(false);
                    }
                  }
                } else if (MValue.Mod_Id === 20) {
                  if (SValue.Screen_Id === 116) {
                    if (SValue.Allow_Access === 1) {
                      setSUPPLIER_LEDGER(true);
                    } else {
                      setSUPPLIER_LEDGER(false);
                    }
                  } else if (SValue.Screen_Id === 117) {
                    if (SValue.Allow_Access === 1) {
                      setPURCHASE_SUMMARY_CATEGORY_WISE(true);
                    } else {
                      setPURCHASE_SUMMARY_CATEGORY_WISE(false);
                    }
                  } else if (SValue.Screen_Id === 118) {
                    if (SValue.Allow_Access === 1) {
                      setPURCHASE_LEDGER_SUPPLIER_WISE(true);
                    } else {
                      setPURCHASE_LEDGER_SUPPLIER_WISE(false);
                    }
                  } else if (SValue.Screen_Id === 119) {
                    if (SValue.Allow_Access === 1) {
                      setPURCHASE_LEDGER_ITEMS_WISE(true);
                    } else {
                      setPURCHASE_LEDGER_ITEMS_WISE(false);
                    }
                  } else if (SValue.Screen_Id === 120) {
                    if (SValue.Allow_Access === 1) {
                      setPAYABLES(true);
                    } else {
                      setPAYABLES(false);
                    }
                  }
                } else if (MValue.Mod_Id === 21) {
                  if (SValue.Screen_Id === 145) {
                    if (SValue.Allow_Access === 1) {
                      setCURRENT_STOCK(true);
                    } else {
                      setCURRENT_STOCK(false);
                    }
                  } else if (SValue.Screen_Id === 146) {
                    if (SValue.Allow_Access === 1) {
                      setBATCH_WISE_SALE(true);
                    } else {
                      setBATCH_WISE_SALE(false);
                    }
                  } else if (SValue.Screen_Id === 147) {
                    if (SValue.Allow_Access === 1) {
                      setNEAR_EXPIRY_STOCK(true);
                    } else {
                      setNEAR_EXPIRY_STOCK(false);
                    }
                  } else if (SValue.Screen_Id === 148) {
                    if (SValue.Allow_Access === 1) {
                      setPRODUCT_PURCHASE_LEDGER(true);
                    } else {
                      setPRODUCT_PURCHASE_LEDGER(false);
                    }
                  } else if (SValue.Screen_Id === 149) {
                    if (SValue.Allow_Access === 1) {
                      setPRODUCT_SALE_LEDGER_INVOICE_WISE(true);
                    } else {
                      setPRODUCT_SALE_LEDGER_INVOICE_WISE(false);
                    }
                  } else if (SValue.Screen_Id === 150) {
                    if (SValue.Allow_Access === 1) {
                      setSALE_SUMMARY(true);
                    } else {
                      setSALE_SUMMARY(false);
                    }
                  } else if (SValue.Screen_Id === 151) {
                    if (SValue.Allow_Access === 1) {
                      setACCOUNT_LEDGER(true);
                    } else {
                      setACCOUNT_LEDGER(false);
                    }
                  } else if (SValue.Screen_Id === 152) {
                    if (SValue.Allow_Access === 1) {
                      setTOWN_WISE_SALE(true);
                    } else {
                      setTOWN_WISE_SALE(false);
                    }
                  } else if (SValue.Screen_Id === 153) {
                    if (SValue.Allow_Access === 1) {
                      setRECOVERY_ANALYSIS(true);
                    } else {
                      setRECOVERY_ANALYSIS(false);
                    }
                  } else if (SValue.Screen_Id === 154) {
                    if (SValue.Allow_Access === 1) {
                      setSALE_REGISTER(true);
                    } else {
                      setSALE_REGISTER(false);
                    }
                  } else if (SValue.Screen_Id === 155) {
                    if (SValue.Allow_Access === 1) {
                      setCUSTOMER_WISE_SALE_REGISTER(true);
                    } else {
                      setCUSTOMER_WISE_SALE_REGISTER(false);
                    }
                  } else if (SValue.Screen_Id === 156) {
                    if (SValue.Allow_Access === 1) {
                      setSALE_AND_STOCK_STATEMENT(true);
                    } else {
                      setSALE_AND_STOCK_STATEMENT(false);
                    }
                  } else if (SValue.Screen_Id === 157) {
                    if (SValue.Allow_Access === 1) {
                      setSALE_RETURN_REGISTER(true);
                    } else {
                      setSALE_RETURN_REGISTER(false);
                    }
                  } else if (SValue.Screen_Id === 170) {
                    if (SValue.Allow_Access === 1) {
                      setAREAWISESALE(true);
                    } else {
                      setAREAWISESALE(false);
                    }
                  } else if (SValue.Screen_Id === 171) {
                    if (SValue.Allow_Access === 1) {
                      setGROUPWISESALE(true);
                    } else {
                      setGROUPWISESALE(false);
                    }
                  } else if (SValue.Screen_Id === 172) {
                    if (SValue.Allow_Access === 1) {
                      setMONTHWISESALE(true);
                    } else {
                      setMONTHWISESALE(false);
                    }
                  }
                } else if (MValue.Mod_Id === 23) {
                  if (SValue.Screen_Id === 121) {
                    if (SValue.Allow_Access === 1) {
                      setSTOCK_LEDGER(true);
                    } else {
                      setSTOCK_LEDGER(false);
                    }
                  } else if (SValue.Screen_Id === 122) {
                    if (SValue.Allow_Access === 1) {
                      setSTOCK_BALANCE_BATCH_WISE(true);
                    } else {
                      setSTOCK_BALANCE_BATCH_WISE(false);
                    }
                  } else if (SValue.Screen_Id === 123) {
                    if (SValue.Allow_Access === 1) {
                      setSTOCK_BALANCE_WAREHOUSE_WISE(true);
                    } else {
                      setSTOCK_BALANCE_WAREHOUSE_WISE(false);
                    }
                  } else if (SValue.Screen_Id === 124) {
                    if (SValue.Allow_Access === 1) {
                      setSHORT_INVENTORY_ITEMS(true);
                    } else {
                      setSHORT_INVENTORY_ITEMS(false);
                    }
                  } else if (SValue.Screen_Id === 125) {
                    if (SValue.Allow_Access === 1) {
                      setMAX_INVENTORY_ITEMS(true);
                    } else {
                      setMAX_INVENTORY_ITEMS(false);
                    }
                  }
                } else if (MValue.Mod_Id === 24) {
                  if (SValue.Screen_Id === 128) {
                    if (SValue.Allow_Access === 1) {
                      setEXPENSES_REPORT(true);
                    } else {
                      setEXPENSES_REPORT(false);
                    }
                  } else if (SValue.Screen_Id === 129) {
                    if (SValue.Allow_Access === 1) {
                      setEXPENSES_CLASSIFICATION_WISE(true);
                    } else {
                      setEXPENSES_CLASSIFICATION_WISE(false);
                    }
                  } else if (SValue.Screen_Id === 130) {
                    if (SValue.Allow_Access === 1) {
                      setEXPENSES_TYPE_WISE(true);
                    } else {
                      setEXPENSES_TYPE_WISE(false);
                    }
                  }
                } else if (MValue.Mod_Id === 25) {
                  if (SValue.Screen_Id === 131) {
                    if (SValue.Allow_Access === 1) {
                      setCHASH_IN_HAND(true);
                    } else {
                      setCHASH_IN_HAND(false);
                    }
                  } else if (SValue.Screen_Id === 132) {
                    if (SValue.Allow_Access === 1) {
                      setCASH_IN_BANK(true);
                    } else {
                      setCASH_IN_BANK(false);
                    }
                  }
                } else if (MValue.Mod_Id === 26) {
                  if (SValue.Screen_Id === 126) {
                    if (SValue.Allow_Access === 1) {
                      setDAILY_ACTIVITIES(true);
                    } else {
                      setDAILY_ACTIVITIES(false);
                    }
                  } else if (SValue.Screen_Id === 127) {
                    if (SValue.Allow_Access === 1) {
                      setPROFIT_AND_LOSS(true);
                    } else {
                      setPROFIT_AND_LOSS(false);
                    }
                  }
                } else if (MValue.Mod_Id === 27) {
                  if (SValue.Screen_Id === 164) {
                    if (SValue.Allow_Access === 1) {
                      setBONUSCLAIMS(true);
                    } else {
                      setBONUSCLAIMS(false);
                    }
                  } else if (SValue.Screen_Id === 165) {
                    if (SValue.Allow_Access === 1) {
                      setDISCOUNTCLAIMS(true);
                    } else {
                      setDISCOUNTCLAIMS(false);
                    }
                  } else if (SValue.Screen_Id === 166) {
                    if (SValue.Allow_Access === 1) {
                      setOPENCLAIM(true);
                    } else {
                      setOPENCLAIM(false);
                    }
                  } else if (SValue.Screen_Id === 167) {
                    if (SValue.Allow_Access === 1) {
                      setCLAIMADJUSTMENT(true);
                    } else {
                      setCLAIMADJUSTMENT(false);
                    }
                  }
                } else if (MValue.Mod_Id === 28) {
                  if (SValue.Screen_Id === 168) {
                    if (SValue.Allow_Access === 1) {
                      setDSR_Files(true);
                    } else {
                      setDSR_Files(false);
                    }
                  }
                } else if (MValue.Mod_Id === 29) {
                  if (SValue.Screen_Id === 169) {
                    if (SValue.Allow_Access === 1) {
                      setBackup(true);
                    } else {
                      setBackup(false);
                    }
                  }
                }
              });

              resolve();
            });
          });
        });
      });
    }
  }, [Group_Id]);
  const showLoader = () => {
    setIsLoader(true);
  };
  const hideLoader = () => {
    setIsLoader(false);
  };
  return ReactDOM.createPortal(
    <>
      {/* LOGO */}
      <a href="#" className="logo text-center logo-light">
        <span className="logo-lg">
          {/* <img src="assets/images/logo_sm.png" alt height={16} /><label className='text-white' style={{fontWeight:'bold',fontSize:'20px'}}>PHARMEX DMS</label>*/}
          <img
            src="assets/images/sidebar_logo.png"
            alt="Logo"
            style={{ width: "250px", height: "80px" }}
          />
        </span>
        <span className="logo-sm">
          <img src="assets/images/sidebar_logo_small.png" alt height={40} />
        </span>
      </a>
      {/* LOGO */}
      <a href="#" className="logo text-center logo-dark">
        <span className="logo-lg">
          <img src="assets/images/logo_sm_dark.png" alt height={16} />
          <label
            className="text-white"
            style={{ fontWeight: "bold", fontSize: "20px" }}
          >
            PHARMEX DMS
          </label>
        </span>
        <span className="logo-sm">
          <img src="assets/images/logo_sm_dark.png" alt height={16} />
        </span>
      </a>
      <div className="h-100" id="leftside-menu-container" data-simplebar>
        {/*- Sidemenu */}
        <ul className="side-nav">
          {Admin_Dashboard && (
            <>
              <li className="side-nav-title side-nav-item">Navigation</li>
              <li className="side-nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#sidebarDashboards"
                  aria-expanded="false"
                  aria-controls="sidebarDashboards"
                  className="side-nav-link"
                >
                  <i className="uil-home-alt" />
                  <span className="badge bg-success float-end">1</span>
                  <span> Dashboard </span>
                </a>
                <div className="collapse" id="sidebarDashboards">
                  <ul className="side-nav-second-level">
                    <li>
                      <Link to="/MainForm">Admin</Link>
                    </li>
                  </ul>
                </div>
              </li>
            </>
          )}

          {RECORDS && (
            <>
              <li className="side-nav-title side-nav-item">Apps</li>
              <li className="side-nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#sidebarEcommerce"
                  aria-expanded="false"
                  aria-controls="sidebarEcommerce"
                  className="side-nav-link"
                >
                  <i className="uil-store" />
                  <span> Records </span>

                  <span className="menu-arrow" />
                </a>
                <div className="collapse" id="sidebarEcommerce">
                  <ul className="side-nav-second-level">
                    {Unit_Of_Measurement && (
                      <li>
                        <Link to="/UOMList">Unit of Measurment</Link>
                      </li>
                    )}
                    {Item_Main_Type && (
                      <li>
                        <Link to="/ItemMainType">Item Main Type</Link>
                      </li>
                    )}
                    {Item_Sub_Type && (
                      <li>
                        <Link to="/ItemSubType">Item Sub Type</Link>
                      </li>
                    )}
                    {/*WareHouse && <li><Link to="/WareHouse">WareHouse</Link></li> */}
                    {DISTRICTS && (
                      <li>
                        <Link to="/District">District</Link>
                      </li>
                    )}
                    {City && (
                      <li>
                        <Link to="/City">City</Link>
                      </li>
                    )}
                    {GROUPS && (
                      <li>
                        <Link to="/Groups">Groups</Link>
                      </li>
                    )}
                    {AREAS && (
                      <li>
                        <Link to="/Areas">Areas</Link>
                      </li>
                    )}
                    {Menufacture_By && (
                      <li>
                        <Link to="/manufacture">Manufacture By</Link>
                      </li>
                    )}
                    {Bank && (
                      <li>
                        <Link to="/bank">Bank</Link>
                      </li>
                    )}
                    {COMPANYGROUP && (
                      <li>
                        <Link to="/CompanyGroup">Company Group</Link>
                      </li>
                    )}
                    {PRODUCTGROUP && (
                      <li>
                        <Link to="/ProductGroup">Product Group</Link>
                      </li>
                    )}
                    {ASSIGNNING_GRPS_TO_MARKTNG_STAFF && (
                      <li>
                        <Link to="/AGM">Create Portal</Link>
                      </li>
                    )}
                    {/* {SCHEME && <li><Link to="/Scheme">Scheme</Link></li>} */}
                    {DRIVER && (
                      <li>
                        <Link to="/Driver">Driver</Link>
                      </li>
                    )}
                    {VEHICLE && (
                      <li>
                        <Link to="/Vehicle">Vehicle</Link>
                      </li>
                    )}
                    {MEDIUM && (
                      <li>
                        <Link to="/Medium">Medium</Link>
                      </li>
                    )}
                    {REASONS && (
                      <li>
                        <Link to="/Reason">Reason</Link>
                      </li>
                    )}
                    {DISCOUNTPOLICYFORCG && (
                      <li>
                        <Link to="/DiscountPolicy">
                          Discount Policy For Company || Group
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            </>
          )}
          {EXPENSE_MODULE && (
            <li className="side-nav-item">
              <a
                data-bs-toggle="collapse"
                href="#sidebarEmail"
                aria-expanded="false"
                aria-controls="sidebarEmail"
                className="side-nav-link"
              >
                <i className="uil-envelope" />
                <span> Expenses </span>
                <span className="menu-arrow" />
              </a>
              <div className="collapse" id="sidebarEmail">
                <ul className="side-nav-second-level">
                  {Expense_Classification && (
                    <li>
                      <Link to="/ExpenseClassification">
                        Expence Classification
                      </Link>
                    </li>
                  )}
                  {Expense_Type && (
                    <li>
                      <Link to="/ExpenseType">Expence Type</Link>
                    </li>
                  )}
                  {Expense && (
                    <li>
                      <Link to="/ExpenseList">Expences</Link>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}
          {SECURITY && (
            <li className="side-nav-item">
              <a
                data-bs-toggle="collapse"
                href="#sidebarProjects"
                aria-expanded="false"
                aria-controls="sidebarProjects"
                className="side-nav-link"
              >
                <i className="uil-briefcase" />
                <span> Security </span>
                <span className="menu-arrow" />
              </a>
              <div className="collapse" id="sidebarProjects">
                <ul className="side-nav-second-level">
                  <li>
                    <Link to="/BranchesList">Branches</Link>
                  </li>
                  <li>
                    <Link to="/FinYearList">Financial Year</Link>
                  </li>
                  <li>
                    <Link to="/ProjectList">Projects</Link>
                  </li>
                  {USER_SECURITY && (
                    <li>
                      <Link to="/UserAccessList">User Security</Link>
                    </li>
                  )}
                  {/* <li><Link to="/UserAccessList">User Security</Link></li>*/}
                  {/* <li><Link to="/Users">Users</Link></li>  */}
                  {USERS && (
                    <li>
                      <Link to="/Users">Users</Link>
                    </li>
                  )}
                  {CONTROL_PANEL && (
                    <li>
                      <Link to="/ControlPanel">Control Panel</Link>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}
           {Opening && (
            <>
              <li className="side-nav-title side-nav-item">Opening</li>
              <li className="side-nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#sidebarOpening"
                  aria-expanded="false"
                  aria-controls="sidebarOpening"
                  className="side-nav-link"
                >
                  <i className="uil-copy-alt" />
                  <span> Opening </span>
                  <span className="menu-arrow" />
                </a>
                <div className="collapse" id="sidebarOpening">
                  <ul className="side-nav-second-level">
                      <li>
                        <Link to="/OpeningList">Inventory Opening</Link>
                      </li>
                      <li>
                        <Link to="/bank-opening">Bank Opening</Link>
                      </li>
                      <li>
                        <Link to="/company-opening">Company Opening</Link>
                      </li>
                      <li>
                        <Link to="/customer-opening">Customer Opening</Link>
                      </li>
                  </ul>
                </div>
              </li>
            </>
                  )}
          {MAIN_FORM && (
            <li className="side-nav-item">
              <a
                data-bs-toggle="collapse"
                href="#sidebarTasks"
                aria-expanded="false"
                aria-controls="sidebarTasks"
                className="side-nav-link"
              >
                <i className="uil-clipboard-alt" />
                <span> Main Forms </span>
                <span className="menu-arrow" />
              </a>

              <div className="collapse" id="sidebarTasks">
                <ul className="side-nav-second-level">
                  {Items && (
                    <li>
                      <Link to="/ItemList">Items</Link>
                    </li>
                  )}
                  {Supplier && (
                    <li>
                      <Link to="/SuppliersList">Suppliers</Link>
                    </li>
                  )}
                  {Customers && (
                    <li>
                      <Link to="/CustomerList">Customer</Link>
                    </li>
                  )}
                  {/*Stock_Transfer && <li><Link to="/StockTransferList">Stock Transfer</Link></li> 
            <li><Link to="/StockInOutList">Stock Transfer</Link></li>*/}
                  {Stock_Adjustment && (
                    <li>
                      <Link to="/StockAdjustmentList">Stock Adjustment</Link>
                    </li>
                  )}
                  {/*{Payment && <li><Link to="/Payments">Payment</Link></li>} */}
                  <li>
                    <Link to="/CompanyPaymentList">Payment</Link>
                  </li>
                  {/*{ Receipt && <li><Link to="/Receipts">Receipt</Link></li>} */}
                  {Receipt && (
                    <li>
                      <Link to="/RecoveryList">Receipt</Link>
                    </li>
                  )}
                  {AUTOMAILER && (
                    <li>
                      <Link to="/AutoMailer">Auto Mailer</Link>
                    </li>
                  )}
                  {/* <li><Link to="/BatchRate">Change Batch Rate</Link></li>
              I remove this menue becuase client requirement is chnage sale rate and expairy without effecting ledgers 
              this approch not working so i done Adjust with Stock adjustment
          */}
                </ul>
              </div>
            </li>
          )}
          {PURCHASE_MODULE && (
            <>
             {User_Info?.User_Type !== "Manger Portal" && <li className="side-nav-title side-nav-item">Purchase</li>} 
              <li className="side-nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#sidebarPages"
                  aria-expanded="false"
                  aria-controls="sidebarPages"
                  className="side-nav-link"
                >
                  <i className="uil-copy-alt" />
                  <span> Purchase Module </span>
                  <span className="menu-arrow" />
                </a>
                <div className="collapse" id="sidebarPages">
                  <ul className="side-nav-second-level">
                    {Purchase_Order && (
                      <li>
                        <Link to="/PurchaseOrderList">Purchase Order</Link>
                      </li>
                    )}
                     {/* {Purchase_Order && (
                      <li>
                        <Link to="/autoPoList">Auto Purchase Order</Link>
                      </li>
                    )} */}
                    {Purchase_Invoice && (
                      <li>
                        <Link to="/PurchaseInvoiceList">Purchase Invoice</Link>
                      </li>
                    )}
                    {Purchase_Return && (
                      <li>
                        <Link to="/PurchaseReturnList">Purchase Return</Link>
                      </li>
                    )}
                    {Pending_PO && (
                      <li>
                        <Link to="/PendingPurchaseOrderList">
                          Pending Purchase Orders
                        </Link>
                      </li>
                    )
                    }
                  </ul>
                </div>
              </li>
            </>
          )}

          {SALE_MODULE && (
            <>
              <li className="side-nav-title side-nav-item">Sale</li>
              <li className="side-nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#sidebarSale"
                  aria-expanded="false"
                  aria-controls="sidebarSale"
                  className="side-nav-link"
                >
                  <i className="uil-copy-alt" />
                  <span> Sale Module </span>
                  <span className="menu-arrow" />
                </a>
                <div className="collapse" id="sidebarSale">
                  <ul className="side-nav-second-level">
                    {Sale_Order && (
                      <li>
                        <Link to="/SaleOrderList">Sale Order</Link>
                      </li>
                    )}
                    {Sale_Invoice && (
                      <li>
                        <Link to="/SaleInvoiceList">Sale Invoice</Link>
                      </li>
                    )}
                    {Sale_Return && (
                      <li>
                        <Link to="/SaleReturnList">Sale Return</Link>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            </>
          )}
          {DELIVERY_MODULE && (
            <>
              <li className="side-nav-title side-nav-item">Delivery</li>
              <li className="side-nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#sidebarSupply"
                  aria-expanded="false"
                  aria-controls="sidebarSupply"
                  className="side-nav-link"
                >
                  <i className="uil-copy-alt" />
                  <span>Delivery </span>
                  <span className="menu-arrow" />
                </a>
                <div className="collapse" id="sidebarSupply">
                  <ul className="side-nav-second-level">
                    {DELIVERY && (
                      <li>
                        <Link to="/SetSedule">Delivery</Link>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            </>
          )}
          {BOOKING_MODULE && (
            <>
              <li className="side-nav-title side-nav-item">Booking</li>
              <li className="side-nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#sidebarBookings"
                  aria-expanded="false"
                  aria-controls="sidebarBookings"
                  className="side-nav-link"
                >
                  <i className="uil-copy-alt" />
                  <span>Booking </span>
                  <span className="menu-arrow" />
                </a>
                <div className="collapse" id="sidebarBookings">
                  <ul className="side-nav-second-level">
                    {ORDERS && (
                      <li>
                        <Link to="/OrderBooking">Orders</Link>
                      </li>
                    )}
                    {ORDERBOOKING && (
                      <li>
                        <Link to="/BookOrder">Order Booking</Link>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            </>
          )}

          {CLAIMS_MODULE && (
            <>
              <li className="side-nav-title side-nav-item">Claims</li>
              <li className="side-nav-item">
                <a
                  data-bs-toggle="collapse"
                  href="#sidebarClaims"
                  aria-expanded="false"
                  aria-controls="sidebarClaims"
                  className="side-nav-link"
                >
                  <i className="uil-copy-alt" />
                  <span>Claims </span>
                  <span className="menu-arrow" />
                </a>
                <div className="collapse" id="sidebarClaims">
                  <ul className="side-nav-second-level">
                    {/* {DISCOUNTCLAIMS && <li><Link to="/DiscountClaimTable">Discount Claim</Link></li>}  */}
                    {DISCOUNTCLAIMS && (
                      <li>
                        <Link
                          to="/DiscountClaimList"
                          onClick={(e) =>
                            set_ClaimPolicyType(
                              0,
                              null,
                              null,
                              null,
                              "Discount",
                              0
                            )
                          }
                        >
                          Discount Claim
                        </Link>
                      </li>
                    )}
                    {BONUSCLAIMS && (
                      <li>
                        <Link to="/"> Bonus Claim</Link>
                      </li>
                    )}
                    {OPENCLAIM && (
                      <li>
                        <Link to="/">Open Claim</Link>
                      </li>
                    )}
                    {CLAIMADJUSTMENT && (
                      <li>
                        <Link to="/">Claim Adjustment</Link>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            </>
          )}
          {/* <li className="side-nav-title side-nav-item">Booker location</li>
          <li className="side-nav-item">
            <Link
              aria-expanded="false"
              aria-controls="sidebarPages"
              className="side-nav-link hvr"
              to={"/Tracking"}
            >
              <i className="uil-copy-alt" />
              <span> Live Booker Tracking </span>
            </Link>
          </li> */}

         {User_Info?.User_Type !== "Manger Portal" && <li className="side-nav-title side-nav-item mt-1">Reports</li>} 
          {DSR_Files && (
            <li className="side-nav-item">
              <Link
                aria-expanded="false"
                aria-controls="sidebarPages"
                className="side-nav-link hvr"
                to={"#"}
              >
                <i className="uil-copy-alt" />
                <span
                  onClick={(e) => {
                    show_Modal();
                  }}
                >
                  {" "}
                  DSR Files{" "}
                </span>
              </Link>
            </li>
          )}
          {Backup && (
            <li className="side-nav-item">
              <Link
                aria-expanded="false"
                aria-controls="sidebarPages"
                className="side-nav-link hvr"
                to={"#"}
              >
                <i className="uil-copy-alt" />
                <span
                  onClick={(e) => {
                    showLoader();
                    SelectDataForBackup(
                      Comp_Id,
                      ProjectID,
                      async (RecivedData) => {
                        hideLoader();
                        const folder = zip.folder(
                          GetCurrentDate() + "databasebackup"
                        );
                        const AreaData = Excel_Converstion(
                          RecivedData.AreaData
                        );
                        const MarketingData = Excel_Converstion(
                          RecivedData.MarketingData
                        );
                        const CityData = Excel_Converstion(
                          RecivedData.CityData
                        );
                        const CGData = Excel_Converstion(RecivedData.CGData);
                        const CPData = Excel_Converstion(RecivedData.CPData);
                        const CustomerData = Excel_Converstion(
                          RecivedData.CustomerData
                        );
                        const DeliveryData = Excel_Converstion(
                          RecivedData.DeliveryData
                        );
                        const DeliveryDetailsData = Excel_Converstion(
                          RecivedData.DeliveryDetailsData
                        );
                        const DistrictData = Excel_Converstion(
                          RecivedData.DistrictData
                        );
                        const DSRData = Excel_Converstion(RecivedData.DSRData);

                        const ExpenceClassificationData = Excel_Converstion(
                          RecivedData.ExpenceClassificationData
                        );
                        const ExpenceData = Excel_Converstion(
                          RecivedData.ExpenceData
                        );
                        const ExpenceTypeData = Excel_Converstion(
                          RecivedData.ExpenceTypeData
                        );
                        const GroupData = Excel_Converstion(
                          RecivedData.GroupData
                        );
                        const GroupDetailData = Excel_Converstion(
                          RecivedData.GroupDetailData
                        );
                        const InventoryData = Excel_Converstion(
                          RecivedData.InventoryData
                        );
                        const ItemMainTypeData = Excel_Converstion(
                          RecivedData.ItemMainTypeData
                        );
                        const ItemData = Excel_Converstion(
                          RecivedData.ItemData
                        );
                        const ItemSubTypeData = Excel_Converstion(
                          RecivedData.ItemSubTypeData
                        );
                        const ManufacturedByData = Excel_Converstion(
                          RecivedData.ManufacturedByData
                        );

                        const mediumData = Excel_Converstion(
                          RecivedData.mediumData
                        );
                        const OrderBookingData = Excel_Converstion(
                          RecivedData.OrderBookingData
                        );
                        const OrderBookingDetailData = Excel_Converstion(
                          RecivedData.OrderBookingDetailData
                        );
                        const PaymentData = Excel_Converstion(
                          RecivedData.PaymentData
                        );
                        const PaymentDetailData = Excel_Converstion(
                          RecivedData.PaymentDetailData
                        );
                        const PurchaseData = Excel_Converstion(
                          RecivedData.PurchaseData
                        );
                        const PurhcaseDetailData = Excel_Converstion(
                          RecivedData.PurhcaseDetailData
                        );
                        const PurchaseOrderData = Excel_Converstion(
                          RecivedData.PurchaseOrderData
                        );
                        const PurhcaseOrderDetailData = Excel_Converstion(
                          RecivedData.PurhcaseOrderDetailData
                        );
                        const receiptsData = Excel_Converstion(
                          RecivedData.receiptsData
                        );

                        const receiptsDetailData = Excel_Converstion(
                          RecivedData.receiptsDetailData
                        );
                        const SaleData = Excel_Converstion(
                          RecivedData.SaleData
                        );
                        const SaleDetailData = Excel_Converstion(
                          RecivedData.SaleDetailData
                        );
                        const SaleOrderData = Excel_Converstion(
                          RecivedData.SaleOrderData
                        );
                        const SaleOrderDetailData = Excel_Converstion(
                          RecivedData.SaleOrderDetailData
                        );
                        const SchemeData = Excel_Converstion(
                          RecivedData.SchemeData
                        );
                        const SchemeDetailData = Excel_Converstion(
                          RecivedData.SchemeDetailData
                        );
                        const StockadjustmentData = Excel_Converstion(
                          RecivedData.StockadjustmentData
                        );
                        const StockadjustmentDetailData = Excel_Converstion(
                          RecivedData.StockadjustmentDetailData
                        );
                        const StockTransferData = Excel_Converstion(
                          RecivedData.StockTransferData
                        );

                        const StockTransferDetailData = Excel_Converstion(
                          RecivedData.StockTransferDetailData
                        );
                        const PGData = Excel_Converstion(RecivedData.PGData);
                        const reasonData = Excel_Converstion(
                          RecivedData.reasonData
                        );
                        const recoveryData = Excel_Converstion(
                          RecivedData.recoveryData
                        );
                        const schemepolicyData = Excel_Converstion(
                          RecivedData.schemepolicyData
                        );
                        const SoftwareData = Excel_Converstion(
                          RecivedData.SoftwareData
                        );
                        const SoftwareLedgerdata = Excel_Converstion(
                          RecivedData.SoftwareLedgerdata
                        );
                        const SupplierData = Excel_Converstion(
                          RecivedData.SupplierData
                        );
                        const UOMData = Excel_Converstion(RecivedData.UOMData);
                        const VehicalData = Excel_Converstion(
                          RecivedData.VehicalData
                        );
                        const WareHouseData = Excel_Converstion(
                          RecivedData.WareHouseData
                        );
                        const bankData = Excel_Converstion(
                          RecivedData.bankData
                        );
                        const driverData = Excel_Converstion(
                          RecivedData.driverData
                        );

                        folder.file("area.xlsx", AreaData);
                        folder.file("assigingstaff.xlsx", MarketingData);
                        folder.file("city.xlsx", CityData);
                        folder.file("companygroup.xlsx", CGData);
                        folder.file("configuration.xlsx", CPData);
                        folder.file("customer.xlsx", CustomerData);
                        folder.file("delivery.xlsx", DeliveryData);
                        folder.file(
                          "deliverydetails.xlsx",
                          DeliveryDetailsData
                        );
                        folder.file("district.xlsx", DistrictData);
                        folder.file("dsrcompanyfile.xlsx", DSRData);

                        folder.file(
                          "expenseclassification.xlsx",
                          ExpenceClassificationData
                        );
                        folder.file("expence.xlsx", ExpenceData);
                        folder.file("expencetype.xlsx", ExpenceTypeData);
                        folder.file("groups.xlsx", GroupData);
                        folder.file("groupdetails.xlsx", GroupDetailData);
                        folder.file("inventory.xlsx", InventoryData);
                        folder.file("itemmaintype.xlsx", ItemMainTypeData);
                        folder.file("items.xlsx", ItemData);
                        folder.file("itemsubtype.xlsx", ItemSubTypeData);
                        folder.file("manufacturedby.xlsx", ManufacturedByData);

                        folder.file("medium.xlsx", mediumData);
                        folder.file("orderbooking.xlsx", OrderBookingData);
                        folder.file(
                          "orderbookingdetails.xlsx",
                          OrderBookingDetailData
                        );
                        folder.file("payment.xlsx", PaymentData);
                        folder.file("paymentdetails.xlsx", PaymentDetailData);
                        folder.file("purchase.xlsx", PurchaseData);
                        folder.file("purchasedetails.xlsx", PurhcaseDetailData);
                        folder.file("purchaseorder.xlsx", PurchaseOrderData);
                        folder.file(
                          "purchaseorderdetails.xlsx",
                          PurhcaseOrderDetailData
                        );
                        folder.file("receipt.xlsx", receiptsData);

                        folder.file("receiptdetails.xlsx", receiptsDetailData);
                        folder.file("saleinvoice.xlsx", SaleData);
                        folder.file("saleinvoicedetails.xlsx", SaleDetailData);
                        folder.file("saleorder.xlsx", SaleOrderData);
                        folder.file(
                          "saleorderdetails.xlsx",
                          SaleOrderDetailData
                        );
                        folder.file("schemedata.xlsx", SchemeData);
                        folder.file("schemedetail.xlsx", SchemeDetailData);
                        folder.file(
                          "stockadjustment.xlsx",
                          StockadjustmentData
                        );
                        folder.file(
                          "stockadjustmentdetails.xlsx",
                          StockadjustmentDetailData
                        );
                        folder.file("stocktransfer.xlsx", StockTransferData);

                        folder.file(
                          "stocktransferdetails.xlsx",
                          StockTransferDetailData
                        );
                        folder.file("productgroup.xlsx", PGData);
                        folder.file("reason.xlsx", reasonData);
                        folder.file("recovery.xlsx", recoveryData);
                        folder.file("schemepolicy.xlsx", schemepolicyData);
                        folder.file("software.xlsx", SoftwareData);
                        folder.file("softwareledger.xlsx", SoftwareLedgerdata);
                        folder.file("suppliers.xlsx", SupplierData);
                        folder.file("uom.xlsx", UOMData);
                        folder.file("vehicel.xlsx", VehicalData);
                        folder.file("warehouse.xlsx", WareHouseData);
                        folder.file("bank.xlsx", bankData);
                        folder.file("driver.xlsx", driverData);

                        const password = "shift@unshift";
                        // zip.generateAsync({ type: "blob", compression: "DEFLATE", compressionOptions: { level: 9 }, password: password }).then(function (content) {
                        // Save the zip file with the password
                        //  saveAs(content, GetCurrentDate()+"D:/backup/databasebackup.zip");
                        //  });
                        const content = await zip.generateAsync({
                          type: "blob",
                          compression: "DEFLATE",
                          compressionOptions: { level: 9 },
                          password: password,
                        });
                        const fileInput = document.createElement("input");
                        fileInput.type = "file";
                        fileInput.directory = true;
                        fileInput.webkitdirectory = true;
                        fileInput.multiple = false;
                        fileInput.style.display = "none";
                        document.body.appendChild(fileInput);
                        fileInput.click();
                        await new Promise((resolve) =>
                          fileInput.addEventListener("change", resolve)
                        );
                        [folder] = fileInput.files;

                        // save the zip file with the password to the selected folder
                        const filename = `${new Date().toISOString()}_databasebackup.zip`;
                        const file = new File([content], filename);
                        const url = URL.createObjectURL(file);
                        const link = document.createElement("a");
                        link.href = url;
                        link.download = filename;
                        link.style.display = "none";
                        if (folder) {
                          folder.webkitGetAsEntry()?.createWriter((writer) => {
                            writer.write(file);
                            writer.onwriteend = () => link.click();
                          });
                        }
                      }
                    );
                  }}
                >
                  {" "}
                  Backup Database{" "}
                </span>
              </Link>
            </li>
          )}
          {true && (
            <>
              {User_Info?.User_Type !== "Manger Portal" && <li className="side-nav-title side-nav-item">Purchase</li>}
              <li className="side-nav-item">
             {User_Info?.User_Type !== "Manger Portal" &&  <a
                  data-bs-toggle="collapse"
                  href="#sidebarPages"
                  aria-expanded="false"
                  aria-controls="sidebarPages"
                  className="side-nav-link"
                >
                  <i className="uil-copy-alt" />
                  <span> Tax Reports </span>
                  <span className="menu-arrow" />
                </a>}
                <div className="collapse" id="sidebarPages">
                  <ul className="side-nav-second-level">
                    {Purchase_Order && (
                      <li>
                        <Link
                          to={"#"}
                          onClick={(e) => {
                            setReportPath("/CompanyTaxes");
                            setReportTitle("Company Wise Taxes");
                            setTaxReport_ReadOnlyProp({
                              FromDate: false,
                              ToDate: false,
                              Project: false,
                              Export: true,
                              ManufacturedBy: false,
                              City: true,
                              Customer: true,
                              Filer: true,
                              GS: true,
                              Further: true,
                            });
                            Show_TaxReport_Modal();
                          }}
                        >
                          Company Wise WHT/Advance Tax
                        </Link>
                      </li>
                    )}
                    {Purchase_Invoice && (
                      <li>
                        <Link
                          to="#"
                          onClick={(e) => {
                            setReportPath("/CustomerWiseTaxes");
                            setReportTitle("Customer Wise Taxes");
                            setTaxReport_ReadOnlyProp({
                              FromDate: false,
                              ToDate: false,
                              Project: false,
                              Export: true,
                              ManufacturedBy: false,
                              City: false,
                              Customer: false,
                              Filer: false,
                              GS: false,
                              Further: false,
                            });
                            Show_TaxReport_Modal();
                          }}
                        >
                          Customer Wise Taxes
                        </Link>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            </>
          )}
          {SALE_REPORT && (
            <li className="side-nav-item">
              <a
                data-bs-toggle="collapse"
                href="#sidebarBaseUI"
                aria-expanded="false"
                aria-controls="sidebarBaseUI"
                className="side-nav-link"
              >
                <i className="uil-box" />
                <span> Sale Reports </span>
                <span className="menu-arrow" />
              </a>
              <div className="collapse" id="sidebarBaseUI">
                <ul className="side-nav-second-level">
                  {CUSTOMER_LEDGER && (
                    <li>
                      <Link
                        to={"#"}
                        onClick={(e) => {
                          setReportPath("/CustomerLedgerReport");
                          setReportTitle("Customer Ledger");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: false,
                            Project: false,
                            Customer: false,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            User: true,
                            Export: false,
                          });
                          Show_SaleReport_Modal();
                        }}
                      >
                        Customer Ledger
                      </Link>
                    </li>
                  )}

                  {SALE_SUMMARY_CUSTOMER_WISE && (
                    <li>
                      <Link
                        to={"#"}
                        onClick={(e) => {
                          setReportPath("/SaleSummaryCustomerWiseReport");
                          setReportTitle("Sale Summary Customer-Wise");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            User: true,
                            Export: false,
                          });
                          Show_SaleReport_Modal();
                        }}
                      >
                        Sale Summary -Customer Wise
                      </Link>
                    </li>
                  )}

                  {SALE_SUMMARY_SAELMAN_WISE && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/SaleSummarySalesManWise");
                          setReportTitle("Sale Summary SalesMan-Wise");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            User: false,
                            Export: true,
                          });
                          Show_SaleReport_Modal();
                        }}
                      >
                        Sale Summary -Saleman Wise
                      </Link>
                    </li>
                  )}

                  {SALE_SUMMARY_ITEMS_WISE && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/SaleLedgerItemWise");
                          setReportTitle("Sale Ledger Item-Wise");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: false,
                            ToItem: false,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            User: true,
                            Export: true,
                          });
                          Show_SaleReport_Modal();
                        }}
                      >
                        Sale Ledger -Item Wise
                      </Link>
                    </li>
                  )}

                  {SALE_RETURN_LEDGER_CUSTOMER_WISE && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/SaleReturnLedgerCustWise");
                          setReportTitle("Sale Return Ledger Customer-Wise");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            User: true,
                            Export: true,
                          });
                          Show_SaleReport_Modal();
                        }}
                      >
                        Sale Return Ledger -Customer Wise
                      </Link>
                    </li>
                  )}

                  {SALE_MARGIN_CUSTOMER_WISE && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/SaleMarginCustomerWise");
                          setReportTitle("Sale Margin Customer-Wise");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            Customer: false,
                            ToCustomer: false,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            User: true,
                            Export: true,
                          });
                          Show_SaleReport_Modal();
                        }}
                      >
                        Sale Margin -Customer Wise
                      </Link>
                    </li>
                  )}

                  {/* {SALE_MARGIN_SUPPLIER_WISE &&(<li><Link to='#'>Sale Margin -Supplier Wise</Link></li>)} */}

                  {/* { SALE_RETURN_LEDGER_CUSTOMER_WISE &&(<li><Link to='#'  onClick={e=>{
          setReportPath('/SaleReturnLedgerCustWise')
          setReportTitle('Sale Return Ledger Customer-Wise')
          setSaleReport_ReadOnlyProp({FromDate:false,ToDate:false,finYear:true,Project:false,Customer:true,ToCustomer:true,FromItem:true,ToItem:true,NoOfItems:true,
            Supplier:false,
            WareHouse:false,
            Classification:false,
            E_type:false,
            Bank:false
          })
          Show_SaleReport_Modal()
          }}>Sale Return Ledger -Customer Wise</Link></li>)} */}

                  {MOST_SOLD_ITEMS && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/MostSoldItems");
                          setReportTitle("Most Sold Items");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: false,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            User: true,
                            Export: true,
                          });
                          Show_SaleReport_Modal();
                        }}
                      >
                        Most Sold Items
                      </Link>
                    </li>
                  )}

                  {MINIMUM_SOLD_ITEMS && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/MinimumSoldItems");
                          setReportTitle("Minimum Sold Items");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: false,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            User: true,
                            Export: true,
                          });
                          Show_SaleReport_Modal();
                        }}
                      >
                        Minimum Sold Items
                      </Link>
                    </li>
                  )}

                  {DAILY_SALES_OF_LAST_MONTH && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/DailySaleOfLastMOnth");
                          setReportTitle("Daily Sale of Last Month");
                          setSaleReport_ReadOnlyProp({
                            FromDate: true,
                            ToDate: true,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            User: true,
                            Export: true,
                          });
                          Show_SaleReport_Modal();
                        }}
                      >
                        Daily Sales Of Last Month
                      </Link>
                    </li>
                  )}

                  {RECEIVABLES && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/Receiveable");
                          setReportTitle("Receivable");
                          setSaleReport_ReadOnlyProp({
                            FromDate: true,
                            ToDate: true,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            User: true,
                            Export: true,
                          });
                          Show_SaleReport_Modal();
                        }}
                      >
                        Recivables
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}
          {PURCHASE_REPORT && (
            <li className="side-nav-item">
              <a
                data-bs-toggle="collapse"
                href="#sidebarExtendedUI"
                aria-expanded="false"
                aria-controls="sidebarExtendedUI"
                className="side-nav-link"
              >
                <i className="uil-package" />
                <span> Purchase Reports</span>
                <span className="menu-arrow" />
              </a>
              <div className="collapse" id="sidebarExtendedUI">
                <ul className="side-nav-second-level">
                  {SUPPLIER_LEDGER && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/SupplierLedgerReport");
                          setReportTitle("Supplier Ledger");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: false,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            Export: false,
                          });
                          Show_PurchaseReport_Modal();
                        }}
                      >
                        Company Groups Ledger
                      </Link>{" "}
                    </li>
                  )}

                  {/* { PURCHASE_SUMMARY_CATEGORY_WISE &&(<li><Link to="#" >Purchase Summary Category Wise</Link> </li>)} */}

                  {PURCHASE_LEDGER_SUPPLIER_WISE && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/SupplierWiseLedgerReport");
                          setReportTitle("Purchase Ledger Supplier-Wise");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: true,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            Export: true,
                          });
                          Show_PurchaseReport_Modal();
                        }}
                      >
                        Purchase Ledger Supplier Wise
                      </Link>{" "}
                    </li>
                  )}

                  {PURCHASE_LEDGER_ITEMS_WISE && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/PurchaseSummaryItemWiseReport");
                          setReportTitle("Purchase Summary Item-Wise");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: false,
                            ToItem: false,
                            NoOfItems: true,
                            Supplier: true,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            Export: true,
                          });
                          Show_PurchaseReport_Modal();
                        }}
                      >
                        Purchase Ledger Item Wise
                      </Link>{" "}
                    </li>
                  )}

                  {PAYABLES && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/Payables");
                          setReportTitle("Payables");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: true,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            Export: true,
                          });
                          Show_PurchaseReport_Modal();
                        }}
                      >
                        Payables
                      </Link>{" "}
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}
          {DISTRIBUTION_REPORT && (
            <li className="side-nav-item">
              <a
                data-bs-toggle="collapse"
                href="#sidebarMaps"
                aria-expanded="false"
                aria-controls="sidebarMaps"
                className="side-nav-link"
              >
                <i className="uil-location-point" />
                <span> Distribution Reports</span>
                <span className="menu-arrow" />
              </a>
              <div className="collapse" id="sidebarMaps">
                <ul className="side-nav-second-level">
                {User_Info?.User_Type !== "Manger Portal" && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/rptEssentialMedical");
                          setReportTitle("Essential Medical Report");
                          setDistributionReportReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            ManufacturedBy: true,
                            FromItem: true,
                            City: true,
                            Area: true,
                            BatchNo: true,
                            Customer: true,
                            Delivery: true,
                            CGID: true,
                            SaleInvNoFrom: true,
                            SaleInvNoTo: true,
                            SaleRate: true,
                            ToCustomer: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            Export: true,
                          });
                          Show_DistributionReport_Modal();
                        }}
                      >
                        Essential Medicains
                      </Link>{" "}
                    </li>
                  )}
                  {CURRENT_STOCK && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/CurrentStockReport");
                          setReportTitle("Current Stock Report");
                          setDistributionReportReadOnlyProp({
                            FromDate: true,
                            ToDate: true,
                            finYear: true,
                            Project: false,
                            ManufacturedBy: false,
                            FromItem: true,
                            City: true,
                            Area: true,
                            BatchNo: true,
                            Customer: true,
                            Delivery: true,
                            CGID: true,
                            SaleInvNoFrom: true,
                            SaleInvNoTo: true,
                            SaleRate: true,
                            ToCustomer: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            Export: true,
                          });
                          Show_DistributionReport_Modal();
                        }}
                      >
                        Current Stock
                      </Link>{" "}
                    </li>
                  )}

                  {BATCH_WISE_SALE && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/BatchWiseSaleReport");
                          setReportTitle("Batch-Wise Sale Report");
                          setDistributionReportReadOnlyProp({
                            FromDate: true,
                            ToDate: true,
                            finYear: true,
                            Project: false,
                            ManufacturedBy: false,
                            FromItem: false,
                            City: false,
                            Area: false,
                            BatchNo: false,
                            Customer: true,
                            Delivery: true,
                            CGID: true,
                            SaleInvNoFrom: true,
                            SaleInvNoTo: true,
                            SaleRate: true,

                            ToCustomer: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            Export: true,
                          });
                          Show_DistributionReport_Modal();
                        }}
                      >
                        Batch-Wise Sale
                      </Link>{" "}
                    </li>
                  )}

                  {NEAR_EXPIRY_STOCK && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/NearExpiryStock");
                          setReportTitle("Near Expiry Stock");
                          setDistributionReportReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            ManufacturedBy: false,
                            FromItem: true,
                            City: true,
                            Area: true,
                            BatchNo: true,
                            Customer: true,
                            Delivery: true,
                            CGID: true,
                            SaleInvNoFrom: true,
                            SaleInvNoTo: true,
                            SaleRate: true,

                            ToCustomer: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            Export: true,
                          });
                          Show_DistributionReport_Modal();
                        }}
                      >
                        Near Expiry Stock
                      </Link>{" "}
                    </li>
                  )}

                  {PRODUCT_PURCHASE_LEDGER && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/ProductPurchaseLedgerReport");
                          setReportTitle("Product Purchase Ledger");
                          setDistributionReportReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            ManufacturedBy: true,
                            FromItem: false,
                            City: true,
                            Area: true,
                            BatchNo: true,
                            Customer: true,
                            Delivery: true,
                            CGID: true,
                            SaleInvNoFrom: true,
                            SaleInvNoTo: true,
                            SaleRate: true,

                            ToCustomer: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            Export: true,
                          });
                          Show_DistributionReport_Modal();
                        }}
                      >
                        Product Purchase Ledger
                      </Link>{" "}
                    </li>
                  )}

                  {PRODUCT_SALE_LEDGER_INVOICE_WISE && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/ProductSaleLedgerInvoiceWise");
                          setReportTitle("Product Sale Ledger Invoice-Wise");
                          setDistributionReportReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            ManufacturedBy: true,
                            FromItem: false,
                            City: false,
                            Area: true,
                            BatchNo: true,
                            Customer: false,
                            Delivery: true,
                            CGID: true,
                            SaleInvNoFrom: true,
                            SaleInvNoTo: true,
                            SaleRate: true,

                            ToCustomer: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            Export: true,
                          });
                          Show_DistributionReport_Modal();
                        }}
                      >
                        Product Sale Ledger Invoice Wise
                      </Link>{" "}
                    </li>
                  )}

                  {SALE_SUMMARY && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/SaleSummaryReport");
                          setReportTitle("Sale Summary");
                          setDistributionReportReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            ManufacturedBy: true,
                            FromItem: true,
                            City: true,
                            Area: true,
                            BatchNo: true,
                            Customer: true,
                            Delivery: false,
                            CGID: true,
                            SaleInvNoFrom: true,
                            SaleInvNoTo: true,
                            SaleRate: true,

                            ToCustomer: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            Export: false,
                          });
                          Show_DistributionReport_Modal();
                        }}
                      >
                        Sale Summary
                      </Link>{" "}
                    </li>
                  )}

                  {/*  {ACCOUNT_LEDGER && 
                <li><Link to="#" onClick={e=>{
                  setReportPath('/AccountLedgerRport')
                  setReportTitle('Account Ledger')
                  setDistributionReportReadOnlyProp({
                    FromDate:false,
                    ToDate:false,
                    finYear:true,
                    Project:false,
                    ManufacturedBy:true,
                    FromItem: true,
                    City:true,
                    Area:true,
                    BatchNo:true,
                    Customer:false,
                    Delivery:true,
                    CGID:false,
                    SaleInvNoFrom:true,
                    SaleInvNoTo:true,
                    SaleRate:true,
                    
                    ToCustomer:true,
                    ToItem:true,
                    NoOfItems:true,
                    Supplier:false,
                    WareHouse:false,
                    Classification:false,
                    E_type:false,
                    Bank:false
                  })
                  Show_DistributionReport_Modal()
                  }}>Account Ledger</Link></li> }*/}
                  {TOWN_WISE_SALE && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/TownWiseReport");
                          setReportTitle("Town-Wise Report");
                          setDistributionReportReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            ManufacturedBy: false,
                            FromItem: true,
                            City: true,
                            Area: true,
                            BatchNo: true,
                            Customer: true,
                            Delivery: true,
                            CGID: false,
                            SaleInvNoFrom: true,
                            SaleInvNoTo: true,
                            SaleRate: true,

                            ToCustomer: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            Export: true,
                          });
                          Show_DistributionReport_Modal();
                        }}
                      >
                        Town wise Sale
                      </Link>
                    </li>
                  )}
                  {AREAWISESALE && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/AreaWiseReport");
                          setReportTitle("Area-Wise Report");
                          setDistributionReportReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            ManufacturedBy: false,
                            FromItem: true,
                            City: true,
                            Area: true,
                            BatchNo: true,
                            Customer: true,
                            Delivery: true,
                            CGID: false,
                            SaleInvNoFrom: true,
                            SaleInvNoTo: true,
                            SaleRate: true,

                            ToCustomer: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            Export: false,
                          });
                          Show_DistributionReport_Modal();
                        }}
                      >
                        Area wise Sale
                      </Link>
                    </li>
                  )}
                  {RECOVERY_ANALYSIS && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/RecoveryAnalysisReport");
                          setReportTitle("Recovery Analysis Report");
                          setDistributionReportReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            ManufacturedBy: true,
                            FromItem: true,
                            City: true,
                            Area: true,
                            BatchNo: true,
                            Customer: true,
                            Delivery: true,
                            CGID: false,
                            SaleInvNoFrom: true,
                            SaleInvNoTo: true,
                            SaleRate: true,

                            ToCustomer: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            Export: true,
                          });
                          Show_DistributionReport_Modal();
                        }}
                      >
                        Recovery Analysis{" "}
                      </Link>
                    </li>
                  )}
                  {SALE_REGISTER && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/SaleRegisterReport");
                          setReportTitle("Sale Register Report");
                          setTr_Type("INV");
                          setDistributionReportReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            ManufacturedBy: true,
                            FromItem: true,
                            City: true,
                            Area: true,
                            BatchNo: true,
                            Customer: true,
                            Delivery: true,
                            CGID: true,
                            SaleInvNoFrom: false,
                            SaleInvNoTo: false,
                            SaleRate: true,

                            ToCustomer: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            Export: true,
                          });
                          Show_DistributionReport_Modal();
                        }}
                      >
                        Sale Register{" "}
                      </Link>
                    </li>
                  )}
                  {SALE_RETURN_REGISTER && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/SaleReturnRegisterReport");
                          setReportTitle("Sale Return Register Report");
                          setTr_Type("SR");
                          setDistributionReportReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            ManufacturedBy: true,
                            FromItem: true,
                            City: true,
                            Area: true,
                            BatchNo: true,
                            Customer: true,
                            Delivery: true,
                            CGID: true,
                            SaleInvNoFrom: false,
                            SaleInvNoTo: false,
                            SaleRate: true,

                            ToCustomer: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            Export: true,
                          });
                          Show_DistributionReport_Modal();
                        }}
                      >
                        Sale Return Register{" "}
                      </Link>
                    </li>
                  )}
                  {CUSTOMER_WISE_SALE_REGISTER && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/CustomerWiseSAleRegisterReport");
                          setReportTitle("Customer-Wise Sale Register Report");
                          // setTr_Type('SR')
                          setDistributionReportReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            ManufacturedBy: false,
                            FromItem: true,
                            City: true,
                            Area: true,
                            BatchNo: true,
                            Customer: false,
                            Delivery: true,
                            CGID: false,
                            SaleInvNoFrom: true,
                            SaleInvNoTo: true,
                            SaleRate: true,

                            ToCustomer: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            Export: true,
                          });
                          Show_DistributionReport_Modal();
                        }}
                      >
                        Customer Wise Sale Register{" "}
                      </Link>
                    </li>
                  )}
                  {SALE_AND_STOCK_STATEMENT && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/SaleAndStockReport");
                          setReportTitle("Sale And Stock Report");
                          setDistributionReportReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            ManufacturedBy: false,
                            FromItem: true,
                            City: true,
                            Area: true,
                            BatchNo: true,
                            Customer: true,
                            Delivery: true,
                            CGID: false,
                            SaleInvNoFrom: true,
                            SaleInvNoTo: true,
                            SaleRate: false,
                            ToCustomer: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                          });
                          Show_DistributionReport_Modal();
                        }}
                      >
                        {" "}
                        Sale and Stock Report{" "}
                      </Link>
                    </li>
                  )}
                  {GROUPWISESALE && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/GroupWiseSale");
                          setReportTitle("Group Wise Sale");
                          setDistributionReportReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            ManufacturedBy: false,
                            FromItem: true,
                            City: true,
                            Area: true,
                            BatchNo: true,
                            Customer: true,
                            Delivery: true,
                            CGID: false,
                            SaleInvNoFrom: true,
                            SaleInvNoTo: true,
                            SaleRate: true,
                            ToCustomer: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: false,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                            Export: false,
                          });
                          Show_DistributionReport_Modal();
                        }}
                      >
                        Group Wise Sale{" "}
                      </Link>
                    </li>
                  )}
                  {MONTHWISESALE && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/SaleComparision");
                          Show_ComparisonReport_Modal();
                        }}
                      >
                        Month Wise Sale{" "}
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}
          {INVENTORY_REPORTS && (
            <li className="side-nav-item">
              <a
                data-bs-toggle="collapse"
                href="#sidebarIcons"
                aria-expanded="false"
                aria-controls="sidebarIcons"
                className="side-nav-link"
              >
                <i className="uil-streering" />
                <span> Inventory Reports </span>
                <span className="menu-arrow" />
              </a>
              <div className="collapse" id="sidebarIcons">
                <ul className="side-nav-second-level">
                  {STOCK_LEDGER && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/StockBalanceReport");
                          setReportTitle("Stock Ledger");
                          setSaleReport_ReadOnlyProp({
                            FromDate: true,
                            ToDate: true,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: true,
                            WareHouse: true,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                          });
                          Show_InventoryReport_Modal();
                        }}
                      >
                        Stock Ledgers
                      </Link>{" "}
                    </li>
                  )}

                  {STOCK_BALANCE_BATCH_WISE && (
                    <li>
                      {" "}
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/StockBatchWiseReport");
                          setReportTitle("Batch-Wise Stock");
                          setSaleReport_ReadOnlyProp({
                            FromDate: true,
                            ToDate: true,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: true,
                            WareHouse: true,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                          });
                          Show_InventoryReport_Modal();
                        }}
                      >
                        Stock Balance -Batch Wise
                      </Link>{" "}
                    </li>
                  )}

                  {STOCK_BALANCE_WAREHOUSE_WISE && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/StockWareHouseWiseReport");
                          setReportTitle("Warehouse-Wise Stock");
                          setSaleReport_ReadOnlyProp({
                            FromDate: true,
                            ToDate: true,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: false,
                            ToItem: false,
                            NoOfItems: true,
                            Supplier: true,
                            WareHouse: false,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                          });
                          Show_InventoryReport_Modal();
                        }}
                      >
                        Stock Balance -WareHouse Wise
                      </Link>
                    </li>
                  )}

                  {SHORT_INVENTORY_ITEMS && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/ShortInventoryItemsReport");
                          setReportTitle("Short Inventory Items");
                          setSaleReport_ReadOnlyProp({
                            FromDate: true,
                            ToDate: true,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: true,
                            WareHouse: true,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                          });
                          Show_InventoryReport_Modal();
                        }}
                      >
                        Short Inventory Items
                      </Link>
                    </li>
                  )}

                  {MAX_INVENTORY_ITEMS && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/MaxInventoryItemsReport");
                          setReportTitle("Max Inventory Items");
                          setSaleReport_ReadOnlyProp({
                            FromDate: true,
                            ToDate: true,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: true,
                            WareHouse: true,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                          });
                          Show_InventoryReport_Modal();
                        }}
                      >
                        Max Inventory Items
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}
          {EXPENSE_REPORTS && (
            <li className="side-nav-item">
              <a
                data-bs-toggle="collapse"
                href="#sidebarForms"
                aria-expanded="false"
                aria-controls="sidebarForms"
                className="side-nav-link"
              >
                <i className="uil-document-layout-center" />
                <span> Expense Reports </span>
                <span className="menu-arrow" />
              </a>
              <div className="collapse" id="sidebarForms">
                <ul className="side-nav-second-level">
                  {EXPENSES_REPORT && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/ExpenseReport");
                          setReportTitle("Expense");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: true,
                            WareHouse: true,
                            Classification: true,
                            E_type: true,
                          });
                          Show_ExpenseReport_Modal();
                        }}
                      >
                        Expenses
                      </Link>
                    </li>
                  )}

                  {EXPENSES_CLASSIFICATION_WISE && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/ExpenseClassificationReport");
                          setReportTitle("Expense Classification");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: true,
                            WareHouse: true,
                            Classification: false,
                            E_type: true,
                            Bank: false,
                          });
                          Show_ExpenseReport_Modal();
                        }}
                      >
                        Expense - ClassificationWise
                      </Link>
                    </li>
                  )}

                  {EXPENSES_TYPE_WISE && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/ExpenseTypeReport");
                          setReportTitle("Expense Type");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: true,
                            WareHouse: true,
                            Classification: true,
                            E_type: false,
                            Bank: false,
                          });
                          Show_ExpenseReport_Modal();
                        }}
                      >
                        Expense - TypeWise
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}
          {FINANCE_REPORTS && (
            <li className="side-nav-item">
              <a
                data-bs-toggle="collapse"
                href="#sidebarCharts"
                aria-expanded="false"
                aria-controls="sidebarCharts"
                className="side-nav-link"
              >
                <i className="uil-chart" />
                <span> Finance Reports </span>
                <span className="menu-arrow" />
              </a>
              <div className="collapse" id="sidebarCharts">
                <ul className="side-nav-second-level">
                  {CHASH_IN_HAND && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/CashInHandReport");
                          setReportTitle("Cash In Hand");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: true,
                            WareHouse: true,
                            Classification: true,
                            E_type: true,
                            Bank: true,
                          });
                          Show_FinanceReport_Modal();
                        }}
                      >
                        Cash In Hand
                      </Link>
                    </li>
                  )}

                  {CASH_IN_BANK && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/CashInBankReport");
                          setReportTitle("Cash In Bankt");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: true,
                            WareHouse: true,
                            Classification: false,
                            E_type: true,
                            Bank: false,
                          });
                          Show_FinanceReport_Modal();
                        }}
                      >
                        Cash In Bank
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}
          {PROFITANDLOSS_REPORTS && (
            <li className="side-nav-item">
              <a
                data-bs-toggle="collapse"
                href="#sidebarTables"
                aria-expanded="false"
                aria-controls="sidebarTables"
                className="side-nav-link"
              >
                <i className="uil-table" />
                <span> Profit & Loss </span>
                <span className="menu-arrow" />
              </a>
              <div className="collapse" id="sidebarTables">
                <ul className="side-nav-second-level">
                  {DAILY_ACTIVITIES && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/DailyActivityReport");
                          setReportTitle("Daily Activity");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: true,
                            WareHouse: true,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                          });
                          Show_PLReport_Modal();
                        }}
                      >
                        Daily Activity
                      </Link>
                    </li>
                  )}

                  {PROFIT_AND_LOSS && (
                    <li>
                      <Link
                        to="#"
                        onClick={(e) => {
                          setReportPath("/PLReport");
                          setReportTitle("Profit & Loss");
                          setSaleReport_ReadOnlyProp({
                            FromDate: false,
                            ToDate: false,
                            finYear: true,
                            Project: false,
                            Customer: true,
                            ToCustomer: true,
                            FromItem: true,
                            ToItem: true,
                            NoOfItems: true,
                            Supplier: true,
                            WareHouse: true,
                            Classification: false,
                            E_type: false,
                            Bank: false,
                          });
                          Show_PLReport_Modal();
                        }}
                      >
                        Profit &amp; Loss
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )}
          {/* <li className="side-nav-item">
        <a data-bs-toggle="collapse" href="#sidebarMaps" aria-expanded="false" aria-controls="sidebarMaps" className="side-nav-link">
          <i className="uil-location-point" />
          <span> Maps </span>
          <span className="menu-arrow" />
        </a>
        <div className="collapse" id="sidebarMaps">
          <ul className="side-nav-second-level">
            <li>
              <a href="maps-google.html">Google Maps</a>
            </li>
            <li>
              <a href="maps-vector.html">Vector Maps</a>
            </li>
          </ul>
        </div>
      </li>
      <li className="side-nav-item">
        <a data-bs-toggle="collapse" href="#sidebarMultiLevel" aria-expanded="false" aria-controls="sidebarMultiLevel" className="side-nav-link">
          <i className="uil-folder-plus" />
          <span> Multi Level </span>
          <span className="menu-arrow" />
        </a>
        <div className="collapse" id="sidebarMultiLevel">
          <ul className="side-nav-second-level">
            <li className="side-nav-item">
              <a data-bs-toggle="collapse" href="#sidebarSecondLevel" aria-expanded="false" aria-controls="sidebarSecondLevel">
                <span> Second Level </span>
                <span className="menu-arrow" />
              </a>
              <div className="collapse" id="sidebarSecondLevel">
                <ul className="side-nav-third-level">
                  <li>
                    <a href="javascript: void(0);">Item 1</a>
                  </li>
                  <li>
                    <a href="javascript: void(0);">Item 2</a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="side-nav-item">
              <a data-bs-toggle="collapse" href="#sidebarThirdLevel" aria-expanded="false" aria-controls="sidebarThirdLevel">
                <span> Third Level </span>
                <span className="menu-arrow" />
              </a>
              <div className="collapse" id="sidebarThirdLevel">
                <ul className="side-nav-third-level">
                  <li>
                    <a href="javascript: void(0);">Item 1</a>
                  </li>
                  <li className="side-nav-item">
                    <a data-bs-toggle="collapse" href="#sidebarFourthLevel" aria-expanded="false" aria-controls="sidebarFourthLevel">
                      <span> Item 2 </span>
                      <span className="menu-arrow" />
                    </a>
                    <div className="collapse" id="sidebarFourthLevel">
                      <ul className="side-nav-forth-level">
                        <li>
                          <a href="javascript: void(0);">Item 2.1</a>
                        </li>
                        <li>
                          <a href="javascript: void(0);">Item 2.2</a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </li> */}
        </ul>
        {/* Help Box */}
        {/* <div className="help-box text-white text-center">
      <a href="javascript: void(0);" className="float-end close-btn text-white">
        <i className="mdi mdi-close" />
      </a>
      <img src="assets/images/help-icon.svg" height={90} alt="Helper Icon Image" />
      <h5 className="mt-3">Unlimited Access</h5>
      <p className="mb-3">Upgrade to plan to get access to unlimited reports</p>
      <a href="javascript: void(0);" className="btn btn-outline-light btn-sm">Upgrade</a>
    </div> */}
        {/* end Help Box */}
        {/* End Sidebar */}
        <div className="clearfix" />
      </div>
      <SaleReport_Modal
        show={ShowSaleReport_modal}
        onHide={Hide_SaleReport_Modal}
        toPath={ReportPath}
        readonlyProps={SaleReport_ReadOnlyProp}
        title={ReportTitle}
      />

      <PurchaseReport_Modal
        show={ShowPurchaseReport_modal}
        onHide={Hide_PurchaseReport_Modal}
        toPath={ReportPath}
        readonlyProps={SaleReport_ReadOnlyProp}
        title={ReportTitle}
      />
      <InventoryReport_Modal
        show={ShowInventoryReport_modal}
        onHide={Hide_InventoryReport_Modal}
        toPath={ReportPath}
        readonlyProps={SaleReport_ReadOnlyProp}
        title={ReportTitle}
      />
      <PLReports_Modal
        show={ShowPLReport_modal}
        onHide={Hide_PLReport_Modal}
        toPath={ReportPath}
        readonlyProps={SaleReport_ReadOnlyProp}
        title={ReportTitle}
      />
      <ExpenseReport_Modal
        show={ShowExpenseReport_modal}
        onHide={Hide_ExpenseReport_Modal}
        toPath={ReportPath}
        readonlyProps={SaleReport_ReadOnlyProp}
        title={ReportTitle}
      />
      <FinanceReport_Modal
        show={ShowFinanceReport_modal}
        onHide={Hide_FinanceReport_Modal}
        toPath={ReportPath}
        readonlyProps={SaleReport_ReadOnlyProp}
        title={ReportTitle}
      />
      <DistributionReport_Modal
        show={ShowDistributionReport_modal}
        onHide={Hide_DistributionReport_Modal}
        toPath={ReportPath}
        readonlyProps={DistributionReportReadOnlyProp}
        title={ReportTitle}
        mTr_Type={Tr_Type}
      />
      <DSRFiles show={DSRFilesModal} onHide={hide_Modal} />
      <TaxReportsModal
        show={ShowTaxReport_modal}
        onHide={Hide_TaxReport_Modal}
        toPath={ReportPath}
        readonlyProps={TaxReport_ReadOnlyProp}
        title={ReportTitle}
      />
      <ComparisionReports_Modal
        show={ShowComparisionReport_modal}
        onHide={Hide_ComparisonReport_Modal}
        toPath={ReportPath}
      />
      <Loader show={IsLoader} hide={hideLoader} />
    </>,
    document.getElementById("leftside")
  );
}

const mapStateToProps = (state) => ({
  Comp_Id:
    state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Comp_Name:
    state.GlobalVariables.Company && state.GlobalVariables.Company.Name,
  Comp_Address:
    state.GlobalVariables.Company && state.GlobalVariables.Company.Address,
  User_Id: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
  User_Info: state.Login_User && state.Login_User.User_Data,
  Group_Id: state.Login_User.User_Data && state.Login_User.User_Data.Group_Id,
  Global_Project_List:
    state.GlobalProjectsList && state.GlobalProjectsList.GlobalProjectsList,
  Global_Selected_Project:
    state.Global_Projects && state.Global_Projects.GlobalProjects,
  ProjectID:
    state.Global_Projects.GlobalProjects &&
    state.Global_Projects.GlobalProjects.Project_ID,
});
export default connect(mapStateToProps, { set_ClaimPolicyType })(Sidebar);
