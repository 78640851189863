import React, { useState, useEffect, Suspense, useRef} from 'react'
import  ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { Row, Col, Card ,Spinner } from "react-bootstrap"
import { Cancel, Save, Print } from '@material-ui/icons'
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { connect } from 'react-redux'
import {SelectManufacturedByList} from '../../Services/manufactureAPI'
import { SelectCompanyGroupAgainstManufacturer } from '../../Services/CompanyGroupAPI'
import { SelectProductGroupAgainstManufacturer } from '../../Services/ProductGroupAPI'
import { SaveMailData } from '../../Services/AutoMailAPI'
import AutoMailerList from './AutoMailerList'
import { SelectAutoMailWRTMailDataID } from '../../Services/AutoMailAPI'
import Loader from '../Loader'
import Swal from 'sweetalert2'




const AutoMailer = ({Comp_Id, ProjectID}) => {
    const [ManufacturedBy_List, setManufacturedBy_List] = useState([])
    const [SelectedManufacturedBy_ListOption, setSelectedManufacturedBy_ListOption] = useState(null)
    const [CG_List, setCG_List] = useState([])
    const [SelectedCG, setSelectedCG] = useState(null)
    const [PG_List, setPG_List] = useState([])
    const [SelectedPG, setSelectedPG] = useState(null)
    const [IsCustomerLedger, setIsCustomerLedger] = useState(true)
    const [IsCGLedger, setIsCGLedger] = useState(true)
    const [IsSaleRegister, setIsSaleRegister] = useState(true)
    const [IsSaleReturnRegister, setIsSaleReturnRegister] = useState(true)
    const [IsTownwiseSale, setIsTownwiseSale] = useState(true)
    const [IsAreawiseSale, setIsAreawiseSale] = useState(true)
    const [Email, setEmail] = useState('')
    const [Subject, setSubject] = useState('')
    const [Body, setBody] = useState('')
    const [MailDataID, setMailDataID] = useState(0)
    const [btnDisabled, setbtnDisabled] = useState(false)
    const [IsLoader, setIsLoader] = useState(false)
    const [Errors, setErrors] = useState({
       ManufacturedBy: '',
       CGName: '',
       PGName: '',
       email: '' 
    })
   const [RefreshedChild, setRefreshedChild] = useState(false)
    useEffect(()=>{
        showLoader()
        SelectManufacturedByList(Comp_Id, ProjectID,(mManufacture)=>{
            hideLoader()
           if(mManufacture){
            setManufacturedBy_List(mManufacture)
           } 
        })
    }, [])
    const Reste_Fields = () =>{
        setSelectedManufacturedBy_ListOption(null)
        setSelectedCG(null)
        setSelectedPG(null)
        setIsCustomerLedger(true)
        setIsCGLedger(true)
        setIsSaleRegister(true)
        setIsSaleReturnRegister(true)
        setIsTownwiseSale(true)
        setIsAreawiseSale(true)
        setEmail('')
        setSubject('')
        setBody('')
        setMailDataID(0)
    }
    const validationSchema = yup.object().shape({
        ManufacturedBy: yup.string().required('Company is Required'),
        CGName: yup.string().required('Company Group is Required'),
        PGName: yup.string().required('Product Group is Required'),
        email: yup.string().email('Invalid email').required('Email is required'), 
    })
    const showLoader = () => {
       setIsLoader(true)
      }
     const  hideLoader = () => {
        setIsLoader(false)
      }
    //   const SelectMailData = () => {
    //     debugger
    //     if (childRef.current) {
    //         debugger
    //       childRef.current.SelectMailData();
    //     }
    //   };
    const SaveHandler = () =>{
        const mailObj = {
            MailDataID: MailDataID,
            ManufacturedBy: SelectedManufacturedBy_ListOption && SelectedManufacturedBy_ListOption.ManufacturedBy,
            ManufacturedByID : SelectedManufacturedBy_ListOption && SelectedManufacturedBy_ListOption.ManufacturedByID,
            CGName: SelectedCG && SelectedCG.CGName,
            CGID: SelectedCG && SelectedCG.CGID,
            PGName:SelectedPG && SelectedPG.PGName,
            PGID:SelectedPG && SelectedPG.PGID,
            email: Email,
            Subject: Subject,
            Body: Body,
            IsCustomerLedger: IsCustomerLedger,
            IsCGLedger: IsCGLedger,
            IsSaleRegister: IsSaleRegister,
            IsSaleReturnRegister: IsSaleReturnRegister,
            IsTownwiseSale: IsTownwiseSale,
            IsAreawiseSale: IsAreawiseSale,
            Comp_Id: Comp_Id,
            ProjectID: ProjectID,
        }
        validationSchema.validate(mailObj, {abortEarly: false})
        .then(()=>{
            setErrors({
                ManufacturedBy: '',
                CGName: '',
                PGName: '',
                email: ''  
            })
            setbtnDisabled(true)
            SaveMailData(mailObj, (mRes)=>{
                setbtnDisabled(false)
                if(mRes.err){
                    Swal.fire({
                        icon: 'error',
                        text: mRes.message,
                        title: 'Error'
                    })
                }
                setRefreshedChild(!RefreshedChild)
                // SelectMailData()
                Reste_Fields()


            })
        }).catch((validationErrors)=>{
            const newErrors = {}
            validationErrors.inner.forEach((error)=>{
                newErrors[error.path] = error.message
            })
            setErrors(newErrors)
        })
    }
    const handleChildId = (id) => {
        setMailDataID(id);
        showLoader()
        SelectAutoMailWRTMailDataID(id, (mData)=>{
            hideLoader()
            ManufacturedBy_List.map((x)=>{
                if(x.ManufacturedByID === mData[0].ManufacturedByID){
                    setSelectedManufacturedBy_ListOption(x)
                }
            })
            SelectCompanyGroupAgainstManufacturer(Comp_Id,ProjectID,mData[0].ManufacturedByID,(data)=>{
                var Selected_CG = data.find(x=> x.CGID === mData[0].CGID)
                setSelectedCG(Selected_CG)
                setCG_List(data)
            })
            SelectProductGroupAgainstManufacturer(Comp_Id,ProjectID,mData[0].ManufacturedByID,(PGdata)=>{
                var Selected_PG = PGdata.find(x=> x.PGID === mData[0].PGID)
                setSelectedPG(Selected_PG)
                setPG_List(PGdata)
            })
            setIsCustomerLedger(mData[0].CustomerLedger)
            setIsCGLedger(mData[0].CompanyGroupLedger)
            setIsSaleRegister(mData[0].SaleRegister)
            setIsSaleReturnRegister(mData[0].SaleReturnRegister)
            setIsTownwiseSale(mData[0].TownWiseSale)
            setIsAreawiseSale(mData[0].AreawiseSale)
            setEmail(mData[0].email)
            setSubject(mData[0].subject)
            setBody(mData[0].bodyText)
        })
      };
 
  return ReactDOM.createPortal(
    <>
    <Row className="row_margin_top row_margin_left row_margin_right" >
    <Col lg={6} xl={6} md={6} className="header_title" >
        <h2>Auto Mailer </h2>
        <h5 className='text-danger'>Set Mail details system will automatically send emails</h5>
    </Col>
</Row>
<Card>
        <Row className="row_margin_top row_margin_left row_margin_right m-2" >
        <Col lg={4} xl={4} md={4}>
        <Autocomplete
                    name="ManufacturedBy"
                    id="ManufacturedBy"
                    options={ManufacturedBy_List}
                    getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""} 
                    value={SelectedManufacturedBy_ListOption}
                    onChange={(e,value) =>{
                    if(value){
                        setSelectedManufacturedBy_ListOption(value)
                        showLoader()
                        SelectCompanyGroupAgainstManufacturer(Comp_Id,ProjectID,value.ManufacturedByID,(mData)=>{
                            hideLoader()
                            setCG_List(mData)
                        })
                        showLoader()
                        SelectProductGroupAgainstManufacturer(Comp_Id,ProjectID,value.ManufacturedByID,(mData)=>{
                            hideLoader()
                            setPG_List(mData)
                        })
                      
                    }
                    else{
                        setSelectedManufacturedBy_ListOption(null)
                        setCG_List([])
                        setSelectedCG(null)
                        setPG_List([])
                        setSelectedPG(null)
                       
                    }
                        
                    }}
                    renderInput={(params) => 
                    <TextField {...params} name="ManufacturedBy" label="ManufacturedBy"
                    />}
                />
               <span className='text-danger'>{Errors.ManufacturedBy}</span>
        </Col>
        <Col lg={4} xl={4} md={4}>
        <Autocomplete
        name="CG"
        id="CG"
        options={CG_List}
        getOptionLabel={(option) => option.CGName ? option.CGName : ""} 
        value={SelectedCG}
        onChange={(e,value) =>{
        if(value){
           
            setSelectedCG(value)
        }
        else{
            setSelectedCG(null)
           
        }
            
        }}
        renderInput={(params) => 
        <TextField {...params} name="CG" label="Company Group"
        />}
    />
    <span className='text-danger'>{Errors.CGName}</span>       
        </Col>
        <Col lg={4} xl={4} md={4}>
        <Autocomplete
        name="PG"
        id="PG"
        options={PG_List}
        getOptionLabel={(option) => option.PGName ? option.PGName : ""} 
        value={SelectedPG}
        onChange={(e,value) =>{
        if(value)
        setSelectedPG(value)
        else
        setSelectedPG(null)
            
        }}
        renderInput={(params) => 
        <TextField {...params} name="PG" label="Product Group"
        />}
    />
    <span className='text-danger'>{Errors.PGName}</span>    
        </Col>
        <Col lg={4} xl={4} md={4} >
        <TextField
            name="Email"
            id="Email"
            label="Email"
            value={Email}
            onChange={e => setEmail(e.target.value)}
            fullWidth
        />
        <span className='text-danger'>{Errors.email}</span>
    </Col>
    <Col lg={4} xl={4} md={4} >
        <TextField
            name="Subject"
            id="Subject"
            label="Subject"
            value={Subject}
            onChange={e => setSubject(e.target.value)}
            fullWidth
        />
    </Col>
    <Col lg={4} xl={4} md={4} >
    <TextField
        name="BodyText"
        id="BodyText"
        label="Body Text"
        value={Body}
        onChange={e => setBody(e.target.value)}
        fullWidth
    />
</Col>
<Col lg={2} xl={2} md={2} className={"p-0"} >
<FormControlLabel
    control={
        <Checkbox
            checked={IsCustomerLedger}
            id="IsCustomer"
            label="IsCustomer"
            onChange={e => { setIsCustomerLedger(e.target.checked)}}
          
            name="Access"
            color="primary"
        />
    }
    label={"Customer Ledger"}
    style={{
        padding: "10px",
        marginTop: "10%"
    }}
/>

</Col> 
<Col lg={2} xl={2} md={2} className={"p-0"} >
<FormControlLabel
    control={
        <Checkbox
            checked={IsCGLedger}
            id="IsCompanyGroupLedger"
            label="IsCompanyGroupLedger"
            onChange={e => {setIsCGLedger(e.target.checked)}}
          
            name="Access"
            color="primary"
        />
    }
    label={"Company Group Ledger"}
    style={{
        padding: "10px",
        marginTop: "10%"
    }}
/>

</Col>  
<Col lg={2} xl={2} md={2} className={"p-0"} >
<FormControlLabel
    control={
        <Checkbox
            checked={IsSaleRegister}
            id="IsSaleRegister"
            label="IsSaleRegister"
            onChange={e => {setIsSaleRegister(e.target.checked)}}
          
            name="Access"
            color="primary"
        />
    }
    label={"Sale Register"}
    style={{
        padding: "10px",
        marginTop: "10%"
    }}
/>

</Col>   
<Col lg={2} xl={2} md={2} className={"p-0"} >
<FormControlLabel
    control={
        <Checkbox
            checked={IsSaleReturnRegister}
            id="IsSaleReturnRegister"
            label="IsSaleReturnRegister"
            onChange={e => {setIsSaleReturnRegister(e.target.checked)}}
          
            name="Access"
            color="primary"
        />
    }
    label={"Sale Return Register"}
    style={{
        padding: "10px",
        marginTop: "10%"
    }}
/>

</Col>
<Col lg={2} xl={2} md={2} className={"p-0"} >
<FormControlLabel
    control={
        <Checkbox
            checked={IsTownwiseSale}
            id="IsTownwise"
            label="IsTownwise"
            onChange={e => {setIsTownwiseSale(e.target.checked)}}
          
            name="Access"
            color="primary"
        />
    }
    label={"Town wise sale"}
    style={{
        padding: "10px",
        marginTop: "10%"
    }}
/>

</Col> 
<Col lg={2} xl={2} md={2} className={"p-0"} >
<FormControlLabel
    control={
        <Checkbox
            checked={IsAreawiseSale}
            id="IsAreawise"
            label="IsAreawise"
            onChange={e => {setIsAreawiseSale(e.target.checked)}}
          
            name="Access"
            color="primary"
        />
    }
    label={"Area wise sale"}
    style={{
        padding: "10px",
        marginTop: "10%"
    }}
/>

</Col>             
 </Row>
        <Row>
        <Col style={{ textAlign: "right" }}>
                <button type='button' 
                className='btn btn-primary rounded-pill m-3 float-right' 
                style={{width: '150px'}} 
                disabled={btnDisabled}
                onClick={SaveHandler}>
                {
                    btnDisabled ? 
                    (<><Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        /> Saving... </>) : <><Save /> Save</>
                }
                
                </button>
                <button type='button' 
                className='btn btn-primary rounded-pill mt-3 float-right' 
                style={{width: '150px'}}
                onClick={Reste_Fields}
                >
                Clear Fields
                </button>
        </Col>
           
        </Row>
        <Loader
        show={IsLoader}
        hide={hideLoader}
        />
</Card>
<Card>
<Row className="row_margin_top row_margin_left row_margin_right" >
<Col lg={6} xl={6} md={6} className="header_title" >
    <h2>Data View</h2>
</Col>
</Row>
<Row className="row_margin_top row_margin_left row_margin_right" >
<Col lg={12} xl={12} md={12} className="header_title" >
<Suspense fallback={ <Loader
    show={IsLoader}
    hide={hideLoader}
    />}>
<AutoMailerList sendIdToParent={handleChildId} RefreshedChild = {RefreshedChild}  />
</Suspense>
    
</Col>
</Row>
</Card>
    </>, document.getElementById('PageContents')
    
  )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    isAddNew:state.Items && state.Items.isAddNew,
    ItemID: state.Items && state.Items.ItemID,
    UserID: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    show_screen: state.SCREEN,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

  })
  export default connect(mapStateToProps)(AutoMailer)
