import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectSaleInvoiceList =(Comp_Id,ProjectID,Tr_Type,Month,Year, InvoiceType,Callback) =>{
  axios.get(`${domain}/SaleInvoice/SelectSaleInvoiceList/${Comp_Id}/${ProjectID}/${Tr_Type}/${Month}/${Year}/${InvoiceType}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const GetMaxSaleInvoiceNo =(Comp_Id,ProjectID,Tr_Type,InvoiceType,Callback) =>{
  
  axios.get(`${domain}/SaleInvoice/GetMaxSaleInvoiceNo/${Comp_Id}/${ProjectID}/${Tr_Type}/${InvoiceType}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectSaleInvoiceMaster =(Comp_Id,ProjectID,SaleInvoiceID,InvoiceType,Callback) =>{
  axios.get(`${domain}/SaleInvoice/SelectSaleInvoiceMaster/${Comp_Id}/${ProjectID}/${SaleInvoiceID}/${InvoiceType}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectSaleInvoiceDetail =(Comp_Id,ProjectID,SaleInvoiceID,InvoiceType,Callback) =>{
    axios.get(`${domain}/SaleInvoice/SelectSaleInvoiceDetail/${Comp_Id}/${ProjectID}/${SaleInvoiceID}/${InvoiceType}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
export const SaveSaleInvoice = (SaleInvoiceMasterData,SaleInvoiceDetailData,DeletedSaleInvoiceData,isAddNew,callback) =>{
  axios.post(`${domain}/SaleInvoice/SaveSaleInvoice`,{
    SaleInvoiceMasterData,
    SaleInvoiceDetailData,
    DeletedSaleInvoiceData,
     isAddNew
  })
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback(response.data)
      })
      .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
}
export const SelectItemsWRTSO =(Comp_Id,ProjectID,CustomerID,SaleOrderNo,Callback) =>{
  axios.get(`${domain}/SaleInvoice/SelectItemsWRTSO/${Comp_Id}/${ProjectID}/${CustomerID}/${SaleOrderNo}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectItemsForReturn = (Comp_Id, Project_Id, InvoiceNo, Tr_Type, callback) =>{
  axios.get(`${domain}/SaleInvoice/SelectItemsForReturn/${Comp_Id}/${Project_Id}/${InvoiceNo}/${Tr_Type}`)
       .then(response=>{
          callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectItemsAgainstBooking = (Comp_Id, Project_Id, OBID,  callback) =>{
  axios.get(`${domain}/SaleInvoice/SelectItemsAgainstBooking/${Comp_Id}/${Project_Id}/${OBID}`)
       .then(response=>{
          callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectInvoiceForDelivery = (Comp_Id, Project_Id,CityID,  callback) =>{
  axios.get(`${domain}/SaleInvoice/SelectInvoiceForDelivery/${Comp_Id}/${Project_Id}/${CityID}`)
       .then(response=>{
          callback(response.data.Data)
       })
       .catch(err =>{
         debugger
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}


export const SelectrptSaleInvoiceMaster =(SaleInvoiceID,InvoiceType,Callback) =>{
  axios.get(`${domain}/SaleInvoice/SelectrptSaleInvoiceMaster/${SaleInvoiceID}/${InvoiceType}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectInvoiceDataForDSRSafron =(FromDate,ToDate,Comp_Id,ProjectID,ManufacturedByID,Callback) =>{
  axios.get(`${domain}/SaleInvoice/SelectInvoiceDataForDSRSafron/${FromDate}/${ToDate}/${Comp_Id}/${ProjectID}/${ManufacturedByID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectSaleInvoiceMasterWRTCode =(Comp_Id,ProjectID, SaleInvoiceNo,Callback) =>{
  axios.get(`${domain}/SaleInvoice/SelectSaleInvoiceMasterWRTCode/${Comp_Id}/${ProjectID}/${SaleInvoiceNo}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectSaleInvoiceDetailWRTCode =(Comp_Id,ProjectID, SaleInvoiceID,Callback) =>{
  axios.get(`${domain}/SaleInvoice/SelectSaleInvoiceDetailWRTCode/${Comp_Id}/${ProjectID}/${SaleInvoiceID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}