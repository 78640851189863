import React, {useEffect, useState} from 'react'
 import './bill.css'
import { GetCurrentDate } from '../../Services/Common'
import { connect } from 'react-redux'
import logo from './lordstech.png'
import { GetSoftwareInvoice } from '../../Services/LoginAPI'

const SoftwareInvoice = ({ Comp_Name, Comp_Address,Comp_Id, ProjectID}) => {
  const [InvoiceData, setInvoiceData] = useState([])
  const [Total, setTotal] = useState(0.00)
    useEffect(() => {
      document.title = 'Software Invoice'
      document.getElementById('topBar').hidden = true;
      document.getElementById('leftside').hidden = true;
      document.getElementById('footer').hidden = true;
      document.getElementById('content_Page').hidden = true;
      document.getElementById('RSidebar').hidden = true;
      let sum = 0;
      GetSoftwareInvoice(Comp_Id, ProjectID, (mData)=>{
        setInvoiceData(mData);
        mData.map(val=>{
          sum+= val.Rate
        })
        setTotal(sum)
      })

    }, [])
  return (
   
   <div className="bill-report container" style={{color: 'black'}}>
   
 {/* <div className="header">
<div className="row">
      <div className='col-md-3 col-sm-3'>
      <div className="logo">
      <img src={logo} alt="Company Logo"  style ={{width: 100, height: 100, paddingTop:'20px', marginTop: '30px'}}/>
    </div>
      </div>
      <div className='col-md-1 col-sm-1'></div>
      <div className='col-md-8 col-sm-8'>
      <div className="address">
      <p>12km Lahore Road, Layyah</p>
      <p>Layyah, Punjab 31200</p>
      <p>Phone: 03003203186</p>
    </div>
      </div>
     
     
</div>
<div className='col-md-3 col-sm-3'>
<p>Date: {GetCurrentDate()}</p>
<h2 style={{color:'#000080'}}>Invoice</h2>

</div>
   
    
   
  </div>*/}
  <div className="header">
  <div className="row">
    <div className="col-md-4 col-sm-4">
      <div className="logo">
        <img src={logo} alt="Company Logo" style={{ width: 100, height: 100, paddingTop: '20px', marginTop: '30px' }} />
      </div>
    </div>
    
    <div className="col-md-8 col-sm-8" >
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="address" style={{marginLeft:'40px'}}>
            <p>12km Lahore Road, Layyah</p>
            <p>03003203186</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="row">
    <div className="col-md-12 col-sm-12">
      <p>Date: {GetCurrentDate()}</p>
      <h2 style={{ color: '#000080' }}>Invoice</h2>
    </div>
  </div>
</div>

  <div className="bill-to">
    <h4 style={{ color: '#000080' }}>Bill To:</h4>
    <p>{ Comp_Name}</p>
    <p>{Comp_Address}</p>
  </div>
  <table style={{   borderCollapse: 'collapse',
    width: '100%',
    marginTop: '40px'}}>
    
    <thead>
      <tr className='trr'>
        <th className='the'>Description</th>
        <th className='the'>Month</th>
        <th className='the'> Unit Price</th>
      </tr>
    </thead>
    <tbody>
    {
      
      InvoiceData.map(val=>(
        <>
        <tr className='trr'>
        <td className='tde'>{val.Discription}</td>
        <td className='tde'>{val.MonthYear}</td>
        <td className='tde'>{val.Rate}</td>
      </tr>
        </>
      ))
    }
    
    </tbody>
    <tfoot>
      <tr className='trr'>
        <td className='tde' colSpan={2}>Total:</td>
        <td className='tde'>{Total}</td>
      </tr>
    </tfoot>
  </table>
</div>


  )
}
const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
  Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
  CustomerID: state.Configuration && state.Configuration.CustomerID,
  show_screen: state.SCREEN,
  ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

})
export default connect(mapStateToProps)(SoftwareInvoice)
// export default SoftwareInvoice