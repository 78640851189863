import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "./Table.css"
import { rptSaleReturnLedgerCustomerWise } from '../../../Services/Sale_ReportAPI'

function SaleReturnLedgerCustWise({Comp_Name,Comp_Id,User_Name,ConfigObj}) {
    
    const { ProjectID,FromDate,ToDate } = useParams();
    const [CustomerData, setCustomerData] = useState([])
    const [TotalBalance, setTotalBalance] = useState(0)
    const [TotalPaid, setTotalPaid] = useState(0)
    const [GrandTotal, setGrandTotal] = useState(0)

    useEffect(() => {
        document.title = "Distribution / Sale Return Ledger Customer Wise"
        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('footer').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;

        rptSaleReturnLedgerCustomerWise(Comp_Id,ProjectID,FromDate,ToDate,(mData)=>{
            var Totals = mData.splice(-1);
            if(Totals[0].Balance !== null){
                // setTotalBalance(Totals[0].Balance.split('-')[1])
                setTotalBalance(Totals[0].Balance)
            }
                
            
            if(Totals[0].PaidAmt !== null){
                // setTotalPaid(Totals[0].PaidAmt.split('-')[1])
                setTotalPaid(Totals[0].PaidAmt)

            }

            if(Totals[0].PaidAmt !== null){
                // setGrandTotal(Totals[0].GrandTotal.split('-')[1])
                setGrandTotal(Totals[0].GrandTotal)

            }

            setCustomerData(mData)
        })
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
            <Row  style={{ textAlign: 'center', color: "black" }}>
            <Col sm={3} md={3} xl={3}><img src={ConfigObj.Logo} width="100" height="100" /></Col>
            <Col sm={6} md={6} xl={6}>
                    <h2 > {Comp_Name} </h2> <br /> <h3>Sale Return Ledger Customer Wise</h3>
                </Col>
                </Row>
                <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left' }} >
                    <Row >
                        <Col lg={3} md={3} xl={3} style={{ borderLeft: '5px solid black', height: '20px', position: 'absolute', left: '10%' }}>
                            <b>From Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} style={{marginLeft:'26%'}}>
                            {ReverseDate(FromDate)}
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'right' }}>
                     <Row >
                            <Col lg={3} md={3} xl={3} style={{ borderLeft: '5px solid black', height: '20px',left:'60%',textAlign:'left'}}>
                            <b>To Date:</b>
                            </Col>
                            <Col lg={9} md={9} xl={9} style={{marginLeft:'-8%'}}>
                            {ReverseDate(ToDate)}
                            </Col>
                    </Row>
                </Col>
            </Row>
            
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}   >Date</th>
                            <th style={{ width: '5%', textAlign: 'center', fontWeight: "bold" }} >TNo.</th>
                            <th style={{ width: '2%', textAlign: 'center', fontWeight: "bold" }} >Type</th>
                            <th style={{ width: '15%', textAlign: 'center', fontWeight: "bold" }} >PaymentType</th>
                            <th style={{ width: '15%', textAlign: 'center', fontWeight: "bold" }} >CustomerName</th>
                            <th style={{ width: '23%', textAlign: 'left', fontWeight: "bold" }} >Narration</th>
                            <th style={{ width: '10%', textAlign: 'right', fontWeight: "bold" }} >GrandTotal</th>
                            <th style={{ width: '10%', textAlign: 'right', fontWeight: "bold" }} >PaidAmt</th>
                            <th style={{ width: '10%', textAlign: 'right', fontWeight: "bold",borderTop:"2px solid #000" }} >Balance</th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                                
                        {
                            CustomerData.map((customerdata, index) => (
                                <>
                                    <tr key={index}>
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{customerdata.Transaction_Date || customerdata.Transaction_Date != '' && (((customerdata.Transaction_Date).split('-')).reverse()).join('-')}</td>
                                        <td style={{ width: '5%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.TransactionNo === "" ? "-" : customerdata.TransactionNo}</td>
                                        <td style={{ width: '2%', textAlign: 'Center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.TransactionType}</td>
                                        <td style={{ width: '15%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {customerdata.PaymentType} </td>
                                        <td style={{ width: '15%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {customerdata.CustomerName} </td>
                                        <td style={{ width: '23%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.Discription}</td>
                                        <td style={{ width: '10%', textAlign: 'right', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.GrandTotal}</td>
                                        <td style={{ width: '10%', textAlign: 'right', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.PaidAmt} </td>
                                        <td style={{ width: '10%', textAlign: 'right', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{customerdata.Balance}</td>
                                 </tr>
                 
                                 </>
                            ))

                        } 
                        <tr id='footer_balance'>
                            <td id='footer_td_cb' colSpan="6" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                            <td id='' colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Grand Total:</td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  >{GrandTotal}</td>
                        </tr>
                        <tr >
                            <td id='footer_td_cb' colSpan="6" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                            <td id='footer_td' colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  >Total Paid:</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  > {TotalPaid}</td>
                        </tr>
                        <tr >
                            <td id='footer_td_cb' colSpan="6" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  > </td>
                            <td id='footer_td' colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Balance:</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  > {TotalBalance}</td>
                        </tr>
                    </tbody>


                </table>
                {/* </div> */}

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
         </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig

})

export default connect (mapStateToProps)(SaleReturnLedgerCustWise)
