import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Row, Col, Card, Button } from 'react-bootstrap'
import { Add, Edit, Print } from '@material-ui/icons'
import  { connect } from 'react-redux'
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { SelectCustomersForExcel , SelectCustomers} from '../../Services/CustomersAPI'
import  { set_Customer, SHOW_SCREEN } from '../../React_Redux/Actions'
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import Loader from '../Loader';
import { File_Download, GetCurrentDate } from '../../Services/Common';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

function CustomerList(props) {

    const {  set_Customer,ItemCat,Comp_Id ,ProjectID, Group_Id, SHOW_SCREEN } = props;
    const [CustomerList_Data, setCustomerList_Data] = useState([])
    const [Excel_Data, setExcel_Data] = useState([])
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [IsLoader, setIsLoader] = useState(false)
    
    useEffect(()=>{
    document.title = " Distribution / Customers"
 
    SelectGroupScreenAccess(Group_Id,11,93,(data)=>{
            
      setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
      setBtn_delete(parseInt(data.Del) === 0 ? false : true)

      SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
      parseInt(data.Edit) === 0 ? false : true,
      parseInt(data.Del) === 0 ? false : true,
      data.OtherAccess
      )
      
  })
Select_customersList()


    },[])
 
   
      // const exportToCSV = ( fileName) => {
      //   debugger
      //   SelectCustomers(Comp_Id,ProjectID,(apiData)=>{
      //     const ws = XLSX.utils.json_to_sheet(apiData);
      //     const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      //     const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      //     const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
      //     FileSaver.saveAs(data, fileName +  ".xlsx");
      //   })
      
      
      // }
  
    const column_Customers = [
        {
         name: "CustomerID",
         label: "Customer ID",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
        {
            name: "CustomerCode",
            label: "Customer Code",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
        {
         name: "CustomerName",
         label: "Customer Name",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
          name: "Address",
          label: "Address",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
             return(
               <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
             )
            },
          }
         },
        {
         name: "PhoneNo",
         label: "Phone No",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },
         }
        },
         {
          name: "action",
          label: "action",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) => {
             
             var CustomerID = CustomerList_Data
             if (CustomerID[rowIndex.rowIndex] != null)
               return (
                 <Link to={"/CustomerEntry"} onClick={e => edit_Customer(CustomerList_Data[rowIndex.rowIndex].CustomerID)}> <Edit /></Link>
               );
           }
          }
         }
    ];
    const edit_Customer = (CustomerID) =>{
       set_Customer(CustomerID,false)
    }
    const add_Customer = () =>{
       set_Customer(0,true)
    }
 
   const Select_customersList = () =>{
    showLoader()
    SelectCustomers(Comp_Id,ProjectID,(mData)=>{
      debugger
    hideLoader()
        setCustomerList_Data(mData)
    })
   }
  
   const showLoader = () => {
    setIsLoader(true)
  }
  const  hideLoader = () => {
    setIsLoader(false)
    }
  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });


    return ReactDOM.createPortal(
      <>
      <table id="table" align = "center" border="1px" hidden></table>
       <Row className="row_margin_top row_margin_left row_margin_right" >
            <Col lg={6} xl={6} md={6} className="header_title" >
              <h2> Customers</h2>
            </Col>
          </Row>
      <Card>
         
          <Row className="row_margin_top row_margin_left row_margin_right mt-4 mr-4 ml-4 mb-1" >
            <Col  style={{ textAlign: "right" }} >
              { btn_add &&(<Link type="button" className="btn btn-primary rounded-pill" to="/CustomerEntry" onClick={add_Customer} style={{ border: "none" }} > <Add />  Create New</Link>)}
              <Link type="button" className="btn btn-primary rounded-pill" to="/CustomersReport" target={"_blank"} onClick={e=>{
                localStorage.setItem('token',sessionStorage.getItem('token'))
                    localStorage.setItem("CustomersData",JSON.stringify(CustomerList_Data))
                  }} style={{ border: "none" }} > <Print />  Print</Link>
                  <Button  type="button" className="btn btn-primary rounded-pill" onClick={e=>{
                    SelectCustomersForExcel(Comp_Id,ProjectID,(apiData)=>{
                      const ws = XLSX.utils.json_to_sheet(apiData);
                      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                      const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
                      FileSaver.saveAs(data, `Customers - ${GetCurrentDate()}`  +  ".xlsx");
                    })
                  
                  }} >Download To Excel</Button>
            </Col>
          
          </Row>
          <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col>              
                <div className="table-responsive" >
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        data={CustomerList_Data}
                        columns={column_Customers}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                      </MuiThemeProvider>
                </div>
              </Col>
          </Row>
          <Loader
        show={IsLoader}
        hide={hideLoader}
        />
      </Card>
                        
      </>  , document.getElementById('PageContents')
    )
}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  ItemCat: state.Items && state.Items.ItemData,
  ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
  Group_Id : state.Login_User.User_Data.Group_Id

})
export default connect(mapStateToProps,{set_Customer, SHOW_SCREEN})(CustomerList)
