
import React, { useEffect, useState, createRef } from 'react'
import ReactDOM from 'react-dom'
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {Link} from "react-router-dom"
import { Row, Col, Card, Button } from 'react-bootstrap'
import { Add, Edit, Delete } from '@material-ui/icons'
import { connect } from "react-redux"
 import PG_Modal from './PG_Modal';
 import { SelectProductGroups, DeleteProductGroup } from '../../Services/ProductGroupAPI'
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import { SHOW_SCREEN } from "../../React_Redux/Actions"
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { GetCurrentDate } from '../../Services/Common';
import Swal from 'sweetalert2'
import Loader from '../Loader';


function ProductGroupList(props) {
    const { Comp_Id,ProjectID } = props;
    const [ProductGroup_Modal, setProductGroup_Modal] = useState(false)
    const [IsAddNew, setIsAddNew] = useState(null)
    const [PG_Data, setPG_Data] = useState([])
    const [PGID, setPGID] = useState(0)
    const [btn_add, setBtn_add] = useState(true)
    const [IsLoader, setIsLoader] = useState(false)

    useEffect(() => {
     document.title = "Distribution / Product Group"
     SelectGroupScreenAccess(props.Group_Id,13,137,(data)=>{
            
      setBtn_add(parseInt(data.AddNew) === 0 ? false : true)

      props.SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
      parseInt(data.Edit) === 0 ? false : true,
      parseInt(data.Del) === 0 ? false : true,
      data.OtherAccess
      )
      
  })    
     Select_PG_List()
    }, [])

    const Select_PG_List = () =>{
      showLoader()
        SelectProductGroups(Comp_Id,ProjectID, (data) =>{
          hideLoader()  
          for(let i = 0; i < data.length; i++)
            {
                data[i].SrNo = i + 1
            }
            setPG_Data(data)
        })
    }
    const theme = createTheme({
      overrides: {
        MuiTableCell: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
          }
        }
      }
    });
    const show_Modal = () =>{
        setProductGroup_Modal(true);
    }

    const hide_Modal = () =>{

        setProductGroup_Modal(false)
        setIsAddNew(false)
        Select_PG_List()
    }
    const column = [
        {
            name: "SrNo",
            label: "Sr No.",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
           {
            name: "PGName",
            label: "Product Group",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
   
       {
         name: "ManufacturedBy",
         label: "Manufactured By",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }
         }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
             customHeadLabelRender: (columnMeta)=>{
              
              return(
                  <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
              )
             },
             customBodyRender: (dataIndex, rowIndex) => {
              
                const _PG_Data = PG_Data
                if(_PG_Data[rowIndex.rowIndex] != null)
                  return (
                      <>
                        <Link to={"#"} onClick={e =>{
                           setIsAddNew(false)
                           setPGID(_PG_Data[rowIndex.rowIndex].PGID)
                            show_Modal()
                         
                          }} > <Edit/> </Link> |  <Link to={"#"} onClick={e =>{
                            Delete_Product_Group(_PG_Data[rowIndex.rowIndex].PGID)
                          
                           }} > <Delete/> </Link>
                      </>
                  );
              }
            }
        }
    ];
    
    const Delete_Product_Group = (PGID) =>{
        if(PGID){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            })
    
            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: `Yes, Delete it`,
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
    
                    await DeleteProductGroup(PGID,(message) => {
                        Select_PG_List()
                        if(message.status){
                          swalWithBootstrapButtons.fire(
                            `ooppss`,
                            `${message.message}`,
                            'info'
                        )
                        }
                        else{
                          swalWithBootstrapButtons.fire(
                            `Deleted`,
                            `${message.message}`,
                            'success'
                        )
                        }
                       
                    })
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire(
                        'Cancelled',
                        `Your record is safe :)`,
                        'error'
                    )
                }
            })
        }
      
     
    }
    const showLoader = () => {
      setIsLoader(true)
     
    }
  const  hideLoader = () => {
    setIsLoader(false)
    }
    return ReactDOM.createPortal(
      <>
      <Row>
      <Col lg={6} xl={6} md={6} className="header_title" >
              <h2> Product Groups  </h2>
            </Col>
      </Row>
      <Card>
          <Row className="row_margin_left row_margin_right pt-2" style={{width:'80%', marginLeft:'17%'}} >
         
            <Col style={{textAlign: "right"}} >
             {btn_add && (<Button variant="btn btn-primary rounded-pill" onClick={e =>{
                show_Modal()
                setIsAddNew(true)
              }} style={{border: "none"}} > <Add />  Create New</Button> )}
              {btn_add && (<Button variant="btn btn-primary rounded-pill" onClick={e =>{
                setIsAddNew(true)
                const ws = XLSX.utils.json_to_sheet(PG_Data);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
                FileSaver.saveAs(data, `Product Group - ${GetCurrentDate()}`  +  ".xlsx");
              }} style={{border: "none"}} > <CloudDownloadIcon />  Export to Excel</Button> )}
            </Col>
          </Row>
          <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col>              
                <div className="table-responsive" >
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        name = "OPD_Datatables"
                         data={PG_Data}
                        columns={column}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                    </MuiThemeProvider>
                </div>
              </Col>
          </Row>
          
      <PG_Modal 
        show = { ProductGroup_Modal }
        onHide = { hide_Modal }
        is_add_new = { IsAddNew }
        PGID = {PGID}
      />
       <Loader
        show={IsLoader}
        hide={hideLoader}
        />
      </Card>

                        
      </>  , document.getElementById('PageContents')
    )
}

const mapStateToProps = state =>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Group_Id : state.Login_User.User_Data.Group_Id,
  ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

})

export default connect(mapStateToProps, {SHOW_SCREEN})(ProductGroupList)

