import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectConfigurationData =(Comp_Id,Callback) =>{
  axios.get(`${domain}/ControlPanel/SelectConfigurationData/${Comp_Id}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
          Callback(err)
     })
}

export const GetFileFromLocalDirectory = async (filename) => {
  try {
    
      const response = await axios.post(`${domain}/ControlPanel/GetFileFromLocalDirectory`,{filename}, { responseType: 'blob' });
     
      const url = URL.createObjectURL(response.data);
      return url;
    } catch (error) {
      console.log(error);
      return null;  
    }
}

export const updateconfiguration = (ConfigurationData,callback) =>{
  axios.post(`${domain}/ControlPanel/updateconfiguration`,
    ConfigurationData 
  )
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback()
      })
      .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
}
