import { domain } from "./Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectCompanyGroups =(Comp_Id,ProjectID,Callback) =>{
    
  axios.get(`${domain}/CompanyGroup/SelectCompanyGroups/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectCompanyGroup =(Comp_Id,ProjectID,CGID,Callback) =>{
    
    axios.get(`${domain}/CompanyGroup/SelectCompanyGroup/${Comp_Id}/${ProjectID}/${CGID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
export const SaveCompanyGroup = (CGData,IsAddNew,callback) =>{
 
  axios.post(`${domain}/CompanyGroup/SaveCompanyGroup`,{
    CGData,
    IsAddNew
  })
    .then(response=>{
        toast.configure()
        toast.info(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        callback()
    })
    .catch(err=>console.error(err))
}

export const DeleteCompanyGroup =(CGID,Callback) =>{
    axios.delete(`${domain}/CompanyGroup/DeleteCompanyGroup/${CGID}`)
         .then(response=>{
            Callback(response.data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }

  export const SelectCompanyGroupAgainstManufacturer =(Comp_Id,ProjectID,ManufacturedByID,Callback) =>{
    
    axios.get(`${domain}/CompanyGroup/SelectCompanyGroupAgainstManufacturer/${Comp_Id}/${ProjectID}/${ManufacturedByID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }

  export const SelectCompanyGroupAgainstMultipleCompanys =(Comp_Id,ProjectID,CGData,Callback) =>{
    
    axios.post(`${domain}/CompanyGroup/SelectCompanyGroupAgainstMultipleCompanys`, {
      Comp_Id, ProjectID, CGData
    })
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }