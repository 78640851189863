import React, { useEffect, useState } from 'react'
import { Modal, Card, Row, Col } from "react-bootstrap"
import {  TextField } from '@material-ui/core'
import { Loop, Cancel } from "@material-ui/icons"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Autocomplete } from '@material-ui/lab'

import { SelectUserProjects } from '../../../Services/Projects'
import { GetCurrentDate, GetFirstDateofCurrentMonth } from '../../../Services/Common'
import { Selectbank } from  '../../../Services/bankAPI'

function FinanceReport_Modal({show, onHide,Comp_Id,User_Id,toPath,readonlyProps,title }) {

    const [Project_List, setProject_List] = useState([])
    var   [SelectedProject, setSelectedProject] = useState(null)
    const [FromDate, setFromDate] = useState(GetFirstDateofCurrentMonth())
    const [ToDate, setToDate] = useState(GetCurrentDate())
    const [Bank_List, setBank_List] = useState([])
    const [SelectedBank, setSelectedBank] = useState(null)
    const [ReportPath, setReportPath] = useState('#')
    var [mTarget, setMTarget] = useState('');
    const [Error_State, setError_State] = useState({
        project_Err : '',
        bank_Err:''
    })
    useEffect(() => {
        if(show && toPath){
            SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
                setSelectedProject(mProjects[0])
                Select_Bank(mProjects[0].Project_ID)
                setProject_List(mProjects)
            })
        }
    }, [show])
  
    const Select_Bank = (Project_ID) =>{
        Selectbank(Comp_Id,Project_ID,(mData)=>{
           var mObj = {BankID:0,BankName:'SELECT ALL',Comp_Id,ProjectID:Project_ID}
            mData.splice(0,0,mObj)
            setBank_List(mData)
        })
    }
  
    const NavigateToReport = ()=>{
        if(toPath == '/CashInHandReport'){
            if(SelectedProject){ 
                localStorage.setItem('token', sessionStorage.getItem('token'))
                window.open(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}`,'_blank')
            } 
            else{
                if(!SelectedProject){
                    setError_State({...Error_State,project_Err:'required'})
                }
            }
        }
        else if(toPath == '/CashInBankReport'){
            if(SelectedProject && SelectedBank){
                localStorage.setItem('token', sessionStorage.getItem('token')) 
                window.open(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}/${SelectedBank.BankID}`,'_blank')
            }
            else{
                if(!SelectedProject){
                    setError_State({...Error_State,project_Err:'required'})
                }
                else if(!SelectedBank){
                    setError_State({...Error_State,project_Err:'',bank_Err:'required'})
                }
            }
        }
    }
    const ResetCotrols = () =>{
        setProject_List([])
        setSelectedProject(null)
        setFromDate(GetCurrentDate())
        setToDate(GetCurrentDate())
        setReportPath('#')
        setBank_List([])
        setSelectedBank(null)
        setError_State({
            bank_Err:'',project_Err:''
        })
    }
    return (
        <>
        <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" id="Inventory_Modal" >
            <Modal.Header closeButton className="header_title" >
            <Modal.Title> Finance / {title} </Modal.Title>
            </Modal.Header>
                <Modal.Body id='mBody'>
                    <Card>
                    <Row className="row_margin_left row_margin_right" >
                    <Col sm={12} lg={4} xl={4} md={4} >
                             <TextField 
                                name="FromDate" 
                                label="From Date"
                                type='date'
                                value={FromDate}
                                onChange={e=>setFromDate(e.target.value)}
                                fullWidth
                                inputProps={{
                                    readOnly: readonlyProps.FromDate
                                }
                                }
                             />
                             </Col>
                             <Col sm={12} lg={4} xl={4} md={4} >
                             <TextField 
                                name="ToDate" 
                                label="To Date"
                                type='date'
                                value={ToDate}
                                onChange={e=>setToDate(e.target.value)}
                                fullWidth
                                inputProps={{
                                    readOnly: readonlyProps.ToDate
                                }
                                }
                             />
                             </Col>
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="Project"
                                    id="Project"
                                    options={Project_List}
                                    getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                                    value={SelectedProject}
                                    disabled={readonlyProps.Project}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedProject(value)
                                            Select_Bank(value.Project_ID)
                                        }
                                        else{
                                            setSelectedProject(null)
                                            setSelectedBank(null)
                                            setBank_List([])
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="Project" label="Select Branch"
                                        />
                                    }
                                />
                                 <span className="text-danger">{Error_State.project_Err}</span>
                            </Col>
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="Bank"
                                    id="Bank"
                                    options={Bank_List}
                                    getOptionLabel={(option) => option.BankName ? option.BankName : ""}
                                    value={SelectedBank}
                                    disabled={readonlyProps.Bank}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedBank(value)
                                        }
                                        else{
                                            setSelectedBank(null)
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="Bank" label="Bank"
                                        />
                                    }
                                />
                                   <span className="text-danger">{Error_State.bank_Err}</span>
                            </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                <button className="btn btn-primary rounded-pill"  style={{ border: "none" }} onClick={NavigateToReport} > <Loop /> <u>Load</u></button>
                                <button type="button" className="btn btn-primary rounded-pill" onClick={e=>{
                                    ResetCotrols()
                                    onHide()
                                }} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
        </Modal>
        </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables.Company && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables.Company &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User.User_Data && state.Login_User.User_Data.User_id
})
export default connect(mapStateToProps)(FinanceReport_Modal)
