import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectReceipts =(Comp_Id,ProjectID,Month,Callback) =>{
  axios.get(`${domain}/Receipts/SelectReceipts/${Comp_Id}/${ProjectID}/${Month}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectRecovery =(Comp_Id,ProjectID,Month,Year,Callback) =>{
  axios.get(`${domain}/Receipts/SelectRecovery/${Comp_Id}/${ProjectID}/${Month}/${Year}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectRecoveryForEdit =(Comp_Id,ProjectID,RecoveryID,Callback) =>{
  axios.get(`${domain}/Receipts/SelectRecoveryForEdit/${Comp_Id}/${ProjectID}/${RecoveryID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const GetMaxReceiptsNo =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/Receipts/GetMaxReceiptsNo/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const GetMaxRecoveryNo =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/Receipts/GetMaxRecoveryNo/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SaveRecovery = (RecoveryMasterData,RecoveryDetailData,isAddNew,callback) =>{
 debugger
  axios.post(`${domain}/Receipts/SaveRecovery`,{
    RecoveryMasterData,
    RecoveryDetailData,
    isAddNew
  })
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              theme:'light',
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback()
      })
      .catch(err=>console.error(err))
}
export const SelectSaleInvoices =(Comp_Id,ProjectID,CustomerID,Callback) =>{
  axios.get(`${domain}/Receipts/SelectSaleInvoices/${Comp_Id}/${ProjectID}/${CustomerID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SaveReceipts = (ReceiptsMasterData,ReceiptsDetailData,DeletedReceiptsDetailData,isAddNew,callback) =>{
 
  axios.post(`${domain}/Receipts/SaveReceipts`,{
    ReceiptsMasterData,
    ReceiptsDetailData,
    DeletedReceiptsDetailData,
    isAddNew
  })
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              theme:'light',
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback()
      })
      .catch(err=>console.error(err))
}

export const SelectReceiptsMaster =(Comp_Id,ProjectID,ReceiptID,Callback) =>{
  axios.get(`${domain}/Receipts/SelectReceiptsMaster/${Comp_Id}/${ProjectID}/${ReceiptID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectReceiptsDetail =(ReceiptID,Callback) =>{
  axios.get(`${domain}/Receipts/SelectReceiptsDetail/${ReceiptID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}