import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Row, Col, Card } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Save, Cancel, Print } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { SelectcustomersList } from "../../Services/CustomersAPI";
import { SaveCustomerOpening, getSingleBankOpening, getSingleCustomerOpening } from "../../Services/OpeningAPI";
const businessgroup = [
  {
    id: 1,
    name: "group one",
  },
  {
    id: 2,
    name: "group two",
  },
];
const customerlist = [
  {
    id: 1,
    name: "Customer 1",
  },
  {
    id: 2,
    name: "Customer 2",
  },
];
const CustomerOpeningBalance = (props) => {
  const { Comp_Id, ProjectID, isAddNew, COID } = props;
  const [customerList, setcustomerList] = useState(customerlist);
  const [selectedCustomer, setselectedCustomer] = useState(null);
  const [address, setAddress] = useState("");
  const [groupList, setgroupList] = useState(businessgroup);
  const [selectedGroup, setselectedGroup] = useState(null);
  const [debitBalance, setdebitBalance] = useState(0);
  const [creditBalance, setcreditBalance] = useState(0);
  const [err, setErr] = useState({
    customerErr: false,
    groupErr: false,
    creditErr: false,
    debitErr: false,
  });
  useEffect(() => {
    SelectcustomersList(Comp_Id, ProjectID, (data) => {
      setcustomerList(data);
      if(isAddNew){

      }else{
        getSingleCustomerOpening(COID, (mData)=>{
          const selectedCustomer = data.find(x=> x.CustomerID === mData[0].CustomerID)
          setselectedCustomer(selectedCustomer)
          setAddress(mData[0].Address)
          setdebitBalance(mData[0].Debit)
          setcreditBalance(mData[0].Credit)
        })
      }
    });
  }, [Comp_Id, ProjectID]);

  const validate = () => {
    let isValid = true;
    if (!selectedCustomer) {
      setErr({ ...err, customerErr: true });
      isValid = false;
      return isValid;
    }
    
    if (debitBalance < 0) {
      setErr({ ...err, debitErr: true });
      isValid = false;
      return isValid;
    }
    if (creditBalance < 0) {
      setErr({ ...err, creditErr: true });
      isValid = false;
      return isValid;
    }
    setErr({
      customerErr: false,
      groupErr: false,
      creditErr: false,
      debitErr: false,
    });
    return isValid;
  };
  const SaveHandler = () => {
    const isValid = validate();
    if (isValid) {
      const CustomerOpening ={
        COID: COID,
        CustomerID: selectedCustomer?.CustomerID,
        Address: address,
        Debit: debitBalance,
        Credit: creditBalance,
        Comp_Id: Comp_Id,
        ProjectID: ProjectID,
      } 
      SaveCustomerOpening(CustomerOpening, isAddNew, (res)=>{
        document.getElementById("btnCancel").click();
      })
    }
  };
  return ReactDOM.createPortal(
    <>
      <Row className="row_margin_top row_margin_left row_margin_right">
        <Col lg={6} xl={6} md={6} className="header_title">
          <h2>Customer Opening Balance</h2>
        </Col>
      </Row>

      <Card>
        <Row className="row_margin_top row_margin_left row_margin_right m-2 g-3">
          <Col sm={12} lg={6} xl={6} md={6}>
            <Autocomplete
              name="Customer"
              id="Customer"
              options={customerList}
              value={selectedCustomer}
              getOptionLabel={(option) => option.CustomerName ?? ""}
              onChange={(e, value) => {
                if (value) {
                  setselectedCustomer(value);
                  setErr({ ...err, customerErr: false });
                } else {
                  setselectedCustomer(null);
                  setErr({ ...err, customerErr: true });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="Customer"
                  label="Customer"
                  onError={err.customerErr}
                  required
                  helperText={
                    err.customerErr && (
                      <span className="text-danger">Customer is required</span>
                    )
                  }
                />
              )}
            />
          </Col>

          {/* <Col sm={12} lg={6} xl={6} md={6}>
            <Autocomplete
              name="BusinessGroup"
              id="BusinessGroups"
              options={groupList}
              value={selectedGroup}
              getOptionLabel={(option) => option.name ?? ""}
              onChange={(e, value) => {
                if (value) {
                  setselectedGroup(value);
                  setErr({ ...err, groupErr: false });
                } else {
                  setselectedGroup(null);
                  setErr({ ...err, groupErr: true });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="BusinessGroup"
                  label="Business Groups"
                  onError={err.groupErr}
                  required
                  helperText={
                    err.groupErr && (
                      <span className="text-danger">
                        Business Group is required
                      </span>
                    )
                  }
                />
              )}
            />
          </Col> */}
          <Col sm={6} lg={6} xl={6} md={6}>
            <TextField
              name="Address"
              label="Address"
              type="text"
              fullWidth
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
          </Col>
          <Col sm={12} lg={6} xl={6} md={6}>
            <TextField
              name="DebitBalance"
              label="Debit Balance"
              type="number"
              required
              value={debitBalance}
              onChange={(e) => {
                setErr({ ...err, debitErr: false });
                setdebitBalance(e.target.value);
              }}
              fullWidth
              onError={err.debitErr}
              helperText={
                err.debitErr && (
                  <span className="text-danger">Debit Balance is required</span>
                )
              }
            />
          </Col>
          <Col sm={12} lg={6} xl={6} md={6}>
            <TextField
              name="CredtBalance"
              label="Credt Balance"
              type="number"
              fullWidth
              required
              value={creditBalance}
              onChange={(e) => {
                setErr({ ...err, creditErr: false });
                setcreditBalance(e.target.value);
              }}
              onError={err.creditErr}
              helperText={
                err.creditErr && (
                  <span className="text-danger">
                    Credit Balance is required
                  </span>
                )
              }
            />
          </Col>
        </Row>
        <Row className=" row_margin_top row_margin_left row_margin_right">
          <Col style={{ textAlign: "right", marginTop: "1%" }}>
            <button
              type="button"
              className="btn btn-primary rounded-pill"
              style={{ border: "none" }}
              onClick={SaveHandler}
            >
              {" "}
              <Save /> <u>Save</u>
            </button>
            <Link
              id="btnCancel"
              className="btn btn-primary rounded-pill"
              style={{ border: "none" }}
              to={"/customer-opening"}
            >
              {" "}
              <Cancel /> <u>Close</u>
            </Link>
          </Col>
        </Row>
      </Card>
    </>,
    document.getElementById("PageContents")
  );
};
const mapStateToProps = (state) => ({
  Comp_Id:
    state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  p_userid: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
  ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
  isAddNew: state.CustomerOpening && state.CustomerOpening.isAddNew,
  COID: state.CustomerOpening && state.CustomerOpening.OPening_ID,
});
export default connect(mapStateToProps)(CustomerOpeningBalance);
