import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import { GetCurrentDate, GetCurrentTimeAM_PM } from '../../../Services/Common'
import "../SaleReports/Table.css"
import { rptAreaWiseSale } from '../../../Services/Distribution_ReportAPI'
import { SelectUserProjects } from '../../../Services/Projects'
import Loader from '../../Loader'

function setPageSize(cssPageSize) {

    const style = document.createElement('style');
    style.innerHTML = `@page {size: ${cssPageSize}}`;
    style.id = 'page-orientation';
    document.head.appendChild(style);
}

function AreaWiseSaleReport({ Comp_Name, Comp_Id, User_Name, User_Id, ConfigObj }) {

    const { ProjectID, ManufacturedByID, PGID, FromDate, ToDate } = useParams();

    const [ItemData, setItemData] = useState([]);
    const [Table_Header_Data, setTable_Header_Data] = useState([])
    const [Table_Body_Data, setTable_Body_Data] = useState([])

    const [Credit, setCredit] = useState(0)
    const [Debit, setDebit] = useState(0)
    const [SumCredit, setSumCredit] = useState(0)
    const [SumDebit, setSumDebit] = useState(0)
    const [OpeneingBalances, setOpeneingBalances] = useState(0)

    const [SelectedProject, setSelectedProject] = useState(null)
    const [Customer, setCustomer] = useState('')
    const [CompanyGroup, setCompanyGroup] = useState('')
    const [Address, setAddress] = useState('')
    const [IsLoader, setIsLoader] = useState(false)

    useEffect(() => {

        document.title = " Distribution / Area-wise Sale "

        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('footer').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;
        showLoader()
        rptAreaWiseSale(Comp_Id, ProjectID, ManufacturedByID, PGID, FromDate, ToDate, (mData) => {

            var _Header_Array = [], _Body_Array = [], _PG_Array = [];
            mData.map((value, index) => {
                var _IsFind = _Header_Array.find(x => x.AreaID == value.AreaID)
                if (!_IsFind) {
                    _Header_Array.push(value)
                }

                var __isFind = _Body_Array.find(x => x.ItemID == value.ItemID)
                if (!__isFind) {
                    _Body_Array.push(value)
                }
                var __isPGFind = _PG_Array.find(x => x.PGID == value.PGID)
                if (!__isPGFind) {
                    _PG_Array.push(value)
                }
              
            })
            
            _Body_Array.sort((a,b)=> a.ItemID - b.ItemID)
            _Header_Array.sort((a,b)=> a.CityID - b.CityID)
            
            
            var _AreaWiseReportData = JSON.parse(localStorage.getItem("AreanWiseReport"))

            if (_AreaWiseReportData) {
                setCompanyGroup(_AreaWiseReportData.ManufacturedBy)
            }

            var _table = document.getElementById('mytable')
            _table.innerHTML = '';

            // table header start
            var thead = document.createElement('thead');
            var tbody = document.createElement('tbody');
            _table.appendChild(thead);
            var _head = thead.appendChild(document.createElement("th"))
            _head.style.borderTop = "1px solid black"
            _head.style.borderBottom = '1px solid black'
            _head.style.borderLeft = '1px solid black'
            // _head.style.borderRight= '1px solid black'
            _head.style.textAlign = 'left'
            _head.appendChild(document.createTextNode("Product Name"));

            for (var i = 0; i < _Header_Array.length; i++) {

                var _mHead = document.createElement("th")
                _mHead.style.borderTop = "1px solid black"
                _mHead.style.borderBottom = '1px solid black'
                 _mHead.style.writingMode = "vertical-lr"
                // _mHead.style.whiteSpace = "pre"
                _mHead.style.borderLeft = '1px solid black'
                _mHead.style.borderRight = '1px solid black'
                _mHead.innerHTML = _Header_Array[i].AreaName
                _mHead.classList.add("areaWiseSale")
                _mHead.style.textAlign = 'center'
                thead.appendChild(_mHead)
            }
            var _headQty = thead.appendChild(document.createElement("th"))
            _headQty.style.borderTop = "1px solid black"
            _headQty.style.borderBottom = '1px solid black'
            _headQty.style.borderLeft = '1px solid black'
            _headQty.style.borderRight = '1px solid black'
            _headQty.innerHTML = "Total Qty"
            _headQty.style.textAlign = 'center'
            thead.appendChild(_headQty);


            var _head = thead.appendChild(document.createElement("th"))
            _head.style.borderTop = "1px solid black"
            _head.style.borderBottom = '1px solid black'
            _head.style.borderLeft = '1px solid black'
            _head.style.borderRight = '1px solid black'
            _head.innerHTML = "Value"
            _head.style.textAlign = 'center'
            thead.appendChild(_head);
            // table header end 

            // table body start
            // var Firstrow = document.createElement('tr');
            // Firstrow.innerHTML =  _AreaWiseReportData ? "Group Name :  " + _AreaWiseReportData.CompanyGroup : "Group Name :  " 
            // Firstrow.style.fontWeight = "bold"
            // Firstrow.style.paddingLeft = "50%"
            // tbody.appendChild(Firstrow)

            _PG_Array.map(PGVal => {

                var row = document.createElement('tr');
                var cell = document.createElement('td');
                cell.style.fontWeight = "bolder"
                cell.style.fontSize = "26px"
                cell.style.borderTop = "1px solid black"
                cell.style.borderBottom = '1px solid black'
                cell.innerHTML = PGVal.PGName;
                row.appendChild(cell);
                tbody.appendChild(row);

                _Body_Array.map((value, index) => {
                    if (PGVal.PGID === value.PGID) {
                        var row = document.createElement('tr');
                        var cell = document.createElement('td');
                        cell.style.fontWeight = "bold"
                        cell.style.borderTop = "1px solid black"
                        cell.style.borderBottom = '1px solid black'
                        cell.style.borderLeft = '1px solid black'
                        cell.style.borderRight = '1px solid black'
                        cell.style.paddingLeft = '15px'

                        cell.innerHTML = value.ItemDiscription;
                        row.appendChild(cell);

                        _Header_Array.map((TValue, TIndex) => {

                            var cell = document.createElement('td');
                            cell.id = `${value.ItemDiscription}-${TValue.AreaName}`
                            cell.style.borderTop = "1px solid black"
                            cell.style.borderBottom = '1px solid black'
                            cell.style.borderLeft = '1px solid black'
                            cell.style.borderRight = '1px solid black'
                            cell.style.textAlign = "center"
                            cell.innerHTML = '-';
                            row.appendChild(cell);

                        })

                        var cellTotalQty = document.createElement('td');
                        cellTotalQty.style.fontWeight = 'bold'
                        cellTotalQty.style.borderTop = "1px solid black"
                        cellTotalQty.style.borderBottom = '1px solid black'

                        cellTotalQty.id = `${value.ItemDiscription}-TotalQty`
                        cellTotalQty.innerHTML = 0;
                        cellTotalQty.style.textAlign = "center"
                        row.appendChild(cellTotalQty);


                        var cell = document.createElement('td');
                        cell.style.fontWeight = 'bold'
                        cell.style.borderTop = "1px solid black"
                        cell.style.borderBottom = '1px solid black'
                        cell.style.borderLeft = '1px solid black'
                        cell.style.borderRight = '1px solid black'
                        cell.style.textAlign = "center"
                        cell.id = `${PGVal.PGName}Value-${value.ItemDiscription}-Total`
                        cell.innerHTML = 0;
                        row.appendChild(cell);

                        tbody.appendChild(row);
                    }
                })
                var row = document.createElement('tr');
                var cell = document.createElement('td');
                cell.style.fontWeight = "bold"
                cell.style.borderTop = "1px solid black"
                cell.style.borderBottom = '1px solid black'
                cell.style.borderLeft = '1px solid black'
                cell.style.borderRight = '1px solid black'
                cell.style.textAlign = "center"
                cell.innerHTML = PGVal.PGName + " Total";
                row.appendChild(cell);

                _Header_Array.map((TValue, TIndex) => {

                    var cell = document.createElement('td');
                    cell.id = `${PGVal.PGName}-${TValue.AreaName}-Total`
                    cell.style.fontWeight = "bold"
                    cell.style.borderTop = "1px solid black"
                    cell.style.borderBottom = '1px solid black'
                    cell.style.borderLeft = '1px solid black'
                    cell.style.borderRight = '1px solid black'
                    cell.style.writingMode = "vertical-lr"
                    cell.style.textAlign = "center"

                    cell.innerHTML = 0;
                    row.appendChild(cell);

                })
                var cellTotalQty = document.createElement('td');
                cellTotalQty.id = `${PGVal.PGName}-QtyTotal`
                cellTotalQty.style.fontWeight = "bold"
                cellTotalQty.style.borderTop = "1px solid black"
                cellTotalQty.style.borderBottom = '1px solid black'
                cellTotalQty.style.textAlign = "center"
                cellTotalQty.style.writingMode = "vertical-lr"
                cellTotalQty.innerHTML = 0;

                var cell = document.createElement('td');
                cell.id = `${PGVal.PGName}-ValueTotal`
                cell.style.fontWeight = "bold"
                cell.style.borderTop = "1px solid black"
                cell.style.borderBottom = '1px solid black'
                cell.style.borderLeft = '1px solid black'
                cell.style.borderRight = '1px solid black'
                cell.style.writingMode = "vertical-lr"
                cell.style.textAlign = "center"
                cell.innerHTML = 0;
                row.appendChild(cellTotalQty)
                row.appendChild(cell);
                tbody.appendChild(row);

            })


            var row = document.createElement('tr');
            var cell = document.createElement('td');
            cell.style.fontWeight = "bold"
            cell.style.borderTop = "1px solid black"
            cell.style.borderBottom = '1px solid black'
            cell.style.borderLeft = '1px solid black'
            cell.style.borderRight = '1px solid black'
            cell.style.textAlign = "center"
            cell.style.writingMode = "vertical-lr"
            cell.innerHTML = "Grand Total";
            row.appendChild(cell);

            _Header_Array.map((TValue, TIndex) => {

                var cell = document.createElement('td');
                cell.id = `${TValue.AreaName}-Total`
                cell.style.fontWeight = "bold"
                cell.style.borderTop = "1px solid black"
                cell.style.borderBottom = '1px solid black'
                cell.style.borderLeft = '1px solid black'
                cell.style.borderRight = '1px solid black'
                cell.style.writingMode = "vertical-lr"
                cell.style.textAlign = "center"

                cell.innerHTML = 0;
                row.appendChild(cell);

            })
            var cellTotalQty = document.createElement('td');
            cellTotalQty.id = `Total-TotalQty`
            cellTotalQty.style.fontWeight = "bold"
            cellTotalQty.style.borderTop = "1px solid black"
            cellTotalQty.style.borderBottom = '1px solid black'
            cellTotalQty.style.textAlign = "center"
              cell.style.writingMode = "vertical-lr"
            cellTotalQty.innerHTML = 0;

            var cell = document.createElement('td');
            cell.id = `Total-Total`
            cell.style.fontWeight = "bold"
            cell.style.borderTop = "1px solid black"
            cell.style.borderBottom = '1px solid black'
            cell.style.borderLeft = '1px solid black'
            cell.style.borderRight = '1px solid black'
            cell.style.writingMode = "vertical-lr"
            cell.style.textAlign = "center"
            cell.innerHTML = 0;
            row.appendChild(cellTotalQty)
            row.appendChild(cell);
            tbody.appendChild(row);
            _table.appendChild(tbody);


            setTimeout(() => {
                DestributeQty(mData)
            }, 1000);
            setTimeout(() => {
                DestributeTotalAndSubTotal(_PG_Array)
            }, 4000);

            setPageSize('')
        })
        SelectUserProjects(Comp_Id, User_Id, (mProjects) => {
            mProjects.map(value => {
                if (value.Project_ID == ProjectID) {
                    setSelectedProject(value)
                }
            })
        })
    }, [])
    const showLoader = () => {
        setIsLoader(true)
    }
    const hideLoader = () => {
        setIsLoader(false)
    }
    var _GrandTotal = 0.00;
    var Total = 0.00;
    const DestributeQty = (Grid_Data) => {
        
        var pgValue = 0.00;
        
        Grid_Data.map((value, index) => {

            var _RecordFound = Grid_Data.find(x => x.ItemID === value.ItemID && x.AreaID === value.AreaID)
            _GrandTotal += _RecordFound.Qty * _RecordFound.SaleRate
              
            if (_RecordFound) {
                var _id = _RecordFound.ItemDiscription + "-" + _RecordFound.AreaName
                if (document.getElementById(_id)) {
                    
                    document.getElementById(_id).innerHTML = _RecordFound.Qty
                }
                

                if (document.getElementById(`${_RecordFound.ItemDiscription}-TotalQty`)) {
                    var _PrevValue = parseFloat(document.getElementById(`${_RecordFound.ItemDiscription}-TotalQty`).innerHTML)
                 
                    document.getElementById(`${_RecordFound.ItemDiscription}-TotalQty`).innerHTML = _PrevValue + _RecordFound.Qty
                    
                }
                if (document.getElementById(`Total-TotalQty`)) {
                    document.getElementById(`Total-TotalQty`).innerHTML = (parseFloat(document.getElementById(`Total-TotalQty`).innerHTML) + _RecordFound.Qty).toFixed(2)

                }
                // Sub Totals Calculations
                if (document.getElementById(`${_RecordFound.PGName}-${_RecordFound.AreaName}-Total`)) {
                    var _PrevValue = parseFloat(document.getElementById(`${_RecordFound.PGName}-${_RecordFound.AreaName}-Total`).innerHTML)
                    document.getElementById(`${_RecordFound.PGName}-${_RecordFound.AreaName}-Total`).innerHTML =parseFloat(_PrevValue + (_RecordFound.Qty * _RecordFound.SaleRate)).toFixed(2)
                  
                //    document.getElementById(`${_RecordFound.PGName}-${_RecordFound.AreaName}-Total`).innerHTML = parseFloat(PrevValue + (_RecordFound.Qty * _RecordFound.SaleRate)).toFixed(2)
                
                }
                if (document.getElementById(`${_RecordFound.AreaName}-Total`)) {
                    var PrevValue = parseFloat(document.getElementById(`${_RecordFound.AreaName}-Total`).innerHTML)
                 document.getElementById(`${_RecordFound.AreaName}-Total`).innerHTML = parseFloat(PrevValue + (_RecordFound.Qty * _RecordFound.SaleRate)).toFixed(2)
                //   document.getElementById(`${_RecordFound.AreaName}-Total`).innerHTML = parseFloat(PrevValue + (_RecordFound.Qty * _RecordFound.SaleRate)).toFixed(2)
                }
                if (document.getElementById(`${_RecordFound.PGName}-QtyTotal`)) {
                    var _PrevValue = parseFloat(document.getElementById(`${_RecordFound.PGName}-QtyTotal`).innerHTML)
                    document.getElementById(`${_RecordFound.PGName}-QtyTotal`).innerHTML = _PrevValue + _RecordFound.Qty
                }

                if (document.getElementById(`${_RecordFound.PGName}Value-${_RecordFound.ItemDiscription}-Total`)) {
                    document.getElementById(`${_RecordFound.PGName}Value-${_RecordFound.ItemDiscription}-Total`).innerHTML = (parseFloat(document.getElementById(`${_RecordFound.ItemDiscription}-TotalQty`).innerHTML) * _RecordFound.SaleRate).toFixed(2)

                }

            }
        })



    }
    const DestributeTotalAndSubTotal = (_PG_Array) => {
      
        let _mainTotal = 0, _subTotal = 0
        for (let i = 0; i < _PG_Array.length; i++) {
            var el = document.querySelectorAll(`[id^="${_PG_Array[i].PGName}Value"]`);
            
            _subTotal = 0
            el.forEach(element => {
                
                _subTotal += parseFloat(element.textContent)
            });
            if (document.getElementById(`${_PG_Array[i].PGName}-ValueTotal`)) {
                document.getElementById(`${_PG_Array[i].PGName}-ValueTotal`).innerHTML = parseFloat(_subTotal).toFixed(2)
            }

            _mainTotal += _subTotal
        }
        if (document.getElementById(`Total-Total`)) {
            document.getElementById(`Total-Total`).innerHTML = _mainTotal.toFixed(2)
        }
        hideLoader()

    }
    return (
        <>

            <div id="main_div_report" style={{ fontSize: '12px' }} >
                <Row style={{ textAlign: 'center', color: "black" }}>
                    <Col sm={3} md={3} xl={3}><img src={ConfigObj.Logo} width="100" height="100" /></Col>
                    <Col sm={6} md={6} xl={6}>
                        <h2> {Comp_Name} </h2> <br /> <h3>Area Wise Sale</h3>
                    </Col>
                </Row>
                <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                    <Col sm={6} md={6} xl={6} style={{ textAlign: 'left' }} >
                        <Row>
                            <Col  >
                                <b style={{ textAlign: "left" }}>Project:</b><b style={{ textAlign: "right", paddingLeft: '30%' }}>{SelectedProject && SelectedProject.ProjectName}</b>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={6} md={6} xl={6} style={{ textAlign: 'left' }} >
                        <Row>
                            <Col  >
                                <b style={{ textAlign: "left" }}>Company:</b> <b style={{ textAlign: "right", paddingLeft: '26%' }}>{CompanyGroup}</b>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                    <Col sm={6} md={6} xl={6} style={{ textAlign: 'left' }} >
                        <Row>
                            <Col  >
                                <b style={{ textAlign: "left" }}>From Date:</b> <b style={{ textAlign: "right", paddingLeft: '26%' }}>{FromDate}</b>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={6} md={6} xl={6} style={{ textAlign: 'left' }} >
                        <Row>
                            <Col  >
                                <b style={{ textAlign: "left" }}>To Date:</b> <b style={{ textAlign: "right", paddingLeft: '27%' }}>{ToDate}</b>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className=" row-margin-left row-margin-top row-margin-right" >
                    <Col>
                        <div className="table-responsive" >
                            <div className="view">
                                <div className="wrapper">
                                    <table id="mytable" width={"100%"} >

                                    </table>
                                </div>
                            </div>


                        </div>
                    </Col>
                </Row>
                <div className='page-break-ledger'>

                </div>
            </div>
            <Row className="divFooter" style={{ marginTop: "3%" }} >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
            </Row>
            <Loader
                show={IsLoader}
                hide={hideLoader}
            />

        </>
    )
}
const mapStateToProps = state => ({
    Comp_Id: state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name: state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address: state.GlobalVariables && state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ConfigObj: state.Global_Configs && state.Global_Configs.GlobalConfig
})

export default connect(mapStateToProps)(AreaWiseSaleReport)