import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectManufacturedByList =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/manufacture/SelectManufacturedByList/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectManufacturedByListForDSR =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/manufacture/SelectManufacturedByListForDSR/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectManufacturer =(Comp_Id,ProjectID,ManufacturedByID,Callback) =>{
  axios.get(`${domain}/manufacture/SelectManufacturer/${Comp_Id}/${ProjectID}/${ManufacturedByID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SaveManufacturedBy = (ManufacturedBy,DeletedManufacturedBy,callback) =>{
 
  axios.post(`${domain}/manufacture/SaveManufacturedBy`,{
    ManufacturedBy:ManufacturedBy,
    DeletedManufacturedBy:DeletedManufacturedBy
  })
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback()
      })
      .catch(err=>console.error(err))
}