import React, { useState, useEffect, useRef } from 'react'
import  ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { Row, Col, Card ,Spinner,Tabs, Tab   } from "react-bootstrap"
import { Cancel, Save, Print } from '@material-ui/icons'
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { connect } from 'react-redux'
import { getValue } from '@syncfusion/ej2-base';
import Swal from 'sweetalert2'
import { enableRipple } from '@syncfusion/ej2-base';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';

import { SaveCustomer , SelectCustomer,GetMaxCustomerCode,SelectCustomerForFlat,SelectCustomerForDis,SelectCustomerForBonus,SelectCustomerForDisCompanyAndGroup } from './../../Services/CustomersAPI'
import { SaveScheme, SelectActiveItems } from '../../Services/ItemsAPI'
import {GetCurrentDate, msg} from '../../Services/Common'
import { SelectCityList } from '../../Services/CityAPI'
import { SelectCityWiseAreas } from '../../Services/AreasAPI'
import { SelectManufacturedByList } from '../../Services/manufactureAPI'
import { SelectProductGroupAgainstManufacturer } from '../../Services/ProductGroupAPI'
import { SelectCompanyGroupAgainstManufacturer } from '../../Services/CompanyGroupAPI'

function CustomerEntry({ isAddNew, CustomerID, show_screen , p_userid,Comp_Id,ProjectID}) {

    var UserID = p_userid
    const [Customer_Data,setCustomer_Data] = useState({
        CustomerID:CustomerID,
        Comp_Id:Comp_Id,
        ProjectID:ProjectID,
        UserID:UserID,
        CustomerName:'',
        Email:'',
        CustomerCode:'',
        PhoneNo:'',
        SupplierCode:'',
        CreatedDate:GetCurrentDate(),  
        NTN:'',
        SaleTaxNo:'',
        Address:'',
        CNIC:'',
        Lis9:false,
        Lis9No: '',
        Lis9Date:GetCurrentDate(),
        Lis10:false,
        Lis10No:'',
        Lis10Date:GetCurrentDate(),
        Lis11:false,
        Lis11No:'',
        Lis11Date:GetCurrentDate(),
        IsActive:true,
        AlternateCustCode:'',
        WHTPer:0,
        IncomeTaxPer:0,
        AddTaxPer:0,
    })
    const [Item_Err, setItem_Err] = useState({
        Name_Err:'',
        PhoneNo_Err:'',
        NTN_Err:'',
        Cat_Err: '',
        UOM_Err:'',
        SUBUOM_Err:'',
        City_Err:'',
        CustomerType_Err:'',
        AdvanceTaxType_Err:'',
        CustomerClass_Err:'',
        Area_Err:'',
        SaleTaxType_Err:'',
    })
    const [CityData, setCityData] = useState([])
    const [SelectedCity, setSelectedCity] = useState(null)
    const [SelectedCustomerType, setSelectedCustomerType] = useState(null)
    const [SelectedAdvanceTaxType, setSelectedAdvanceTaxType] = useState(null)
    const [SelectedCustomerClass, setSelectedCustomerClass] = useState(null)
    const [btn_Edit, setBtn_Edit] = useState(true)
    const [SelectedArea, setSelectedArea] = useState(null)
    const [AreaData, setAreaData] = useState([])
    const [btn_Disabled, setbtn_Disabled] = useState(false)
    const [SelectedSaleTaxType, setSelectedSaleTaxType] = useState(null)
    const [SelectedTab,setSelectedTab] = useState('')
    const [FlatRatePolicy,setFlatRatePolicy] = useState([])
    const [DeletedFlatRate, setDeletedFlatRate] = useState([])
    const [DiscountPolicy, setDiscountPolicy] = useState([])
    const [DeletedDiscountPolicy, setDeletedDiscountPolicy] = useState([])
    const [BonusPolicy, setBonusPolicy] = useState([])
    const [DeletedBonusPolicy, setDeletedBonusPolicy] = useState([])
    const [DiscountCGPolicy, setDiscountCGPolicy] = useState([])
    const [DeletedDiscountCGPolicy, setDeletedDiscountCGPolicy] = useState([])
    // const [ManufacturedBy_List, setManufacturedBy_List] = useState([])
    const [SelectedManufacturedBy_ListOption, setSelectedManufacturedBy_ListOption] = useState(null)
    const [CG_List, setCG_List] = useState([])
    const [SelectedCG, setSelectedCG] = useState(null)
           
    const [btnFlatAdd, setbtnFlatAdd] = useState(true)
    const [btnFlatEdit, setbtnFlatEdit] = useState(true)
    const [btnFlatDelete, setbtnFlatDelete] = useState(true)
    const [btnDiscountAdd, setbtnDiscountAdd] = useState(true)
    const [btnDiscountEdit, setbtnDiscountEdit] = useState(true)
    const [btnDiscountDelete, setbtnDiscountDelete] = useState(true)
    const [btnBonusAdd, setbtnBonusAdd] = useState(true)
    const [btnBonusEdit, setbtnBonusEdit] = useState(true)
    const [btnBonusDelete, setbtnBonusDelete] = useState(true)
    // const [Item_List, setItem_List] = useState([])
    const [Selected_item, setSelected_item] = useState([])
    const [DisSelected_item, setDisSelected_item] = useState([])
    const [BonusSelected_item, setBonusSelected_item] = useState([])
    // const [MtestArray, setMtestArray] = useState([])
    const bg_Ref = React.createRef()
    var grid = null
    const toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
    const validationRules = { required: true };
    const customBGValidation =(args)=> {
     return getValue('value', args) != ''
 }
   const BGValidation = {
     required: [customBGValidation, 'Flat Policy must be Entered'],
 };
 const sortingOptions = {
     columns: [{ field: 'SrNo', direction: 'Ascending' }]
 };
  const Item_List = useRef([]);
  const ManufacturedBy_List = useRef([]);
//   const CG_List = useRef([]);
  var mCDD


    useEffect(() => {

    document.title = " Distribution / Customers"
   
      SelectManufacturedByList(Comp_Id, ProjectID, (mData)=>{
        ManufacturedBy_List.current.value = mData
      });
       
    
    SelectCityList(Comp_Id,ProjectID,(mCities)=>{
        setCityData(mCities)
    if(isAddNew)
    {  
        GetMaxCustomerCode(Comp_Id,ProjectID,(mCode)=>{
            setCustomer_Data({...Customer_Data,CustomerCode:mCode})
        })
    }
     else   {
            setBtn_Edit(show_screen.edit)
            SelectCustomer(Comp_Id,ProjectID,CustomerID,(mData)=>{
                setCustomer_Data({
                    CustomerID:CustomerID,
                    Comp_Id:Comp_Id,
                    ProjectID:ProjectID,
                    CustomerCode:mData[0].CustomerCode,
                    CustomerName:mData[0].CustomerName,
                    Email:mData[0].Email,
                    PhoneNo:mData[0].PhoneNo,
                    CreatedDate:mData[0].CreatedDate,
                    UserID:mData[0].UserID,
                    // Remarks:mData[0].Remarks,
                    // Company:mData[0].Company,
                    NTN:mData[0].NTN,
                    SaleTaxNo:mData[0].SaleTaxNo,
                    // City:mData[0].City,
                    Address:mData[0].Address,
                    CNIC:mData[0].CNIC,
                    Lis9:mData[0].Lis9 === 1 ? true : false,
                    Lis9No:mData[0].Lis9No,
                    Lis9Date:mData[0].Lis9_Date,
                    Lis10:mData[0].Lis10 === 1 ? true : false,
                    Lis10No:mData[0].Lis10No,
                    Lis10Date:mData[0].Lis10_Date,
                    Lis11:mData[0].Lis11 === 1 ? true : false,
                    Lis11No:mData[0].Lis11No,
                    Lis11Date:mData[0].Lis11_Date,
                    IsActive:mData[0].IsActive === 1 ? true : false,
                    AlternateCustCode:mData[0].AlternateCustCode,
                    WHTPer:mData[0].WHTPer,
                    IncomeTaxPer:mData[0].IncomeTaxPer,
                    AddTaxPer:mData[0].AddTaxPer
                })
                mCities.map(value=>{
                    if(value.CityID === mData[0].CityID){
                        setSelectedCity(value)

                        SelectCityWiseAreas(Comp_Id,ProjectID,mData[0].CityID,(mareas)=>{
                            setAreaData(mareas)
                            mareas.map(val=>{
                                if(val.AreaID === mData[0].AreaID){
                                    setSelectedArea(val)
                                }
                            })
                        })
                    }
                })
               setSelectedCustomerType(CustomerTypeData.find(x=> x.value === mData[0].CustomerType))
               setSelectedAdvanceTaxType(AdvanceTaxTypeData.find(x=> x.value === mData[0].AdvanceTaxType))
               setSelectedCustomerClass(CustomerClassData.find(x=> x.value === mData[0].CustomerClass))
               setSelectedSaleTaxType(SaleTaxTypeData.find(x=> x.title === mData[0].SaleTaxType))
            })
            fetchFlatPolicy()
            fetchDisPolicy()
            fetchBonusPolicy()
            fetchDiscountDiscountPG()
        }
        SelectActiveItems(Comp_Id,ProjectID,(mItems)=>{
         Item_List.current.value = mItems
        })
    })
    
    setbtn_Disabled(false)
    }, [])

    const fetchDiscountDiscountPG = () =>{
        SelectCustomerForDisCompanyAndGroup(Comp_Id,ProjectID,CustomerID,(mData)=>{
                mData.map((value,index1)=>{
                    value.SrNo = index1 + 1
                    if(value.PGID === 0){
                     value.PGName = 'SELECT ALL'
                    }
                })
                if(mData.length > 0){
                    setDiscountCGPolicy(mData)
                }
                setDeletedDiscountCGPolicy([])
             
           })
    }
    const fetchFlatPolicy = () =>{
        SelectCustomerForFlat(Comp_Id,ProjectID,CustomerID,(mData)=>{
            mData.map((value,index1)=>{
                value.SrNo = index1 + 1
            })
            setFlatRatePolicy(mData)
            setDeletedFlatRate([])
        
    })
    }
    const fetchBonusPolicy = () =>{
        SelectCustomerForBonus(Comp_Id,ProjectID,CustomerID,(mData)=>{
            mData.map((value,index1)=>{
                value.SrNo = index1 + 1
            })
            if(mData.length > 0){
                setBonusPolicy(mData)
            }
          
            
        })
    }
    const fetchDisPolicy = () =>{
        SelectCustomerForDis(Comp_Id,ProjectID,CustomerID,(mData)=>{
            mData.map((value,index1)=>{
                value.SrNo = index1 + 1
            })
            setDiscountPolicy(mData)
            setDeletedDiscountPolicy([])
         
        })
    }
   const CGList = (ManufacturedByID)=>{
        
       SelectProductGroupAgainstManufacturer(Comp_Id,ProjectID,ManufacturedByID,(mData)=>{
            debugger
                var mobj = {PGID:0, PGName: 'SELECT ALL'}
                mData.unshift(mobj)
                
                if(mCDD){
                    mCDD.props.options.length = 0 
                    for(let i=0; i<mData.length; i++){

                        mCDD.props.options.push(mData[i])
                    }
                }
                
                setCG_List(mData)   
        })
    }
  
   
   const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
  const validate =() =>{
        var  NameErr=""
        var PhoneNoErr = ""
        var CityErr = "",CCErr= "",ATTErr = "",CClassErr = "",AreaErr = "",SaleTax_Err = ''
        if(!(Customer_Data.CustomerName).trim())
        {
         NameErr = "Name is required"
        }
        if(!SelectedCity)
        {
            CityErr = "required"
        }
        if(!SelectedArea){
            AreaErr = "required"
        }
        if(!(Customer_Data.PhoneNo).trim())
        {
            PhoneNoErr="required"
        }
        if(!SelectedCustomerType){
            CCErr = "required"
        }
      if(!SelectedAdvanceTaxType){
          ATTErr="required"
      }
      if(!SelectedCustomerClass){
          CClassErr="required"
      }
      if(!SelectedSaleTaxType){
        SaleTax_Err = 'required'
      }
        if(NameErr)
        {
           setItem_Err({...Item_Err,Name_Err:NameErr})
           document.getElementById('CustomerName').focus()
            return false
        }
        if(CityErr)
        {
            setItem_Err({...Item_Err,City_Err:CityErr})
           document.getElementById('City').focus()
            return false
        }
        if(AreaErr){
            setItem_Err({...Item_Err,Area_Err:AreaErr})
            document.getElementById('Area').focus()
             return false
        }
     if(PhoneNoErr)
     {
        setItem_Err({...Item_Err,Name_Err:'',PhoneNo_Err:PhoneNoErr})
        document.getElementById('PhoneNo').focus()
        return false
     }
    if(CCErr){
        setItem_Err({...Item_Err,Name_Err:'',PhoneNo_Err:'',CustomerType_Err:CCErr})
        document.getElementById('CustomerType').focus()
        return false
    }
    if(ATTErr){
        setItem_Err({...Item_Err,Name_Err:'',PhoneNo_Err:'',CustomerType_Err:'',AdvanceTaxType_Err:ATTErr})
        document.getElementById('AdvanceTaxType').focus()
        return false
    }
    if(CClassErr){
        setItem_Err({...Item_Err,Name_Err:'',PhoneNo_Err:'',CustomerType_Err:'',AdvanceTaxType_Err:'',CustomerClass_Err:CClassErr})
        document.getElementById('CustomerClass').focus()
        return false
    }
    if(SaleTax_Err){
        setItem_Err({...Item_Err,Name_Err:'',PhoneNo_Err:'',CustomerType_Err:'',AdvanceTaxType_Err:'',CustomerClass_Err:'',SaleTaxType_Err:SaleTax_Err})
        document.getElementById('SaleTaxType').focus()
        return false
    }
     setItem_Err({...Item_Err,Name_Err:'',PhoneNo_Err:'',City_Err:'',CustomerType_Err:'',AdvanceTaxType_Err:'',CustomerClass_Err:'',Area_Err:'',SaleTaxType_Err:''})

      return true
    
    }

    const Save_Handler = () =>{
        if(validate())
        {
            var CustomerLicense = ''
            if( Customer_Data.Lis9){  CustomerLicense = Customer_Data.Lis9No   }
          if( Customer_Data.Lis10){ CustomerLicense = Customer_Data.Lis10No }
       if( Customer_Data.Lis11){CustomerLicense = Customer_Data.Lis11No }
            var CustomerData = {
                CustomerID: Customer_Data.CustomerID,
                Comp_Id:Comp_Id,
                ProjectID:ProjectID,
                UserID:UserID,
                CustomerCode:Customer_Data.CustomerCode,
                CustomerName:Customer_Data.CustomerName,
                Email:Customer_Data.Email,
                PhoneNo:Customer_Data.PhoneNo,
                NTN:Customer_Data.NTN,
                SaleTaxNo:Customer_Data.SaleTaxNo,
                CreatedDate:Customer_Data.CreatedDate,
                // Remarks:Customer_Data.Remarks, 
                // City:Customer_Data.City,
                Address:Customer_Data.Address,
                // Company:Supplier_Data.Company,
                CityID: SelectedCity && SelectedCity.CityID,
                CustomerType: SelectedCustomerType && SelectedCustomerType.value,
                AdvanceTaxType: SelectedAdvanceTaxType && SelectedAdvanceTaxType.value,
                CustomerClass:SelectedCustomerClass && SelectedCustomerClass.value,
                CNIC:Customer_Data.CNIC,
                Lis9:Customer_Data.Lis9 ? 1 : 0,
                Lis9No:Customer_Data.Lis9No,
                Lis9Date:Customer_Data.Lis9Date,
                Lis10:Customer_Data.Lis10 ? 1 : 0,
                Lis10No:Customer_Data.Lis10No,
                Lis10Date:Customer_Data.Lis10Date,
                Lis11:Customer_Data.Lis11 ? 1 : 0,
                Lis11No:Customer_Data.Lis11No,
                Lis11Date:Customer_Data.Lis11Date,
                IsActive:Customer_Data.IsActive ? 1 : 0,
                AreaID:SelectedArea && SelectedArea.AreaID,
                AlternateCustCode:Customer_Data.AlternateCustCode,
                CustomerLicense:CustomerLicense,
                WHTPer:Customer_Data.WHTPer,
                IncomeTaxPer:Customer_Data.IncomeTaxPer,
                AddTaxPer:Customer_Data.AddTaxPer,
                SaleTaxType: SelectedSaleTaxType.title,
            }
            setbtn_Disabled(true)
            SaveCustomer(CustomerData, isAddNew,()=>{
                setbtn_Disabled(false)
                document.getElementById('btnCancel').click()
            })
        }
   
    }
const AdvanceTaxTypeData = [
    {title:'EXEMPTED',value:'EXEMPTED'},
    {title:'FILER',value:'FILER'},
    {title:'NON FILER',value:'NON FILER'}
]
const SaleTaxTypeData = [
    {title:'REGISTER WITH GST'},
    {title:'NOT REGISTER WITH GST'},
    {title:'EXEMPTED'}
]
const CustomerTypeData = [
    {title:'RETAILER',value:'RETAILER'},
    {title:'WHOLESALER',value:'WHOLESALER'},
    {title:'DOCTOR',value:'DOCTOR'}
]
const CustomerClassData = [
    {title:'A +',value:'A +'},
    {title:'A',value:'A'},
    {title:'B',value:'B'},
    {title:'C',value:'C'},
    {title:'D',value:'D'},
    {title:'E',value:'E'},
    {title:'F',value:'F'}
]
const FlatRate_Handler = () =>{
    if(FlatRatePolicy.length > 0 || DeletedFlatRate.length > 0){
       for(let i =0; i<FlatRatePolicy.length; i++){
        if(typeof FlatRatePolicy[i].TargetFromQty === 'undefined' || (FlatRatePolicy[i].TargetFromQty) == 0 || FlatRatePolicy[i].TargetFromQty == null )
        {
         Swal.fire({
             icon: 'info',
             text: `TargetFromQty is required at Sr No. ${FlatRatePolicy[i].SrNo}...`,
         })
         return
        }
        if(typeof FlatRatePolicy[i].Criteria === 'undefined' || (FlatRatePolicy[i].Criteria) == 0 || FlatRatePolicy[i].Criteria == null )
        {
         Swal.fire({
             icon: 'info',
             text: `Criteria is required at Sr No. ${FlatRatePolicy[i].SrNo}...`,
         })
         return
        }
        if(typeof FlatRatePolicy[i].FlatRate === 'undefined' || (FlatRatePolicy[i].FlatRate) == 0 || FlatRatePolicy[i].FlatRate == null )
        {
         Swal.fire({
             icon: 'info',
             text: `Flat Rate is required at Sr No. ${FlatRatePolicy[i].SrNo}...`,
         })
         return
        }
       }
       
        setbtn_Disabled(true)
        SaveScheme(FlatRatePolicy,DeletedFlatRate,(mStatus)=>{
            setbtn_Disabled(false)
            if(mStatus){
                fetchFlatPolicy()
            }
            
        })
    }
}
const DiscountPolicy_Handler = () =>{
    if(DiscountPolicy.length > 0 || DeletedDiscountPolicy.length > 0){
      
        for(let  i=0; i<DiscountPolicy.length; i++){
            // if(typeof DiscountPolicy[i].TargetFromQty === 'undefined' || (DiscountPolicy[i].TargetFromQty) == 0 || DiscountPolicy[i].TargetFromQty == null )
            // {
            //  Swal.fire({
            //      icon: 'info',
            //      text: `TargetFromQty is required at Sr No. ${DiscountPolicy[i].SrNo}...`,
            //  })
            //  return
            // }
            // if(typeof DiscountPolicy[i].Criteria === 'undefined' || (DiscountPolicy[i].Criteria) == 0 || DiscountPolicy[i].Criteria == null )
            // {
            //  Swal.fire({
            //      icon: 'info',
            //      text: `Criteria is required at Sr No. ${DiscountPolicy[i].SrNo}...`,
            //  })
            //  return
            // } 
            if(typeof DiscountPolicy[i].Discount1Per === 'undefined' || (DiscountPolicy[i].Discount1Per) == 0 || DiscountPolicy[i].Discount1Per == null )
            {
             Swal.fire({
                 icon: 'info',
                 text: `Discount 1 % is required at Sr No. ${DiscountPolicy[i].SrNo}...`,
             })
             return
            }
            
        }
        
        setbtn_Disabled(true)
        SaveScheme(DiscountPolicy,DeletedDiscountPolicy,(mStatus)=>{
            setbtn_Disabled(false)
            if(mStatus){
                fetchDisPolicy()
            }
            
        })
    }
}
const BonusPolicy_Handler = () =>{
    if(BonusPolicy.length > 0 || DeletedBonusPolicy.length > 0){
        for(let  i=0; i<BonusPolicy.length; i++){
            if(typeof BonusPolicy[i].TargetFromQty === 'undefined' || (BonusPolicy[i].TargetFromQty) == 0 || BonusPolicy[i].TargetFromQty == null )
            {
             Swal.fire({
                 icon: 'info',
                 text: `TargetFromQty is required at Sr No. ${BonusPolicy[i].SrNo}...`,
             })
             return
            }
            if(typeof BonusPolicy[i].Criteria === 'undefined' || BonusPolicy[i].Criteria == null )
            {
             Swal.fire({
                 icon: 'info',
                 text: `Criteria is required at Sr No. ${BonusPolicy[i].SrNo}...`,
             })
             return
            } 
            if(typeof BonusPolicy[i].BonusQty === 'undefined' || (BonusPolicy[i].BonusQty) == 0 || BonusPolicy[i].BonusQty == null )
            {
             Swal.fire({
                 icon: 'info',
                 text: `BonusQty is required at Sr No. ${BonusPolicy[i].SrNo}...`,
             })
             return
            }
        }
        setbtn_Disabled(true)
        debugger
        SaveScheme(BonusPolicy,DeletedBonusPolicy,(mStatus)=>{
            setbtn_Disabled(false)
            if(mStatus){
                setDeletedBonusPolicy([])
                fetchBonusPolicy()
            }
            
        })
    }
}
const DiscountCGPolicy_Handler = () =>{
   
    if(DiscountCGPolicy.length > 0 || DeletedDiscountCGPolicy.length > 0){
        for(let i=0; i<DiscountCGPolicy.length; i++){
            if(typeof DiscountCGPolicy[i].ManufacturedByID === 'undefined' || (DiscountCGPolicy[i].ManufacturedByID) == 0 || DiscountCGPolicy[i].ManufacturedByID == null )
            {
             Swal.fire({
                 icon: 'info',
                 text: `Company is required at Sr No. ${DiscountCGPolicy[i].SrNo}...`,
             })   
             return
            }
          DiscountCGPolicy[i].PGID === 0 ? DiscountCGPolicy[i].IsAllForCompany = 1 : DiscountCGPolicy[i].IsAllForCompany = 0
          DiscountCGPolicy[i].PGID !== 0 ? DiscountCGPolicy[i].IsAllForPG = 1 : DiscountCGPolicy[i].IsAllForPG = 0
        }
       setbtn_Disabled(true)
        SaveScheme(DiscountCGPolicy,DeletedDiscountCGPolicy,(mStatus)=>{
            setbtn_Disabled(false)
            if(mStatus){
                fetchDiscountDiscountPG()
            }
            
        })
        
    }
}
const FlatbeforeRowInsert = (rowValue) => {

    if (rowValue.rowData) {
     
        if (rowValue.data) {
              
            if (rowValue.data.SrNo) {
                if (rowValue.requestType != "cancel") {
                    rowValue.data.ItemDiscription = Selected_item.ItemDiscription
                    rowValue.data.ItemID =Selected_item.ItemID
                    if(rowValue.data.SchemeID)
                    rowValue.data.action = rowValue.action
                    else
                    rowValue.data.action = 'add'
                    
                }
            }
            else {
                rowValue.data.SrNo = FlatRatePolicy.length + 1
                setSelected_item(null)

            }
        }
    }

}
const FlatafterRowInsert = (rowValue) => {
   
    if (rowValue.requestType == "beginEdit") {
        
            setSelected_item({ "ItemDiscription": rowValue.rowData.ItemDiscription, "ItemID": rowValue.rowData.ItemID})
    }
  
    if (rowValue.requestType === 'delete') {
        if (rowValue.data[0].SchemeID) {
            setDeletedFlatRate(DeletedFlatRate.concat(rowValue.data))
        }
    }
}
const DiscountbeforeRowInsert = (rowValue) => {

    if (rowValue.rowData) {
        if (rowValue.data) {
            if (rowValue.data.SrNo) {
                if (rowValue.requestType != "cancel") {
                    rowValue.data.ItemDiscription = DisSelected_item.ItemDiscription
                    rowValue.data.ItemID =DisSelected_item.ItemID
                    if(rowValue.data.SchemeID)
                     {rowValue.data.action = rowValue.action}
                    else
                  {
                    rowValue.data.action = 'add'
                    rowValue.data.IsStopDiscount =  rowValue.data.IsStopDiscount ?  1 : 0
                    rowValue.data.IsClaimable =  rowValue.data.IsClaimable ?  1 : 0
                    // rowValue.data.StartDate = GetCurrentDate()
                    // rowValue.data.EndDate = GetCurrentDate()
                    rowValue.data.RefrenceDate = GetCurrentDate()
                    setDisSelected_item(null)
                  }

                }
            }
            else {
                rowValue.data.SrNo = DiscountPolicy.length + 1
               
            }
        }
    }

}
const DiscountafterRowInsert = (rowValue) => {
    if (rowValue.requestType == "beginEdit") {
        setDisSelected_item({ "ItemDiscription": rowValue.rowData.ItemDiscription, "ItemID": rowValue.rowData.ItemID})
      }
    if (rowValue.requestType === 'delete') {
        if (rowValue.data[0].SchemeID) {
            
            setDeletedDiscountPolicy(DeletedDiscountPolicy.concat(rowValue.data))
        }
    }
   
}
const BonusbeforeRowInsert = (rowValue) => {

    if (rowValue.rowData) {
        if (rowValue.data) {
            if (rowValue.data.SrNo) {
                if (rowValue.requestType != "cancel") {
                    rowValue.data.ItemDiscription = BonusSelected_item.ItemDiscription
                    rowValue.data.ItemID =BonusSelected_item.ItemID
                    if(rowValue.data.SchemeID)
                    {
                        rowValue.data.action = rowValue.action
                    }
                    else{
                        rowValue.data.action = 'add'
                        rowValue.data.IsClaimable =  rowValue.data.IsClaimable ?  1 : 0
                        // rowValue.data.StartDate = GetCurrentDate()
                        // rowValue.data.EndDate = GetCurrentDate()
                        rowValue.data.RefrenceDate = GetCurrentDate()
                        setBonusSelected_item(null)
                    }
              

                }
            }
            else {
                rowValue.data.SrNo = BonusPolicy.length + 1
               
            }
        }
    }

}
const BonusafterRowInsert = (rowValue) => {
    if (rowValue.requestType == "beginEdit") {
        setBonusSelected_item({ "ItemDiscription": rowValue.rowData.ItemDiscription, "ItemID": rowValue.rowData.ItemID})
      }
    if (rowValue.requestType === 'delete') {
        if (rowValue.data[0].SchemeID) {
            setDeletedBonusPolicy(DeletedBonusPolicy.concat(rowValue.data))
        }
    }
   
}
const DiscountCGbeforeRowInsert = (rowValue) => {

    if (rowValue.rowData) {
        if (rowValue.data) {
            if (rowValue.data.SrNo) {
                if (rowValue.requestType != "cancel") {
                        rowValue.data.ManufacturedBy = SelectedManufacturedBy_ListOption && SelectedManufacturedBy_ListOption.ManufacturedBy
                        rowValue.data.ManufacturedByID =SelectedManufacturedBy_ListOption && SelectedManufacturedBy_ListOption.ManufacturedByID
                        rowValue.data.PGID =SelectedCG ? SelectedCG.PGID : 0
                        rowValue.data.PGName =SelectedCG ? SelectedCG.PGName :""
                        // rowValue.data.StartDate = GetCurrentDate()
                        // rowValue.data.EndDate = GetCurrentDate()
                        rowValue.data.RefrenceDate = GetCurrentDate()
                    if(rowValue.data.SchemeID)
                     {rowValue.data.action = rowValue.action}
                    else
                  {
                    rowValue.data.action = 'add'
                    rowValue.data.IsStopDiscount =  rowValue.data.IsStopDiscount ?  1 : 0
                    rowValue.data.IsClaimable =  rowValue.data.IsClaimable ?  1 : 0
                    // rowValue.data.StartDate = GetCurrentDate()
                    // rowValue.data.EndDate = GetCurrentDate()
                    rowValue.data.RefrenceDate = GetCurrentDate()
                    setDisSelected_item(null)
                  }

                }
            }
            else {
                rowValue.data.SrNo = DiscountPolicy.length + 1
                rowValue.data.StartDate = GetCurrentDate()
                rowValue.data.EndDate = GetCurrentDate()
            }
        }
    }

}
const DiscountCGafterRowInsert = (rowValue) => {
    if (rowValue.requestType == "beginEdit") {
        setSelectedManufacturedBy_ListOption({"ManufacturedBy": rowValue.rowData.ManufacturedBy, "ManufacturedByID": rowValue.rowData.ManufacturedByID})
        setSelectedCG({"PGName": rowValue.rowData.PGName, "PGID": rowValue.rowData.PGID})
        CGList(rowValue.rowData.ManufacturedByID)
      }
    if (rowValue.requestType === 'delete') {
        if (rowValue.data[0].SchemeID) {
           
            setDeletedDiscountCGPolicy(DeletedDiscountCGPolicy.concat(rowValue.data))
        }
    }
   
}
var MinFlatRate, MaxFlatRate
const ItemDiscription = (rowValue) => {
  
    return (
        <Autocomplete
            name="ItemDiscription"
            id="ItemDiscription"
            defaultValue={Item_List.current.value.find(v => v.ItemDiscription === rowValue.ItemDiscription)}
            options={Item_List.current.value}
            getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : ""}
            onChange={(e, value) => {
                if (value) {
                 
                        document.getElementById('FlatRate').value = value.FlatRate
                        MinFlatRate = value.MinFlatRate
                        MaxFlatRate = value.MaxFlatRate
                    
                    
                    setSelected_item(value)
                }
                else {
                    document.getElementById('FlatRate').value = 0
                    MinFlatRate = 0
                    MaxFlatRate = 0
                    setSelected_item(null)
                }
            }}
            renderInput={(params) =>
                <TextField {...params} name="ItemDiscription" />}
        />
    )
}
const DisItemDiscription = (rowValue) => {
  
    return (
        <Autocomplete
            name="DisItemDiscription"
            id="DisItemDiscription"
            defaultValue={Item_List.current.value.find(v => v.ItemDiscription === rowValue.ItemDiscription)}
            options={Item_List.current.value}
            getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : ""}
            onChange={(e, value) => {
                if (value) { 
                    setDisSelected_item(value)
                }
                else {
                    setDisSelected_item(null)
                }
            }}
            renderInput={(params) =>
                <TextField {...params} name="DisItemDiscription" />}
        />
    )
}
const BonusItemDiscription = (rowValue) => {
  
    return (
        <Autocomplete
            name="BonusItemDiscription"
            id="BonusItemDiscription"
            defaultValue={Item_List.current.value.find(v => v.ItemDiscription === rowValue.ItemDiscription)}
            options={Item_List.current.value}
            getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : ""}
            onChange={(e, value) => {
                if (value) { 
                    setBonusSelected_item(value)
                }
                else {
                    setBonusSelected_item(null)
                }
            }}
            renderInput={(params) =>
                <TextField {...params} name="BonusItemDiscription" />}
        />
    )
}
const  ManufacturerDD = (rowValue) => {
    
    return (
        <Autocomplete
            name="ManufacturedBy"
            id="ManufacturedBy"
            defaultValue={ManufacturedBy_List.current.value.find(v => v.ManufacturedBy === rowValue.ManufacturedBy)}
            options={ ManufacturedBy_List.current.value}
            getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""}
            onChange={(e, value) => {
                if (value) {
                    
                  setSelectedManufacturedBy_ListOption(value)
                  CGList(value.ManufacturedByID)
                    // this.setState({SelectedManufacturedBy_ListOption: value},()=>{
                        
                    //     this.CGList(value.ManufacturedByID) 
                    // })
                    
                }
                else {
                    setSelectedManufacturedBy_ListOption(null)
                    setSelectedCG(null)
                    // this.setState({SelectedManufacturedBy_ListOption: null, SelectedCG:null, CG_List:[]})
                   
                }
            }}
            renderInput={(params) =>
                <TextField {...params} name="ManufacturedBy" />}
        />
    )
}
// const  CGDD = (rowValue) => {
    
//     return (
//         <Autocomplete
//             name="CGName"
//             id="CGName"
//             defaultValue={CG_List.current.value.find(v => v.CGName === rowValue.CGName)}
//             options={ CG_List.current.value}
//             getOptionLabel={(option) => option.CGName ? option.CGName : ""}
//             onChange={(e, value) => {
//                 if (value) {
                    
//                   setSelectedCG(value)
                    
//                 }
//                 else {
//                     setSelectedCG(null)
//                 }
//             }}
//             renderInput={(params) =>
//                 <TextField {...params} name="CGName" />}
//         />
//     )
// }
const CGDD = (rowValue) => {
    mCDD = <Autocomplete
    name="PGName"
    id="PGName"
    defaultValue={CG_List.find(v => v.PGName === rowValue.PGName)}
    options={ CG_List}
    getOptionLabel={(option) => option.PGName ? option.PGName : ""}
    onChange={(e, value) => {
        if (value) {
          setSelectedCG(value)
        }
        else {
            setSelectedCG(null)
        }
    }}
    renderInput={(params) =>
        <TextField {...params} name="PGName" />}
/>
    return (
        mCDD
    )
}
const TargetFromQty = (rowValue) => {
    return (<input
        style={{ width: "80%" }}
        type="number"
        id="TargetFromQty"
        className="textbox"
        name='TargetFromQty'
        onInput={toInputUppercase}
        defaultValue={rowValue.TargetFromQty}
    />)
}
const QtyLimit = (rowValue) => {
    return (<input
        style={{ width: "80%" }}
        type="number"
        id="QtyLimit"
        className="textbox"
        name='QtyLimit'
        onInput={toInputUppercase}
        defaultValue={rowValue.QtyLimit}
    />)
}
const AmountLimit = (rowValue) => {
    return (<input
        style={{ width: "80%" }}
        type="text"
        id="AmountLimit"
        className="textbox"
        name='AmountLimit'
        onKeyPress={(event) => {
            // Allow only decimal values and backspace/delete
            const charCode = event.which ? event.which : event.keyCode;
            if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
              event.preventDefault();
            }
          }}
        defaultValue={rowValue.AmountLimit}
    />)
}
const Discount1Per = (rowValue) => {
    return (<input
        style={{ width: "80%" }}
        type="text"   
        id="Discount1Per"
        className="textbox"
        name='Discount1Per'
        onKeyPress={(event) => {
            // Allow only decimal values and backspace/delete
            const charCode = event.which ? event.which : event.keyCode;
            if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
              event.preventDefault();
            }
          }}
        defaultValue={rowValue.Discount1Per}
    />)
}
const Discount2Per = (rowValue) => {
    return (<input
        style={{ width: "80%" }}
        type="text"
        id="Discount2Per"
        className="textbox"
        name='Discount2Per'
        onKeyPress={(event) => {
            // Allow only decimal values and backspace/delete
            const charCode = event.which ? event.which : event.keyCode;
            if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
              event.preventDefault();
            }
          }}
        defaultValue={rowValue.Discount2Per}
    />)
}
const BonusQty = (rowValue) => {
    return (<input
        style={{ width: "80%" }}
        type="number"
        id="BonusQty"
        className="textbox"
        name='BonusQty'
        onInput={toInputUppercase}
        defaultValue={rowValue.BonusQty}
    />)
}

const IsClaimable = (rowValue) => {
    return (<input
        style={{ width: "40%" }}
        type="checkbox"
        id="IsClaimable"
        className="textbox"
        name='IsClaimable'
        onInput={toInputUppercase}
        defaultValue={rowValue.IsClaimable}
    />)
}
const OurSharePer = (rowValue) => {
    return (<input
        style={{ width: "80%" }}
        type="text"
        id="OurSharePer"
        className="textbox"
        name='OurSharePer'
      
        defaultValue={rowValue.OurSharePer}
        onKeyPress={(event) => {
          // Allow only decimal values and backspace/delete
          const charCode = event.which ? event.which : event.keyCode;
          if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
          }
        }}
      />)
}
const StartDate = (rowValue) => {
    return (<input
        style={{ width: "80%" }}
        type="date"
        id="StartDate"
        className="textbox"
        name='StartDate'
        onInput={toInputUppercase}
        defaultValue={rowValue.StartDate}
    />)
}
const EndDate = (rowValue) => {
    return (<input
        style={{ width: "80%" }}
        type="date"
        id="EndDate"
        className="textbox"
        name='EndDate'
        onInput={toInputUppercase}
        defaultValue={rowValue.EndDate}
    />)
}
const Criteria = (rowValue) => {
    return (<input
        style={{ width: "80%" }}
        type="number"
        id="Criteria"
        className="textbox"
        name='Criteria'
        onInput={toInputUppercase}
        defaultValue={rowValue.Criteria}
    />)
}
const FlatRate = (rowValue) => {
    return (<input
        style={{ width: "80%" }}
        type="text"
        id="FlatRate"
        className="textbox"
        name='FlatRate'
        onKeyPress={(event) => {
            // Allow only decimal values and backspace/delete
            const charCode = event.which ? event.which : event.keyCode;
            if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
              event.preventDefault();
            }
          }}
        defaultValue={rowValue.FlatRate}
        min={MinFlatRate}
        max={MaxFlatRate}
        
    />)
}
const Remarks = (rowValue) => {
    return (<input
        style={{ width: "80%" }}
        type="text"
        id="Remarks"
        className="textbox"
        name='Remarks'
        onInput={toInputUppercase}
        defaultValue={rowValue.Remarks}
    />)
}
const RefrenceNo = (rowValue) => {
    return (<input
        style={{ width: "80%" }}
        type="text"
        id="RefrenceNo"
        className="textbox"
        name='RefrenceNo'
        onInput={toInputUppercase}
        defaultValue={rowValue.RefrenceNo}
    />)
}
const RefrenceDate = (rowValue) => {
    return (<input
        style={{ width: "80%" }}
        type="date"
        id="RefrenceDate"
        className="textbox"
        name='RefrenceDate'
        defaultValue={rowValue.RefrenceDate}
    />)
}
    return ReactDOM.createPortal (
        <>
          <Row className="row_margin_top row_margin_left row_margin_right" >
                    <Col lg={6} xl={6} md={6} className="header_title" >
                        <h2>Customer</h2>
                    </Col>
                </Row>
            <Card>
              
                <Tabs
                defaultActiveKey="home"
                transition={false}
                id="noanim-tab-example"
                className="mb-3 ml-3 mr-3 row-margin-left row-margin-right row-margin-top"
                onSelect={e => {
                    setSelectedTab(e)
                }}
            >
                <Tab eventKey="home" title="Customers">
                <Row className="row_margin_top row_margin_left row_margin_right m-2" >
                <Col lg={4} xl={4} md={4} >
                    <TextField
                        name="CustomerCode"
                        id="CustomerCode"
                        label="CustomerCode"
                        value={Customer_Data.CustomerCode}
                        onChange={e => setCustomer_Data({ ...Customer_Data, CustomerCode: e.target.value })}
                        onInput={toInputUppercase}
                        fullWidth
                    />
                </Col>
                <Col lg={4} xl={4} md={4} >
                    <TextField
                        name="CustomerName"
                        id="CustomerName"
                        label="CustomerName"
                        value={Customer_Data.CustomerName}
                        onChange={e=>setCustomer_Data({...Customer_Data,CustomerName:e.target.value})}
                        onInput={toInputUppercase}
                        fullWidth
                    />
                    <span className="text-danger">{Item_Err.Name_Err}</span>
                </Col>
                <Col lg={4} xl={4} md={4} >
                    <Autocomplete
                        id="City"
                        name="City"
                        options={CityData}
                        value={SelectedCity}
                        getOptionLabel={(option) => option.City ? option.City : ""}
                        onChange={(e, value) => {

                            if (value) {
                                 setSelectedCity(value)
                                 SelectCityWiseAreas(Comp_Id,ProjectID,value.CityID,(mareas)=>{
                                     setAreaData(mareas)
                                 })
                            }
                            else {
                                 setSelectedCity(null)
                                 setAreaData([])
                                 setSelectedArea(null)
                            }
                        }}
                        renderInput={(params) => <TextField {...params}  name="City" label="City" />}
                    />
                    <span className="text-danger">{Item_Err.City_Err}</span>
                </Col>
                <Col lg={4} xl={4} md={4} >
                    <Autocomplete
                        id="Area"
                        name="Area"
                        options={AreaData}
                        value={SelectedArea}
                        getOptionLabel={(option) => option.AreaName ? option.AreaName : ""}
                        onChange={(e, value) => {

                            if (value) {
                                 setSelectedArea(value)
                            }
                            else {
                                 setSelectedArea(null)
                            }
                        }}
                        renderInput={(params) => <TextField {...params}  name="Area" label="Area" />}
                    />
                    <span className="text-danger">{Item_Err.Area_Err}</span>
                </Col>
                <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="Email"
                            id="Email"
                            label="Email"
                            value={Customer_Data.Email}
                            onChange={e=>setCustomer_Data({...Customer_Data,Email:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="PhoneNo"
                            id="PhoneNo"
                            type="number"
                            label="PhoneNo"
                            value={Customer_Data.PhoneNo}
                            onChange={e=>setCustomer_Data({...Customer_Data,PhoneNo:e.target.value})}
                            fullWidth
                        />
                        <span className="text-danger">{Item_Err.PhoneNo_Err}</span>
                    </Col>
                   
                    <Col lg={4} xl={4} md={4} >
                    <TextField
                        name="NTN"
                        id="NTN"
                        type="number"
                        label={Comp_Id === 6 ? "TRN" : "NTN"}
                        value={Customer_Data.NTN}
                        onChange={e=>setCustomer_Data({...Customer_Data,NTN:e.target.value})}
                        fullWidth
                    />
                </Col>
                <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="SaleTaxNo"
                            id="SaleTaxNo"
                            label="S T R N"
                            type="number"
                            value={Customer_Data.SaleTaxNo}
                            onChange={e=>setCustomer_Data({...Customer_Data,SaleTaxNo:e.target.value})}
                           
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="CNIC"
                            id="CNIC"
                            label="CNIC"
                            value={Customer_Data.CNIC}
                            onChange={e=>setCustomer_Data({...Customer_Data,CNIC:e.target.value})}
                            fullWidth
                        />
                    </Col>
                   <Col lg={4} xl={4} md={4} >
                    <Autocomplete
                        id="CustomerType"
                        name="CustomerType"
                        options={CustomerTypeData}
                        value={SelectedCustomerType}
                        getOptionLabel={(option) => option.title ? option.title : ""}
                        onChange={(e, value) => {

                            if (value) {
                                 setSelectedCustomerType(value)
                            }
                            else {
                                 setSelectedCustomerType(null)
                            }
                        }}
                        renderInput={(params) => <TextField {...params}  name="CustomerType" label="Customer Type" />}
                    />
                    <span className="text-danger">{Item_Err.CustomerType_Err}</span>
                </Col>
                <Col lg={4} xl={4} md={4} >
                    <Autocomplete
                        id="AdvanceTaxType"
                        name="AdvanceTaxType"
                        options={AdvanceTaxTypeData}
                        value={SelectedAdvanceTaxType}
                        getOptionLabel={(option) => option.title ? option.title : ""}
                        onChange={(e, value) => {

                            if (value) {
                                if(value.value === 'FILER'){
                                    setCustomer_Data({...Customer_Data,AddTaxPer: 0.5})

                                }
                                else if(value.value === 'NON FILER'){
                                    setCustomer_Data({...Customer_Data,AddTaxPer: 1})

                                }
                                else{
                                    setCustomer_Data({...Customer_Data,AddTaxPer: 0})

                                }
                                 setSelectedAdvanceTaxType(value)
                            }
                            else {
                                 setSelectedAdvanceTaxType(null)
                                 setCustomer_Data({...Customer_Data,AddTaxPer: 0})

                            }
                        }}
                        renderInput={(params) => <TextField {...params}  name="AdvanceTaxType" label="Advance Tax Type" />}
                    />
                    <span className="text-danger">{Item_Err.AdvanceTaxType_Err}</span>
                </Col>
                <Col lg={4} xl={4} md={4} >
                    <Autocomplete
                        id="CustomerClass"
                        name="CustomerClass"
                        options={CustomerClassData}
                        value={SelectedCustomerClass}
                        getOptionLabel={(option) => option.value ? option.value : ""}
                        onChange={(e, value) => {

                            if (value) {
                                 setSelectedCustomerClass(value)
                            }
                            else {
                                 setSelectedCustomerClass(null)
                            }
                        }}
                        renderInput={(params) => <TextField {...params}  name="CustomerClass" label="Customer Class" />}
                    />
                    <span className="text-danger">{Item_Err.CustomerClass_Err}</span>
                </Col>
                <Col lg={4} xl={4} md={4} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Customer_Data.Lis9}
                                    id="Lis9"
                                    label="Lis9"
                                    onChange={e => setCustomer_Data({ ...Customer_Data, Lis9: e.target.checked })}

                                    name="Access"
                                    color="primary"
                                />
                            }
                            label={"License 9"}
                            style={{
                                //     padding: "0px",
                                marginTop: "3%"
                            }}
                        />

                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Customer_Data.Lis10}
                                    id="Lis10"
                                    label="Lis10"
                                    onChange={e => setCustomer_Data({ ...Customer_Data, Lis10: e.target.checked })}

                                    name="Access"
                                    color="primary"
                                />
                            }
                            label={"License 10"}
                            style={{
                                marginTop: "3%"
                            }}
                        />

                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Customer_Data.Lis11}
                                    id="Lis11"
                                    label="Lis11"
                                    onChange={e => setCustomer_Data({ ...Customer_Data, Lis11: e.target.checked })}

                                    name="Access"
                                    color="primary"
                                />
                            }
                            label={"License 11"}
                            style={{
                                //     padding: "0px",
                                marginTop: "3%"
                            }}
                        />

                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="Lis9No"
                            id="Lis9No"
                            label="License 9 No"
                            disabled={!Customer_Data.Lis9}
                            value={Customer_Data.Lis9No}
                            onChange={e=>setCustomer_Data({...Customer_Data,Lis9No:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="Lis10No"
                            id="Lis10No"
                            label="License 10 No"
                            disabled={!Customer_Data.Lis10}
                            value={Customer_Data.Lis10No}
                            onChange={e=>setCustomer_Data({...Customer_Data,Lis10No:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="Lis11No"
                            id="Lis11No"
                            label="License 11 No"
                            value={Customer_Data.Lis11No}
                            disabled={!Customer_Data.Lis11}
                            onChange={e=>setCustomer_Data({...Customer_Data,Lis11No:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="Lis9Date"
                            id="Lis9Date"
                            label="License 9 Date"
                            type={"date"}
                            disabled={!Customer_Data.Lis9}
                            value={Customer_Data.Lis9Date}
                            onChange={e=>setCustomer_Data({...Customer_Data,Lis9Date:e.target.value})}
                            fullWidth
                        />
                    </Col>
                   
                    
                    <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="Lis10Date"
                            id="Lis10Date"
                            label="License 10 Date"
                            type={"date"}
                            disabled={!Customer_Data.Lis10}
                            value={Customer_Data.Lis10Date}
                            onChange={e=>setCustomer_Data({...Customer_Data,Lis10Date:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    
                   
                    <Col lg={4} xl={4} md={4} >
                        <TextField
                            name="Lis11Date"
                            id="Lis11Date"
                            label="License 11 Date"
                            type={"date"}
                            disabled={!Customer_Data.Lis11}
                            value={Customer_Data.Lis11Date}
                            onChange={e=>setCustomer_Data({...Customer_Data,Lis11Date:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col lg={7} xl={7} md={7} >
                        <TextField
                            name="Address"
                            id="Address"
                            label="Address"
                            onInput={toInputUppercase}
                            value={Customer_Data.Address}
                            onChange={e=>setCustomer_Data({...Customer_Data,Address:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={Customer_Data.IsActive}
                                    id="IsActive"
                                    label="IsActive"
                                    onChange={e => setCustomer_Data({ ...Customer_Data, IsActive: e.target.checked })}

                                    name="Access"
                                    color="primary"
                                />
                            }
                            label={"Active"}
                            style={{
                           
                                marginTop: "4%",
                                marginLeft:'2%'
                            }}
                        />

                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            name="AlternateCustCode"
                            id="AlternateCustCode"
                            label="AlternateCustCode"
                            onInput={toInputUppercase}
                            value={Customer_Data.AlternateCustCode}
                            onChange={e=>setCustomer_Data({...Customer_Data,AlternateCustCode:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                    <TextField
                        name="AddTaxPer"
                        label="AddTax U/S 236H %"
                        type='number'
                        value={Customer_Data.AddTaxPer}
                        onChange={e=>{
                                setCustomer_Data({...Customer_Data,AddTaxPer: e.target.valueAsNumber ?  e.target.valueAsNumber  : 0})
                        }}
                        fullWidth
                    />
                </Col>
               
                <Col lg={3} xl={3} md={3} >
                    <Autocomplete
                        id="SaleTaxType"
                        name="SaleTaxType"
                        options={SaleTaxTypeData}
                        value={SelectedSaleTaxType}
                        closeIcon={false}
                        getOptionLabel={(option) => option.title ? option.title : ""}
                        onChange={(e, value) => {
                            if (value) {
                                 setSelectedSaleTaxType(value)
                            }
                            else {
                                 setSelectedSaleTaxType(null)

                            }
                        }}
                        renderInput={(params) => <TextField {...params}  name="SaleTaxType" label="SALE Tax Register Type" />}
                    />
                    <span className="text-danger">{Item_Err.SaleTaxType_Err}</span>
                </Col>
                    </Row>
            <Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'2%'}} >
                <Col style={{ textAlign: "right" }}>
                    {btn_Edit &&(<button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={Save_Handler} disabled={btn_Disabled}>
                    {
                         btn_Disabled ?
                           (<><Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                /> Saving... </>) : <><Save /> Save</>
                            }  </button>)}
                    <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{ border: "none" }} to='/CustomerList' > <Cancel /> <u>Close</u></Link>

                </Col>
            </Row>
                </Tab>
              {/*  FLAT RATE POLICY */} 
                <Tab eventKey="FinYears" title="Flat Rate Policy"  disabled={isAddNew}>
                    <Row className="row-margin-left row-margin-right">
                    <Col lg={12} xl={12} sm={12}>
                    <Row className="row_margin_top row_margin_left row_margin_right pt-4" style={{ width: '80%', marginLeft: '10%' }} >
    <div style={{ display: "flex", width: "100%", height: "100%", overflow: "hidden" }}>
        <div style={{ flex: "1", width: "100%", height: "100%" }}>
            <GridComponent dataSource={FlatRatePolicy} actionBegin={FlatbeforeRowInsert} ref={g => grid = g} actionComplete={FlatafterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: btnFlatEdit, allowDeleting: btnFlatDelete, allowAdding: btnFlatAdd, newRowPosition: 'Bottom' }} sortSettings={sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={toolbarOptions}>
                <ColumnsDirective>
                    <ColumnDirective width='70' field='SrNo' headerText='SrNo #' Format="{0:n}" allowEditing={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                    <ColumnDirective width='250' field='CustomerName' headerText='Customer Name' defaultValue={Customer_Data.CustomerName} allowEditing={false} textAlign='Left' />
                    <ColumnDirective width='250' field='ItemDiscription' headerText='Item Discription'  editTemplate={ItemDiscription}  textAlign='Left'/>
                    <ColumnDirective width='250' field='TargetFromQty' headerText='From Target' editTemplate={TargetFromQty} textAlign='Left' />
                    <ColumnDirective width='250' field='Criteria' headerText='Criteria' editTemplate={Criteria} textAlign='Left' />
                    <ColumnDirective width='250' field='FlatRate' headerText='FlatRate' defaultValue={0}  editTemplate={FlatRate} textAlign='Left' />
                    <ColumnDirective width='250' field='Remarks' headerText='Remarks' editTemplate={Remarks} textAlign='Left' />

                    <ColumnDirective field='SchemeID' headerText='SchemeID' visible={false}></ColumnDirective>
                    <ColumnDirective field='CustomerID' headerText='CustomerID' visible={false} defaultValue={Customer_Data.CustomerID}></ColumnDirective>
                    <ColumnDirective field='ItemID' visible={false} ></ColumnDirective>
                    <ColumnDirective field='BonusQty' visible={false} defaultValue={0}></ColumnDirective>
                    <ColumnDirective field='QtyLimit' visible={false} defaultValue={0}></ColumnDirective>
                    <ColumnDirective field='AmountLimit' visible={false} defaultValue={0}></ColumnDirective>
                    <ColumnDirective field='IsStopDiscount' visible={false} defaultValue={0}></ColumnDirective>
                    <ColumnDirective field='IsFlatScheme' visible={false} defaultValue={1}></ColumnDirective>
                    <ColumnDirective field='IsBonusScheme' visible={false} defaultValue={0}></ColumnDirective>
                    <ColumnDirective field='IsDiscountScheme' visible={false} defaultValue={0}></ColumnDirective>
                    <ColumnDirective field='IsAllForCompany' visible={false} defaultValue={0}></ColumnDirective>
                    <ColumnDirective field='IsAllForPG' visible={false} defaultValue={0}></ColumnDirective>
                    <ColumnDirective field='ManufacturedByID' visible={false} defaultValue={0}></ColumnDirective>
                    <ColumnDirective field='PGID' visible={false} defaultValue={0}></ColumnDirective>
                    <ColumnDirective field='Discount1Per' visible={false} defaultValue={0}></ColumnDirective>
                    <ColumnDirective field='Discount2Per' visible={false} defaultValue={0}></ColumnDirective>
                    <ColumnDirective field='OurSharePer' visible={false} defaultValue={0}></ColumnDirective>
                    <ColumnDirective field='IsClaimable' visible={false} defaultValue={0}></ColumnDirective>
                    <ColumnDirective field='RefrenceNo' visible={false} defaultValue={'0'}></ColumnDirective>
                    <ColumnDirective field='RefrenceDate' visible={false} defaultValue={GetCurrentDate()}></ColumnDirective>
                    <ColumnDirective field='StartDate' visible={false} defaultValue={GetCurrentDate()}></ColumnDirective>
                    <ColumnDirective field='EndDate' visible={false} defaultValue={GetCurrentDate()}></ColumnDirective>
                    <ColumnDirective field='Comp_Id'  visible={false} defaultValue={Comp_Id}></ColumnDirective>
                    <ColumnDirective field='ProjectID' visible={false} defaultValue={ProjectID}></ColumnDirective>
                    <ColumnDirective field='UserID'  visible={false} defaultValue={UserID}></ColumnDirective>
                    <ColumnDirective field='IsCancle' visible={false} defaultValue={0}></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
            </GridComponent>
        </div>
    </div>
</Row>
           </Col>
                    </Row>
                    <Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'1%',marginTop:'1%', marginRight:'10%'}} >
                    <Col style={{ textAlign: "right" }}>
                        {btn_Edit && (
                        <button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={FlatRate_Handler} disabled={btn_Disabled}> 
                           {
                             btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                                }  </button>)}
                      
                        <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{ border: "none" }} to='/CustomerList' > <Cancel /> <u>Close</u></Link>

                    </Col>
                </Row>
                </Tab>
                  {/*  DISCOUNT POLICY */} 
                <Tab eventKey="UserProjects" title="Discount Policy"  disabled={isAddNew}>
                <Row className="row-margin-left row-margin-right">
                <Col lg={12} xl={12} sm={12}>
                <Row className="row_margin_top row_margin_left row_margin_right pt-4" style={{ width: '80%', marginLeft: '10%' }} >
<div style={{ display: "flex", width: "100%", height: "100%", overflow: "hidden" }}>
    <div style={{ flex: "1", width: "100%", height: "100%" }}>
        <GridComponent dataSource={DiscountPolicy} actionBegin={DiscountbeforeRowInsert} ref={g => grid = g} actionComplete={DiscountafterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: btnDiscountDelete, allowDeleting: btnFlatDelete, allowAdding: btnDiscountAdd, newRowPosition: 'Bottom' }} sortSettings={sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={toolbarOptions}>
            <ColumnsDirective>
                <ColumnDirective width='70' field='SrNo' headerText='SrNo #' Format="{0:n}" allowEditing={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                <ColumnDirective width='250' field='CustomerName' headerText='Customer Name' defaultValue={Customer_Data.CustomerName} allowEditing={false} textAlign='Left' />
                <ColumnDirective width='250' field='ItemDiscription' headerText='Item Discription'  editTemplate={DisItemDiscription}  textAlign='Left'/>
                <ColumnDirective width='150' field='TargetFromQty' headerText='From Target' editTemplate={TargetFromQty} textAlign='Left' />
                <ColumnDirective width='150' field='Criteria' headerText='Criteria' editTemplate={Criteria} textAlign='Left' />
                <ColumnDirective width='150' field='Discount1Per'  headerText='Discount 1 %' editTemplate={Discount1Per} textAlign='Left'></ColumnDirective>
                <ColumnDirective width='150' field='Discount2Per' defaultValue='0'  headerText='Discount 2 %' editTemplate={Discount2Per} textAlign='Left'></ColumnDirective> 
                {/*<ColumnDirective width='200' field='IsStopDiscount'   headerText='Stop Discount' editTemplate={IsStopDiscount} template = {IsStopDiscount} ></ColumnDirective>*/} 
                <ColumnDirective width='200' field='IsStopDiscount' type = "boolean" displayAsCheckBox = {true} editType = "booleanedit"  headerText='Stop Discount' ></ColumnDirective>
                <ColumnDirective width='150' field='OurSharePer' defaultValue='0' headerText='Our Share %' editTemplate={OurSharePer} textAlign='Left'></ColumnDirective>
                <ColumnDirective width='150' field='IsClaimable' type = "boolean" displayAsCheckBox = {true} editType = "booleanedit"  headerText='Claimable'></ColumnDirective>
                <ColumnDirective width='200' field='StartDate' headerText='Start Date' defaultValue={GetCurrentDate()} editTemplate={StartDate} textAlign='Left'></ColumnDirective>
                <ColumnDirective width='200' field='EndDate'  headerText='End Date' defaultValue={GetCurrentDate()} editTemplate={EndDate} textAlign='Left'></ColumnDirective>
                <ColumnDirective width='150' field='QtyLimit'  headerText='Qty Limit' defaultValue={0} editTemplate={QtyLimit} textAlign='Left' ></ColumnDirective>
                <ColumnDirective width='150' field='AmountLimit'  headerText='Amount Limit' defaultValue={0} editTemplate={AmountLimit} textAlign='Left'></ColumnDirective>
                <ColumnDirective width='150' field='RefrenceNo' defaultValue='' headerText='Refrence No' editTemplate={RefrenceNo} textAlign='Left'></ColumnDirective>
                <ColumnDirective width='150' field='RefrenceDate'  visible={false} defaultValue={GetCurrentDate()}></ColumnDirective>
                <ColumnDirective width='250' field='Remarks' defaultValue='' headerText='Remarks' editTemplate={Remarks} textAlign='Left' />

                <ColumnDirective field='SchemeID' headerText='SchemeID' visible={false}></ColumnDirective>
                <ColumnDirective field='CustomerID' headerText='CustomerID' visible={false} defaultValue={Customer_Data.CustomerID}></ColumnDirective>
                <ColumnDirective field='ItemID' visible={false}></ColumnDirective>
                <ColumnDirective field='BonusQty' visible={false} defaultValue={0}></ColumnDirective>
               
                <ColumnDirective field='IsFlatScheme' visible={false} defaultValue={0}></ColumnDirective>
                <ColumnDirective field='IsBonusScheme' visible={false} defaultValue={0}></ColumnDirective>
                <ColumnDirective field='IsDiscountScheme' visible={false} defaultValue={1}></ColumnDirective>
                <ColumnDirective field='IsAllForCompany' visible={false} defaultValue={0}></ColumnDirective>
                <ColumnDirective field='IsAllForPG' visible={false} defaultValue={0}></ColumnDirective>
                <ColumnDirective field='ManufacturedByID' visible={false} defaultValue={0}></ColumnDirective>
                <ColumnDirective field='PGID' visible={false} defaultValue={0}></ColumnDirective>
                <ColumnDirective width='250' field='FlatRate' visible={false} defaultValue={0} />
                     {/*  <ColumnDirective field='MaxFlatRate' headerText='MaxFlatRate' visible={false} defaultValue={Item_Data.MaxFlatRate}></ColumnDirective>
                <ColumnDirective field='MinFlatRate' headerText='MinFlatRate' visible={false} defaultValue={Item_Data.MinFlatRate}></ColumnDirective>*/}
                <ColumnDirective field='Comp_Id'  visible={false} defaultValue={Comp_Id}></ColumnDirective>
                <ColumnDirective field='ProjectID' visible={false} defaultValue={ProjectID}></ColumnDirective>
                <ColumnDirective field='UserID' visible={false} defaultValue={UserID}></ColumnDirective>
                <ColumnDirective field='IsCancle' visible={false} defaultValue={0}></ColumnDirective>
            </ColumnsDirective>
            <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
        </GridComponent>
    </div>
</div>
</Row>
                
                </Col>
                </Row>
                <Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'1%',marginTop:'1%', marginRight:'10%'}} >
                <Col style={{ textAlign: "right" }}>
                    {btn_Edit && (
                    <button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={DiscountPolicy_Handler} disabled={btn_Disabled}> 
                       {
                         btn_Disabled ?
                           (<><Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                /> Saving... </>) : <><Save /> Save</>
                            }  </button>)}
                  
                    <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{ border: "none" }} to='/CustomerList' > <Cancel /> <u>Close</u></Link>

                </Col>
               </Row>
                </Tab>
                  {/*  BONUS POLICY */} 
                <Tab eventKey="UserProjects1" title="Bonus Policy"  disabled={isAddNew}>
                <Row className="row-margin-left row-margin-right">
                <Col lg={12} xl={12} sm={12}>
                <Row className="row_margin_top row_margin_left row_margin_right pt-4" style={{ width: '80%', marginLeft: '10%' }} >
<div style={{ display: "flex", width: "100%", height: "100%", overflow: "hidden" }}>
    <div style={{ flex: "1", width: "100%", height: "100%" }}>
        <GridComponent dataSource={BonusPolicy} actionBegin={BonusbeforeRowInsert} ref={g => grid = g} actionComplete={BonusafterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: btnBonusEdit, allowDeleting: btnBonusDelete, allowAdding: btnBonusAdd, newRowPosition: 'Bottom' }} sortSettings={sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={toolbarOptions}>
            <ColumnsDirective>
                <ColumnDirective width='70' field='SrNo' headerText='SrNo #' Format="{0:n}" allowEditing={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>
              
                <ColumnDirective width='250' field='CustomerName' headerText='Customer Name' defaultValue={Customer_Data.CustomerName} allowEditing={false} textAlign='Left' />
                <ColumnDirective width='250' field='ItemDiscription' headerText='Item Discription'  editTemplate={BonusItemDiscription}  textAlign='Left'/>
                <ColumnDirective width='150' field='TargetFromQty' headerText='From Target' editTemplate={TargetFromQty} textAlign='Left' />
                <ColumnDirective width='150' field='Criteria' headerText='Criteria' editTemplate={Criteria} textAlign='Left' />
                <ColumnDirective width='150' field='BonusQty' headerText='Bonus Qty' editTemplate={BonusQty} textAlign='Left' ></ColumnDirective>
                <ColumnDirective width='150' field='OurSharePer' defaultValue='0' headerText='Our Share %' editTemplate={OurSharePer} textAlign='Left'></ColumnDirective>
                <ColumnDirective width='150' field='IsClaimable' type = "boolean" displayAsCheckBox = {true} editType = "booleanedit"  headerText='Claimable'></ColumnDirective>
                <ColumnDirective width='200' field='StartDate' headerText='Start Date' defaultValue={GetCurrentDate()} editTemplate={StartDate} textAlign='Left'></ColumnDirective>
                <ColumnDirective width='200' field='EndDate'  headerText='End Date' defaultValue={GetCurrentDate()} editTemplate={EndDate} textAlign='Left'></ColumnDirective>
                <ColumnDirective width='150' field='QtyLimit'  headerText='Qty Limit' defaultValue={0} editTemplate={QtyLimit} textAlign='Left' ></ColumnDirective>
                <ColumnDirective width='150' field='AmountLimit'  headerText='Amount Limit' defaultValue={0} editTemplate={AmountLimit} textAlign='Left'></ColumnDirective>
                <ColumnDirective width='150' field='RefrenceNo' defaultValue='' headerText='Refrence No' editTemplate={RefrenceNo} textAlign='Left'></ColumnDirective>
                <ColumnDirective field='RefrenceDate' visible={false} defaultValue={GetCurrentDate()}></ColumnDirective>
                <ColumnDirective width='250' field='Remarks' defaultValue='' headerText='Remarks' editTemplate={Remarks} textAlign='Left' />
                <ColumnDirective field='SchemeID' headerText='SchemeID' visible={false}></ColumnDirective>
                <ColumnDirective field='CustomerID' headerText='CustomerID' visible={false} defaultValue={Customer_Data.CustomerID}></ColumnDirective>
                <ColumnDirective field='ItemID' visible={false} ></ColumnDirective>
                <ColumnDirective field='Discount1Per' visible={false} defaultValue={0}></ColumnDirective>
                <ColumnDirective field='Discount2Per' visible={false} defaultValue={0}></ColumnDirective>
                <ColumnDirective field='IsFlatScheme' visible={false} defaultValue={0}></ColumnDirective>
                <ColumnDirective field='IsBonusScheme' visible={false} defaultValue={1}></ColumnDirective>
                <ColumnDirective field='IsStopDiscount' visible={false} defaultValue={0}></ColumnDirective>
                <ColumnDirective field='IsDiscountScheme' visible={false} defaultValue={0}></ColumnDirective>
                <ColumnDirective field='IsAllForCompany' visible={false} defaultValue={0}></ColumnDirective>
                <ColumnDirective field='IsAllForPG' visible={false} defaultValue={0}></ColumnDirective>
                <ColumnDirective field='ManufacturedByID' visible={false} defaultValue={0}></ColumnDirective>
                <ColumnDirective field='PGID' visible={false} defaultValue={0}></ColumnDirective>
                <ColumnDirective width='250' field='FlatRate' visible={false} defaultValue={0} />
                <ColumnDirective field='Comp_Id'  visible={false} defaultValue={Comp_Id}></ColumnDirective>
                <ColumnDirective field='ProjectID' visible={false} defaultValue={ProjectID}></ColumnDirective>
                <ColumnDirective field='UserID' visible={false} defaultValue={UserID}></ColumnDirective>
                <ColumnDirective field='IsCancle' visible={false} defaultValue={0}></ColumnDirective>
            </ColumnsDirective>
            <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
        </GridComponent>
    </div>
</div>
</Row>
                
                </Col>
                </Row>
                <Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'1%',marginTop:'1%', marginRight:'10%'}} >
                <Col style={{ textAlign: "right" }}>
                    {btn_Edit && (
                    <button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={BonusPolicy_Handler} disabled={btn_Disabled}> 
                       {
                         btn_Disabled ?
                           (<><Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                /> Saving... </>) : <><Save /> Save</>
                            }  </button>)}
                  
                    <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{ border: "none" }} to='/CustomerList' > <Cancel /> <u>Close</u></Link>

                </Col>
               </Row>
            </Tab>
              {/*  COMPANY || COMPANY GROUP POLICY */} 
              <Tab eventKey="UserProjects2" title="Discount Policy For Company || Group Policy"  disabled={isAddNew}>
                <Row className="row-margin-left row-margin-right">
                <Col lg={12} xl={12} sm={12}>
                <Row className="row_margin_top row_margin_left row_margin_right pt-4" style={{ width: '80%', marginLeft: '10%' }} >
<div style={{ display: "flex", width: "100%", height: "100%", overflow: "hidden" }}>
    <div style={{ flex: "1", width: "100%", height: "100%" }}>
    <GridComponent dataSource={DiscountCGPolicy} actionBegin={DiscountCGbeforeRowInsert} ref={g => grid = g} actionComplete={DiscountCGafterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: btnDiscountDelete, allowDeleting: btnFlatDelete, allowAdding: btnDiscountAdd, newRowPosition: 'Bottom' }} sortSettings={sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={toolbarOptions}>
    <ColumnsDirective>
        <ColumnDirective width='70' field='SrNo' headerText='SrNo #' Format="{0:n}" allowEditing={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>
        <ColumnDirective width='250' field='CustomerName' headerText='Customer Name' defaultValue={Customer_Data.CustomerName} allowEditing={false} textAlign='Left' />
        <ColumnDirective width='250' field='ManufacturedBy' headerText='Manufactured By'  editTemplate={ManufacturerDD}  textAlign='Left'/>
        <ColumnDirective width='250' field='PGName' headerText='Product Group'  editTemplate={CGDD}  textAlign='Left'/>
        <ColumnDirective width='150' field='TargetFromQty' headerText='From Target' editTemplate={TargetFromQty} textAlign='Left' />
        <ColumnDirective width='150' field='Criteria' headerText='Criteria' editTemplate={Criteria} textAlign='Left' />
        <ColumnDirective width='150' field='Discount1Per'  headerText='Discount 1 %' editTemplate={Discount1Per} textAlign='Left'></ColumnDirective>
        <ColumnDirective width='150' field='Discount2Per' defaultValue='0'  headerText='Discount 2 %' editTemplate={Discount2Per} textAlign='Left'></ColumnDirective> 
        {/*<ColumnDirective width='200' field='IsStopDiscount'   headerText='Stop Discount' editTemplate={IsStopDiscount} template = {IsStopDiscount} ></ColumnDirective>*/} 
        <ColumnDirective width='200' field='IsStopDiscount' type = "boolean" displayAsCheckBox = {true} editType = "booleanedit"  headerText='Stop Discount' ></ColumnDirective>
        <ColumnDirective width='150' field='OurSharePer' defaultValue='0' headerText='Our Share %' editTemplate={OurSharePer} textAlign='Left'></ColumnDirective>
        <ColumnDirective width='150' field='IsClaimable' type = "boolean" displayAsCheckBox = {true} editType = "booleanedit"  headerText='Claimable'></ColumnDirective>
        <ColumnDirective width='200' field='StartDate' headerText='Start Date' defaultValue={GetCurrentDate()} editTemplate={StartDate} textAlign='Left'></ColumnDirective>
        <ColumnDirective width='200' field='EndDate'  headerText='End Date' defaultValue={GetCurrentDate()} editTemplate={EndDate} textAlign='Left'></ColumnDirective>
        <ColumnDirective width='150' field='QtyLimit'  headerText='Qty Limit' defaultValue={0} editTemplate={QtyLimit} textAlign='Left' ></ColumnDirective>
        <ColumnDirective width='150' field='AmountLimit'  headerText='Amount Limit' defaultValue={0} editTemplate={AmountLimit} textAlign='Left'></ColumnDirective>
        <ColumnDirective width='150' field='RefrenceNo' defaultValue='' headerText='Refrence No' editTemplate={RefrenceNo} textAlign='Left'></ColumnDirective>
        <ColumnDirective width='150' field='RefrenceDate'  visible={false} defaultValue={GetCurrentDate()}></ColumnDirective>
        <ColumnDirective width='250' field='Remarks' defaultValue='' headerText='Remarks' editTemplate={Remarks} textAlign='Left' />

        <ColumnDirective field='SchemeID' headerText='SchemeID' visible={false}></ColumnDirective>
        <ColumnDirective field='CustomerID' headerText='CustomerID' visible={false} defaultValue={Customer_Data.CustomerID}></ColumnDirective>
        <ColumnDirective field='ItemID' visible={false} defaultValue={0}></ColumnDirective>
        <ColumnDirective field='BonusQty' visible={false} defaultValue={0}></ColumnDirective>
       
        <ColumnDirective field='IsFlatScheme' visible={false} defaultValue={0}></ColumnDirective>
        <ColumnDirective field='IsBonusScheme' visible={false} defaultValue={0}></ColumnDirective>
        <ColumnDirective field='IsDiscountScheme' visible={false} defaultValue={1}></ColumnDirective>
        <ColumnDirective field='IsAllForCompany' visible={false} defaultValue={0}></ColumnDirective>
        <ColumnDirective field='IsAllForPG' visible={false} defaultValue={0}></ColumnDirective>
        <ColumnDirective field='ManufacturedByID' visible={false} ></ColumnDirective>
        <ColumnDirective field='PGID' visible={false} defaultValue={0}></ColumnDirective>
        <ColumnDirective width='250' field='FlatRate' visible={false} defaultValue={0} />
             {/*  <ColumnDirective field='MaxFlatRate' headerText='MaxFlatRate' visible={false} defaultValue={Item_Data.MaxFlatRate}></ColumnDirective>
        <ColumnDirective field='MinFlatRate' headerText='MinFlatRate' visible={false} defaultValue={Item_Data.MinFlatRate}></ColumnDirective>*/}
        <ColumnDirective field='Comp_Id'  visible={false} defaultValue={Comp_Id}></ColumnDirective>
        <ColumnDirective field='ProjectID' visible={false} defaultValue={ProjectID}></ColumnDirective>
        <ColumnDirective field='UserID' visible={false} defaultValue={UserID}></ColumnDirective>
        <ColumnDirective field='IsCancle' visible={false} defaultValue={0}></ColumnDirective>
    </ColumnsDirective>
    <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
</GridComponent>
    </div>
</div>
</Row>
                
                </Col>
                </Row>
                <Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'1%',marginTop:'1%', marginRight:'10%'}} >
                <Col style={{ textAlign: "right" }}>
                    {btn_Edit && (
                    <button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={DiscountCGPolicy_Handler} disabled={btn_Disabled}> 
                       {
                         btn_Disabled ?
                           (<><Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                /> Saving... </>) : <><Save /> Save</>
                            }  </button>)}
                  
                    <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{ border: "none" }} to='/CustomerList' > <Cancel /> <u>Close</u></Link>

                </Col>
               </Row>
            </Tab>

            </Tabs>
            </Card>
        </> , document.getElementById('PageContents')
    )
}
 
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    CustomerID: state.Customer && state.Customer.CustomerID,
    isAddNew: state.Customer && state.Customer.isAddNew,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
    show_screen: state.SCREEN
  })
export default connect(mapStateToProps)(CustomerEntry)
