import React, { useState, useEffect, useRef } from 'react'
import  ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { Row, Col, Card,Spinner ,Tabs, Tab  } from "react-bootstrap"
import { Cancel, Save, Print } from '@material-ui/icons'
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { connect } from 'react-redux'
import { enableRipple } from '@syncfusion/ej2-base';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import { getValue } from '@syncfusion/ej2-base';
import Swal from 'sweetalert2'
import {SelectManufacturedByList} from '../../Services/manufactureAPI'
import { SaveScheme,SelectItemForDisCompanyAndGroup } from '../../Services/ItemsAPI'
import { SelectProductGroupAgainstManufacturer } from '../../Services/ProductGroupAPI'
import { GetCurrentDate } from '../../Services/Common'

const DiscountPolicy = ({ Comp_Id, isAddNew, ItemID,UserID,Comp_Address, show_screen,ProjectID }) => {
    // Grid Buttons
    const [btnDisCGAdd, setbtnDisCGAdd] = useState(true)
    const [btnDisCGEdit, setbtnDisCGEdit] = useState(true)
    const [btnDisCGDelete, setbtnDisCGDelete] = useState(true)
    const [btn_edit, setBtn_edit] = useState(true)
    const [btn_Disabled, setbtn_Disabled] = useState(false)
    // Grid Binding 
    const [DiscountCGPolicy, setDiscountCGPolicy] = useState([])
    const [DeletedDiscountCGPolicy, setDeletedDiscountCGPolicy] = useState([])
    // const [CG_List, setCG_List] = useState([])
    const [SelectedCG, setSelectedCG] = useState(null)
    // Manufacturer 
//    const [ManufacturedBy_List, setManufacturedBy_List] = useState([])
    const [SelectedManufacturedBy_ListOption, setSelectedManufacturedBy_ListOption] = useState(null)
    const [DeletedDiscountPolicy, setDeletedDiscountPolicy] = useState([])

    const [test,setTest] = useState([])
    // Grid requirments
    const bg_Ref = React.createRef()
   var grid = null
   const toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
   const validationRules = { required: true };
   const customBGValidation =(args)=> {
    return getValue('value', args) != ''
}
  const BGValidation = {
    required: [customBGValidation, 'Flat Policy must be Entered'],
};
const sortingOptions = {
    columns: [{ field: 'SrNo', direction: 'Ascending' }]
};
var mDD;
 const ManufacturedBy_List = useRef([])
  const CG_List = useRef([])


useEffect(()=>{
    document.title = 'Discount Policy'
    SelectManufacturedByList(Comp_Id,ProjectID,(mData)=>{
        ManufacturedBy_List.current.value = mData
    })
    SelectItemForDisCompanyAndGroup(Comp_Id,ProjectID,(mData)=>{
     
        if(mData.length > 0){
           mData.map((value,index1)=>{
               value.SrNo = index1 + 1
               if(value.PGID === 0){
                value.PGName = 'SELECT ALL'
               }
           })
           setDiscountCGPolicy(mData)
        }
        
       })

},[])
 const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    const CGList = (ManufacturedByID)=>{
        SelectProductGroupAgainstManufacturer(Comp_Id,ProjectID,ManufacturedByID,(mData)=>{
            
            var mobj = {PGID:0, PGName: 'SELECT ALL'}
                mData.unshift(mobj)
                if(mDD){
                    mDD.props.options.length = 0 
                    for(let i=0; i<mData.length; i++){

                        mDD.props.options.push(mData[i])
                    }
                }
                
                CG_List.current.value = mData               
        })
    }
    const DiscountCGbeforeRowInsert = (rowValue) => {

        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.SrNo) {
                    if (rowValue.requestType != "cancel") {
                        rowValue.data.ManufacturedBy = SelectedManufacturedBy_ListOption.ManufacturedBy
                        rowValue.data.ManufacturedByID =SelectedManufacturedBy_ListOption.ManufacturedByID
                        rowValue.data.PGID = SelectedCG.PGID
                        rowValue.data.PName = SelectedCG.PGName
                      
                        
                        if(rowValue.data.SchemeID)
                         {rowValue.data.action = rowValue.action}
                        else
                      {
                        rowValue.data.action = 'add'
                        rowValue.data.IsStopDiscount =  rowValue.data.IsStopDiscount ?  1 : 0
                        rowValue.data.IsClaimable =  rowValue.data.IsClaimable ?  1 : 0
                        setSelectedManufacturedBy_ListOption(null)
                        setSelectedCG(null)
                      }

                    }
                }
                else {
                    rowValue.data.SrNo = DiscountPolicy.length + 1
                   
                }
            }
        }

    }
    const DiscountCGafterRowInsert = (rowValue) => {
        if (rowValue.requestType == "beginEdit") {
            
            setSelectedManufacturedBy_ListOption({ "ManufacturedBy": rowValue.rowData.ManufacturedBy, "ManufacturedByID": rowValue.rowData.ManufacturedByID})
          
            setSelectedCG({"PGName": rowValue.rowData.PGName, "PGID": rowValue.rowData.PGID})
          }
        if (rowValue.requestType === 'delete') {
            if (rowValue.data[0].SchemeID) {
                setDeletedDiscountPolicy(DeletedDiscountPolicy.concat(rowValue.data))
            }
        }
       
    }
    const ItemDiscription = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="text"
            id="ItemDiscription"
            className="textbox"
            name='ItemDiscription'
            disabled= {true}
            onInput={toInputUppercase}
            defaultValue={rowValue.ItemDiscription}
        />)
    }
    const ManufacturerDD = (rowValue) => {
  
        return (
            <Autocomplete
                name="ManufacturedBy"
                id="ManufacturedBy"
                defaultValue={ ManufacturedBy_List.current.value.find(v => v.ManufacturedBy === rowValue.ManufacturedBy)}
                options={ ManufacturedBy_List.current.value}
                getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""}
                onChange={(e, value) => {
                    if (value) {
                        setSelectedManufacturedBy_ListOption(value)
                        
                         CGList(value.ManufacturedByID) 
                    }
                    else {
                        setSelectedManufacturedBy_ListOption(null)
                        setSelectedCG(null)
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="ManufacturedBy" />}
            />
        )
    }
  
    const CGDD = (rowValue) => {
  
  mDD =  <Autocomplete
  name="PG"
  id="_PG"
  // defaultValue={CG_List.current.value && CG_List.current.value.find(v => v.CGName === rowValue.CGName)}
  options={test}
  getOptionLabel={(option) => option.PGName ? option.PGName : ""} 
  onChange={(e,value) =>{
      if(value){
          setSelectedCG(value)
      }
      else{
          setSelectedCG(null) 
      }  
      }}
      style={{marginBottom:'15px'}}
      renderInput={(params) =>
          <TextField {...params} name="PG"  />}
  />
        return (
            mDD
        )
    }

    const TargetFromQty = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"
            id="TargetFromQty"
            className="textbox"
            name='TargetFromQty'
            onInput={toInputUppercase}
            defaultValue={rowValue.TargetFromQty}
        />)
    }
    const Discount1Per = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"   
            id="Discount1Per"
            className="textbox"
            name='Discount1Per'
            onInput={toInputUppercase}
            defaultValue={rowValue.Discount1Per}
        />)
    }
    const Discount2Per = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"
            id="Discount2Per"
            className="textbox"
            name='Discount2Per'
            onInput={toInputUppercase}
            defaultValue={rowValue.Discount2Per}
        />)
    }
    const BonusQty = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"
            id="BonusQty"
            className="textbox"
            name='BonusQty'
            onInput={toInputUppercase}
            defaultValue={rowValue.BonusQty}
        />)
    }
    const IsClaimable = (rowValue) => {
        return (<input
            style={{ width: "40%" }}
            type="checkbox"
            id="IsClaimable"
            className="textbox"
            name='IsClaimable'
            onInput={toInputUppercase}
            defaultValue={rowValue.IsClaimable}
        />)
    }
    const OurSharePer = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"
            id="OurSharePer"
            className="textbox"
            name='OurSharePer'
            onInput={toInputUppercase}
            defaultValue={rowValue.OurSharePer}
        />)
    }
    const StartDate = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="date"
            id="StartDate"
            className="textbox"
            name='StartDate'
            onInput={toInputUppercase}
            defaultValue={rowValue.StartDate}
        />)
    }
    const EndDate = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="date"
            id="EndDate"
            className="textbox"
            name='EndDate'
            onInput={toInputUppercase}
            defaultValue={rowValue.EndDate}
        />)
    }
    const Criteria = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"
            id="Criteria"
            className="textbox"
            name='Criteria'
            onInput={toInputUppercase}
            defaultValue={rowValue.Criteria}
        />)
    }
    const FlatRate = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"
            id="FlatRate"
            className="textbox"
            name='FlatRate'
            onInput={toInputUppercase}
            defaultValue={rowValue.FlatRate}
          
        />)
    }
    const Remarks = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="text"
            id="Remarks"
            className="textbox"
            name='Remarks'
            onInput={toInputUppercase}
            defaultValue={rowValue.Remarks}
        />)
    }
    const RefrenceNo = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="text"
            id="RefrenceNo"
            className="textbox"
            name='RefrenceNo'
            onInput={toInputUppercase}
            defaultValue={rowValue.RefrenceNo}
        />)
    }
    const RefrenceDate = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="date"
            id="RefrenceDate"
            className="textbox"
            name='RefrenceDate'
            defaultValue={rowValue.RefrenceDate}
        />)
    }
    const DiscountPolicy_Handler = () =>{
        if(DiscountCGPolicy.length > 0){
          
            for(let  i=0; i<DiscountCGPolicy.length; i++){
                if(typeof DiscountCGPolicy[i].TargetFromQty === 'undefined' || (DiscountCGPolicy[i].TargetFromQty) == 0 || DiscountCGPolicy[i].TargetFromQty == null )
                {
                 Swal.fire({
                     icon: 'info',
                     text: `TargetFromQty is required at Sr No. ${DiscountCGPolicy[i].SrNo}...`,
                 })
                 return
                }
                if(typeof DiscountCGPolicy[i].Criteria === 'undefined' || (DiscountCGPolicy[i].Criteria) == 0 || DiscountCGPolicy[i].Criteria == null )
                {
                 Swal.fire({
                     icon: 'info',
                     text: `Criteria is required at Sr No. ${DiscountCGPolicy[i].SrNo}...`,
                 })
                 return
                } 
                if(typeof DiscountCGPolicy[i].Discount1Per === 'undefined' || (DiscountCGPolicy[i].Discount1Per) == 0 || DiscountCGPolicy[i].Discount1Per == null )
                {
                 Swal.fire({
                     icon: 'info',
                     text: `Discount 1 % is required at Sr No. ${DiscountCGPolicy[i].SrNo}...`,
                 })
                 return
                }
                DiscountCGPolicy[i].PGID === 0 ?  DiscountCGPolicy[i].IsAllForCompany = 1 :  DiscountCGPolicy[i].IsAllForCompany = 0
                DiscountCGPolicy[i].PGID !== 0 ?  DiscountCGPolicy[i].IsAllForPG = 1 :  DiscountCGPolicy[i].IsAllForPG = 0
            }
           
            setbtn_Disabled(true)
            SaveScheme(DiscountCGPolicy,()=>{
                setbtn_Disabled(false)
                document.getElementById('btnCancel').click()
            })
        }
    }
    return ReactDOM.createPortal  (
    <>
    <div className="block-header">
    <div className="row">
        <div className="col-lg-5 col-md-5 col-sm-12">
            <h2>Discount Policy For Company || Groups
            </h2>
        </div>
    </div>
</div>
<Card>
<Row className="row-margin-left row-margin-right">
<Col lg={12} xl={12} sm={12}>
<Row className="row_margin_top row_margin_left row_margin_right pt-4" style={{ width: '80%', marginLeft: '10%' }} >
<div style={{ display: "flex", width: "100%", height: "100%", overflow: "hidden" }}>
<div style={{ flex: "1", width: "100%", height: "100%" }}>
<GridComponent dataSource={DiscountCGPolicy} actionBegin={DiscountCGbeforeRowInsert} ref={g => grid = g} actionComplete={DiscountCGafterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: btnDisCGEdit, allowDeleting: btnDisCGDelete, allowAdding: btnDisCGAdd, newRowPosition: 'Bottom' }} sortSettings={sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={toolbarOptions}>
<ColumnsDirective>
<ColumnDirective width='70' field='SrNo' headerText='SrNo #' Format="{0:n}" allowEditing={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>

<ColumnDirective width='250' field='ManufacturedBy' headerText='Manufactured By'  editTemplate={ManufacturerDD}  textAlign='Left'/>
<ColumnDirective width='250' field='PGName' headerText='Product Group'  editTemplate={CGDD}  textAlign='Left'/>
<ColumnDirective width='150' field='TargetFromQty' headerText='From Target' editTemplate={TargetFromQty} textAlign='Left' />
<ColumnDirective width='150' field='Criteria' headerText='Criteria' editTemplate={Criteria} textAlign='Left' />
<ColumnDirective width='150' field='Discount1Per'  headerText='Discount 1 %' editTemplate={Discount1Per} textAlign='Left'></ColumnDirective>
<ColumnDirective width='150' field='Discount2Per' defaultValue='0'  headerText='Discount 2 %' editTemplate={Discount2Per} textAlign='Left'></ColumnDirective> 
{/*<ColumnDirective width='200' field='IsStopDiscount'   headerText='Stop Discount' editTemplate={IsStopDiscount} template = {IsStopDiscount} ></ColumnDirective>*/} 
<ColumnDirective width='200' field='IsStopDiscount' type = "boolean" displayAsCheckBox = {true} editType = "booleanedit"  headerText='Stop Discount' ></ColumnDirective>
<ColumnDirective width='150' field='OurSharePer' defaultValue='0' headerText='Our Share %' editTemplate={OurSharePer} textAlign='Left'></ColumnDirective>
<ColumnDirective width='150' field='IsClaimable' type = "boolean" displayAsCheckBox = {true} editType = "booleanedit"  headerText='Claimable'></ColumnDirective>
<ColumnDirective width='200' field='StartDate' defaultValue={GetCurrentDate()} headerText='Start Date' editTemplate={StartDate} textAlign='Left'></ColumnDirective>
<ColumnDirective width='200' field='EndDate' defaultValue={GetCurrentDate()}  headerText='End Date' editTemplate={EndDate} textAlign='Left'></ColumnDirective>
<ColumnDirective width='150' field='RefrenceNo' defaultValue='' headerText='Refrence No' editTemplate={RefrenceNo} textAlign='Left'></ColumnDirective>
<ColumnDirective width='150' field='RefrenceDate'  visible={false} defaultValue={GetCurrentDate()}></ColumnDirective>
<ColumnDirective width='250' field='Remarks' defaultValue='' headerText='Remarks' editTemplate={Remarks} textAlign='Left' />

<ColumnDirective field='SchemeID' headerText='SchemeID' visible={false}></ColumnDirective>
<ColumnDirective field='ItemID' headerText='ItemID' visible={false} defaultValue={0}></ColumnDirective>
<ColumnDirective field='CustomerID' visible={false} defaultValue={0}></ColumnDirective>
<ColumnDirective field='BonusQty' visible={false} defaultValue={0}></ColumnDirective>
<ColumnDirective field='QtyLimit' visible={false} defaultValue={0}></ColumnDirective>
<ColumnDirective field='AmountLimit' visible={false} defaultValue={0}></ColumnDirective>
<ColumnDirective field='IsFlatScheme' visible={false} defaultValue={0}></ColumnDirective>
<ColumnDirective field='IsBonusScheme' visible={false} defaultValue={0}></ColumnDirective>
<ColumnDirective field='IsDiscountScheme' visible={false} defaultValue={1}></ColumnDirective>
<ColumnDirective field='IsAllForCompany' visible={false} defaultValue={0}></ColumnDirective>
<ColumnDirective field='IsAllForPG' visible={false} defaultValue={0}></ColumnDirective>
<ColumnDirective field='ManufacturedByID' visible={false} ></ColumnDirective>
<ColumnDirective field='PGID' visible={false} defaultValue={0}></ColumnDirective>
<ColumnDirective width='250' field='FlatRate' visible={false} defaultValue={0} />
{/*<ColumnDirective field='MaxFlatRate' headerText='MaxFlatRate' visible={false} defaultValue={Item_Data.MaxFlatRate}></ColumnDirective>
<ColumnDirective field='MinFlatRate' headerText='MinFlatRate' visible={false} defaultValue={Item_Data.MinFlatRate}></ColumnDirective>*/}
<ColumnDirective field='Comp_Id'  visible={false} defaultValue={Comp_Id}></ColumnDirective>
<ColumnDirective field='ProjectID' visible={false} defaultValue={ProjectID}></ColumnDirective>
<ColumnDirective field='UserID' visible={false} defaultValue={UserID}></ColumnDirective>
</ColumnsDirective>
<Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
</GridComponent>
</div>
</div>
</Row>

</Col>
</Row>
<Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'1%',marginTop:'1%', marginRight:'10%'}} >
<Col style={{ textAlign: "right" }}>
    {btn_edit && (
    <button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={DiscountPolicy_Handler} disabled={btn_Disabled}> 
       {
         btn_Disabled ?
           (<><Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                /> Saving... </>) : <><Save /> Save</>
            }  </button>)}
  
    <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{ border: "none" }} to='/DiscountPolicy' > <Cancel /> <u>Close</u></Link>

</Col>
</Row>
</Card>
    </>, document.getElementById('PageContents')
    
  )
}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    isAddNew:state.Items && state.Items.isAddNew,
    ItemID: state.Items && state.Items.ItemID,
    UserID: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    show_screen: state.SCREEN,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

  })
export default connect(mapStateToProps)(DiscountPolicy)
// export default DiscountPolicy