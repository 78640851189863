import React, { useState, useEffect } from 'react'
import  ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { Row, Col, Card,Spinner   } from "react-bootstrap"
import { Cancel, Save, Print,Delete, FormatListBulleted } from '@material-ui/icons'
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { connect } from 'react-redux'
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Swal from "sweetalert2"
import { SelectSupplierList } from '../../Services/SuppliersAPI'
import { SelectCityList } from '../../Services/CityAPI'
import { GetMaxDeliveryNo,SelectDeliveryMaster, SelectDeliveryDetail,
     DeleteDelivery, SaveDelivery, SelectCityForDelivery } from '../../Services/DeliveryAPI'
import { GetCurrentDate } from '../../Services/Common'
import { SelectInvoiceForDelivery } from '../../Services/SaleInvoiceAPI'
import { SelectVehicleList } from "../../Services/VehicleAPI"
import { SelectDriverList } from "../../Services/DriverAPI"
import { SelectSalesMan } from "../../Services/SecurityAPI"

function DeliveryEntry({ Comp_Id, isAddNew, DeliveryID,UserID,Comp_Address, show_screen,ProjectID }) {
    const [Salesman_List, setSalesman_List] = useState([])
    const [SelectedSalesman, setSelectedSalesman] = useState(null)
    const [City_List, setCity_List] = useState([])
    const [SelectedCityOption, setSelectedCityOption] = useState([])              
    const [InvoiceLIst, setInvoiceLIst] = useState([])
    var [SelectedInvoice, setSelectedInvoice] = useState(null)
    const [FromDate, setFromDate] = useState(GetCurrentDate())
    const [ToDate, setToDate] = useState(GetCurrentDate())
    const [btn_edit, setBtn_edit] = useState(true)
    const [DeliveryDetail_Data, setDeliveryDetail_Data] = useState([])
    const [DeletedDeliveryDetailData, setDeletedDeliveryDetailData] = useState([])
    const [Delivery_Data,setDelivery_Data] = useState({
        DeliveryID:DeliveryID,
        Comp_Id:Comp_Id,
        ProjectID:ProjectID,
        DeliveryCode:'',
        DeliveryName:'',
        DriverName:'',
        VehicleNo:'',
        DeliveryDate:GetCurrentDate()
    })
    const [Delivery_Err, setDelivery_Err] = useState({
        Name_Err: '',
        DriverName_Err: '',
        City_Err: '',
        Supplier_Err: '',
        Vehicle_Err:'',
    })
    const [VehicleList, setVehicleList] = useState([])
    const [SelectedVehicle, setSelectedVehicle] = useState(null)
    const [DriverList, setDriverList] = useState([])
    const [SelectedDriver, setSelectedDriver] = useState(null)
    const [btn_Disabled, setBtn_Disabled] = useState(false)
    var mArray = []
    useEffect(() => {
        document.title = " Distribution / Delivery"

        SelectSalesMan(Comp_Id, (mSalesman) => {

            setSalesman_List(mSalesman)

            SelectCityList(Comp_Id, ProjectID, (mCity) => {
                setCity_List(mCity)
                SelectDriverList(Comp_Id,ProjectID,(mDrivers)=>{
                    setDriverList(mDrivers)
                    SelectVehicleList(Comp_Id,ProjectID,(mVehicles)=>{
                        setVehicleList(mVehicles)
                if (isAddNew) {

                    GetMaxDeliveryNo(Comp_Id, ProjectID, (mCode) => {
                        setDelivery_Data({ ...Delivery_Data, DeliveryCode: mCode })
                    })
                }
                else {

                    setBtn_edit(show_screen.edit)
                    SelectDeliveryMaster(Comp_Id, ProjectID, DeliveryID, async(mData) => {
                            await Select_Detail(DeliveryID)
                            setDelivery_Data({
                                DeliveryID: DeliveryID,
                                Comp_Id: mData[0].Comp_Id,
                                ProjectID: mData[0].ProjectID,
                                DeliveryCode: mData[0].DeliveryCode,
                                DeliveryName: mData[0].DeliveryName,
                                DeliveryDate: mData[0].DeliveryDate,
                            })

                            mSalesman.map(value => {
                                if (value.User_id === mData[0].SalesmanID)
                                    setSelectedSalesman(value)
                            })
                            mDrivers.map(value=>{
                                if(value.DriverID === mData[0].DriverID){
                                    setSelectedDriver(value)
                                }
                            })
                            mVehicles.map(value=>{
                                if(value.VehicleID === mData[0].VehicleID){
                                    setSelectedVehicle(value)
                                }
                            })
                            SelectCityForDelivery(DeliveryID,(mData)=>{
                                setSelectedCityOption(mData)
                            })
                        })
                }
            })
        })

    })
})
    }, [])

    const Select_Detail = (DeliveryID) =>{
        SelectDeliveryDetail(DeliveryID, (mDetailData) => {
            setDeliveryDetail_Data(mDetailData)
        })
    }
    const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    const validate = () => {
                var  NameErr=""
                var SupplierErr = ""
                var CityErr = ""
                var DriverNameErr = ""
                var VehicleNameErr = ""
                if(!Delivery_Data.DeliveryName)
                {
                 NameErr = "Name is required"
                }
                if(!SelectedSalesman)
                {
                    SupplierErr = "Supplier is required"
                }

                // if(!SelectedCityOption)
                // {
                //     CityErr = "City is required"
                // }

                if(!SelectedDriver)
                {
                    DriverNameErr="Driver Name is required"
                }
                if(!SelectedVehicle)
                {
                    VehicleNameErr="Vehicle Name is required"
                }
               
                if(NameErr)
                {
                   setDelivery_Err({...Delivery_Err,Name_Err:NameErr})
                   document.getElementById('DeliveryName').focus()
                    return false
                }

                if(SupplierErr)
                {
                    setDelivery_Err({...Delivery_Err,Name_Err:'',Supplier_Err:SupplierErr})
                    document.getElementById('Supplier').focus()
                     return false
                }
            //  if(CityErr)
            //  {
            //     setDelivery_Err({...Delivery_Err,Name_Err:'',City_Err:CityErr})
            //     document.getElementById('City').focus()
            //      return false
            //  }
             if(DriverNameErr)
             {
                setDelivery_Err({...Delivery_Err,Name_Err:'',City_Err:'',Supplier_Err:'',DriverName_Err:DriverNameErr})
                document.getElementById('DriverName').focus()
                return false
             }
             if(VehicleNameErr)
             {
                setDelivery_Err({...Delivery_Err,Name_Err:'',City_Err:'',Supplier_Err:'',DriverName_Err:'',Vehicle_Err:VehicleNameErr})
                document.getElementById('VehicleName').focus()
                return false
             }
           

             setDelivery_Err({...Delivery_Err,Name_Err:'',City_Err:'',Supplier_Err:'',DriverName_Err:'',Vehicle_Err:''})
              return true

    }
    const Save_Handler = (fromprint) => {
        debugger
        if (validate()) {
            if(DeliveryDetail_Data.length > 0 ){

                var DeliveryData = {
                    DeliveryID: Delivery_Data.DeliveryID,
                    DeliveryCode: Delivery_Data.DeliveryCode,
                    DeliveryName: Delivery_Data.DeliveryName,
                    VehicleID: SelectedVehicle.VehicleID,
                    DeliveryDate: Delivery_Data.DeliveryDate,
                    SalesmanID: SelectedSalesman.User_id,
                    DriverID: SelectedDriver.DriverID,
                    Comp_Id: Comp_Id,
                    ProjectID: ProjectID,
                }
              
                setBtn_Disabled(true)
                SaveDelivery(DeliveryData,DeliveryDetail_Data, DeletedDeliveryDetailData,isAddNew,(err,DeliveryID)=>{
                    debugger
                    setBtn_Disabled(false)
                    if(!err){
                        
                    if(fromprint){
                        debugger
                        localStorage.setItem('token',sessionStorage.getItem('token'))
                        window.open(`/DeliverySummary/${DeliveryID}`,"_blank")
                    }
                    document.getElementById('btnCancel').click()
                }
                })
            }
            else {
                Swal.fire({
                    icon: 'info',
                    text: `Delivery Details Must be Entered...`,
                })
                return
            }
        }

    }
    const Select_InvoiceList = (CityID)=>{
        SelectInvoiceForDelivery(Comp_Id,ProjectID,CityID,(mData)=>{
            var mArray = [...DeliveryDetail_Data]
            if(mArray.length > 0 ){
                mData.map(value=>{
                    mArray.push(value)
                })
                setDeliveryDetail_Data(mArray)
            }
            else{
                setDeliveryDetail_Data(mData)
            }
           
        })
    }
    const column_Delivery = [
        {
         name: "invoicedesc",
         label: "Invoice Description",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
            name: "City",
            label: "City",
            options: {
             filter: true,
             sort: true,
           customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              }, 
             }
           },
           {
            name: "AreaName",
            label: "Area Name",
            options: {
             filter: true,
             sort: true,
           customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              }, 
             }
           },
           {
            name: "GrandTotal",
            label: "Grand Total",
            options: {
             filter: true,
             sort: true,
           customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              }, 
             }
           },
         {
          name: "action",
          label: "action",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) => {
             
             var DeliveryID = DeliveryDetail_Data
             if (DeliveryID[rowIndex.rowIndex] != null)
               return (
                   <>
                 <Link to={"#"} onClick={e => {
                    Delete_Booking(DeliveryDetail_Data[rowIndex.rowIndex])}}> <Delete /></Link> |
                     <Link target={"_blank"} to={`/SaleInvA4/${DeliveryDetail_Data[rowIndex.rowIndex].SaleInvoiceID}`} onClick={e=>{localStorage.setItem('token',sessionStorage.getItem('token'))}}> <Print /></Link></>
               );
           }
          }
         }
    ];
    const theme = createTheme({
        overrides: {
          MuiTableCell: {
            root: {
              paddingTop: 0,
              paddingBottom: 0,
            }
          }
        }
      });
      const Delete_Booking = (DeliverydetailData) =>{
          if(DeliverydetailData.DDID > 0){
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: false
                })
        
                swalWithBootstrapButtons.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: `Yes, Delete it`,
                    cancelButtonText: 'No, cancel!',
                    reverseButtons: true
                }).then(async (result) => {
                    if (result.isConfirmed) {
        
                        await DeleteDelivery(DeliverydetailData.DDID, DeliverydetailData.SaleInvoiceID,(message) => {
                            Select_Detail(DeliveryID)
                            // Select_InvoiceList()
                            swalWithBootstrapButtons.fire(
                                `Deleted`,
                                `${message}`,
                                'success'
                            )
                        })
                    } else if (
                        /* Read more about handling dismissals below */
                        result.dismiss === Swal.DismissReason.cancel
                    ) {
                        swalWithBootstrapButtons.fire(
                            'Cancelled',
                            `Your record is safe :)`,
                            'error'
                        )
                    }
                })
             
            
          }
          else if(DeliverydetailData.DDID === 0){
            for(var i = 0; i < DeliveryDetail_Data.length; i++) {
                if(DeliveryDetail_Data[i].SaleInvoiceID == DeliverydetailData.SaleInvoiceID) {
                    DeliveryDetail_Data.splice(i, 1);
                    break;
                }
            }
            Swal.fire({
                icon: 'Deleted',
                title: 'Your file has been Deleted.',
                text: 'success',
              })

          }
         
      }
      

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

    return ReactDOM.createPortal (
        <>
      <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2>Delivery
                    </h2>
                </div>
            </div>
        </div>
            <Card> 
                <Row className="row_margin_top row_margin_left row_margin_right m-4" >
                <Col lg={3} xl={3} md={3} >
                        <TextField
                            name="DeliveryCode"
                            id="DeliveryCode"
                            label="Code"
                            value={Delivery_Data.DeliveryCode}
                            inputProps={{
                                readOnly:true
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col lg={6} xl={6} md={6} >
                        <TextField
                            name="DeliveryName"
                            id="DeliveryName"
                            label="Delivery Name"
                            value={Delivery_Data.DeliveryName}
                            onChange={e=>setDelivery_Data({...Delivery_Data,DeliveryName:e.target.value})}
                            onInput={toInputUppercase}
                            fullWidth
                        />
                        <span className="text-danger">{Delivery_Err.Name_Err}</span>
                    </Col>
                    
                    <Col lg={3} xl={3} md={3} >
                            <TextField
                                name="DeliveryDate"
                                id="DeliveryDate"
                                label="DeliveryDate"
                                type="date"
                                 value={Delivery_Data.DeliveryDate}
                                onChange={e=>setDelivery_Data({...Delivery_Data,DeliveryDate:e.target.value})}
                                fullWidth
                            />
                        </Col>
                    <Col lg={4} xl={4} md={4} >
                            <Autocomplete
                                name="Supplier"
                                id="Supplier"
                                options={Salesman_List}
                                getOptionLabel={(option) => option.User_name ? option.User_name : ""} 
                                value={SelectedSalesman}
                                onChange={(e,value) =>{
                                if(value)
                                {
                                    setSelectedSalesman(value)
                                   
                                }
                                else
                                setSelectedSalesman(null)
                                    
                                }}
                                renderInput={(params) => 
                                <TextField {...params} name="Supplier" label="Salesman"
                                />
                            }
                            />
                            <span className="text-danger">{Delivery_Err.Supplier_Err}</span>
                        </Col>
                        <Col lg={4} xl={4} md={4} >
                        {/* <TextField
                            name="DriverName"
                            id="DriverName"
                            label="Driver Name"
                            value={Delivery_Data.DriverName}
                            onChange={e=>{
                                    setDelivery_Data({...Delivery_Data,DriverName:e.target.value})

                            }}
                            onInput={toInputUppercase}
                            fullWidth
                        /> */}
                        <Autocomplete
                                name="Driver"
                                id="DriverName"
                                options={DriverList}
                                getOptionLabel={(option) => option.DriverName ? option.DriverName : ""} 
                                value={SelectedDriver}
                                onChange={(e,value) =>{
                                if(value){
                                setSelectedDriver(value)
                                }
                                else{
                                    setSelectedDriver(null)
                                }
                                    
                                }}
                                renderInput={(params) => 
                                <TextField {...params} name="Driver" label="Driver"/>}
                            />
                        <span className="text-danger">{Delivery_Err.DriverName_Err}</span>
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                            {/* <TextField
                                name="VehicleNo"
                                id="VehicleNo"
                                label="VehicleNo"
                                value={Delivery_Data.VehicleNo}
                                onChange={e=>setDelivery_Data({...Delivery_Data,VehicleNo:e.target.value})}
                                onInput={toInputUppercase}
                                fullWidth
                            /> */}
                            <Autocomplete
                                name="Vehicle"
                                id="VehicleName"
                                options={VehicleList}
                                getOptionLabel={(option) => option.VehicleNo ? option.VehicleNo : ""} 
                                value={SelectedVehicle}
                                onChange={(e,value) =>{
                                if(value){
                                setSelectedVehicle(value)
                                }
                                else{
                                    setSelectedVehicle(null)
                                }
                                    
                                }}
                                renderInput={(params) => 
                                <TextField {...params} name="Vehicle" label="Vehicle"/>}
                            />
                            <span className="text-danger">{Delivery_Err.Vehicle_Err}</span>
                        </Col>
                        {/* <Col lg={3} xl={3} md={3} >
                            <Autocomplete
                                name="City"
                                id="City"
                                options={City_List}
                                getOptionLabel={(option) => option.City ? option.City : ""} 
                                value={SelectedCityOption}
                                onChange={(e,value) =>{
                                if(value){
                                    let array = [...DeliveryDetail_Data]
                                    var count = 0
                                    for (let i = 0; i < DeliveryDetail_Data.length; i++) {
                                        
                                      if (DeliveryDetail_Data[i].CityID === value.CityID) {
                                        count++
                                      }
            
                                    }
                                    if (count > 0) {
                                      Swal.fire({
                                        icon: 'info',
                                        title: 'Oops...',
                                        text: `${value.City}'s invoices Already Exist...`,
                                      })
                                      return;
                                    }
                                    else{
                                        setSelectedCityOption(value)
                                        Select_InvoiceList(value.CityID)
                                    }
                                }
                                else{
                                    setSelectedCityOption(null)
                                    setDeliveryDetail_Data([])
                                }
                                    
                                }}
                                renderInput={(params) => 
                                <TextField {...params} name="City" label="City"/>}
                            />
                            <span className="text-danger">{Delivery_Err.City_Err}</span>
                        </Col> */}
                    <Col lg={12} xl={12} md={12}>
                        <Autocomplete
                            multiple
                            id="City"
                            options={City_List}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.City ? option.City : ''}
                            getOptionSelected={(option, value) => option.CityID === value.CityID}  //
                            value={SelectedCityOption}
                            // renderOption={(props, option) =>  {
                            //    return ( <>
                            //         <Checkbox
                            //             icon={icon}
                            //             name="City"
                            //             checkedIcon={checkedIcon}
                            //             style={{ marginRight: 8 }}
                            //             checked={option.selected}
                            //             disabled={true}                                    />
                            //         {props.City}
                            //     </>)
                            // }
                            // }
                            onChange={(e, value) => {
                                if(value.length > 0){
                                    if(e.target.outerText){
                                       var cityAdded  = value.find(x=> x.City.trim() == (e.target.outerText).trim())
                                       if(cityAdded)
                                       Select_InvoiceList(cityAdded.CityID)
                                    }
                                    else if(e.target.outerText === undefined || e.target.outerText=== ""){
                                        var cityRemoved = SelectedCityOption.filter(x => !value.includes(x));
                                        var marray = [],DeletedArray = []
                                        if(cityRemoved){
                                            DeliveryDetail_Data.map(mvalue=>{
                                                if(mvalue.CityID !== cityRemoved[0].CityID){
                                                    marray.push(mvalue)
                                                }
                                                else{
                                                    if(mvalue.DDID > 0 ){
                                                        setDeletedDeliveryDetailData(DeletedDeliveryDetailData.concat(mvalue)) 
                                                    }
                                                }
                                            })
                                        }
                                        
                                        if(marray.length > 0){
                                            setDeliveryDetail_Data(marray)
                                        }
                                    }

                                    setSelectedCityOption(value)
                                }
                                else{
                                    DeliveryDetail_Data.map(mvalue=>{
                                        if(mvalue.DDID > 0 ){
                                            setDeletedDeliveryDetailData(DeletedDeliveryDetailData.concat(mvalue)) 
                                        }
                                    })
                                    setSelectedCityOption([])
                                    setDeliveryDetail_Data([])
                                }
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="City" />
                            )}
                        />
                    </Col>
                        
                    </Row>
                <Row className="row_margin_left row_margin_right pl-4 pr-4" >
                    <Col>
                        <div className="table-responsive" >
                            <MuiThemeProvider theme={theme}>
                                <MUIDataTable
                                    name="IPD_Datatables"
                                    data={DeliveryDetail_Data}
                                    columns={column_Delivery}
                                    options={{
                                        selectableRows: 'none',
                                        print: false,
                                        search: false,
                                        download: false,
                                        viewColumns: false,
                                        filter: false,
                                    }}
                                />
                            </MuiThemeProvider>
                        </div>
                    </Col>
                </Row>
                <Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'2%'}} >
                    <Col style={{ textAlign: "right" }}>
                        {btn_edit && (<button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={Save_Handler}  disabled={btn_Disabled}> 
                        {
                             btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                                }
                        </button>)}
                        <button id="btnPrint" className="btn btn-primary rounded-pill" style={{ border: "none" }}   onClick={e=>Save_Handler(true)}  disabled={btn_Disabled}> 
                        {
                            btn_Disabled ?
                              (<><Spinner
                                   as="span"
                                   animation="grow"
                                   size="sm"
                                   role="status"
                                   aria-hidden="true"
                                   /> Saving... </>) : <><Print /> <u>Delivery Summary Save and Print</u></>
                               }
                        </button>
                        {!isAddNew && <Link id="btnPrint" className="btn btn-primary rounded-pill" style={{ border: "none" }} target ="_blank" to={`/SaleInvA4/0`} onClick={e=>{
                              localStorage.setItem('token',sessionStorage.getItem('token'))
                              localStorage.setItem('DeliveryDetailData',JSON.stringify(DeliveryDetail_Data))
                              }}> <Print /> <u>Print Invoices</u></Link>}
                        
                        <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{ border: "none" }} to='/SetSedule' > <Cancel /> <u>Close</u></Link>

                    </Col>
                </Row>
            </Card>
        </> , document.getElementById('PageContents')
    )

 
}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    isAddNew:state.Delivery && state.Delivery.isAddNew,
    DeliveryID: state.Delivery && state.Delivery.DeliveryID,
    UserID: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    show_screen: state.SCREEN,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

  })
export default connect(mapStateToProps)(DeliveryEntry)