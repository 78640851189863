import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const rptStockBalance =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/Inventory/rptStockBalance/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const rptStockBalanceWareHouseWise =(Comp_Id,ProjectID,WareHouseID,FromItemCode,ToItemCode,Callback) =>{
    axios.get(`${domain}/Inventory/rptStockBalanceWareHouseWise/${Comp_Id}/${ProjectID}/${WareHouseID}/${FromItemCode}/${ToItemCode}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const rptStockBalanceBatchWise =(Comp_Id,ProjectID,Callback) =>{
    axios.get(`${domain}/Inventory/rptStockBalanceBatchWise/${Comp_Id}/${ProjectID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }

  export const rptShortInventoryItems =(Comp_Id,ProjectID,Callback) =>{
    axios.get(`${domain}/Inventory/rptShortInventoryItems/${Comp_Id}/${ProjectID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const rptMaxInventoryItems =(Comp_Id,ProjectID,Callback) =>{
    axios.get(`${domain}/Inventory/rptMaxInventoryItems/${Comp_Id}/${ProjectID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }