import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const rptCashInHand =(Comp_Id,ProjectID,FromDate,ToDate,Callback) =>{
  axios.get(`${domain}/Finance/rptCashInHand/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const rptCashInBank =(Comp_Id,ProjectID,FromDate,ToDate,BankID,Callback) =>{
    axios.get(`${domain}/Finance/rptCashInBank/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${BankID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }