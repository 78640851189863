import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectUOMList =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/UOM/SelectUOMList/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SaveUOM = (UOM,DeletedUOM,callback) =>{
 
  axios.post(`${domain}/UOM/SaveUOM`,{
    UOM:UOM,
    DeletedUOM:DeletedUOM
  })
      .then(response=>{
        debugger
        if(response.data.status === true)
        {
          Swal.fire({
            icon: 'info',
            title: 'Sorry...',
            text: `${response.data.message}`,
          })
        }
        else
        {
          toast.configure()
          toast.info(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
        }
          callback()
      })
      .catch(err=>console.error(err))
}