import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "../SaleReports/Table.css"
import { rptBatchwiseSale } from '../../../Services/Distribution_ReportAPI'
import { SelectUserProjects } from '../../../Services/Projects'
import { SelectManufacturedByList  } from '../../../Services/manufactureAPI'
import { SelectCityWiseAreas } from '../../../Services/AreasAPI'

function BatchWiseSaleReport({Comp_Name,Comp_Id,User_Name,User_Id,ConfigObj}) {

    const { ProjectID,ManufacturedByID,CityID,AreaID,ItemID,BatchNo } = useParams();
    const [ItemData, setItemData] = useState([]);
    const [TotalValue, setTotalValue] = useState(0);
    const [TotalQty, setTotalQty] = useState(0)
    const [SelectedProject, setSelectedProject] = useState(null)
    const [Manufacturer,setManufacturer] = useState('')
    const [AreaName, setAreaName] = useState("")
    const [ItemName, setItemName] = useState("")
    useEffect(() => {

        document.title = "Distribution / Batch-Wise Sale Report" 

        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('footer').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;
            
        rptBatchwiseSale(Comp_Id,ProjectID,ManufacturedByID,CityID,AreaID,ItemID,BatchNo,(mData)=>{
            if(mData.length > 0){
            var Totals = mData.splice(-1);
            setTotalValue((Totals[0].value).toFixed(2))
            setTotalQty((Totals[0].Qty).toFixed(2))
            setItemData(mData)
            }
        
        })
     
        
        SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
            mProjects.map(value=>{
                if(value.Project_ID == ProjectID){
                    setSelectedProject(value)
                }
            })
        })
        if(ManufacturedByID == "0"){
            setManufacturer('ALL COMPANIES')
        }
        else{
            SelectManufacturedByList(Comp_Id,ProjectID,(data) =>{
                data.map(mvalue=>{
                    if(mvalue.ManufacturedByID == ManufacturedByID ){
                        setManufacturer(mvalue.ManufacturedBy)
                    }
                })
            })

        }
        if(AreaID == "0"){
            setAreaName('ALL')
        }
        else{
            SelectCityWiseAreas(Comp_Id,ProjectID,CityID,(mareas)=>{
                mareas.map(value=>{
                    if(value.AreaID === AreaID){
                        setAreaName(value.AreaName)
                    }
                })
            })

        }
        var _BatchWiseSaleReportParams = JSON.parse(localStorage.getItem("BatchWiseSaleReportParams"))
        if(_BatchWiseSaleReportParams){
            setItemName(_BatchWiseSaleReportParams.ItemDiscription)
        }
        localStorage.removeItem("token");
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
            <Row style={{ textAlign: 'center', color: "black" }}>
            <Col sm={3} md={3} xl={3}><img src={ConfigObj.Logo} width="100" height="100" /></Col>
            <Col sm={6} md={6} xl={6}>
                    <h2> {Comp_Name} </h2> <br /> <h3>Batch-Wise Sale Report</h3>
                </Col>
                </Row>
                <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Branch:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{SelectedProject && SelectedProject.ProjectName}</b>
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Company:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{Manufacturer}</b>
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Town:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                         <b>{AreaName}</b>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
            <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Product:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{ItemName}</b>
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} md={4} xl={4} >
                 </Col>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Batch No:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                         <b>{BatchNo}</b>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}   >Sale Inv No</th>
                            <th style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }} >Sale Date</th>
                            <th style={{ width: '15%', textAlign: 'left', fontWeight: "bold" }} >Customer</th>
                            <th style={{ width: '15%', textAlign: 'left', fontWeight: "bold" }} >Town</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Qty</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Price</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Value</th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                         
                        {
                            ItemData.map((Itemdata, index) => {
                            return(
                                                               
                                <>
                                {
                                    Itemdata.title === true ? 
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none',borderBottom:'none'}}>
                                        <td colSpan={7} style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.ManufacturedBy}</td>
                                       </tr>
                                    :
                                    Itemdata.ItemID === "Total" ? 
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none'}}>
                                        <td colSpan={6} style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold',borderRight:'2px solid black' }}  >Total</td>
                                        <td colSpan={1} style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  > {Itemdata.Qty} </td>
                                    </tr>
                                    :
                                    <tr key={index} >
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{Itemdata.SaleInvoiceNo}</td>
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.InvoiceDate}</td>
                                        <td style={{ width: '15%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.CustomerName}</td>
                                        <td style={{ width: '15%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.AreaName}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Qty} </td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.SaleRate !== "" && (parseFloat(Itemdata.SaleRate)).toFixed(2)}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {(Itemdata.value).toFixed(2)} </td>
                                    </tr>
                                }
                                </>
                               
                            )}
                            )
                        }
                        <tr id='footer_balance'>
                            <td id='footer_td' colSpan="4" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  >Total :</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  > {TotalQty} </td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > </td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  >{TotalValue}</td>
                        </tr>
                   
                    </tbody>


                </table>

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig
})

export default connect(mapStateToProps)(BatchWiseSaleReport)
