import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectSchemeFromCust =(Comp_Id,ProjectID,CustomerID,Callback) =>{
    
  axios.get(`${domain}/Scheme/SelectSchemeFromCust/${Comp_Id}/${ProjectID}/${CustomerID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectItemsWRTManufacturer =(Comp_Id,ProjectID,ManufacturedByID,CGID,Callback) =>{
    
  axios.get(`${domain}/Scheme/SelectItemsWRTManufacturer/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${CGID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectSchemeDetail =(Comp_Id,ProjectID,CustomerID,ItemID,Callback) =>{
    
  axios.get(`${domain}/Scheme/SelectSchemeDetail/${Comp_Id}/${ProjectID}/${CustomerID}/${ItemID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectScheme =(Comp_Id,Project_ID,CustomerID,ManufacturerID,CGID,ItemID,Callback) =>{
    
  axios.get(`${domain}/Scheme/SelectScheme/${Comp_Id }/${Project_ID}/${CustomerID }/${ManufacturerID}/${CGID }/${ItemID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
          
     })
   
}

export const SaveScheme = (SchemeMasterData,SchemeDetailData,DeletedSchemeData,isAddNew,callback) =>{

  axios.post(`${domain}/Scheme/SaveScheme`,{
    SchemeMasterData,SchemeDetailData,DeletedSchemeData,
     isAddNew
  })
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback()
      })
      .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
}


export const SelectSchemeMaster =(SchemeID,Callback) =>{
    
  axios.get(`${domain}/Scheme/SelectSchemeMaster/${SchemeID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const DeleteScheme =(Comp_Id,ProjectID,idObj,Callback) =>{
    debugger
  axios.post(`${domain}/Scheme/DeleteScheme`,{
    Comp_Id,ProjectID,idObj
  })
       .then(response=>{
           Callback(response.data.message)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}


export const SelectScheme_Detail =(Comp_Id,ProjectID,SchemeID,Callback) =>{
    
  axios.get(`${domain}/Scheme/SelectSchemeDetail/${Comp_Id}/${ProjectID}/${SchemeID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}



export const SelectCustForScheme =(Comp_Id,ProjectID,Callback) =>{
    
  axios.get(`${domain}/Scheme/SelectCustForScheme/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectDataForApp =(Comp_Id,ProjectID,ItemID,CustomerID,Callback) =>{
  axios.get(`${domain}/Scheme/SelectDataForApp/${Comp_Id}/${ProjectID}/${ItemID}/${CustomerID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}