import React from 'react'
import ReactDOM from 'react-dom'
import {Row, Col,Card, Spinner} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {TextField, Popper} from '@material-ui/core'
import { Save, Cancel,ShowChart, Print} from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import { Autocomplete } from '@material-ui/lab';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
import { connect} from 'react-redux'
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

import { GetCurrentDate ,preventMinus} from '../../Services/Common'
import { SelectSupplierList } from "../../Services/SuppliersAPI";
import { SelectWareHouseList } from "../../Services/WareHouseAPI";
import { GetMaxPurchaseNo, SelectItemDetails,SavePurchaseOrder,SelectPurchaseOrderMaster,SelectPurchaseOrderDetail,SelectItemWRTSupplier } from '../../Services/PurchaseOrderAPI'
import MinStockModal from './MinStockModal'
import ReOrderStockModal from './ReorderStockModal'
import Loader from '../Loader';
import AutoOrderModal from './AutoOrderModal'

export class PurchaseOrderEntry extends React.Component {
  constructor(props){
      super(props)

      this.state = {
          PurchaseOrderNo: '',
          SupplierList: [],
          SelectedSupplier: null,
          PurchaseOrderDate: GetCurrentDate(),
          CreatedDate:GetCurrentDate(),
          WareHouseList: [],
          SelectedWareHouse: null,
          Item_List: [],
          Selected_item: null,
          Discription: '',
          GrandTotal:0.00,
          PO_Detail_Data: [],
          Deleted_PO_Detail_Data:[],
          Supplier_Err: '',
        UOMID:0,
        // Modal 
        Stock_Modal : false,
        ReStock_Modal : false,
        AutoOrderModal : false,
        btn_edit : true,
        btn_Add: false,
        btn_Disabled:false,
        filter:null,
        IsLoader:false,
        inventoryLevel: 0,
        from1Date: '',
        from2Date: '',
        from3Date: '',
        to1Date: '',
        to2Date: '',
        to3Date: '',
      }
      this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
      this.validationRules = { required: true };
      this.bg_Ref = React.createRef()
      this.customNameValidation = this.customNameValidation.bind(this)
      this.grid = null
    //   this.Comp_Id = this.props.Comp_Id && this.props.Comp_Id
      this.NAmeValidation = {
        required: [this.customNameValidation, 'Name must be selected'],
      };
      this.Comp_Id = this.props.Comp_Id
      this.ProjectID = this.props.Project_ID
      this.UserID = this.props.p_userid
      this.mArray = []
  }
 
  sortingOptions = {
    columns: [{ field: 'SrNo', direction: 'Ascending' }]
  };
  customNameValidation(args) {
      args.value=this.state.Selected_item ? this.state.Selected_item.ItemID : ''
    return getValue('value', args) != ''   
    }
    showLoader = () => {
        this.setState({IsLoader:true})
      }
      hideLoader = () => {
        this.setState({IsLoader:false})
      }
  componentDidMount(){

    document.title = " Distribution / Purchase Order"
    SelectWareHouseList(this.Comp_Id,this.ProjectID,(mWareHouse)=>{
        this.setState({WareHouseList:mWareHouse,SelectedWareHouse:mWareHouse[0], btn_Disabled:false}) // by default first selected 
    
    SelectSupplierList(this.Comp_Id,this.ProjectID,(mSupplier)=>{
        this.setState({SupplierList:mSupplier})
  
        if(this.props.isAddNew) // save
        {
            
            this.Max_Purchase_OrderNo() // new reciver NO genrate
        }

        else // update
        {
            this.showLoader()
            SelectPurchaseOrderMaster(this.Comp_Id,this.ProjectID,this.props.PurchaseOrderID,(mData)=>{
                this.hideLoader()
                const orderDates = JSON.parse(mData[0].orderDates)
                this.setState({
                    PurchaseOrderNo:mData[0].PurchaseOrderNo,
                    PurchaseOrderDate:mData[0].PurchaseOrderDate,
                    Discription:mData[0].Discription,
                    GrandTotal:mData[0].GrandTotal,
                    btn_edit: this.props.show_screen.edit,
                    from1Date: orderDates?.from1Date,
                    from2Date: orderDates?.from2Date,
                    from3Date: orderDates?.from3Date,
                    to1Date: orderDates?.to1Date,
                    to2Date: orderDates?.to2Date,
                    to3Date: orderDates?.to3Date
                })
                mWareHouse.map(val=>{
                    if(val.WareHouseID === mData[0].WareHouseID)
                    {
                        this.setState({SelectedWareHouse:val})
                    }
                })
                mSupplier.map(val=>{
                    if(val.SupplierID === mData[0].SupplierID)
                    {
                        this.Select_Item_Details(mData[0].SupplierID)
                        this.setState({SelectedSupplier:val,btn_Add:true})
                    }
                })
                SelectPurchaseOrderDetail(this.Comp_Id,this.ProjectID,this.props.PurchaseOrderID,(mDetailData)=>{
                    mDetailData.map((value,index)=>{
                        value.SrNo = index + 1 
                    })
                    if(mDetailData.length > 0)
                    this.setState({PO_Detail_Data:mDetailData})
                })
            })
        }
        // this.Select_Item_Details()
       })
    })
  }
  
    Select_Item_Details = (SupplierID) =>{
        SelectItemWRTSupplier(this.Comp_Id,this.ProjectID,SupplierID,(mItems)=>{ 
                this.setState({Item_List:mItems})
            })
        // SelectItemDetails(this.Comp_Id,this.ProjectID,(mItems)=>{ 
        //     this.setState({Item_List:mItems})
        // })
    }
    Show_Min_Stock_Modal = ()=>{
        if(this.state.SelectedSupplier){
            this.setState({Stock_Modal:true})
        }
        else{
            this.setState({Supplier_Err:'required'})
        }
    }
    Hide_Min_Stock_Modal = ()=>{
    this.setState({Stock_Modal:false})
    }
    Show_ReOrder_Stock_Modal = ()=>{
        if(this.state.SelectedSupplier){
            this.setState({ReStock_Modal:true})
        }
        else{
            this.setState({Supplier_Err:'required'})
        }
    
    }
    Hide_ReOrder_Stock_Modal = ()=>{
    this.setState({ReStock_Modal:false})
    }
    Show_AutoOrder_Modal = ()=>{
        if(this.state.SelectedSupplier){
            this.setState({AutoOrderModal:true})
        }
        else{
            this.setState({Supplier_Err:'required'})
        }
    
    }
    Hide_AutoOrder_Modal = ()=>{
        this.setState({AutoOrderModal:false})
        }
    Max_Purchase_OrderNo = () => {
        this.showLoader()
        GetMaxPurchaseNo(this.Comp_Id,this.ProjectID, (mPurchaseOrderNo) => { // API Function
            this.hideLoader()
            this.setState({ PurchaseOrderNo: mPurchaseOrderNo })  // set state here 
        })
    }
    ItemDiscription = (rowValue) => {
        return (
            <Autocomplete
                name="ItemDiscription"
                id="ItemDiscription"
                defaultValue={this.state.Item_List.find(v => v.ItemDiscription === rowValue.ItemDiscription)}
                options={this.state.Item_List}
                getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : ""}
                onChange={(e, value) => {
                    if (value) {
                        document.getElementById('UOM').value = value.UOM
                        document.getElementById('PurchaseRate').value = value.PurchaseRate
                        document.getElementById('value').value = value.PurchaseRate
                        document.getElementById('DisPer').value = value.DisPer
                        document.getElementById('TaxPer').value = value.GST
                        document.getElementById('MRP').value = value.MRP
                        this.setState({ Selected_item: value,UOMID:value.UOMID },()=>{
                            this.Calculate_Total()
                        })
                    }
                    else {
                        document.getElementById('UOM').value = ''
                        document.getElementById('PurchaseRate').value = 0
                        document.getElementById('MRP').value = 0
                        document.getElementById('value').value = 0
                        document.getElementById('DisPer').value = 0
                        document.getElementById('TaxPer').value = 0


                        this.setState({ Selected_item: null },()=>{
                            this.Calculate_Total()
                        })
                      
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="ItemDiscription" />}
            />
        )
    }
    Qty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="Qty"
            className="textbox"
            name='Qty'
            defaultValue={rowValue.Qty}
            onChange = { e => {
                this.Calculate_Total()
            }}
            min={1}
            onKeyPress={e=>preventMinus(e)}
        />)
    }
    UOM = (rowValue) => {

        return (<input
            id="UOM"
            name="UOM"
            style={{ textAlign: "right", width: "80%" }}
            defaultValue={rowValue.UOM}
            className="textbox"
            readOnly={true}
        />)

    }
    PurchaseRate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="PurchaseRate"
            defaultValue={rowValue.PurchaseRate}
            className="textbox"
            name="PurchaseRate"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={1}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    MRP = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="MRP"
            defaultValue={rowValue.MRP}
            className="textbox"
            name="MRP"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={1}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    DisPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisPer"
            defaultValue={rowValue.DisPer}
            className="textbox"
            name="DisPer"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={0}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    DisAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisAmt"
            defaultValue={rowValue.DisAmt}
            className="textbox"
            name="DisAmt"
            readOnly={true}
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    TaxPer = (rowValue) => {
      
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxPer"
            defaultValue={rowValue.TaxPer}
            className="textbox"
            name="TaxPer"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={0}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    TaxAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxAmt"
            defaultValue={rowValue.TaxAmt}
            className="textbox"
            name="TaxAmt"
            readOnly={true}
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    Value = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="value"
            defaultValue={rowValue.Value}
            className="textbox"
            name="Value"
            readOnly={true}
        />)
    }
    SaveHandler = (fromPrint) => {
            if(this.state.SelectedSupplier)
                {
                    this.setState({Supplier_Err:''})
                    if(this.state.PO_Detail_Data.length > 0)
                    {
                        const orderDates = {
                            from1Date: this.state.from1Date,
                            from2Date: this.state.from2Date,
                            from3Date: this.state.from3Date,
                            to1Date: this.state.to1Date,
                            to2Date: this.state.to2Date,
                            to3Date: this.state.to3Date,
                        }
                       
                        var OrderData = {
                            PurchaseOrderID:this.props.PurchaseOrderID,
                            PurchaseOrderNo:this.state.PurchaseOrderNo,
                            CreatedDate:this.state.CreatedDate,
                            SupplierID:this.state.SelectedSupplier.SupplierID,
                            Discription:this.state.Discription,
                            WareHouseID:this.state.SelectedWareHouse.WareHouseID,
                            PurchaseOrderDate:this.state.PurchaseOrderDate,
                            GrandTotal:this.state.GrandTotal,
                            UserID:this.UserID,
                            Comp_Id:this.Comp_Id,
                            ProjectID:this.ProjectID,
                            inventoryLevel: this.state.inventoryLevel,
                            orderDates: JSON.stringify(orderDates)
                        
                        }
                        
                        for(let i = 0; i< this.state.PO_Detail_Data.length; i++)
                        {
                            if(typeof this.state.PO_Detail_Data[i].Qty === 'undefined' || this.state.PO_Detail_Data[i].Qty == '' || this.state.PO_Detail_Data[i].Qty == null )
                            this.state.PO_Detail_Data[i].Qty = 0
                            else if (typeof this.state.PO_Detail_Data[i].Qty === 'string')
                            this.state.PO_Detail_Data[i].Qty =  parseFloat(this.state.PO_Detail_Data[i].Qty)
        
                            if(typeof this.state.PO_Detail_Data[i].TaxPer === 'undefined' || this.state.PO_Detail_Data[i].TaxPer == '' || this.state.PO_Detail_Data[i].TaxPer == null )
                            this.state.PO_Detail_Data[i].TaxPer = 0
                            else if (typeof this.state.PO_Detail_Data[i].TaxPer === 'string')
                            this.state.PO_Detail_Data[i].TaxPer =  parseFloat(this.state.PO_Detail_Data[i].TaxPer)
        
                            if(typeof this.state.PO_Detail_Data[i].DisPer === 'undefined' || this.state.PO_Detail_Data[i].DisPer == '' || this.state.PO_Detail_Data[i].DisPer == null )
                            this.state.PO_Detail_Data[i].DisPer = 0
                            else if (typeof this.state.PO_Detail_Data[i].DisPer === 'string')
                            this.state.PO_Detail_Data[i].DisPer =  parseFloat(this.state.PO_Detail_Data[i].DisPer)
        
                            if(typeof this.state.PO_Detail_Data[i].TaxAmt === 'undefined' || this.state.PO_Detail_Data[i].TaxAmt == '' || this.state.PO_Detail_Data[i].TaxAmt == null )
                            this.state.PO_Detail_Data[i].TaxAmt = 0
                            else if (typeof this.state.PO_Detail_Data[i].TaxAmt === 'string')
                            this.state.PO_Detail_Data[i].TaxAmt =  parseFloat(this.state.PO_Detail_Data[i].TaxAmt)
        
                            if(typeof this.state.PO_Detail_Data[i].DisAmt === 'undefined' || this.state.PO_Detail_Data[i].DisAmt == '' || this.state.PO_Detail_Data[i].DisAmt == null )
                            this.state.PO_Detail_Data[i].DisAmt = 0
                            else if (typeof this.state.PO_Detail_Data[i].DisAmt === 'string')
                            this.state.PO_Detail_Data[i].DisAmt =  parseFloat(this.state.PO_Detail_Data[i].DisAmt)
        
                            if(typeof this.state.PO_Detail_Data[i].PurchaseRate === 'undefined' || this.state.PO_Detail_Data[i].PurchaseRate == '' || this.state.PO_Detail_Data[i].PurchaseRate == null )
                            this.state.PO_Detail_Data[i].PurchaseRate = 0
                            else if (typeof this.state.PO_Detail_Data[i].PurchaseRate === 'string')
                            this.state.PO_Detail_Data[i].PurchaseRate =  parseFloat(this.state.PO_Detail_Data[i].PurchaseRate)
                            
                            if(typeof this.state.PO_Detail_Data[i].Value === 'undefined' || this.state.PO_Detail_Data[i].Value == '' || this.state.PO_Detail_Data[i].Value == null )
                            this.state.PO_Detail_Data[i].Value = 0
                            else if (typeof this.state.PO_Detail_Data[i].Value === 'string')
                            this.state.PO_Detail_Data[i].Value =  parseFloat(this.state.PO_Detail_Data[i].Value)
                        }
                        
                        this.setState({btn_Disabled:true})
                        this.state.PO_Detail_Data.sort((a, b) => a.SrNo - b.SrNo);
                        
                        SavePurchaseOrder(OrderData,this.state.PO_Detail_Data,this.state.Deleted_PO_Detail_Data,this.props.isAddNew,()=>{
                            if(fromPrint == true){
                                window.open('/PurchaseOrderReport','_blank')
                            }
                            
                            this.setState({btn_Disabled:false})
                            document.getElementById('btnCancel').click()
                        })
                    }
                    else
                    { 
                        Swal.fire({
                            icon: 'info',
                            text: `Order Details Must be Entered...`,
                        })
                        return
                    }
                }
                else
                {
                    this.setState({Supplier_Err:'Supplier is required'})
                }
       
       
    }
    beforeRowInsert = (rowValue) => {
        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.SrNo) {
                    
                    if (rowValue.requestType != "cancel") {
                        rowValue.data.SrNo = parseInt(rowValue.data.SrNo)
                        rowValue.data.ItemDiscription = this.state.Selected_item.ItemDiscription
                        rowValue.data.ItemID = this.state.Selected_item.ItemID
                        rowValue.data.UOMID = this.state.UOMID
                        // rowValue.data.MRP = this.state.Selected_item.MRP
                        if (rowValue.data.PurchaseOrderDetailID === undefined)
                            rowValue.data.action = 'add'
                        else
                            rowValue.data.action = rowValue.action

                    }
                }
                else {
                    rowValue.data.SrNo = this.state.PO_Detail_Data.length + 1
                    rowValue.data.Qty = 1
                    rowValue.data.DisPer = 0.00
                    rowValue.data.DisAmt = 0.00
                    rowValue.data.TaxPer = 0.00
                    rowValue.data.TaxAmt = 0.00

                    this.setState({ Selected_item: null,UOMID:0 })
                }
            }
            // if (rowValue.requestType === 'beginEdit') {
            // }
        }

    }
    afterRowInsert = (rowValue) => {

        if (rowValue.requestType == "beginEdit") {
            
            this.setState({ Selected_item: { "ItemDiscription": rowValue.rowData.ItemDiscription, "ItemID": rowValue.rowData.ItemID, "MRP": rowValue.rowData.MRP  },UOMID: rowValue.rowData.UOMID })
     
        }
        if (rowValue.requestType == "save") {
            this.Calculate_Grand_Total();
        }
        if (rowValue.requestType == "delete") {
             this.Calculate_Grand_Total();
        }
    }
    Calculate_Grand_Total = () =>{
    var _GrandTotal = 0;
    for(let i = 0; i< this.state.PO_Detail_Data.length; i++)
    {
       if( typeof this.state.PO_Detail_Data[i].Value === 'string' )
       {
        this.state.PO_Detail_Data[i].Value = parseFloat((this.state.PO_Detail_Data[i].Value).replace(/,/g, ''))
       }
       else if (this.state.PO_Detail_Data[i].Value == '')
       {
        this.state.PO_Detail_Data[i].Value = 0 
       }
       _GrandTotal += this.state.PO_Detail_Data[i].Value
    }
    this.setState({GrandTotal:_GrandTotal})
}
    Calculate_Total = () => {
        var Qty = 0, Rate = 0,MRP = 0, DisPer = 0, TaxPer = 0, DisAmt = 0, TaxAmt = 0, Total_Value = 0;

        
        if (document.getElementById('Qty').value)
            Qty = typeof (document.getElementById('Qty').value) === 'string' ? parseFloat((document.getElementById('Qty').value).replace(/,/g, '')) : document.getElementById('Qty').value
        else if (document.getElementById('Qty').value == "")
            Qty = 0
        else
            Qty = parseFloat(document.getElementById('Qty').value)
            // MRP = this.state.Selected_item ? this.state.Selected_item.MRP : 
            MRP = document.getElementById('MRP').value == "" ? 0 : parseFloat(document.getElementById('MRP').value)
        Rate = document.getElementById('PurchaseRate').value == "" ? 0 : parseFloat(document.getElementById('PurchaseRate').value)
        DisPer = document.getElementById('DisPer').value == "" ? 0 : parseFloat(document.getElementById('DisPer').value)
        TaxPer = document.getElementById('TaxPer').value == "" ? 0 : parseFloat(document.getElementById('TaxPer').value)

        DisAmt = parseFloat((( (Qty * Rate) / 100) * DisPer).toFixed(2))
        TaxAmt = parseFloat((( (Qty * MRP)/ 100) * TaxPer).toFixed(2))
        Total_Value = parseFloat(((((Qty * Rate) - DisAmt) + TaxAmt)).toFixed(2))
        document.getElementById('DisAmt').value = DisAmt;
        document.getElementById('TaxAmt').value = TaxAmt;
        document.getElementById('value').value = Total_Value; 

    }
    toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    LoadStockToGrid = mData =>{
        if(this.state.PO_Detail_Data.length > 0 )
        {
            for(let i = 0; i<this.state.PO_Detail_Data.length; i++){
               var isExist =  mData.find(x=> x.ItemID === this.state.PO_Detail_Data[i].ItemID )
               if(isExist)
               {
                   mData.splice(isExist,1)     
                   Swal.fire({
                    icon: 'info',
                    text: `Same Products are Eliminated...`,
                })
               
               }
            }
        }
            this.setState({ PO_Detail_Data: this.state.PO_Detail_Data.concat(mData) },()=>{
                this.Calculate_Grand_Total()
                var  mArray = [...this.state.PO_Detail_Data]
                   mArray.map((value,index)=>{
                       value.SrNo = index + 1
                   })
                   this.setState({mArray})
               })
               
               if(this.state.Stock_Modal === true)
                this.Hide_Min_Stock_Modal()
                else if (this.state.ReStock_Modal)
                this.Hide_ReOrder_Stock_Modal()           
    }
    getLevel = childData=>{
        this.setState({ 
            inventoryLevel: childData.inventoryLevel,
            from1Date: childData.from1Date,
            from2Date: childData.from2Date,
            from3Date: childData.from3Date,
            to1Date: childData.to1Date,
            to2Date: childData.to2Date,
            to3Date: childData.to3Date
         });
    }
    Inv_Columns = [
        {
            field: "SupplierName",
            header: "Supplier Name",
            width: "150px",
        },
        {
            field: "ManufacturedBy",
            header: "Manufactured By",
            width: "150px",
        },
    ];
    handleFilterChange = event => {
        if (event) {
          this.setState({ filter: event.filter });
        }
    };
render(){
        if(this.grid)
            this.grid.autoFitColumns(['SrNo','ItemDescription','dd_qty','Godown','Mnuit']);
        enableRipple(true);
    return ReactDOM.createPortal (
        <>
          <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2> Purchase Order
                    </h2>
                </div>
            </div>
        </div>
            <Card>
           
            <Row className="row_margin_top row_margin_left row_margin_right p-4" >
                    <Col sm={12} lg={3} xl={3} md={3} >
                        <TextField 
                            name="PurchaseOrderNo"
                            label="Po No" 
                            value={this.state.PurchaseOrderNo}
                            inputProps={
                                {readOnly: true}
                            }
                            fullWidth
                            />
                    </Col>

                    <Col lg={6} xl={6} md={6} >
                        <MultiColumnComboBox
                            data={this.state.filter ? filterBy(this.state.SupplierList, this.state.filter) : this.state.SupplierList}
                            value={this.state.SelectedSupplier}
                            style={{ width: "100%", fontSize: "12px",backgroundColor:'white' }}
                            columns={this.Inv_Columns}
                            textField={"SupplierName"}
                            filterable={true}
                            label='Select Supplier'
                            onFilterChange={this.handleFilterChange}
                            onChange={e => {
                                if(this.state.SelectedSupplier && this.state.PO_Detail_Data.length > 0){
                                    const swalWithBootstrapButtons = Swal.mixin({
                                        customClass: {
                                            confirmButton: 'btn btn-success',
                                            cancelButton: 'btn btn-danger'
                                        },
                                        buttonsStyling: false
                                    })
                            
                                    swalWithBootstrapButtons.fire({
                                        title: 'Are you sure, You want to change Supplier?',
                                        text: "This might loss your Data in Grid",
                                        icon: 'warning',
                                        showCancelButton: true,
                                        confirmButtonText: `Yes`,
                                        cancelButtonText: 'No',
                                        reverseButtons: true
                                    }).then(async (result) => {
                                        if (result.isConfirmed) {
                                            var mArray = [];
                                            for (let i = 0; i < this.state.PO_Detail_Data.length; i++) {
                                                if(this.state.PO_Detail_Data[i].PurchaseOrderDetailID)
                                                {
                                                    mArray.push(this.state.PO_Detail_Data[i])
                                                }
                                            }
                                            this.setState({Deleted_PO_Detail_Data:mArray,PO_Detail_Data:[],GrandTotal:0},()=>{
                                                swalWithBootstrapButtons.fire(
                                                            `success`,
                                                            `Done...`,
                                                            'info'
                                                        )
                                            })
                                        } else if (
                                            /* Read more about handling dismissals below */
                                            result.dismiss === Swal.DismissReason.cancel
                                        ) {
                                            swalWithBootstrapButtons.fire(
                                                'Cancelled',
                                                `Your record is safe :)`,
                                                'error'
                                            )
                                        }
                                    })
                                }
                                if (e.value) {
                                    
                                    this.setState({SelectedSupplier:e.value,btn_Add:true,Supplier_Err:''})
                                    this.Select_Item_Details(e.value.SupplierID)
                                }
                                else {
                                    this.setState({SelectedSupplier:null,btn_Add:false})
                                }
                            }}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                        {/* <Autocomplete
                            name="SupplierName"
                            id="SupplierName"
                            options={this.state.SupplierList}
                            getOptionLabel={(option) => option.SupplierName ? option.SupplierName : ""}
                            value={this.state.SelectedSupplier}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedSupplier:value,btn_Add:true,Supplier_Err:''})
                                    this.Select_Item_Details(value.SupplierID)
                              
                                }
                                else
                                {
                                    this.setState({SelectedSupplier:null,btn_Add:false})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="SupplierName" label="Supplier"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span> */}
                    </Col>
                    {/* <Col lg={3} xl={3} md={3} >
                        <Autocomplete
                            name="WareHouse"
                            id="WareHouse"
                            options={this.state.WareHouseList}
                            getOptionLabel={(option) => option.WareHouse ? option.WareHouse : ""}
                            value={this.state.SelectedWareHouse}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedWareHouse:value})
                              
                                }
                                else
                                {
                                    this.setState({SelectedWareHouse:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="WareHouse" label="WareHouse"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col> */}
                    <Col sm={12} lg={3} xl={3} md={3} >
                        <TextField 
                            value={this.state.PurchaseOrderDate}
                            name="PurchaseOrderDate"
                            label="Order Date" 
                            type= 'date'
                            onChange= {e => this.setState({PurchaseOrderDate:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={6} xl={6} md={2} >
                        <TextField
                            name="Discription"
                            label="Discription" 
                            value={this.state.Discription}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({Discription:e.target.value})}
                            fullWidth
                        />
                    </Col> 
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="GrandTotal"
                            label="GrandTotal" 
                            value={this.state.GrandTotal}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({GrandTotal:e.target.value})}
                            fullWidth
                            inputProps={
                                {readOnly: true}
                            }
                        />
                    </Col> 
                    <Col sm={12} lg={2} xl={2} md={2} >
                    <Link id="btnFetchRecord" className="btn btn-primary rounded-pill mt-2" style={{border: "none"}} to={'#'} onClick={this.Show_AutoOrder_Modal}>  <ShowChart /><u>Get Auto Order</u></Link>

                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                    <Link id="btnClear" className="btn btn-primary rounded-pill mt-2" style={{border: "none"}} to={'#'} onClick={()=> this.setState({PO_Detail_Data:[], GrandTotal: 0.00})}>  <ShowChart /><u>Clear</u></Link>

                    </Col>
                </Row>
                <Row className="row_margin_top row_margin_left row_margin_right p-4 mt-2" >
                    <div style={{display:"flex",width:"100%",height:"100%",overflow:"hidden"}}> 
                        <div style={{flex:"1",width:"100%",height:"100%"}}>
                            <GridComponent dataSource={this.state.PO_Detail_Data} actionBegin={ this.beforeRowInsert} ref={g => this.grid = g} actionComplete={this.afterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: true, allowDeleting: true, allowAdding: this.state.btn_Add, newRowPosition:'Bottom'  }} sortSettings={this.sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={this.toolbarOptions}>
                                <ColumnsDirective>
                                    <ColumnDirective width='120' field='SrNo' headerText='SrNo #' Format= "{0:n}" allowEditing={false} isIdentity={true}  textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                    <ColumnDirective width='250' field='ItemDiscription' headerText='ItemDiscription' editType= 'dropdownedit'  editTemplate={this.ItemDiscription}   edit={this.cbParams} allowEditing={false}  textAlign='Left'/> {/*validationRules={true} */}
                                    <ColumnDirective width='170' field='packSize' headerText='Pack Size' allowEditing={false} textAlign='right'/>
                                    <ColumnDirective width='170' field='NETSale' headerText='Net Sale' allowEditing={false} textAlign='right'/>
                                    <ColumnDirective width='170' field='ClosingQTy' headerText='Clossing Qty' allowEditing={false} textAlign='right'/>
                                    <ColumnDirective width='170' field='ReqQty' headerText='Req Qty' allowEditing={false} textAlign='right'/>
                                    <ColumnDirective width='170' field='Qty' headerText='Qty' editTemplate={this.Qty} textAlign='right'/>
                                    <ColumnDirective width='130' field='UOM' headerText='UOM'  editTemplate={this.UOM} allowEditing={false} />
                                    {/* <ColumnDirective width='170' field='Expairy' headerText='Expiry Date'  editTemplate={this.Expairy} textAlign='right'></ColumnDirective> */}
                                    <ColumnDirective width='150' field='PurchaseRate' headerText='PurchaseRate'   editTemplate={this.PurchaseRate} allowEditing={false} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='130' field='MRP' headerText='MRP' editTemplate={this.MRP} allowEditing={false} textAlign='right'/>
                                    <ColumnDirective width='150' field='DisPer' headerText='Dis%'   editTemplate={this.DisPer} allowEditing={false} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='DisAmt' headerText='DisAmt'   editTemplate={this.DisAmt} allowEditing={false} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='TaxPer' headerText={this.Comp_Id === 6 ? 'VAT%' : 'Tax%'} allowEditing={false}  editTemplate={this.TaxPer} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='TaxAmt'  headerText={this.Comp_Id === 6 ? 'VAT Amt' : 'Tax Amt'} allowEditing={false}  editTemplate={this.TaxAmt} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='Value' headerText='Value'   editTemplate={this.Value} allowEditing={false} textAlign='right'></ColumnDirective>
                                    
                                    <ColumnDirective field='ItemID'  visible={false}  ></ColumnDirective>
                                  
                                    <ColumnDirective field='PurchaseOrderDetailID' headerText='PurchaseOrderDetailID'  visible={false}></ColumnDirective>
                                    <ColumnDirective field='PurchaseOrderID' headerText='PurchaseOrderID'  width='150' hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='UOMID' headerText='UOMID'  width='150' hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='sSale' headerText='First Sale'  hideAtMedia={true} width='150'  ></ColumnDirective>
                                    <ColumnDirective field='fSale' headerText='Second Sale' hideAtMedia={true}  width='150'  ></ColumnDirective>
                                    <ColumnDirective field='tSale' headerText='Third Sale'  hideAtMedia={true}  width='150'  ></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]}   />
                            </GridComponent>
                        </div>
                    </div> 
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                    <Col style={{textAlign: "right", marginTop: "1%"}} >
                            <Link id="btnReStock" className="btn btn-primary rounded-pill" style={{border: "none"}} to={'#'} onClick={this.Show_ReOrder_Stock_Modal}>  <ShowChart /><u>Reorder. Stock</u></Link>
                             <Link id="btnStock" className="btn btn-primary rounded-pill" style={{border: "none"}} to='#' onClick={this.Show_Min_Stock_Modal}> <ShowChart /><u>Get Min. Stock</u></Link>
                            {this.state.btn_edit && (<button type="button" className="btn btn-primary rounded-pill"  style={{border: "none"}} onClick={this.SaveHandler} disabled={this.state.btn_Disabled}>
                            {
                             this.state.btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                                }  </button>)}
                            <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{border: "none"}} to={'/PurchaseOrderList'} > <Cancel /> <u>Close</u></Link>
                            {this.state.btn_edit && ( <button 
                             className="btn btn-primary rounded-pill" 
                             style={{border: "none"}} 
                             onClick={e =>{
                                var obj = {
                                    PurchaseOrderNo:this.state.PurchaseOrderNo,
                                    PurchaseOrderDate:this.state.PurchaseOrderDate,
                                    Discription:this.state.Discription,
                                    GrandTotal:this.state.GrandTotal,
                                    Detail: this.state.PO_Detail_Data,
                                    Supplier: this.state.SelectedSupplier,
                                    Warehouse: this.state.SelectedWareHouse,
                                    from1Date: this.state.from1Date,
                                    from2Date: this.state.from2Date,
                                    from3Date: this.state.from3Date,
                                    to1Date: this.state.to1Date,
                                    to2Date: this.state.to2Date,
                                    to3Date: this.state.to3Date
                                }
                                localStorage.setItem('token',sessionStorage.getItem('token'))
                                localStorage.setItem("PurchaseOrder", JSON.stringify(obj))
                                this.SaveHandler(true)
                             }}
                             disabled={this.state.btn_Disabled}
                             > 
                              {
                             this.state.btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <> <Print /> <u>Save &amp; Print</u></>
                                }
                            </button>)}
                        
                        </Col>
                    </Row>
                    <MinStockModal 
                        show={this.state.Stock_Modal}
                        onHide = {this.Hide_Min_Stock_Modal}
                        mSaveStock = {this.LoadStockToGrid}
                        Project_ID = {this.ProjectID}
                        SupplierID = {this.state.SelectedSupplier && this.state.SelectedSupplier.SupplierID}
                    />
                     <ReOrderStockModal 
                        show={this.state.ReStock_Modal}
                        onHide = {this.Hide_ReOrder_Stock_Modal}
                        mSaveStock = {this.LoadStockToGrid}
                        Project_ID = {this.ProjectID}
                        SupplierID = {this.state.SelectedSupplier && this.state.SelectedSupplier.SupplierID}
                    />
                    <AutoOrderModal
                        show={this.state.AutoOrderModal}
                        onHide = {this.Hide_AutoOrder_Modal}
                        mSaveStock = {this.LoadStockToGrid}
                        Project_ID = {this.ProjectID}
                        manufacturedByID = {this.state.SelectedSupplier && this.state.SelectedSupplier.ManufacturedByID}
                        getLevel =  {this.getLevel}
                    />



                    <Loader
                    show={this.state.IsLoader}
                    hide={this.hideLoader}
                    />
                </Card>
            
        </>, document.getElementById('PageContents')
    )
}

}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    isAddNew: state.PO && state.PO.isAddNew,
    PurchaseOrderID:state.PO && state.PO.PurchaseOrderID,
    Project_ID:state.PO && state.PO.Project_ID,
    show_screen: state.SCREEN
})
export default connect(mapStateToProps)(PurchaseOrderEntry)