import React, { useEffect, useState } from 'react'
import { Modal, Card, Row, Col } from "react-bootstrap"
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Loop, Cancel } from "@material-ui/icons"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Autocomplete } from '@material-ui/lab'

import { SelectUserProjects } from '../../../Services/Projects'
import { GetCurrentDate, GetFirstDateofCurrentMonth } from '../../../Services/Common'
import { SelectWareHouseList } from '../../../Services/WareHouseAPI'
import { SelectItemsListWRTProject } from '../../../Services/ItemsAPI'

function InventoryReport_Modal({show, onHide,Comp_Id,User_Id,toPath,readonlyProps,title }) {
    const [Project_List, setProject_List] = useState([])
    var   [SelectedProject, setSelectedProject] = useState(null)
    const [FromDate, setFromDate] = useState(GetFirstDateofCurrentMonth())
    const [ToDate, setToDate] = useState(GetCurrentDate())
    const [WareHouse_List, setWareHouse_List] = useState([])
    const [SelectedWareHouse, setSelectedWareHouse] = useState(null)
    const [ReportPath, setReportPath] = useState('#')
    const [ItemList, setItemList] = useState([])
    const [SelectedFromItem, setSelectedFromItem] = useState(null)
    const [SelectedToItem, setSelectedToItem] = useState(null)
    var [mTarget, setMTarget] = useState('');
    const [Error_State, setError_State] = useState({
        project_Err : '',
        warehouse_Err:'',
        fromItem_Err:'',
        toItem_Err : '',
    })
    useEffect(() => {
        if(show && toPath){
            SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
                setSelectedProject(mProjects[0])
                setProject_List(mProjects)
                Select_Item(mProjects[0].Project_ID)
                Select_WareHouse(mProjects[0].Project_ID)
            })
        }
    }, [show])
  
    
    const Select_Item = (Project_ID)=>{
        SelectItemsListWRTProject(Comp_Id,Project_ID,(mItems)=>{
            setItemList(mItems)
        })
    }
    const Select_WareHouse = (Project_ID)=>{
        SelectWareHouseList(Comp_Id,Project_ID,(mWH)=>{
            setWareHouse_List(mWH)
        })
    }
    const NavigateToReport = ()=>{
        if(toPath == '/StockBalanceReport'){
            if(SelectedProject){ 
                localStorage.setItem('token', sessionStorage.getItem('token'))
               
                window.open(`${toPath}/${SelectedProject.Project_ID}`,'_blank')
            } else{
                if(!SelectedProject){
                    setError_State({...Error_State,project_Err:'required'})
                }
            }
        }
        else if(toPath == '/StockWareHouseWiseReport'){
            if(SelectedProject && SelectedWareHouse && SelectedFromItem && SelectedToItem){
                localStorage.setItem('token', sessionStorage.getItem('token'))
                window.open(`${toPath}/${SelectedProject.Project_ID}/${SelectedWareHouse.WareHouseID}/${SelectedFromItem.ItemID}/${SelectedToItem.ItemID}`,'_blank')
            } else{
                if(!SelectedProject){
                    setError_State({...Error_State,project_Err:'required'})
                }
                else if(!SelectedWareHouse){
                    setError_State({...Error_State,project_Err:'',warehouse_Err:'required'})
                }
                else if(!SelectedFromItem){
                    setError_State({...Error_State,project_Err:'',warehouse_Err:'',fromItem_Err:'required'})

                }
                else if(!SelectedToItem){
                    setError_State({...Error_State,project_Err:'',warehouse_Err:'',fromItem_Err:'',toItem_Err:'required'})

                }
            }
        }
        else if(toPath == '/StockBatchWiseReport')
        {
            if (SelectedProject){
                localStorage.setItem('token', sessionStorage.getItem('token'))
                
                window.open(`${toPath}/${SelectedProject.Project_ID}`,'_blank')
            } else{
                if(!SelectedProject){
                    setError_State({...Error_State,project_Err:'required'})
                }
            }
        }
        else if (toPath == '/ShortInventoryItemsReport'){
            if(SelectedProject){
                localStorage.setItem('token', sessionStorage.getItem('token'))
               
                window.open(`${toPath}/${SelectedProject.Project_ID}`,'_blank') 
            } else{
                if(!SelectedProject){
                    setError_State({...Error_State,project_Err:'required'})
                }
            }
        }
        else if (toPath == '/MaxInventoryItemsReport'){
            if(SelectedProject){
                localStorage.setItem('token', sessionStorage.getItem('token'))
               
                window.open(`${toPath}/${SelectedProject.Project_ID}`,'_blank') 
            } else{
                if(!SelectedProject){
                    setError_State({...Error_State,project_Err:'required'})
                }
            }
        }
    }
    const ResetCotrols = () =>{
        setProject_List([])
        setSelectedProject(null)
        setFromDate(GetCurrentDate())
        setToDate(GetCurrentDate())
        setWareHouse_List([])
        setSelectedWareHouse(null)
        setReportPath('#')
        setItemList([])
        setSelectedFromItem(null)
        setSelectedToItem(null)
        setError_State({
            project_Err : '',
            warehouse_Err:'',
            fromItem_Err:'',
            toItem_Err : '',
        })
    }
    return (
        <>
        <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" id="Inventory_Modal" >
            <Modal.Header closeButton className="header_title" >
            <Modal.Title> Inventory Reports / {title} </Modal.Title>
            </Modal.Header>
                <Modal.Body id='mBody'>
                    <Card>
                    <Row className="row_margin_left row_margin_right" >
                            
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="Project"
                                    id="Project"
                                    options={Project_List}
                                    getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                                    value={SelectedProject}
                                    disabled={readonlyProps.Project}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedProject(value)
                                            Select_Item(value.Project_ID)
                                            Select_WareHouse(value.Project_ID)
                                        }
                                        else{
                                            setSelectedProject(null)
                                            setItemList([])
                                            setWareHouse_List([])
                                            setSelectedWareHouse(null)
                                            setSelectedFromItem(null)
                                            setSelectedToItem(null)
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="Project" label="Select Branch"
                                        />
                                    }
                                />
                                   <span className="text-danger">{Error_State.project_Err}</span>
                            </Col>
                        
                          
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="WareHouse"
                                    id="WareHouse"
                                    options={WareHouse_List}
                                    getOptionLabel={(option) => option.WareHouse ? option.WareHouse : ""}
                                    value={SelectedWareHouse}
                                    disabled={readonlyProps.WareHouse}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedWareHouse(value)
                                        }
                                        else
                                            setSelectedWareHouse(null)

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="WareHouse" label="WareHouse"
                                        />
                                    }
                                />
                                   <span className="text-danger">{Error_State.warehouse_Err}</span>
                            </Col>
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="FromItem"
                                    id="FromItem"
                                    options={ItemList}
                                    getOptionLabel={(option) => option.ItemName ? option.ItemName : ""}
                                    value={SelectedFromItem}
                                    disabled={readonlyProps.FromItem}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedFromItem(value)
                                        }
                                        else
                                            setSelectedFromItem(null)

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="FromItem" label="FromItem"
                                        />
                                    }
                                />
                                   <span className="text-danger">{Error_State.fromItem_Err}</span>
                            </Col> 
                        <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="ToItem"
                                    id="ToItem"
                                    options={ItemList}
                                    getOptionLabel={(option) => option.ItemName ? option.ItemName : ""}
                                    value={SelectedToItem}
                                    disabled={readonlyProps.ToItem}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedToItem(value)
                                        }
                                        else
                                            setSelectedToItem(null)

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="ToItem" label="ToItem"
                                        />
                                    }
                                />
                                   <span className="text-danger">{Error_State.toItem_Err}</span>
                            </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                <button className="btn btn-primary rounded-pill"  style={{ border: "none" }} onClick={NavigateToReport} > <Loop /> <u>Load</u></button>
                                <button type="button" className="btn btn-primary rounded-pill" onClick={e=>{
                                    ResetCotrols()
                                    onHide()
                                }} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
        </Modal>
        </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables.Company && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables.Company &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User.User_Data && state.Login_User.User_Data.User_id
})
export default connect(mapStateToProps)(InventoryReport_Modal)
