import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import './InvoicePrint.css'

function setPageSize(cssPageSize) {

    const style = document.createElement('style');
    style.innerHTML = `@page {size: ${cssPageSize}}`;
    style.id = 'page-orientation';
    document.head.appendChild(style);
}

function SaleOrderReport({ CompName,ConfigObj }) {
    const { Comp_Id, InvID } = useParams()
    const [InvoiceTableData, setInvoiceTableData] = useState([])
    const [Inv, setInv] = useState('')
    const [InvDate, setInvDate] = useState('')
    const [Supplier, setSupplier] = useState('')
    const [Godown, setGodown] = useState('')
    const [PaymentType, setPaymentType] = useState('')
    const [BankName, setBankName] = useState('')
    var [TotalQty, setTotalQty] = useState(0.00)
    var [TotalNetAmt, setTotalNetAmt] = useState(0.00)


    useEffect(() => {

        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('footer').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;

       var testing  = localStorage.getItem("SaleOrder")

       var _Data = JSON.parse(localStorage.getItem("SaleOrder"))
       
       
       setInv(_Data.SaleOrderNo)
       setInvDate(_Data.SaleOrderDate)
       setSupplier(_Data.Supplier.CustomerName)
       setGodown(_Data.WareHouse.WareHouse)
       setInvoiceTableData(_Data.Detail)


       
       
    }, [])

    return (
        <div className='size' >
            <Row className='between-rows-margin between-left-margin between-right-margin'>
                <Col sm={3} md={3} xl={3}>
                <img src={ConfigObj.Logo} width="100" height="100" />
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'center' }}>
                    <h3 style={{ textAlign: 'center' }}> {CompName} </h3> <br/> <h4>Sale Order</h4>
                </Col>
            </Row>

            <Row className='between-rows-margin between-left-margin between-right-margin' >

                <Col lg={8} md={8} >
                    <Row lg={12} md={12} xl={12} >
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            Inv.#
                        </Col>
                        <Col sm={9} md={9} xl={9}>
                            { Inv }
                        </Col>
                    </Row>

                    <Row lg={12} md={12} xl={12}>
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            Inv Date:
                        </Col>
                        <Col sm={9} md={9} xl={9}>
                            { InvDate }
                        </Col>
                    </Row>
                   
                </Col>

                <Col lg={4} md={4}>
                <Row lg={12} md={12} xl={12}>
                        <Col sm={4} md={4} xl={4} style={{ fontWeight: "bold" }}>
                            Customer Name:
                        </Col>
                        <Col sm={8} md={8} xl={8}>
                            { Supplier }
                        </Col>
                        <Col sm={4} md={4} xl={4} style={{ fontWeight: "bold" }}>
                            Godown:
                        </Col>
                        <Col sm={8} md={8} xl={8}>
                            { Godown }
                        </Col>
                    </Row>
                </Col>
            </Row>


            <Row className='between-rows-margin between-left-margin between-right-margin' >
                <Col>
                    <div id='table_sec_invprt'>
                        <table id='main_table_invoicepr' style={{ marginBottom: "20px" }}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}> Sr # </th>
                            <th style={{ textAlign: 'left' }}> Item Description </th>
                            <th style={{ textAlign: 'center' }}> Qty </th>
                            <th style={{ textAlign: 'center' }}> UOM </th>
                            <th style={{ textAlign: 'right' }}> Rate </th>
                            {/* <th style={{ textAlign: 'right' }}> Discount </th>
                            <th style={{ textAlign: 'right' }}> Tax </th> */}
                            <th style={{ textAlign: 'right' }}> Amount </th>
                        </tr>
                    </thead>

                    <tbody >
                        {
                            InvoiceTableData.map((invoicetabledata, index) => {
                                TotalQty += invoicetabledata.Qty
                                TotalNetAmt += invoicetabledata.Value
                                return(                               
                                    <tr key={index}>
                                        <td style={{ textAlign: 'center' }}> {index + 1} </td>
                                        <td style={{ textAlign: 'left' }}> {invoicetabledata.ItemDiscription} </td>
                                        <td style={{ textAlign: 'center' }}> {invoicetabledata.Qty} </td>
                                        <td style={{ textAlign: 'center' }}> {invoicetabledata.UOM} </td>
                                        <td style={{ textAlign: 'right' }}> {invoicetabledata.SaleRate} </td>
                                        {/* <td style={{ textAlign: 'right' }}> {invoicetabledata.DisAmt} </td>
                                        <td style={{ textAlign: 'right' }}> {invoicetabledata.TaxAmt} </td> */}
                                        <td style={{ textAlign: 'right' }}> {invoicetabledata.Value} </td>
                                    </tr>                                
                            )
                        })
                        }

                    </tbody>
                    <tfoot>
                        <tr>
                            <td style={{ textAlign: 'right' }} colSpan='2'  > Total:</td>
                            <td style={{ textAlign: 'center' }}>{TotalQty}</td>
                            <td style={{ textAlign: 'right' }}></td>
                            <td style={{ textAlign: 'right' }}></td>
                            {/* <td style={{ textAlign: 'right' }}></td>
                            <td style={{ textAlign: 'right' }}></td> */}
                            <td style={{ textAlign: 'right' }}>{TotalNetAmt}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
                </Col>
            </Row>

           
        </div>

    )
}

const mapStateToProps = state => ({
    CompName: state.GlobalVariables.Company.Name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig

})

export default connect(mapStateToProps)(SaleOrderReport)

// export default InvoicePrint