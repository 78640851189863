import React from 'react'
import ReactDOM from 'react-dom'
import {Row, Col,Card, Spinner, Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Save, Cancel, Print } from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';

import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
//  import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
 import { connect} from 'react-redux'
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import { SelectCityList , SaveCity } from '../../Services/CityAPI'
import { SelectDistrict } from '../../Services/DistrictAPI'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { GetCurrentDate } from '../../Services/Common';
import Loader from '../Loader'

export class City extends React.Component {
  constructor(props){
      super(props)

      this.state = {
        City:[],
        DeletedCity:[],
        District:[],
        SelectedDistrict:null,
       btn_Add: true,
       btn_Edit: true,
       btn_Delete: true,
       Others: null,
       btn_Disabled:false,
       IsLoader:false,

      }
      this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
      this.validationRules = { required: true };
      this.bg_Ref = React.createRef()
      this.grid = null
      this.Comp_Id = this.props.Comp_Id
      this.ProjectID = this.props.Project_ID
      this.BGValidation = {
        required: [this.customBGValidation, 'City must be Entered'],
      };
  }
  sortingOptions = {
    columns: [{ field: 'SrNo', direction: 'Ascending' }]
  };
 
  customBGValidation(args) {
  return getValue('value', args) != ''   
  }

 
  componentDidMount()
  {
    document.title = " Distribution / City"
    SelectGroupScreenAccess(this.props.Group_Id,13,85,(data)=>{
        
        this.setState({ btn_Add: parseInt(data.AddNew) === 0 ? false : true, 
                        btn_Edit: parseInt(data.Edit) === 0 ? false : true,
                        btn_Delete: parseInt(data.Del) === 0 ? false : true,
                        Others:data.OtherAccess})
  
        
    })
 this.Select_CityList();
 this.Select_DistrictList()
  }
  Select_CityList =() =>{
    this.showLoader()
    SelectCityList(this.Comp_Id,this.ProjectID,(mCity)=>{
        this.hideLoader()
        
        mCity.map((value,index)=>{
            mCity[index].SrNo = index + 1
        })
        if(mCity.length > 0 )
        this.setState({City:mCity})
    })
  }
  Select_DistrictList =() =>{

    SelectDistrict(this.Comp_Id,this.ProjectID,(mDistrict)=>{
        if(mDistrict.length > 0 )
        this.setState({District:mDistrict})
    })
  }
  DistrictName = (rowValue) => {

    return (
        <Autocomplete
            id="DistrictName"
            name="DistrictName"
            options={this.state.District}
            defaultValue={this.state.District.find(x => x.DistrictID === rowValue.DistrictID)}
            getOptionLabel={(option) => option.DistrictName ? option.DistrictName : "" } 
            onChange={(e, value) => {

                if (value) {
                    this.setState({SelectedDistrict:value})
                }
                else {
                    this.setState({SelectedDistrict:null})
                }
            }}
            renderInput={(params) => <TextField {...params} />}
            fullWidth
        />
    )
}
  City = (rowValue) =>{
    return(<input
        style={{width:"80%"}}
        type="text"
        id="City"
        className="textbox"
        name='City'
         defaultValue={rowValue.City}
         onInput={this.toInputUppercase}
    />)
}

showLoader = () => {
    this.setState({IsLoader:true})
   
  }
   hideLoader = () => {
    this.setState({IsLoader:false})
  }


SaveHandler =()=>{
    
        if(this.state.City.length > 0 || this.state.DeletedCity.length > 0)
        {
            for (let i = 0; i < this.state.City.length; i++) {
                if(this.state.City[i].City === undefined || (this.state.City[i].City).trim() === '')
                {
                    Swal.fire({
                        icon:'error',
                        text:`City Name at Sr. No. ${this.state.City[i].SrNo} is required`
                    })
                    return
                }
                if(this.state.City[i].DistrictName === undefined || (this.state.City[i].DistrictName).trim() === '')
                {
                    Swal.fire({
                        icon:'error',
                        text:`District Name at Sr. No. ${this.state.City[i].SrNo} is required`
                    })
                    return
                }
            }
            this.setState({btn_Disabled:true})
            SaveCity(this.state.City,this.state.DeletedCity,()=>{
                this.setState({btn_Disabled:false})
                this.Select_CityList();
            })
        }
        else
        { 
            Swal.fire({
                icon: 'info',
                text: `City Must be Entered...`,
            })
            return
        }
}


beforeRowInsert = (rowValue) =>{
    
    if(rowValue.rowData)
    {
        if(rowValue.data)
        {
            if(rowValue.data.SrNo)
            {
                if(rowValue.requestType != "cancel")
                {
                     rowValue.data.Comp_Id = this.Comp_Id
                     rowValue.data.ProjectID = this.ProjectID
                     if(this.state.SelectedDistrict){
                         rowValue.data.DistrictID = this.state.SelectedDistrict.DistrictID;
                         rowValue.data.DistrictName = this.state.SelectedDistrict.DistrictName;
                     }
                     if(rowValue.data.CityID === undefined){
                        rowValue.data.action = 'add'
                     }
                     else{
                        rowValue.data.action = rowValue.action

                     }
                }
            }    
            else
            {
                rowValue.data.SrNo = this.state.City.length + 1
                this.setState({SelectedDistrict:null})
            }
        }
    }
  
}
afterRowInsert = (rowValue) =>{ 
 
    if(rowValue.requestType === 'delete')
    {
        if(rowValue.data[0].CityID)
        {
            this.setState({DeletedCity:this.state.DeletedCity.concat(rowValue.data)})
            
        }
    }else if (rowValue.requestType == "beginEdit") {

        document.getElementById('City').focus()
        let _DistrictData = this.state.District.find(x => x.DistrictID === rowValue.rowData.DistrictID)
        this.setState({SelectedCity:_DistrictData})
    }
}

toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
} 

render(){
        if(this.grid)
            this.grid.autoFitColumns(['SrNo','City']);
         enableRipple(true);
    return ReactDOM.createPortal (
        <>
            <div className="block-header">
                <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <h2>City
                        </h2>
                    </div>
                </div>
            </div>
    <Card>     
                <Row className="row_margin_top row_margin_left row_margin_right pt-4" style={{width:'80%', marginLeft:'10%'}} >
                    <div style={{display:"flex",width:"100%",height:"100%",overflow:"hidden"}}> 
                        <div style={{flex:"1",width:"100%",height:"100%"}}> 
                            <GridComponent dataSource={this.state.City} actionBegin={ this.beforeRowInsert} ref={g => this.grid = g} actionComplete={this.afterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: this.state.btn_Edit, allowDeleting: this.state.btn_Delete, allowAdding: this.state.btn_Add, newRowPosition:'Bottom'  }} sortSettings={this.sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={this.toolbarOptions}>
                                <ColumnsDirective>
                                    <ColumnDirective width='70' field='SrNo' headerText='SrNo #' Format= "{0:n}" allowEditing={false} isIdentity={true}  textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                    <ColumnDirective width='250' field='City' headerText='City' editTemplate={this.City} textAlign='Left'/> 
                                    <ColumnDirective width='200' field='DistrictName' headerText='District Name'  editTemplate={this.DistrictName} textAlign='Left' />
                                    
                                    
                                    <ColumnDirective field='CityID' headerText='CityID'  visible={false}></ColumnDirective>
                                    <ColumnDirective field='Comp_Id' headerText='Comp_Id'  hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='ProjectID' headerText='ProjectID'  hideAtMedia={true} ></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]}   />
                            </GridComponent>
                        </div>
                    </div> 
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right row_margin_bottom" >
                    <Col style={{textAlign: "right", marginTop: "1%"}} >
                            <button type="button" className="btn btn-primary rounded-pill"  style={{border: "none"}} onClick={this.SaveHandler}  disabled={this.state.btn_Disabled}>  {
                             this.state.btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                                } </button>
                                <Button variant="btn btn-primary rounded-pill" onClick={e =>{
                               
                                    const ws = XLSX.utils.json_to_sheet(this.state.City);
                                    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                                    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                                    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
                                    FileSaver.saveAs(data, `Cities - ${GetCurrentDate()}`  +  ".xlsx");
                                  }} style={{border: "none"}} > <CloudDownloadIcon />  Export to Excel</Button> 
                            <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{border: "none"}} to={'#'} > <Cancel /> <u>Close</u></Link>
                            <Link type="button" className="btn btn-primary rounded-pill" to="/CityReport" target={"_blank"} onClick={e=>{
                                localStorage.setItem('token',sessionStorage.getItem('token'))
                                localStorage.setItem("CityData",JSON.stringify(this.state.City))
                            }} style={{ border: "none" }} > <Print />  Print</Link>
                        </Col>
                    </Row>
                    <Loader
                        show={this.state.IsLoader}
                        hide={this.hideLoader}
                    />
                </Card>
            
        </>, document.getElementById('PageContents')
    )
}

}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Project_ID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
    Group_Id : state.Login_User.User_Data && state.Login_User.User_Data.Group_Id
})

export default connect(mapStateToProps)(City)