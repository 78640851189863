import React, { useEffect, useState } from 'react'
import { Modal, Card, Row, Col } from "react-bootstrap"
import {  TextField } from '@material-ui/core'
import { Loop, Cancel } from "@material-ui/icons"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Autocomplete } from '@material-ui/lab'

import { SelectUserProjects } from '../../../Services/Projects'
import { GetCurrentDate, GetFirstDateofCurrentMonth } from '../../../Services/Common'

function PLReport_Modal({show, onHide,Comp_Id,User_Id,toPath,readonlyProps,title }) {
    const [Project_List, setProject_List] = useState([])
    var   [SelectedProject, setSelectedProject] = useState(null)
    const [FromDate, setFromDate] = useState(GetFirstDateofCurrentMonth())
    const [ToDate, setToDate] = useState(GetCurrentDate())
    const [ReportPath, setReportPath] = useState('#')
    var [mTarget, setMTarget] = useState('');
    useEffect(() => {
        if(show && toPath){
            SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
                setSelectedProject(mProjects[0])
                setProject_List(mProjects)
            })
        }
    }, [show])
  
    
  
    const NavigateToReport = ()=>{
        if(toPath == '/DailyActivityReport'){
            if(SelectedProject){ 
                localStorage.setItem('token', sessionStorage.getItem('token'))
                window.open(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}`,'_blank')
            } else{
                if(!SelectedProject){
                    setMTarget('required')
                }
                
            }
        }
        else if(toPath == '/PLReport'){
            if(SelectedProject){ 
                localStorage.setItem('token', sessionStorage.getItem('token'))
                window.open(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}`,'_blank')
            } else{
                setMTarget('required')
            }
        }
    }
    const ResetCotrols = () =>{
        setProject_List([])
        setSelectedProject(null)
        setFromDate(GetCurrentDate())
        setToDate(GetCurrentDate())
        setReportPath('#')
        setMTarget('')
    }
    return (
        <>
        <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" id="Inventory_Modal" >
            <Modal.Header closeButton className="header_title" >
            <Modal.Title> Profit &amp; Loss Reports / {title} </Modal.Title>
            </Modal.Header>
                <Modal.Body id='mBody'>
                    <Card>
                    <Row className="row_margin_left row_margin_right" >
                    <Col sm={12} lg={4} xl={4} md={4} >
                             <TextField 
                                name="FromDate" 
                                label="From Date"
                                type='date'
                                value={FromDate}
                                onChange={e=>setFromDate(e.target.value)}
                                fullWidth
                                inputProps={{
                                    readOnly: readonlyProps.FromDate
                                }
                                }
                             />
                             </Col>
                             <Col sm={12} lg={4} xl={4} md={4} >
                             <TextField 
                                name="ToDate" 
                                label="To Date"
                                type='date'
                                value={ToDate}
                                onChange={e=>setToDate(e.target.value)}
                                fullWidth
                                inputProps={{
                                    readOnly: readonlyProps.ToDate
                                }
                                }
                             />
                             </Col>
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="Project"
                                    id="Project"
                                    options={Project_List}
                                    getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                                    value={SelectedProject}
                                    disabled={readonlyProps.Project}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedProject(value)
                                        }
                                        else{
                                            setSelectedProject(null)
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="Project" label="Select Branch"
                                        />
                                    }
                                />
                                 <span className="text-danger">{mTarget}</span>
                            </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                <button className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={NavigateToReport} > <Loop /> <u>Load</u></button>
                                <button type="button" className="btn btn-primary rounded-pill" onClick={e=>{
                                    ResetCotrols()
                                    onHide()
                                }} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
        </Modal>
        </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables.Company && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables.Company &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User.User_Data && state.Login_User.User_Data.User_id
})
export default connect(mapStateToProps)(PLReport_Modal)
