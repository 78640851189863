import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'


export const SelectItemsListWRTManufactured =(Comp_Id,ProjectID, ManufacturedByID,Callback) =>{
    axios.get(`${domain}/ChangeRate/SelectItemsListWRTManufactured/${Comp_Id}/${ProjectID}/${ManufacturedByID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }

  export const UpdateBatchRate =(mObj,Callback) =>{
    axios.post(`${domain}/ChangeRate/UpdateBatchRate`, {mObj})
         .then(response=>{
             if(response.status === 500){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `${response.data.message}`,
                  })
             }else if(response.status === 200){
                Swal.fire({
                    icon: 'sucess',
                    title: 'Success',
                    text: `${response.data.message}`,
                  })
             }
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }