import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../Services/Common'
import "../Reports/SaleReports/Table.css"

function ItemsReport({Comp_Name,Comp_Id,User_Name,User_Id,ConfigObj}) {


    const [ItemData, setItemData] = useState([]);
    const [GrandTotal, setGrandTotal] = useState(0);
    const [SelectedProject, setSelectedProject] = useState(null)
    const [Manufacturer,setManufacturer] = useState('')

    useEffect(() => {
        document.title = "Distribution / Items" 

        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('footer').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;

        var _ItemsData =  JSON.parse(localStorage.getItem("ItemsData"))
        if(_ItemsData){
            setItemData(_ItemsData)
            localStorage.removeItem("ItemsData")
        }
        
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
            <Row  style={{ textAlign: 'center', color: "black" }}>
            <Col sm={3} md={3} xl={3}>
            <img src={ConfigObj.Logo} width="100" height="100" />
            </Col>
            <Col sm={6} md={6} xl={6}>
                    <h2> {Comp_Name} </h2> <br /> <h3>Items</h3>
                </Col>
                
            </Row>
            
            
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Item Code</th>
                            <th style={{ width: '15%', textAlign: 'left', fontWeight: "bold" }} >Description</th>
                            <th style={{ width: '15%', textAlign: 'left', fontWeight: "bold" }} >Manufactured By</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Stock Qty</th>
                            <th style={{ width: '5%', textAlign: 'center', fontWeight: "bold" }} > Sale Rate</th>
                            {/* <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Sale Rate</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Purchase Rate</th> 
                            
                            <th style={{ width: '5%', textAlign: 'center', fontWeight: "bold" }} > Purchase Rate</th>*/}
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                         
                        {
                            ItemData.map((Itemdata, index) => {
                            return(
                                                               
                                <>
                                {
                                    Itemdata.title === true ? 
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none',borderBottom:'none'}}>
                                        <td colSpan={7} style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.ManufacturedBy}</td>
                                       </tr>
                                    :
                                    Itemdata.title === "CompanyTotal" ? 
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none'}}>
                                        <td colSpan={6} style={{ width: '10%', textAlign: 'right', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',paddingRight:'15px',fontWeight:'bold',borderRight:'2px solid black' }}  >Company Total :</td>
                                        <td colSpan={1} style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  > {Itemdata.total} </td>
                                    </tr>
                                    :
                                    <tr key={index} >
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.ItemCode}</td>
                                        <td style={{ width: '20%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}    >{Itemdata.ItemDiscription}</td>
                                        <td style={{ width: '20%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}    >{Itemdata.ManufacturedBy}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.StockQty}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.PackSaleRate} </td>
                                        {/* <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.SaleRate} </td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.PurchaseRate} </td> 
                                        
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.PackPurRate} </td>*/}
                                    </tr>
                                }
                                </>
                               
                            )}
                            )
                        }
                        {/* <tr id='footer_balance'>
                            <td id='footer_td_cb' colSpan="5" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  >Grand Total :</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  >{GrandTotal}</td>
                        </tr> */}
                    </tbody>
                </table>

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig

})

export default connect(mapStateToProps)(ItemsReport)
