import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit } from '@material-ui/icons'
import {TextField, Popper} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import  { connect } from 'react-redux'
import { _Years } from '../../Services/Common';
import { SelectSaleInvoiceList } from "../../Services/SaleInvoiceAPI";
import { set_SI, SHOW_SCREEN } from '../../React_Redux/Actions'
import { SelectUserProjects } from "../../Services/Projects"
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import Loader from '../Loader';

function SaleReturnList(props) {

    const {  set_SI,SALE_INVOICE, Comp_Id,p_userid, Group_Id, SHOW_SCREEN  } = props;
    const [SI_Data, setSI_Data] = useState([])
    const [Selected_Month, setSelected_Month] = useState(null)
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [ProjectList, setProjectList] = useState([])
    const [SelectedProject, setSelectedProject] = useState(null)
    const [Selected_Year, setSelected_Year] = useState(null)
    const [error_message, setError_message] = useState({
      Month: '',
      Project: '',
      Year:''
    })
    const [IsLoader, setIsLoader] = useState(false)

    useEffect(()=>{
    document.title = " Distribution / Sale Return"
    var _mdate = new Date()
    var cYear = _mdate.getFullYear()
    var mData = _Years.find(x => x.value == cYear)
    setSelected_Year(mData)
    SelectUserProjects(Comp_Id,p_userid,(ProjectData) =>{

      setProjectList(ProjectData)
      if(SALE_INVOICE.SI_data)
      {
          Select_SaleInvoiceList(SALE_INVOICE.SI_data.value, SALE_INVOICE.Project_ID,cYear)
          setSelected_Month(SALE_INVOICE.SI_data)
          setSelectedProject(ProjectData.find(x => x.Project_ID === SALE_INVOICE.Project_ID))
          set_SI(null,0,false)
      }
      else
      {
        setSelectedProject(ProjectData[0])
        var _Date = new Date()
        setSelected_Month(Month_List.find(x=> x.value === (_Date.getMonth())+1))
        Select_SaleInvoiceList((_Date.getMonth())+1, ProjectData[0].Project_ID,cYear)

      }
    })
    SelectGroupScreenAccess(Group_Id,16,104,(data)=>{
            
      setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
      setBtn_delete(parseInt(data.Del) === 0 ? false : true)

      SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
      parseInt(data.Edit) === 0 ? false : true,
      parseInt(data.Del) === 0 ? false : true,
      data.OtherAccess
      )
      
  })

    },[])

    const validation = () =>{

      if(!Selected_Month)
      {
        setError_message({Month: 'must be selected', Project: '', Year:''})
        return false
      }
      else if (!Selected_Year)
      {
        setError_message({Month: '',Project:'', Year: 'must be selected'})
        return false
      }
      else if (!SelectedProject)
      {
        setError_message({Month: '', Year:'', Project: 'must be selected'})
        return false
      }

      setError_message({Month: '', Project: '', Year:''})
      return true
    }

    const column_SI = [
        {
         name: "SaleInvoiceNo",
         label: "Invoice No",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
        {
         name: "CustomerName",
         label: "Customer Name",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
         name: "Invoice_Date",
         label: "Invoice Date",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },
         }
        },
         {
          name: "action",
          label: "action",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) => {
             
             var SaleInvoiceID = SI_Data
             if (SaleInvoiceID[rowIndex.rowIndex] != null)
               return (
                 <Link to={"/SaleReturnEntry"} onClick={e => edit_SI(SI_Data[rowIndex.rowIndex].SaleInvoiceID)}> <Edit /></Link>
               );
           }
          }
         }
    ];
    const edit_SI = (SaleInvoiceID) =>{
       set_SI(Selected_Month,SaleInvoiceID,false, SelectedProject.Project_ID)
    }
    const add_SI = () =>{
      if(validation())
      {
        set_SI(Selected_Month,0,true, SelectedProject.Project_ID)
        document.getElementById('btn_navigate').click()
      }
     
    }
  const Month_List = [
      {title:"JANUARY",value:1},
      {title:"FEBURARY",value:2},
      {title:"MARCH",value:3},
      {title:"APRIL",value:4},
      {title:"MAY",value:5},
      {title:"JUNE",value:6},
      {title:"JULY",value:7},
      {title:"AUGUST",value:8},
      {title:"SEPTEMBER",value:9},
      {title:"OCTOBER",value:10},
      {title:"NOVEMBER",value:11},
      {title:"DECEMBER",value:12},
  ]
   const Select_SaleInvoiceList = (Month, Project_Id,Year) =>{
    showLoader()
    SelectSaleInvoiceList(Comp_Id,Project_Id,'SR',Month,Year,'SaleReturn',(mData)=>{
      hideLoader()
        setSI_Data(mData)
    })
   }
   const showLoader = () => {
    setIsLoader(true)
   
  }
const  hideLoader = () => {
  setIsLoader(false)
  }
   const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });
    return ReactDOM.createPortal(
      <>
        <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2> Sale Return 
                    </h2>
                </div>
            </div>
        </div>
      <Card>
         
          <Row className="row_margin_top row_margin_left row_margin_right m-4" >
          <Col lg={1} xl={1} md={1} >
          <Autocomplete
              name="Year"
              id="Year"
              options={_Years}
              getOptionLabel={(option) => option.title ? option.title : ""}
              value={Selected_Year}
              onChange={(e, value) => {
                  if (value)
                  {
                    debugger
                      setSelected_Year(value)
                      if(SelectedProject)
                      Select_SaleInvoiceList(Selected_Month.value, SelectedProject.Project_ID,value.value)
                  }
                  else
                  {
                    setSelected_Year(null)
                      setSI_Data([])
                  }
              }}
              renderInput={(params) =>
                  <TextField {...params} name="Year" label="Year"
                  />}
          />
          <span style={{color: 'red'}} > {error_message.Year} </span>
      </Col>
                    <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="Month"
                            id="Month"
                            options={Month_List}
                            getOptionLabel={(option) => option.title ? option.title : ""}
                            value={Selected_Month}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    setSelected_Month(value)

                                    if(SelectedProject)
                                      Select_SaleInvoiceList(value.value, SelectedProject.Project_ID)
                                }
                                else
                                {
                                    setSelected_Month(null)
                                    setSI_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Month" label="Select Month"
                                />}
                        />
                        <span style={{color: 'red'}} >{error_message.Month}</span>
                    </Col>

                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="Project"
                            id="Project"
                            options={ProjectList}
                            getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                            value={SelectedProject}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    setSelectedProject(value)

                                    if(Selected_Month)
                                    Select_SaleInvoiceList(Selected_Month.value,value.Project_ID)
                                }
                                else
                                {
                                  setSelectedProject(null)
                                    setSI_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Project" label="Select Branch"
                                />}
                        />
                        <span style={{color: 'red'}} >{error_message.Project}</span>
                    </Col>

            <Col lg={5} xl={5} md={5} style={{ textAlign: "right" }} >
              <Link to={"/SaleReturnEntry"} id='btn_navigate' ></Link>
              { btn_add &&(<Link type="button" className="btn btn-primary rounded-pill" to="#" onClick={add_SI} style={{ border: "none" }} > <Add />  Create New</Link>)}
            </Col>
          </Row>
          <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col>              
                <div className="table-responsive" >
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        data={SI_Data}
                        columns={column_SI}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                    
                    </MuiThemeProvider>
                </div>
              </Col>
          </Row>
          <Loader
        show={IsLoader}
        hide={hideLoader}
        />
      </Card>
                        
      </>  , document.getElementById('PageContents')
    )
}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  SALE_INVOICE: state.SI && state.SI,
  Group_Id : state.Login_User.User_Data.Group_Id,
  p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,

})
export default connect(mapStateToProps,{set_SI, SHOW_SCREEN})(SaleReturnList)
