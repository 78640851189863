import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit, Print } from '@material-ui/icons'
import { connect } from 'react-redux'
import { SelectAutoMail } from '../../Services/AutoMailAPI';

import Loader from '../Loader';


// const AutoMailerList = forwardRef(({Comp_Id, ProjectID, sendIdToParent}, ref)=>{
//   useImperativeHandle(ref, () => ({
//     SelectMailData: SelectMailData,
//   }));
//     const [MailData, setMailData] = useState([])
//     const [IsLoader, setIsLoader] = useState(false)
//     useEffect(()=>{
//       SelectMailData()
//     },[])
//     const SelectMailData = () =>{
//       showLoader()
//       SelectAutoMail(Comp_Id, ProjectID, (mData)=>{
//         hideLoader()
//             setMailData(mData)
//       })
//     }
//     const column_Mail = [
//         {
//           name: "MailDataID",
//           label: "Mail ID",
//           options: {
//             filter: true,
//             sort: true,
//             customHeadLabelRender: (columnMeta) => {
//               return (
//                 <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
//               )
//             },
    
//           }
//         },
//         {
//           name: "ManufacturedBy",
//           label: "Manufacturer",
//           options: {
//             filter: true,
//             sort: true,
//             customHeadLabelRender: (columnMeta) => {
//               return (
//                 <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
//               )
//             },
//           }
//         },
//         {
//           name: "CGName",
//           label: "Company Group",
//           options: {
//             filter: true,
//             sort: true,
//             customHeadLabelRender: (columnMeta) => {
//               return (
//                 <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
//               )
//             },
//           }
//         },
//         {
//           name: "PGName",
//           label: "Product Group",
//           options: {
//             filter: true,
//             sort: true,
//             customHeadLabelRender: (columnMeta) => {
//               return (
//                 <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
//               )
//             },
//           }
//         },
//         {
//           name: "email",
//           label: "Email",
//           options: {
//             filter: true,
//             sort: true,
//             customHeadLabelRender: (columnMeta) => {
//               return (
//                 <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
//               )
//             },
//           }
//         },
//         {
//           name: "action",
//           label: "action",
//           options: {
//             filter: true,
//             sort: true,
//             customHeadLabelRender: (columnMeta) => {
//               return (
//                 <div style={{ fontSize: "15px", fontWeight: "Bold", textAlign: "right" }} >{columnMeta.label}</div>
//               )
//             },
//             customBodyRender: (dataIndex, rowIndex) => {
    
//               var MailDataID = MailData
//               if (MailDataID[rowIndex.rowIndex] != null)
//                 return (
//                   <Link to={"/AutoMailer"} onClick={e => {sendIdToParent(MailDataID[rowIndex.rowIndex].MailDataID)}}> <Edit /></Link>
//                 );
//             }
//           }
//         }
//       ];
//       const theme = createTheme({
//         overrides: {
//           MuiTableCell: {
//             root: {
//               paddingTop: 0,
//               paddingBottom: 0,
//             }
//           }
//         }
//       });
//       const showLoader = () => {
//         setIsLoader(true)
//        }
//       const  hideLoader = () => {
//          setIsLoader(false)
//        }
     
//   return (
//     <div className="table-responsive" >
//     <MuiThemeProvider theme={theme}>
//       <MUIDataTable
//         data={MailData}
//         columns={column_Mail}
//         options={{
//           selectableRows: 'none',
//           print: false,
//           search: true,
//           download: false,
//           viewColumns: false,
//           filter: false,
//         }}
//       />
//         </MuiThemeProvider>
//         <Loader
//         show={IsLoader}
//         hide={hideLoader}
//         />
//     </div>
//   )
// })

const AutoMailerList = ({Comp_Id, ProjectID, sendIdToParent, RefreshedChild}) => {
    const [MailData, setMailData] = useState([])
    const [IsLoader, setIsLoader] = useState(false)
    useEffect(()=>{
      SelectMailData()
    },[RefreshedChild])
    const SelectMailData = () =>{
      showLoader()
      SelectAutoMail(Comp_Id, ProjectID, (mData)=>{
        hideLoader()
            setMailData(mData)
      })
    }
    const column_Mail = [
        {
          name: "MailDataID",
          label: "Mail ID",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
    
          }
        },
        {
          name: "ManufacturedBy",
          label: "Manufacturer",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
          name: "CGName",
          label: "Company Group",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
          name: "PGName",
          label: "Product Group",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
          name: "email",
          label: "Email",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
          name: "action",
          label: "action",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold", textAlign: "right" }} >{columnMeta.label}</div>
              )
            },
            customBodyRender: (dataIndex, rowIndex) => {
    
              var MailDataID = MailData
              if (MailDataID[rowIndex.rowIndex] != null)
                return (
                  <Link to={"/AutoMailer"} onClick={e => {sendIdToParent(MailDataID[rowIndex.rowIndex].MailDataID)}}> <Edit /></Link>
                );
            }
          }
        }
      ];
      const theme = createTheme({
        overrides: {
          MuiTableCell: {
            root: {
              paddingTop: 0,
              paddingBottom: 0,
            }
          }
        }
      });
      const showLoader = () => {
        setIsLoader(true)
       }
      const  hideLoader = () => {
         setIsLoader(false)
       }
  return (
    <div className="table-responsive" >
    <MuiThemeProvider theme={theme}>
      <MUIDataTable
        data={MailData}
        columns={column_Mail}
        options={{
          selectableRows: 'none',
          print: false,
          search: true,
          download: false,
          viewColumns: false,
          filter: false,
        }}
      />
        </MuiThemeProvider>
        <Loader
        show={IsLoader}
        hide={hideLoader}
        />
    </div>
  )
}
const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

})
export default connect(mapStateToProps)(AutoMailerList)
// export default AutoMailerList