import React, { useEffect, useState } from 'react'
import { Modal, Card, Row, Col } from "react-bootstrap"
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Loop, Cancel, CloudDownload } from "@material-ui/icons"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Autocomplete } from '@material-ui/lab'
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { SelectManufacturedByList } from '../../../Services/manufactureAPI'
import { SelectUserProjects } from '../../../Services/Projects'
import { GetCurrentDate, GetFirstDateofCurrentMonth } from '../../../Services/Common'
import { SelectSupplierList } from '../../../Services/SuppliersAPI'
import { FinYear } from '../../../Services/FinYear'
import { SelectItemsListWRTProject } from '../../../Services/ItemsAPI'
import { SelectCompanyGroupAgainstManufacturer } from '../../../Services/CompanyGroupAPI'
import { rptCompanyLedger } from '../../../Services/Purchase_ReportAPI'
import { GetUserCG, GetUserManufacturer } from '../../../Services/ClaimAPI'
const ExcelJS = require("exceljs");

function PurchaseReport_Modal({show, onHide,Comp_Id,Comp_Name,User_Id,toPath,readonlyProps,title,UserType }) {
    const [Project_List, setProject_List] = useState([])
    var [SelectedProject, setSelectedProject] = useState(null)
    const [FromDate, setFromDate] = useState(GetFirstDateofCurrentMonth())
    const [ToDate, setToDate] = useState(GetCurrentDate())
    const [Supplier_List, setSupplier_List] = useState([])
    const [SelectedSupplier, setSelectedSupplier] = useState(null)
    const [Manufacturer_List, setManufacturer_List] = useState([])
    const [SelectedManufacturer, setSelectedManufacturer] = useState(null)
    const [filter, setfilter] = useState(null)
    const [FinYearData, setFinYearData] = useState([])
    const [SelectedFinData, setSelectedFinData] = useState(null)
    const [ReportPath, setReportPath] = useState('#')
    const [ItemList, setItemList] = useState([])
    const [SelectedFromItem, setSelectedFromItem] = useState(null)
    const [SelectedToItem, setSelectedToItem] = useState(null)
    const [CG_Data, setCG_Data] = useState([])
    const [Selected_CG_Data, setSelected_CG_Data] = useState(null)
    var [mTarget, setMTarget] = useState('');
    const [Error_State, setError_State] = useState({
        project_Err : '',
        finYear_Err:'',
        supplier_Err:'',
        fromItem_Err:'',
        toItem_Err : '',
    })
    const [Supplier, setSupplier] = useState(null)
    const [CompanyLedger, setCompanyLedger] = useState([])
    const [sumDebit, setSumDebit] = useState(0)
    const [sumDebitOP, setSumDebitOP] = useState(0)
    const [sumCredit, setSumCredit] = useState(0)
    const [sumCreditOP, setSumCreditOP] = useState(0)
    const [sumBalance, setSumBalance] = useState(0)
    const [sumBalanceOP, setSumBalanceOP] = useState(0)
    useEffect(() => {
        if(show && toPath){
            SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
                setSelectedProject(mProjects[0])
                Select_Item(mProjects[0].Project_ID)
                Select_Supplier(mProjects[0].Project_ID)
                SelectManufacturedBy_List(mProjects[0].Project_ID)
                setProject_List(mProjects)
            })
            FinYear(Comp_Id,User_Id,(mData)=>{
                setSelectedFinData(mData[0])
                setFinYearData(mData)
            })
        }
    }, [show])
    const handleFilterChange = event => {
        if (event) {
            setfilter(event.filter);
        }
    };
    const Fin_Columns = [
        {
            field: "fin_year",
            header: "fin_year",
            width: "150px",
        },
        {
            field: "StartYear",
            header: "StartYear",
            width: "150px",
        },
        {
            field: "EndYear",
            header: "EndYear",
            width: "150px",
        },
    ];
    const Select_Item = (Project_ID)=>{
        SelectItemsListWRTProject(Comp_Id,Project_ID,(mItems)=>{
            setItemList(mItems)
        })
    }
    const Select_Supplier = (Project_ID)=>{
        SelectSupplierList(Comp_Id,Project_ID,(mCustomer)=>{
            setSupplier_List(mCustomer)
        })
    }
    const SelectManufacturedBy_List = (Project_ID) =>{
        SelectManufacturedByList(Comp_Id,Project_ID,async(mManufacturer)=>{
            if(UserType === 'Manger Portal'){
                const res = await GetUserManufacturer(User_Id)
                const UserManufacturer = res.data
                const DisplayUserManufacturer = UserManufacturer.map(val => {
                    return mManufacturer.find(x => x.ManufacturedByID === val.ManufacturedByID);
                  }).filter(obj => obj !== undefined);
                  setManufacturer_List(DisplayUserManufacturer)
            }else{
                setManufacturer_List(mManufacturer)
            }
            
        })
    }
    const NavigateToReport = ()=>{
        if(toPath == '/SupplierLedgerReport'){
            if(SelectedProject && SelectedFinData && SelectedManufacturer && Selected_CG_Data){ 
              
                localStorage.setItem('token', sessionStorage.getItem('token'))
                localStorage.setItem('CGName', Selected_CG_Data.CGName)
                window.open(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}/${SelectedFinData.fin_year}/${SelectedManufacturer.ManufacturedByID}/${Selected_CG_Data.CGID}`,'_blank')
                
            }
            else{
               if(!SelectedFinData){
                setError_State({...Error_State,finYear_Err:'required'})
               }
               else if(!SelectedProject){
                setError_State({...Error_State,finYear_Err:'',project_Err:'required'})
               }
               else if(!SelectedSupplier){
                setError_State({...Error_State,finYear_Err:'',project_Err:'',supplier_Err:'required'})
               }
            }
        }
        else if(toPath == '/SupplierWiseLedgerReport'){
            localStorage.setItem('token', sessionStorage.getItem('token'))
            if(SelectedProject){
                window.open(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}`,'_blank')
            }
            else{
                if(!SelectedProject){
                    setError_State({...Error_State,project_Err:'required'})
                   }
            }
        }
        else if(toPath == '/PurchaseSummaryItemWiseReport')
        {
            if (SelectedProject && SelectedFromItem && SelectedToItem){
                localStorage.setItem('token', sessionStorage.getItem('token'))
                
                window.open(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}/${SelectedFromItem.ItemID}/${SelectedToItem.ItemID}`,'_blank')
            }
            else{
                if(!SelectedProject){
                    setError_State({...Error_State,project_Err:'required'})
                   }
                   else if(!SelectedFromItem){
                    setError_State({...Error_State,project_Err:'',fromItem_Err:'required'})
                   }
                   else if(!SelectedToItem){
                    setError_State({...Error_State,project_Err:'',fromItem_Err:'',toItem_Err:'required'})
                   }
            }
        }
        else if (toPath == '/Payables'){
            if(SelectedProject){
                localStorage.setItem('token', sessionStorage.getItem('token'))
              
                window.open(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}`,'_blank' )
            }
            else{
                setError_State({...Error_State,project_Err:'required'})
            }
        }
    }
    const ResetCotrols = () =>{
        setProject_List([])
        setSelectedProject(null)
        setFromDate(GetCurrentDate())
        setToDate(GetCurrentDate())
        setSupplier_List([])
        setSelectedSupplier(null)
        setfilter(null)
        setFinYearData([])
        setSelectedFinData(null)
        setReportPath('#')
        setItemList([])
        setSelectedFromItem(null)
        setSelectedToItem(null)
        setError_State({
            project_Err : '',
            finYear_Err:'',
            supplier_Err:'',
            fromItem_Err:'',
            toItem_Err : '',
        })
    }
    const ExportReport= () =>{
        if(toPath == '/SupplierLedgerReport'){
            if(SelectedProject && SelectedFinData && SelectedManufacturer && Selected_CG_Data){ 
                SelectManufacturedByList(Comp_Id,SelectedProject.Project_ID,(mSupplierData)=>{
                    mSupplierData.map(value=>{
                        if(value.ManufacturedByID == SelectedManufacturer.ManufacturedByID){
                            setSupplier(value)
                        }
                    })
                })
                rptCompanyLedger(Comp_Id,SelectedProject.Project_ID,FromDate, ToDate,SelectedManufacturer.ManufacturedByID,Selected_CG_Data.CGID,(mData)=>{
                    var mArray = mData.splice(1)
                    var PBalnce = mArray.length ? mArray[0].debit - mArray[0].credit : 0
                    var sumofDebitWithOP = 0
                    var sumofDebitWithoutOP = 0
                    var sumofCreditWithOP = 0
                    var sumofCreditWithoutOP = 0
                    var sumofBalanceWithOP = 0
                    var sumofBalanceWithoutOP = 0
                    for(let i =0; i<mArray.length; i++){
                        if(i === 0){
                            mArray[i].balance=  (mArray[i].balance + mArray[i].debit) - mArray[i].credit
                        }else{
                            mArray[i].balance= (PBalnce+ mArray[i].debit) - mArray[i].credit 
                        }
                        PBalnce = mArray[i].balance
                        sumofDebitWithoutOP+=   mArray[i].debit    
                        sumofCreditWithoutOP += mArray[i].credit
                        sumofBalanceWithoutOP += mArray[i].balance
                    }
                    setSumDebit(sumofDebitWithoutOP)
                    setSumCredit(sumofCreditWithoutOP)
                    setSumBalance(sumofBalanceWithoutOP)
                    mArray.unshift(mData)
                    for(let i=0; i<mArray.length; i++){
                        sumofDebitWithOP +=  mArray[i].debit ? mArray[i].debit : 0  
                        sumofCreditWithOP += mArray[i].credit ? mArray[i].credit : 0
                        sumofBalanceWithOP += mArray[i].balance ? mArray[i].balance : 0
                       } 
                       setSumDebitOP(sumofDebitWithOP)
                       setSumCreditOP(sumofCreditWithOP)
                       setSumBalanceOP(sumofBalanceWithOP)
                       setCompanyLedger(mArray)

                       const workbook = new ExcelJS.Workbook()
                       const sheet = workbook.addWorksheet("Company Ledger")
                       sheet.columns = [
                    
                        {
                          header: "Invoice Date",
                          key: "InvoiceDate",
                          width: 15,
                        },
                        { header: "Transaction No", key: "TransactionNo", width: 20 },
                        {
                          header: "Discription",
                          key: "Discription",
                          width: 30,
                        },
                        {
                            header: "Debit",
                            key: "debit",
                            width: 20,
                          },
                        {
                          header: "Credit",
                          key: "credit",
                          width: 20,
                        },
                        {
                          header: "Balance",
                          key: "balance",
                          width: 40,
                        }
                       
                      ];
                      sheet.spliceRows(1, 0, [ Comp_Name]);
                      sheet.spliceRows(2, 0, [ Comp_Name]);
                      sheet.spliceRows(3, 0, [ "Company Ledger"]);
                      sheet.spliceRows(4, 0, [ "Project:   " + Comp_Name,null, null, "Company :  " + SelectedManufacturer.ManufacturedBy]);
                      sheet.spliceRows(5, 0, [ "From Date:   " + FromDate,null, null, "To Date :  " + ToDate]);
                      sheet.getRow(1).font = {
                        bold: true,
                        size: 34,
                      }
                      sheet.getRow(6).font = {
                        bold: true,
                        size: 14,
                        
                      }
                      const row = sheet.getRow(6);
                        row.height = 30
                           for (let i = 1; i <= 6; i++) {
                                       const cell = row.getCell(i);
                                       cell.border = {
                                       top: { style: "thick" },
                                       bottom: { style: "thick" },
                                      };
                                      cell.alignment = { horizontal: 'center'};
                                      cell.wrapText = true;
                                  }
                                                 // Merg Cell and Style
                  sheet.mergeCells('A1', 'F2');
                  sheet.getCell('A1').value = Comp_Name
                  const cell = sheet.getCell('A1');
                  cell.alignment = { horizontal: 'center' };
                  sheet.mergeCells('A3', 'F3');
                  const cell2 = sheet.getCell('A3');
                  cell2.alignment = { horizontal: 'center' };
                  cell2.font = { size: 16, bold: true };
                  sheet.mergeCells('A4', 'C4');
                 const cell3 = sheet.getCell('A4');
                 cell3.alignment = { horizontal: 'center' };
                 cell3.font = { size: 14, bold: true };
                 sheet.mergeCells('D4', 'F4');
                 const cell4 = sheet.getCell('D4')
                 cell4.alignment = { horizontal: 'center' };
                 cell4.font = { size: 14, bold: true };
                 sheet.mergeCells('A5', 'C5');
                 const cell5 = sheet.getCell('A5');
                 cell5.alignment = { horizontal: 'center' };
                 cell5.font = { size: 14, bold: true };
                 // To Date 
                 sheet.mergeCells('D5', 'F5');
                 const cell6 = sheet.getCell('D5');
                 cell6.alignment = { horizontal: 'center' };
                 cell6.font = { size: 14, bold: true };

                 mArray.map((item,index)=>{
                    let row = sheet.addRow({
                        InvoiceDate: item.PurchaseDate  ? item.PurchaseDate : '-',
                        TransactionNo: item.PurchaseInvoiceNo ? item.PurchaseInvoiceNo : '-',
                        Discription: item.Discription ? item.Discription : '-',
                        debit: item.debit ? item.debit : '0',
                        credit: item.credit ? item.credit : '0',
                        balance: item.balance ? parseFloat(item.balance).toFixed(2) > 0 ? item.balance? parseFloat(item.balance).toFixed(2) + " DR" : 0 : item.balance? parseFloat(item.balance).toFixed(2) + " CR" : 0 : '0'
                        
                    });
                    row.eachCell((cell) => {
                        cell.alignment = { horizontal: 'center' };
                    });
                });
                let row1 =   sheet.addRow([null, null,'Total Without Opening',sumofDebitWithoutOP,sumofCreditWithoutOP, parseFloat(sumofDebitWithoutOP - sumofCreditWithoutOP).toFixed(2)]);
                let row2 =  sheet.addRow([null, null,'Total With Opening',sumofDebitWithOP,sumofCreditWithOP,  parseFloat(sumofDebitWithOP - sumofCreditWithOP).toFixed(2)]);
                row1.eachCell((cell)=>{
                    cell.border = {
                        top: { style: "thick" },
                        bottom: { style: "thick" },
                       };
                       cell.alignment = { horizontal: 'center'};
                       cell.wrapText = true;
                       cell.font = { size: 14, bold: true };
                  })
                  row2.eachCell((cell)=>{
                    cell.border = {
                        top: { style: "thick" },
                        bottom: { style: "thick" },
                       };
                       cell.alignment = { horizontal: 'center'};
                       cell.wrapText = true;
                       cell.font = { size: 14, bold: true };
                  })
                  workbook.xlsx.writeBuffer().then(function (mData) {
                    const blob = new Blob([mData], {
                      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                    const url = window.URL.createObjectURL(blob);
                    const anchor = document.createElement("a");
                    anchor.href = url;
                    anchor.download = "CompanyLedger.xlsx";
                    anchor.click();
                    window.URL.revokeObjectURL(url);
                  });
            })

            } else{
               if(!SelectedFinData){
                setError_State({...Error_State,finYear_Err:'required'})
               }
               else if(!SelectedProject){
                setError_State({...Error_State,finYear_Err:'',project_Err:'required'})
               }
               else if(!SelectedSupplier){
                setError_State({...Error_State,finYear_Err:'',project_Err:'',supplier_Err:'required'})
               }
            }
        }
    }
    return (
        <>
        <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" id="Purchase_Modal" >
            <Modal.Header closeButton className="header_title" >
            <Modal.Title> Purchase Reports / {title} </Modal.Title>
            </Modal.Header>
                <Modal.Body id='mBody'>
                    <Card>
                    <Row className="row_margin_left row_margin_right" >
                             <Col sm={12} lg={4} xl={4} md={4} >
                             <TextField 
                                name="FromDate" 
                                label="From Date"
                                type='date'
                                value={FromDate}
                                onChange={e=>setFromDate(e.target.value)}
                                fullWidth
                                inputProps={{
                                    readOnly: readonlyProps.FromDate
                                }
                                }
                             />
                             </Col>
                             <Col sm={12} lg={4} xl={4} md={4} >
                             <TextField 
                                name="ToDate" 
                                label="To Date"
                                type='date'
                                value={ToDate}
                                onChange={e=>setToDate(e.target.value)}
                                fullWidth
                                inputProps={{
                                    readOnly: readonlyProps.ToDate
                                }
                                }
                             />
                             </Col>
                                <Col xl={4} lg={4} md={4} sm={12}>
                                    {/* <MultiColumnComboBox
                                        data={filter ? filterBy(FinYearData, filter) : FinYearData}
                                        value={SelectedFinData}
                                        style={{ fontSize: "12px"}}
                                        disabled={readonlyProps.finYear}
                                        columns={Fin_Columns}
                                        textField={"fin_year"}
                                        filterable={true}
                                        label='Select Fin Year'
                                        onFilterChange={handleFilterChange}
                                        onChange={e => {

                                            if (e.value) {
                                              setSelectedFinData(e.value)
                                            }
                                            else {
                                                setSelectedFinData(null)
                                            }
                                        }}
                                        fullWidth
                                    /> */}
                                    <Autocomplete
                                    name="FinYear"
                                    id="FinYear"
                                    options={FinYearData}
                                    getOptionLabel={(option) => option.fin_year ? option.fin_year : ""}
                                    value={SelectedFinData}
                                    disabled={readonlyProps.finYear}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedFinData(value)
                                        }
                                        else{
                                            setSelectedFinData(null)
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="FinYear" label="Fin Year"
                                        />
                                    }
                                />
                                 <span className="text-danger">{Error_State.finYear_Err}</span>
                                </Col>
                        </Row>
                        <Row className=" row_margin_left row_margin_right" >
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="Project"
                                    id="Project"
                                    options={Project_List}
                                    getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                                    value={SelectedProject}
                                    disabled={readonlyProps.Project}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedProject(value)
                                            Select_Item(value.Project_ID)
                                            Select_Supplier(value.Project_ID)
                                            SelectManufacturedBy_List(value.Project_ID)

                                        }
                                        else{
                                            setSelectedProject(null)
                                            setItemList([])
                                            setSupplier_List([])
                                            setSelectedSupplier(null)
                                            setSelectedManufacturer(null)
                                            setSelectedFromItem(null)
                                            setSelectedToItem(null)
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="Project" label="Branch"
                                        />
                                    }
                                />
                                 <span className="text-danger">{Error_State.project_Err}</span>
                            </Col>
                            <Col sm={12} lg={4} xl={4} md={4} >
                          {toPath !== '/SupplierLedgerReport'  ?    
                          <Autocomplete
                                    name="Supplier"
                                    id="Supplier"
                                    options={Supplier_List}
                                    getOptionLabel={(option) => option.SupplierName ? option.SupplierName : ""}
                                    value={SelectedSupplier}
                                    disabled={readonlyProps.Supplier}
                                    onChange={(e, value) => {
                                        if (value) {
                                       
                                            setSelectedSupplier(value)
                                        }
                                        else
                                            setSelectedSupplier(null)

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="Supplier" label="Supplier"
                                        />
                                    }
                                />
                                : 
                                <Autocomplete
                                name="ManufacturedBy"
                                id="ManufacturedBy"
                                options={Manufacturer_List}
                                getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""}
                                value={SelectedManufacturer}
                                // disabled={readonlyProps.Supplier}
                                onChange={(e, value) => {
                                    if (value) {
                                        setSelectedManufacturer(value)
                                        setCG_Data([])
                                        setSelected_CG_Data(null)
                                        SelectCompanyGroupAgainstManufacturer(Comp_Id, SelectedProject.Project_ID, value.ManufacturedByID, async(CGData) => {
                                          
                                           if(UserType === 'Manger Portal'){
                                            const res = await GetUserCG(User_Id)
                                            const UserAssignCG = res.data
                                            const DisplayUserCG = UserAssignCG.map(val => {
                                                return CGData.find(x => x.CGID === val.CGID);
                                              }).filter(obj => obj !== undefined);
                                              setCG_Data(DisplayUserCG)
                                        }else{
                                            setCG_Data(CGData)
                                        }
                                           
                                        })
                                    }
                                    else{
                                        setSelectedManufacturer(null)
                                        setCG_Data([])
                                        setSelected_CG_Data(null)
                                    }
                                      

                                }}
                                renderInput={(params) =>
                                    <TextField {...params} name="ManufacturedBy" label="ManufacturedBy"
                                    />
                                }
                            />
                            }
                                 <span className="text-danger">{Error_State.supplier_Err}</span>
                              
                            </Col>
                            <Col sm={12} lg={4} xl={4} md={4} >
                          {toPath === '/SupplierLedgerReport'  ?    
                          <Autocomplete
                                    name="CG"
                                    id="CG"
                                    options={CG_Data}
                                    getOptionLabel={(option) => option.CGName ? option.CGName : ""}
                                    value={Selected_CG_Data}
                                    disabled={readonlyProps.CGID}
                                    onChange={(e, value) => {

                                        if (value) {
                                            setSelected_CG_Data(value)
                                        }
                                        else {
                                            setSelected_CG_Data(null)
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="CG" id="CG" label="Company Gruop"
                                        />}

                                />
                                : 
                               ''
                            }
                                 <span className="text-danger">{Error_State.supplier_Err}</span>
                              
                            </Col>
                        </Row>
                        <Row className=" row_margin_left row_margin_right" >
                        <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="FromItem"
                                    id="FromItem"
                                    options={ItemList}
                                    getOptionLabel={(option) => option.ItemName ? option.ItemName : ""}
                                    value={SelectedFromItem}
                                    disabled={readonlyProps.FromItem}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedFromItem(value)
                                        }
                                        else
                                            setSelectedFromItem(null)

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="FromItem" label="FromItem"
                                        />
                                    }
                                />
                                 <span className="text-danger">{Error_State.fromItem_Err}</span>
                            </Col> 
                        <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="ToItem"
                                    id="ToItem"
                                    options={ItemList}
                                    getOptionLabel={(option) => option.ItemName ? option.ItemName : ""}
                                    value={SelectedToItem}
                                    disabled={readonlyProps.ToItem}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedToItem(value)
                                        }
                                        else
                                            setSelectedToItem(null)

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="ToItem" label="ToItem"
                                        />
                                    }
                                />
                                 <span className="text-danger">{Error_State.toItem_Err}</span>
                            </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                <button className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={NavigateToReport} > <Loop /> <u>Load</u></button>
                                <button className="btn btn-primary rounded-pill"  style={{ border: "none" }}  disabled={readonlyProps.Export} onClick= {ExportReport} > <CloudDownload /> <u>Export To Excel</u></button>
                                <button type="button" className="btn btn-primary rounded-pill" onClick={e=>{
                                    ResetCotrols()
                                    onHide()
                                }} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
        </Modal>
        </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables.Company && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables.Company &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    UserType: state.Login_User.User_Data && state.Login_User.User_Data.User_Type
})
export default connect(mapStateToProps)(PurchaseReport_Modal)
