import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectStockAdjustmentList =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/StockAdjustment/SelectStockAdjustmentList/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const GetMaxStockAdjustmentNo =(Comp_Id,ProjectID,Callback) =>{
    axios.get(`${domain}/StockAdjustment/GetMaxStockAdjustmentNo/${Comp_Id}/${ProjectID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
export const SelectStockadjustmentMaster =(Comp_Id,ProjectID,StockAdjustmentID,Callback) =>{
  axios.get(`${domain}/StockAdjustment/SelectStockadjustmentMaster/${Comp_Id}/${ProjectID}/${StockAdjustmentID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectStockAdjustmentDetail =(Comp_Id,ProjectID,StockAdjustmentID,Callback) =>{
    axios.get(`${domain}/StockAdjustment/SelectStockAdjustmentDetail/${Comp_Id}/${ProjectID}/${StockAdjustmentID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
export const SaveStockAdjustment = (StockAdjustmentMasterData,StockAdjustmentDetailData,isAddNew,callback) =>{
  axios.post(`${domain}/StockAdjustment/SaveStockAdjustment`,{
    StockAdjustmentMasterData,
    StockAdjustmentDetailData,
     isAddNew
  })
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback()
      })
      .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
}

