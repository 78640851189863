import axios from 'axios'
import { domain } from "./Common"
import { toast } from 'react-toastify'
import Swal from "sweetalert2"
import 'react-toastify/dist/ReactToastify.css';

export const SelectGroupScreenAccess = (GroupID, Mod_Id, ScreenID, callback) =>{

    axios.get(`${domain}/UserSecurity/SelectGroupScreenAccess/${GroupID}/${Mod_Id}/${ScreenID}`)
         .then(response =>{
             callback(response.data)
         })
         .catch(err => console.error(err))
}
export const SelectSecurityGroupsList = (callback) =>{
    axios.get(`${domain}/UserSecurity/SelectSecurityGroupsList`)
         .then(response=>{
             callback(response.data.Data)
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
}
export const SelectSecurityGroupsListForManager = (callback) =>{
    axios.get(`${domain}/UserSecurity/SelectSecurityGroupsListForManager`)
         .then(response=>{
             callback(response.data.Data)
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
}
export const SelectGroupModuleList = (Group_Id,callback) =>{
    axios.get(`${domain}/UserSecurity/SelectGroupModulesList/${Group_Id}`)
         .then(response=>{
             callback(response.data.Data)
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
        }

export const SelectModulesList = (Callback) =>{
    axios.get(`${domain}/UserSecurity/SelectModulesList`)
        .then(response=>{
            Callback(response.data.Data)
        })
        .catch(err=> console.error(err))
}


export const SaveSecurityGroups = (Group_Id,Description,isAddNew,callback) =>{

    axios.post(`${domain}/UserSecurity/SaveSecurityGroups`,{
        Group_Id:Group_Id,
        Description:Description,
        IsAddNew:isAddNew
    })
         .then(response=>{
            toast.configure()
            toast.info(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            callback()
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
}

export const SelectGroup  = (Group_Id,callback)=>{
    axios.get(`${domain}/UserSecurity/SelectGroup/${Group_Id}`)
         .then(response=>{
             callback(response.data.Data)
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
}

export const SaveGroupModules = (GroupModuleAccessData,Callback) =>{
    axios.post(`${domain}/UserSecurity/SaveGroupModules`,{
        GroupModuleAccessData:GroupModuleAccessData
    })
      .then(response=>{
        toast.configure()
        toast.info(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        Callback()
      })
      .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectGroupScreensList = (Group_Id, Mod_Id, Callback)=>{
    axios.get(`${domain}/UserSecurity/SelectGroupScreensList/${Group_Id}/${Mod_Id}`)
         .then(response=>{
            Callback(response.data.Data)
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
}

export const SaveGroupModuleScreens = (GroupScreenAccessData,Callback)=>{
    axios.post(`${domain}/UserSecurity/SaveGroupModuleScreens`,{
        GroupScreenAccessData:GroupScreenAccessData
    })
         .then(response=>{
            toast.configure()
            toast.info(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            Callback()
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
}

export const SelectUsersList = (callback) =>{
    axios.get(`${domain}/UserSecurity/SelectUsersList`)
        .then(response=>{
            callback(response.data.Data)
        })
        .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
            })
        })
}
export const SelectSalesMan = (Comp_Id,callback) =>{
    axios.get(`${domain}/UserSecurity/SelectSalesMan/${Comp_Id}`)
        .then(response=>{
            callback(response.data.Data)
        })
        .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
            })
        })
}

export const SelectFinYearList = (Comp_Id,callback) =>{
    axios.get(`${domain}/UserSecurity/SelectFinYearList/${Comp_Id}`)
         .then(response=>{
             callback(response.data.Data)
         })
         .catch(err=>console.error(err))
}

export const SelectBranchesList = (callback)=>{
axios.get(`${domain}/UserSecurity/SelectBranchesList`)
     .then(response=>{
         callback(response.data.Data)
     })
     .catch(err=> console.error(err))
}

export const SaveUser = (UserData,UsersBranchData,DeletedUserBranchData,UserFinYearData,DeletedUserFinYearData,UserProjectData,DeletedUserProjectData,IsAddNew,Callback) =>{
   axios.post(`${domain}/UserSecurity/SaveUsers`,{
    UserData:UserData,
    UsersBranchData:UsersBranchData,
    DeletedUsersBranchData:DeletedUserBranchData,
    UserFinYearData:UserFinYearData,
    DeletedUserFinYearData:DeletedUserFinYearData,
    UserProjectData,DeletedUserProjectData,
    IsAddNew:IsAddNew
   })
        .then(response=>{
            toast.configure()
            toast.info(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            Callback()
        })
        .catch(err=> console.error(err))
}

export const SelectUser = (User_Id,callback) =>{
    axios.get(`${domain}/UserSecurity/SelectUser/${User_Id}`)
         .then(response=>{
             callback(response.data.Data)
         })
         .catch(err=> console.log(err))
}

export const SelectUserBranches = (User_Id,callback) =>{
    axios.get(`${domain}/UserSecurity/SelectUserBranches/${User_Id}`)
         .then(response=>{
             callback(response.data.Data)
         })
         .catch(err=> console.error(err))

}
export const SelectUserFinYear = (User_Id,callback) =>{
    axios.get(`${domain}/UserSecurity/SelectUserFinYear/${User_Id}`)
         .then(response=>{
             callback(response.data.Data)
         })
         .catch(err=> console.error(err))

}


export const UpdateUserProfile = (UserData, callback) => {

    axios.post(`${domain}/UserSecurity/UpdateUserProfile`, UserData)
         .then(response =>{
            toast.configure();
            toast.info(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                
            });

            callback()
         })
         .catch(err => console.error(err))
}


export const SelectBranchUsers = (Branch_Id,callback) =>{
    
    axios.get(`${domain}/UserSecurity/SelectBranchUsers/${Branch_Id}`)
         .then(response=>{
             callback(response.data.Data)
         })
         .catch(err=> console.error(err))

}
export const SelectProjectUser =(User_Id,Callback) =>{
    axios.get(`${domain}/UserSecurity/SelectProjectUsers/${User_Id}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }