import React, { useState, useEffect } from 'react'
import { Modal, Card, Row, Col, Button,Spinner  } from "react-bootstrap"
import { TextField } from "@material-ui/core"
import { Save, Cancel } from "@material-ui/icons"
import { connect } from "react-redux"
import { SelectBranch, SaveBranch } from '../../Services/BranchAPI'

function BranchModal({ show, onHide, Branch_Id, is_add_new, show_screen,}) {

    
   
    const [btn_edit, setBtn_edit] = useState(true)
    const [OtherAccess, setOtherAccess] = useState(null)
    const [Name, setName] = useState('')
    const [Abbr, setAbbr] = useState('')
    const [PhoneNo, setPhoneNo] = useState('')
    const [MobileNo, setMobileNo] = useState('')
    const [Address, setAddress] = useState('')
    const [ErrorName, setErrorName] = useState('')
    const [btn_Disabled, setBtn_Disabled] = useState(false)
    useEffect(() => {
       
        if(show){
            if(is_add_new === true)
            {
                setName('')
                setAbbr('')
                setPhoneNo('')
                setMobileNo('')
                setAddress('')
                setErrorName('')
                document.getElementById("Name").focus()
            }
            else if(is_add_new === false)
            {
                // setBtn_edit(show_screen.edit) 
                if(Branch_Id){
                    SelectBranch(Branch_Id,(data)=>{
                        setName(data[0].Name)
                        setAbbr(data[0].Abbr)
                        setPhoneNo(data[0].PhoneNo)
                        setMobileNo(data[0].MobileNo)
                        setAddress(data[0].Address)
                    })
                }
            }
        }
    }, [show,is_add_new, Branch_Id])

   
    const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }

   
    const save  = () =>{

        if(!Name.trim() == '')
        {
            var obj_save = {
                Branch_Id,
                Name,Abbr,Address,PhoneNo,MobileNo
            }
            setBtn_Disabled(true)
            SaveBranch(obj_save,  is_add_new,(message) =>{
                setBtn_Disabled(false)
                clearFields()
            })
        }
        else{
            setErrorName('required')
        }

    }



    const clearFields = () => {

        onHide()
        setName('')
        setAbbr('')
        setPhoneNo('')
        setMobileNo('')
        setAddress('')
        setErrorName('')
    }

    return (
        <>
            <Modal show={show} onHide={onHide} size="lg"   backdrop="static"  centered>
                <Modal.Header closeButton className="header_title " >
                    <Modal.Title > Branch</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col lg={6} xl={6} md={6} >
                                <TextField
                                    name="Name"
                                    id="Name"
                                    label="Name"
                                    value={Name}
                                    onInput={toInputUppercase}
                                    onChange={e=> setName(e.target.value)}
                                    fullWidth
                                />
                                <span style={{color: "red"}} >{ErrorName}</span>
                            </Col>
                            <Col lg={6} xl={6} md={6} >
                                <TextField
                                    name="Abbr"
                                    id="Abbr"
                                    label="Abbr"
                                    value={Abbr}
                                    onChange={e=> setAbbr(e.target.value)}
                                    onInput={toInputUppercase}
                                    fullWidth
                                />
                            </Col>
                            <Col lg={6} xl={6} md={6} >
                                <TextField
                                    name="PhoneNo"
                                    id="PhoneNo"
                                    label="PhoneNo"
                                    value={PhoneNo}
                                    onChange={e=> setPhoneNo(e.target.value)}
                                    fullWidth
                                />
                            </Col>
                            <Col lg={6} xl={6} md={6} >
                                <TextField
                                    name="MobileNo"
                                    id="MobileNo"
                                    label="MobileNo"
                                    value={MobileNo}
                                    onChange={e=> setMobileNo(e.target.value)}
                                    fullWidth
                                />
                            </Col>
                            <Col lg={12} xl={12} md={12} >
                                <TextField
                                    name="Address"
                                    id="Address"
                                    label="Address"
                                    value={Address}
                                    onInput={toInputUppercase}
                                    onChange={e=> setAddress(e.target.value)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                {btn_edit && (<Button variant="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={save}  disabled={btn_Disabled}>    
                                 {
                             btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                                }</Button>)}
                                <Button variant="btn btn-primary rounded-pill" onClick={clearFields} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></Button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    show_screen: state.SCREEN,    
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,

})

export default connect(mapStateToProps)(BranchModal)
