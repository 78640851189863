import React from 'react';
import './WelcomeComponent.css'; // Import your CSS file
import ReactDOM from 'react-dom'
import {connect} from 'react-redux'
const WelcomeComponent = ({Comp_Name}) => {
  return ReactDOM.createPortal (
    <div className="welcome-container">
      <div className="welcome-text">
        <h1>Welcome Reports Portal of {Comp_Name}</h1>
      </div>
    </div>, document.getElementById('PageContents')
  );
};
const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Comp_Name:state.GlobalVariables.Company && state.GlobalVariables.Company.Name,
  Comp_Address:state.GlobalVariables.Company &&  state.GlobalVariables.Company.Address,
  User_Id: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
  User_Info: state.Login_User && state.Login_User.User_Data,
  Group_Id: state.Login_User.User_Data && state.Login_User.User_Data.Group_Id,
  Global_Project_List: state.GlobalProjectsList && state.GlobalProjectsList.GlobalProjectsList,
  Global_Selected_Project: state.Global_Projects && state.Global_Projects.GlobalProjects,
  ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID
})
export default  connect(mapStateToProps)(WelcomeComponent)