import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Row, Col, Card } from "react-bootstrap";
import { Add, Edit,CloudDownload,DeleteForever } from "@material-ui/icons";
import { connect } from "react-redux";
import { TextField, Popper } from "@material-ui/core";
import Box from '@mui/material/Box';
import { Bounce } from "react-activity";
import { Autocomplete } from "@material-ui/lab";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { SelectcustomersList } from "../../Services/CustomersAPI";
import { SelectManufacturedByList } from "../../Services/manufactureAPI";
import { SelectItemsWRTManufacturer } from "../../Services/SchemeAPI";
import { SelectCompanyGroupAgainstManufacturer } from "../../Services/CompanyGroupAPI";
import { set_Scheme, SHOW_SCREEN } from "../../React_Redux/Actions";
import { SelectGroupScreenAccess } from "../../Services/SecurityAPI";
import { SelectUserProjects } from "../../Services/Projects";
import { SelectScheme,DeleteScheme } from "../../Services/SchemeAPI";
import Loader from "../Loader";
import { msg } from "../../Services/Common";
import { DataGrid } from '@mui/x-data-grid';
import Swal from "sweetalert2";

function Scheme(props) {
  const {
    set_Scheme,
    ItemCat,
    Comp_Id,
    Group_Id,
    p_userid,
    SHOW_SCREEN,
    Project,
  } = props;
  const [SchemeList_Data, setSchemeList_Data] = useState([]);
  const [ProjectList, setProjectList] = useState([]);
  const [SelectedProject, setSelectedProject] = useState(null);
  const [Customer_List, setCustomer_List] = useState([]);
  const [SelectedCustomer, setSelectedCustomer] = useState(null);
  const [btn_add, setBtn_add] = useState(true);
  const [btn_delete, setBtn_delete] = useState(true);
  const [IsLoader, setIsLoader] = useState(false);
  const [Manufacturer_List, setManufacturer_List] = useState([]);
  const [SelectedManufacturer, setSelectedManufacturer] = useState(null);
  const [CG_List, setCG_List] = useState([]);
  const [SelectedCG, setSelectedCG] = useState(null);
  const [Items_List, setItems_List] = useState([]);
  const [SelectedItems, setSelectedItems] = useState(null)
  const [DeleteData, setDeleteData] = useState([])
  const [isSpiner, setIsSpiner] = useState(false)
  useEffect(() => {
    document.title = " Distribution / Scheme";
    SelectUserProjects(Comp_Id, p_userid, (ProjectData) => {
      setProjectList(ProjectData);
      if (ProjectData.length > 0) {
        setSelectedProject(ProjectData[0]);
       // Select_SchemeList(ProjectData[0].Project_ID);
        SelectCustomer_List(ProjectData[0].Project_ID);
        SelectManufacturer_List(ProjectData[0].Project_ID);
      }
    });

    if (Project) {
      setSelectedProject(Project);
      //Select_SchemeList(Project.Project_ID);
      SelectCustomer_List(Project.Project_ID);
      SelectManufacturer_List(Project.Project_ID);
    }
    SelectGroupScreenAccess(Group_Id, 13, 139, (data) => {
      setBtn_add(parseInt(data.AddNew) === 0 ? false : true);
      setBtn_delete(parseInt(data.Del) === 0 ? false : true);

      props.SHOW_SCREEN(
        parseInt(data.AddNew) === 0 ? false : true,
        parseInt(data.Edit) === 0 ? false : true,
        parseInt(data.Del) === 0 ? false : true,
        data.OtherAccess
      );
    });
   
  }, []);
  const SelectCustomer_List = (ProjectID) => {
    SelectcustomersList(Comp_Id, ProjectID, (mCustomers) => {
      var mObj = {
        CustomerID: 0,
        CustomerName: "SELECT ALL",
        Comp_Id: Comp_Id,
        ProjectID: ProjectID,
      };
      mCustomers.splice(0, 0, mObj);
      setCustomer_List(mCustomers);
    });
  };
  const SelectManufacturer_List = (ProjectID) => {
    SelectManufacturedByList(Comp_Id, ProjectID, (mManufacturer) => {
      setManufacturer_List(mManufacturer);
    });
  };
  
  
  const edit_Scheme = (SchemeID) => {
    if (SelectedProject) {
      set_Scheme(SelectedProject, SchemeID, false);
      document.getElementById("_scheme").click();
    }
  };
  const add_Scheme = () => {
    if (SelectedProject) {
      set_Scheme(SelectedProject, 0, true);
      document.getElementById("_scheme").click();
    }
  };

  const Select_SchemeList = (Project_ID) => {
   showLoader()
    SelectScheme(Comp_Id, Project_ID,SelectedCustomer.CustomerID,SelectedManufacturer.ManufacturedByID,SelectedCG.CGID,SelectedItems.ItemID, (mData) => {
    hideLoader()
      setSchemeList_Data(mData);
   
    });
  };
  const showLoader = () => {
    setIsLoader(true);
  };
  const hideLoader = () => {
    setIsLoader(false);
  };
  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
  });
  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'ItemName',
      headerName: 'Item Name',
      width: 250,
      // editable: true,
    },
    {
      field: 'BonusQty',
      headerName: 'Bonus Qty',
      width: 100,
      // editable: true,
    },
    {
      field: 'BonusCriteria',
      headerName: 'Bonus Criteria',
      width: 80,
      // editable: true,
    },
    {
      field: 'DisPer',
      headerName: 'DisPer 1',
      type: 'number',
      width: 80,
      // editable: true,
    },
    {
      field: 'DisPer1',
      headerName: 'DisPer 2',
      type: 'number',
      width: 80,
      // editable: true,
    },
    {
      field: 'TaxPer',
      headerName: 'TaxPer',
      type: 'number',
      width: 80,
      // editable: true,
    },
    {
      field: 'StartDate',
      headerName: 'StartDate',
      type: 'date',
      width: 110,
      // editable: true,
    },
    {
      field: 'EndDate',
      headerName: 'EndDate',
      type: 'date',
      width: 110,
      // editable: true,
    },
    {
      field: 'AddTaxPer',
      headerName: 'AddTax %',
      type: 'number',
      width: 80,
      // editable: true,
    },
    {
      field: 'IncomeTaxPer',
      headerName: 'IncomeTax %',
      type: 'number',
      width: 80,
      // editable: true,
    },
    {
      field: 'WHTPer',
      headerName: 'WHT %',
      type: 'number',
      width: 80,
      // editable: true,
    },
    {
      field: 'ReferenceNo',
      headerName: 'Refrence No',
      width: 100,
      // editable: true,
    },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  const Get_Handler = () =>{
    if(SelectedCustomer){
      if(SelectedManufacturer){
        if(SelectedCG){
          if(SelectedItems){
            Select_SchemeList(SelectedProject.Project_ID)
          }else{
              msg('Please Select Items')  
          }
        }else{
          msg('Please Select Company Group')
         
        }
      }else{
        msg('Please Select manufacturer')
      
      }
    }else{
      Swal.fire({
        text:'Please Select Customer',
        icon:'info'
      })
    }
  }
  const DeleteHandler_Handler = () =>{
    if(SchemeList_Data.length >0){
     if(DeleteData.length > 0){
    //  const deletedObj = Object.assign({},DeleteData)
   var mArr = []
      DeleteData.map((i)=>{
      var mObj = {}
      mObj.SchemeDetailID=i
      mArr.push(mObj)
     })
     debugger
     const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteScheme(Comp_Id,Project.Project_ID,mArr,(res)=>{
          Select_SchemeList(Project.Project_ID)
          swalWithBootstrapButtons.fire(
            'Deleted!',
            `${res}`,
            'success'
          )
        })
      
      
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          'Your imaginary Scheme is safe :)',
          'error'
        )
      }
    })
 
  

   
     }else{
      Swal.fire({
        title:'Information',
        text:'No Data Selected For Delete',
        icon:'info'
      })
     }
    }else{
      Swal.fire({
        title:'Information',
        text:'Please Select Scheme First',
        icon:'info'
      })
    }
  }
  return ReactDOM.createPortal(
    <>
      <div className="block-header">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-12">
            <h2>Scheme List</h2>
          </div>
        </div>
      </div>

      <Card>
        <Row className="row_margin_top row_margin_left row_margin_right  mt-4 mr-4 ml-4">
          <Link to={"/SchemeEntry"} id="_scheme"></Link>
          <Col lg={3} xl={3} md={3}>
            <Autocomplete
              name="Project"
              id="Project"
              options={ProjectList}
              getOptionLabel={(option) =>
                option.ProjectName ? option.ProjectName : ""
              }
              value={SelectedProject}
              onChange={(e, value) => {
                if (value) {
                  setSelectedProject(value);
                  Select_SchemeList(value.Project_ID);
                } else {
                  setSelectedProject(null);
                  setSchemeList_Data([]);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} name="Project" label="Select Branch" />
              )}
            />
          </Col>
          <Col style={{ textAlign: "right" }}>
            {btn_add && (
              <Link
                type="button"
                className="btn btn-primary rounded-pill"
                to="#"
                onClick={add_Scheme}
                style={{ border: "none" }}
              >
                {" "}
                <Add /> Create New
              </Link>
            )}
          </Col>
        </Row>
        <Row className="row_margin_top row_margin_left row_margin_right  mt-4 mr-4 ml-4">
          <Col lg={3} xl={3} md={3}>
            <Autocomplete
              name="CustomerName"
              id="CustomerName"
              options={Customer_List}
              getOptionLabel={(option) =>
                option.CustomerName ? option.CustomerName : ""
              }
              value={SelectedCustomer}
              onChange={(e, value) => {
                if (value) {
                  setSelectedCustomer(value);
                } else {
                  setSelectedCustomer(null);
                  setSchemeList_Data([])
                }
              }}
              renderInput={(params) => (
                <TextField {...params} name="CustomerName" label="Customer" />
              )}
            />
          </Col>
          <Col lg={3} xl={3} md={3}>
            <Autocomplete
              name="ManufacturedBy"
              id="ManufacturedBy"
              options={Manufacturer_List}
              getOptionLabel={(option) =>
                option.ManufacturedBy ? option.ManufacturedBy : ""
              }
              value={SelectedManufacturer}
              onChange={(e, value) => {
                if (value) {
                  setSelectedManufacturer(value);
                  SelectCompanyGroupAgainstManufacturer(Comp_Id,SelectedProject.Project_ID,value.ManufacturedByID,(mData)=>{
                  var mObj = {CGID:0,CGName:'SELECT ALL',Comp_Id:Comp_Id,Description:'',ManufacturedByID:value.ManufacturedByID, ProjectID:SelectedProject.Project_ID}
                  mData.splice(0,0,mObj) 
                  setCG_List(mData)
                
                })
                } else {
                  setSelectedManufacturer(null);
                  setSelectedCG(null)
                  setCG_List([])
                  setItems_List([])
                  setSelectedItems(null)
                  setSchemeList_Data([])
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="ManufacturedBy"
                  label="ManufacturedBy"
                />
              )}
            />
          </Col>
          <Col lg={2} xl={2} md={2}>
            <Autocomplete
              name="CG"
              id="CG"
              options={CG_List}
              getOptionLabel={(option) => (option.CGName ? option.CGName : "")}
              value={SelectedCG}
              onChange={(e, value) => {
                if (value) {
                  setSelectedCG(value);
                  SelectItemsWRTManufacturer(Comp_Id,SelectedProject.Project_ID,SelectedManufacturer.ManufacturedByID,value.CGID,(mItems)=>{
                    var mObj = {ItemID:0, ItemDiscription:'SELECT ALL', Comp_Id:Comp_Id, ProjectID:SelectedProject.ProjectID}
                    mItems.splice(0,0,mObj)
                    setItems_List(mItems)
                  })
                } else {
                  setSelectedCG(null);
                  setItems_List([])
                  setSelectedItems(null)
                  setSchemeList_Data([])
                }
              }}
              renderInput={(params) => (
                <TextField {...params} name="CG" label="Company Group" />
              )}
            />
          </Col>
          <Col lg={2} xl={2} md={2}>
          <Autocomplete
          name="ItemDiscription"
          id="ItemDiscription"
          options={Items_List}
          getOptionLabel={(option) => (option.ItemDiscription ? option.ItemDiscription : "")}
          value={SelectedItems}
          onChange={(e, value) => {
            if (value) {
              setSelectedItems(value);
            } else {
              setSelectedItems(null);
              setSchemeList_Data([])
            }
          }}
          renderInput={(params) => (
            <TextField {...params} name="ItemDiscription" label="ItemDiscription" />
          )}
        />
          </Col>
          <Col lg={2} xl={2} md={2}>
          
            <Link
              type="button"
              className="btn btn-primary rounded-pill mt-2 float-right"
              to="#"
              onClick={Get_Handler}
              style={{ border: "none" }}
            >
              {" "}
              <CloudDownload /> GET SCHEME
            </Link>
          
          </Col>
        </Row>
        <Row className="row_margin_left row_margin_right m-3">
          <Col>
            <div className="table-responsive">
              <MuiThemeProvider theme={theme}>
              <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={SchemeList_Data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        onSelectionModelChange = {(ids)=>{
        const selectedIDs = ids
        setDeleteData(selectedIDs)
       
        
        }}
      />
    </Box>
              </MuiThemeProvider>
            </div>
          </Col>
        </Row>
        <Row className="row_margin_left row_margin_right m-4 justify-content-end mr-0">
        <Col lg={3} xl={3} md={3}>
          
        <Link
          type="button"
          className="btn btn-danger rounded-pill float-right"
          to="#"
          onClick={DeleteHandler_Handler}
          style={{ border: "none"}}
        >
          {" "}
          <DeleteForever />  DELETE SCHEME
        </Link>
        {/*<Bounce color="#727981" size={32} speed={1} animating={true} />*/}
      </Col>
        </Row>
        <Loader show={IsLoader} hide={hideLoader} />
      </Card>
    </>,
    document.getElementById("PageContents")
  );
}

const mapStateToProps = (state) => ({
  Comp_Id:
    state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  ItemCat: state.StockTransfer && state.StockTransfer.ItemData,
  //   ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
  Group_Id: state.Login_User.User_Data.Group_Id,
  p_userid: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
  Project: state.Scheme && state.Scheme.mData,
});
export default connect(mapStateToProps, { set_Scheme, SHOW_SCREEN })(Scheme);
