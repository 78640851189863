import * as React from 'react';
import ReactDOM from 'react-dom';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Row, Col, Card } from 'react-bootstrap';
import Button from 'rsuite/Button'
import 'rsuite/dist/rsuite.min.css'
const DiscountClaimTable = () => {
  return (
    <>
      <Card>
       
        <TreeView
          aria-label="file system navigator"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}

        >
          <TreeItem nodeId="1" label="Company Name">
            <TreeItem nodeId="2" label="Group Name">
              <TreeItem nodeId="3" label="Item Name">
                <TableComponent />
              </TreeItem>

            </TreeItem>
          </TreeItem>
        </TreeView>
      </Card>

    </>

  )
}

export default DiscountClaimTable

const TableComponent = () => {
  const columns = [
    {
      name: "InvoiceID",
      label: "InvoiceID",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
        //  } 
      }
    },
    {
      name: "InvoiceDate",
      label: "InvoiceDate",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        }
      }
    },
    {
      name: "CustomerID",
      label: "CustomerID",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "CustomerName",
      label: "CustomerName",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "ItemID",
      label: "ItemID",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "ItemName",
      label: "ItemName",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "GrossAmt",
      label: "GrossAmt",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "Qty",
      label: "Qty",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "Rate",
      label: "Rate",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "Dis%",
      label: "Dis%",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "DisAmt",
      label: "DisAmt",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "OurShare%",
      label: "OurShare%",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "OurShareAmt",
      label: "OurShareAmt",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "ClaimableAmt",
      label: "ClaimableAmt",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },

  ];
  const rows = [
    { InvoiceID: 'India', InvoiceDate: 'IN', CustomerID: 1324171354, CustomerName: 3287263, ItemID: 'IN', ItemName: 1324171354, GrossAmt: 3287263 },
    { InvoiceID: 'China', InvoiceDate: 'CN', CustomerID: 1403500365, CustomerName: 9596961, ItemID: 'IN', ItemName: 1324171354, GrossAmt: 3287263 },
    { InvoiceID: 'Italy', InvoiceDate: 'IT', CustomerID: 60483973, CustomerName: 301340, ItemID: 'IN', ItemName: 1324171354, GrossAmt: 3287263 },
    { InvoiceID: 'United States', InvoiceDate: 'US', CustomerID: 327167434, CustomerName: 9833520, ItemID: 'IN', ItemName: 1324171354, GrossAmt: 3287263 },
    { InvoiceID: 'Canada', InvoiceDate: 'CA', CustomerID: 37602103, CustomerName: 9984670, ItemID: 'IN', ItemName: 1324171354, GrossAmt: 3287263 },
    { InvoiceID: 'Australia', InvoiceDate: 'AU', CustomerID: 25475400, CustomerName: 7692024, ItemID: 'IN', ItemName: 1324171354, GrossAmt: 3287263 },

  ];
  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });
  return (
    <Row className="row_margin_top row_margin_left row_margin_right" >
      <Col>
        <div className="table-responsive" >
          <MuiThemeProvider theme={theme}>
            <MUIDataTable
              name="OPD_Datatables"
              data={rows}
              columns={columns}
              options={{
                selectableRows: 'none',
                print: false,
                search: true,
                download: false,
                viewColumns: false,
                filter: false,
              }}
            />
          </MuiThemeProvider>
        </div>
      </Col>
    </Row>
  );
};