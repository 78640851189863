import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "../SaleReports/Table.css"
import { rptCurrentStock } from '../../../Services/Distribution_ReportAPI'
import { SelectUserProjects } from '../../../Services/Projects'
import { SelectManufacturedByList  } from '../../../Services/manufactureAPI'
import { GetUserManufacturer } from '../../../Services/ClaimAPI';

function CurrrentStockReport({Comp_Name,Comp_Id,User_Name,User_Id,ConfigObj,UserType}) {

    const { ProjectID,ManufacturedByID } = useParams();
    const [ItemData, setItemData] = useState([]);
    const [TotalValue, setTotalValue] = useState(0);
    const [SelectedProject, setSelectedProject] = useState(null)
    const [Manufacturer,setManufacturer] = useState('')
    useEffect(() => {
        document.title = "Distribution / Current Stock Report" 

        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('footer').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;

        rptCurrentStock(Comp_Id, ProjectID, ManufacturedByID, async (mData) => {
            if (mData.length > 0) {
                if (UserType === 'Manager Portal') {
                    const res = await GetUserManufacturer(User_Id);
                    const userManufacturer = res.data;
                    const mDataCopy = [...mData]; // Create a shallow copy of mData
                    const FinalArray = userManufacturer.map(val => {
                        return mDataCopy.filter(x => x.ManufacturedByID === val.ManufacturedByID);
                    }).filter(obj => obj.length > 0);
        
                    const Totals = mDataCopy.slice(-1); // Use slice to get a copy of the last element
                    setTotalValue(Totals[0].value.toFixed(2));
        
                    const mArray = [];
                    FinalArray.forEach((value, index) => {
                        value.PurchaseRate = parseFloat(value.PurchaseRate).toFixed(2);
        
                        if (index === 0 || value.ManufacturedBy !== FinalArray[index - 1].ManufacturedBy) {
                            mArray.push({ ManufacturedBy: value.ManufacturedBy, title: true });
                        }
        
                        mArray.push(value);
                    });
        
                    setItemData(mArray);
                } else {
                    const mDataCopy = [...mData]; // Create a shallow copy of mData
        
                    const Totals = mDataCopy.slice(-1); // Use slice to get a copy of the last element
                    setTotalValue(Totals[0].value.toFixed(2));
        
                    const mArray = [];
                    mDataCopy.forEach((value, index) => {
                        value.PurchaseRate = parseFloat(value.PurchaseRate).toFixed(2);
        
                        if (index === 0 || value.ManufacturedBy !== mDataCopy[index - 1].ManufacturedBy) {
                            mArray.push({ ManufacturedBy: value.ManufacturedBy, title: true });
                        }
        
                        mArray.push(value);
                    });
        
                    setItemData(mArray);
                }
            }
        });
        
     
        
        SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
            mProjects.map(value=>{
                if(value.Project_ID == ProjectID){
                    setSelectedProject(value)
                }
            })
        })
        if(ManufacturedByID == "0"){
            setManufacturer('ALL COMPANIES')
        }
        else{
            SelectManufacturedByList(Comp_Id,ProjectID,(data) =>{
                data.map(mvalue=>{
                    if(mvalue.ManufacturedByID == ManufacturedByID ){
                        setManufacturer(mvalue.ManufacturedBy)
                    }
                })
            })

        }
        localStorage.removeItem("token");
    }, [])

   
    return (
        <>
        
        <div id="main_div_report" >
            <Row  style={{ textAlign: 'center', color: "black" }}>
            <Col sm={3} md={3} xl={3}><img src={ConfigObj.Logo} width="100" height="100" /></Col>
            <Col sm={6} md={6} xl={6}>
                    <h2> {Comp_Name} </h2> <br /> <h3>Current Stock</h3>
                </Col>
                </Row>
                <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Branch:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                         {SelectedProject && SelectedProject.ProjectName}
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Company:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                         {Manufacturer}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}   >Item Code</th>
                            <th style={{ width: '20%', textAlign: 'left', fontWeight: "bold" }} >Item</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Packing</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Batch</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Exp Date</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Qty</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >T.P</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Value</th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                         
                        {
                            ItemData.map((Itemdata, index) => {
                            return(
                                                               
                                <>
                                {
                                    
                                    Itemdata.title === true ? 
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none',borderBottom:'none'}}>
                                        <td colSpan={8} style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.ManufacturedBy}</td>
                                       </tr>
                                    :
                                    Itemdata.ItemID === "Total" ? 
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none'}}>
                                        <td colSpan={6} style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold',borderRight:'2px solid black' }}  >Total</td>
                                        <td colSpan={1} style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  > {Itemdata.Qty} </td>
                                    </tr>
                                    :
                                    <tr key={index} >
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{Itemdata.ItemCode}</td>
                                        <td style={{ width: '20%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.ItemDiscription}</td>
                                        
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.remarks}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.BatchNo === "" ? "-" : Itemdata.BatchNo}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.ExpairyDate}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.qty} </td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.PurchaseRate}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {(Itemdata?.value).toFixed(2)} </td>
                                    </tr>
                                }
                                </>
                               
                            )}
                            )
                        }
                        <tr id='footer_balance'>
                            <td id='footer_td_cb' colSpan="6" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  > Total :</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  >{TotalValue}</td>
                        </tr>
                   
                    </tbody>


                </table>

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig,
    UserType: state.Login_User.User_Data && state.Login_User.User_Data.User_Type
})

export default connect(mapStateToProps)(CurrrentStockReport)
