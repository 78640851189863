import React from 'react'
import ReactDOM from 'react-dom'
import {Row, Col,Card,Spinner} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {TextField, Popper} from '@material-ui/core'
import { Save, Cancel,ShowChart, Print} from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import { Autocomplete } from '@material-ui/lab';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
import { connect} from 'react-redux'
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { GetCurrentDate,preventMinus } from '../../Services/Common'
import { SelectSupplierList } from "../../Services/SuppliersAPI";
import { SelectWareHouseList } from "../../Services/WareHouseAPI";
import { SelectItemDetails,SelectItemWRTSupplier } from '../../Services/PurchaseOrderAPI'
import { SelectBatchDetails } from '../../Services/StockTransferAPI'
import { SelectCompanyGroupAgainstManufacturer } from '../../Services/CompanyGroupAPI'
import { GetMaxPurchaseeInvoiceNo,SavePurchaseInvoice,SelectPurchaseInvoiceMaster,SelectPurchaseInvoiceDetail, SelectPurchaseInvoiceMasterWRTCode,  SelectPurchaseInvoiceDetailWRTCode} from '../../Services/PurchaseInvoiceAPI'
import { Selectbank } from '../../Services/bankAPI'
 import PurchaseReturnModal from './../PurchaseReturn/PurchaseReturnModal'
 import { SelectManufacturer } from '../../Services/manufactureAPI'
import { SelectReason } from '../../Services/Reason_API'
 import Loader from '../Loader';
export class PurchaseReturn extends React.Component {
  constructor(props){
      super(props)

      this.state = {
        PurchaseInvoiceNo: '',
          SupplierList: [],
          SelectedSupplier: null,
          BankList:[],
          SelectedBank:null,
          Reason:[],
          SelectedReason:null,
          SelectedPaymentType:null,
          SelectedStockType:null,
          PurchaseDate: GetCurrentDate(),
          CreateDate:GetCurrentDate(),
          WareHouseList: [],
          SelectedWareHouse: null,
          Item_List: [],
          Batch_List:[],
          Selected_Batch:null,
          Selected_item: null,
           CompanyGroup_Data:[],
          SelectedCompanyGroup:null,
          Remarks: '',
          PI_Detail_Data: [],
          Supplier_Err: '',
          CG_Err:"",
            UOMID:0,
            Bank_Disable_Prop:false,
            Total_Disable_Prop:false,
            Balance_Disable_Prop:false,
            TotalQty:0,
            TotalDisAmt:0,
            StockType_Err:'',
            TotalTaxAmt:0,
            PurchaseValue:0,
            GrandTotal:0.00,
            Balance:0,
            TotalPaid:0,
            Discription:'',
            InvNo:'',
            WHTAmt:0,
            WHT:0,
            VATAmt:0,
            VATPer: 0,
            filter:null,
            IsLoader:false,
        // Modal 
        Stock_Modal : false,
        btn_edit : true, btn_Disabled:false,

        
        AddvTaxPer:0,
        AddvTaxAmt:0,
      }
      this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
      this.validationRules = { required: true };
      this.bg_Ref = React.createRef()
      this.customNameValidation = this.customNameValidation.bind(this)
      this.grid = null
    //   this.Comp_Id = this.props.Comp_Id && this.props.Comp_Id
      this.NAmeValidation = {
        required: [this.customNameValidation, 'Name must be selected'],
      };
      this.Comp_Id = this.props.Comp_Id 
      this.ProjectID = this.props.Project_ID
      this.UserID = this.props.p_userid
      this._Options= [
          {value:'Cash Purchase',title:'Cash Purchase'},
          {value:'Credit Purchase',title:'Credit Purchase'},
          {value:'Account Purchase',title:'Account Purchase'},
          {value:'Cash Credit Purchase',title:'Cash Credit Purchase'},
          {value:'Account Credit Purchase',title:'Account Credit Purchase'}]
           this._TypeOptions= [
            {value:'Purchase Return',title:'Purchase Return'},
            {value:'Stock Out',title:'Stock Out'}]
  }
 
  sortingOptions = {
    columns: [{ field: 'SrNo', direction: 'Ascending' }]
  };
  customNameValidation(args) {
      args.value=this.state.Selected_item ? this.state.Selected_item.ItemID : ''
    return getValue('value', args) != ''   
    }
    showLoader = () => {
        this.setState({IsLoader:true})
      }
      hideLoader = () => {
        this.setState({IsLoader:false})
      }
  componentDidMount(){

    document.title = " Distribution / Purchase Invoice"
    Selectbank(this.Comp_Id,this.ProjectID,(mBank)=>{
        this.setState({BankList:mBank})
    })
    SelectReason((mReason)=>{
        this.setState({Reason:mReason}) 
    SelectWareHouseList(this.Comp_Id,this.ProjectID,(mWareHouse)=>{
        this.setState({WareHouseList:mWareHouse,SelectedWareHouse:mWareHouse[0], btn_Disabled:false})
   
    SelectSupplierList(this.Comp_Id,this.ProjectID,(mSupplier)=>{
        this.setState({SupplierList:mSupplier})
      
        if(this.props.isAddNew) // save
        {
       
            this.GetMax_PurchaseeInvoiceNo() // new reciver NO 
            this.setState({SelectedStockType:this._TypeOptions[0]})
            this.setState({SelectedPaymentType: {value:'Cash Purchase',title:'Cash Purchase'},Bank_Disable_Prop:true,Total_Disable_Prop:true, Balance_Disable_Prop:true})
        }

        else // update
        {
            this.showLoader() 
            SelectPurchaseInvoiceMaster(this.Comp_Id,this.ProjectID,this.props.PurchaseInvoiceID,(mData)=>{
                this.hideLoader()
                this.setState({
                    PurchaseInvoiceID:mData[0].PurchaseInvoiceID,
                    PurchaseDate:mData[0].PurchaseDate,
                    Discription:mData[0].Discription,
                    TotalDisAmt:mData[0].TotalDisAmt,
                    TotalTaxAmt:mData[0].TotalTaxAmt,
                    TotalQty:mData[0].TotalQty,
                    GrandTotal:-(mData[0].GrandTotal),
                    PurchaseValue:mData[0].PurchaseValue,
                    PurchaseInvoiceNo: mData[0].PurchaseInvoiceNo,
                    WHT:mData[0].WHT,
                    WHTAmt:mData[0].WHTAmt,
                    VATPer: mData[0].VATPer,
                    VATAmt:mData[0].VATAmt,
                    btn_edit: this.props.show_screen.edit,
                    AddvTaxPer: mData[0].AddvTaxPer,
                    AddvTaxAmt : mData[0].AddvTaxAmt,
                })
                this._Options.map(val=>{
                    if(val.value === mData[0].PaymentType )
                    {
                        this.setState({SelectedPaymentType:val},()=>{
                            if(val.value === 'Account Purchase'){
                                this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                            }
                            else if (val.value === 'Account Credit Purchase'){
                                this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                            }
                            else if (val.value === 'Cash Credit Purchase'){
                                this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                            }
                            else if (val.value === 'Cash Purchase'){
                                this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                            }
                        })  
                    }
                })
                this._TypeOptions.map(val=>{
                    if(val.value === mData[0].StockType){
                        this.setState({SelectedStockType:val})
                    }
                })
                mWareHouse.map(val=>{
                    if(val.WareHouseID === mData[0].WareHouseID)
                    {
                        this.setState({SelectedWareHouse:val})
                    }
                })
                // mSupplier.map(val=>{
                //     if(val.SupplierID === mData[0].SupplierID)
                //     {
                //         this.setState({SelectedSupplier:val})
                //     }
                // })
                mSupplier.map(val=>{
                    
                    if(val.SupplierID === mData[0].SupplierID)
                    {
                        this.setState({SelectedSupplier:val})
                        SelectCompanyGroupAgainstManufacturer(this.Comp_Id,this.ProjectID,val.ManufacturedByID,(cgdata)=>{
                            this.setState({CompanyGroup_Data:cgdata,SelectedCompanyGroup:cgdata.find(x=> x.CGID === mData[0].CGID)})
                        })
                    }
                })
                mReason.map(val=>{
                    if(val.ReasonID === mData[0].ReasonID)
                    {
                        this.setState({SelectedReason:val})
                    }
                })
               
                SelectPurchaseInvoiceDetail(this.Comp_Id,this.ProjectID,this.props.PurchaseInvoiceID,(mDetailData)=>{
                    
                    mDetailData.map((value,index)=>{
                        value.SrNo = index + 1 
                        value.Qty = -(value.Qty)
                        value.BonusQty = -(value.BonusQty)
                    })
                    this.setState({PI_Detail_Data:mDetailData})
                })
            })
        }
        this.Select_Item_Details()
       })
    })
})
  }
  
  Select_Item_Details = () =>{
    SelectItemDetails(this.Comp_Id,this.ProjectID,(mItems)=>{ // Api
        this.setState({Item_List:mItems})
    })
  }
  Show_Min_Stock_Modal = ()=>{
      this.setState({Stock_Modal:true})
  }
  Hide_Min_Stock_Modal = ()=>{
    this.setState({Stock_Modal:false})
}
GetMax_PurchaseeInvoiceNo = () => {
    this.showLoader()
        GetMaxPurchaseeInvoiceNo(this.Comp_Id,this.ProjectID,'PR', (mPurchaseReturnNo) => { 
            
            this.hideLoader()
            this.setState({ PurchaseInvoiceNo: mPurchaseReturnNo }) 
        })
    }
    // Select_BatchDetails = (ItemID) =>{
    //     SelectBatchDetails(this.Comp_Id,this.ProjectID,ItemID,this.state.SelectedWareHouse.WareHouseID,(mItems)=>{ // Api
    //         this.setState({Batch_List:mItems})
    //     })
    // }
    Select_Batch_Detail = (ItemID, WareHouseID) =>{
        
        SelectBatchDetails(this.Comp_Id,this.ProjectID,ItemID,WareHouseID,async(mItems)=>{ // Api
            
            mItems.forEach(obj => {
                for (let key in obj) {
                  if (typeof obj[key] === 'number') {
                    obj[key] = parseFloat(obj[key].toFixed(2));
                  }
                }
              });
              mItems.sort(this.customSort);
              debugger
              var BatchNo_select = document.getElementById('BatchNo')
              var length = BatchNo_select.options.length;
              for (let i = length - 1; i >= 0; i--) {
                  BatchNo_select.options[i] = null;
              }
              for (let i = 0; i < mItems.length; i++) {
                  BatchNo_select.options[BatchNo_select.options.length] = new Option(mItems[i].batchno, mItems[i].batchno)
              }
              BatchNo_select.selectedIndex = 0
              if(mItems.length > 0){
                
                         document.getElementById('PurchaseRate').value = mItems[0].PackPurchaseRate
                        document.getElementById('MRP').value = mItems[0].MRP
                        document.getElementById('value').value = mItems[0].PurchaseRate
                      
                        document.getElementById('TaxPer').value = mItems[0].GST;
                        document.getElementById('ExpairyDate').value = mItems[0].ExpairyDate
                        this.setState({ Selected_Batch: mItems[0] },()=>{
                            this.Calculate_Total()
                        })
              }else{
                document.getElementById('PurchaseRate').value = ''
                document.getElementById('MRP').value =''
                document.getElementById('value').value = ''
                document.getElementById('TaxPer').value = ''
                document.getElementById('ExpairyDate').value = GetCurrentDate()
                this.setState({ Batch_List:[], Selected_Batch: null },()=>{
                    this.Calculate_Total()
                })
              }
          
        //  SelectSchemePolicy(this.Comp_Id, this.ProjectID,this.state.SelectedCustomer.CustomerID ,ItemID,(mSchemeData)=>{
        //    const FRData = mSchemeData[0]
        //     var BatchNo_select = document.getElementById('BatchNo')
        //     var length = BatchNo_select.options.length;
        //     for (let i = length - 1; i >= 0; i--) {
        //         BatchNo_select.options[i] = null;
        //     }
        //     for (let i = 0; i < mItems.length; i++) {
        //         BatchNo_select.options[BatchNo_select.options.length] = new Option(mItems[i].batchno, mItems[i].batchno)
        //     }
        //     BatchNo_select.selectedIndex = 0
        //     if(mItems.length > 0){
                
        //         // mmm.value=  mItems[0].batchno 
        //         // document.getElementById('ExpairyDate').value = mItems[0].ExpairyDate
        //         document.getElementById('BQty').value = mItems[0].BQty
        //         //Flat Rate Work
        //         if(FRData[0].FRPolicyFound){
        //             const FlatRate = FRData[0].FRData[0].ItemPolicyData[0].FlatRate
        //             document.getElementById('SaleRate').value = FlatRate
        //             document.getElementById('MRP').value = mItems[0].MRP
        //             document.getElementById('value').value = FlatRate
        //         }else{
        //             document.getElementById('SaleRate').value = mItems[0].PackSaleRate
        //             document.getElementById('MRP').value = mItems[0].MRP
        //             document.getElementById('TaxPer').value = mItems[0].GST
        //             document.getElementById('value').value = mItems[0].PackSaleRate
        //         }
               
        //         document.getElementById('ExpairyDate').value = mItems[0].ExpairyDate
        //         this.setState({Batch_List:mItems,Selected_Batch:mItems[0]})
        //     }
        //     else{
        //         document.getElementById('BQty').value = 0
        //         document.getElementById('SaleRate').value = 0
        //         document.getElementById('value').value = 0
        //         document.getElementById('ExpairyDate').value = 0
               
        //         this.setState({Batch_List:[],Selected_Batch:null})
        //     }
        //     //End Flat Rate Work
            
        //     const DiscountScheme = mSchemeData[1]
            
        //     let normalizedData = this.normalizeData(DiscountScheme);
        //     let DiscountMainArray =normalizedData.ItemPolicyData
           
        //     if(DiscountMainArray){
        //         DiscountMainArray.sort(function(a, b) {
        //             return a.TargetFromQty - b.TargetFromQty;
        //         });
        //     }
           
        //     if(DiscountScheme[0].DisPolicyFound){
        //         if(DiscountScheme[0].DisData){
        //             let StartDate = DiscountMainArray[0].StartDate ;
        //             let EndDate =  DiscountMainArray[0].EndDate;
        //             let MaxLimit =  DiscountMainArray[0].QtyLimit;
        //             let AmountLimit = DiscountMainArray[0].AmountLimit
        //             let SoldQty =  normalizedData.SoldQty;
        //             let SoldValue = normalizedData.SoldValue ? normalizedData.SoldValue : 0;
        //             let today = GetCurrentDate()
        //             let Qty = document.getElementById('Qty').value;
        //             let PolicyArray = []
        //             if(today >= StartDate && today <= EndDate){
        //                 // if(this.state.DisPer_Visible){
        //                     if(SoldQty <= MaxLimit || MaxLimit === 0){
        //                         PolicyArray = DiscountMainArray.find(obj => obj.TargetFromQty <= Qty && obj.Criteria >= Qty);
        //                         if(PolicyArray){
        //                             document.getElementById('DisPer').value = PolicyArray.Discount1Per 
        //                             document.getElementById('DisPer1').value = PolicyArray.Discount2Per
        //                         }else{
        //                             let maxid =  DiscountMainArray[DiscountMainArray.length-1].SchemeID;
        //                                 const minCriteria = DiscountMainArray[0].TargetFromQty;
        //                                 let chkmaxCriteria = DiscountMainArray[DiscountMainArray.length-1].Criteria;
        //                                 let maxDisQty 
        //                                 let maxDis2Qty
        //                                 if(DiscountMainArray.length > 1){
        //                                     maxDisQty = DiscountMainArray[DiscountMainArray.length-2].Discount1Per; // pick from 2nd last
        //                                     maxDis2Qty = DiscountMainArray[DiscountMainArray.length-2].Discount2Per;
        //                                 }else{
        //                                     maxDisQty = DiscountMainArray[DiscountMainArray.length-1].Discount1Per; // pick from 2nd last
        //                                     maxDis2Qty = DiscountMainArray[DiscountMainArray.length-1].Discount2Per;
        //                                }
        //                                PolicyArray =   DiscountMainArray.find(obj => obj.SchemeID === maxid);
                                   
        //                                if(Qty <=minCriteria){
        //                                 document.getElementById('DisPer').value = 0
        //                                 document.getElementById('DisPer1').value =0
        //                                }else{
        //                                     if(chkmaxCriteria === 0){
        //                                         document.getElementById('DisPer').value = maxDisQty
        //                                         document.getElementById('DisPer1').value =maxDis2Qty
        //                                     }else if(chkmaxCriteria === 1){
        //                                         var mDisQty = Math.floor(Qty /  PolicyArray.TargetFromQty) 
        //                                         document.getElementById('DisPer').value = mDisQty * maxDisQty
        //                                         document.getElementById('DisPer1').value =mDisQty * maxDis2Qty
        //                                     }else{
        //                                         document.getElementById('DisPer').value = maxDisQty
        //                                         document.getElementById('DisPer1').value =maxDis2Qty
        //                                     }
        //                                }
        //                         }
                              
        //                         // this.Calculate_Total()
        //                     }else{
        //                         document.getElementById('DisPer').value = 0
        //                         document.getElementById('DisPer1').value =0
        //                         // this.Calculate_Total() 
        //                     }
        //                 // }
                    
        //             }else{
        //                 document.getElementById('DisPer').value = 0
        //                 document.getElementById('DisPer1').value =0
        //                 // this.Calculate_Total()
        //             }
        //             this.setState({SoldValue: SoldValue, AmountLimit: AmountLimit, StartDate: StartDate, EndDate: EndDate})
        //         }else{
        //             document.getElementById('DisPer').value = 0
        //             document.getElementById('DisPer1').value =0
        //             // this.Calculate_Total()
        //         }
                
        //     }else{
        //         // document.getElementById('DisPer').value = 0
        //         // document.getElementById('DisPer1').value =0 
        //     }
            
        //     this.Calculate_Total()
        //  }) 
        //     this.hideLoader()
        })
    }
    Select_Item_DetailsForSupplier = (SupplierID) =>{
        SelectItemWRTSupplier(this.Comp_Id,this.ProjectID,SupplierID,(mItems)=>{
            this.setState({Item_List:mItems})
        })
      }
    ItemDiscription = (rowValue) => {
        return (
            <Autocomplete
                name="ItemDiscription"
                id="ItemDiscription"
                defaultValue={this.state.Item_List.find(v => v.ItemDiscription === rowValue.ItemDiscription)}
                options={this.state.Item_List}
                getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : ""}
                onChange={(e, value) => {
                    this.showLoader()
                  
                    if (value) {
                        debugger
                        this.setState({ Selected_item: value,UOMID:value.UOMID })
                        document.getElementById('UOM').value = value.UOM
                        document.getElementById('PurchaseRate').value = value.PurchaseRate
                        document.getElementById('MRP').value = value.MRP
                        document.getElementById('DisPer').value = value.DisPer
                        document.getElementById('value').value = value.PurchaseRate
                        document.getElementById('TaxPer').value = value.GST;
                        this.setState({ Selected_item: value,UOMID:value.UOMID },()=>{
                            this.Calculate_Total()  
                        })
                        this.setState({Selected_item: value})
                        this.Select_Batch_Detail(value.ItemID, this.state.SelectedWareHouse.WareHouseID)
                    }
                    else {
                        var BatchNo_select = document.getElementById('BatchNo')
                        var length = BatchNo_select.options.length;
                        for (let i = length - 1; i >= 0; i--) {
                            BatchNo_select.options[i] = null;
                        } 
                        this.setState({ Selected_item: null, Batch_List:[] })
                        document.getElementById('UOM').value = ''
                        document.getElementById('PurchaseRate').value = ''
                        document.getElementById('MRP').value = ''
                        document.getElementById('DisPer').value = value.DisPer;
                        document.getElementById('TaxPer1').value = value.GST;
                        document.getElementById('value').value = ''
                        document.getElementById('TaxPer').value = 0
                        document.getElementById('ExpairyDate').value = GetCurrentDate()
                        this.setState({ Selected_item: null },()=>{
                            this.Calculate_Total()
                        })
                       
                    }
                    this.hideLoader()
                }}
                renderInput={(params) =>
                    <TextField {...params} name="ItemDiscription" />}
            />
        )
    }
    Qty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="Qty"
            className="textbox"
            name='Qty'
            defaultValue={rowValue.Qty}
            onChange = { e => {
               this.Calculate_Total()
            }}
            min={1}
            onKeyPress={e=>preventMinus(e)}
        />)
    }
    BonusQty= (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="BonusQty"
            className="textbox"
            name='BonusQty'
            defaultValue={rowValue.BonusQty}
            // onChange = { e => {
            //    this.Calculate_Total()
            // }}
            min={0}
            onKeyPress={e=>preventMinus(e)}
        />)
    }
    UOM = (rowValue) => {

        return (<input
            id="UOM"
            name="UOM"
            style={{ textAlign: "right", width: "80%" }}
            defaultValue={rowValue.UOM}
            className="textbox"
            readOnly={true}
        />)

    }
    PurchaseRate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="PurchaseRate"
            defaultValue={rowValue.PurchaseRate}
            className="textbox"
            name="PurchaseRate"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={1}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    MRP = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="MRP"
            defaultValue={rowValue.MRP}
            className="textbox"
            name="MRP"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={1}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    BatchNo = (rowValue) => {
        return (<select id="BatchNo" name="BatchNo" 
            style={{ fontSize: 17, textAlign: "right", width: "100%" }}
            onChange={e => {
                var Qty = 0;
                this.state.Batch_List.find(value => {
                    debugger
                    if (e.target.value == value.batchno) {
                        document.getElementById('ExpairyDate').value = value.ExpairyDate
                        // document.getElementById('BQty').value =value.BQty
                        // document.getElementById('SaleRate').value =value.PackSaleRate
                        document.getElementById('TaxPer').value = parseFloat(value.GST).toFixed(2)
                        document.getElementById('MRP').value = parseFloat(value.MRP).toFixed(2)
                        document.getElementById('value').value = parseFloat(rowValue.Qty * value.PackSaleRate).toFixed(2)
                        this.setState({ Selected_Batch: value })
                    }
                })
            }}
            className="textbox" ></select>)

    }
    // BatchNo = (rowValue) => {
    //     return (<input
    //         style={{ textAlign: "right", width: "80%" }}
    //         type="text"
    //         id="BatchNo"
    //         defaultValue={rowValue.BatchNo}
    //         className="textbox"
    //         name="BatchNo"
    //         onChange = { e => {
    //             //  this.Calculate_Total()
    //          }}
    //     />)
    // }
    ExpairyDate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="date"
            id="ExpairyDate"
            defaultValue={rowValue.ExpairyDate}
            className="textbox"
            name="ExpairyDate"
            onChange = { e => {
                //  this.Calculate_Total()
             }}
        />)
    }
    DisPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisPer"
            defaultValue={rowValue.DisPer}
            className="textbox"
            name="DisPer"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={0}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    DisAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisAmt"
            defaultValue={rowValue.DisAmt}
            className="textbox"
            name="DisAmt"
            readOnly={true}
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    TaxPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxPer"
            defaultValue={rowValue.TaxPer}
            className="textbox"
            name="TaxPer"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={0}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    TaxAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxAmt"
            defaultValue={rowValue.TaxAmt}
            className="textbox"
            name="TaxAmt"
            readOnly={true}
            onChange = { e => {
               this.Calculate_Total()
             }}
        />)
    }
    Value = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="value"
            defaultValue={rowValue.Value}
            className="textbox"
            name="Value"
            readOnly={true}
        />)
    }

    SaveHandler = (fromPrint) => {
        
        if(this.state.SelectedSupplier)
        {
            this.setState({Supplier_Err:''})
            if(this.state.SelectedCompanyGroup){
                this.setState({CG_Err:''})
                if(this.state.PI_Detail_Data.length > 0)
                {
                    if(this.state.SelectedStockType){
                        if(this.state.SelectedReason){
                            var PurchaseData = {
                                PurchaseInvoiceID:this.props.PurchaseInvoiceID,
                                Comp_Id:this.Comp_Id,
                                ProjectID : this.ProjectID,
                                UserID : this.UserID,
                                PurchaseInvoiceNo:this.state.PurchaseInvoiceNo,
                                WareHouseID:this.state.SelectedWareHouse.WareHouseID,
                                SupplierID:this.state.SelectedSupplier.SupplierID,
                                CGID:this.state.SelectedCompanyGroup && this.state.SelectedCompanyGroup.CGID,
                                CreateDate:this.state.CreateDate,
                                PurchaseDate:this.state.PurchaseDate, 
                                Discription:this.state.Discription,
                                TotalDisAmt:this.state.TotalDisAmt,
                                TotalTaxAmt:this.state.TotalTaxAmt,
                                TotalQty:this.state.TotalQty,
                                GrandTotal:this.state.GrandTotal,
                                PurchaseValue:this.state.PurchaseValue,
                                IsPost:1,
                                TransactionType:'PR',
                                RefInvoiceDate:GetCurrentDate(),
                                PaymentType:this.state.SelectedPaymentType.value,
                                BankID:this.state.SelectedBank ? this.state.SelectedBank.BankID:0,
                                TotalPaid:this.state.TotalPaid,
                                Balance:this.state.Balance,
                                WHT:this.state.WHT === '' ? 0 : this.state.WHT,
                                WHTAmt:this.state.WHTAmt,
                                VATPer: this.state.VATPer,
                                VATAmt: this.state.VATAmt,
                                TotalTaxAmt1:0,
                                MediumID:0,
                                TrackingNo:'',
                                StockType: this.state.SelectedStockType.value,
                                AddvTaxPer: this.state.AddvTaxPer == '' ? 0 : this.state.AddvTaxPer,
                                AddvTaxAmt : this.state.AddvTaxAmt,
                                ReasonID: this.state.SelectedReason.ReasonID ? this.state.SelectedReason.ReasonID  : 0
                            }
            
                            for(let i = 0; i< this.state.PI_Detail_Data.length; i++)
                            {
                                if(typeof this.state.PI_Detail_Data[i].Qty === 'undefined' || this.state.PI_Detail_Data[i].Qty == '' || this.state.PI_Detail_Data[i].Qty == null )
                                this.state.PI_Detail_Data[i].Qty = 0
                                else if (typeof this.state.PI_Detail_Data[i].Qty === 'string')
                                this.state.PI_Detail_Data[i].Qty = parseFloat(this.state.PI_Detail_Data[i].Qty)
            
                                if(typeof this.state.PI_Detail_Data[i].BonusQty === 'undefined' || this.state.PI_Detail_Data[i].BonusQty == '' || this.state.PI_Detail_Data[i].BonusQty == null )
                                this.state.PI_Detail_Data[i].BonusQty = 0
                                else if (typeof this.state.PI_Detail_Data[i].BonusQty === 'string')
                                this.state.PI_Detail_Data[i].BonusQty =  parseFloat(this.state.PI_Detail_Data[i].BonusQty)
            
                                if(typeof this.state.PI_Detail_Data[i].TaxPer === 'undefined' || this.state.PI_Detail_Data[i].TaxPer == '' || this.state.PI_Detail_Data[i].TaxPer == null )
                                this.state.PI_Detail_Data[i].TaxPer = 0
                                else if (typeof this.state.PI_Detail_Data[i].TaxPer === 'string')
                                this.state.PI_Detail_Data[i].TaxPer =  parseFloat(this.state.PI_Detail_Data[i].TaxPer)
            
                                if(typeof this.state.PI_Detail_Data[i].DisPer === 'undefined' || this.state.PI_Detail_Data[i].DisPer == '' || this.state.PI_Detail_Data[i].DisPer == null )
                                this.state.PI_Detail_Data[i].DisPer = 0
                                else if (typeof this.state.PI_Detail_Data[i].DisPer === 'string')
                                this.state.PI_Detail_Data[i].DisPer =  parseFloat(this.state.PI_Detail_Data[i].DisPer)
            
                                if(typeof this.state.PI_Detail_Data[i].TaxAmt === 'undefined' || this.state.PI_Detail_Data[i].TaxAmt == '' || this.state.PI_Detail_Data[i].TaxAmt == null )
                                this.state.PI_Detail_Data[i].TaxAmt = 0
                                else if (typeof this.state.PI_Detail_Data[i].TaxAmt === 'string')
                                this.state.PI_Detail_Data[i].TaxAmt =  parseFloat(this.state.PI_Detail_Data[i].TaxAmt)
            
                                if(typeof this.state.PI_Detail_Data[i].DisAmt === 'undefined' || this.state.PI_Detail_Data[i].DisAmt == '' || this.state.PI_Detail_Data[i].DisAmt == null )
                                this.state.PI_Detail_Data[i].DisAmt = 0
                                else if (typeof this.state.PI_Detail_Data[i].DisAmt === 'string')
                                this.state.PI_Detail_Data[i].DisAmt =  parseFloat(this.state.PI_Detail_Data[i].DisAmt)
            
                                if(typeof this.state.PI_Detail_Data[i].PurchaseRate === 'undefined' || this.state.PI_Detail_Data[i].PurchaseRate == '' || this.state.PI_Detail_Data[i].PurchaseRate == null )
                                this.state.PI_Detail_Data[i].PurchaseRate = 0
                                else if (typeof this.state.PI_Detail_Data[i].PurchaseRate === 'string')
                                this.state.PI_Detail_Data[i].PurchaseRate =  parseFloat(this.state.PI_Detail_Data[i].PurchaseRate)
                                
                                if(typeof this.state.PI_Detail_Data[i].Value === 'undefined' || this.state.PI_Detail_Data[i].Value == '' || this.state.PI_Detail_Data[i].Value == null )
                                this.state.PI_Detail_Data[i].Value = 0
                                else if (typeof this.state.PI_Detail_Data[i].Value === 'string')
                                this.state.PI_Detail_Data[i].Value =  parseFloat(this.state.PI_Detail_Data[i].Value)
        
                                if(typeof this.state.PI_Detail_Data[i].BatchNo === 'undefined' || (this.state.PI_Detail_Data[i].BatchNo).trim() == '' || this.state.PI_Detail_Data[i].BatchNo == null )
                                {
                                 Swal.fire({
                                     icon: 'info',
                                     text: `Batch no is required at Sr No. ${this.state.PI_Detail_Data[i].SrNo}...`,
                                 })
                                 return
                                }
                            }
                            this.setState({btn_Disabled:true})
                            SavePurchaseInvoice(PurchaseData,this.state.PI_Detail_Data,this.props.isAddNew,()=>{
                                
                                if(fromPrint === true){
                                    window.open(`/PurchaseReturnReport`, "_blank");
                                }
                                this.setState({btn_Disabled:false})
                                document.getElementById('btnCancel').click()
                            })
                        }else{
                            this.setState({btn_Disabled:false})
                            Swal.fire({
                                icon: 'info',
                                text: `Reason Must be Entered...`,
                            })
                            return 
                        }
                      
                    }else{
                        this.setState({btn_Disabled:false})
                        Swal.fire({
                            icon: 'info',
                            text: `Stock Type Must be Entered...`,
                        })
                        return 
                    }
                   
                }
                else
                {
                    this.setState({btn_Disabled:false})
                    Swal.fire({
                        icon: 'info',
                        text: `Purchase Return Details Must be Entered...`,
                    })
                    return
                }
            }else{
                this.setState({CG_Err:'Company Group is required',btn_Disabled:false})
            }
           
        }
        else
        {
            this.setState({Supplier_Err:'Supplier is required',btn_Disabled:false})
        }
    }
    beforeRowInsert = (rowValue) => {

        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.SrNo) {
                    
                    if (rowValue.requestType != "cancel") {
                        rowValue.data.ItemDiscription = this.state.Selected_item.ItemDiscription
                        rowValue.data.ItemID = this.state.Selected_item.ItemID
                        rowValue.data.UOMID = this.state.UOMID
                        if(this.state.Selected_Batch){
                            rowValue.data.BatchNo = this.state.Selected_Batch.batchno
                        }
                   
                        // rowValue.data.MRP = this.state.Selected_item.MRP
                        if (rowValue.data.PurchaseInvoiceDetailID === undefined)
                            rowValue.data.action = 'add'
                        else
                            rowValue.data.action = rowValue.action

                    }
                }
                else {
                    rowValue.data.SrNo = this.state.PI_Detail_Data.length + 1
                    rowValue.data.Qty = 1
                    rowValue.data.BonusQty = 0
                    rowValue.data.DisPer = 0.00
                    rowValue.data.DisAmt = 0.00
                    rowValue.data.TaxPer = 0.00
                    rowValue.data.TaxAmt = 0.00

                    this.setState({ Selected_item: null,UOMID:0,Selected_Batch : null })
                }
            }
            // if (rowValue.requestType === 'beginEdit') {
            // }
        }

    }
    afterRowInsert = (rowValue) => {

        if (rowValue.requestType == "beginEdit") {
            
            this.setState({ Selected_item: { "ItemDiscription": rowValue.rowData.ItemDiscription, "ItemID": rowValue.rowData.ItemID, "MRP":rowValue.rowData.MRP  },UOMID: rowValue.rowData.UOMID }, ()=>{
                SelectBatchDetails(this.Comp_Id,this.ProjectID,rowValue.rowData.ItemID,this.state.SelectedWareHouse.WareHouseID,(mItems)=>{ // Api
                    
                    var BatchNo_select = document.getElementById('BatchNo')
                        
                    var length = BatchNo_select.options.length;
                    for (let i = length - 1; i >= 0; i--) {
                        BatchNo_select.options[i] = null;
                    }
                    
                    for (let i = 0; i < mItems.length; i++) {
                        if((mItems[i].batchno).trim() == (rowValue.rowData.BatchNo).trim()){
                            BatchNo_select.options[BatchNo_select.options.length] = new Option(mItems[i].batchno, mItems[i].batchno)
                            BatchNo_select.selectedIndex = i
                            this.setState({Selected_Batch:mItems[i]})
                        }
                        else{
                            BatchNo_select.options[BatchNo_select.options.length] = new Option(mItems[i].batchno, mItems[i].batchno)
                        }
                    }
                   
                    
                   this.setState({Batch_List:mItems})
            })
            })
        
        }
        if (rowValue.requestType == "save") {
            this.Calculate_Values()
        }
        if (rowValue.requestType == "delete") {
            this.Calculate_Values()
        }
        if(rowValue.requestType === "add")
        {
            document.getElementById("ExpairyDate").value = GetCurrentDate()
        }
    }
    Calculate_Total = () => {
        
        var Qty = 0, Rate = 0,MRP = 0, DisPer = 0, TaxPer = 0, DisAmt = 0, TaxAmt = 0, Total_Value = 0;
        if (document.getElementById('Qty').value)
            Qty = typeof (document.getElementById('Qty').value) === 'string' ? parseFloat((document.getElementById('Qty').value).replace(/,/g, '')) : document.getElementById('Qty').value
        else if (document.getElementById('Qty').value == "")
            Qty = 0
        else
            Qty = parseFloat(document.getElementById('Qty').value)
        // MRP = this.state.Selected_item ?  this.state.Selected_item.MRP : 0
        MRP = document.getElementById('MRP').value == "" ? 0 : parseFloat(document.getElementById('MRP').value)
        
        Rate = document.getElementById('PurchaseRate').value == "" ? 0 : parseFloat(document.getElementById('PurchaseRate').value)
        DisPer = document.getElementById('DisPer').value == "" ? 0 : parseFloat(document.getElementById('DisPer').value)
        TaxPer = document.getElementById('TaxPer').value == "" ? 0 : parseFloat(document.getElementById('TaxPer').value)

        // DisAmt = parseFloat(((DisPer / 100) * Rate).toFixed(2))
        // TaxAmt = parseFloat(((TaxPer / 100) * Rate).toFixed(2))
        DisAmt = parseFloat((((Qty * Rate) / 100) * DisPer).toFixed(2))
        TaxAmt = parseFloat((((Qty * MRP) / 100) * TaxPer).toFixed(2))
        
        
        Total_Value = parseFloat(((((Qty * Rate) - DisAmt) + TaxAmt)).toFixed(2))
        document.getElementById('DisAmt').value = DisAmt;
        document.getElementById('TaxAmt').value = TaxAmt;
        document.getElementById('value').value = Total_Value;

    }
    Calculate_Values = (VATAmt = 0) =>{
        var _TotalQty = 0, _TotalDis = 0, _TotalTax = 0, _TotalPur = 0, _GrandTotal = 0, Rate=0, Qty=0
        for(let i = 0; i< this.state.PI_Detail_Data.length; i++)
        {
           if( typeof this.state.PI_Detail_Data[i].Qty === 'string' && typeof this.state.PI_Detail_Data[i].DisAmt === 'string' && typeof this.state.PI_Detail_Data[i].TaxAmt === 'string' && typeof this.state.PI_Detail_Data[i].PurchaseRate === 'string' && typeof this.state.PI_Detail_Data[i].value === 'string'  )
           {
            this.state.PI_Detail_Data[i].Qty = parseFloat((this.state.PI_Detail_Data[i].Qty).replace(/,/g, ''))
            this.state.PI_Detail_Data[i].DisAmt = parseFloat((this.state.PI_Detail_Data[i].DisAmt).replace(/,/g, ''))
            this.state.PI_Detail_Data[i].TaxAmt = parseFloat((this.state.PI_Detail_Data[i].TaxAmt).replace(/,/g, ''))
            this.state.PI_Detail_Data[i].PurchaseRate = parseFloat((this.state.PI_Detail_Data[i].PurchaseRate).replace(/,/g, ''))
            this.state.PI_Detail_Data[i].Value = parseFloat((this.state.PI_Detail_Data[i].Value).replace(/,/g, ''))
           }
           else if (this.state.PI_Detail_Data[i].Qty == '' && typeof this.state.PI_Detail_Data[i].DisAmt === '' && typeof this.state.PI_Detail_Data[i].TaxAmt === '' && typeof this.state.PI_Detail_Data[i].PurchaseRate === '' && typeof this.state.PI_Detail_Data[i].value === '' )
           {
            this.state.PI_Detail_Data[i].Qty = 0 
            this.state.PI_Detail_Data[i].DisAmt = 0
            this.state.PI_Detail_Data[i].TaxAmt = 0
            this.state.PI_Detail_Data[i].PurchaseRate = 0
           this.state.PI_Detail_Data[i].Value = 0
           }
           _TotalQty += parseFloat(this.state.PI_Detail_Data[i].Qty)
           _TotalDis += parseFloat(this.state.PI_Detail_Data[i].DisAmt)
           _TotalTax += parseFloat(this.state.PI_Detail_Data[i].TaxAmt)
           _TotalPur += parseFloat((this.state.PI_Detail_Data[i].Qty * this.state.PI_Detail_Data[i].PurchaseRate).toFixed(2))
            _GrandTotal += parseFloat(this.state.PI_Detail_Data[i].Value).toFixed(2)
        }
        // this.setState({TotalQty:_TotalQty})
        // this.setState({TotalDisAmt:_TotalDis})
        // this.setState({TotalTaxAmt:_TotalTax})
        // this.setState({PurchaseValue:_TotalPur})
         this.setState({GrandTotal:parseFloat(_GrandTotal).toFixed(2),PurchaseValue:_TotalPur,TotalTaxAmt:_TotalTax,TotalDisAmt:_TotalDis,TotalQty:_TotalQty},()=>{
            this.CalculateWHT(VATAmt);
         })
    }
    toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    LoadStockToGrid = mData =>{
        this.state.SupplierList.map(value=>{
            if(value.SupplierID === mData[0].SupplierID)
            {
                this.setState({SelectedSupplier:value})
            }
        })

        this.setState({ PI_Detail_Data: this.state.PI_Detail_Data.concat(mData) },()=>{
         var  mArray = [...this.state.PI_Detail_Data]
            mArray.map((value,index)=>{
                value.SrNo = index + 1
            })
            this.setState({mArray},()=>{
                this.Calculate_Values()
            })
        })
        this.Hide_Min_Stock_Modal()
    }
    CalculateWHT = (VATAmt =0)=>{
        var mWHTAmt = 0,_GrandTotal=0, _AddTaxAmt = 0
        for(let i = 0; i< this.state.PI_Detail_Data.length; i++)
        {
           if( typeof this.state.PI_Detail_Data[i].value === 'string'  )
           {
            this.state.PI_Detail_Data[i].Value = parseFloat((this.state.PI_Detail_Data[i].Value).replace(/,/g, ''))
           }
           else if (typeof this.state.PI_Detail_Data[i].value === '' )
           {
           this.state.PI_Detail_Data[i].Value = 0
           }
            _GrandTotal += parseFloat(this.state.PI_Detail_Data[i].Value)
        }
        mWHTAmt = parseFloat(((_GrandTotal / 100 ) * this.state.WHT).toFixed(2))
        _AddTaxAmt =  parseFloat(((_GrandTotal / 100 ) * this.state.AddvTaxPer).toFixed(2))

        _GrandTotal =parseFloat(_GrandTotal + mWHTAmt + _AddTaxAmt + VATAmt).toFixed(2)
        this.setState({WHTAmt:mWHTAmt,AddvTaxAmt:_AddTaxAmt, VATAmt:VATAmt,GrandTotal:_GrandTotal})
    }
    Inv_Columns = [
        {
            field: "SupplierName",
            header: "Supplier Name",
            width: "150px",
        },
        {
            field: "ManufacturedBy",
            header: "Manufactured By",
            width: "150px",
        },
    ];
    handleFilterChange = event => {
        if (event) {
          this.setState({ filter: event.filter });
        }
    };
render(){
        if(this.grid)
            this.grid.autoFitColumns(['SrNo','ItemDescription','dd_qty','Godown','Mnuit']);
        enableRipple(true);
    return ReactDOM.createPortal (
        <>
          <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2> Purchase Return
                    </h2>
                </div>
            </div>
        </div>
            <Card>
           
            <Row className="row_margin_top row_margin_left row_margin_right p-4" >
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            name="PurchaseInvoiceNo"
                            label="Purchase Return No" 
                            value={this.state.PurchaseInvoiceNo}
                            inputProps={
                                {readOnly: true}
                            }
                            fullWidth
                            />
                    </Col>
                 {/*  <Col lg={4} xl={4} md={4} >
                    <MultiColumnComboBox
                            data={this.state.SelectedSupplier}
                            style={{ width: "100%", fontSize: "12px",backgroundColor:'white' }}
                            columns={this.state.filter ? filterBy(this.state.SupplierList, this.state.filter) : this.state.SupplierList}
                            value={this.Inv_Columns}
                            textField={"SupplierName"}
                            filterable={true}
                            label='Select Supplier'
                            onFilterChange={this.handleFilterChange}
                            onChange={e => {
                                if (e.value) {
                                    
                                    SelectCompanyGroupAgainstManufacturer(this.Comp_Id,this.ProjectID,e.value.ManufacturedByID,(mData)=>{
                                        this.setState({CompanyGroup_Data:mData})
                                    })
                                    SelectManufacturer(this.Comp_Id,this.ProjectID,e.value.ManufacturedByID,(manufacturer)=>{
                                        this.setState({WHT:manufacturer[0].WHT,AddvTaxPer:manufacturer[0].AddvTaxPer},()=>{
                                            this.CalculateWHT()
                                        })
                                    this.setState({SelectedSupplier:e.value})
                                    })
                                   
                                }
                                else {
                                    this.setState({SelectedSupplier:null,WHT:0,WHTAmt:0})
                                }
                            }}
                        />
                         <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col>*/}
                    <Col lg={4} xl={4} md={4} >
                    <MultiColumnComboBox
                            data={this.state.filter ? filterBy(this.state.SupplierList, this.state.filter) : this.state.SupplierList}
                            value={this.state.SelectedSupplier}
                            style={{ width: "100%", fontSize: "12px",backgroundColor:'white' }}
                            columns={this.Inv_Columns}
                            textField={"SupplierName"}
                            filterable={true}
                            label='Select Supplier'
                            onFilterChange={this.handleFilterChange}
                            onChange={e => {
                                if (e.value) {
                                    this.Select_Item_DetailsForSupplier(e.value.SupplierID)
                                    SelectCompanyGroupAgainstManufacturer(this.Comp_Id,this.ProjectID,e.value.ManufacturedByID,(mData)=>{
                                        this.setState({CompanyGroup_Data:mData,SelectedSupplier:e.value})
                                    })
                                    SelectManufacturer(this.Comp_Id,this.ProjectID,e.value.ManufacturedByID,(manufacturer)=>{
                                        this.setState({WHT:manufacturer[0].WHT,AddvTaxPer:manufacturer[0].AddvTaxPer},()=>{
                                            this.CalculateWHT()
                                        })
                                        
                                    })
                                }
                                else {
                                    this.setState({Item_List:[]})
                                    this.setState({SelectedSupplier:null,CompanyGroup_Data:[],SelectedCompanyGroup:null})
                                }
                            }}
                        />
                         <span className="text-danger">{this.state.Supplier_Err}</span>

                        {/* <Autocomplete
                            name="SupplierName"
                            id="SupplierName"
                            options={this.state.SupplierList}
                            getOptionLabel={(option) => option.SupplierName ? option.SupplierName : ""}
                            value={this.state.SelectedSupplier}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    SelectCompanyGroupAgainstManufacturer(this.Comp_Id,this.ProjectID,value.ManufacturedByID,(mData)=>{
                                        this.setState({CompanyGroup_Data:mData,SelectedSupplier:value})
                                    })
                                    SelectManufacturer(this.Comp_Id,this.ProjectID,value.ManufacturedByID,(manufacturer)=>{
                                        this.setState({WHT:manufacturer[0].WHT},()=>{
                                            this.CalculateWHT()
                                        })
                                        
                                    })
                              
                                }
                                else
                                {
                                    this.setState({SelectedSupplier:null,CompanyGroup_Data:[],SelectedCompanyGroup:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="SupplierName" label="Supplier"
                                />
                            }
                        /> */}
                       
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                    <Autocomplete
                        name="CGID"
                        id="CGID"
                        options={this.state.CompanyGroup_Data}
                        getOptionLabel={(option) => option.CGName ? option.CGName : ""}
                        value={this.state.SelectedCompanyGroup}
                        onChange={(e, value) => {

                            if (value) {
                                this.setState({SelectedCompanyGroup:value})
                            }
                            else {
                                this.setState({SelectedCompanyGroup:value})
                            }

                        }}
                        renderInput={(params) =>
                            <TextField {...params} name="CGID" id="CGID" label="Company Group"
                            />}

                    />
                    <span className="text-danger">{this.state.CG_Err}</span>
                </Col>
                    {/* <Col lg={4} xl={4} md={4}>
                        <Autocomplete
                            name="SupplierName"
                            id="SupplierName"
                            options={this.state.SupplierList}
                            getOptionLabel={(option) => option.SupplierName ? option.SupplierName : ""}
                            value={this.state.SelectedSupplier}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    SelectManufacturer(this.Comp_Id,this.ProjectID,value.ManufacturedByID,(manufacturer)=>{
                                        this.setState({WHT:manufacturer[0].WHT},()=>{
                                            this.CalculateWHT()
                                        })
                                    this.setState({SelectedSupplier:value})
                                    })
                                }
                                else
                                {
                                    this.setState({SelectedSupplier:null,WHT:0,WHTAmt:0})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="SupplierName" label="Supplier"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col> */}
                    {/* <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="WareHouse"
                            id="WareHouse"
                            options={this.state.WareHouseList}
                            getOptionLabel={(option) => option.WareHouse ? option.WareHouse : ""}
                            value={this.state.SelectedWareHouse}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedWareHouse:value})
                              
                                }
                                else
                                {
                                    this.setState({SelectedWareHouse:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="WareHouse" label="WareHouse"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col> */}
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            value={this.state.PurchaseDate}
                            name="PurchaseDate"
                            label="Purchase Date" 
                            type= 'date'
                            onChange= {e => this.setState({PurchaseDate:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="PaymentType"
                            id="PaymentType"
                            options={this._Options}
                            getOptionLabel={(option) => option.title ? option.title : ""}
                            value={this.state.SelectedPaymentType}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedPaymentType:value},()=>{
                                        if(value.value === 'Account Purchase'){
                                            this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                                        }
                                        else if (value.value === 'Account Credit Purchase'){
                                            this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                                        }
                                        else if (value.value === 'Cash Credit Purchase'){
                                            this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                                        }
                                        else if (value.value === 'Cash Purchase'){
                                            this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                                        }
                                    })
                              
                                }
                            
                                else
                                {
                                    this.setState({SelectedPaymentType:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="PaymentType" label="Payment Type"
                                />}
                        />
                        {/* <span className="text-danger">{this.state.Supplier_Err}</span> */}
                    </Col>
                
                    
                    <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="BankName"
                            id="BankName"
                            options={this.state.BankList}
                            getOptionLabel={(option) => option.BankName ? option.BankName : ""}
                            value={this.state.SelectedBank}
                            disabled={this.state.Bank_Disable_Prop}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedBank:value})
                              
                                }
                                else
                                {
                                    this.setState({SelectedBank:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="BankName" label="BankName"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalPaid"
                            label="TotalPaid"
                            type="number"
                            value={this.state.TotalPaid}
                            // onInput={this.toInputUppercase}
                            disabled={this.state.Total_Disable_Prop}
                            onChange={e=>{
                                this.setState({TotalPaid:e.target.value},()=>{
                                  let bal =   this.state.TotalPaid - this.state.GrandTotal
                                  this.setState({Balance:bal})
                                })}}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="Balance"
                            label="Balance" 
                            type="number"
                            value={this.state.Balance}
                            // onInput={this.toInputUppercase}
                            disabled={this.state.Balance_Disable_Prop}
                            onChange={e=>this.setState({Balance:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={6} xl={6} md={6} >
                        <TextField
                            name="Discription"
                            label="Discription" 
                            value={this.state.Discription}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({Discription:e.target.value})}
                            fullWidth
                        />
                    </Col> 
                    <Col sm={12} lg={2} xl={2} md={2} >
                    <TextField
                        name="InvNo"
                        label="Purchase Invoice #" 
                        value={this.state.InvNo}
                        onChange={e=>this.setState({InvNo:e.target.value},()=>{
                            if(e.target.value.length != 6 ){
                                this.setState({PI_Detail_Data:[],CompanyGroup_Data:[],SelectedCompanyGroup:null, TotalQty:0,GrandTotal:0,PurchaseValue:0,TotalTaxAmt:0,TotalDisAmt:0,SelectedSupplier:null,WHTAmt:0,WHT:0,AddvTaxAmt:0,AddvTaxPer:0})
                               
                            }
                            if(e.target.value.length == 6){
                                SelectPurchaseInvoiceMasterWRTCode(this.Comp_Id,this.ProjectID,e.target.value,(mData)=>{
                                    
                                  if(mData.length > 0) {
                                    this.setState({VATPer:mData[0].VATPer})
                                   const Selectedsup =  this.state.SupplierList.find(x=> x.SupplierID === mData[0].SupplierID)
                                   if(Selectedsup){
                                    SelectManufacturer(this.Comp_Id,this.ProjectID,Selectedsup.ManufacturedByID,(manufacturer)=>{
                                        this.setState({WHT:manufacturer[0].WHT,AddvTaxPer:manufacturer[0].AddvTaxPer},()=>{
                                            this.CalculateWHT(mData[0].VATAmt)
                                        })
                                        SelectCompanyGroupAgainstManufacturer(this.Comp_Id,this.ProjectID,Selectedsup.ManufacturedByID,(cgdata)=>{
                                            this.setState({CompanyGroup_Data:cgdata,SelectedCompanyGroup:cgdata.find(x=> x.CGID === mData[0].CGID)})
                                        })
                                    })
                                    this.setState({SelectedSupplier:Selectedsup})
                                   }
                                   SelectPurchaseInvoiceDetailWRTCode(this.Comp_Id,this.ProjectID,mData[0].PurchaseInvoiceID,(mDetailData)=>{ 
                                        mDetailData.map((value,index)=>{
                                            value.SrNo = index + 1 
                                            value.action = 'add'
                                        })
                                        this.setState({PI_Detail_Data:mDetailData},()=>{
                                            this.Calculate_Values(mData[0].VATAmt)
                                        })
                                    })
                                  }
                                  else{
                                    Swal.fire({
                                        icon: 'info',
                                        title: 'Oops...',
                                        text: `No Data Found on this Invoice No`,
                                      })
                                  }
                                  
                                })
                            }
                            
                        })}
                        fullWidth
                    />
                    </Col> 
                    <Col sm={12} lg={2} xl={2} md={3} >
                    <Autocomplete
                    name="StockType"
                    id="StockType"
                    options={this._TypeOptions}
                    getOptionLabel={(option) => option.title ? option.title : ""}
                    value={this.state.SelectedStockType}
                    onChange={(e, value) => {
                        if (value)
                        {
                            this.setState({SelectedStockType:value})   
                      
                        }
                    
                        else
                        {
                            this.setState({SelectedStockType:null})
                    
                        }
                    }}
                    renderInput={(params) =>
                        <TextField {...params} name="Stock" label="Stock Type"
                        />}
                />
                <span className="text-danger">{this.state.StockType_Err}</span> 
                </Col>
                <Col sm={12} lg={2} xl={2} md={3} >
                <Autocomplete
                name="Reason"
                id="Reason"
                options={this.state.Reason}
                getOptionLabel={(option) => option.Reason ? option.Reason : ""}
                value={this.state.SelectedReason}
                onChange={(e, value) => {
                    if (value)
                    {
                        this.setState({SelectedReason:value})   
                    }
                    else
                    {
                        this.setState({SelectedReason:null})
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="Reason" label="Reason"
                    />}
            />
            
            </Col>
                </Row>
                <Row className="row_margin_top row_margin_left row_margin_right p-4 mt-2" >
                    <div style={{display:"flex",width:"100%",height:"100%",overflow:"hidden"}}> 
                        <div style={{flex:"1",width:"100%",height:"100%"}}>
                            <GridComponent dataSource={this.state.PI_Detail_Data} actionBegin={ this.beforeRowInsert} ref={g => this.grid = g} actionComplete={this.afterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: true, allowDeleting: true, allowAdding: true, newRowPosition:'Bottom'  }} sortSettings={this.sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={this.toolbarOptions}>
                                <ColumnsDirective>
                                    <ColumnDirective width='120' field='SrNo' headerText='SrNo #' Format= "{0:n}" allowEditing={false} isIdentity={true}  textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                    <ColumnDirective width='250' field='ItemDiscription' headerText='ItemDiscription' editType= 'text'  editTemplate={this.ItemDiscription}   edit={this.cbParams}   textAlign='Left'/> {/*validationRules={true} */}
                                    <ColumnDirective width='170' field='Qty' headerText='Qty' editTemplate={this.Qty} textAlign='right'/>
                                    <ColumnDirective width='170' field='BonusQty' headerText='BonusQty' editType="number" editTemplate={this.BonusQty} textAlign='right'/>
                                    <ColumnDirective width='130' field='UOM' headerText='UOM'  editTemplate={this.UOM} />
                                    {/* <ColumnDirective width='170' field='Expairy' headerText='Expiry Date'  editTemplate={this.Expairy} textAlign='right'></ColumnDirective> */}
                                    <ColumnDirective width='150' field='PurchaseRate' headerText='PurchaseRate'   editTemplate={this.PurchaseRate} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='130' field='MRP' headerText='MRP' editTemplate={this.MRP} textAlign='right'/>
                                    <ColumnDirective width='250' field='BatchNo' headerText='BatchNo' editType= 'text'  editTemplate={this.BatchNo}   edit={this.cbParams}   textAlign='Left'/>
                                    <ColumnDirective width='250' field='ExpairyDate' headerText='ExpairyDate' editType= 'date'  editTemplate={this.ExpairyDate}   edit={this.cbParams}   textAlign='Left'/>
                                    <ColumnDirective width='150' field='DisPer' headerText='Dis%'   editTemplate={this.DisPer} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='DisAmt' headerText='DisAmt'   editTemplate={this.DisAmt} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='TaxPer' headerText={this.Comp_Id === 6 ? 'VAT%' : 'GST%'}   editTemplate={this.TaxPer} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='TaxAmt' headerText={this.Comp_Id === 6 ? 'VAT Amt' : 'GST Amt'}   editTemplate={this.TaxAmt} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='Value' headerText='Value'   editTemplate={this.Value} textAlign='right'></ColumnDirective>
                                    
                                     {/*<ColumnDirective width='130' field='MRP' headerText='MRP' visible={false}/>*/}
                                    <ColumnDirective  field='TaxPer1' defaultValue={0} visible={false} ></ColumnDirective>
                                    <ColumnDirective  field='TaxAmt1' defaultValue={0}  visible={false}  ></ColumnDirective>
                                     <ColumnDirective field='ItemID'  visible={false}  ></ColumnDirective>
                                    <ColumnDirective field='PurchaseInvoiceDetailID' headerText='PurchaseInvoiceDetailID'  visible={false}></ColumnDirective>
                                    <ColumnDirective field='PurchaseInvoiceID' headerText='PurchaseInvoiceID'  width='150' hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='UOMID' headerText='UOMID'  width='150' hideAtMedia={true} ></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]}   />
                            </GridComponent>
                        </div>
                    </div> 
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right p-4" >
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalQty"
                            label="TotalQty" 
                            value={this.state.TotalQty}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({TotalQty:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalDisAmt"
                            label="TotalDisAmt" 
                            value={this.state.TotalDisAmt}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({TotalDisAmt:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalTaxAmt"
                            label={this.Comp_Id === 6 ? 'Total VAT Amt' : 'Total GST Amt'} 
                            value={this.state.TotalTaxAmt}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({TotalTaxAmt:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="WHT"
                            label="WHT %" 
                            value={this.state.WHT}
                            type="number"
                            onChange={e=>this.setState({WHT:e.target.value},()=>{
                                this.CalculateWHT();
                            })}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="WHTAmt"
                            label="WHT Amt" 
                            value={this.state.WHTAmt}
                            type="number"
                            inputProps={
                                {readOnly: true}
                            }
                            // onChange={e=>this.setState({WHTAmt:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="AddvTaxPer"
                            label="Add Tax %" 
                            value={this.state.AddvTaxPer}
                            type="number"
                            onChange={e=>this.setState({AddvTaxPer:e.target.value},()=>{
                                this.CalculateWHT();
                            })}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="AddvTaxAmt"
                            label="Add Tax Amt" 
                            value={this.state.AddvTaxAmt}
                            type="number"
                            inputProps={
                                {readOnly: true}
                            }
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="PurchaseValue"
                            label="PurchaseValue" 
                            value={this.state.PurchaseValue}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({PurchaseValue:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    {/*
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField 
                            name="VATPer"
                            label="VAT %"
                            type='number'
                            value={this.state.VATPer}
                            inputProps={{ style: { color: "red", fontWeight: 'bold', fontSize: '16px' } }}
                            onChange={e => {
                                const newVATPer = e.target.value;
                                this.setState({ VATPer: newVATPer });
                                const mAmt = (this.state.GrandTotal * newVATPer) / 100;
                                const roundedVATAmt = parseFloat(mAmt.toFixed(2));
                                this.CalculateWHT(roundedVATAmt);
                                
                                
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField 
                            name="VATAmt"
                            label="VAT Amount"
                            type='number'
                            value={this.state.VATAmt}
                            disabled={true}
                            inputProps={{ style: { color: "red", fontWeight: 'bold', fontSize: '16px' } }}
                            onChange={e=>{
                                this.setState({VATAmt:e.target.value})
                            }}
                            fullWidth
                        />
                    </Col>*/}
                    <Col sm={12} lg={4} xl={4} md={4} >
                        <TextField
                            name="GrandTotal"
                            label="GrandTotal" 
                            value={this.state.GrandTotal}
                            onInput={this.toInputUppercase}
                            inputProps={{ style: { color: "green", fontWeight: 'bold', fontSize: '20px' } }}
                            onChange={e=>this.setState({GrandTotal:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    </Row>
                  
                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                    <Col style={{textAlign: "right", marginTop: "1%"}} >
                             {/* <Link id="btnGetItems" className="btn btn-primary rounded-pill" style={{border: "none"}} to={'#'} onClick={this.Show_Min_Stock_Modal}> <ShowChart /><u>Get Items from Purchase Invoice</u></Link> */}
                            {this.state.btn_edit && (<button type="button" className="btn btn-primary rounded-pill"  style={{border: "none"}} onClick={this.SaveHandler}  disabled={this.state.btn_Disabled}>
                            {
                             this.state.btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                                }  </button>)}
                            <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{border: "none"}} to={'/PurchaseReturnList'} > <Cancel /> <u>Close</u></Link>
                            {this.state.btn_edit && ( <button 
                             className="btn btn-primary rounded-pill" 
                             style={{border: "none"}} 
                             disabled={this.state.btn_Disabled}
                             onClick={e =>{
                                var obj = {
                                    PurchaseInvoiceID:this.state.PurchaseInvoiceID,
                                    PurchaseDate:this.state.PurchaseDate,
                                    Discription:this.state.Discription,
                                    TotalDisAmt:this.state.TotalDisAmt,
                                    TotalTaxAmt:this.state.TotalTaxAmt,
                                    TotalQty:this.state.TotalQty,
                                    GrandTotal:this.state.GrandTotal,
                                    PurchaseValue:this.state.PurchaseValue,
                                    PurchaseInvoiceNo: this.state.PurchaseInvoiceNo,
                                    Supplier: this.state.SelectedSupplier,
                                    Warehouse: this.state.SelectedWareHouse,
                                    PaymentType: this.state.SelectedPaymentType,
                                    Detail: this.state.PI_Detail_Data,
                                    StockType: this.state.SelectedStockType,
                                    WHT: this.state.WHT,
                                    WHTAmt:this.state.WHTAmt,
                                    AddvTaxPer: this.state.AddvTaxPer,
                                    AddvTaxAmt:this.state.AddvTaxAmt

                                }
                                localStorage.setItem('token',sessionStorage.getItem('token'))

                                localStorage.setItem("SaleReturn", JSON.stringify(obj))

                                this.SaveHandler(true)
                             }}
                             >
                               {
                             this.state.btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <> <Print /> <u>Save &amp; Print</u></>
                                }  
                                
                                </button>)}
                        </Col>
                    </Row>
                    <PurchaseReturnModal 
                        show={this.state.Stock_Modal}
                        onHide = {this.Hide_Min_Stock_Modal}
                        mSaveStock = {this.LoadStockToGrid}
                        SupplierID={this.state.SelectedSupplier && this.state.SelectedSupplier.SupplierID}
                    />
                    <Loader
                    show={this.state.IsLoader}
                    hide={this.hideLoader}
                    />
                </Card>
            
        </>, document.getElementById('PageContents')
    )
}

}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    isAddNew: state.PI && state.PI.isAddNew,
    PurchaseInvoiceID:state.PI && state.PI.PurchaseInvoiceID,
    Project_ID: state.PI && state.PI.Project_ID,
    show_screen: state.SCREEN
})
export default connect(mapStateToProps)(PurchaseReturn)