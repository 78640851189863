import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Row, Col, Card } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Save, Cancel } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { SelectManufacturedByList } from "../../Services/manufactureAPI";
import { connect } from "react-redux";
import { SelectCompanyGroupAgainstManufacturer } from "../../Services/CompanyGroupAPI";
import { SaveCompanyOpening, getSingleCompanyOpening } from "../../Services/OpeningAPI";

const CompanyOpeningBalance = (props) => {
  const { Comp_Id, ProjectID, isAddNew, COID } = props;
  const [companyList, setcompanyList] = useState([]);
  const [selectedCompany, setselectedCompany] = useState(null);
  const [address, setAddress] = useState("");
  const [groupList, setgroupList] = useState([]);
  const [selectedGroup, setselectedGroup] = useState(null);
  const [debitBalance, setdebitBalance] = useState(0);
  const [creditBalance, setcreditBalance] = useState(0);
  const [err, setErr] = useState({
    companyErr: false,
    groupErr: false,
    creditErr: false,
    debitErr: false,
  });
  useEffect(() => {
    SelectManufacturedByList(Comp_Id, ProjectID, (manufacturer) => {
      setcompanyList(manufacturer);
      if(isAddNew){

      }else{
        getSingleCompanyOpening(COID, (mData)=>{
          const selectedManufacturer = manufacturer.find(x=> x.ManufacturedByID === mData[0].ManufacturedByID)
          SelectCompanyGroupAgainstManufacturer(
            Comp_Id,
            ProjectID,
            mData[0]?.ManufacturedByID,
            (mCG) => {
              mCG.unshift({CGID: 0, CGName: 'SELECT ALL'})
              const selectedCG = mCG.find(x=> x.CGID === mData[0]?.CGID)
              setgroupList(mCG)
              setselectedGroup(selectedCG)
            });
          setselectedCompany(selectedManufacturer)
          setAddress(mData[0].Address)
          setdebitBalance(mData[0].Debit)
          setcreditBalance(mData[0].Credit)
        })
      }
    });
  }, [Comp_Id, ProjectID]);
  const validate = () => {
    let isValid = true;
    if (!selectedCompany) {
      setErr({ ...err, companyErr: true });
      isValid = false;
      return isValid;
    }
    if (!selectedGroup) {
      setErr({ ...err, groupErr: true });
      isValid = false;
      return isValid;
    }
    if (debitBalance < 0) {
      setErr({ ...err, debitErr: true });
      isValid = false;
      return isValid;
    }
    if (creditBalance < 0) {
      setErr({ ...err, creditErr: true });
      isValid = false;
      return isValid;
    }
    setErr({
      companyErr: false,
      groupErr: false,
      creditErr: false,
      debitErr: false,
    });
    return isValid;
  };

  const SaveHandler = () => {
    const isValid = validate();
    if (isValid) {
      const CompanyOpening ={
        COID: COID,
        ManufacturedByID: selectedCompany?.ManufacturedByID,
        CGID: selectedGroup?.CGID,
        Address: address,
        Debit: debitBalance,
        Credit: creditBalance,
        Comp_Id: Comp_Id,
        ProjectID: ProjectID,
      } 
      SaveCompanyOpening(CompanyOpening, isAddNew, (res)=>{
        document.getElementById("btnCancel").click();
      })
    }
  };
  return ReactDOM.createPortal(
    <>
      <Row className="row_margin_top row_margin_left row_margin_right">
        <Col lg={6} xl={6} md={6} className="header_title">
          <h2>Company Opening Balance</h2>
        </Col>
      </Row>

      <Card>
        <Row className="row_margin_top row_margin_left row_margin_right m-2 g-3">
          <Col sm={12} lg={6} xl={6} md={6}>
            <Autocomplete
              name="Company"
              id="Company"
              options={companyList}
              value={selectedCompany}
              getOptionLabel={(option) => option.ManufacturedBy ?? ""}
              onChange={(e, value) => {
                if (value) {
                  SelectCompanyGroupAgainstManufacturer(
                    Comp_Id,
                    ProjectID,
                    value.ManufacturedByID,
                    (companies) => {
                      companies.unshift({CGID: 0, CGName: 'SELECT ALL'})
                      setgroupList(companies);
                    }
                  );
                  setErr({ ...err, companyErr: false });
                  setselectedCompany(value);
                  setselectedGroup(null);
                } else {
                  setErr({ ...err, companyErr: true });
                  setgroupList([]);
                  setselectedCompany(null);
                  setselectedGroup(null);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="Company"
                  label="Company"
                  onError={err.companyErr}
                  required
                  helperText={
                    err.companyErr && (
                      <span className="text-danger">Company is required</span>
                    )
                  }
                />
              )}
            />
          </Col>
          <Col sm={12} lg={6} xl={6} md={6}>
            <Autocomplete
              name="CompanyGroups"
              id="CompanyGroups"
              required
              options={groupList}
              value={selectedGroup}
              getOptionLabel={(option) => option.CGName ?? ""}
              onChange={(e, value) => {
                if (value) {
                  setErr({ ...err, groupErr: false });
                  setselectedGroup(value);
                } else {
                  setselectedGroup(null);
                  setErr({ ...err, groupErr: true });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="CompanyGroups"
                  label="Company Groups"
                  onError={err.groupErr}
                  required
                  helperText={
                    err.groupErr && (
                      <span className="text-danger">
                        Company group is required
                      </span>
                    )
                  }
                />
              )}
            />
          </Col>
          <Col sm={12} lg={12} xl={12} md={12}>
            <TextField
              name="Address"
              label="Address"
              type="text"
              fullWidth
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Col>

          <Col sm={12} lg={6} xl={6} md={6}>
            <TextField
              name="DebitBalance"
              label="Debit Balance"
              type="number"
              required
              value={debitBalance}
              onChange={(e) => {
                setErr({ ...err, debitErr: false });
                setdebitBalance(e.target.value);
              }}
              fullWidth
              onError={err.debitErr}
              helperText={
                err.debitErr && (
                  <span className="text-danger">Debit Balance is required</span>
                )
              }
            />
          </Col>
          <Col sm={12} lg={6} xl={6} md={6}>
            <TextField
              name="CredtBalance"
              label="Credt Balance"
              required
              type="number"
              fullWidth
              value={creditBalance}
              onChange={(e) => {
                setErr({ ...err, creditErr: false });
                setcreditBalance(e.target.value);
              }}
              onError={err.creditErr}
              helperText={
                err.creditErr && (
                  <span className="text-danger">
                    Credit Balance is required
                  </span>
                )
              }
            />
          </Col>
        </Row>
        <Row className=" row_margin_top row_margin_left row_margin_right">
          <Col style={{ textAlign: "right", marginTop: "1%" }}>
            <button
              type="button"
              className="btn btn-primary rounded-pill"
              style={{ border: "none" }}
              onClick={SaveHandler}
            >
              {" "}
              <Save /> <u>Save</u>
            </button>
            <Link
              id="btnCancel"
              className="btn btn-primary rounded-pill"
              style={{ border: "none" }}
              to={"/company-opening"}
            >
              {" "}
              <Cancel /> <u>Close</u>
            </Link>
          </Col>
        </Row>
      </Card>
    </>,
    document.getElementById("PageContents")
  );
};
const mapStateToProps = (state) => ({
  Comp_Id:
    state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  p_userid: state.Login_User.User_Data && state.Login_User.User_Data.User_id,

  ProjectID:
    state.Global_Projects.GlobalProjects &&
    state.Global_Projects.GlobalProjects.Project_ID,
    isAddNew: state.CompanyOpening && state.CompanyOpening.isAddNew,
    COID: state.CompanyOpening && state.CompanyOpening.OPening_ID,
});
export default connect(mapStateToProps)(CompanyOpeningBalance);
