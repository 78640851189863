import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const rptExpense =(Comp_Id,ProjectID,FromDate,ToDate,Callback) =>{
  axios.get(`${domain}/Expense/rptExpense/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const rptExpenseClassificationWise =(Comp_Id,ProjectID,FromDate,ToDate,classificationID,Callback) =>{
    axios.get(`${domain}/Expense/rptExpenseClassificationWise/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${classificationID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  

  export const rptExpenseTypeWise =(Comp_Id,ProjectID,FromDate,ToDate,TypeID,Callback) =>{
    axios.get(`${domain}/Expense/rptExpenseTypeWise/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${TypeID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }