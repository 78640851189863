import React from 'react'
import ReactDOM from 'react-dom'
import {Row, Col,Card,Spinner} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {TextField, Popper} from '@material-ui/core'
import { Save, Cancel,ShowChart, Print} from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import { Autocomplete } from '@material-ui/lab';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
import { connect} from 'react-redux'
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import Loader from '../Loader';
import { GetCurrentDate,preventMinus } from '../../Services/Common'
import { SelectcustomersList } from "../../Services/CustomersAPI";
import { SelectWareHouseList } from "../../Services/WareHouseAPI";
import { SelectItemsWRTWareHouse,SelectBatchDetails} from '../../Services/StockTransferAPI'
// import { SelectItemDetails } from '../../Services/PurchaseOrderAPI'
import { GetMaxSaleOrderNo,SelectSaleOrderMaster,SelectSaleOrderDetail,SaveSaleOrder } from '../../Services/SaleOrderAPI'
//  import SaleOrderModal from './SaleOrderModal'
export class SaleOrderEntry extends React.Component {
  constructor(props){
      super(props)

      this.state = {
        SaleOrderNo: '',
          CustomerList: [],
          SelectedCustomer: null,
          SaleOrderDate: GetCurrentDate(),
          CreatedDate:GetCurrentDate(),
          WareHouseList: [],
          SelectedWareHouse: null,
          Batch_List:[],
          Selected_Batch:null,
          Item_List: [],
          Selected_item: null,
          Discription: '',
          GrandTotal:0,
          SO_Detail_Data: [],
          Supplier_Err: '',
        UOMID:0,
        // Modal 
        Stock_Modal : false,
        btn_edit : true,
         btn_Disabled:false,
         IsLoader:false
      }
      this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
      this.validationRules = { required: true };
      this.bg_Ref = React.createRef()
      this.customNameValidation = this.customNameValidation.bind(this)
      this.grid = null
      this.Comp_Id = this.props.Comp_Id && this.props.Comp_Id
      this.ProjectID = this.props.ProjectID
      this.UserID = this.props.p_userid
      this.IsAddNew = this.props.isAddNew
      this.SaleOderID = this.props.SaleOrderID
      this.NAmeValidation = {
        required: [this.customNameValidation, 'Name must be selected'],
      };
  }
 
  sortingOptions = {
    columns: [{ field: 'SrNo', direction: 'Ascending' }]
  };
  customNameValidation(args) {
      args.value=this.state.Selected_item ? this.state.Selected_item.ItemID : ''
    return getValue('value', args) != ''   
    }
    showLoader = () => {
        this.setState({IsLoader:true})
      }
      hideLoader = () => {
        this.setState({IsLoader:false})
      }
  componentDidMount(){

    document.title = " Distribution / Sale Order"
    SelectWareHouseList(this.Comp_Id,this.ProjectID,(mWareHouse)=>{
        this.setState({WareHouseList:mWareHouse,SelectedWareHouse:mWareHouse[0], btn_Disabled:false}) //auto selected warehouse
        if(mWareHouse.length > 0){
            this.Select_Item_Details(mWareHouse[0].WareHouseID) // for auto pick items from here 
        }
       

    SelectcustomersList(this.Comp_Id,this.ProjectID,(mCustomer)=>{
        this.setState({CustomerList:mCustomer})
        this.GetMax_SaleOrderNo()
        
        if(this.IsAddNew) // save
        {
            this.GetMax_SaleOrderNo() // new reciver NO genrate
        }
        else
        {
            this.showLoader()
            SelectSaleOrderMaster(this.Comp_Id,this.ProjectID, this.SaleOderID,(MasterData) =>{
                SelectSaleOrderDetail(this.Comp_Id,this.ProjectID, this.SaleOderID,(DetailData) =>{
                    this.hideLoader()
                    DetailData.map((value, index) =>{
                        value.SrNo = index + 1
                        value.SaleOrderID = this.SaleOderID
                    })   
                    this.setState({
                        SaleOrderNo: MasterData[0].SaleOrderNo,
                        SelectedCustomer: mCustomer.find(x =>x.CustomerID === MasterData[0].CustomerID),
                        SelectedWareHouse: mWareHouse.find(x => x.WareHouseID === MasterData[0].WareHouseID),
                        SaleOrderDate: MasterData[0].SaleOrderDate,
                        Discription: MasterData[0].Discription,
                        SO_Detail_Data: DetailData,
                        btn_edit: this.props.show_screen.edit
                    }, () =>{
                        this.Calculate_Values()
                    })
                    this.Select_Item_Details(MasterData[0].WareHouseID)
                    
                })
            })
        }
 
       })
    })
    
  }
  
  Select_Item_Details = (WareHouseID) =>{
    SelectItemsWRTWareHouse(this.Comp_Id,this.ProjectID,WareHouseID,(mItems)=>{ // Api
        this.setState({Item_List:mItems})
    })
  }
  Show_Min_Stock_Modal = ()=>{
      this.setState({Stock_Modal:true})
  }
  Hide_Min_Stock_Modal = ()=>{
    this.setState({Stock_Modal:false})
}
GetMax_SaleOrderNo = () => {
        this.showLoader()
        GetMaxSaleOrderNo(this.Comp_Id,this.ProjectID, (mSaleOrderNo) => { // API Function
            this.hideLoader()
            this.setState({ SaleOrderNo: mSaleOrderNo })  // set state here 
        })
    }
   
    BatchNO_Auto = null
    ItemDiscription = (rowValue) => {
        return (
            <Autocomplete
                name="ItemDiscription"
                id="ItemDiscription"
                defaultValue={this.state.Item_List.find(v => v.ItemDiscription === rowValue.ItemDiscription)}
                options={this.state.Item_List}
                getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : ""}
                onChange={(e, value) => {
                    if (value) {
                        
                        // var test = document.getElementById('BatchNo')
                        // 
                        this.setState({ Selected_item: value },()=>{
                            document.getElementById('UOM').value = value.uom
                            this.Select_Batch_Details(value.ItemID,this.state.SelectedWareHouse.WareHouseID)

                        })
                       
                    }
                    else {
                        this.setState({ Selected_item: null,Batch_List:[],Selected_Batch:null })
                        document.getElementById('UOM').value = ''
                       
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="ItemDiscription" />}
            />
        )
    }

    Select_Batch_Details = (ItemID, WareHouseID) =>{

        SelectBatchDetails(this.Comp_Id,this.ProjectID,ItemID,WareHouseID,(mItems)=>{ // Api
            if(mItems.length > 0){
                document.getElementById("SaleRate").value = mItems[0].PackSaleRate
            }
                this.setState({Batch_List:mItems}, () =>{
                    this.Calculate_Total()
                })
            
        })
    }
    Qty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="Qty"
            className="textbox"
            name='Qty'
            defaultValue={rowValue.Qty}
            onChange = { e => {
                this.Calculate_Total()
            }}
            min={1}
            onKeyPress={e=>preventMinus(e)}
        />)
    }
    UOM = (rowValue) => {

        return (<input
            id="UOM"
            name="UOM"
            style={{ textAlign: "right", width: "80%" }}
            defaultValue={rowValue.UOM}
            className="textbox"
            readOnly={true}
        />)

    }
    BatchNo = (rowValue) => {
        this.BatchNO_Auto = <Autocomplete
        name="BatchNo"
        id="BatchNo"
        defaultValue={this.state.Batch_List.find(v => v.batchno === rowValue.batchno)}
        options={this.state.Batch_List}
        getOptionLabel={(option) => option.batchno ? option.batchno : ""}
        ref={this.bg_Ref}
        onChange={(e, value) => {
            if (value) {
                this.setState({ Selected_Batch: value })
                document.getElementById('ExpairyDate').value = value.ExpairyDate
                document.getElementById('BQty').value = value.BQty
                document.getElementById('SaleRate').value = value.SaleRate
                document.getElementById('value').value = value.SaleRate
            }
            else {
                this.setState({ Selected_Batch: null })
                document.getElementById('ExpairyDate').value = ''
                document.getElementById('BQty').value = ''
                document.getElementById('SaleRate').value =  ''
                document.getElementById('value').value = ''

            }
        }}
        renderInput={(params) =>
            <TextField {...params} name="BatchNo" />}
    />

        return (
            this.BatchNO_Auto
        )
    }
    ExpairyDate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="text"
            id="ExpairyDate"
            defaultValue={rowValue.ExpairyDate}
            className="textbox"
            name="ExpairyDate"
            readOnly={true}

        />)
    }
    BQty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="text"
            id="BQty"
            defaultValue={rowValue.BQty}
            className="textbox"
            name="BQty"
            readOnly={true}
            
        />)
    }
    SaleRate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="SaleRate"
            defaultValue={rowValue.SaleRate}
            className="textbox"
            name="SaleRate"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={1}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    DisPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisPer"
            defaultValue={rowValue.DisPer}
            className="textbox"
            name="DisPer"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={0}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    DisAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisAmt"
            defaultValue={rowValue.DisAmt}
            className="textbox"
            name="DisAmt"
            readOnly={true}
            onChange = { e => {
                // this.Calculate_Total()
             }}
        />)
    }
    TaxPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxPer"
            defaultValue={rowValue.TaxPer}
            className="textbox"
            name="TaxPer"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={0}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    TaxAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxAmt"
            defaultValue={rowValue.TaxAmt}
            className="textbox"
            name="TaxAmt"
            readOnly={true}
            onChange = { e => {
                // this.Calculate_Total()
             }}
        />)
    }
    Value = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="value"
            defaultValue={rowValue.Value}
            className="textbox"
            name="Value"
            readOnly={true}
        />)
    }
    SaveHandler = (fromPrint) => {
        if(this.state.SelectedWareHouse && this.state.SelectedCustomer)
        {
            this.setState({Supplier_Err:''})
            if(this.state.SO_Detail_Data.length > 0)
            {
                var OrderData = {
                    SaleOrderID:this.SaleOderID,
                    Comp_Id:this.Comp_Id,
                    ProjectID:this.ProjectID,
                    UserID:this.UserID,
                    SaleOrderNo:this.state.SaleOrderNo,
                    CreatedDate:this.state.CreatedDate,
                    SaleOrderDate:this.state.SaleOrderDate,
                    WareHouseID:this.state.SelectedWareHouse.WareHouseID,
                    CustomerID:this.state.SelectedCustomer.CustomerID,
                    Discription:this.state.Discription,
                    GrandTotal:this.state.GrandTotal,
                }

                for(let i = 0; i< this.state.SO_Detail_Data.length; i++)
                {
                    if(typeof this.state.SO_Detail_Data[i].Qty === 'undefined' || this.state.SO_Detail_Data[i].Qty == '' || this.state.SO_Detail_Data[i].Qty == null )
                    this.state.SO_Detail_Data[i].Qty = 0
                    else if (typeof this.state.SO_Detail_Data[i].Qty === 'string')
                    this.state.SO_Detail_Data[i].Qty =  parseFloat(this.state.SO_Detail_Data[i].Qty)

                    if(typeof this.state.SO_Detail_Data[i].BQty === 'undefined' || this.state.SO_Detail_Data[i].BQty == '' || this.state.SO_Detail_Data[i].BQty == null )
                    this.state.SO_Detail_Data[i].BQty = 0
                    else if (typeof this.state.SO_Detail_Data[i].BQty === 'string')
                    this.state.SO_Detail_Data[i].BQty =  parseFloat(this.state.SO_Detail_Data[i].BQty)

                    if(typeof this.state.SO_Detail_Data[i].TaxPer === 'undefined' || this.state.SO_Detail_Data[i].TaxPer == '' || this.state.SO_Detail_Data[i].TaxPer == null )
                    this.state.SO_Detail_Data[i].TaxPer = 0
                    else if (typeof this.state.SO_Detail_Data[i].TaxPer === 'string')
                    this.state.SO_Detail_Data[i].TaxPer =  parseFloat(this.state.SO_Detail_Data[i].TaxPer)

                    if(typeof this.state.SO_Detail_Data[i].DisPer === 'undefined' || this.state.SO_Detail_Data[i].DisPer == '' || this.state.SO_Detail_Data[i].DisPer == null )
                    this.state.SO_Detail_Data[i].DisPer = 0
                    else if (typeof this.state.SO_Detail_Data[i].DisPer === 'string')
                    this.state.SO_Detail_Data[i].DisPer =  parseFloat(this.state.SO_Detail_Data[i].DisPer)

                    if(typeof this.state.SO_Detail_Data[i].TaxAmt === 'undefined' || this.state.SO_Detail_Data[i].TaxAmt == '' || this.state.SO_Detail_Data[i].TaxAmt == null )
                    this.state.SO_Detail_Data[i].TaxAmt = 0
                    else if (typeof this.state.SO_Detail_Data[i].TaxAmt === 'string')
                    this.state.SO_Detail_Data[i].TaxAmt =  parseFloat(this.state.SO_Detail_Data[i].TaxAmt)

                    if(typeof this.state.SO_Detail_Data[i].DisAmt === 'undefined' || this.state.SO_Detail_Data[i].DisAmt == '' || this.state.SO_Detail_Data[i].DisAmt == null )
                    this.state.SO_Detail_Data[i].DisAmt = 0
                    else if (typeof this.state.SO_Detail_Data[i].DisAmt === 'string')
                    this.state.SO_Detail_Data[i].DisAmt =  parseFloat(this.state.SO_Detail_Data[i].DisAmt)

                    if(typeof this.state.SO_Detail_Data[i].SaleRate === 'undefined' || this.state.SO_Detail_Data[i].SaleRate == '' || this.state.SO_Detail_Data[i].SaleRate == null )
                    this.state.SO_Detail_Data[i].SaleRate = 0
                    else if (typeof this.state.SO_Detail_Data[i].SaleRate === 'string')
                    this.state.SO_Detail_Data[i].SaleRate =  parseFloat(this.state.SO_Detail_Data[i].SaleRate)
                    
                    if(typeof this.state.SO_Detail_Data[i].Value === 'undefined' || this.state.SO_Detail_Data[i].Value == '' || this.state.SO_Detail_Data[i].Value == null )
                    this.state.SO_Detail_Data[i].Value = 0
                    else if (typeof this.state.SO_Detail_Data[i].Value === 'string')
                    this.state.SO_Detail_Data[i].Value =  parseFloat(this.state.SO_Detail_Data[i].Value)
                }
                this.setState({btn_Disabled:true})
                SaveSaleOrder(OrderData,this.state.SO_Detail_Data,this.IsAddNew,()=>{
                    if(fromPrint === true){
                        window.open('/SaleOrderReport','_blank')
                    }
                    this.setState({btn_Disabled:false})
                    document.getElementById('btnCancel').click()
                })
            }
            else
            { 
                this.setState({btn_Disabled:false})
                Swal.fire({
                    icon: 'info',
                    text: `Order Details Must be Entered...`,
                })
                return
            }
        }
        else
        {
            this.setState({Supplier_Err:'Supplier is required',btn_Disabled:false})
        }
    }
    beforeRowInsert = (rowValue) => {

        if(rowValue.requestType === 'save')
        {
            rowValue.data.ItemDiscription = this.state.Selected_item.ItemDiscription
            rowValue.data.ItemID = this.state.Selected_item.ItemID
            rowValue.data.UOMID = this.state.Selected_item.UOMID
            if(parseFloat(rowValue.data.Qty) > parseFloat(rowValue.data.BQty))
            {
                Swal.fire({
                    icon: 'info',
                    text: `Qty must be less than the Batch Qty : ${rowValue.data.BQty} Given...`,
                })
                this.grid.closeEdit()

            }
            if (rowValue.data.SaleOrderDetailID === undefined)
                rowValue.data.action = 'add'
            else
                rowValue.data.action = rowValue.action
        }
        else if ( rowValue.requestType === 'add' )
        {
            rowValue.data.SrNo = this.state.SO_Detail_Data.length + 1
            rowValue.data.Qty = 1
            rowValue.data.DisPer = 0.00
            rowValue.data.DisAmt = 0.00
            rowValue.data.TaxPer = 0.00
            rowValue.data.TaxAmt = 0.00

            this.setState({ Selected_item: null,UOMID:0 })
        }

    }
    afterRowInsert = (rowValue) => {

        if (rowValue.requestType == "beginEdit") {
            this.setState({ Selected_item: { 
                "ItemDiscription": rowValue.rowData.ItemDiscription, 
                "ItemID": rowValue.rowData.ItemID, UOMID: rowValue.rowData.UOMID } })
            
            this.Select_Batch_Details(rowValue.rowData.ItemID,this.state.SelectedWareHouse.WareHouseID)
            this.Calculate_Values()
        }
        if (rowValue.requestType == "save") {
            this.Calculate_Values();
        }
        if (rowValue.requestType == "delete") {
            this.Calculate_Values()
        }
    }

    Calculate_Total = () => {
        var Qty = 0, Rate = 0, DisPer = 0, TaxPer = 0, DisAmt = 0, TaxAmt = 0, Total_Value = 0;
        if (document.getElementById('Qty').value)
            Qty = typeof (document.getElementById('Qty').value) === 'string' ? parseFloat((document.getElementById('Qty').value).replace(/,/g, '')) : document.getElementById('Qty').value
        else if (document.getElementById('Qty').value == "")
            Qty = 0
        else
            Qty = parseFloat(document.getElementById('Qty').value)

        Rate = document.getElementById('SaleRate').value == "" ? 0 : parseFloat(document.getElementById('SaleRate').value)
        if(document.getElementById('DisPer')){
            DisPer = document.getElementById('DisPer').value == "" ? 0 : parseFloat(document.getElementById('DisPer').value)

        }
        else{
            DisPer = 0;
        }
        if(document.getElementById('TaxPer'))
        TaxPer = document.getElementById('TaxPer').value == "" ? 0 : parseFloat(document.getElementById('TaxPer').value)
        else
        TaxPer = 0

        DisAmt = parseFloat((( (Qty * Rate)/ 100) * DisPer).toFixed(2))
        TaxAmt = parseFloat((((Qty * Rate) / 100) * TaxPer).toFixed(2))
        Total_Value = parseFloat(((((Qty * Rate) - DisAmt) + TaxAmt)).toFixed(2))

        // DisAmt = parseFloat(((DisPer / 100) * Rate).toFixed(2))
        // TaxAmt = parseFloat(((TaxPer / 100) * Rate).toFixed(2))
        // Total_Value = parseFloat(((((Qty * Rate) - DisAmt) + TaxAmt)).toFixed(2))
        if(document.getElementById('DisAmt'))
        document.getElementById('DisAmt').value = DisAmt;

        if(document.getElementById('TaxAmt'))
        document.getElementById('TaxAmt').value = TaxAmt;

        document.getElementById('value').value = Total_Value;

    }
     Calculate_Values = () =>{
        var  _GrandTotal = 0
        for(let i = 0; i< this.state.SO_Detail_Data.length; i++)
        {
           if( typeof this.state.SO_Detail_Data[i].Value === 'string' )
           {
            
            this.state.SO_Detail_Data[i].Value = parseFloat((this.state.SO_Detail_Data[i].Value).replace(/,/g, ''))
           }
           else if (this.state.SO_Detail_Data[i].Qty == '' && typeof this.state.SO_Detail_Data[i].DisAmt === '' && typeof this.state.SO_Detail_Data[i].TaxAmt === '' && typeof this.state.SO_Detail_Data[i].SaleRate === '' && typeof this.state.SO_Detail_Data[i].value === '' )
           {
 
           this.state.SO_Detail_Data[i].Value = 0
           }
         
            _GrandTotal += parseFloat(this.state.SO_Detail_Data[i].Value)
        }
    
         this.setState({GrandTotal:_GrandTotal})
    }
    toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    LoadStockToGrid = mData =>{
        this.setState({ SO_Detail_Data: this.state.SO_Detail_Data.concat(mData) },()=>{
         var  mArray = [...this.state.SO_Detail_Data]
            mArray.map((value,index)=>{
                value.SrNo = index + 1
            })
            this.setState({mArray})
        })
        this.Hide_Min_Stock_Modal()
    }


render(){
        if(this.grid)
            this.grid.autoFitColumns(['SrNo','ItemDescription','dd_qty','Godown','Mnuit']);
        enableRipple(true);
    return ReactDOM.createPortal (
        <>
          <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2> Sale Order
                    </h2>
                </div>
            </div>
        </div>
            <Card>
           
            <Row className="row_margin_top row_margin_left row_margin_right p-4" >
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            name="SaleOrderNo"
                            label="Sale Order No" 
                            value={this.state.SaleOrderNo}
                            inputProps={
                                {readOnly: true}
                            }
                            fullWidth
                            />
                    </Col>

                    <Col lg={6} xl={6} md={6} >
                        <Autocomplete
                            name="CustomerName"
                            id="CustomerName"
                            options={this.state.CustomerList}
                            getOptionLabel={(option) => option.CustomerName ? option.CustomerName : ""}
                            value={this.state.SelectedCustomer}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedCustomer:value})
                              
                                }
                                else
                                {
                                    this.setState({SelectedCustomer:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="CustomerName" label="Customer"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col>
                    {/* <Col lg={3} xl={3} md={3} >
                        <Autocomplete
                            name="WareHouse"
                            id="WareHouse"
                            options={this.state.WareHouseList}
                            getOptionLabel={(option) => option.WareHouse ? option.WareHouse : ""}
                            value={this.state.SelectedWareHouse}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedWareHouse:value})
                                    this.Select_Item_Details(value.WareHouseID)
                                }
                                else
                                {
                                    this.setState({SelectedWareHouse:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="WareHouse" label="WareHouse"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col> */}
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            value={this.state.SaleOrderDate}
                            name="SaleOrderDate"
                            label="Order Date" 
                            type= 'date'
                            onChange= {e => this.setState({SaleOrderDate:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            name="GrandTotal"
                            label="Grand Total" 
                            value={this.state.GrandTotal}
                            inputProps={
                                {readOnly: true}
                            }
                            fullWidth
                            />
                    </Col>
                    <Col sm={12} lg={8} xl={8} md={8} >
                        <TextField
                            name="Discription"
                            label="Discription" 
                            value={this.state.Discription}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({Discription:e.target.value})}
                            fullWidth
                        />
                    </Col> 
                </Row>
             
                    
                <Row className="row_margin_top row_margin_left row_margin_right p-4 mt-2" >
                    <div style={{display:"flex",width:"100%",height:"100%",overflow:"hidden"}}> 
                        <div style={{flex:"1",width:"100%",height:"100%"}}>
                            <GridComponent dataSource={this.state.SO_Detail_Data} actionBegin={ this.beforeRowInsert} ref={g => this.grid = g} actionComplete={this.afterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: true, allowDeleting: true, allowAdding: true, newRowPosition:'Bottom'  }} sortSettings={this.sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={this.toolbarOptions}>
                            <ColumnsDirective>
                                <ColumnDirective width='120' field='SrNo' headerText='SrNo #' Format= "{0:n}" allowEditing={false} isIdentity={true}  textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                    <ColumnDirective width='250' field='ItemDiscription' headerText='ItemDiscription' editType= 'dropdownedit'  editTemplate={this.ItemDiscription}   edit={this.cbParams}   textAlign='Left'/> 
                                    <ColumnDirective width='130' field='UOM' headerText='UOM'  editTemplate={this.UOM} />
                                    {/* <ColumnDirective width='250' field='BatchNo' headerText='BatchNo' editType= 'dropdownedit'  editTemplate={this.BatchNo}   edit={this.cbParams}   textAlign='Left'/>  */}
                                    {/* <ColumnDirective width='170' field='ExpairyDate' headerText='Expiry Date'  editTemplate={this.ExpairyDate} textAlign='right'></ColumnDirective> */}
                                    {/* <ColumnDirective width='150' field='BQty' headerText='Batch Balance'   editTemplate={this.BQty} textAlign='right'></ColumnDirective> */}
                                    <ColumnDirective width='170' field='Qty' headerText='Qty' editTemplate={this.Qty} textAlign='right'/>
                                    <ColumnDirective width='150' field='SaleRate' headerText='SaleRate'   editTemplate={this.SaleRate} textAlign='right'></ColumnDirective>
                                   
                                    {/* <ColumnDirective width='150' field='DisPer' headerText='Dis%'   editTemplate={this.DisPer} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='DisAmt' headerText='DisAmt'   editTemplate={this.DisAmt} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='TaxPer' headerText='Tax%'   editTemplate={this.TaxPer} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='TaxAmt' headerText='TaxAmt'   editTemplate={this.TaxAmt} textAlign='right'></ColumnDirective> */}
                                    <ColumnDirective width='150' field='Value' headerText='Value'   editTemplate={this.Value} textAlign='right'></ColumnDirective>
                                    
                                    <ColumnDirective width='150' field='DisPer' defaultValue={0} visible={false}  textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='DisAmt' defaultValue={0} visible={false} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='TaxPer' defaultValue={0} visible={false} textAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='TaxAmt' defaultValue={0} visible={false}   textAlign='right'></ColumnDirective>
                                    <ColumnDirective field='ItemID'  visible={false}  ></ColumnDirective>
                                    <ColumnDirective field='SaleOrderDetailID' headerText='SaleOrderDetailID'  visible={false}></ColumnDirective>
                                    <ColumnDirective field='SaleOrderID' headerText='SaleOrderID'  width='150' hideAtMedia={true} ></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]}   />
                            </GridComponent>
                        </div>
                    </div> 
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                    <Col style={{textAlign: "right", marginTop: "1%"}} >
                    {/* <Link id="btnReStock" className="btn btn-primary" style={{border: "none"}} to={'#'} > <ShowChart /><u>Reorder. Stock</u></Link>
                             <Link id="btnStock" className="btn btn-primary" style={{border: "none"}} to={'#'} onClick={this.Show_Min_Stock_Modal}> <ShowChart /><u>Get Min. Stock</u></Link> */}
                            {this.state.btn_edit && (<button type="button" className="btn btn-primary rounded-pill"  style={{border: "none"}} onClick={this.SaveHandler}  disabled={this.state.btn_Disabled}>
                            {
                             this.state.btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                                }  </button>)}
                            <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{border: "none"}} to={'/SaleOrderList'} > <Cancel /> <u>Close</u></Link>
                            {this.state.btn_edit && ( <button
                             className="btn btn-primary rounded-pill" 
                             style={{border: "none"}} 
                             onClick={e =>{

                                var obj = {
                                    SaleOrderNo: this.state.SaleOrderNo,
                                    Supplier: this.state.SelectedCustomer,
                                    WareHouse: this.state.SelectedWareHouse,
                                    SaleOrderDate: this.state.SaleOrderDate,
                                    Discription: this.state.Discription,
                                    Detail: this.state.SO_Detail_Data
                                }

                                localStorage.setItem('token',sessionStorage.getItem('token'))
                                localStorage.setItem("SaleOrder", JSON.stringify(obj))

                                this.SaveHandler(true)
                             }}
                             disabled={this.state.btn_Disabled}
                             >  {
                                this.state.btn_Disabled ?
                                  (<><Spinner
                                       as="span"
                                       animation="grow"
                                       size="sm"
                                       role="status"
                                       aria-hidden="true"
                                       /> Saving... </>) : <><Print /> <u>Save &amp; Print</u></>
                                   }</button>)}
                        </Col>
                    </Row>
                    <Loader
                    show={this.state.IsLoader}
                    hide={this.hideLoader}
                    />
                    {/* <SaleOrderModal 
                        show={this.state.Stock_Modal}
                        onHide = {this.Hide_Min_Stock_Modal}
                        mSaveStock = {this.LoadStockToGrid}
                    /> */}
                </Card>
            
        </>, document.getElementById('PageContents')
    )
}

}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    isAddNew: state.SO && state.SO.isAddNew,
    SaleOrderID:state.SO && state.SO.SaleOrderID,
    show_screen: state.SCREEN,
    ProjectID: state.SO.ProjectID
})
export default connect(mapStateToProps)(SaleOrderEntry)