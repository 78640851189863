import React, { useState, useEffect } from 'react'
import { Modal, Card, Row, Col, Button ,Spinner } from "react-bootstrap"
import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { Save, Cancel } from "@material-ui/icons"
import { connect } from "react-redux"   
import { GetCurrentDate, GetFirstDateofCurrentMonth } from '../../../Services/Common'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { File_Download,msg } from '../../../Services/Common'
import { SelectManufacturedByList, SelectManufacturedByListForDSR } from '../../../Services/manufactureAPI'
import { SelectCustomers,SelectDSRData,SelectDSRDataForGlobalPharma,SelectDSRDataForShigan, SelectDSRCustomerDataForGlacxy, SelectDSRCustomerDataForSafron } from '../../../Services/CustomersAPI'
import { SelectItemDSRForMacalson,SelectItemDSRForShigan,SelectTransactionDSRForMacalson,SelectTransactionDSRForShaigan, SelectItemDSRForGlaxcy,SelectTransactionDSRForGlaxcy, getStockDSRDataForSafron } from '../../../Services/ItemsAPI'
import { SelectAreaDSRForShaigan, SelectAreaDSRForGlacxy } from '../../../Services/AreasAPI'
import { SelectMacolsonStockData, SelectGlaxcyStockData,SelectShiganStockData,SelectShiganSummaryData,SelectHighQData,GetVidaID, GetHQNUTRITIONID } from '../../../Services/OpeningAPI'
import Loader from '../../Loader';
import Swal from 'sweetalert2'
import JSZip from "jszip";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { SelectInvoiceDataForDSRSafron } from '../../../Services/SaleInvoiceAPI'


function DSRFiles({ show, onHide,Comp_Id,ProjectID }) {
 const [SelectedReport, setSelectedReport] = useState(null)
 const [Manufacturer_list,setManufacturer_List] = useState([])
 const [SelctedManufacturer, setSelectedManufacturer] = useState(null)
 const [FromDate, setFromDate] = useState(GetFirstDateofCurrentMonth())
 const [ToDate, setToDate] = useState(GetCurrentDate())
 const [IsLoader, setIsLoader] = useState(false)
 const zip = new JSZip();
    useEffect(() => {
        document.title = "Distribution / Company Group"
        SelectManufacturedBy_ListForDSR()
    }, [show])
    const SelectManufacturedBy_ListForDSR = ()=>{
        SelectManufacturedByListForDSR(Comp_Id,ProjectID,(mData)=>{
            setManufacturer_List(mData)
        })
    }
    const clearFields = () =>{
        onHide()
        setSelectedReport(null)
        setSelectedManufacturer(null)
        setFromDate(GetCurrentDate())
        setToDate(GetCurrentDate())
    }
    
    const SelectDSR_DataForMacolson = () =>{
        showLoader()
        SelectDSRData(Comp_Id,ProjectID,FromDate,ToDate,SelctedManufacturer.CompanyID,(mData)=>{
          SelectItemDSRForMacalson(Comp_Id,ProjectID,SelctedManufacturer.CompanyID,FromDate,ToDate,(mItemData)=>{
            SelectTransactionDSRForMacalson(Comp_Id,ProjectID,SelctedManufacturer.CompanyID,FromDate,ToDate,(mTran)=>{
                SelectMacolsonStockData(Comp_Id,ProjectID,SelctedManufacturer.CompanyID,FromDate,ToDate,(mStock)=>{
                    hideLoader()            
                    const folder =  zip.folder("MCOLSON")
                    const myData = mData.map(o => Object.values(o).join("#$#")).join('\n');
                    const ProductData = mItemData.map(o => Object.values(o).join("#$#")).join('\n');
                    const TranData = mTran.map(o => Object.values(o).join("#$#")).join('\n');
                    const StockData = mStock.map(o => Object.values(o).join("#$#")).join('\n');
                    folder.file("cust.txt",myData);
                    folder.file('prod.txt',ProductData)
                    folder.file('tran.txt',TranData)
                    folder.file('stock.txt',StockData)
                    zip.generateAsync({type:"blob"}).then(function(content) {
                    saveAs(content, "MCOLSON.zip");
                })
                
              });
            })
          
          })
          
        
        })
       }
       const Excel_Converstion = (mData) =>{
        const ws = XLSX.utils.json_to_sheet(mData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
        return data
       }
      
       const SelectDSR_DataForGlacxy = () =>{
        showLoader()
        SelectDSRCustomerDataForGlacxy(Comp_Id,ProjectID,FromDate,ToDate,SelctedManufacturer.CompanyID,(mData)=>{
            SelectItemDSRForGlaxcy(Comp_Id,ProjectID,SelctedManufacturer.CompanyID,FromDate,ToDate,(mItemData)=>{
                SelectTransactionDSRForGlaxcy(Comp_Id,ProjectID,SelctedManufacturer.CompanyID,FromDate,ToDate,(mTran)=>{
                    SelectGlaxcyStockData(Comp_Id,ProjectID,SelctedManufacturer.CompanyID,FromDate,ToDate,(mStock)=>{
                        SelectAreaDSRForGlacxy(Comp_Id,ProjectID,SelctedManufacturer.CompanyID,FromDate,ToDate,(mArea)=>{
                            hideLoader()
                            const folder =  zip.folder("Glacxy")
                            // this will be used for text files
                            const myData = mData.map(o => Object.values(o).join("#$#")).join('\n');
                            const ProductData = mItemData.map(o => Object.values(o).join("#$#")).join('\n');
                            const TranData = mTran.map(o => Object.values(o).join("#$#")).join('\n');
                            const StockData = mStock.map(o => Object.values(o).join("#$#")).join('\n');
                            const AreaData = mArea.map(o => Object.values(o).join("#$#")).join('\n');
                            // For Excel Files
                            const data = Excel_Converstion(mData)
                            const ProData = Excel_Converstion(mItemData)
                            const TraData = Excel_Converstion(mTran)
                            const SData = Excel_Converstion(mStock)
                            const AData = Excel_Converstion(mArea)
                            folder.file("cust.xlsx",data);
                            folder.file("prod.xlsx",ProData);
                            folder.file("tran.xlsx",TraData);
                            folder.file("stock.xlsx",SData);
                            folder.file("area.xlsx",AData);
                            // For text Files
                            folder.file("cust.txt",myData);
                            folder.file('prod.txt',ProductData)
                            folder.file('tran.txt',TranData)
                            folder.file('stock.txt',StockData)
                            folder.file('area.txt',AreaData)
                            zip.generateAsync({type:"blob"}).then(function(content) {
                            saveAs(content, "Glacxy.zip");
                        })
                    })
                    })
                })
            })
        })
      }
const SelectDSR_DataForShiganPharma = () =>{
    showLoader()
    SelectDSRDataForShigan(Comp_Id,ProjectID,FromDate,ToDate,SelctedManufacturer.CompanyID,(mCustomer)=>{
        SelectItemDSRForShigan(Comp_Id,ProjectID,SelctedManufacturer.CompanyID,FromDate,ToDate,(mItems)=>{  
            SelectAreaDSRForShaigan(Comp_Id,ProjectID,SelctedManufacturer.CompanyID,FromDate,ToDate,(mArea)=>{
                SelectTransactionDSRForShaigan(Comp_Id,ProjectID,SelctedManufacturer.CompanyID,FromDate,ToDate,(mTran)=>{ 
                    SelectShiganStockData(Comp_Id,ProjectID,SelctedManufacturer.CompanyID,FromDate,ToDate,(mStock)=>{
                        SelectShiganSummaryData(Comp_Id,ProjectID,SelctedManufacturer.CompanyID,FromDate,ToDate,(mSummary)=>{
                            hideLoader()
                            const folder =  zip.folder("SHAIGAN")
                            const myCus = mCustomer.map(o => Object.values(o).join("$SPL$")).join('\n');
                            const ProductData = mItems.map(o => Object.values(o).join("$SPL$")).join('\n');
                            const AreaData = mArea.map(o => Object.values(o).join("$SPL$")).join('\n');
                            const TranData = mTran.map(o => Object.values(o).join("$SPL$")).join('\n');
                            const StockData = mStock.map(o => Object.values(o).join("$SPL$")).join('\n');
                            const SumaryData = mSummary.map(o => Object.values(o).join("$SPL$")).join('\n');
                            folder.file("BPCust.txt",myCus);
                            folder.file('BPProd.txt',ProductData)
                            folder.file('BPTran.txt',TranData)
                            folder.file('BPStock.txt',StockData)
                            folder.file('BPBrick.txt',AreaData)
                            folder.file('BPValue.txt',SumaryData)
                            zip.generateAsync({type:"blob"}).then(function(content) {
                            saveAs(content, "SHAIGAN.zip");
                            })
                        })
                    })

                })
            })        
        })

    })

}
const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    const [day, month, year] = new Date(dateString).toLocaleDateString('en-GB', options).split(' ');
    return `${day}-${month}-${year}`;
};

const calculateClosingQty = (arr) => {
    for (let i = 0; i < arr.length; i++) {
        const ClsQty = parseFloat(arr[i].OPQty || 0) + parseFloat(arr[i].PurchaseUnits || 0) +  parseFloat(arr[i].SRUnits || 0) - parseFloat(arr[i].PRUnits || 0)  - parseFloat(arr[i].SaleUnits || 0);
        arr[i].ClsQty = ClsQty;
    }
    return arr;
}

const SelectDSR_HighQANDVida = () =>{
     showLoader()
    GetVidaID(Comp_Id,ProjectID,(mID)=>{
        GetHQNUTRITIONID(Comp_Id,ProjectID,(mNutID)=>{
            SelectHighQData(Comp_Id,ProjectID,SelctedManufacturer.CompanyID,FromDate,ToDate,SelctedManufacturer.DsCode.split(",")[0],(mHighQ)=>{
                SelectHighQData(Comp_Id,ProjectID,mID[0].CompanyID,FromDate,ToDate,SelctedManufacturer.DsCode.split(",")[1],(mVida)=>{
                    SelectHighQData(Comp_Id,ProjectID,mNutID[0].CompanyID,FromDate,ToDate,SelctedManufacturer.DsCode.split(",")[2], (mNut)=>{
                        hideLoader()
                        const folder =  zip.folder("HIGHQ-VIDA-NUT")
                      
                        const formatData = (data) => data.map(o => Object.values(o).map(val => val === FromDate || val === ToDate ? formatDate(val) : val).join("^")).join('\n');
                        const updateHighQdData = calculateClosingQty(mHighQ);
                        const HighQ = formatData(updateHighQdData);
                        const updateVidadData = calculateClosingQty(mVida);
                        const Vida = formatData(updateVidadData);
                        const updateNuTdData = calculateClosingQty(mNut);
                        const Nutrition = formatData(updateNuTdData);
                        folder.file(`${SelctedManufacturer.DsCode.split(",")[0]}MSSR.txt`,HighQ);
                        folder.file(`${SelctedManufacturer.DsCode.split(",")[1]}MSSR.txt`,Vida);
                        const filename = SelctedManufacturer.DsCode.split(",")[2].trim();
                        folder.file(`${filename}MSSR.txt`,Nutrition)
                          zip.generateAsync({type:"blob"}).then(function(content) {
                              saveAs(content, "HIGHQ-VIDA-NUT.zip");
                              })
                    })
                  
                })
                })
        })
        
    })
   
}
const SelectDSRData_Safron = () => {
    showLoader();
    SelectDSRCustomerDataForSafron(SelctedManufacturer.CompanyID, Comp_Id, ProjectID, FromDate, ToDate, (mCustomers) => {
      SelectInvoiceDataForDSRSafron(FromDate, ToDate, Comp_Id, ProjectID, SelctedManufacturer.CompanyID, (mInvoice) => {
        getStockDSRDataForSafron(Comp_Id, ProjectID, SelctedManufacturer.CompanyID, FromDate, ToDate, (mStock) => {
          hideLoader();
  
          const allData = [...mCustomers, ...mInvoice, ...mStock];
  
          // Determine the maximum width for each column
          const columnWidths = allData.reduce((widths, row) => {
            Object.keys(row).forEach(key => {
              const value = row[key] !== undefined ? row[key].toString() : "";
              widths[key] = Math.max(widths[key] || 0, value.length);
            });
            return widths;
          }, {});
  
          const formatRow = (row, columnWidths) => {
            return Object.keys(row)
              .map(key => {
                const value = row[key] !== undefined ? row[key].toString() : "";
                return value.padEnd(columnWidths[key] + 2, ' '); // Adding padding to each column width
              })
              .join('');
          };
  
          const generateTextFileContent = (data, columnWidths) => {
            return data.map(row => formatRow(row, columnWidths)).join('\n');
          };
  
          const customerData = generateTextFileContent(mCustomers, columnWidths);
          const invoiceData = generateTextFileContent(mInvoice, columnWidths);
          const stockData = generateTextFileContent(mStock, columnWidths);
  
          const zip = new JSZip();
          const folder = zip.folder(`${mCustomers[0].code}SAS`);
  
          folder.file(`${mCustomers[0].code}CUST.txt`, customerData);
          folder.file(`${mCustomers[0].code}INV.txt`, invoiceData);
          folder.file(`${mCustomers[0].code}STOCK.txt`, stockData);
  
          zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, `${mCustomers[0].code}SAS`);
          });
        });
      });
    });
  };
  
  
  
    const Download_Handler = () =>{
    
    if(SelctedManufacturer){
        if(SelctedManufacturer.Company === 'MCOLSON RESERCH LABORATORIES'){
            SelectDSR_DataForMacolson()
        }else if(SelctedManufacturer.Company === 'GLAXY PHARMA PVT LTD'){
            SelectDSR_DataForGlacxy()
        }
        else if(SelctedManufacturer.Company === 'SHAIGAN PHARMA'){
            SelectDSR_DataForShiganPharma()
        }
        else if(SelctedManufacturer.Company === 'HIGH Q PHARMA'){
            SelectDSR_HighQANDVida()
        }
        else if(SelctedManufacturer.Company === 'SAFFRON PHARMA'){
            SelectDSRData_Safron()
        }
        else{
            msg(`Data Not Found For This Company`)
        }

    }
    }
    const showLoader = () => {
        setIsLoader(true)
      }
      const  hideLoader = () => {
        setIsLoader(false)
        }
    return (    
        <>
            <Modal show={show} size="lg" centered onHide={clearFields} backdrop="static" >
                <Modal.Header closeButton className="header_title " >
                    <Modal.Title > DSR Files </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Card style={{borderRadius:'10px', boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}>
                        <Row className=" row_margin_top row_margin_left row_margin_right p-2" >
                     
                    <Col lg={12} xl={12} md={12} >
                    <Autocomplete
                        error
                        name="ManufacturedBy"
                        id="ManufacturedBy"
                        options={Manufacturer_list}
                        getOptionLabel={(option) => option.Company ? option.Company : ""}
                        value={SelctedManufacturer}
                        onChange={(e, value) => {
                            if (value)
                            {
                                
                                setSelectedManufacturer(value)
                            }
                            else
                            {
                                setSelectedManufacturer(null) 
                            }
                        }}
                        renderInput={(params) =>
                            <TextField {...params} name="ManufacturedBy" label="Manufacturer"
                               error = {!SelctedManufacturer}
                                fullWidth
                                helperText={!SelctedManufacturer ? 'Required' : ''}
                            />}
                            fullWidth
                    />
                </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right p-2" >
                        <Col sm={6} lg={6} xl={6} md={6} >
                        <TextField 
                            value={FromDate}
                            name="FromDate"
                            label="From Date" 
                            type= 'date'
                            onChange= {e => setFromDate(e.target.value)}
                            fullWidth
                        />
                    </Col>
                    <Col sm={6} lg={6} xl={6} md={6} >
                    <TextField 
                        value={ToDate}
                        name="ToDate"
                        label="To Date" 
                        type= 'date'
                        onChange= {e => setToDate(e.target.value)}
                        fullWidth
                    />
                </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} className='m-2' >
                                <Button variant="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={Download_Handler} >
                              <CloudDownloadIcon />  Download
                                </Button>
                                <Button variant="btn btn-primary rounded-pill"  style={{ border: "none" }} onClick={clearFields} > <Cancel /> <u>Cancel</u></Button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
            </Modal>
            <Loader
            show={IsLoader}
            hide={hideLoader}
            />
        </>
    )
}
const mapStateToProps = state => ({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    show_screen: state.SCREEN,    
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
})

export default connect(mapStateToProps)(DSRFiles)
