import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const GetMaxClaimNo =(Comp_Id,ProjectID,Type,Callback) =>{
  
    axios.get(`${domain}/Claim/GetMaxClaimNo/${Comp_Id}/${ProjectID}/${Type}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }

export const SelectClaimDropDowns = async(Comp_Id,ProjectID) =>{
    try{    
        const response = await axios.get(`${domain}/Claim/SelectClaimDropDowns/${Comp_Id}/${ProjectID}`)
        console.log(response)
        return { data: response.data.Data, status:true}
    }catch(err){
        return { data:[], status: false}
    }
}

export const SelectMCPDD = async(Comp_Id,ProjectID) =>{
    try{    
        const response = await axios.get(`${domain}/Claim/SelectMCPDD/${Comp_Id}/${ProjectID}`)
        return { data: response.data.Data, status:true}
    }catch(err){
        return { data:[], status: false}
    }
}

export const CreateManagerPortal =  async(CGOBJ, PGOBJ, User_Manufacturer,UserObj, UserBranches, UserProjects,UserYear) =>{
    try{
        const mResponse = await axios.post(`${domain}/Claim/CreateManagerPortal`, {
            CGOBJ,
            PGOBJ, 
            User_Manufacturer,
            UserObj, 
            UserBranches,
            UserProjects,
            UserYear
        })
        return {Res: mResponse, isError: false}
    }catch(err){
        return {Res: err, isError: true}
    }
}
export const SelectUsersListForManagerPortal = async(Comp_Id,ProjectID) =>{
    try{    
        const response = await axios.get(`${domain}/Claim/SelectUsersListForManagerPortal/${Comp_Id}/${ProjectID}`)
        return { data: response.data.Data, status:true}
    }catch(err){
        return { data:[], status: false}
    }
}
export const SelectUserForEdit = async(UserID) =>{
    try{    
        const response = await axios.get(`${domain}/Claim/SelectUserForEdit/${UserID}`)
        return { data: response.data.Data, status:true}
    }catch(err){
        return { data:[], status: false}
    }
}
export const GetUserManufacturer = async(UserID) =>{
    try{    
        const response = await axios.get(`${domain}/Claim/GetUserManufacturer/${UserID}`)
        return { data: response.data.Data, status:true}
    }catch(err){
        return { data:[], status: false}
    }
}
export const GetUserCG = async(UserID) =>{
    try{    
        const response = await axios.get(`${domain}/Claim/GetUserCG/${UserID}`)
        return { data: response.data.Data, status:true}
    }catch(err){
        return { data:[], status: false}
    }
}

export const GetUserPG = async(UserID) =>{
    try{    
        const response = await axios.get(`${domain}/Claim/GetUserPG/${UserID}`)
        return { data: response.data.Data, status:true}
    }catch(err){
        return { data:[], status: false}
    }
}
 export const SelectClaimPolicyList = async (Comp_Id, ProjectID, Year, Month,Type)=>{
    try{    
        const response = await axios.get(`${domain}/Claim/SelectClaimPolicyList/${Comp_Id}/${ProjectID}/${Year}/${Month}/${Type}`)
        return { data: response.data.Data, status:true}
    }catch(err){
        return { data:[], status: false}
    }
 }