import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Row, Col, Card,Button } from 'react-bootstrap'
import { Add, Edit, Print } from '@material-ui/icons'
import {TextField, Popper} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import  { connect } from 'react-redux'
import { File_Download, GetCurrentDate } from '../../Services/Common';
import  { SelectitemmaintypeList } from '../../Services/ItemMainTypeAPI'
import  { SelectItemsList, SelectItemsForExcel} from '../../Services/ItemsAPI'
import  { set_Medicine, SHOW_SCREEN } from '../../React_Redux/Actions'
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import Loader from '../Loader';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
function ItemsList(props) {

    const {  set_Medicine,ItemCat ,Comp_Id,ProjectID, SHOW_SCREEN, Group_Id } = props;
    
    // const { set_Medicine,ItemCat  } = props;
    const [Item_Data, setItem_Data] = useState([])
     const [Cat_List, setCat_List] = useState([])
    const [Selected_Cat, setSelected_Cat] = useState(null)
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
  const [ReportLink, setReportLink] = useState({to:'#'})
  const [IsLoader, setIsLoader] = useState(false)
   
  useEffect(()=>{
      document.title = " Distribution / Items"
      SelectGroupScreenAccess(Group_Id,11,91,(data)=>{
            
        setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
        setBtn_delete(parseInt(data.Del) === 0 ? false : true)
  
        SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
        parseInt(data.Edit) === 0 ? false : true,
        parseInt(data.Del) === 0 ? false : true,
        data.OtherAccess
        )
        
    })
    Select_itemmaintypeList()
    Select_Items_List(0)
        if(ItemCat)
        {
            Select_Items_List(ItemCat.ItemMainTypeID)
            setSelected_Cat(ItemCat)
            set_Medicine(null,0,false)
        }
    },[])

    const column_Items = [
        {
         name: "ItemCode",
         label: "Item Code",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
        {
         name: "ItemDiscription",
         label: "Item Discription",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
          name: "ManufacturedBy",
          label: "Manufactured By",
          options: {
           filter: true,
           sort: true,
         customHeadLabelRender: (columnMeta)=>{
             return(
               <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
             )
            }, 
           }
         },
        {
         name: "StockQty",
         label: "Stock Qty",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },
         }
        },
         {
          name: "action",
          label: "action",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) => {
             
             var ItemID = Item_Data
             if (ItemID[rowIndex.rowIndex] != null)
               return (
                 <Link to={"/ItemEntry"} onClick={e => edit_Item(Item_Data[rowIndex.rowIndex].ItemID)}> <Edit /></Link>
               );
           }
          }
         }
    ];
    const edit_Item = (ItemID) =>{
      
        set_Medicine(Selected_Cat,ItemID,false)
    }
    const add_Item = () =>{
        set_Medicine(Selected_Cat,0,true)
    }
    const Select_itemmaintypeList = ()=>{
        SelectitemmaintypeList(Comp_Id,ProjectID,(mData)=>{
          if(mData.length > 0){
            var FirstIndex = {
              ItemMainType : 'SELECT ALL',
              ItemMainTypeID: 0
            }
            mData.splice(0,0,FirstIndex)
            setSelected_Cat(FirstIndex)
           
            setCat_List(mData)
          }
        })
    }

   const Select_Items_List = (ItemMainTypeID) =>{
    showLoader()
    SelectItemsList(Comp_Id,ProjectID,ItemMainTypeID,(mData)=>{
      hideLoader()  
      setItem_Data(mData)
    })
   }
   
   const showLoader = () => {
    setIsLoader(true)
   
  }
const  hideLoader = () => {
  setIsLoader(false)
  }
   const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });
    return ReactDOM.createPortal(
      <>
        <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2>Items
                    </h2>
                </div>
            </div>
        </div>
      <Card>
        
          <Row className="row_margin_top row_margin_left row_margin_right pt-4 pl-4 pr-4" >

                    <Col lg={3} xl={3} md={3} >
                        <Autocomplete
                            name="Cat"
                            id="Cat"
                            options={Cat_List}
                            getOptionLabel={(option) => option.ItemMainType ? option.ItemMainType : ""}
                            value={Selected_Cat}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    setSelected_Cat(value)
                                    Select_Items_List(value.ItemMainTypeID)
                                }
                                else
                                {
                                    setSelected_Cat(null)
                                    setItem_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Cat" label="ItemMainType"
                                />}
                        />
                    </Col>

            <Col lg={9} xl={9} md={9} style={{ textAlign: "right" }} >
              { btn_add && (<Link type="button" className="btn btn-primary rounded-pill" to="/ItemEntry" onClick={add_Item} style={{ border: "none" }} > <Add />  Create New</Link>)}
              <Link type="button" className="btn btn-primary rounded-pill" to="/ItemsReport" target={"_blank"} onClick={e=>{
                      localStorage.setItem('token',sessionStorage.getItem('token'))
                      localStorage.setItem("ItemsData",JSON.stringify(Item_Data))
                  }} style={{ border: "none" }} > <Print />  Print</Link>
                  <Button  type="button" className="btn btn-primary rounded-pill" onClick={e=>{
                    SelectItemsForExcel(Comp_Id,ProjectID,(apiData)=>{
                      const ws = XLSX.utils.json_to_sheet(apiData);
                      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                      const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
                      FileSaver.saveAs(data, `Items - ${GetCurrentDate()}`  +  ".xlsx");
                    })
                  
                  }} >Download To Excel</Button>
                
            </Col>
          </Row>
          <Row className=" row_margin_left row_margin_right" >
              <Col>              
                <div className="table-responsive" >
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        name = "IPD_Datatables"
                        data={Item_Data}
                        columns={column_Items}
                        options={{
                          selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                    </MuiThemeProvider>
                </div>
              </Col>
          </Row>
          <Loader
        show={IsLoader}
        hide={hideLoader}
        />
      </Card>
                        
      </>  , document.getElementById('PageContents')
    )
}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  ItemCat: state.Items && state.Items.ItemData,
  Group_Id : state.Login_User.User_Data.Group_Id,
  ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID


})
//  export default ItemsList
 export default connect(mapStateToProps,{set_Medicine, SHOW_SCREEN})(ItemsList)
