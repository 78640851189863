import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col,Form } from "react-bootstrap";
import ReactDOM from 'react-dom'
import { Checkbox, Card ,FormControlLabel } from '@material-ui/core' 
import { Save, Cancel } from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'
import { Link } from "react-router-dom";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { connect } from "react-redux"

import { SelectGroupScreensList, SaveGroupModuleScreens } from '../../Services/SecurityAPI'
import { setUserAccessData } from "../../React_Redux/Actions"

function UserScreens({show, onHide,CompID,Group_Id,Mod_Id,IsAddNew, setUserAccessData}) {

    const [ScreenAccessData, setScreenAccessData] = useState([])
    const [Select_All_chkbox, setSelect_All_chkbox] = useState(false);
  useEffect(() => {
    if(!IsAddNew)
    {
      
      SelectGroupScreensList(Group_Id,Mod_Id,(mscreenData)=>{
          
          mscreenData.map((screen,index)=>{
              if(screen.Allow_Access == 1)
              {
                screen.Allow_Access  = true
              }
              else
              { 
                screen.Allow_Access  = false
                screen.Adddisabled = true
                screen.editdisabled = true
                screen.deldisabled = true
              }
              if(screen.addnew == 1)
              {
                screen.addnew  = true
              }
              else
              {
                screen.addnew  = false
              }
              if(screen.edit == 1)
              {
                screen.edit  = true
              }
              else
              {
                screen.edit  = false
              }
              if(screen.del == 1)
              {
                screen.del  = true
              }
              else
              {
                screen.del  = false
              }
          })
          setScreenAccessData(mscreenData)
      })
    } 
  }, [])
  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });
  document.title = "Distribution / Users Access"
    const columns = [
        {
         name: "Screen_Id",
         label: "Screen ID",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "12px"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) =>{
            const _ScreenAccessData = ScreenAccessData;
            if(_ScreenAccessData[rowIndex.rowIndex] != null)
            {
                return(
                  <div style={{padding:"0px"}}>
                    {
                      _ScreenAccessData[rowIndex.rowIndex].Screen_Id
                    }
                  </div>
                )      
            }
         }
         }
        },
        {
         name: "Screen_Name",
         label: "Screen Name",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "12px"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) =>{
            const _ScreenAccessData = ScreenAccessData;
            if(_ScreenAccessData[rowIndex.rowIndex] != null)
            {
                return(
                  <div style={{padding:"0px"}}>
                    {
                      _ScreenAccessData[rowIndex.rowIndex].Screen_Name
                    }
                  </div>
                )      
            }
         }
         }
        },
        {
            name: "Allow_Access",
            label: "Access",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "12px"}} >{columnMeta.label}</div>
               )
              },
              customBodyRender: (dataIndex, rowIndex) =>{
              
                const _ScreenAccessData = ScreenAccessData;
                if(_ScreenAccessData[rowIndex.rowIndex] != null)
                {
                    return(
                        <Checkbox
                            checked={_ScreenAccessData[rowIndex.rowIndex].Allow_Access}
                            id={'Allow_Access'+rowIndex.rowIndex}
                            onChange={e => {
                                let newScreenAccessData = [...ScreenAccessData]
                                newScreenAccessData[rowIndex.rowIndex].Allow_Access = e.target.checked 
                               setScreenAccessData(newScreenAccessData)
                               AllowAccess(e.target.checked , rowIndex.rowIndex)
                            }}
                            style={{
                              padding:"0px"
                            }}
                           // name="Access"
                            color="primary"
                        />
                    )      
                }
             }
            }
        },
        {
            name: "addnew",
            label: "Add New",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "12px"}} >{columnMeta.label}</div>
               )
              },
              customBodyRender: (dataIndex, rowIndex) =>{
              
                const _ScreenAccessData = ScreenAccessData;
                if(_ScreenAccessData[rowIndex.rowIndex] != null)
                {
                    return(
                        <Checkbox
                            checked={_ScreenAccessData[rowIndex.rowIndex].addnew}
                            disabled={_ScreenAccessData[rowIndex.rowIndex].Adddisabled}
                            onChange={e => {
                                let newScreenAccessData = [...ScreenAccessData]
                                newScreenAccessData[rowIndex.rowIndex].addnew = e.target.checked 
                               setScreenAccessData(newScreenAccessData)
                            }}
                            style={{
                              padding:"0px"
                            }}
                            id={'addnew'+rowIndex.rowIndex}
                            color="primary"
                        />
                    )      
                }
             }
            }
        },
        {
            name: "edit",
            label: "Edit",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "12px"}} >{columnMeta.label}</div>
               )
              },
              customBodyRender: (dataIndex, rowIndex) =>{
              
                const _ScreenAccessData = ScreenAccessData;
                if(_ScreenAccessData[rowIndex.rowIndex] != null)
                {
                    return(
                        <Checkbox
                            checked={_ScreenAccessData[rowIndex.rowIndex].edit}
                            disabled={_ScreenAccessData[rowIndex.rowIndex].editdisabled}
                            onChange={e => {
                                let newScreenAccessData = [...ScreenAccessData]
                                newScreenAccessData[rowIndex.rowIndex].edit = e.target.checked 
                               setScreenAccessData(newScreenAccessData)
                            }}
                            style={{
                              padding:"0px"
                            }}
                            id={'edit'+rowIndex.rowIndex}
                            color="primary"
                        />
                    )      
                }
             }
            }
        },
        {
            name: "del",
            label: "Delete",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "12px"}} >{columnMeta.label}</div>
               )
              },
              customBodyRender: (dataIndex, rowIndex) =>{
              
                const _ScreenAccessData = ScreenAccessData;
                if(_ScreenAccessData[rowIndex.rowIndex] != null)
                {
                    return(
                        <Checkbox
                            checked={_ScreenAccessData[rowIndex.rowIndex].del}
                            disabled={_ScreenAccessData[rowIndex.rowIndex].deldisabled}
                            onChange={e => {
                                let newScreenAccessData = [...ScreenAccessData]
                                newScreenAccessData[rowIndex.rowIndex].del = e.target.checked 
                               setScreenAccessData(newScreenAccessData)
                            }}
                            style={{
                              padding:"0px"
                            }}
                            id={'del'+rowIndex.rowIndex}
                            color="primary"
                        />
                    )      
                }
             }
            }
        }
    ]

   const Save_Screens = () =>{
    
   
    ScreenAccessData.map(screen=>{
        if(screen.Allow_Access == true)
        screen.Allow_Access = 1
        else
        screen.Allow_Access = 0
        if(screen.addnew == true)
        screen.addnew = '1'
        else
        screen.addnew = '0'
        if(screen.edit == true)
        screen.edit = '1'
        else
        screen.edit = '0'    
        if(screen.del == true)
        screen.del = '1'
        else
        screen.del = '0'     

        screen.Access_Level = screen.addnew + screen.edit + screen.del
    })
    SaveGroupModuleScreens(ScreenAccessData,()=>{
        document.getElementById('cancel').click()
    })
   }
 
  const AllowAccess = (AllowAccess,Index) =>{
      
      if(AllowAccess == false)
      {
          ScreenAccessData[Index].addnew = false
          ScreenAccessData[Index].Adddisabled = true

          ScreenAccessData[Index].edit = false
          ScreenAccessData[Index].editdisabled = true

          ScreenAccessData[Index].del = false
          ScreenAccessData[Index].deldisabled=true

      }
      else
      {
          ScreenAccessData[Index].Adddisabled = false
          ScreenAccessData[Index].editdisabled = false
          ScreenAccessData[Index].deldisabled=false
      }
  }

  const Select_All = () => {
    var mData = [...ScreenAccessData]
    if (document.getElementById('select_all').checked === true) {
      mData.map(value => {
        if (value.Type === 'OPTION') {
          value.addnew = false
          value.Adddisabled = true
          value.edit = false
          value.editdisabled = true
          value.del = false
          value.deldisabled = true
          value.Allow_Access = true
        }
        else {
          value.addnew = true
          value.Adddisabled = false
          value.edit = true
          value.editdisabled = false
          value.del = true
          value.deldisabled = false
          value.Allow_Access = true
        }
      })
    }
    else {
      mData.map(value => {
        value.addnew = false
        value.Adddisabled = true
        value.edit = false
        value.editdisabled = true
        value.del = false
        value.deldisabled = true
        value.Allow_Access = false
      })
    }
    setScreenAccessData(mData)
  }
   return ReactDOM.createPortal (
    <>
       <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2> User Screen Access
                    </h2>
                </div>
            </div>
        </div>
       <Row>
            <Col lg={12} md={12}>
                <div className="ui-tab-card">
                    <Card>
                        <Row className="row-margin-left row-margin-right mt-4 mb-4 mr-4 ml-4">
                        <Col lg={3} xl={3} md={3} >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={Select_All_chkbox}
                                id={'select_all'}
                                onChange={e => {
                                  setSelect_All_chkbox(e.target.checked)
                                  Select_All()
                                }}
                                style={{
                                  padding: "0px"
                                }}
                                color="primary"
                              />
                            }
                            label="SELECT ALL"
                          />
                        </Col>
                            <Col lg={9} xl={9} md={9} style={{textAlign: "right"}} >
                            <Button type="button" className="btn btn-primary rounded-pill" onClick={Save_Screens}> <Save/> Save </Button>
                            <Link to={"/UserAccess"} id="cancel" className="btn btn-primary rounded-pill" ><Cancel/> Close </Link>
                            </Col>
                        </Row>
                        <Row className="row-margin-top row-margin-left row-margin-rightmr-4 ml-4">
                          <Col>
                            <div className="table-responsive" >
                              <MuiThemeProvider theme={theme}>
                                <MUIDataTable
                                  data={ScreenAccessData}
                                  columns={columns}
                                  options={{
                                      selectableRows: false,
                                      search: false,
                                      download: false,
                                      print: false,
                                      viewColumns: false,
                                      filter: false,
                                      selectableRows: false,
                                      selection: true,
                                      toolbar: false,
                                      paging: false,
                                      headerStyle: {
                                          fontSize: 12
                                      }}
                                      
                                  }
                                    /> 
                              </MuiThemeProvider>
                            </div>
                          </Col>
                        </Row>    
                    </Card>
                </div>
            </Col>
        </Row>
    </>, document.getElementById('PageContents')
)
}


const mapStateToProps = state =>({
    CompID: state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Gl_year: state.GlobalVariables.Financial_Year && state.GlobalVariables.Financial_Year.fin_year,
    User: state.Login_User.User_Data,
    IsAddNew: state.UserAccess && state.UserAccess.isAddNew,
    Group_Id: state.UserAccess && state.UserAccess.Group_Id,
    Mod_Id: state.UserAccess && state.UserAccess.AccessData
  })
export default connect(mapStateToProps, { setUserAccessData })(UserScreens)




