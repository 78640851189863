import React,{useState,useEffect} from 'react'
import ReactDOM from 'react-dom'
import {Row, Col,Card,Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {TextField, Popper} from '@material-ui/core'
import { Save, Cancel,ShowChart,Print} from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import { Autocomplete } from '@material-ui/lab';
import { GetCurrentDate, msg } from '../../Services/Common'
import { Selectbank } from '../../Services/bankAPI'
import { SelectManufacturedByList } from '../../Services/manufactureAPI'
import { SelectCompanyGroupAgainstManufacturer } from '../../Services/CompanyGroupAPI'
import { GetMaxCompanyPaymentNo,SaveCompanyPayment, SelectCompanyPaymentMaster } from '../../Services/CompanyPaymentAPI'
import { connect } from 'react-redux'
const CompanyPayments = ({Comp_Id,ProjectID,p_userid,AddNew,PaymentID}) => {
    const [PaymentData, setPaymentData] = useState({
        PaymentNo: '',
        PaymentDate: GetCurrentDate(),
        Balance:0.00,
        Amount: 0.00,
        Discription:''
    })
    const [BankList, setBankList] = useState([])
    const [SelectedBank, setSelectedBank] = useState(null)
    const [CompanyList, setCompanyList] = useState([])
    const [SelectedCompany, setSelectedCompany] = useState(null)
    const [SelectedPaymentType, setSelectedPaymentType] = useState(null)
    const [CGList, setCGList] = useState([])
    const [SelectedCG, setSelectedCG] = useState(null)
    const [isBankOff, setIsBankOff] = useState(true)
    useEffect(() => {
        document.title = 'Distribution /Company Payments'
        Selectbank(Comp_Id, ProjectID, (mBank) => {
          SelectManufacturedByList(Comp_Id, ProjectID, (mComp) => {
            setBankList(mBank);
            setCompanyList(mComp);
            if (AddNew) {
              GET_MAXNO();
            } else {
              SelectCompanyPaymentMaster(Comp_Id,ProjectID,PaymentID,(mData) => {  
                  setPaymentData({
                    ...PaymentData,
                    PaymentNo: mData[0].PaymentNo,
                    PaymentDate: mData[0].PaymentDate,
                    Balance: mData[0].Balance,
                    Amount: mData[0].Amount,
                    Discription: mData[0].Discription,
                  });
                  PaymentType_Options.map((val) => {
                    if (val.value.trim() === mData[0].PaymentType.trim()) {
                      setSelectedPaymentType(val);
                    }
                  });
                  if (mData[0].PaymentType.trim() === "Cash Payment") {
                    setIsBankOff(true);
                  } else {
                    setIsBankOff(false);
                  }
                  if(mData[0].BankID != 0){
                    mBank.map((mbank) => {
                        if (mbank.BankID === mData[0].BankID) {
                            setSelectedBank(mbank);
                        }
                      });
                  }
                  mComp.map(comp => {
                    if (comp.ManufacturedByID === mData[0].ManufacturedByID) {
                      setSelectedCompany(comp);
                    }
                  });
                  SelectCompanyGroupAgainstManufacturer(Comp_Id,ProjectID,mData[0].ManufacturedByID,(mCG)=>{
                    var Selected_CG =mCG.find(x=> x.CGID === mData[0].CGID)
                    setSelectedCG(Selected_CG)
                    setCGList(mCG)
                  })
                }
              );
            }
          });
        });
       
        
    }, []);
    const GET_MAXNO = () =>{
        GetMaxCompanyPaymentNo(Comp_Id,ProjectID,(mMax)=>{
            setPaymentData({...PaymentData, PaymentNo: mMax})
        })
    }
   const PaymentType_Options =[
        {value:'Cash Payment',title:'Cash Payment'},
        {value:'Account Payment',title:'Account Payment'},
    ]
    const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    const Save_Handler = () =>{
      var Err = false
        if(SelectedPaymentType){
                if(SelectedCompany){
                    if(SelectedCG){
                        if(PaymentData.Amount > 0){
                            if(SelectedPaymentType.value === 'Account Payment'){
                                if(!SelectedBank){
                                    Err = true
                                }
                            }
                            if(Err){
                                msg('Bank Required')
                            }else{
                                var mPayment = {
                                    PaymentID: PaymentID,
                                    PaymentNo: PaymentData.PaymentNo,
                                    PaymentDate: PaymentData.PaymentDate,
                                    PaymentType : SelectedPaymentType ? SelectedPaymentType.value : '',
                                    BankID : SelectedBank ? SelectedBank.BankID : 0,
                                    ManufacturedByID: SelectedCompany ? SelectedCompany.ManufacturedByID : 0,
                                    CGID : SelectedCG ? SelectedCG.CGID : 0,
                                    Balance: PaymentData.Balance,
                                    Amount: parseFloat(PaymentData.Amount),
                                    Discription : PaymentData.Discription,
                                    UserID:p_userid ,
                                    Comp_Id:Comp_Id,
                                    ProjectID: ProjectID
                                }
                              
                                SaveCompanyPayment(mPayment,AddNew,()=>{
                                    document.getElementById('btnCancel').click()
                                })
                            }
                        }else{
                            msg('Amount Required')    
                        }

                    }else{
                        msg('Company Group Required')    
                    }

                }else{
                    msg('Manufacturer Required')  
                }

        }else{
            msg('Payment Type Required')
        }
        
    }
  return ReactDOM.createPortal (
    <>
    <div className="block-header">
        <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12">
                <h2>Payments
                </h2>
            </div>
        </div>
    </div> 
        <Card>
        <Row className="row_margin_top row_margin_left row_margin_right p-4" >
                <Col sm={12} lg={2} xl={2} md={2} >
                    <TextField 
                        name="PaymentNo"
                        label="Payment No" 
                        value={PaymentData.PaymentNo}
                        inputProps={
                            {readOnly: true}
                        }
                        fullWidth
                        />
                </Col>
                <Col sm={12} lg={2} xl={2} md={2} >
                    <TextField 
                        value={PaymentData.PaymentDate}
                        name="PaymentDate"
                        label="Payment Date" 
                        type= 'date'
                        onChange= {e => setPaymentData({...PaymentData, PaymentDate:e.target.value})}
                        fullWidth
                    />
                </Col>
                <Col lg={2} xl={2} md={2} >
                <Autocomplete
                name="PaymentType"
                id="PaymentType"
                options={PaymentType_Options}
                value={SelectedPaymentType}
                getOptionLabel={(option) => option.title ? option.title : ""}
                getOptionSelected={(option, value) => option.value === value.value}
               // disabled = {this.state.disabledProp}
                onChange={(e, value) => {
                    if (value)
                    {
                        if(value.value === 'Account Payment'){
                            setIsBankOff(false)
                        }else{
                            setIsBankOff(true)
                        }
                        setSelectedPaymentType(value)
                    }
                    else
                    {
                        setSelectedPaymentType(null)
                        setIsBankOff(true)
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="PaymentType" label="Payment Type"
                    />}
            />
           
                </Col>
                <Col lg={2} xl={2} md={2} >
                <Autocomplete
                name="Bank"
                id="Bank"
                options={BankList}
                value={SelectedBank}
                getOptionLabel={(option) => option.BankName ? option.BankName : ""}
                getOptionSelected={(option, value) => option.BankID === value.BankID}
                 disabled = {isBankOff}
                onChange={(e, value) => {
                    if (value)
                    {
                        setSelectedBank(value)
                      //  this.setState({selectedBank:value,BankErr:''})
                    }
                    else
                    {
                        setSelectedBank(null)
                       // this.setState({selectedBank:null})
                
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="Bank" label="Select Bank"
                    />}
            />
                </Col>
                <Col lg={2} xl={2} md={2} >
                <Autocomplete
                                name="ManufacturedBy"
                                id="ManufacturedBy"
                                options={CompanyList}
                                getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""} 
                                value={SelectedCompany}
                                onChange={(e,value) =>{
                                if(value){
                                   setSelectedCompany(value)
                                    SelectCompanyGroupAgainstManufacturer(Comp_Id,ProjectID,value.ManufacturedByID,(mData)=>{
                                        setCGList(mData)
                                    })
                                }
                                else{
                                    setSelectedCompany(null)
                                     setCGList([])  
                                     setSelectedCG(null)
                                   
                                }
                                    
                                }}
                                renderInput={(params) => 
                                <TextField {...params} name="ManufacturedBy" label="ManufacturedBy"
                                />}
                            />
            </Col>
            <Col lg={2} xl={2} md={2} >
            <Autocomplete
                name="CG"
                id="CG"
                options={CGList}
                getOptionLabel={(option) => option.CGName ? option.CGName : ""} 
                value={SelectedCG}
                onChange={(e,value) =>{
                if(value){
                    setSelectedCG(value)
                }
                else{
                    setSelectedCG(null)
                 
                }
                    
                }}
                renderInput={(params) => 
                <TextField {...params} name="CG" label="Company Group"
                />}
            />
        </Col>
        
        <Col sm={12} lg={2} xl={2} md={2} >
                    <TextField 
                        name="Balance"
                        label="Balance" 
                        value={PaymentData.Balance}
                        inputProps={
                            {readOnly: true}
                        }
                        />
                </Col>
                <Col sm={12} lg={2} xl={2} md={2} >
                    <TextField 
                        name="Amount"
                        label="Amount" 
                        value={PaymentData.Amount}
                        onChange={e=> setPaymentData({...PaymentData, Amount: e.target.value})}
                        fullWidth
                        />
                </Col>
                <Col sm={12} lg={8} xl={8} md={8} >
                    <TextField 
                        name="Discription"
                        label="Discription" 
                        value={PaymentData.Discription}
                        onInput={toInputUppercase}
                        onChange={e=>setPaymentData({...PaymentData,Discription:e.target.value})}
                        fullWidth
                        />
                </Col>
            </Row>
            <Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'2%'}} >
                    <Col style={{ textAlign: "right" }}>
                      {/*  {btn_edit && (*/}
                        <button type="button" className="btn btn-primary rounded-pill" onClick={Save_Handler} style={{ border: "none" }} > 
                          {/*  {
                             btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <>
                                    <Save /> Save</>
                            {/*    } */}
                            <Save /> Save
                                 </button>
                               {/*     )}*/}
                        {/* <Link id="btnPrint" className="btn btn-outline-primary" style={{ border: "none" }} target ="_blank" to='/IPD_Registration_Report' onClick={Print_Handler}> <Print /> <u>Print</u></Link> */}
                        <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{ border: "none" }} to='/CompanyPaymentList' > <Cancel /> <u>Close</u></Link>

                    </Col>
                </Row>
            </Card>
        
    </>, document.getElementById('PageContents')
  )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    //     StockTransferID:state.StockTransfer && state.StockTransfer.StockTransferID,
        show_screen: state.SCREEN,
        Month:state.Payments.Payment_data && state.Payments.Payment_data.SelectedMonth_Option,
      //  Project:state.Payments.Payment_data && state.Payments.Payment_data.SelectedProject,
      ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
        AddNew: state.CompanyPayment && state.CompanyPayment.isAddNew,
        PaymentID:state.Payments && state.CompanyPayment.PaymentID,
    
    })
    export default connect(mapStateToProps)(CompanyPayments)
