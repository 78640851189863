import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Row, Col, Card } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { GetCurrentDate, preventMinus } from "../../Services/Common";
import { SelectCompanyGroupAgainstManufacturer } from "../../Services/CompanyGroupAPI";
import { SelectManufacturedByList } from "../../Services/manufactureAPI";
import { connect } from "react-redux";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Filter,
  Inject,
  Edit,
  Sort,
  ForeignKey,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { GetMaxOpeningNo, PostOpening, SelectItemDetails, getOpeningRecordsForEdit } from "../../Services/OpeningAPI";
import { enableRipple } from "@syncfusion/ej2-base";
import { Save, Cancel, Print } from "@material-ui/icons";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { SelectWareHouseList } from "../../Services/WareHouseAPI";
import { SelectItemsWRTManufacturer } from "../../Services/SchemeAPI";
export class Opening extends Component {
  constructor(props) {
    super(props);

    this.state = {
      OpeningNo: "",
      transactionDate: GetCurrentDate(),
      manufacturedByList: [],
      selectedManufacturedBy: null,
      selectedCG: null,
      CGList: [],
      discription: "",
      total: 0,
      Selected_item: null,
      btn_AddNew: false,
      openingDetailsData: [],
      Warehouse_List:[],
      SelectedWareHouse:null,
      itemList: [],
      manuFacturerErr: "",
      CGErr: "",
      isLoading: false
    };
    this.toolbarOptions = ["Add", "Edit", "Delete", "Update", "Cancel"];
    this.validationRules = { required: true };
    this.grid = null;
    this.Comp_Id = this.props.Comp_Id;
    this.UserID = this.props.p_userid
    this.ProjectID = this.props.ProjectID;
    this.AddNew = this.props.AddNew;
    this.InventoryID = this.props.InventoryID
  }
  sortingOptions = {
    columns: [{ field: "SrNo", direction: "Ascending" }],
  };

  componentDidMount() {
    document.title = " Distribution / Sale Comparison";
    
    SelectWareHouseList(this.Comp_Id,this.ProjectID,(mWareHOuse)=>{
      this.setState({Warehouse_List:mWareHOuse,SelectedWareHouse:mWareHOuse[0]})
    })
    SelectManufacturedByList(this.Comp_Id, this.ProjectID, (manufacturer) => {
      this.setState({ manufacturedByList: manufacturer });
  
    if (this.AddNew) {
      //save
      this.GetMax_OpeningNo(); // new reciver NO genrate
    } else {
      getOpeningRecordsForEdit(this.props.InventoryID, (res)=>{
        const selectedManufacture = manufacturer.find(x=> x.ManufacturedByID === res?.masterData[0]?.ManufacturedByID)
        SelectCompanyGroupAgainstManufacturer(
          this.Comp_Id,
          this.ProjectID,
          res?.masterData[0]?.ManufacturedByID,
          (mData) => {
            mData.unshift({CGID: 0, CGName: 'SELECT ALL'})
            const selectedCG = mData.find(x=> x.CGID === res?.masterData[0]?.CGID)
            this.setState({ CGList: mData, selectedCG: selectedCG });
          });
          SelectItemsWRTManufacturer(this.Comp_Id, this.ProjectID, res?.masterData[0]?.ManufacturedByID, res?.masterData[0]?.CGID, (items)=>{
            this.setState({ itemList: items });
          })
        this.setState({
          OpeningNo: res?.masterData[0]?.OpeningNo,
          transactionDate: res?.masterData[0]?.TransactionDate,
          discription: res?.masterData[0]?.Discription,
          total: res?.masterData[0]?.Total,
          selectedManufacturedBy: selectedManufacture, 
          openingDetailsData: res?.detailsData
        })
        
      })
    }
  });
  }
  GetMax_OpeningNo = () => {
    GetMaxOpeningNo(this.Comp_Id, this.ProjectID, (mOpeningNo) => {
      // API Function
      this.setState({ OpeningNo: mOpeningNo }); // set state here
    });
  };
  ItemDiscription = (rowValue) => {
    debugger
    return (
      <Autocomplete
        name="ItemDiscription"
        id="ItemDiscription"
        defaultValue={this.state.itemList.find(
          (v) => v.ItemDiscription === rowValue.ItemDiscription
        )}
        options={this.state.itemList}
        getOptionLabel={(option) =>
          option.ItemDiscription ? option.ItemDiscription : ""
        }
        onChange={(e, value) => {
          if (value) {
            document.getElementById("UOM").value = value.UOM;
            document.getElementById("PurchaseRate").value = value.PackPurRate;
            document.getElementById("SaleRate").value = value.PackSaleRate;
            document.getElementById("GST").value = value.GST;
            document.getElementById("Amount").value = value.PackPurRate;
            document.getElementById("MRP").value = value.MRP;
            this.setState({ Selected_item: value }, () => {
              this.calculateTotalAmount();
            });
          } else {
            document.getElementById("UOM").value = "";
            document.getElementById("PurchaseRate").value = 0;
            document.getElementById("SaleRate").value = 0;
            document.getElementById("GST").value = 0;
            document.getElementById("Amount").value = 0;
            document.getElementById("MRP").value = 0;
            this.setState({ Selected_item: null }, () => {
              this.calculateTotalAmount();
            });
          }
        }}
        renderInput={(params) => (
          <TextField {...params} name="ItemDiscription" />
        )}
      />
    );
  };
  UOM = (rowValue) => {
    return (
      <input
        id="UOM"
        name="UOM"
        style={{ textAlign: "right", width: "80%" }}
        defaultValue={rowValue.UOM}
        className="textbox"
        readOnly={true}
      />
    );
  };
  BatchNo = (rowValue) => {
    return (
      <input
        id="BatchNo"
        name="BatchNo"
        style={{ textAlign: "right", width: "80%" }}
        defaultValue={rowValue.BatchNo}
        className="textbox"
      />
    );
  };
  Qty = (rowValue, rowIdx) => {
    return (
      <input
        id={"Qty"}
        name="Qty"
        type="number"
        style={{ textAlign: "center", width: "80%" }}
        defaultValue={rowValue.Qty}
        className="textbox"
        onChange={(e) => {
          this.calculateTotalAmount();
        }}
        min={1}
        onKeyPress={(e) => preventMinus(e)}
      />
    );
  };
  ExpairyDate = (rowValue) => (
    <input
      id="ExpairyDate"
      name="ExpairyDate"
      type="date"
      style={{ textAlign: "right", width: "80%" }}
      defaultValue={rowValue.ExpairyDate}
      className="textbox"
    />
  );
  PurchaseRate = (rowValue) => (
    <input
      id="PurchaseRate"
      name="PurchaseRate"
      type="number"
      style={{ textAlign: "center", width: "80%" }}
      defaultValue={rowValue.PurchaseRate}
      onChange={(e) => {
        this.calculateTotalAmount();
      }}
      onKeyPress={(e) => preventMinus(e)}
      className="textbox"
    />
  );

  SaleRate = (rowValue) => (
    <input
      id="SaleRate"
      name="SaleRate"
      style={{ textAlign: "right", width: "80%" }}
      defaultValue={rowValue.SaleRate}
      className="textbox"
    />
  );
  MRP = (rowValue) => (
    <input
      id="MRP"
      name="MRP"
      style={{ textAlign: "right", width: "80%" }}
      defaultValue={rowValue.MRP}
      className="textbox"
    />
  );
  GST = (rowValue) => (
    <input
      id="GST"
      name="GST"
      style={{ textAlign: "right", width: "80%" }}
      defaultValue={rowValue.GST}
      className="textbox"
    />
  );

  Amount = (rowValue) => (
    <input
      id="Amount"
      name="Amount"
      style={{ textAlign: "right", width: "80%" }}
      defaultValue={rowValue.Amount}
      className="textbox"
      readOnly={true}
    />
  );

  beforeRowInsert = (rowValue) => {
    console.log("before insert", rowValue);
    if (rowValue.rowData && rowValue.data) {
      if (rowValue.data.SrNo) {
        if (rowValue.requestType != "cancel") {
          rowValue.data.SrNo = parseInt(rowValue.data.SrNo);
          rowValue.data.ItemDiscription =
            this.state.Selected_item.ItemDiscription;
          rowValue.data.ItemID = this.state.Selected_item.ItemID;
        }
        // console.log('before insert',rowValue.rowData);
      } else {
        rowValue.data.SrNo = this.state.openingDetailsData.length + 1;
        rowValue.data.Qty = 1;
        rowValue.data.PurchaseRate = 0;
        rowValue.data.SaleRate = 0;
        rowValue.data.MRP = 0;
        rowValue.data.GST = 0;
        rowValue.data.Amount = 0;
        rowValue.data.BatchNo = "0001";
        rowValue.data.ExpairyDate = GetCurrentDate();
        this.setState({ Selected_item: null });
      }
    }
  };
  afterRowInsert = (rowValue) => {
    if (rowValue.requestType == "beginEdit") {
      this.setState({ Selected_item: { 
        "ItemDiscription": rowValue.rowData.ItemDiscription, 
        "ItemID": rowValue.rowData.ItemID, UOMID: rowValue.rowData.UOMID } })
    }
    if (rowValue.requestType == "save") {
      this.Calculate_Grand_Total();
    }
    if (rowValue.requestType == "delete") {
      this.Calculate_Grand_Total();
    }
  };
  calculateTotalAmount = () => {
    const qty = parseFloat(document.getElementById("Qty").value) || 1;
    const purchaseRate =
      parseFloat(document.getElementById("PurchaseRate").value) || 0;
    const _amount = qty * purchaseRate;
    document.getElementById("Amount").value = _amount;
  };
  Calculate_Grand_Total = () => {
    let _GrandTotal = 0;
    for (let i = 0; i < this.state.openingDetailsData.length; i++) {
      let _amount = parseFloat(this.state.openingDetailsData[i].Amount);
      _GrandTotal += _amount;
    }
    this.setState({ total: _GrandTotal });
  };
  saveHandler = (frmPrint) => {
    try{
      if (this.state.selectedManufacturedBy) {
        if (this.state.selectedCG) {
          if (this.state.openingDetailsData.length > 0) {
            const openingData = {
              OpeningNo: this.state.OpeningNo,
              TransactionDate: this.state.transactionDate,
              ManufacturedByID: this.state.selectedManufacturedBy.ManufacturedByID,
              CGID: this.state.selectedCG.CGID,
              Discription:this.state.discription,
              Total :this.state.total,
              Comp_Id: this.Comp_Id,
              ProjectID: this.ProjectID,
              UserID: this.UserID,
              WareHouseID:this.state.SelectedWareHouse.WareHouseID
            };
            this.setState({isLoading: true})
            PostOpening(openingData, this.state.openingDetailsData,this.AddNew, (res)=>{
              this.setState({isLoading: false})
              document.getElementById("btnCancel").click()
              if(frmPrint){
                window.open(`/opening-invoice/${res?.data?.InventoryMasterID}`,'_blank')
              }
              
            })
          } else {
            Swal.fire({
              icon: "info",
              text: `Opening Details Must be Entered...`,
            });
            return;
          }
        } else {
          this.setState({ CGErr: "CG is required" });
        }
      } else {
        this.setState({ manuFacturerErr: "Manufacturer is required" });
      }
    }catch(err){
        console.log(err.message)
    }finally{
      this.setState({isLoading: false})
    }
   
  };

  render() {
    if (this.grid)
      this.grid.autoFitColumns([
        "ItemID",
        "ItemDescription",
        "UOM",
        "SaleRate",
        "PurchaseRate",
      ]);
    enableRipple(true);
    return ReactDOM.createPortal(
      <>
        <Row className="row_margin_top row_margin_left row_margin_right">
          <Col lg={6} xl={6} md={6} className="header_title">
            <h2>Inventory Opening</h2>
          </Col>
        </Row>
        <Card>
          <Row className="row_margin_top row_margin_left row_margin_right m-2">
            <Col sm={12} lg={3} xl={3} md={3}>
              <TextField
                name="OpeningNo"
                label="Opening No"
                type="text"
                value={this.state.OpeningNo}
                inputProps={{ readOnly: true }}
                fullWidth
              />
            </Col>
            <Col sm={12} lg={3} xl={3} md={3}>
              <TextField
                name="Transaction_Date"
                label="Transaction Date"
                type="date"
                value={this.state.transactionDate}
                onChange={(e) =>
                  this.setState({ transactionDate: e.target.value })
                }
                fullWidth
              />
            </Col>
            <Col sm={12} lg={3} xl={3} md={3}>
              <Autocomplete
                name="ManufacturedBy"
                id="ManufacturedBy"
                options={this.state.manufacturedByList}
                getOptionLabel={(option) => option?.ManufacturedBy ?? ""}
                value={this.state.selectedManufacturedBy}
                onChange={(e, value) => {
                  if (value) {
                    this.setState({
                      selectedManufacturedBy: value,
                      manuFacturerErr: "",
                    });
                    SelectCompanyGroupAgainstManufacturer(
                      this.Comp_Id,
                      this.ProjectID,
                      value.ManufacturedByID,
                      (mData) => {
                        mData.unshift({CGID: 0, CGName: 'SELECT ALL'})
                        this.setState({ CGList: mData });

                      }
                    );
                  } else {
                    this.setState({
                      selectedManufacturedBy: null,
                      manuFacturerErr: "Manufacturer is required",
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="ManufacturedBy"
                    label="Manufactured By"
                  />
                )}
              />
              <span className="text-danger">{this.state.manuFacturerErr}</span>
            </Col>
            <Col sm={12} lg={3} xl={3} md={3}>
              <Autocomplete
                name="CG"
                id="CG"
                options={this.state.CGList}
                getOptionLabel={(option) => option?.CGName ?? ""}
                value={this.state.selectedCG}
                onChange={(e, value) => {
                  if (value) {
                    this.setState({
                      selectedCG: value,
                      btn_AddNew: true,
                      CGErr: "",
                    });
                    SelectItemsWRTManufacturer(this.Comp_Id, this.ProjectID, this.state.selectedManufacturedBy.ManufacturedByID, value.CGID, (items)=>{
                      this.setState({ itemList: items });
                    })
                  } else {
                    this.setState({
                      selectedCG: null,
                      btn_AddNew: false,
                      CGErr: "CG is required",
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="CompayGroup"
                    label="Compay Group"
                  />
                )}
              />
              <span className="text-danger">{this.state.CGErr}</span>
            </Col>
          </Row>

          <Row className="row_margin_top row_margin_left row_margin_right m-2">
            <Col sm={12} lg={8} xl={8} md={8}>
              <TextField
                name="Discription"
                label="Discription"
                type="text"
                value={this.state.discription}
                onChange={(e) => this.setState({ discription: e.target.value })}
                fullWidth
              />
            </Col>
            <Col sm={12} lg={4} xl={4} md={4}>
              <TextField
                name="Total"
                label="Total"
                type="number"
                value={this.state.total}
                onChange={(e) => this.setState({ total: e.target.value })}
                inputProps={{ readOnly: true }}
                fullWidth
              />
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <div style={{ flex: "1", width: "100%", height: "100%" }}>
              <GridComponent
                dataSource={this.state.openingDetailsData}
                actionBegin={this.beforeRowInsert}
                ref={(g) => (this.grid = g)}
                actionComplete={this.afterRowInsert}
                allowPaging={true}
                allowFiltering={true}
                allowSorting={true}
                editSettings={{
                  allowEditing: true,
                  allowDeleting: true,
                  allowAdding: this.state.btn_AddNew,
                  newRowPosition: "Bottom",
                }}
                sortSettings={this.sortingOptions}
                filterSettings={{ type: "Menu" }}
                toolbar={this.toolbarOptions}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    width="120"
                    field="SrNo"
                    headerText="SrNo #"
                    Format="{0:n}"
                    allowEditing={false}
                    isIdentity={true}
                    textAlign="Left"
                    isPrimaryKey={true}
                  ></ColumnDirective>
                  <ColumnDirective
                    field="ItemID"
                    visible={false}
                  ></ColumnDirective>
                  <ColumnDirective
                    width="250"
                    field="ItemDiscription"
                    headerText="ItemDiscription"
                    editType="dropdownedit"
                    editTemplate={this.ItemDiscription}
                    edit={this.cbParams}
                    textAlign="Left"
                  />{" "}
                  {/*validationRules={true} */}
                  <ColumnDirective
                    width="170"
                    field="UOM"
                    headerText="UOM"
                    editTemplate={this.UOM}
                  />
                  <ColumnDirective
                    width="150"
                    field="BatchNo"
                    headerText="BatchNo"
                    editType="text"
                    textAlign="Left"
                    editTemplate={this.BatchNo}
                  />
                  <ColumnDirective
                    width="200"
                    field="ExpairyDate"
                    headerText="ExpairyDate"
                    editType="date"
                    textAlign="Left"
                    editTemplate={this.ExpairyDate}
                  />
                  <ColumnDirective
                    width="150"
                    field="Qty"
                    headerText="Qty"
                    textAlign="right"
                    editTemplate={this.Qty}
                  />
                  <ColumnDirective
                    width="150"
                    field="PurchaseRate"
                    headerText="Purchase Rate"
                    textAlign="right"
                    editTemplate={this.PurchaseRate}
                  />
                  <ColumnDirective
                    width="150"
                    field="SaleRate"
                    headerText="Sale Rate"
                    textAlign="right"
                    editTemplate={this.SaleRate}
                  />
                  <ColumnDirective
                    width="170"
                    field="MRP"
                    headerText="MRP"
                    textAlign="right"
                    editTemplate={this.MRP}
                  />
                  <ColumnDirective
                    width="150"
                    field="GST"
                    headerText="GST"
                    textAlign="right"
                    editTemplate={this.GST}
                  />
                  <ColumnDirective
                    width="150"
                    field="Amount"
                    headerText="Amount"
                    textAlign="right"
                    editTemplate={this.Amount}
                  />
                </ColumnsDirective>
                <Inject
                  services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]}
                />
              </GridComponent>
            </div>
          </div>
          <Row className=" row_margin_top row_margin_left row_margin_right">
            <Col style={{ textAlign: "right", marginTop: "1%" }}>
              {/* <Link id="btnReStock" className="btn btn-primary rounded-pill" style={{border: "none"}} to={'#'} > <ShowChart /><u>Reorder. Stock</u></Link>
                             <Link id="btnStock" className="btn btn-primary rounded-pill" style={{border: "none"}} to={'#'} onClick={this.Show_Min_Stock_Modal}> <ShowChart /><u>Get Min. Stock</u></Link> */}
              <button
                type="button"
                className="btn btn-primary rounded-pill"
                style={{ border: "none" }}
                onClick={()=>this.saveHandler(false)}
                disabled={this.state.isLoading}
              >
                {" "}
                <Save /> <u>Save</u>
              </button>
              <Link
                id="btnCancel"
                className="btn btn-primary rounded-pill"
                style={{ border: "none" }}
                to={"/OpeningList"}
              >
                {" "}
                <Cancel /> <u>Close</u>
              </Link>
              <button
                target={"_blank"}
                className="btn btn-primary rounded-pill"
                style={{ border: "none" }}
                onClick={()=> this.saveHandler(true)}
                //  to={'/SaleOrderReport'}
                //  onClick={e =>{

                //     var obj = {
                //         SaleOrderNo: this.state.SaleOrderNo,
                //         Supplier: this.state.SelectedCustomer,
                //         WareHouse: this.state.SelectedWareHouse,
                //         SaleOrderDate: this.state.SaleOrderDate,
                //         Discription: this.state.Discription,
                //         Detail: this.state.SO_Detail_Data
                //     }

                //     localStorage.setItem("SaleOrder", JSON.stringify(obj))
                //  }}
              >
                {" "}
                <Print /> <u>Save and Print</u>
              </button>
            </Col>
          </Row>
        </Card>
      </>,
      document.getElementById("PageContents")
    );
  }
}
const mapStateToProps = (state) => ({
  Comp_Id:
    state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  p_userid: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
  AddNew: state.Opening && state.Opening.isAddNew,
  InventoryID: state.Opening && state.Opening.OPening_ID,
  ProjectID:
    state.Global_Projects.GlobalProjects &&
    state.Global_Projects.GlobalProjects.Project_ID,
  show_screen: state.SCREEN,
});

export default connect(mapStateToProps)(Opening);
