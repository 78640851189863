import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import {Row, Col,Card, Spinner} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {TextField, Popper, Button} from '@material-ui/core'
import { Save, Cancel,ShowChart, Print} from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import { Autocomplete } from '@material-ui/lab';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
import { connect} from 'react-redux'
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query"
import { GetCurrentDate } from '../../Services/Common'

const AutoPOEntry = () => {
    const [purchaseOrderNo, setPurchaseOrderNo] = useState('')
    const [purchaseOrderDate, setPurchaseOrderDate] = useState(GetCurrentDate())
    const [manufacturedByList, setManufacturedByList] = useState([
        {ManufacturedByID: 1, ManufacturedBy:"Test Data"}
    ])
    const [selectedManufacturer, setSelectedManufacturer] = useState(null)
    const [inventoryLevel, setInventoryLevel] = useState('')
    const [from1Date, setFrom1Date] = useState(GetCurrentDate())
    const [from2Date, setFrom2Date] = useState(GetCurrentDate())
    const [from3Date, setFrom3Date] = useState(GetCurrentDate())
    const [to1Date, setTo1Date] = useState(GetCurrentDate())
    const [to2Date, setTo2Date] = useState(GetCurrentDate())
    const [to3Date, setTo3Date] = useState(GetCurrentDate())

  return ReactDOM.createPortal (
    <>
         <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2>Auto Purchase Order
                    </h2>
                </div>
            </div>
        </div>
        <Card>
        <Row className="row_margin_top row_margin_left row_margin_right p-4" >
        <Col sm={12} lg={3} xl={3} md={3} >
                        <TextField 
                            name="PurchaseOrderNo"
                            label="Po No" 
                            value={purchaseOrderNo}
                            inputProps={
                                {readOnly: true}
                            }
                            fullWidth
                            />
                    </Col>
                    <Col sm={12} lg={3} xl={3} md={3} >
                        <TextField 
                            value={purchaseOrderDate}
                            name="PurchaseOrderDate"
                            label="Order Date" 
                            type= 'date'
                            onChange= {e => setPurchaseOrderDate(e.target.value)}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={3} xl={3} md={3} >
                    <Autocomplete
                        name="SupplierName"
                        id="SupplierName"
                        options={manufacturedByList}
                        getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""}
                        value={selectedManufacturer}
                        onChange={(e, value) => {
                            if (value)
                            {
                                setSelectedManufacturer(value)
                            }
                            else
                            {
                                setSelectedManufacturer(null)
                            }
                        }}
                        renderInput={(params) =>
                            <TextField {...params} name="Company" label="Company"
                            />}
                    />
                    </Col>
                    <Col sm={12} lg={3} xl={3} md={3} >
                        <TextField 
                            value={inventoryLevel}
                            name="inventoryLevel"
                            label="Inventrory Level" 
                            type= 'text'
                            onChange= {e => setInventoryLevel(e.target.value)}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={3} xl={3} md={3} >
                        <TextField 
                            value={from1Date}
                            name="FromDate1"
                            label="From Date 1st" 
                            type= 'date'
                            onChange= {e => setFrom1Date(e.target.value)}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={3} xl={3} md={3} >
                        <TextField 
                            value={to1Date}
                            name="ToDate1"
                            label="To Date 1st" 
                            type= 'date'
                            onChange= {e => setTo1Date(e.target.value)}
                            fullWidth
                        />
                    </Col>

                    <Col sm={12} lg={3} xl={3} md={3} >
                        <TextField 
                            value={from2Date}
                            name="FromDate2"
                            label="From Date 2nd" 
                            type= 'date'
                            onChange= {e => setFrom2Date(e.target.value)}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={3} xl={3} md={3} >
                        <TextField 
                            value={to2Date}
                            name="ToDate2"
                            label="To Date 2nd" 
                            type= 'date'
                            onChange= {e => setTo2Date(e.target.value)}
                            fullWidth
                        />
                    </Col>

                    <Col sm={12} lg={3} xl={3} md={3} >
                        <TextField 
                            value={from3Date}
                            name="FromDate3"
                            label="From Date 3rd" 
                            type= 'date'
                            onChange= {e => setFrom3Date(e.target.value)}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={3} xl={3} md={3} >
                        <TextField 
                            value={to3Date}
                            name="ToDate3"
                            label="To Date 3rd" 
                            type= 'date'
                            onChange= {e => setTo3Date(e.target.value)}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={3} xl={3} md={3} >
                       <Link type="button" className="btn btn-primary  mt-2" to="#" style={{ border: "none" }} > Get Orders</Link>
                    </Col>
        </Row>
        </Card>
    </>, document.getElementById('PageContents')
  )
}

export default AutoPOEntry