import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectProjects =(Branch_Id,Callback) =>{
  axios.get(`${domain}/Projects/SelectProjects/${Branch_Id}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectUserProjects =(Branch_Id,User_Id,Callback) =>{
  axios.get(`${domain}/Projects/SelectUserProjects/${Branch_Id}/${User_Id}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
          Callback(err)
     })
}

export const SelectProjectForModal =(Project_ID,Callback) =>{
  axios.get(`${domain}/Projects/SelectProjectForModal/${Project_ID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectProject =(Branch_Id,Project_ID,Callback) =>{
  axios.get(`${domain}/Projects/SelectProject/${Branch_Id}/${Project_ID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SaveProject = (ProjectData,IsAddNew,callback) =>{

axios.post(`${domain}/Projects/SaveProject`,{
  ProjectData,
  IsAddNew
})
  .then(response=>{
      toast.configure()
      toast.info(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
      callback()
  })
  .catch(err=>console.error(err))
}
export const DeleteProject =(Project_ID,Callback) =>{
  
  axios.delete(`${domain}/Projects/DeleteProject/${Project_ID}`)
       .then(response=>{
          Callback(response.data.status , response.data.message)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}