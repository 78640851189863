import React, { useState, useEffect } from 'react'
import { Modal, Card, Row, Col, Button ,Spinner } from "react-bootstrap"
import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { Save, Cancel } from "@material-ui/icons"
import { connect } from "react-redux"
import { GetCurrentDate } from "./../../Services/Common"
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { SelectExpenseClassificationList } from "../../Services/ExpenseClassificationAPI"
import { SelectExpenseTypeList,SelectExpenseType } from "../../../src/Services/ExpenseTypeAPI"
import { SaveExpences, SelectExpense } from "../../Services/ExpenseAPI"
import { Selectbank } from  '../../Services/bankAPI'

function AddExpense_Modal({ show, onHide, Expense_Id, is_add_new, show_screen,Comp_Id,ProjectID }) {

    const [EC_Data, setEC_Data] = useState([])
    const [Selected_EC_Data, setSelected_EC_Data] = useState(null)
    const [ET_Data, setET_Data] = useState([])
    const [Selected_ET_Data, setSelected_ET_Data] = useState(null)
    const [date, setDate] = useState(GetCurrentDate())
    const [Expense, setExpense] = useState(0.00)
    const [Discription, setDiscription] = useState('')
    const [Error, setError] = useState({
        Expense_Classification: '',
        ExpenseType: '',
        Expense : '',
        BankErr:'',
    })
    const [btn_edit, setBtn_edit] = useState(true)
    const [OtherAccess, setOtherAccess] = useState(null)
    const [Bank_List, setBank_List] = useState([])
    const [SelectedBank, setSelectedBank] = useState(null)
    const [btn_Disabled, setBtn_Disabled] = useState(false)
    useEffect(() => {

        document.title = "Distribution / Expense"
       
        if(show){
            // Select_EC_List()
            Selectbank(Comp_Id,ProjectID,(mData)=>{
            var mObj = {BankID:0,BankName:'Expense By Cash',Comp_Id,ProjectID:ProjectID}
                mData.splice(0,0,mObj)
                setBank_List(mData)
            SelectExpenseClassificationList(Comp_Id,ProjectID,(EC_Data) =>{
            setEC_Data(EC_Data)
                
            if(is_add_new === true)
            {
                setSelected_EC_Data(null)
                setET_Data([])
                setSelected_ET_Data(null)
                setDate(GetCurrentDate())
                setExpense(0.00)
                setDiscription('')
                setSelectedBank(mObj)
                setError({Expense_Classification: "", ExpenseType: "", Expense: ""})

                document.getElementById("ExpenseClassification").focus()
            }
            else if(is_add_new === false)
            {
                debugger
                setBtn_edit(show_screen.edit) 
                if(Expense_Id)
                SelectExpense(Comp_Id,ProjectID, Expense_Id,(data)=>{
                    var Selected_EC = EC_Data.find(x => x.ExpenseClassificationID === data.ExpenseClassificationID)
                    var SelectedBAnk = mData.find(x=> x.BankID === data.ExpenseBy)
                    setSelectedBank(SelectedBAnk)
                    setSelected_EC_Data(Selected_EC)
                    setDate(data.date)
                    setExpense(data.Expenses)
                    setDiscription(data.Discription)
    
                    Select_ExpenseType(data.ExpenseClassificationID, data.ExpenseTypeID)
                })
            }
             })
            })
        }
    
    }, [show,is_add_new, Expense_Id])

    // const Select_EC_List = () =>{

        // SelectExpenseClassificationList(Comp_Id,ProjectID,(data) =>{
        //     setEC_Data(data)
        // })
    // }

    const Select_ExpenseType = (EC_Id, ExpenseTypeID ) =>{
            SelectExpenseType(Comp_Id, ProjectID,EC_Id,(data) =>{
            
            setET_Data(data)

            if(is_add_new === false)
            {
               
                var Selected_ET = data.find(x => x.ExpenseTypeID === ExpenseTypeID)
                setSelected_ET_Data(Selected_ET)
            }
        })
    }
   
    const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }

    const validation = () =>{
        
        if(!Selected_EC_Data)
        {
            setError({Expense_Classification: "Must be Required", ExpenseType: "", Expense: ""})
            document.getElementById("ExpenseClassification").focus()
            return false
        } else if (!Selected_ET_Data){
            setError({Expense_Classification: "", ExpenseType: "Must be Required", Expense: ""})
            document.getElementById("ExpenseType").focus()
            return false
        } else if (!SelectedBank){
            setError({Expense_Classification: "", ExpenseType: "", Expense: "",BankErr:'Required'})
            document.getElementById("Bank").focus()
            return false
        }
         else if (Expense === 0.00){
            setError({Expense_Classification: "", ExpenseType: "", Expense: "Must be Required",BankErr:''})
            document.getElementById("Expenses").focus()
            return false
        }

        setError({Expense_Classification: "", ExpenseType: "", Expense: ""})
            return true
    }

    const save  = () =>{

        if(validation())
        {
           
            var obj_save = {
                ExpenseID: Expense_Id,
                ExpenseClassificationID: Selected_EC_Data.ExpenseClassificationID,
                ExpenseTypeID: Selected_ET_Data.ExpenseTypeID,
                Expenses: Expense,
                ExpenseDate: date,
                Discription: Discription,
                ExpenseBy:SelectedBank.BankID ,
            }
            setBtn_Disabled(true)
            SaveExpences(obj_save, Comp_Id,ProjectID, is_add_new,(message) =>{
                setBtn_Disabled(false)
                clearFields()
            })
        }

    }


    const clearFields = () => {

        onHide()
        setEC_Data([])
        setSelected_EC_Data(null)
        setET_Data([])
        setSelected_ET_Data(null)
        setDate(GetCurrentDate())
        setExpense(0.00)
        setDiscription('')
        setSelectedBank(null)
        setError({Expense_Classification: "", ExpenseType: "", Expense: ""})
    }

    return (
        <>
            <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" >
                <Modal.Header closeButton className="header_title " >
                    <Modal.Title > Add Expense </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col lg={6} xl={6} md={6} >
                                <Autocomplete
                                    name="ExpenseClassification"
                                    id="ExpenseClassification"
                                    options={EC_Data}
                                    getOptionLabel={(option) => option.ExpenseClassification ? option.ExpenseClassification : ""}
                                    value={Selected_EC_Data}
                                    onChange={(e, value) => {

                                        if (value) {
                                            setSelected_EC_Data(value)
                                            Select_ExpenseType(value.ExpenseClassificationID,null)
                                        }
                                        else {
                                            setSelected_EC_Data(null)
                                            setSelected_ET_Data(null)
                                            setET_Data([])
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="ExpenseClassification" id="ExpenseClassification" label="Expense Classification"
                                        />}

                                />
                                <span style={{color: "red"}} >{Error.Expense_Classification}</span>
                            </Col>

                            <Col lg={6} xl={6} md={6} >
                                <Autocomplete
                                    name="ExpenseType"
                                    id="ExpenseType"
                                    options={ET_Data}
                                    getOptionLabel={(option) => option.ExpenseType ? option.ExpenseType : ""}
                                    value={Selected_ET_Data}
                                    onChange={(e, value) => {

                                        if (value) {
                                            setSelected_ET_Data(value)
                                        }
                                        else {
                                            setSelected_ET_Data(null)
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="ExpenseType" id="ExpenseType" label="Expense Type"
                                        />}

                                />
                                <span style={{color: "red"}} >{Error.ExpenseType}</span>
                            </Col>
                        </Row>

                        <Row className=" row_margin_top row_margin_left row_margin_right" style={{marginTop: "4%", marginBottom: "4%"}} >
                            <Col lg={4} xl={4} md={4} >
                                <TextField
                                    name="Date"
                                    id="Date"
                                    label="Date"
                                    value={ date }
                                    onChange = {e => { setDate(e.target.value) }}
                                    type="date"
                                    fullWidth
                                />
                            </Col>
                            <Col sm={4} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="Bank"
                                    id="Bank"
                                    options={Bank_List}
                                    getOptionLabel={(option) => option.BankName ? option.BankName : ""}
                                    value={SelectedBank}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedBank(value)
                                        }
                                        else{
                                            setSelectedBank(null)
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="Bank" label="Bank"
                                        />
                                    }
                                />
                                 <span style={{color: "red"}} >{Error.BankErr}</span>
                            </Col>
                            <Col lg={4} xl={4} md={4} >
                                <CurrencyTextField
                                    name="Expenses"
                                    id = "Expenses"
                                    label="Expenses"
                                    currencySymbol=""
                                    value={Expense}
                                    onChange = {(e,value) =>{
                                        setExpense(value)
                                    }}
                                    fullWidth

                                />
                                <span style={{color: "red"}} >{Error.Expense}</span>
                            </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col >
                                <textarea placeholder="Enter Description" onInput={ toInputUppercase } onChange={e => setDiscription(e.target.value)} name="Note" value={Discription} rows={4} cols={40} ></textarea>
                            </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                {btn_edit && (<Button variant="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={save}  disabled={btn_Disabled}>

                                {
                             btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                                }

                                </Button>)}
                                <Button variant="btn btn-primary rounded-pill" onClick={clearFields} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></Button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    show_screen: state.SCREEN,    
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,

})

export default connect(mapStateToProps)(AddExpense_Modal)
