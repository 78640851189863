
import React, { useEffect, useState, createRef } from 'react'
import ReactDOM from 'react-dom'
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {Link} from "react-router-dom"
import { Row, Col, Card, Button } from 'react-bootstrap'
import { Add, Edit, Delete } from '@material-ui/icons'
import { connect } from "react-redux"
 import FinYearModal from './FinYearModal';
 import { SelectFinYearList, DeleteFinYear } from '../../Services/FinYear'
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import { SHOW_SCREEN } from "../../React_Redux/Actions"
import Swal from 'sweetalert2'
import Loader from '../Loader';

function FinYearList(props) {
    const [FinYear_Modal, setFinYear_Modal] = useState(false)
    const [IsAddNew, setIsAddNew] = useState(null)
    const [FinYearData, setFinYearData] = useState([])
    const [Year_Id, setYear_Id] = useState(0)
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete]= useState(true)
    const [IsLoader, setIsLoader] = useState(false)

    useEffect(() => {
     document.title = "Distribution / FinYear"
//      SelectGroupScreenAccess(props.Group_Id,13,138,(data)=>{
            
//       setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
//       setBtn_delete(parseInt(data.Del) === 0 ? false : true)

//       props.SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
//       parseInt(data.Edit) === 0 ? false : true,
//       parseInt(data.Del) === 0 ? false : true,
//       data.OtherAccess
//       )
      
//   })    
     Select_FinYearList()
    }, [])

    const Select_FinYearList = () =>{
      showLoader()
        SelectFinYearList(data=>{
          hideLoader()
            setFinYearData(data)
        })
    }
    const theme = createTheme({
      overrides: {
        MuiTableCell: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
          }
        }
      }
    });
    const showLoader = () => {
      setIsLoader(true)
     
    }
  const  hideLoader = () => {
    setIsLoader(false)
    }
    const show_Modal = () =>{
        setFinYear_Modal(true);
    }
    const hide_Modal = () =>{
        setFinYear_Modal(false)
        setIsAddNew(false)
        Select_FinYearList()
    }
    const column = [
        {
            name: "Year_Id",
            label: "Year ID",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
           {
            name: "Name",
            label: "Name",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
           {
            name: "fin_year",
            label: "Fin Year",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
        {
            name: "actions",
            label: "Actions",
            options: {
             customHeadLabelRender: (columnMeta)=>{
              return(
                  <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
              )
             },
             customBodyRender: (dataIndex, rowIndex) => {
              
                const _FinYearData = FinYearData
                if(_FinYearData[rowIndex.rowIndex] != null)
                  return (
                      <>
                        <Link to={"#"} onClick={e =>{
                           setIsAddNew(false)
                           setYear_Id(_FinYearData[rowIndex.rowIndex].Year_Id)
                            show_Modal()
                          }} > <Edit/> </Link> {
                            btn_delete ?
                              <Link to={"#"} onClick={e =>{
                              Delete_BranchGroup(_FinYearData[rowIndex.rowIndex].Year_Id)
                            
                             }} > | <Delete/> </Link> : null
                          } 
                      </>
                  );
              }
            }
        }
    ];

    const Delete_BranchGroup = (Year_Id) =>{
        if(Year_Id){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            })
    
            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: `Yes, Delete it`,
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
    
                    await DeleteFinYear(Year_Id,(status,message) => {
                        Select_FinYearList()
                        if(status){
                            swalWithBootstrapButtons.fire(
                                `Deleted`,
                                `${message}`,
                                'success'
                            )
                        }
                        else{
                            swalWithBootstrapButtons.fire(
                                `Deleted`,
                                `${message}`,
                                'error'
                            )
                        }
                       
                    })
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire(
                        'Cancelled',
                        `Your record is safe :)`,
                        'error'
                    )
                }
            })
        }
      
     
    }
    return ReactDOM.createPortal(
      <>
      <Row>
      <Col lg={6} xl={6} md={6} className="header_title" >
              <h2> Financal Years  </h2>
        </Col>
      </Row>
      <Card>
          <Row className="row_margin_left row_margin_right pt-2" style={{width:'80%', marginLeft:'17%'}} >
         
            <Col style={{textAlign: "right"}} >
             {btn_add && (<Button variant="btn btn-primary rounded-pill" onClick={e =>{
                show_Modal()
                setIsAddNew(true)
              }} style={{border: "none"}} > <Add />  Create New</Button> )}
            </Col>
          </Row>
          <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col>              
                <div className="table-responsive" >
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        data={FinYearData}
                        columns={column}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                    </MuiThemeProvider>
                </div>
              </Col>
          </Row>
          
      <FinYearModal 
        show = { FinYear_Modal }
        onHide = { hide_Modal }
        is_add_new = { IsAddNew }
        Year_Id = { Year_Id }
      />
          <Loader
        show={IsLoader}
        hide={hideLoader}
        />
      </Card>

                        
      </>  , document.getElementById('PageContents')
    )
}

const mapStateToProps = state =>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Year_Id,
  Group_Id : state.Login_User.User_Data.Group_Id,
  ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

})

export default connect(mapStateToProps, {SHOW_SCREEN})(FinYearList)

