import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectMedium =(Comp_Id,ProjectID,Callback) =>{
    
  axios.get(`${domain}/Medium/SelectMediumList/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SaveMedium = (Medium,DeletedMedium,callback) =>{
 
  axios.post(`${domain}/Medium/SaveMedium`,{
    Medium,
    DeletedMedium
  })
    .then(response=>{
        toast.configure()
        toast.info(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        callback()
    })
    .catch(err=>{
      console.error(err)})
}