import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "../SaleReports/Table.css"
import { rptSaleSummary } from '../../../Services/Distribution_ReportAPI'
import { SelectUserProjects } from '../../../Services/Projects'

function SaleSummaryReport({Comp_Name,Comp_Id,User_Name,User_Id,ConfigObj}) {

    const { ProjectID,FromDate,ToDate ,DeliveryID,DeliveryName} = useParams();

    const [ItemData, setItemData] = useState([]);
    const [GrandTotal, setGrandTotal] = useState(0);
    const [SelectedProject, setSelectedProject] = useState(null)
    // const [Item,setItem] = useState('')
    const [City, setCity] = useState('')
    const [Customer, setCustomer] = useState('')

    useEffect(() => {

        document.title = " Distribution / Sale Summary " 

        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('footer').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;

        rptSaleSummary(Comp_Id,ProjectID,FromDate,ToDate,DeliveryID,(mData)=>{
            debugger
                setItemData(mData)
            
        
        })
       
        SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
        mProjects.map(value=>{
            if(value.Project_ID == ProjectID){
                setSelectedProject(value)
            }
         })
        })
        localStorage.removeItem("ProductSaleLedgerInvoiceWise")
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
            <Row  style={{ textAlign: 'center', color: "black" }}>
            <Col sm={3} md={3} xl={3}><img src={ConfigObj.Logo} width="100" height="100" /></Col>
            <Col sm={6} md={6} xl={6}>
                    <h2> {Comp_Name} </h2> <br /> <h3>Sale Summary</h3>
                </Col>
                </Row>
                <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Project:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{SelectedProject && SelectedProject.ProjectName}</b>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Delivery:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{DeliveryName}</b>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
            <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>From Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{FromDate}</b>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>To Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{ToDate}</b>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Cust No</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Sale Inv No</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Sale Date</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Name</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Sector</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Gross Val.</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Discount</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Total Gst</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Add Tax U/S 236</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Net Value + Tax</th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                         
                        {
                            ItemData.map((Itemdata, index) => {
                            return(
                                                               
                                <>
                                {
                                    Itemdata.title === true ? 
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none',borderBottom:'none'}}>
                                        <td colSpan={7} style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.ManufacturedBy}</td>
                                       </tr>
                                    :
                                    Itemdata.CustomerID === "Totals" ? 
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none'}}>
                                     <td colSpan={2} style={{ width: '10%', fontWeight:'bold',textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >Invoices</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.SaleInvoiceID}</td>
                                        <td colSpan={2}  style={{ width: '10%',fontWeight:'bold', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >Totals :</td>
                                        <td style={{ width: '10%',fontWeight:'bold', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.InvoiceAmount}</td>
                                        <td style={{ width: '10%',fontWeight:'bold', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.TotalDiscount} </td>
                                        <td style={{ width: '10%',fontWeight:'bold', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.AddTaxAmt} </td>
                                        <td style={{ width: '10%',fontWeight:'bold', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.IncomeTaxAmt} </td>
                                        <td style={{ width: '10%',fontWeight:'bold', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.GrandTotal} </td>
                                    </tr>
                                    :
                                    <tr key={index} >
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.CustomerCode}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.SaleInvoiceNo} </td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}    >{Itemdata.InvoiceDate}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.CustomerName}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.City}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.InvoiceAmount}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.TotalDiscount} </td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.IncomeTaxAmt} </td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.AddTaxAmt} </td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.GrandTotal} </td>
                                    </tr>
                                }
                                </>
                               
                            )}
                            )
                        }
                        {/* <tr id='footer_balance'>
                            <td id='footer_td_cb' colSpan="5" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  >Grand Total :</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  >{GrandTotal}</td>
                        </tr> */}
                    </tbody>
                </table>

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig
})

export default connect(mapStateToProps)(SaleSummaryReport)
