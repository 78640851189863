import React from 'react'
import ReactDOM from 'react-dom'
import { connect} from 'react-redux'
import {  OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { UpdateUserProfile } from '../../Services/SecurityAPI'
import { domain_for_image } from '../../Services/Common'
import { SelectUser } from '../../Services/SecurityAPI'
import { setLoginData } from '../../React_Redux/Actions/index'
import { GetFileFromLocalDirectory } from '../../Services/ControlPanelAPI'
export class Profile extends React.Component {
  constructor(props){
      super(props)

      this.state = {
        imgSrc:'https://cdn-icons-png.flaticon.com/512/149/149071.png',
        file:   null ,
        Name:'',
        PhoneNo:'',
        Email:'',
        Address:''
      }
    
  }


 
  componentDidMount()
  {
    document.title = " Distribution / Profile"
    SelectUser(this.props.UserID,async(mData)=>{
        if(mData.length > 0 ){
            this.setState({
                Name :mData[0].User_name,
                PhoneNo:mData[0].PhoneNo,
                Email:mData[0].Email,
                Address:mData[0].Address === null ? '' : mData[0].Address
            })
            if(mData[0].UserPic){
                let marr = mData[0].UserPic.split('/')
                mData[0].UserPic = marr[marr.length - 1]
                const _localProfilePic1 = await GetFileFromLocalDirectory(mData[0].UserPic)
                mData[0].UserPic = _localProfilePic1
                this.setState({
                    imgSrc: mData[0].UserPic
                })
            }
        }
    })
  }
  renderTooltip = (props) => {
      return(
    <Tooltip id="button-tooltip" {...props}>
      Click to Change Profile Pic
    </Tooltip>
      )
  };
   change_Image = (e) => {

    var img = document.getElementById('img')
    if (e.target.files && e.target.files[0]) {

        this.setState({file: e.target.files[0]})
      var reader = new FileReader();

      reader.onload = function (e) {
        img.src = e.target.result;
      }

      reader.readAsDataURL(e.target.files[0]);
    }
  }
  Save_User = () => {

        var formdata = new FormData()

        formdata.append("User_Id", this.props.UserID)
        formdata.append("Address",this.state.Address)
        formdata.append("Email",this.state.Email)
        formdata.append("PhoneNo",this.state.PhoneNo)
        formdata.append("Name",this.state.Name)
        formdata.append("image", this.state.file)
        UpdateUserProfile(formdata, () => {
            SelectUser(this.props.UserID,(mData)=>{
            this.props.setLoginData(mData[0], this.props.token);
            })
        })
}
render(){
    return ReactDOM.createPortal (
        <>
            <div className="block-header">
                <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <h2>Profile
                        </h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4 col-lg-5">
                    <div class="card text-center">
                        <div class="card-body">
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={this.renderTooltip}
                            >
                                <img 
                                 src={this.state.imgSrc} 
                                 id="img" 
                                 style={{ cursor: 'pointer', marginTop: '3%' }} 
                                 onClick={e => {
                                    document.getElementById("img_file").click()
                                }}
                                class="rounded-circle avatar-lg img-thumbnail"
                                alt="profile-image"
                                />
                            </OverlayTrigger>
                            <input type="file" name="img_file" id="img_file" hidden={true} onChange={this.change_Image} />
                            {/* <img src="assets/images/users/avatar-1.jpg" class="rounded-circle avatar-lg img-thumbnail"
                                alt="profile-image" /> */}

                            <h4 class="mb-0 mt-2">{this.state.Name}</h4>
                            {/* <p class="text-muted font-14">Founder</p> */}

                            <div class="text-start mt-3">
                               
                                <p class="text-muted mb-2 font-13"><strong>Full Name :</strong> <span class="ms-2">{this.state.Name}</span></p>

                                <p class="text-muted mb-2 font-13"><strong>Mobile :</strong><span class="ms-2">{this.state.PhoneNo}</span></p>

                                <p class="text-muted mb-2 font-13"><strong>Email :</strong> <span class="ms-2 ">{this.state.Email}</span></p>
                                <h4 class="font-13 text-uppercase">Address :</h4>
                                <p class="text-muted font-13 mb-3">
                                    {this.state.Address}
                                </p>
                                {/* <p class="text-muted mb-1 font-13"><strong>Address :</strong> <span class="ms-2">USA</span></p> */}
                            </div>

                            <ul class="social-list list-inline mt-3 mb-0">
                                <li class="list-inline-item">
                                    <a href="https://www.facebook.com" target={"_blank"} class="social-list-item border-primary text-primary"><i
                                        class="mdi mdi-facebook"></i></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="https://www.google.com" target={"_blank"}  class="social-list-item border-danger text-danger"><i
                                        class="mdi mdi-google"></i></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="https://www.twitter.com" target={"_blank"}  class="social-list-item border-info text-info"><i
                                        class="mdi mdi-twitter"></i></a>
                                </li>
                                <li class="list-inline-item">
                                    <a href="https://www.instagram.com" target={"_blank"}  class="social-list-item border-secondary text-danger"><i
                                        class="mdi mdi-instagram"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                   

                </div>

                <div class="col-xl-8 col-lg-7">
                    <div class="card">
                        <div class="card-body">
                            <ul class="nav nav-pills bg-nav-pills nav-justified mb-3">
                                <li class="nav-item">
                                    <a href="#settings" data-bs-toggle="tab" aria-expanded="false"  class="nav-link rounded-0">
                                        ABOUT
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div class="tab-pane show active" id="settings">
                                    <h5 class="mb-4 text-uppercase"><i class="mdi mdi-account-circle me-1"></i> Personal Info</h5>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="mb-3">
                                                <TextField
                                                    name="Name"
                                                    id="Name"
                                                    label="Name"
                                                    value={this.state.Name}
                                                    onChange={e => this.setState({Name:e.target.value})}
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <TextField
                                                    name="email"
                                                    id="email"
                                                    label="Email Address"
                                                    value={this.state.Email}
                                                    onChange={e => this.setState({Email:e.target.value})}
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mb-3">
                                                <TextField
                                                    name="Phone"
                                                    id="Phone"
                                                    label="Phone No."
                                                    value={this.state.PhoneNo}
                                                    onChange={e => this.setState({PhoneNo:e.target.value})}
                                                    fullWidth
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="mb-3">
                                                <label for="userbio" class="form-label">Address</label>
                                                <textarea class="form-control" id="userbio" rows="4" value={this.state.Address} onChange={e => this.setState({Address:e.target.value})} placeholder="Write something..."></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="text-end">
                                        <button class="btn btn-primary mt-2  rounded-pill" onClick={this.Save_User}><i class="mdi mdi-content-save"></i> Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>, document.getElementById('PageContents')
    )
}
}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Group_Id : state.Login_User.User_Data && state.Login_User.User_Data.Group_Id,
    Project_ID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
    UserID: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    token:state.Login_User && state.Login_User.loginToken
})

export default connect(mapStateToProps,{setLoginData})(Profile)