import React, { useEffect, useState } from 'react'
import { Modal, Card, Row, Col } from "react-bootstrap"
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Loop, Cancel } from "@material-ui/icons"
import { connect } from 'react-redux'
import Loader from '../Loader';
import MUIDataTable from "mui-datatables";

import { getAutoPO, SelectItemsWRTReOrderStock } from '../../Services/PurchaseOrderAPI' 
import { GetCurrentDate, GetFirstDateofCurrentMonth } from '../../Services/Common';

function AutoOrderModal({show, onHide, mSaveStock,Comp_Id,Project_ID,manufacturedByID, getLevel}) {

    const [inventoryLevel, setInventoryLevel] = useState('')
    const [from1Date, setFrom1Date] = useState(GetFirstDateofCurrentMonth())
    const [from2Date, setFrom2Date] = useState(GetFirstDateofCurrentMonth())
    const [from3Date, setFrom3Date] = useState(GetFirstDateofCurrentMonth())
    const [to1Date, setTo1Date] = useState(GetCurrentDate())
    const [to2Date, setTo2Date] = useState(GetCurrentDate())
    const [to3Date, setTo3Date] = useState(GetCurrentDate())
    const [isLoader, setIsLoader] = useState(false)

    const showLoader = () => {
        setIsLoader(true)
      }
      const hideLoader = () => {
        setIsLoader(false)
      }

    const get_Handler = async ()=>{
        showLoader()
        let mObj = {from1Date, from2Date, from3Date, to1Date, to2Date, to3Date, inventoryLevel, manufacturedByID, compId:Comp_Id, projectID:Project_ID}
        const res = await getAutoPO(mObj)
        hideLoader()
        if(res?.status){
            mSaveStock(res?.Data)
            const childData = {
                inventoryLevel, 
                from1Date,
                from2Date,
                from3Date,
                to1Date,
                to2Date,
                to3Date
            }
            getLevel(childData)
            onHide()
        }
        
    }
    return (
        <>
        <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" >
            <Modal.Header closeButton className="header_title" >
            <Modal.Title>Auto Purchase Order </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Row className=" row_margin_left row_margin_right" >
                    <Col sm={12} lg={6} xl={6} md={6} >
                        <TextField 
                            value={from1Date}
                            name="FromDate1"
                            label="From Date 1st" 
                            type= 'date'
                            onChange= {e => setFrom1Date(e.target.value)}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={6} xl={6} md={6} >
                        <TextField 
                            value={to1Date}
                            name="ToDate1"
                            label="To Date 1st" 
                            type= 'date'
                            onChange= {e => setTo1Date(e.target.value)}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={6} xl={6} md={6} >
                        <TextField 
                            value={from2Date}
                            name="FromDate2"
                            label="From Date 2nd" 
                            type= 'date'
                            onChange= {e => setFrom2Date(e.target.value)}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={6} xl={6} md={6} >
                        <TextField 
                            value={to2Date}
                            name="ToDate2"
                            label="To Date 2nd" 
                            type= 'date'
                            onChange= {e => setTo2Date(e.target.value)}
                            fullWidth
                        />
                    </Col>

                    <Col sm={12} lg={6} xl={6} md={6} >
                        <TextField 
                            value={from3Date}
                            name="FromDate3"
                            label="From Date 3rd" 
                            type= 'date'
                            onChange= {e => setFrom3Date(e.target.value)}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={6} xl={6} md={6} >
                        <TextField 
                            value={to3Date}
                            name="ToDate3"
                            label="To Date 3rd" 
                            type= 'date'
                            onChange= {e => setTo3Date(e.target.value)}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={12} xl={12} md={12} >
                        <TextField 
                            value={inventoryLevel}
                            name="inventoryLevel"
                            label="Inventrory Level" 
                            type= 'text'
                            onChange= {e => setInventoryLevel(e.target.value)}
                            fullWidth
                        />
                    </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                <button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={get_Handler} > <Loop /> <u>Load</u></button>
                                <button type="button" className="btn btn-primary rounded-pill" onClick={onHide} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
                <Loader
                    show={isLoader}
                    hide={hideLoader}
                    />
        </Modal>
        </>
    )
}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    projectId:state.PO && state.PO.Project_ID,

  })
export default connect(mapStateToProps)(AutoOrderModal)
