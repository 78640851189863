import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "../SaleReports/Table.css"
import { rptPayables } from '../../../Services/Purchase_ReportAPI'

function PayablesReport({Comp_Name,Comp_Id,User_Name,ConfigObj}) {

    const { ProjectID,FromDate,ToDate } = useParams();
    const [ItemData, setItemData] = useState([])
    const [Balance, setBalance] = useState(0)
    const [GrandTotal, setGrandTotal] = useState(0)
    const [TotalPaid, setTotalPaid] = useState(0)

    useEffect(() => {
        document.title = "Distribution / Payables" 

        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('footer').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;

        rptPayables(Comp_Id,ProjectID,FromDate,ToDate,(mData)=>{
            if(mData.length > 0){
            var Totals = mData.splice(-1);
            setBalance(Totals[0].Balance.split('~')[1])
            setGrandTotal(Totals[0].GrandTotal.split('~')[1])
            setTotalPaid(Totals[0].TotalPaid)
            setItemData(mData)
            }
        
        })
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
            <Row style={{ textAlign: 'center', color: "black" }}>
            <Col sm={3} md={3} xl={3}><img src={ConfigObj.Logo} width="100" height="100" /></Col>
            <Col sm={6} md={6} xl={6}>
                    <h2> {Comp_Name} </h2> <br /> <h3>Payables</h3>
            </Col>
            </Row>
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}> 
            <Col sm={6} md={6} xl={6} style={{ textAlign: 'left' }} >
                    <Row >
                        <Col lg={3} md={3} xl={3} style={{ borderLeft: '5px solid black', height: '20px', position: 'absolute', left: '10%' }}>
                            <b>From Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} style={{marginLeft:'26%'}}>
                            {ReverseDate(FromDate)}
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'right' }}>
                     <Row >
                            <Col lg={3} md={3} xl={3} style={{ borderLeft: '5px solid black', height: '20px',left:'60%',textAlign:'left'}}>
                            <b>To Date:</b>
                            </Col>
                            <Col lg={9} md={9} xl={9} style={{marginLeft:'-8%'}}>
                            {ReverseDate(ToDate)}
                            </Col>
                    </Row>
                </Col>

            </Row>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}   >Date</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >INVNo.</th>
                            <th style={{ width: '20%', textAlign: 'left', fontWeight: "bold" }} >Supplier Name</th>
                            <th style={{ width: '20%', textAlign: 'center', fontWeight: "bold" }} >Grand Total</th>
                            <th style={{ width: '20%', textAlign: 'center', fontWeight: "bold" }} >Total Paid</th>
                            <th style={{ width: '20%', textAlign: 'center', fontWeight: "bold" }} >Balance</th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                         
                        {
                            ItemData.map((Itemdata, index) => (
                                <>
                                    <tr key={index}>
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{Itemdata.InvoiceDate || Itemdata.InvoiceDate != '' && ReverseDate(Itemdata.InvoiceDate)}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.PurchaseInvoiceNo === "" ? "-" : Itemdata.PurchaseInvoiceNo}</td>
                                        <td style={{ width: '20%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.SupplierName}</td>
                                        <td style={{ width: '20%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.GrandTotal} </td>
                                        <td style={{ width: '20%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.TotalPaid}</td>
                                        <td style={{ width: '20%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Balance}</td>
                                    </tr>
                                  
                                </>
                            ))
                        }
                        <tr id='footer_balance' style={{border:'2px solid black',borderLeft:'none',borderRight:'none'}}>
                            <td id='footer_td_cb' colSpan="3" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  >Totals: </td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  > {GrandTotal}</td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  >{TotalPaid}</td>
                            <td id='' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  >{Balance}</td>
                        </tr>
                    </tbody>


                </table>
                {/* </div> */}

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig

})


export default connect(mapStateToProps)(PayablesReport)
