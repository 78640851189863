import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectPurchaseOrderList =(Comp_Id,ProjectID,SupplierID,Callback) =>{
  axios.get(`${domain}/PurchaseOrder/SelectPurchaseOrderList/${Comp_Id}/${ProjectID}/${SupplierID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectPendingPurchaseOrderList =(Comp_Id,ProjectID,SupplierID,Callback) =>{
  axios.get(`${domain}/PurchaseOrder/SelectPendingPurchaseOrderList/${Comp_Id}/${ProjectID}/${SupplierID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectItemDetails =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/PurchaseOrder/SelectItemDetails/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const GetMaxPurchaseNo =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/PurchaseOrder/GetMaxPurchaseNo/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectPurchaseOrderMaster =(Comp_Id,ProjectID,PurchaseOrderID,Callback) =>{
  axios.get(`${domain}/PurchaseOrder/SelectPurchaseOrderMaster/${Comp_Id}/${ProjectID}/${PurchaseOrderID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectPurchaseOrderDetail =(Comp_Id,ProjectID,PurchaseOrderID,Callback) =>{
    axios.get(`${domain}/PurchaseOrder/SelectPurchaseOrderDetail/${Comp_Id}/${ProjectID}/${PurchaseOrderID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
export const getAutoPO = async ({from1Date, from2Date, from3Date, to1Date, to2Date, to3Date, inventoryLevel,manufacturedByID, compId, projectID})=>{
 const res = await axios.post(`${domain}/PurchaseOrder/getAutoPO`, {
    from1Date, from2Date, from3Date, to1Date, to2Date, to3Date, inventoryLevel,manufacturedByID, compId, projectID
  })
  return res?.data
}
export const SavePurchaseOrder = (PurchaseOrderMasterData,PurchaseOrderDetailData,Deleted_PO_Detail_Data,isAddNew,callback) =>{
  axios.post(`${domain}/PurchaseOrder/SavePurchaseOrder`,{
    PurchaseOrderMasterData,PurchaseOrderDetailData,
    Deleted_PO_Detail_Data,
     isAddNew
  })
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback()
      })
      .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
}

export const SelectItemsWRTMinStock =(Comp_Id,ProjectID,SupplierID,Callback) =>{
    axios.get(`${domain}/PurchaseOrder/SelectItemsWRTMinStock/${Comp_Id}/${ProjectID}/${SupplierID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  
  export const SelectItemsWRTMaxStock =(Comp_Id,ProjectID,Callback) =>{
    axios.get(`${domain}/PurchaseOrder/SelectItemsWRTMaxStock/${Comp_Id}/${ProjectID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const SelectItemsWRTReOrderStock =(Comp_Id,ProjectID,SupplierID,Callback) =>{
    axios.get(`${domain}/PurchaseOrder/SelectItemsWRTReOrderStock/${Comp_Id}/${ProjectID}/${SupplierID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }

  export const SelectPOWRTINV =(Comp_Id,ProjectID, PurchaseOrderID,Callback) =>{
    axios.get(`${domain}/PurchaseOrder/SelectPOWRTINV/${Comp_Id}/${ProjectID}/${PurchaseOrderID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  
  export const SelectItemWRTSupplier =(Comp_Id,ProjectID, SupplierID,Callback) =>{
    axios.get(`${domain}/PurchaseOrder/SelectItemWRTSupplier/${Comp_Id}/${ProjectID}/${SupplierID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }

  

  export const SelectPurchaseOrderWRTCode =(Comp_Id,ProjectID, PONo,Callback) =>{
    axios.get(`${domain}/PurchaseOrder/SelectPurchaseOrderWRTCode/${Comp_Id}/${ProjectID}/${PONo}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }

  export const SelectPurchaseOrderDetailWRTCode =(Comp_Id,ProjectID, PurchaseOrderID,Callback) =>{
    axios.get(`${domain}/PurchaseOrder/SelectPurchaseOrderDetailWRTCode/${Comp_Id}/${ProjectID}/${PurchaseOrderID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }