import React, { useState, useEffect, useRef } from 'react'
import  ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { Row, Col, Card,Spinner ,Tabs, Tab  } from "react-bootstrap"
import { Cancel, Save, Print } from '@material-ui/icons'
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { connect } from 'react-redux'
import { enableRipple } from '@syncfusion/ej2-base';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import { getValue } from '@syncfusion/ej2-base';
import Swal from 'sweetalert2'
import {SelectManufacturedByList} from '../../Services/manufactureAPI'
import { SelectCompanyGroupAgainstManufacturer  } from '../../Services/CompanyGroupAPI'
import { SaveScheme,SelectItemForDisCompanyAndGroup } from '../../Services/ItemsAPI'
import { SelectProductGroupAgainstManufacturer } from '../../Services/ProductGroupAPI'
import Loader from '../Loader'
import { GetCurrentDate } from '../../Services/Common'

export class DisPolicyForCG extends React.Component {
    constructor(props) {
        super(props)
     
        this.state = {
            DiscountCGPolicy: [],
            DeletedDiscountCGPolicy: [],
            btn_Add: true,
            btn_Edit: true,
            btn_Delete: true,
            Others: null,
            btn_Disabled: false,
            IsLoader: false,
            ManufacturedBy_List:[],
            SelectedManufacturedBy_ListOption: null,
            CG_List: [],
            SelectedCG: null,


        }
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
        this.validationRules = { required: true };
        this.bg_Ref = React.createRef()
        this.grid = null
        this.Comp_Id = this.props.Comp_Id
        this.ProjectID = this.props.ProjectID
        this.BGValidation = {
            required: [this.customBGValidation, 'Reason must be Entered'],
        };
    }
    sortingOptions = {
        columns: [{ field: 'SrNo', direction: 'Ascending' }]
    };

    customBGValidation(args) {
        return getValue('value', args) != ''
    }
  
    componentDidMount () {
        document.title = 'Discount Policy'
        SelectManufacturedByList(this.Comp_Id, this.ProjectID,(mData)=>{
            this.setState({ManufacturedBy_List: mData})

            
        })
     this.getData()
    }
    getData = () =>{
        SelectItemForDisCompanyAndGroup(this.Comp_Id,this.ProjectID,(mData)=>{
     
            if(mData.length > 0){
               mData.map((value,index1)=>{
                   value.SrNo = index1 + 1
                   if(value.PGID === 0){
                    value.PGName = 'SELECT ALL'
                   }
               })
               if(mData.length > 0){
                this.setState({DiscountCGPolicy: mData})
            }
            this.setState({DeletedDiscountCGPolicy: []})
               
              
            }
            
           })
    }
    toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    showLoader = () => {
        this.setState({ IsLoader: true })

    }
    hideLoader = () => {
        this.setState({ IsLoader: false })
    }
     CGList = (ManufacturedByID)=>{
        
        SelectProductGroupAgainstManufacturer(this.Comp_Id,this.ProjectID,ManufacturedByID,(mData)=>{
                var mobj = {PGID:0, PGName: 'SELECT ALL'}
                mData.unshift(mobj)
                if(this.mCDD){
                    this.mCDD.props.options.length = 0 
                    for(let i=0; i<mData.length; i++){

                        this.mCDD.props.options.push(mData[i])
                    }
                }
                
                this.setState({CG_List: mData})   
        })
    }
   
     ManufacturerDD = (rowValue) => {
  
        return (
            <Autocomplete
                name="ManufacturedBy"
                id="ManufacturedBy"
                defaultValue={this.state.ManufacturedBy_List.find(v => v.ManufacturedBy === rowValue.ManufacturedBy)}
                options={ this.state.ManufacturedBy_List}
                getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""}
                onChange={(e, value) => {
                    if (value) {
                        
                        this.setState({SelectedManufacturedBy_ListOption: value},()=>{
                            
                            this.CGList(value.ManufacturedByID) 
                        })
                        
                       
                    }
                    else {
                        this.setState({SelectedManufacturedBy_ListOption: null, SelectedCG:null, CG_List:[]})
                       
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="ManufacturedBy" />}
            />
        )
    }
    CGDD = (rowValue) => {
        this.mCDD = <Autocomplete
        name="PGName"
        id="PGName"
        defaultValue={this.state.CG_List.find(v => v.PGName === rowValue.PGName)}
        options={ this.state.CG_List}
        getOptionLabel={(option) => option.PGName ? option.PGName : ""}
        onChange={(e, value) => {
            if (value) {
                this.setState({SelectedCG: value})
            }
            else {
                this.setState({SelectedCG: null})
            }
        }}
        renderInput={(params) =>
            <TextField {...params} name="PGName" />}
    />
        return (
            this.mCDD
        )
    }
    

     TargetFromQty = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"
            id="TargetFromQty"
            className="textbox"
            name='TargetFromQty'
         
            defaultValue={rowValue.TargetFromQty}
        />)
    }
     Discount1Per = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"   
            id="Discount1Per"
            className="textbox"
            name='Discount1Per'
          
            defaultValue={rowValue.Discount1Per}
        />)
    }
     Discount2Per = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"
            id="Discount2Per"
            className="textbox"
            name='Discount2Per'
          
            defaultValue={rowValue.Discount2Per}
        />)
    }
     BonusQty = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"
            id="BonusQty"
            className="textbox"
            name='BonusQty'
           
            defaultValue={rowValue.BonusQty}
        />)
    }
     IsClaimable = (rowValue) => {
        return (<input
            style={{ width: "40%" }}
            type="checkbox"
            id="IsClaimable"
            className="textbox"
            name='IsClaimable'
           
            defaultValue={rowValue.IsClaimable}
        />)
    }
     OurSharePer = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"
            id="OurSharePer"
            className="textbox"
            name='OurSharePer'
          
            defaultValue={rowValue.OurSharePer}
        />)
    }
     StartDate = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="date"
            id="StartDate"
            className="textbox"
            name='StartDate'
          
            defaultValue={rowValue.StartDate}
        />)
    }
     EndDate = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="date"
            id="EndDate"
            className="textbox"
            name='EndDate'
          
            defaultValue={rowValue.EndDate}
        />)
    }
     Criteria = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"
            id="Criteria"
            className="textbox"
            name='Criteria'
           
            defaultValue={rowValue.Criteria}
        />)
    }
     FlatRate = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"
            id="FlatRate"
            className="textbox"
            name='FlatRate'
           
            defaultValue={rowValue.FlatRate}
          
        />)
    }
     Remarks = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="text"
            id="Remarks"
            className="textbox"
            name='Remarks'
            onInput={this.toInputUppercase}
            defaultValue={rowValue.Remarks}
        />)
    }
     RefrenceNo = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="text"
            id="RefrenceNo"
            className="textbox"
            name='RefrenceNo'
            onInput={this.toInputUppercase}
            defaultValue={rowValue.RefrenceNo}
        />)
    }
     RefrenceDate = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="date"
            id="RefrenceDate"
            className="textbox"
            name='RefrenceDate'
            defaultValue={rowValue.RefrenceDate}
        />)
    }
     DiscountPolicy_Handler = () =>{
        if(this.state.DiscountCGPolicy.length > 0 || this.state.DeletedDiscountCGPolicy.length > 0){
          
            for(let  i=0; i<this.state.DiscountCGPolicy.length; i++){
                if(typeof this.state.DiscountCGPolicy[i].TargetFromQty === 'undefined' || (this.state.DiscountCGPolicy[i].TargetFromQty) == 0 || this.state.DiscountCGPolicy[i].TargetFromQty == null )
                {
                 Swal.fire({
                     icon: 'info',
                     text: `TargetFromQty is required at Sr No. ${this.state.DiscountCGPolicy[i].SrNo}...`,
                 })
                 return
                }
                // if(typeof this.state.DiscountCGPolicy[i].Criteria === 'undefined' || (this.state.DiscountCGPolicy[i].Criteria) == 0 || this.state.DiscountCGPolicy[i].Criteria == null )
                // {
                //  Swal.fire({
                //      icon: 'info',
                //      text: `Criteria is required at Sr No. ${this.state.DiscountCGPolicy[i].SrNo}...`,
                //  })
                //  return
                // } 
                // if(typeof this.state.DiscountCGPolicy[i].Discount1Per === 'undefined' || (this.state.DiscountCGPolicy[i].Discount1Per) == 0 || this.state.DiscountCGPolicy[i].Discount1Per == null )
                // {
                //  Swal.fire({
                //      icon: 'info',
                //      text: `Discount 1 % is required at Sr No. ${this.state.DiscountCGPolicy[i].SrNo}...`,
                //  })
                //  return
                // }
                this.state.DiscountCGPolicy[i].PGID === 0 ?  this.state.DiscountCGPolicy[i].IsAllForCompany = 1 :  this.state.DiscountCGPolicy[i].IsAllForCompany = 0
                this.state.DiscountCGPolicy[i].PGID !== 0 ?  this.state.DiscountCGPolicy[i].IsAllForCG = 1 :  this.state.DiscountCGPolicy[i].IsAllForCG = 0
            }
           this.setState({btn_Disabled:true})
           debugger
            SaveScheme(this.state.DiscountCGPolicy,this.state.DeletedDiscountCGPolicy,(status)=>{
                this.setState({btn_Disabled:false})
                if(status){
                    this.getData()
                }
              
               
            })
        }
    }
     DiscountCGbeforeRowInsert = (rowValue) => {

        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.SrNo) {
                    if (rowValue.requestType != "cancel") {
                        rowValue.data.ManufacturedBy = this.state.SelectedManufacturedBy_ListOption && this.state.SelectedManufacturedBy_ListOption.ManufacturedBy
                        rowValue.data.ManufacturedByID =this.state.SelectedManufacturedBy_ListOption && this.state.SelectedManufacturedBy_ListOption.ManufacturedByID
                        rowValue.data.PGID =this.state.SelectedCG && this.state.SelectedCG.PGID
                        rowValue.data.PGName =this.state.SelectedCG && this.state.SelectedCG.PGName
                        // rowValue.data.StartDate = GetCurrentDate()
                        // rowValue.data.EndDate = GetCurrentDate()
                        rowValue.data.RefrenceDate = GetCurrentDate()
                        if(rowValue.data.SchemeID)
                         {rowValue.data.action = rowValue.action}
                        else
                      {
                        rowValue.data.action = 'add'
                        rowValue.data.IsStopDiscount =  rowValue.data.IsStopDiscount ?  1 : 0
                        rowValue.data.IsClaimable =  rowValue.data.IsClaimable ?  1 : 0
                        this.setState({SelectedManufacturedBy_ListOption:null,SelectedCG:null })
                      }

                    }
                }
                else {
                    rowValue.data.SrNo = this.state.DiscountCGPolicy.length + 1
                   
                }
            }
        }

    }
     DiscountCGafterRowInsert = (rowValue) => {
        if (rowValue.requestType == "beginEdit") {
            
            this.setState({SelectedManufacturedBy_ListOption:{"ManufacturedBy": rowValue.rowData.ManufacturedBy, "ManufacturedByID": rowValue.rowData.ManufacturedByID},
            SelectedCG:{"PGName": rowValue.rowData.PGName, "PGID": rowValue.rowData.PGID}
        
        })
        this.CGList(rowValue.rowData.ManufacturedByID)
          }
        if (rowValue.requestType === 'delete') {
            if (rowValue.data[0].SchemeID) {
                this.setState({DeletedDiscountCGPolicy:this.state.DeletedDiscountCGPolicy.concat(rowValue.data)})
               
            }
        }
       
    }
  render() {
    return ReactDOM.createPortal (
        <>
        <div className="block-header">
        <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12">
                <h2>Discount Policy For Company || Groups
                </h2>
            </div>
        </div>
    </div>
    <Card>
    <Row className="row-margin-left row-margin-right">
    <Col lg={12} xl={12} sm={12}>
    <Row className="row_margin_top row_margin_left row_margin_right pt-4" style={{ width: '80%', marginLeft: '10%' }} >
    <div style={{ display: "flex", width: "100%", height: "100%", overflow: "hidden" }}>
    <div style={{ flex: "1", width: "100%", height: "100%" }}>
    <GridComponent dataSource={this.state.DiscountCGPolicy} actionBegin={this.DiscountCGbeforeRowInsert} ref={g => this.grid = g} actionComplete={this.DiscountCGafterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: this.state.btn_Edit, allowDeleting: this.state.btn_Delete, allowAdding: this.state.btn_Add, newRowPosition: 'Bottom' }} sortSettings={this.sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={this.toolbarOptions}>
    <ColumnsDirective>
    <ColumnDirective width='70' field='SrNo' headerText='SrNo #' Format="{0:n}" allowEditing={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>
    
    <ColumnDirective width='250' field='ManufacturedBy' headerText='Manufactured By'  editTemplate={this.ManufacturerDD}  textAlign='Left'/>
    <ColumnDirective width='250' field='PGName' headerText='Product Group'  editTemplate={this.CGDD}  textAlign='Left'/>
    <ColumnDirective width='150' field='TargetFromQty' headerText='From Target' editTemplate={this.TargetFromQty} textAlign='Left' />
    <ColumnDirective width='150' field='Criteria' headerText='Criteria' editTemplate={this.Criteria} textAlign='Left' />
    <ColumnDirective width='150' field='Discount1Per'  headerText='Discount 1 %' editTemplate={this.Discount1Per} textAlign='Left'></ColumnDirective>
    <ColumnDirective width='150' field='Discount2Per' defaultValue='0'  headerText='Discount 2 %' editTemplate={this.Discount2Per} textAlign='Left'></ColumnDirective> 
    {/*<ColumnDirective width='200' field='IsStopDiscount'   headerText='Stop Discount' editTemplate={IsStopDiscount} template = {IsStopDiscount} ></ColumnDirective>*/} 
    <ColumnDirective width='200' field='IsStopDiscount' type = "boolean" displayAsCheckBox = {true} editType = "booleanedit"  headerText='Stop Discount' ></ColumnDirective>
    <ColumnDirective width='150' field='OurSharePer' defaultValue='0' headerText='Our Share %' editTemplate={this.OurSharePer} textAlign='Left'></ColumnDirective>
    <ColumnDirective width='150' field='IsClaimable' type = "boolean" displayAsCheckBox = {true} editType = "booleanedit"  headerText='Claimable'></ColumnDirective>
    <ColumnDirective width='200' field='StartDate' defaultValue={GetCurrentDate()} headerText='Start Date' editTemplate={this.StartDate} textAlign='Left'></ColumnDirective>
    <ColumnDirective width='200' field='EndDate' defaultValue={GetCurrentDate()}  headerText='End Date' editTemplate={this.EndDate} textAlign='Left'></ColumnDirective>
    <ColumnDirective width='150' field='RefrenceNo' defaultValue='' headerText='Refrence No' editTemplate={this.RefrenceNo} textAlign='Left'></ColumnDirective>
    <ColumnDirective width='150' field='RefrenceDate'  visible={false} defaultValue={GetCurrentDate()}></ColumnDirective>
    <ColumnDirective width='250' field='Remarks' defaultValue='' headerText='Remarks' editTemplate={this.Remarks} textAlign='Left' />
    
    <ColumnDirective field='SchemeID' headerText='SchemeID' visible={false}></ColumnDirective>
    <ColumnDirective field='ItemID' headerText='ItemID' visible={false} defaultValue={0}></ColumnDirective>
    <ColumnDirective field='CustomerID' visible={false} defaultValue={0}></ColumnDirective>
    <ColumnDirective field='BonusQty' visible={false} defaultValue={0}></ColumnDirective>
    <ColumnDirective field='QtyLimit' visible={false} defaultValue={0}></ColumnDirective>
    <ColumnDirective field='AmountLimit' visible={false} defaultValue={0}></ColumnDirective>
    <ColumnDirective field='IsFlatScheme' visible={false} defaultValue={0}></ColumnDirective>
    <ColumnDirective field='IsBonusScheme' visible={false} defaultValue={0}></ColumnDirective>
    <ColumnDirective field='IsDiscountScheme' visible={false} defaultValue={1}></ColumnDirective>
    <ColumnDirective field='IsAllForCompany' visible={false} defaultValue={0}></ColumnDirective>
    <ColumnDirective field='IsAllForPG' visible={false} defaultValue={0}></ColumnDirective>
    <ColumnDirective field='ManufacturedByID' visible={false} ></ColumnDirective>
    <ColumnDirective field='PGID' visible={false} defaultValue={0}></ColumnDirective>
    <ColumnDirective width='250' field='FlatRate' visible={false} defaultValue={0} />
    <ColumnDirective width='150' field='RefrenceDate'  visible={false} defaultValue={GetCurrentDate()}></ColumnDirective>
    {/*<ColumnDirective field='MaxFlatRate' headerText='MaxFlatRate' visible={false} defaultValue={Item_Data.MaxFlatRate}></ColumnDirective>
    <ColumnDirective field='MinFlatRate' headerText='MinFlatRate' visible={false} defaultValue={Item_Data.MinFlatRate}></ColumnDirective>*/}
    <ColumnDirective field='Comp_Id'  visible={false} defaultValue={this.Comp_Id}></ColumnDirective>
    <ColumnDirective field='ProjectID' visible={false} defaultValue={this.ProjectID}></ColumnDirective>
    <ColumnDirective field='UserID' visible={false} defaultValue={this.props.UserID}></ColumnDirective>
    <ColumnDirective field='IsCancle' visible={false} defaultValue={0}></ColumnDirective>
    </ColumnsDirective>
    <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
    </GridComponent>
    </div>
    </div>
    </Row>
    
    </Col>
    </Row>
    <Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'1%',marginTop:'1%', marginRight:'10%'}} >
    <Col style={{ textAlign: "right" }}>
        {this.state.btn_Edit && (
        <button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={this.DiscountPolicy_Handler} disabled={this.state.btn_Disabled}> 
           {
             this.state.btn_Disabled ?
               (<><Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    /> Saving... </>) : <><Save /> Save</>
                }  </button>)}
      
      
    
    </Col>
    </Row>
    </Card>
        </>, document.getElementById('PageContents')
      
    )
  }
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    isAddNew:state.Items && state.Items.isAddNew,
    ItemID: state.Items && state.Items.ItemID,
    UserID: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    show_screen: state.SCREEN,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

  })
export default connect(mapStateToProps)(DisPolicyForCG)
// export default DisPolicyForCG