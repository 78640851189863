import React, { useState, useEffect } from 'react'
import { Modal, Card, Row, Col, Button ,Spinner } from "react-bootstrap"
import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { Save, Cancel } from "@material-ui/icons"
import { connect } from "react-redux"
import { SelectCompanyGroup ,SaveCompanyGroup } from '../../Services/CompanyGroupAPI'
import { SelectManufacturedByList  } from '../../Services/manufactureAPI'

function CG_Modal({ show, onHide, CGID, is_add_new, show_screen,Comp_Id,ProjectID }) {

    const [ManufactuererBy_Data, setManufactuererBy_Data] = useState([])
    const [Selected_ManufactuererBy_Data, setSelected_ManufactuererBy_Data] = useState(null)
    const [CGName, setCGName] = useState('')
    const [Discription, setDiscription] = useState('')
    const [Error, setError] = useState({
        ManufacturedBy: '',
        CGName: ''
    })
    const [btn_edit, setBtn_edit] = useState(true)
    const [OtherAccess, setOtherAccess] = useState(null)
    const [btn_Disabled, setBtn_Disabled] = useState(false)
    useEffect(() => {

        document.title = "Distribution / Company Group"
        SelectManufacturedBy_List()
         
        if(show){
            if(is_add_new === true)
            {
                setManufactuererBy_Data([])
        setSelected_ManufactuererBy_Data(null)
        setCGName('')
        setDiscription('')
        setError({ManufacturedBy: "", CGName: ""})
                document.getElementById("CGName").focus()
            }
            else if(is_add_new === false)
            {
                    setBtn_edit(show_screen.edit)
                
                if(CGID){
                    SelectCompanyGroup(Comp_Id,ProjectID, CGID,(data)=>{
                        var Selected_MB = ManufactuererBy_Data.find(x => x.ManufacturedByID === data[0].ManufacturedByID)
                      
                        setSelected_ManufactuererBy_Data(Selected_MB)
                        setCGName(data[0].CGName)
                        setDiscription(data[0].Description)
                    })
                }
            }
        }
    }, [show,is_add_new, CGID])

    const SelectManufacturedBy_List = () =>{

        SelectManufacturedByList(Comp_Id,ProjectID,(data) =>{

            setManufactuererBy_Data(data)
        })
    }
   
    const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }

    const validation = () =>{
        if (!CGName){
            setError({CGName: "Must be Enterted"})
            document.getElementById("CGName").focus()
            return false
        } 
      else if(!Selected_ManufactuererBy_Data)
        {
            setError({ManufacturedBy: "Must be Required", CGName: ""})
            document.getElementById("ManufacturedBy").focus()
            return false
        } 

        setError({ManufacturedBy: "", CGName: ""})
            return true
    }

    const save  = () =>{

        if(validation())
        {
           
            var obj_save = {
                CGID: CGID,
                CGName: CGName,
                ManufacturedByID: Selected_ManufactuererBy_Data.ManufacturedByID,
                Description: Discription,
                Comp_Id:Comp_Id,
                ProjectID:ProjectID
            }
            setBtn_Disabled(true)
            SaveCompanyGroup(obj_save,  is_add_new,(message) =>{
                setBtn_Disabled(false)
                clearFields()
            })
        }

    }


    const clearFields = () => {

        onHide()
        setManufactuererBy_Data([])
        setSelected_ManufactuererBy_Data(null)
        setCGName('')
        setDiscription('')
        setError({ManufacturedBy: "", CGName: ""})
    }

    return (
        <>
            <Modal show={show} size="md" centered onHide={onHide} backdrop="static" >
                <Modal.Header closeButton className="header_title " >
                    <Modal.Title > Company Group </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                        <Col lg={12} xl={12} md={12} >
                                <TextField
                                    name="CGName"
                                    id="CGName"
                                    label="Company Group"
                                    value={ CGName }
                                    onInput={ toInputUppercase }
                                    onChange = {e => { setCGName(e.target.value) }}
                                    fullWidth
                                />
                                <span style={{color: "red"}} >{Error.CGName}</span>
                            </Col>
                            <Col lg={12} xl={12} md={12} >
                                <Autocomplete
                                    name="ManufacturedBy"
                                    id="ManufacturedBy"
                                    options={ManufactuererBy_Data}
                                    getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""}
                                    value={Selected_ManufactuererBy_Data}
                                    onChange={(e, value) => {

                                        if (value) {
                                            setSelected_ManufactuererBy_Data(value)
                                        }
                                        else {
                                            setSelected_ManufactuererBy_Data(null)
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="ManufacturedBy" id="ManufacturedBy" label="Manufactured By"
                                        />}

                                />
                                <span style={{color: "red"}} >{Error.ManufacturedBy}</span>
                            </Col>
                         
                        </Row>
                        <Row className="row_margin_top row_margin_left row_margin_right" >
                        <Col lg={12} xl={12} md={12} style={{marginTop:'4%'}}>
                                <textarea placeholder="Enter Description" onInput={ toInputUppercase } onChange={e => setDiscription(e.target.value)} name="Note" value={Discription} rows={4} cols={63} ></textarea>
                        </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                {btn_edit && (<Button variant="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={save}  disabled={btn_Disabled}>
                                {
                             btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                                }
                                </Button>)}
                                <Button variant="btn btn-primary rounded-pill" onClick={clearFields} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></Button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    show_screen: state.SCREEN,    
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,

})

export default connect(mapStateToProps)(CG_Modal)
