import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit } from '@material-ui/icons'
import { TextField, Popper } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { connect } from 'react-redux'
import { set_Receipt, SHOW_SCREEN } from '../../React_Redux/Actions'
import { SelectUserProjects } from '../../Services/Projects'
import { SelectRecovery } from '../../Services/ReceiptsAPI'
import { SelectGroupScreenAccess } from "../../Services/SecurityAPI"
import Loader from '../Loader';

const RecoveryList = (props) => {
    
  const { Project, Month, set_Receipt, Comp_Id, p_userid, SHOW_SCREEN, Group_Id } = props;
  const [SelectedMonth_Option, setSelectedMonth_Option] = useState(null)
const [ProjectData, setProjectData] = useState([])
const [SelectedProject, setSelectedProject] = useState(null)
const [ReceiptsData, setReceiptsData] = useState([])
const [btn_add, setBtn_add] = useState(true)
const [btn_delete, setBtn_delete] = useState(true)
const [IsLoader, setIsLoader] = useState(false)
var [btn_Link, setbtn_Link] = useState('#')
const [Selected_Year, setSelected_Year] = useState(null)
const [Month_Options, setMonth_Options] = useState([
    { value: 1, title: "JANUARY" },
    { value: 2, title: "FEBRUARY" },
    { value: 3, title: "MARCH" },
    { value: 4, title: "APRIL" },
    { value: 5, title: "MAY" },
    { value: 6, title: "JUNE" },
    { value: 7, title: "JULY" },
    { value: 8, title: "AUGUST" },
    { value: 9, title: "SEPTEMBER" },
    { value: 10, title: "OCTOBER" },
    { value: 11, title: "NOVEMBER" },
    { value: 12, title: "DECEMBER" }
  ])
  const _Years= [
    {value:'2022',title:'2022'},
    {value:'2023',title:'2023'},
    {value:'2024',title:'2024'}]
  useEffect(() => {
    document.title = " Distribution / Receipts"
    var _mdate = new Date()
    var cYear = _mdate.getFullYear()
    var mData = _Years.find(x => x.value == cYear)
    setSelected_Year(mData)
    SelectGroupScreenAccess(Group_Id, 11, 96, (data) => {
      setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
      setBtn_delete(parseInt(data.Del) === 0 ? false : true)

      SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
        parseInt(data.Edit) === 0 ? false : true,
        parseInt(data.Del) === 0 ? false : true,
        data.OtherAccess
      )

    })
    SelectUserProjects(Comp_Id, p_userid, (mProjectData) => {
      setProjectData(mProjectData)

      if (Month) {
        setSelectedMonth_Option(Month)
        setSelectedProject(Project)
        Select_Receipt_List(Month.value, Project.Project_ID,cYear)
        set_Receipt(null, 0, false)
      }
      else {
        var dateObj = new Date();
        var month = dateObj.getMonth() + 1;
        Month_Options.map(value => {
          if (value.value == month) {
            setSelectedMonth_Option(value)
            if (mProjectData.length > 0) {
              setSelectedProject(mProjectData[0])
              Select_Receipt_List(month, mProjectData[0].Project_ID,cYear)
            }
          }
        })
      }
    })

  }, [])
  const column_Receipts = [
    {
      name: "RecoveryNo",
      label: "Receipt No",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },

      }
    },
    {
      name: "RecoveryDate",
      label: "Receipt Date",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
   
    {
      name: "CustomerName",
      label: "Customer Name",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
        name: "RecivedAmt",
        label: "Recived Amt",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
            )
          },
        }
      },
    {
      name: "action",
      label: "action",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold", textAlign: "right" }} >{columnMeta.label}</div>
          )
        },
        customBodyRender: (dataIndex, rowIndex) => {

          var RecoveryID = ReceiptsData
         
          if (RecoveryID[rowIndex.rowIndex] != null)
            return (
              <Link to={"/RecoveryEntry"} onClick={e => edit_Receipt(ReceiptsData[rowIndex.rowIndex].RecoveryID)}> <Edit /></Link>
            );
        }
      }
    }
  ];
  const edit_Receipt = (RecoveryID) => {
    var R_Data = { SelectedMonth_Option, SelectedProject }
    
    set_Receipt(R_Data, RecoveryID, false)
  }

  const add_Receipt = () => {
    if (SelectedProject && SelectedMonth_Option) {
      var R_Data = { SelectedMonth_Option, SelectedProject }
      set_Receipt(R_Data, 0, true)
    }

  }
  const Select_Receipt_List = (Month, ProjectID,Year) => {
    setbtn_Link('/RecoveryEntry')
    showLoader()
    SelectRecovery(Comp_Id, ProjectID, Month,Year, (mData) => {
      hideLoader()
      setReceiptsData(mData)
    })
  }
  const showLoader = () => {
    setIsLoader(true)

  }
  const hideLoader = () => {
    setIsLoader(false)
  }
  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });
  return ReactDOM.createPortal (
    <>
    <div className="block-header">
      <div className="row">
        <div className="col-lg-5 col-md-5 col-sm-12">
          <h2>Receipts
          </h2>
        </div>
      </div>
    </div>
    <Card>
      <Row className="row_margin_top row_margin_left row_margin_right p-4" >
      <Col sm={12} lg={1} xl={1} md={1}>
      <Autocomplete
        id="Year"
        options={_Years}
        value={Selected_Year}
        getOptionLabel={(option) => option.title ? option.title : ""}
        getOptionSelected={(option, value) => option.title === value.title}
        onChange={(e, option) => {
          if (option) {
            setSelected_Year(option)
            if (SelectedProject)
              Select_Receipt_List(SelectedMonth_Option.value, SelectedProject.Project_ID,option.value)
          }
          else {
            setSelected_Year(null)
            setReceiptsData([])
          }
        }}

        renderInput={(params) => <TextField {...params} label="Select Year" />}
      />

    </Col>
        <Col sm={12} lg={2} xl={2} md={2}>
          <Autocomplete
            id="Months"
            options={Month_Options}
            value={SelectedMonth_Option}
            getOptionLabel={(option) => option.title ? option.title : ""}
            getOptionSelected={(option, value) => option.title === value.title}
            onChange={(e, option) => {
              if (option) {
                setSelectedMonth_Option(option)
                if (SelectedProject)
                  Select_Receipt_List(option.value, SelectedProject.Project_ID,Selected_Year.value)
              }
              else {
                setSelectedMonth_Option(null)
                setReceiptsData([])
              }
            }}

            renderInput={(params) => <TextField {...params} label="Select Month" />}
          />

        </Col>
        <Col sm={12} lg={3} xl={3} md={3}>
          <Autocomplete
            id="Project"
            options={ProjectData}
            value={SelectedProject}
            getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
            getOptionSelected={(option, value) => option.Project_ID === value.Project_ID}
            onChange={(e, option) => {
              if (option) {
                setSelectedProject(option)
                if (SelectedMonth_Option)
                  Select_Receipt_List(SelectedMonth_Option.value, option.Project_ID,Selected_Year.value)
              }
              else {
                setSelectedProject(null)
                setReceiptsData([])
              }
            }}

            renderInput={(params) => <TextField {...params} label="Select Branch" />}
          />

        </Col>

        <Col lg={6} xl={6} md={6} style={{ textAlign: "right" }} >
          {btn_add && <Link type="button" className="btn btn-primary rounded-pill" to={btn_Link} style={{ border: "none" }} onClick={add_Receipt}> <Add />  Create New</Link>}
        </Col>
      </Row>
      <Row className="row_margin_top row_margin_left row_margin_right" >
        <Col>
          <div className="table-responsive" >
          <MuiThemeProvider theme={theme}>
            <MUIDataTable
              name="Receipts_Datatables"
              data={ReceiptsData}
              columns={column_Receipts}
              options={{
                selectableRows: 'none',
                print: false,
                search: true,
                download: false,
                viewColumns: false,
                filter: false,
              }}
            />
            </MuiThemeProvider>
          </div>
        </Col>
      </Row>
      <Loader
        show={IsLoader}
        hide={hideLoader}
      />
    </Card>

  </>, document.getElementById('PageContents')
  )
}
const mapStateToProps = state => ({
    Comp_Id: state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    p_userid: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    //   ItemCat: state.Items && state.Items.ItemData,
    Group_Id: state.Login_User.User_Data.Group_Id,
    Month: state.Receipts.Receipt_data && state.Receipts.Receipt_data.SelectedMonth_Option,
    Project: state.Receipts.Receipt_data && state.Receipts.Receipt_data.SelectedProject,
  })
  export default connect(mapStateToProps, { set_Receipt, SHOW_SCREEN })(RecoveryList)
