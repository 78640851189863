import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "../SaleReports/Table.css"
import { rptCashInHand } from '../../../Services/FinanceReport_API'
import { SelectUserProjects } from '../../../Services/Projects'

function CashInHandReport({Comp_Name,Comp_Id,User_Name,User_Id,ConfigObj}) {
    const { ProjectID, FromDate, ToDate } = useParams();

    const [ItemData, setItemData] = useState([]);
    const [SelectedProject, setSelectedProject] = useState(null)
    const [TotalAmount, setTotalAmount] = useState(0.00)
    useEffect(() => {
        document.title = "Distribution / Cash In Hand Report" 

        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('footer').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;

        rptCashInHand(Comp_Id,ProjectID,FromDate,ToDate,(mData)=>{
           if(mData.length > 0){
               var Totals = mData.splice(-1)
                setTotalAmount(Totals[0].PaidAmt)
                var mArray = []
                mData.map((value, index) =>{
                    if(value.TransactionType === 'INV')
                    value.TransactionType = 'Sale Invoice'
                    else if(value.TransactionType === 'PR')
                    value.TransactionType = 'Purchase Return'
                    else if(value.TransactionType === 'PUR')
                    value.TransactionType = 'Purchase Invoice'
                    else if(value.TransactionType === 'SR')
                    value.TransactionType = 'Sale Return'
                
                    if(value.TransactionDate && value.TransactionDate !== ''){
                        value.TransactionDate = value.TransactionDate.split(" ")[0]
                     }
              
                    if(mData[index - 1])
                    {
                        if(value.TransactionType !== 'Total')
                        {
                            if(value.TransactionType !== mData[index - 1].TransactionType)
                            {
                                mArray.push({TransactionType: value.TransactionType, title: true,TransactionNo:value.TransactionNo})
                            }
                        }
                       
                    }
                    else
                    {
                        mArray.push({TransactionType: value.TransactionType, title: true,TransactionNo:value.TransactionNo})
                    }
                    
    
                    mArray.push(value)
                })
                setItemData(mArray)
           }
        
        })
     
        
        SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
        mProjects.map(value=>{
            if(value.Project_ID == ProjectID){
                setSelectedProject(value)
            }
        })
        })
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
            <Row  style={{ textAlign: 'center', color: "black" }}>
            <Col sm={3} md={3} xl={3}><img src={ConfigObj.Logo} width="100" height="100" /></Col>
            <Col sm={6} md={6} xl={6}>
                    <h2> {Comp_Name} </h2> <br /> <h3>Cash In Hand Report</h3>
                </Col>
                </Row>
                <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Project:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                         {SelectedProject && SelectedProject.ProjectName}
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'5%', }} >
                    <Row>
                        <Col lg={6} md={6} xl={6} >
                         <b>From Date:</b>
                        </Col>
                        <Col lg={6} md={6} xl={6} >
                         {ReverseDate(FromDate)}
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'5%', }} >
                    <Row>
                        <Col lg={6} md={6} xl={6} >
                         <b>To Date:</b>
                        </Col>
                        <Col lg={6} md={6} xl={6} >
                         {ReverseDate(ToDate)}
                        </Col>
                    </Row>
                </Col>
              
            </Row>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                    <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}   >Date</th>
                            <th style={{ width: '20%', textAlign: 'center', fontWeight: "bold" }} >No.</th>
                            <th style={{ width: '20%', textAlign: 'center', fontWeight: "bold" }} >Type</th>
                            <th style={{ width: '20%', textAlign: 'left', fontWeight: "bold" }} >PaymentType</th>
                            <th style={{ width: '20%', textAlign: 'center', fontWeight: "bold" }} >Naration</th>
                            <th style={{ width: '20%', textAlign: 'center', fontWeight: "bold" }} >PaidAmt</th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                         
                        {
                           ItemData.map((Itemdata, index) => {

                            return(
                                                               
                                <>
                                { 
                                    Itemdata.title === true ? 
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none',borderBottom:'none'}}>
                                        <td colSpan={6} style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.TransactionNo === "Total Expenses" ? 'Expense': Itemdata.TransactionType}</td>
                                       </tr>
                                    :  
                                Itemdata.TransactionType === 'Total' ?
                                <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none'}}>
                                        <td colSpan={5} style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold',borderRight:'2px solid black' }}  >{Itemdata.Naration}</td>
                                        <td colSpan={1} style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  > {Itemdata.PaidAmt} </td>
                                    </tr>
                                    :
                                    <tr key={index} >
                                        <td style={{ width: '20%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{Itemdata.TransactionDate || Itemdata.TransactionDate != '' && Itemdata.TransactionDate}</td>
                                        <td style={{ width: '20%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.TransactionNo === "Total Expenses" ? '' : Itemdata.TransactionNo }</td>
                                        <td style={{ width: '20%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.TransactionType}</td>
                                        <td style={{ width: '20%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.PaymentType}</td>
                                        <td style={{ width: '20%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.Naration} </td>
                                        <td style={{ width: '20%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.PaidAmt} </td>

                                    </tr>
                                }
                                </>
                               
                            )}
                            )
                        }
                            <tr id='footer_balance'>
                                <td id='footer_td_cb' colSpan="4" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                                <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'right', paddingTop: '15px', paddingBottom: '15px' }}  > Total Amount</td>
                                <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  >{TotalAmount}</td>
                            </tr>
                    </tbody>


                </table>

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig
})

export default connect(mapStateToProps)(CashInHandReport)
