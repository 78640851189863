import React, { useState, useEffect } from 'react'
import { Modal, Card, Row, Col, Button,Spinner  } from "react-bootstrap"
import { TextField } from "@material-ui/core"
import { Save, Cancel } from "@material-ui/icons"
import { Autocomplete } from '@material-ui/lab'
import { connect } from "react-redux"
import { SelectBranchesList } from '../../Services/BranchAPI'
import { SelectFinYear, SaveFinYear } from '../../Services/FinYear'
import { GetCurrentDate } from '../../Services/Common'

function FinYearModal({ show, onHide, Year_Id, is_add_new, show_screen,}) {

    const [btn_edit, setBtn_edit] = useState(true)
    const [OtherAccess, setOtherAccess] = useState(null)
    const [StartYear, setStartYear] = useState('')
    const [EndYear, setEndYear] = useState('')
   const [SelectedBranch, setSelectedBranch] = useState(null)
   const [BranchesList, setBranchesList] = useState([])
    const [BranchError, setBranchError] = useState('')
    const [btn_Disabled, setBtn_Disabled] = useState(false)
    useEffect(() => {
        if(show){
            SelectBranchesList(mBranches=>{
                setBranchesList(mBranches)
                setBranchError('')

                if(is_add_new === true)
                {
                    setStartYear(GetCurrentDate())
                    setEndYear(GetCurrentDate())
                    setSelectedBranch(null)
                   
                }
                else if(is_add_new === false)
                {
                    // setBtn_edit(show_screen.edit) 
                    if(Year_Id){
                        SelectFinYear(Year_Id,(data)=>{
                            setStartYear(data[0].StartYear)
                            setEndYear(data[0].EndYear)
                           var _mSelectedBranch = mBranches.find(x=> x.Branch_Id === data[0].Branch_Id)
                           if(_mSelectedBranch){
                            setSelectedBranch(_mSelectedBranch)
                           }
                        })
                    }
                }
            })
           
        }
    }, [show,is_add_new, Year_Id])

 
   
    const save  = () =>{

        if(SelectedBranch)
        {
            var obj_save = {
                Year_Id,
                Branch_Id: SelectedBranch.Branch_Id,
                StartYear,EndYear
            }
            setBtn_Disabled(true)
            SaveFinYear(obj_save,  is_add_new,(message) =>{
                setBtn_Disabled(false)
                clearFields()
            })
        }
        else{
            setBranchError('required')
        }

    }



    const clearFields = () => {

        onHide()
        setStartYear('')
        setEndYear('')
        setBranchError('')
    }

    return (
        <>
            <Modal show={show} onHide={onHide} size="md"   backdrop="static"  centered>
                <Modal.Header closeButton classStartYear="header_title " >
                    <Modal.Title >  Financal Years </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Row classStartYear=" row_margin_top row_margin_left row_margin_right" >
                        <Col lg={12} xl={12} md={12} >
                            <Autocomplete
                                name="Branches"
                                id="Branches"
                                options={BranchesList}
                                getOptionLabel={(option) => option.Name ? option.Name : ""} 
                                value={SelectedBranch}
                                onChange={(e,value) =>{
                                if(value)
                                {
                                    setSelectedBranch(value)
                                   
                                }
                                else
                                setSelectedBranch(null)
                                    
                                }}
                                renderInput={(params) => 
                                <TextField {...params} name="Branches" label="Select Branch "
                                />
                            }
                            />                                
                            <span style={{color: "red"}} >{BranchError}</span>

                        </Col>
                            <Col lg={6} xl={6} md={6} >
                                <TextField
                                    name="StartYear"
                                    id="StartYear"
                                    label="Start Year"
                                    type="date"
                                    value={StartYear}
                                 
                                    onChange={e=> setStartYear(e.target.value)}
                                    fullWidth
                                />
                            </Col>
                            <Col lg={6} xl={6} md={6} >
                                <TextField
                                    StartYear="EndYear"
                                    id="EndYear"
                                    label="End Year"
                                    type="date"
                                    value={EndYear}
                                    onChange={e=> setEndYear(e.target.value)}
                                  
                                    fullWidth
                                />
                            </Col>
                        </Row>
                        <Row classStartYear=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                {btn_edit && (<Button variant="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={save}  disabled={btn_Disabled}> 
                                {
                             btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                                }
                                </Button>)}
                                <Button variant="btn btn-primary rounded-pill" onClick={clearFields} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></Button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Year_Id,
    show_screen: state.SCREEN,    
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,

})

export default connect(mapStateToProps)(FinYearModal)
