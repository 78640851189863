import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectCompanyPayments =(Comp_Id,ProjectID,Month,Year,Callback) =>{
  axios.get(`${domain}/CompanyPayment/SelectCompanyPayments/${Comp_Id}/${ProjectID}/${Month}/${Year}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectCompanyPaymentMaster =(Comp_Id,ProjectID,PaymentID,Callback) =>{
  axios.get(`${domain}/CompanyPayment/SelectCompanyPaymentMaster/${Comp_Id}/${ProjectID}/${PaymentID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const GetMaxCompanyPaymentNo =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/CompanyPayment/GetMaxCompanyPaymentNo/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}



export const SaveCompanyPayment = (PaymentMasterData,isAddNew,callback) =>{
 
  axios.post(`${domain}/CompanyPayment/SaveCompanyPayment`,{
    PaymentMasterData,
    isAddNew
  })
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              theme:'light',
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback()
      })
      .catch(err=>console.error(err))
}

