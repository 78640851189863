import React, { useEffect, useState } from 'react'
import { Modal, Card, Row, Col } from "react-bootstrap"
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Loop, Cancel } from "@material-ui/icons"
import { connect } from 'react-redux'
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

import { SelectItemsForReturn } from '../../Services/SaleInvoiceAPI' 

function SaleOrderModal({show, onHide, mSaveStock,CustomerID, Comp_Id, project_Id, invoiceDate}) {
    var ProjectID = 1
    const [SO_Data, setSO_Data] = useState([])
    const [SaleInvoiceNo, setSaleInvoiceNo] = useState('')
    const [LoadAlll, setLoadAlll] = useState(false)
    useEffect(()=>{
          if(show){
            setSaleInvoiceNo('')
            setLoadAlll(false)
            setSO_Data([])
          }

    },[show])

    const column_SO = [
        {
            name: "Select",
            label: "Select",
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.name}</div>
                    )
                },
                customBodyRender: (dataIndex, rowIndex) => {
                    var dopt_id = SO_Data
                    if (dopt_id[rowIndex.rowIndex] != null)
                        return (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={SO_Data[rowIndex.rowIndex].chkForSelect}
                                            id={`chk${rowIndex.rowIndex}`}
                                            onChange={e => {
                                                let newSO_Data = [...SO_Data]
                                                newSO_Data[rowIndex.rowIndex].chkForSelect = e.target.checked 
                                               setSO_Data(newSO_Data)
                                            }}

                                            // name="Access"
                                            color="primary"
                                        />
                                    }
                                    label=""
                                    style={{
                                        padding: "0px",
                                    }}
                                />
                            </>
                        );
                }
            }
        },
        {
          name: "ItemID",
          label: "ItemID",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
          name: "ItemDiscription",
          label: "ItemDiscription",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
            name: "UOM",
            label: "UOM",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
            }
          },
      
        {
          name: "Amount",
          label: "SaleRate",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
            name: "Qty",
            label: "Qty",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
            }
          },
    ];

    const save_Handler = ()=>{
      var  mArray = []
        for(let i=0; i<SO_Data.length; i++ )
        {
            if(SO_Data[i].chkForSelect)
            {
                SO_Data[i].Qty = SO_Data[i].Qty
                SO_Data[i].SaleRate =  SO_Data[i].Rate
                SO_Data[i].DisPer = 0 
                SO_Data[i].DisAmt = 0 
                SO_Data[i].TaxPer = 0 
                SO_Data[i].TaxAmt = 0 
                SO_Data[i].Value = SO_Data[i].Amount
                // SO_Data[i].Value = SO_Data[i].Qty
                SO_Data[i].action = 'add'
                mArray.push(SO_Data[i])
            }
            
        }
        if(mArray.length > 0)
        mSaveStock(mArray)
        else
        onHide()

    }

    const theme = createTheme({
      overrides: {
        MuiTableCell: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
          }
        }
      }
    });
    return (
        <>
        <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" >
            <Modal.Header closeButton className="header_title" >
            <Modal.Title> Sale Return Items </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Row className=" row_margin_left row_margin_right" >
                            <Col sm={12} lg={3} xl={3} md={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={LoadAlll}
                                            id="LoadAlll"
                                            label="LoadAlll"
                                            onChange={e => {
                                                setLoadAlll(e.target.checked)
                                                SO_Data.map(value=>{
                                                    if(e.target.checked)
                                                    value.chkForSelect = true
                                                    else
                                                    value.chkForSelect = false
                                                })
                                            }}

                                            // name="Access"
                                            color="primary"
                                        />
                                    }
                                    label="Select All"
                                    // style={{
                                    //     padding: "0px",
                                    // }}
                                />
                            </Col>
                            <Col sm={12} lg={3} xl={3} md={3}>
                              <TextField
                              name="SaleInvoiceNo"
                              label="Sale Invoice No" 
                              value={SaleInvoiceNo}
                              onChange={e=>{
                                if(e.target.value.length === 6){
                                  SelectItemsForReturn(Comp_Id,project_Id, e.target.value, 'INV',(mStockData)=>{
                                    mStockData.map(value=>{
                                        value.chkForSelect = false
                                    })
                                    setSO_Data(mStockData)
                                  })
                                }
                                else{
                                  setSO_Data([])
                                }
                                setSaleInvoiceNo(e.target.value)
                              }}
                              fullWidth
                        />
                            </Col>
                        </Row>
                        <Row className=" row_margin_left row_margin_right" >
                            <Col>
                                <div className="table-responsive" >
                                <MuiThemeProvider theme={theme}>
                                    <MUIDataTable
                                        name="SO_Datatables"
                                        data={SO_Data}
                                        columns={column_SO}
                                        options={{
                                            selectableRows: 'none',
                                            print: false,
                                            search: false,
                                            download: false,
                                            viewColumns: false,
                                            filter: false,
                                        }}
                                    />
                                    </MuiThemeProvider>
                                </div>
                            </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                <button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={save_Handler} > <Loop /> <u>Load</u></button>
                                <button type="button" className="btn btn-primary rounded-pill" onClick={onHide} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
        </Modal>
        </>
    )
}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address
  })
export default connect(mapStateToProps)(SaleOrderModal)
