import React, { useEffect, useState } from 'react'
import  './style.css'
import { getOpeningRecordsForEdit } from '../../Services/OpeningAPI';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const RptOpening = () => {
  const {InventoryMasterID} = useParams()
 const [masterData, setMasterData] = useState([])
 const [detailsData, setDetailsData] = useState([])
  useEffect(() => {
    document.title = "Inventory Opening";


    document.getElementById('topBar').hidden = true;
    document.getElementById('leftside').hidden = true;
    document.getElementById('footer').hidden = true;
    document.getElementById('content_Page').hidden = true;
    document.getElementById('RSidebar').hidden = true;

    getOpeningRecordsForEdit(InventoryMasterID, (res)=>{
      if(res){
        setMasterData(res.masterData)
        setDetailsData(res.detailsData)
      }
    })
  }, [InventoryMasterID])


  // Calculate total invoice value

  return (
    <div className="report-container">
      <h1 className="report-header">Inventory Opening Invoice</h1>

      {/* Master Data */}
      <div className="master-data">
        {masterData.map((item) => (
          <div key={item.OpeningNo} className="master-item">
            <div className="left">
              <p><strong>Opening No:</strong> {item.OpeningNo}</p>
              <p><strong>Transaction Date:</strong> {item.TransactionDate}</p>
            </div>
            <div className="right">
              <p><strong>Company Name:</strong> {item.Manufacturedby}</p>
              <p><strong>Company Group:</strong> {item.CGName}</p>
              <p><strong>Total:</strong> {item.Total}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Details Table */}
      <table className="details-table">
        <thead>
          <tr>
            <th>Item Name</th>
            <th>Batch No</th>
            <th>Expairy Date</th>
            <th>Qty</th>
            <th>Sale Rate</th>
            <th>Purchase Rate</th>
            <th>MRP</th>
            <th>GST</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {detailsData.map((item, index) => (
            <tr key={index}>
              <td>{item.ItemDiscription}</td>
              <td>{item.BatchNo}</td>
              <td>{item.ExpairyDate}</td>
              <td>{item.Qty}</td>
              <td>{item.SaleRate}</td>
              <td>{item.PurchaseRate}</td>
              <td>{item.MRP}</td>
              <td>{item.GST}</td>
              <td>{item.Amount}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Total Invoice */}
      <div className="total-invoice">
        <strong>Total Invoice:</strong> {masterData[0]?.Total}
      </div>
    </div>
  );
}

export default RptOpening