import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit, PostAddRounded } from '@material-ui/icons'
import {TextField, Popper} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import  { connect } from 'react-redux'
import Swal from 'sweetalert2';
import { _Years } from '../../Services/Common';
import { PostRecord, SelectPurchaseInvoiceList } from "../../Services/PurchaseInvoiceAPI";
import { set_PI, SHOW_SCREEN } from '../../React_Redux/Actions'
import { SelectUserProjects } from "../../Services/Projects"
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import Loader from '../Loader';
import { PostAddOutlined } from '@mui/icons-material';

function PurchaseInvoiceList(props) {

    const {  set_PI,Purchase_Invoice, Comp_Id ,ProjectID,p_userid,Group_Id, SHOW_SCREEN} = props;
    const [PI_Data, setPI_Data] = useState([])
    const [Selected_Month, setSelected_Month] = useState(null)
    const [Selected_Year, setSelected_Year] = useState(null)
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [ProjectList, setProjectList] = useState([])
    const [SelectedProject, setSelectedProject] = useState(null)
    const [SelectedStockType, setSelectedStockType] = useState(null)
    const [error_message, setError_message] = useState({
      Month: '',
      Project: '',
      Year: ''
    })
    const [IsLoader, setIsLoader] = useState(false)
    const _TypeOptions= [
      {value:'Purchase Invoice',title:'Purchase Invoice'},
      {value:'Stock In',title:'Stock In'}]
   
    useEffect(()=>{
    document.title = " Distribution / Purchase Invoice"
    var _mdate = new Date()
    var cYear = _mdate.getFullYear()
    var mData = _Years.find(x => x.value == cYear)
    setSelected_Year(mData)
     
      setSelectedStockType(_TypeOptions[0])
   
   
    SelectGroupScreenAccess(Group_Id,15,100,(data)=>{
            
      setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
      setBtn_delete(parseInt(data.Del) === 0 ? false : true)

      SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
      parseInt(data.Edit) === 0 ? false : true,
      parseInt(data.Del) === 0 ? false : true,
      data.OtherAccess
      )
      
  })
        

      SelectUserProjects(Comp_Id,p_userid,(ProjectData) =>{

          setProjectList(ProjectData)
          if(Purchase_Invoice.PI_data)
          {
            var _date = new Date()

            Select_PurchaseInvoice_List(Purchase_Invoice.PI_data.value, Purchase_Invoice.Project_ID,_TypeOptions[0].value,(_date.getFullYear()))
            setSelected_Month(Purchase_Invoice.PI_data)
            setSelectedProject(ProjectData.find(x => x.Project_ID === Purchase_Invoice.Project_ID))
            set_PI(null,0,false)
          }
          else
          {
            setSelectedProject(ProjectData[0])
            var _date = new Date()
            setSelected_Month(Month_List.find(x => x.value === (_date.getMonth())+1))
            Select_PurchaseInvoice_List((_date.getMonth())+1, ProjectData[0].Project_ID,_TypeOptions[0].value,(_date.getFullYear()))
          }
        })
    },[])

    const column_PI = [
        {
         name: "PurchaseInvoiceNo",
         label: "Invoice No",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
        {
         name: "SupplierName",
         label: "Supplier Name",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
         name: "PurchaseDate",
         label: "Purchase Date",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },
         }
        },
         {
          name: "action",
          label: "action",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) => {
             
             var PurchaseInvoiceID = PI_Data
             if (PurchaseInvoiceID[rowIndex.rowIndex] != null)
               return (
                <Link to={"/PurchaseInvoiceEntry"} onClick={e => edit_PI(PI_Data[rowIndex.rowIndex].PurchaseInvoiceID)}> <Edit /></Link>
               );
           }
          }
         },
        //  {
        //   name: "IsPost",
        //   label: "Posted",
        //   options: {
        //    filter: true,
        //    sort: true,
        //    customHeadLabelRender: (columnMeta)=>{
        //     return(
        //       <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
        //     )
        //    },
        //    customBodyRender: (dataIndex, rowIndex) => {
             
        //      var PurchaseInvoiceID = PI_Data
        //      let PostData = {
        //         PurchaseInvoiceID: PI_Data[rowIndex.rowIndex].PurchaseInvoiceID,
        //         Comp_Id: Comp_Id,
        //         ProjectID: ProjectID
        //      }

        //      debugger
        //      if (PurchaseInvoiceID[rowIndex.rowIndex] != null)
            
        //        return (
        //          <Link to={"#"} onClick={e => {
                 
        //           Swal.fire({
        //             title: 'Are you sure?',
        //             text: "You won't be able to edit this record again!",
        //             icon: 'warning',
        //             showCancelButton: true,
        //             confirmButtonColor: '#3085d6',
        //             cancelButtonColor: '#d33',
        //             confirmButtonText: 'Yes, Posted it!'
        //           }).then((result) => {
        //             if (result.isConfirmed) {
        //               PostRecord(PostData,()=>{})
        //             }
        //           })
        //          }}> {PI_Data[rowIndex.rowIndex].IsPost === 1 ?  <PostAddOutlined className="text-success" /> : <PostAddRounded /> }</Link>
        //        );
        //    }
        //   }
        //  }
    ];

    const validation = () =>{

      if(!Selected_Month)
      {
        setError_message({Month: 'must be selected',Year:'', Project: ''})
        return false
      }
      else if(!Selected_Year)
      {
        setError_message({Month: '', Year: 'must be selected', Project:''})
        return false
      }
      else if(!SelectedProject)
      {
        setError_message({Month: '',Year:'', Project: 'must be selected'})
        return false
      }

      setError_message({Month: '', Project: '',Year:''})
      return true
    }
    const edit_PI = (PurchaseInvoiceID) =>{
       set_PI(Selected_Month,PurchaseInvoiceID,false, SelectedProject.Project_ID)
    }
    const add_PI = () =>{
      if(validation())
      {
        set_PI(Selected_Month,0,true, SelectedProject.Project_ID)
        document.getElementById("btn_navigate").click()
      }
      
    }
  const Month_List = [
      {title:"JANUARY",value:1},
      {title:"FEBURARY",value:2},
      {title:"MARCH",value:3},
      {title:"APRIL",value:4},
      {title:"MAY",value:5},
      {title:"JUNE",value:6},
      {title:"JULY",value:7},
      {title:"AUGUST",value:8},
      {title:"SEPTEMBER",value:9},
      {title:"OCTOBER",value:10},
      {title:"NOVEMBER",value:11},
      {title:"DECEMBER",value:12},
  ]
  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });
   const Select_PurchaseInvoice_List = (Month, ProjectID,StockType, Year) =>{

    showLoader()
    SelectPurchaseInvoiceList(Comp_Id,ProjectID,'PUR',StockType,Month,Year,(mData)=>{
      hideLoader()  
      setPI_Data(mData)
    })
   }
   const showLoader = () => {
    setIsLoader(true)
   
  }
const  hideLoader = () => {
  setIsLoader(false)
  }
    return ReactDOM.createPortal(
      <>
        <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2> Purchase Invoice
                    </h2>
                </div>
            </div>
        </div>
      <Card>
         
          <Row className="row_margin_top row_margin_left row_margin_right m-4" >

          <Col lg={1} xl={1} md={1} >
                        <Autocomplete
                            name="Year"
                            id="Year"
                            options={_Years}
                            getOptionLabel={(option) => option.title ? option.title : ""}
                            value={Selected_Year}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    setSelected_Year(value)

                                    if(SelectedProject)
                                    Select_PurchaseInvoice_List(Selected_Month.value, SelectedProject.Project_ID,SelectedStockType.value,value.value)
                                }
                                else
                                {
                                  setSelected_Year(null)
                                    setPI_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Year" label="Year"
                                />}
                        />
                        <span style={{color: 'red'}} > {error_message.Year} </span>
                    </Col>

                    <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="Month"
                            id="Month"
                            options={Month_List}
                            getOptionLabel={(option) => option.title ? option.title : ""}
                            value={Selected_Month}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    setSelected_Month(value)

                                    if(SelectedProject)
                                    Select_PurchaseInvoice_List(value.value, SelectedProject.Project_ID,SelectedStockType.value,Selected_Year.value)
                                }
                                else
                                {
                                    setSelected_Month(null)
                                    setPI_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Month" label="Month"
                                />}
                        />
                        <span style={{color: 'red'}} > {error_message.Month} </span>
                    </Col>

                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="Project"
                            id="Project"
                            options={ProjectList}
                            getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                            value={SelectedProject}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    setSelectedProject(value)

                                    if(Selected_Month)
                                    Select_PurchaseInvoice_List(Selected_Month.value,value.Project_ID,SelectedStockType.value,Selected_Year.value)
                                }
                                else
                                {
                                  setSelectedProject(null)
                                    setPI_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Project" label="Select Branch"
                                />}
                        />
                         <span style={{color: 'red'}} > {error_message.Project} </span>
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={3} >
                    <Autocomplete
                    name="StockType"
                    id="StockType"
                    options={_TypeOptions}
                    getOptionLabel={(option) => option.title ? option.title : ""}
                    value={SelectedStockType}
                    onChange={(e, value) => {
                        if (value)
                        {
                           setSelectedStockType(value) 
                           if(SelectedStockType)
                                    Select_PurchaseInvoice_List(Selected_Month.value,SelectedProject.Project_ID,value.value, Selected_Year.value)
                      
                        }
                    
                        else
                        {
                          setSelectedStockType(null) 
                          setPI_Data([])
                        }
                    }}
                    renderInput={(params) =>
                        <TextField {...params} name="Stock" label="Stock Type"
                        />}
                />
               {/* <span className="text-danger">{this.state.StockType_Err}</span> */}
                </Col>
            <Col lg={3} xl={3} md={3} style={{ textAlign: "right" }} >
              <Link to="/PurchaseInvoiceEntry" id='btn_navigate' ></Link>
              { btn_add &&(<Link type="button" className="btn btn-primary rounded-pill" to="#" onClick={add_PI} style={{ border: "none" }} > <Add />  Create New</Link>)}
            </Col>
          </Row>
          <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col>              
                <div className="table-responsive" >
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        data={PI_Data}
                        columns={column_PI}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                    
                    </MuiThemeProvider>
                </div>
              </Col>
          </Row>
          <Loader
        show={IsLoader}
        hide={hideLoader}
        />
      </Card>
                        
      </>  , document.getElementById('PageContents')
    )
}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Purchase_Invoice: state.PI && state.PI,
  Group_Id : state.Login_User.User_Data.Group_Id,
  p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
  ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID
})
export default connect(mapStateToProps,{set_PI, SHOW_SCREEN})(PurchaseInvoiceList)
