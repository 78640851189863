import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectItemsList =(Comp_Id,ProjectID,ItemMainTypeID,Callback) =>{
  axios.get(`${domain}/Items/SelectItemsList/${Comp_Id}/${ProjectID}/${ItemMainTypeID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectItemsForExcel =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/Items/SelectItemsForExcel/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectItemDSRForMacalson =(Comp_Id,ProjectID,ManufacturedByID,FromDate,ToDate,Callback) =>{
  axios.get(`${domain}/Items/SelectItemDSRForMacalson/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${FromDate}/${ToDate}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectItemDSRForGlaxcy =(Comp_Id,ProjectID,ManufacturedByID,FromDate,ToDate,Callback) =>{
  axios.get(`${domain}/Items/SelectItemDSRForGlaxcy/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${FromDate}/${ToDate}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectTransactionDSRForMacalson =(Comp_Id,ProjectID,ManufacturedByID,FromDate,ToDate,Callback) =>{
  axios.get(`${domain}/Items/SelectTransactionDSRForMacalson/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${FromDate}/${ToDate}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const getStockDSRDataForSafron =(Comp_Id, ProjectID, ManufacturedByID, FromDate, ToDate,Callback) =>{
  axios.get(`${domain}/Items/getStockDSRDataForSafron/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${FromDate}/${ToDate}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectTransactionDSRForGlaxcy =(Comp_Id,ProjectID,ManufacturedByID,FromDate,ToDate,Callback) =>{
  axios.get(`${domain}/Items/SelectTransactionDSRForGlaxcy/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${FromDate}/${ToDate}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectTransactionDSRForShaigan =(Comp_Id,ProjectID,ManufacturedByID,FromDate,ToDate,Callback) =>{
  axios.get(`${domain}/Items/SelectTransactionDSRForShaigan/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${FromDate}/${ToDate}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectItemDSRForShigan =(Comp_Id,ProjectID,ManufacturedByID,FromDate,ToDate,Callback) =>{
  axios.get(`${domain}/Items/SelectItemDSRForShigan/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${FromDate}/${ToDate}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const GetMaxItemCode =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/Items/GetMaxItemCode/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectSchemePolicy =(Comp_Id,ProjectID,CustomerID,ItemID,Callback) =>{
  axios.get(`${domain}/Items/SelectSchemePolicy/${Comp_Id}/${ProjectID}/${CustomerID}/${ItemID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectItem =(Comp_Id,ProjectID,ItemID,Callback) =>{
  axios.get(`${domain}/Items/SelectItem/${Comp_Id}/${ProjectID}/${ItemID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectItemForOpenFlat =(Comp_Id,ProjectID,ItemID,Callback) =>{
  axios.get(`${domain}/Items/SelectItemForOpenFlat/${Comp_Id}/${ProjectID}/${ItemID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectItemForOpenDis =(Comp_Id,ProjectID,ItemID,Callback) =>{
  axios.get(`${domain}/Items/SelectItemForOpenDis/${Comp_Id}/${ProjectID}/${ItemID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectItemForOpenBonus =(Comp_Id,ProjectID,ItemID,Callback) =>{
  axios.get(`${domain}/Items/SelectItemForOpenBonus/${Comp_Id}/${ProjectID}/${ItemID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectItemForDisCompanyAndGroup =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/Items/SelectItemForDisCompanyAndGroup/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SaveItems = (ItemsData,isAddNew,callback) =>{

  axios.post(`${domain}/Items/SaveItems`,{
    ItemsData,
     isAddNew
  })
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback()
      })
      .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
}

export const SaveScheme = (SchemeData,DeletedSchemeData,callback) =>{
  axios.post(`${domain}/Items/SaveScheme`,{
    SchemeData,
    DeletedSchemeData
  })
      .then(response=>{
        
          toast.configure()
          toast.info(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback(true)
      })
      .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
            callback(false)
       })
}

export const SelectItemsListWRTProject =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/Items/SelectItemsListWRTProject/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}


export const SelectActiveItems =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/Items/SelectActiveItems/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectItemsAgainstMultipleCompanyGroup =(Comp_Id,ProjectID,CGData,Callback) =>{
  axios.post(`${domain}/Items/SelectItemsAgainstMultipleCompanyGroup`, {
    Comp_Id,
    ProjectID,
    CGData
  })
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}