import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectProductGroups =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/ProductGroup/SelectProductGroups/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectProductGroup =(Comp_Id,ProjectID,PGID,Callback) =>{
    
    axios.get(`${domain}/ProductGroup/SelectProductGroup/${Comp_Id}/${ProjectID}/${PGID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
export const SaveProductGroup = (PGData,IsAddNew,callback) =>{
  axios.post(`${domain}/ProductGroup/SaveProductGroup`,{
    PGData,
    IsAddNew
  })
    .then(response=>{
        toast.configure()
        toast.info(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        callback()
    })
    .catch(err=>console.error(err))
}

export const DeleteProductGroup =(PGID,Callback) =>{
    
    axios.delete(`${domain}/ProductGroup/DeleteProductGroup/${PGID}`)
         .then(response=>{
            Callback(response.data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const SelectProductGroupAgainstManufacturer =(Comp_Id,ProjectID,ManufacturedByID,Callback) =>{
    
    axios.get(`${domain}/ProductGroup/SelectProductGroupAgainstManufacturer/${Comp_Id}/${ProjectID}/${ManufacturedByID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }