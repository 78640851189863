import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit } from '@material-ui/icons'
import { TextField, Popper } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { connect } from 'react-redux'

import { SelectSaleInvoiceList } from "../../Services/SaleInvoiceAPI";
import { set_SI, SHOW_SCREEN, set_OB_TO_SI, set_ClaimPolicyType } from '../../React_Redux/Actions'
import { SelectUserProjects } from "../../Services/Projects"
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import { _Years } from '../../Services/Common';
import Loader from '../Loader';
import { SelectClaimPolicyList } from '../../Services/ClaimAPI';
import Swal from 'sweetalert2';

function DiscountClaimList(props) {

    const { set_SI, DISCOUNT_POLICY, Comp_Id, p_userid, Group_Id, SHOW_SCREEN } = props;
    const [DC_Data, setDC_Data] = useState([])
    const [Selected_Month, setSelected_Month] = useState(null)
    const [Selected_Year, setSelected_Year] = useState(null)
    const [SelectedInvoiceType, setSelectedInvoiceType] = useState(null)
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [ProjectList, setProjectList] = useState([])
    const [SelectedProject, setSelectedProject] = useState(null)
    const [error_message, setError_message] = useState({
        Month: '',
        Project: '',
        Year: '',
        InvoiceType_Error: '',
    })
    const [IsLoader, setIsLoader] = useState(false)

    
    useEffect(() => {
        document.title = " Distribution / Discount Claim"
        var _mdate = new Date()
        var cYear = _mdate.getFullYear()
        var cMonth = _mdate.getMonth() + 1
        var mData = _Years.find(x => x.value == cYear)
        var mMonth = Month_List.find(x => x.value === cMonth)
        SelectGroupScreenAccess(Group_Id, 16, 103, (data) => {
            setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
            setBtn_delete(parseInt(data.Del) === 0 ? false : true)
            SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
                parseInt(data.Edit) === 0 ? false : true,
                parseInt(data.Del) === 0 ? false : true,
                data.OtherAccess
            )

        })
        SelectUserProjects(Comp_Id, p_userid, (ProjectData) => {
            setProjectList(ProjectData)
            const {Year , Month, SelectedProject} = DISCOUNT_POLICY
            if(Year !== null && Month !== null && SelectedProject !== null){
                setSelectedProject(SelectedProject)
                setSelected_Year(Year)
                setSelected_Month(Month)
                Select_DiscountClaimList(Month, SelectedProject.Project_ID, Year)
            }else{
                setSelectedProject(ProjectData[0])
                setSelected_Year(mData)
                setSelected_Month(mMonth)
                Select_DiscountClaimList(mMonth.value, ProjectData[0].Project_ID, mData.value)
            }
           
        })
       
       
    }, [DISCOUNT_POLICY.Type])

    const validation = () => {

        if (!Selected_Month) {
            setError_message({ Month: 'must be selected', Project: '', Year: '' })
            return false
        }
        else if (!Selected_Year) {
            setError_message({ Month: '', Year: 'must be selected', Project: '' })
            return false
        }
        else if (!SelectedProject) {
            setError_message({ Month: '', Year: '', Project: 'must be selected' })
            return false
        }

        setError_message({ Month: '', Project: '', Year: '' })
        return true
    }

    const column_DC = [
        {
            name: "ClaimNo",
            label: "Claim No",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },

            }
        },
        {
            name: "ClaimDate",
            label: "Claim Date",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "FromDate",
            label: "From Date",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "ToDate",
            label: "To Date",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "GrandTotal",
            label: "Grand Total",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "action",
            label: "action",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold", textAlign: "right" }} >{columnMeta.label}</div>
                    )
                },
                customBodyRender: (dataIndex, rowIndex) => {

                    if (DC_Data[rowIndex.rowIndex] != null)
                        return (
                            <Link to={"/DiscountClaim"} onClick={e => edit_DC(DC_Data[rowIndex.rowIndex].ClaimID)}> <Edit /></Link>
                        );
                }
            }
        }
    ];
    const edit_DC = (ClaimID) => {
        set_ClaimPolicyType(ClaimID,Selected_Year.value,Selected_Month.value,SelectedProject.Proect_ID,'Discount',false )
    }
    const add_DC = () => {
        if (validation()) {
            set_ClaimPolicyType(0,Selected_Year.value,Selected_Month.value,SelectedProject.Proect_ID,'Discount',true )
            document.getElementById("btn_navigate").click()
        }
    }
    const Month_List = [
        { title: "JANUARY", value: 1 },
        { title: "FEBURARY", value: 2 },
        { title: "MARCH", value: 3 },
        { title: "APRIL", value: 4 },
        { title: "MAY", value: 5 },
        { title: "JUNE", value: 6 },
        { title: "JULY", value: 7 },
        { title: "AUGUST", value: 8 },
        { title: "SEPTEMBER", value: 9 },
        { title: "OCTOBER", value: 10 },
        { title: "NOVEMBER", value: 11 },
        { title: "DECEMBER", value: 12 },
    ]
    const Select_DiscountClaimList = async(Month, Project_Id, Year) => {
        showLoader()
        const mData = await  SelectClaimPolicyList(Comp_Id, Project_Id,  Year,Month,'Discount') 
        hideLoader()
        if(mData.status){
            setDC_Data(mData.data)
        }else{
            Swal.fire({
                title: 'API Error',
                text: 'Something went wrong',
                icon: 'error'
            })
            setDC_Data([])
        }
    }
    const showLoader = () => {
        setIsLoader(true)

    }
    const hideLoader = () => {
        setIsLoader(false)
    }
    const theme = createTheme({
        overrides: {
            MuiTableCell: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                }
            }
        }
    });
    return ReactDOM.createPortal(
        <>
            <div className="block-header">
                <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <h2> Discount Claim List </h2>
                    </div>
                </div>
            </div>

            <Card>
                <Row className="row_margin_top row_margin_left row_margin_right mt-4 mr-4 ml-4" >
                    <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="Year"
                            id="Year"
                            options={_Years}
                            getOptionLabel={(option) => option.title ? option.title : ""}
                            value={Selected_Year}
                            onChange={(e, value) => {
                                if (value) {
                                    setSelected_Year(value)
                                    if (SelectedProject && Selected_Month)
                                        Select_DiscountClaimList(Selected_Month.value, SelectedProject.Project_ID, value.value)
                                }
                                else {
                                    setSelected_Year(null)
                                    setDC_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Year" label="Year"
                                />}
                        />
                        <span style={{ color: 'red' }} > {error_message.Year} </span>
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="Month"
                            id="Month"
                            options={Month_List}
                            getOptionLabel={(option) => option.title ? option.title : ""}
                            value={Selected_Month}
                            onChange={(e, value) => {
                                if (value) {
                                    setSelected_Month(value)
                                    if (SelectedProject && Selected_Year)
                                        Select_DiscountClaimList(value.value, SelectedProject.Project_ID, Selected_Year.value)
                                }
                                else {
                                    setSelected_Month(null)
                                    setDC_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Month" label="Month"
                                />}
                        />
                        <span style={{ color: 'red' }} > {error_message.Month} </span>
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="Project"
                            id="Project"
                            options={ProjectList}
                            getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                            value={SelectedProject}
                            onChange={(e, value) => {
                                if (value) {
                                    setSelectedProject(value)

                                    if (Selected_Month && Selected_Year)
                                        Select_DiscountClaimList(Selected_Month.value, value.Project_ID, Selected_Year.value)
                                }
                                else {
                                    setSelectedProject(null)
                                    setDC_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Project" label="Select Branch"
                                />}
                        />
                        <span style={{ color: 'red' }} > {error_message.Project} </span>
                    </Col>
                    <Col lg={4} xl={4} md={4} style={{ textAlign: "right" }} >
                        <Link to="/DiscountClaim" id='btn_navigate' ></Link>
                        {btn_add && (<Link type="button" className="btn btn-primary rounded-pill" to="#" onClick={add_DC} style={{ border: "none" }} > <Add />  Create New</Link>)}
                    </Col>
                </Row>
                <Row className="row_margin_left row_margin_right" >
                    <Col>
                        <div className="table-responsive" >
                            <MuiThemeProvider theme={theme}>
                                <MUIDataTable
                                    data={DC_Data}
                                    columns={column_DC}
                                    options={{
                                        selectableRows: 'none',
                                        print: false,
                                        search: true,
                                        download: false,
                                        viewColumns: false,
                                        filter: false,
                                    }}
                                />
                            </MuiThemeProvider>
                        </div>
                    </Col>
                </Row>
                <Loader
                    show={IsLoader}
                    hide={hideLoader}
                />
            </Card>

        </>, document.getElementById('PageContents')
    )
}

const mapStateToProps = state => ({
    Comp_Id: state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    SALE_INVOICE: state.SI && state.SI,
    DISCOUNT_POLICY: state.Claim && state.Claim,
    Group_Id: state.Login_User.User_Data && state.Login_User.User_Data.Group_Id,
    p_userid: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
})
export default connect(mapStateToProps, { set_ClaimPolicyType, SHOW_SCREEN })(DiscountClaimList)
