import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit } from '@material-ui/icons'
import  { connect } from 'react-redux'
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

import { getBankOpeningList, getCompanyOpeningList, getCustomerOpeningList, SelectOpeningList } from '../../Services/OpeningAPI'
  import  { setCustomerOpening, SHOW_SCREEN } from '../../React_Redux/Actions'
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import Loader from '../Loader';

function CustomerOpeningList(props) {

    const {  setCustomerOpening,ItemCat , Comp_Id,ProjectID, Group_Id, SHOW_SCREEN } = props;
  
    const [OpeningList_Data, setOpeningList_Data] = useState([])
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [IsLoader, setIsLoader] = useState(false)

    useEffect(()=>{
    document.title = "Bank Opening"
    Select_OpeningList()
    },[])

    const column_Opening = [
        {
         name: "COID",
         label: "ID",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
        {
            name: "CustomerName",
            label: "Customer Name",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
         
        {
         name: "CreatedDate",
         label: "Created Date",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
          name: "Address",
          label: "Address",
          options: {
           filter: true,
           sort: true,
         customHeadLabelRender: (columnMeta)=>{
             return(
               <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
             )
            }, 
           }
         },
        {
          name: "Debit",
          label: "Debit",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
             return(
               <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
             )
            },
          }
         },
        {
         name: "Credit",
         label: "Credit",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },
         }
        },
         {
          name: "action",
          label: "action",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) => {
             
             var InventoryID = OpeningList_Data
             if (InventoryID[rowIndex.rowIndex] != null)
               return (
                 <Link to={"/customer-opening-entry"} onClick={e => edit_opening(OpeningList_Data[rowIndex.rowIndex].COID)}> <Edit /></Link>
               );
           }
          }
         }
    ];
    // const edit_Supplier = (InventoryID) =>{
    //   // set_Supplier(SupplierID,false)
    // }
    // const add_Supplier = () =>{
    //   // set_Supplier(0,true)
    // }
    const edit_opening = (Id) =>{
        setCustomerOpening(null,Id,false)
     }
     const add_Opening = () =>{
       setCustomerOpening(null,0,true)
     }
   const Select_OpeningList = () =>{
    showLoader()
    getCustomerOpeningList(Comp_Id,ProjectID,(mData)=>{
      hideLoader() 
      setOpeningList_Data(mData)
    })
   }
   const showLoader = () => {
    setIsLoader(true)
   
  }
const  hideLoader = () => {
  setIsLoader(false)
  }
  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });
    return ReactDOM.createPortal(
      <>
       <Row className="row_margin_top row_margin_left row_margin_right" >
            <Col lg={6} xl={6} md={6} className="header_title" >
              <h2> Customer Opening List</h2>
            </Col>
          </Row>
      <Card>
         
          <Row className="row_margin_top row_margin_left row_margin_right mt-4 mr-4 ml-4" >
          {/* <Col  style={{ textAlign: "right" }} >
              { btn_add &&(<Link type="button" className="btn btn-primary  rounded-pill" to="/Opening" onClick={add_Opening} style={{ border: "none" }} > <Add />  New (Create New)</Link>)}
            </Col> */}
            <Col  style={{ textAlign: "right" }} >
              { btn_add &&(<Link type="button" className="btn btn-primary  rounded-pill" to="/customer-opening-entry" onClick={add_Opening} style={{ border: "none" }} > <Add />  Create New</Link>)}
            </Col>
          </Row>
          <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col>              
                <div className="table-responsive" >
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        data={OpeningList_Data}
                        columns={column_Opening}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                      </MuiThemeProvider>
                </div>
              </Col>
          </Row>
          <Loader
        show={IsLoader}
        hide={hideLoader}
        />
      </Card>
                        
      </>  , document.getElementById('PageContents')
    )
}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
    Group_Id : state.Login_User.User_Data.Group_Id,

  })
 export default connect(mapStateToProps,{setCustomerOpening, SHOW_SCREEN})(CustomerOpeningList)
