import React from 'react'
import ReactDOM from 'react-dom'
import {Row, Col,Card, Spinner} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {TextField, Popper} from '@material-ui/core'
import { Save, Cancel,ShowChart, Print} from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import { Autocomplete } from '@material-ui/lab';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
import { connect} from 'react-redux'
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch'

import { GetCurrentDate ,preventMinus} from '../../Services/Common'
import { SelectSupplierList } from "../../Services/SuppliersAPI";
import { SelectWareHouseList } from "../../Services/WareHouseAPI";
import { SelectItemWRTSupplier } from '../../Services/PurchaseOrderAPI'
import { SelectItemDetails, SelectPurchaseOrderWRTCode,SelectPurchaseOrderDetailWRTCode } from '../../Services/PurchaseOrderAPI'
import { GetMaxPurchaseeInvoiceNo,SavePurchaseInvoice,SelectPurchaseInvoiceMaster,SelectPurchaseInvoiceDetail } from '../../Services/PurchaseInvoiceAPI'
import { SelectConfigurationData } from '../../Services/ControlPanelAPI'
import { Selectbank } from '../../Services/bankAPI'
import PurchaseOrderModal from './PurchaseOrderModal'
import { SelectCompanyGroupAgainstManufacturer  } from '../../Services/CompanyGroupAPI'
import { SelectManufacturer } from '../../Services/manufactureAPI'
import { SelectMedium } from '../../Services/MediumAPI'
import Loader from '../Loader';
export class PurchaseInvoiceEntry extends React.Component {
  constructor(props){
      super(props)

      this.state = {
        PurchaseInvoiceNo: '',
          SupplierList: [],
          SelectedSupplier: null,
          BankList:[],
          SelectedBank:null,
          SelectedPaymentType:null,
          SelectedStockType:null,
          PurchaseDate: GetCurrentDate(),
          CreateDate:GetCurrentDate(),
          RefInvoiceDate:GetCurrentDate(),
          RefInvoiceNo:'',
          WareHouseList: [],
          SelectedWareHouse: null,
          Item_List: [],
          Selected_item: null,
          Remarks: '',
          PI_Detail_Data: [],
          CompanyGroup_Data:[],
          SelectedCompanyGroup:null,
          Supplier_Err: '',
          WHTAmt:0,
          WHT:0,
          VATAmt:0,
          VATPer: 0,
        UOMID:0,
        Bank_Disable_Prop:false,
        Total_Disable_Prop:false,
        Balance_Disable_Prop:false,
        TotalQty:0,
        TotalDisAmt:0,
        TotalTaxAmt:0,
        TotalTaxAmt1:0,
        PurchaseValue:0,
        GrandTotal:0,
        Balance:0,
        TotalPaid:0,
        Discription:'',

        // Modal 
        Stock_Modal : false,
        btn_edit : true,

        //Hide or Show Columns
        DisAmt_Visible: true,
        DisPer_Visible: true,
        SaleTaxAmt_Visible:true,
        SaleTaxPer_Visible:true,
        btn_AddNew:false,
        btn_Disabled:false,
        
        PaymentType_Err : '',
        StockType_Err:'',
        Bank_Err:'',
        PONo:'',
        filter:null,

        MediumList:[],
        SelectedMedium:null,
        TrackingNo:' ',
        IsLoader:false,

        AddvTaxPer:0,
        AddvTaxAmt:0,
        isGSTCalculateOnMRP: true,
        isGSTCalculateOnDP: false
      }
      this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
      this.validationRules = { required: true };
      this.bg_Ref = React.createRef()
      this.customNameValidation = this.customNameValidation.bind(this)
      this.grid = null
      this.NAmeValidation = {
        required: [this.customNameValidation, 'Name must be selected'],
      };
      this.Comp_Id = this.props.Comp_Id 
      this.ProjectID = this.props.Project_ID
      this.UserID = this.props.p_userid
      this._Options= [
          {value:'Cash Purchase',title:'Cash Purchase'},
          {value:'Credit Purchase',title:'Credit Purchase'},
          {value:'Account Purchase',title:'Account Purchase'},
          {value:'Cash Credit Purchase',title:'Cash Credit Purchase'},
          {value:'Account Credit Purchase',title:'Account Credit Purchase'}]
        
          this._TypeOptions= [
            {value:'Purchase Invoice',title:'Purchase Invoice'},
            {value:'Stock In',title:'Stock In'}]
  }
  
  sortingOptions = {
    columns: [{ field: 'SrNo', direction: 'Ascending' }]
  };
  customNameValidation(args) {
      args.value=this.state.Selected_item ? this.state.Selected_item.ItemID : ''
    return getValue('value', args) != ''   
    }
     showLoader = () => {
        this.setState({IsLoader:true})
      }
      hideLoader = () => {
        this.setState({IsLoader:false})
      }
  componentDidMount(){

    document.title = " Distribution / Purchase Invoice"

    Selectbank(this.Comp_Id,this.ProjectID,(mBank)=>{
        this.setState({BankList:mBank})
   
    SelectWareHouseList(this.Comp_Id,this.ProjectID,(mWareHouse)=>{

        this.setState({WareHouseList:mWareHouse,SelectedWareHouse:mWareHouse[0],btn_AddNew:true, btn_Disabled:false}) //auto selected warehouse
   
    SelectSupplierList(this.Comp_Id,this.ProjectID,(mSupplier)=>{
        this.setState({SupplierList:mSupplier})
        SelectMedium(this.Comp_Id,this.ProjectID,(mMediums)=>{
            this.setState({MediumList:mMediums})
         
          
            if(this.props.isAddNew) // save
            {
                this.GetMax_PurchaseeInvoiceNo() 
                this.setState({SelectedStockType:this._TypeOptions[0]})
                if(this.props.ConfigObj){
                    mWareHouse.map(x=>{
                        if(x.WareHouseID == this.props.ConfigObj.DefaultWareHouseID){
                            this.setState({SelectedWareHouse : x})
                        }
                    })
                    this._Options.map(y=>{
                        if(y.title ==  this.props.ConfigObj.PaymentTypeForPUR){
                            this.setState({SelectedPaymentType:y})
                        }
                    })
                 
                    if(this.props.ConfigObj.PaymentTypeForPUR == 'Account Purchase'){
                        this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                    }
                    else if (this.props.ConfigObj.PaymentTypeForPUR == 'Account Credit Purchase'){
                        this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                    }
                    else if (this.props.ConfigObj.PaymentTypeForPUR == 'Cash Credit Purchase'){
                        this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                    }
                    else if (this.props.ConfigObj.PaymentTypeForPUR == 'Cash Purchase'){
                        this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                    }
                }
               
                // this.setState({SelectedPaymentType: {value:'Account Purchase',title:'Account Purchase'},Bank_Disable_Prop:false,Total_Disable_Prop:true, Balance_Disable_Prop:true})
            }
            else 
            {   
                this.showLoader()
                SelectPurchaseInvoiceMaster(this.Comp_Id,this.ProjectID,this.props.PurchaseInvoiceID,(mData)=>{
                    this.hideLoader()
                    this.setState({
                        PurchaseInvoiceID:mData[0].PurchaseInvoiceID,
                        PurchaseDate:mData[0].PurchaseDate,
                        Discription:mData[0].Discription,
                        TotalDisAmt:mData[0].TotalDisAmt,
                        TotalTaxAmt:mData[0].TotalTaxAmt,
                        TotalTaxAmt1:mData[0].TotalTaxAmt1,
                        TotalQty:mData[0].TotalQty,
                        GrandTotal:parseFloat(mData[0].GrandTotal.toFixed(2)),
                        PurchaseValue:mData[0].PurchaseValue,
                        btn_edit: this.props.show_screen.edit,
                        PurchaseInvoiceNo:mData[0].PurchaseInvoiceNo,
                        RefInvoiceDate:mData[0].RefInvoice_Date && mData[0].RefInvoice_Date  ,
                        RefInvoiceNo:mData[0].RefInvoiceNo && mData[0].RefInvoiceNo,
                        WHT:mData[0].WHT,
                        WHTAmt:mData[0].WHTAmt,
                        TrackingNo:mData[0].TrackingNo,
                        AddvTaxPer: mData[0].AddvTaxPer,
                        AddvTaxAmt : mData[0].AddvTaxAmt,
                        VATPer: mData[0].VATPer,
                        VATAmt: mData[0].VATAmt,
                        PONo: mData[0].PurchaseOrderNo && mData[0].PurchaseOrderNo,
                        isGSTCalculateOnMRP: parseInt(mData[0].isGSTCalculateOnMRP) ? true : false,
                        isGSTCalculateOnDP: parseInt(mData[0].isGSTCalculateOnDP) ? true : false,
                    })
                    this._Options.map(val=>{
                        if(val.value === mData[0].PaymentType )
                        {
                            this.setState({SelectedPaymentType:val},()=>{
                                if(val.value === 'Account Purchase'){
                                    this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                                }
                                else if (val.value === 'Account Credit Purchase'){
                                    this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                                }
                                else if (val.value === 'Cash Credit Purchase'){
                                    this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                                }
                                else if (val.value === 'Cash Purchase'){
                                    this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                                }
                            })  
                        }
                    })
                    this._TypeOptions.map(val=>{
                        if(val.value === mData[0].StockType){
                            this.setState({SelectedStockType:val})
                        }
                    })
                    mWareHouse.map(val=>{
                        if(val.WareHouseID === mData[0].WareHouseID)
                        {
                            this.setState({SelectedWareHouse:val})
                        }
                    })
                    mSupplier.map(val=>{
                        if(val.SupplierID === mData[0].SupplierID)
                        {
                            this.setState({SelectedSupplier:val})
                            SelectCompanyGroupAgainstManufacturer(this.Comp_Id,this.ProjectID,val.ManufacturedByID,(cgdata)=>{
                                this.setState({CompanyGroup_Data:cgdata,SelectedCompanyGroup:cgdata.find(x=> x.CGID === mData[0].CGID)})
                            })
                        }
                    })
                    mBank.map(x=>{
                        if(x.BankID === mData[0].BankID){
                            this.setState({SelectedBank:x})
                        }
                    })
                    mMediums.map(y=>{
                        if(y.MediumID === mData[0].MediumID){
                            this.setState({SelectedMedium:y})
                        }
                    })
                    SelectPurchaseInvoiceDetail(this.Comp_Id,this.ProjectID,this.props.PurchaseInvoiceID,(mDetailData)=>{
                        
                        mDetailData.map((value,index)=>{
                            value.SrNo = index + 1 
                            // value.Qty = value.Qty - value.BonusQty
                        })
                        
                        this.setState({PI_Detail_Data:mDetailData})
                    })
                })
            }

            // this.Select_Item_Details()
        })
       
       })
    })
})
    // Sale Tax Column hide
    SelectConfigurationData(this.Comp_Id,(mConfigData)=>{
        if (mConfigData[0].ApplyTax_on_PurInvoice === 1)
        {
            this.setState({
             DisAmt_Visible: mConfigData[0].IsDisAmt === 1 ? true : false ,
             DisPer_Visible:mConfigData[0].IsDisPer === 1 ? true : false ,
             SaleTaxAmt_Visible:mConfigData[0].IsSaleTaxAmt === 1 ? true : false ,
             SaleTaxPer_Visible:mConfigData[0].IsSaleTaxPer === 1 ? true : false ,
            },()=>{
                this.grid.refresh()
            })
        }
    })
  }
  
  Select_Item_Details = (SupplierID) =>{
    SelectItemWRTSupplier(this.Comp_Id,this.ProjectID,SupplierID,(mItems)=>{
        this.setState({Item_List:mItems})
    })
  }
  Show_Min_Stock_Modal = ()=>{
      this.setState({Stock_Modal:true})
  }
  Hide_Min_Stock_Modal = ()=>{
    this.setState({Stock_Modal:false})
}
    GetMax_PurchaseeInvoiceNo = () => {
this.showLoader()
        GetMaxPurchaseeInvoiceNo(this.Comp_Id, this.ProjectID, 'PUR', (mPurchaseOrderNo) => {
            this.hideLoader()
            this.setState({ PurchaseInvoiceNo: mPurchaseOrderNo })
        })
    }
   
    ItemDiscription = (rowValue) => {
        return (
            <Autocomplete
                name="ItemDiscription"
                id="ItemDiscription"
                defaultValue={this.state.Item_List.find(v => v.ItemDiscription === rowValue.ItemDiscription)}
                options={this.state.Item_List}
                getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : ""}
                onChange={(e, value) => {
                    if (value) {
                        document.getElementById('UOM').value = value.UOM
                        document.getElementById('PurchaseRate').value = value.PurchaseRate
                        document.getElementById('SaleRate').value = value.SaleRate
                        document.getElementById('MRP').value = value.MRP
                        document.getElementById('value').value = value.PurchaseRate
                        document.getElementById('DisPer').value = value.DisPer;
                        document.getElementById('TaxPer1').value = value.GST;
                        this.setState({ Selected_item: value,UOMID:value.UOMID },()=>{
                            this.Calculate_Total()
                        })
                    }
                    else {
                        document.getElementById('UOM').value = ''
                        document.getElementById('PurchaseRate').value = 0
                        document.getElementById('SaleRate').value = 0
                        document.getElementById('MRP').value = 0
                        document.getElementById('value').value = 0
                        document.getElementById('DisPer').value = 0
                        document.getElementById('TaxPer1').value = 0;
                        this.setState({ Selected_item: null },()=>{
                            this.Calculate_Total()
                        })
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="ItemDiscription" />}
            />
        )
    }
    
    Qty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="Qty"
            className="textbox"
            name='Qty'
            min={1}
            onKeyPress={e=>preventMinus(e)}
            defaultValue={rowValue.Qty}
            onChange = { e => {
               this.Calculate_Total()
            }}
        />)
    }
    BonusQty= (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="BonusQty"
            className="textbox"
            name='BonusQty'
            defaultValue={rowValue.BonusQty}
            min={0}
            onKeyPress={e=>preventMinus(e)}
            onChange = { e => {
               this.Calculate_Total()
            }}
        />)
    }
    UOM = (rowValue) => {

        return (<input
            id="UOM"
            name="UOM"
            style={{ textAlign: "right", width: "80%" }}
            defaultValue={rowValue.UOM}
            className="textbox"
            readOnly={true}
        />)

    }
    PurchaseRate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="PurchaseRate"
            defaultValue={rowValue.PurchaseRate}
            className="textbox"
            name="PurchaseRate"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={1}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    SaleRate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="SaleRate"
            defaultValue={rowValue.SaleRate}
            className="textbox"
            name="SaleRate"
            min={1}
            onKeyPress={e=>preventMinus(e)}
            // onChange = { e => {
            //      this.Calculate_Total()
            //  }}
        />)
    }
    MRP = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="MRP"
            defaultValue={rowValue.MRP}
            className="textbox"
            name="MRP"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={1}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    BatchNo = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="text"
            id="BatchNo"
            defaultValue={rowValue.BatchNo}
            className="textbox"
            name="BatchNo"
            onChange = { e => {
                //  this.Calculate_Total()
             }}
        />)
    }
    ExpairyDate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="date"
            id="ExpairyDate"
            defaultValue={rowValue.ExpairyDate}
            className="textbox"
            name="ExpairyDate"
            onChange = { e => {
                //  this.Calculate_Total()
             }}
        />)
    }
    DisPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisPer"
            defaultValue={rowValue.DisPer}
            className="textbox"
            name="DisPer"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={0}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    DisAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisAmt"
            defaultValue={rowValue.DisAmt}
            className="textbox"
            name="DisAmt"
            readOnly={true}
            onChange = { e => {
                 this.Calculate_Total()
             }}
        />)
    }
    // TaxPer = (rowValue) => {
    //     return (<input
    //         style={{ textAlign: "right", width: "80%" }}
    //         type="number"
    //         id="TaxPer"
    //         defaultValue={rowValue.TaxPer}
    //         className="textbox"
    //         name="TaxPer"
    //         onChange = { e => {
    //              this.Calculate_Total()
    //          }}
    //          min={0}
    //          onKeyPress={e=>preventMinus(e)}
    //     />)
    // }
    // TaxAmt = (rowValue) => {
    //     return (<input
    //         style={{ textAlign: "right", width: "80%" }}
    //         type="number"
    //         id="TaxAmt"
    //         defaultValue={rowValue.TaxAmt}
    //         className="textbox"
    //         name="TaxAmt"
    //         readOnly={true}
    //         onChange = { e => {
    //            this.Calculate_Total()
    //          }}
    //     />)
    // }
    TaxPer1 = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxPer1"
            defaultValue={rowValue.TaxPer1}
            className="textbox"
            name="TaxPer1"
            onChange = { e => {
                 this.Calculate_Total()
             }}
             min={0}
             onKeyPress={e=>preventMinus(e)}
        />)
    }
    TaxAmt1 = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxAmt1"
            defaultValue={rowValue.TaxAmt1}
            className="textbox"
            name="TaxAmt1"
            readOnly={true}
        />)
    }
    Value = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="value"
            defaultValue={rowValue.Value}
            className="textbox"
            name="Value"
            readOnly={true}
        />)
    }
    validate = ()=>{
        if(!this.state.SelectedPaymentType){
            this.setState({PaymentType_Err: 'required'})
            document.getElementById('PaymentType').focus()
            return false;
        }
        if(!this.state.SelectedStockType){
            this.setState({StockType_Err: 'required'})
            document.getElementById('StockType').focus()
            return false;
        }
        if(this.state.SelectedPaymentType.value == 'Account Purchase' || this.state.SelectedPaymentType.value == 'Account Credit Purchase'){
            if(!this.state.SelectedBank){
                this.setState({Bank_Err: 'required'})
                document.getElementById('BankName').focus()
                return false;
            }
        }
        return true
    }
    SaveHandler = (fromPrint) => {
        if(this.state.SelectedSupplier)
        {
            if(this.validate()){
                if(this.state.SelectedCompanyGroup){
                    this.setState({Supplier_Err:''})
                    if(this.state.PI_Detail_Data.length > 0)
                    {
                        var PurchaseData = {
                            PurchaseInvoiceID:this.props.PurchaseInvoiceID,
                            Comp_Id:this.Comp_Id,
                            ProjectID : this.ProjectID,
                            UserID : this.UserID,
                            PurchaseInvoiceNo:this.state.PurchaseInvoiceNo,
                            WareHouseID:this.state.SelectedWareHouse.WareHouseID,
                            SupplierID:this.state.SelectedSupplier.SupplierID,
                            CreateDate:this.state.CreateDate,
                            PurchaseDate:this.state.PurchaseDate, 
                            Discription:this.state.Discription,
                            TotalDisAmt:this.state.TotalDisAmt,
                            TotalTaxAmt:this.state.TotalTaxAmt,
                            TotalQty:this.state.TotalQty,
                            GrandTotal:this.state.GrandTotal,
                            PurchaseValue:this.state.PurchaseValue,
                            IsPost:1,
                            TransactionType:'PUR',
                            PaymentType:this.state.SelectedPaymentType.value,
                            BankID:this.state.SelectedBank ? this.state.SelectedBank.BankID:0,
                            TotalPaid: this.state.SelectedPaymentType.value === 'Cash Credit Purchase' || this.state.SelectedPaymentType.value === 'Account Credit Purchase' || this.state.SelectedPaymentType.value == 'Credit Purchase' ? this.state.TotalPaid : this.state.GrandTotal,
                            Balance:this.state.Balance,
                            RefInvoiceDate: this.state.RefInvoiceDate,
                            RefInvoiceNo: this.state.RefInvoiceNo,
                            CGID:this.state.SelectedCompanyGroup && this.state.SelectedCompanyGroup.CGID,
                            WHT:this.state.WHT === '' ? 0 : this.state.WHT,
                            WHTAmt:this.state.WHTAmt,
                            VATAmt: this.state.VATAmt,
                            VATPer: this.state.VATPer,
                            TotalTaxAmt1:this.state.TotalTaxAmt1,
                            MediumID: this.state.SelectedMedium ? this.state.SelectedMedium.MediumID : 0,
                            TrackingNo: this.state.TrackingNo,
                            StockType: this.state.SelectedStockType.value,
                            AddvTaxPer: this.state.AddvTaxPer == '' ? 0 : this.state.AddvTaxPer,
                            AddvTaxAmt : this.state.AddvTaxAmt,
                            ReasonID:  0,
                            isGSTCalculateOnDP: this.state.isGSTCalculateOnDP ? 1 : 0,
                            isGSTCalculateOnMRP: this.state.isGSTCalculateOnMRP ? 1 : 0
                        }
                       
                        for(let i = 0; i< this.state.PI_Detail_Data.length; i++)
                        {
                            if(typeof this.state.PI_Detail_Data[i].Qty === 'undefined' || this.state.PI_Detail_Data[i].Qty == '' || this.state.PI_Detail_Data[i].Qty == null )
                            this.state.PI_Detail_Data[i].Qty = 0
                            else if (typeof this.state.PI_Detail_Data[i].Qty === 'string')
                            this.state.PI_Detail_Data[i].Qty =  parseFloat(this.state.PI_Detail_Data[i].Qty)
        
                            if(typeof this.state.PI_Detail_Data[i].BonusQty === 'undefined' || this.state.PI_Detail_Data[i].BonusQty == '' || this.state.PI_Detail_Data[i].BonusQty == null )
                            this.state.PI_Detail_Data[i].BonusQty = 0
                            else if (typeof this.state.PI_Detail_Data[i].BonusQty === 'string')
                            this.state.PI_Detail_Data[i].BonusQty =  parseFloat(this.state.PI_Detail_Data[i].BonusQty)
        
                            if(typeof this.state.PI_Detail_Data[i].TaxPer === 'undefined' || this.state.PI_Detail_Data[i].TaxPer == '' || this.state.PI_Detail_Data[i].TaxPer == null )
                            this.state.PI_Detail_Data[i].TaxPer = 0
                            else if (typeof this.state.PI_Detail_Data[i].TaxPer === 'string')
                            this.state.PI_Detail_Data[i].TaxPer =  parseFloat(this.state.PI_Detail_Data[i].TaxPer)

                            if(typeof this.state.PI_Detail_Data[i].TaxPer1 === 'undefined' || this.state.PI_Detail_Data[i].TaxPer1 == '' || this.state.PI_Detail_Data[i].TaxPer1 == null )
                            this.state.PI_Detail_Data[i].TaxPer1 = 0
                            else if (typeof this.state.PI_Detail_Data[i].TaxPer1 === 'string')
                            this.state.PI_Detail_Data[i].TaxPer1 =  parseFloat(this.state.PI_Detail_Data[i].TaxPer1)
        
                            if(typeof this.state.PI_Detail_Data[i].DisPer === 'undefined' || this.state.PI_Detail_Data[i].DisPer == '' || this.state.PI_Detail_Data[i].DisPer == null )
                            this.state.PI_Detail_Data[i].DisPer = 0
                            else if (typeof this.state.PI_Detail_Data[i].DisPer === 'string')
                            this.state.PI_Detail_Data[i].DisPer =  parseFloat(this.state.PI_Detail_Data[i].DisPer)
        
                            if(typeof this.state.PI_Detail_Data[i].TaxAmt === 'undefined' || this.state.PI_Detail_Data[i].TaxAmt == '' || this.state.PI_Detail_Data[i].TaxAmt == null )
                            this.state.PI_Detail_Data[i].TaxAmt = 0
                            else if (typeof this.state.PI_Detail_Data[i].TaxAmt === 'string')
                            this.state.PI_Detail_Data[i].TaxAmt =  parseFloat(this.state.PI_Detail_Data[i].TaxAmt)

                            if(typeof this.state.PI_Detail_Data[i].TaxAmt1 === 'undefined' || this.state.PI_Detail_Data[i].TaxAmt1 == '' || this.state.PI_Detail_Data[i].TaxAmt1 == null )
                            this.state.PI_Detail_Data[i].TaxAmt1 = 0
                            else if (typeof this.state.PI_Detail_Data[i].TaxAmt1 === 'string')
                            this.state.PI_Detail_Data[i].TaxAmt1 =  parseFloat(this.state.PI_Detail_Data[i].TaxAmt1)
        
                            if(typeof this.state.PI_Detail_Data[i].DisAmt === 'undefined' || this.state.PI_Detail_Data[i].DisAmt == '' || this.state.PI_Detail_Data[i].DisAmt == null )
                            this.state.PI_Detail_Data[i].DisAmt = 0
                            else if (typeof this.state.PI_Detail_Data[i].DisAmt === 'string')
                            this.state.PI_Detail_Data[i].DisAmt =  parseFloat(this.state.PI_Detail_Data[i].DisAmt)
        
                            if(typeof this.state.PI_Detail_Data[i].PurchaseRate === 'undefined' || this.state.PI_Detail_Data[i].PurchaseRate == '' || this.state.PI_Detail_Data[i].PurchaseRate == null )
                            this.state.PI_Detail_Data[i].PurchaseRate = 0
                            else if (typeof this.state.PI_Detail_Data[i].PurchaseRate === 'string')
                            this.state.PI_Detail_Data[i].PurchaseRate =  parseFloat(this.state.PI_Detail_Data[i].PurchaseRate)
                            
                            if(typeof this.state.PI_Detail_Data[i].SaleRate === 'undefined' || this.state.PI_Detail_Data[i].SaleRate == '' || this.state.PI_Detail_Data[i].SaleRate == null )
                            this.state.PI_Detail_Data[i].SaleRate = 0
                            else if (typeof this.state.PI_Detail_Data[i].SaleRate === 'string')
                            this.state.PI_Detail_Data[i].SaleRate =  parseFloat(this.state.PI_Detail_Data[i].SaleRate)
                            
                            if(typeof this.state.PI_Detail_Data[i].Value === 'undefined' || this.state.PI_Detail_Data[i].Value == '' || this.state.PI_Detail_Data[i].Value == null )
                            this.state.PI_Detail_Data[i].Value = 0
                            else if (typeof this.state.PI_Detail_Data[i].Value === 'string')
                            this.state.PI_Detail_Data[i].Value =  parseFloat(this.state.PI_Detail_Data[i].Value)
        
                            if(typeof this.state.PI_Detail_Data[i].BatchNo === 'undefined' || (this.state.PI_Detail_Data[i].BatchNo).trim() == '' || this.state.PI_Detail_Data[i].BatchNo == null )
                           {
                            Swal.fire({
                                icon: 'info',
                                text: `Batch no is required at Sr No. ${this.state.PI_Detail_Data[i].SrNo}...`,
                            })
                            return
                           }
                            if (this.state.PI_Detail_Data[i].PurchaseOrderID > 0 && this.state.PI_Detail_Data[i].PurchaseOrderDetailID > 0) {
                              for (let j = 0; j < this.state.PI_Detail_Data.length; j++) {
                                if (this.state.PI_Detail_Data[i].ItemID === this.state.PI_Detail_Data[j].ItemID && this.state.PI_Detail_Data[j].PurchaseOrderID === 0 && this.state.PI_Detail_Data[j].PurchaseOrderDetailID === 0) {
                                    this.state.PI_Detail_Data[j].PurchaseOrderID = this.state.PI_Detail_Data[i].PurchaseOrderID;
                                    this.state.PI_Detail_Data[j].PurchaseOrderDetailID = this.state.PI_Detail_Data[i].PurchaseOrderDetailID;
                                }
                              }
                            }
                       
                        
                        }
                  
                        this.setState({btn_Disabled:true})
                        this.state.PI_Detail_Data.sort((a, b) => a.SrNo - b.SrNo);
                        SavePurchaseInvoice(PurchaseData,this.state.PI_Detail_Data,this.props.isAddNew,()=>{
                   
                            if(fromPrint == true){
                                window.open(`/PurchaseReport`, "_blank");
                            }
                            this.setState({btn_Disabled:false})
                            document.getElementById('btnCancel').click()
                        })
                    }
                    else
                    { 
                        this.setState({btn_Disabled:false})
                        Swal.fire({
                            icon: 'info',
                            text: `Purchase Details Must be Entered...`,
                        })
                        return
                    }
                }
                else{
                    this.setState({CG_Err:'Company Group is required',btn_Disabled:false})
                }
            }
        }
        else
        {
            this.setState({Supplier_Err:'Supplier is required',btn_Disabled:false})
        }
    }
    beforeRowInsert = (rowValue) => {

        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.SrNo) {
                    if (rowValue.requestType != "cancel") {
                        rowValue.data.SrNo = parseInt(rowValue.data.SrNo)
                        rowValue.data.ItemDiscription = this.state.Selected_item.ItemDiscription
                        rowValue.data.ItemID = this.state.Selected_item.ItemID
                        rowValue.data.UOMID = this.state.UOMID
                        // rowValue.data.MRP = this.state.Selected_item.MRP
                        if (rowValue.data.PurchaseInvoiceDetailID === undefined)
                            rowValue.data.action = 'add'
                        else
                            rowValue.data.action = rowValue.action

                    }
                }
                else {
                    rowValue.data.SrNo = this.state.PI_Detail_Data.length + 1
                    rowValue.data.BatchNo = '0001'
                    rowValue.data.Qty = 1
                    rowValue.data.BonusQty = 0
                    rowValue.data.SaleRate = 0.00
                    rowValue.data.DisPer = 0.00
                    rowValue.data.DisAmt = 0.00
                    rowValue.data.TaxPer = 0.00
                    rowValue.data.TaxAmt = 0.00
                    rowValue.data.TaxPer1 = 0.00
                    rowValue.data.TaxAmt1 = 0.00

                    this.setState({ Selected_item: null,UOMID:0 })
                }
            }
            // if (rowValue.requestType === 'beginEdit') {
            // }
        }

    }
    afterRowInsert = (rowValue) => {

        if (rowValue.requestType == "beginEdit") {
            
            this.setState({ Selected_item: { "ItemDiscription": rowValue.rowData.ItemDiscription, "ItemID": rowValue.rowData.ItemID, "MRP":rowValue.rowData.MRP  },UOMID: rowValue.rowData.UOMID})
          
        }
        if (rowValue.requestType == "save") {
            this.Calculate_Values()
        }
        if (rowValue.requestType == "delete") {
            this.Calculate_Values()
        }
        
        if(rowValue.requestType === 'add')
        {
            document.getElementById("ExpairyDate").value = GetCurrentDate()
        }
    }
    Calculate_Total = () => {
        
        var Qty = 0, Rate = 0,MRP = 0, DisPer = 0, TaxPer = 0, DisAmt = 0, TaxAmt = 0, Total_Value = 0,TaxPer1 = 0,TaxAmt1 = 0, BonusQty = 0, GSTQty = 0;
        if (document.getElementById('Qty').value)
            Qty = typeof (document.getElementById('Qty').value) === 'string' ? parseFloat((document.getElementById('Qty').value).replace(/,/g, '')) : document.getElementById('Qty').value
        else if (document.getElementById('Qty').value == "")
            Qty = 0
        else
            Qty = parseFloat(document.getElementById('Qty').value)


            if (document.getElementById('BonusQty').value)
                BonusQty = typeof (document.getElementById('BonusQty').value) === 'string' ? parseFloat((document.getElementById('BonusQty').value).replace(/,/g, '')) : document.getElementById('BonusQty').value
            else if (document.getElementById('BonusQty').value == "")
                BonusQty = 0
            else
            BonusQty = parseFloat(document.getElementById('BonusQty').value)

        Rate = document.getElementById('PurchaseRate').value == "" ? 0 : parseFloat(document.getElementById('PurchaseRate').value)
        if(this.state.DisPer_Visible === true)
        {
            DisPer = document.getElementById('DisPer').value == "" ? 0 : parseFloat(document.getElementById('DisPer').value)

        }
        if(this.state.SaleTaxPer_Visible === true ){
           // TaxPer = document.getElementById('TaxPer').value == "" ? 0 : parseFloat(document.getElementById('TaxPer').value)
            TaxPer1 = document.getElementById('TaxPer1').value == "" ? 0 : parseFloat(document.getElementById('TaxPer1').value)
        }
        // MRP = this.state.Selected_item ? this.state.Selected_item.MRP : 0
        MRP = document.getElementById('MRP').value == "" ? 0 : parseFloat(document.getElementById('MRP').value)
        DisAmt = parseFloat((( (Qty * Rate)/ 100) * DisPer).toFixed(2))
        GSTQty = TaxPer1 > 0 ? Qty + BonusQty : Qty
        if (this.state.isGSTCalculateOnDP) {
            const discountedPrice = parseFloat(Rate * GSTQty) * (1 - parseFloat(DisPer) / 100);
            const GST = (discountedPrice / 100) * parseFloat(TaxPer1);
            const actualTax = GST;  // GST after discount applied
            
            TaxAmt  = parseFloat(actualTax).toFixed(2);
            TaxAmt1 = parseFloat(actualTax).toFixed(2);
        } else {
            TaxAmt = parseFloat((((parseFloat(GSTQty) * parseFloat(MRP)) / 100) * parseFloat(TaxPer)).toFixed(2));
            TaxAmt1 = parseFloat((((parseFloat(GSTQty) * parseFloat(MRP)) / 100) * parseFloat(TaxPer1)).toFixed(2));
        }
        // Total_Value = parseFloat((((parseFloat(Qty) * parseFloat(Rate)) - parseFloat(DisAmt)) + parseFloat(TaxAmt) + parseFloat(TaxAmt1)).toFixed(2));
        Total_Value = parseFloat((((parseFloat(Qty) * parseFloat(Rate)) - parseFloat(DisAmt)) + parseFloat(TaxAmt1)).toFixed(2));


        // DisAmt = parseFloat(((DisPer / 100) * Rate).toFixed(2))
        // TaxAmt = parseFloat(((TaxPer / 100) * Rate).toFixed(2))
        // Total_Value = parseFloat(((((Qty * Rate) - DisAmt) + TaxAmt)).toFixed(2))
        if(this.state.DisAmt_Visible){
            document.getElementById('DisAmt').value = DisAmt;

        }
        if(this.state.SaleTaxAmt_Visible){
           // document.getElementById('TaxAmt').value = TaxAmt;
            document.getElementById('TaxAmt1').value = TaxAmt1;

        }
        document.getElementById('value').value = Total_Value;

    }
    Calculate_Values = () =>{
        
        var _TotalQty = 0, _TotalDis = 0, _TotalTax = 0,_TotalTax1 = 0, _TotalPur = 0, _GrandTotal = 0, Rate=0, Qty=0
        for(let i = 0; i< this.state.PI_Detail_Data.length; i++)
        {
           
           if( typeof this.state.PI_Detail_Data[i].Qty === 'string' && typeof this.state.PI_Detail_Data[i].DisAmt === 'string' && typeof this.state.PI_Detail_Data[i].TaxAmt === 'string' && typeof this.state.PI_Detail_Data[i].PurchaseRate === 'string' && typeof this.state.PI_Detail_Data[i].value === 'string'  )
           {
            this.state.PI_Detail_Data[i].Qty = parseFloat((this.state.PI_Detail_Data[i].Qty).replace(/,/g, ''))
            this.state.PI_Detail_Data[i].DisAmt = parseFloat((this.state.PI_Detail_Data[i].DisAmt).replace(/,/g, ''))
            this.state.PI_Detail_Data[i].TaxAmt = parseFloat((this.state.PI_Detail_Data[i].TaxAmt).replace(/,/g, ''))
            this.state.PI_Detail_Data[i].PurchaseRate = parseFloat((this.state.PI_Detail_Data[i].PurchaseRate).replace(/,/g, ''))
            this.state.PI_Detail_Data[i].Value = parseFloat((this.state.PI_Detail_Data[i].Value).replace(/,/g, ''))
           }
           else if (this.state.PI_Detail_Data[i].Qty == '' && typeof this.state.PI_Detail_Data[i].DisAmt === '' && typeof this.state.PI_Detail_Data[i].TaxAmt === '' && typeof this.state.PI_Detail_Data[i].PurchaseRate === '' && typeof this.state.PI_Detail_Data[i].value === '' )
           {
            this.state.PI_Detail_Data[i].Qty = 0 
            this.state.PI_Detail_Data[i].DisAmt = 0
            this.state.PI_Detail_Data[i].TaxAmt1 = 0
            this.state.PI_Detail_Data[i].PurchaseRate = 0
           this.state.PI_Detail_Data[i].Value = 0
           }

           if(this.state.PI_Detail_Data[i].TaxAmt1 === 'string' )
           this.state.PI_Detail_Data[i].TaxAmt1 = parseFloat((this.state.PI_Detail_Data[i].TaxAmt1).replace(/,/g, ''))
           if(this.state.PI_Detail_Data[i].TaxAmt1 === '' )
           this.state.PI_Detail_Data[i].TaxAmt1 = 0

           
           _TotalQty += parseFloat(this.state.PI_Detail_Data[i].Qty)
           _TotalDis += parseFloat(this.state.PI_Detail_Data[i].DisAmt)
           _TotalTax += parseFloat(this.state.PI_Detail_Data[i].TaxAmt)
           _TotalTax1 += parseFloat(this.state.PI_Detail_Data[i].TaxAmt1)
           _TotalPur += parseFloat((this.state.PI_Detail_Data[i].Qty * this.state.PI_Detail_Data[i].PurchaseRate).toFixed(2))
            _GrandTotal += parseFloat(this.state.PI_Detail_Data[i].Value)
        }
        
        this.setState({TotalQty:_TotalQty,TotalDisAmt:_TotalDis,TotalTaxAmt:_TotalTax,TotalTaxAmt1:_TotalTax1,PurchaseValue:_TotalPur,GrandTotal:parseFloat(_GrandTotal.toFixed(2))},()=>{
            this.CalculateWHT();
        })
        
    }
    // updateGrid = () => {
    
    
    //     const updatedData = this.state.PI_Detail_Data.map(item => {
    //         let Qty = item.Qty || 0;
    //         let Rate = item.PurchaseRate || 0;
    //         let MRP = item.MRP || 0;
    //         let DisPer = item.DisPer || 0;
    //         let TaxPer = item.TaxPer || 0;
    //         let TaxPer1 = item.TaxPer1 || 0;
    //         let DisAmt = 0;
    //         let TaxAmt = 0;
    //         let TaxAmt1 = 0;
    //         let Value = 0;
    
    //         // Calculate DisAmt
    //         DisAmt = parseFloat(((Qty * Rate) / 100) * DisPer).toFixed(2);
    
    //         if (this.state.isGSTCalculateOnDP) {
    //             const discountedPrice = parseFloat(Rate * Qty) * (1 - parseFloat(DisPer) / 100);
    //             const GST = (discountedPrice / 100) * parseFloat(TaxPer1);
    //             const actualTax = GST;
                
    //             TaxAmt1 = parseFloat(actualTax).toFixed(2);
    //         } else {
    //             TaxAmt1 = parseFloat((((Qty * MRP) / 100) * TaxPer1)).toFixed(2);
    //         }
    
    //         Value = parseFloat((((Qty * Rate) - DisAmt) + parseFloat(TaxAmt1)).toFixed(2));
    //         return {
    //             ...item,
    //             DisAmt,
    //             TaxAmt,
    //             TaxAmt1,
    //             Value
    //         };
    //     });
    //     // Update the state with new data
    //     this.setState({ PI_Detail_Data: updatedData }, () => {
    //        this.Calculate_Values()
    //     });
    // };
    
    updateGrid = () => {
        if(this.state.PI_Detail_Data.length > 0){
            const updatedData = this.state.PI_Detail_Data.map(item => {
                let Qty = item.Qty || 0;
                let Rate = item.PurchaseRate || 0;
                let MRP = item.MRP || 0;
                let DisPer = item.DisPer || 0;
                let TaxPer = item.TaxPer || 0;
                let TaxPer1 = item.TaxPer1 || 0;
                let DisAmt = 0;
                let TaxAmt1 = 0;
                let Value = 0;
        
                // Calculate DisAmt
                DisAmt = parseFloat(((Qty * Rate) / 100) * DisPer).toFixed(2);
                DisAmt = parseFloat(DisAmt); // Convert it back to number
        
                if (this.state.isGSTCalculateOnDP) {
                    const discountedPrice = parseFloat(Rate * Qty) * (1 - parseFloat(DisPer) / 100);
                    const GST = (discountedPrice / 100) * parseFloat(TaxPer1);
                    const actualTax = GST;
                    
                    TaxAmt1 = parseFloat(actualTax).toFixed(2);
                } else {
                    TaxAmt1 = parseFloat((((Qty * MRP) / 100) * TaxPer1)).toFixed(2);
                }
                TaxAmt1 = parseFloat(TaxAmt1); // Convert back to number
        
                Value = parseFloat((((Qty * Rate) - DisAmt) + parseFloat(TaxAmt1)).toFixed(2));
                Value = parseFloat(Value); // Convert back to number
        
                return {
                    ...item,
                    DisAmt,
                    TaxAmt1,
                    Value
                };
            });
        
            // Log the updated data for debugging purposes
            console.log(updatedData);
        
            // Update the state with new data
            this.setState({ PI_Detail_Data: updatedData }, () => {
                this.Calculate_Values();
            });
        }
    };
    
    toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    LoadStockToGrid = mData =>{
        this.setState({ PI_Detail_Data: this.state.PI_Detail_Data.concat(mData) },()=>{
         var  mArray = [...this.state.PI_Detail_Data]
            mArray.map((value,index)=>{
                value.SrNo = index + 1
            })
            this.setState({mArray}, ()=>{
                this.Calculate_Values()
            })
        })
        this.Hide_Min_Stock_Modal()
        
    }
    CalculateWHT = (VATAmt = 0)=>{
        var mWHTAmt = 0,_GrandTotal=0, _AddTaxAmt = 0
        for(let i = 0; i< this.state.PI_Detail_Data.length; i++)
        {
           if( typeof this.state.PI_Detail_Data[i].value === 'string'  )
           {
            this.state.PI_Detail_Data[i].Value = parseFloat((this.state.PI_Detail_Data[i].Value).replace(/,/g, ''))
           }
           else if (typeof this.state.PI_Detail_Data[i].value === '' )
           {
           this.state.PI_Detail_Data[i].Value = 0
           }
            _GrandTotal += parseFloat(this.state.PI_Detail_Data[i].Value)
        }
        mWHTAmt = parseFloat(((_GrandTotal / 100 ) * this.state.WHT).toFixed(2))
        _AddTaxAmt =  parseFloat(((_GrandTotal / 100 ) * this.state.AddvTaxPer).toFixed(2))
        
        _GrandTotal = _GrandTotal + mWHTAmt + _AddTaxAmt + VATAmt
        this.setState({WHTAmt:mWHTAmt,AddvTaxAmt:_AddTaxAmt,VATAmt: VATAmt,GrandTotal:parseFloat(_GrandTotal.toFixed(2))})
    }
    Inv_Columns = [
        {
            field: "SupplierName",
            header: "Supplier Name",
            width: "150px",
        },
        {
            field: "ManufacturedBy",
            header: "Manufactured By",
            width: "150px",
        },
    ];
    handleFilterChange = event => {
        if (event) {
          this.setState({ filter: event.filter });
        }
    };
    
render(){
        if(this.grid)
            this.grid.autoFitColumns(['SrNo','ItemDescription','dd_qty','Godown','Mnuit']);
        enableRipple(true);
    return ReactDOM.createPortal (
        <>
           <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2> Purchase Invoice
                    </h2>
                </div>
            </div>
        </div>
        
            <Card>
           
            <Row className="row_margin_top row_margin_left row_margin_right p-4" >
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            name="PurchaseInvoiceNo"
                            label="Purchase Invoice No" 
                            value={this.state.PurchaseInvoiceNo}
                            inputProps={
                                {readOnly: true}
                            }
                            fullWidth
                            />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                    <MultiColumnComboBox
                            data={this.state.filter ? filterBy(this.state.SupplierList, this.state.filter) : this.state.SupplierList}
                            value={this.state.SelectedSupplier}
                            style={{ width: "100%", fontSize: "12px",backgroundColor:'white' }}
                            columns={this.Inv_Columns}
                            textField={"SupplierName"}
                            filterable={true}
                            label='Select Supplier'
                            onFilterChange={this.handleFilterChange}
                            onChange={e => {
                                if (e.value) {
                                    this.Select_Item_Details(e.value.SupplierID)
                                    SelectCompanyGroupAgainstManufacturer(this.Comp_Id,this.ProjectID,e.value.ManufacturedByID,(mData)=>{
                                        this.setState({CompanyGroup_Data:mData,SelectedSupplier:e.value})
                                    })
                                    SelectManufacturer(this.Comp_Id,this.ProjectID,e.value.ManufacturedByID,(manufacturer)=>{
                                        this.setState({WHT:manufacturer[0].WHT,AddvTaxPer:manufacturer[0].AddvTaxPer},()=>{
                                            this.CalculateWHT()
                                        })
                                        
                                    })
                                }
                                else {
                                    this.setState({Item_List:[]})
                                    this.setState({SelectedSupplier:null,CompanyGroup_Data:[],SelectedCompanyGroup:null})
                                }
                            }}
                        />
                         <span className="text-danger">{this.state.Supplier_Err}</span>

                        {/* <Autocomplete
                            name="SupplierName"
                            id="SupplierName"
                            options={this.state.SupplierList}
                            getOptionLabel={(option) => option.SupplierName ? option.SupplierName : ""}
                            value={this.state.SelectedSupplier}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    SelectCompanyGroupAgainstManufacturer(this.Comp_Id,this.ProjectID,value.ManufacturedByID,(mData)=>{
                                        this.setState({CompanyGroup_Data:mData,SelectedSupplier:value})
                                    })
                                    SelectManufacturer(this.Comp_Id,this.ProjectID,value.ManufacturedByID,(manufacturer)=>{
                                        this.setState({WHT:manufacturer[0].WHT},()=>{
                                            this.CalculateWHT()
                                        })
                                        
                                    })
                              
                                }
                                else
                                {
                                    this.setState({SelectedSupplier:null,CompanyGroup_Data:[],SelectedCompanyGroup:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="SupplierName" label="Supplier"
                                />
                            }
                        /> */}
                       
                    </Col>
                    {/* <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="WareHouse"
                            id="WareHouse"
                            options={this.state.WareHouseList}
                            getOptionLabel={(option) => option.WareHouse ? option.WareHouse : ""}
                            value={this.state.SelectedWareHouse}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedWareHouse:value,btn_AddNew:true})
                              
                                }
                                else
                                {
                                    this.setState({SelectedWareHouse:null,btn_AddNew:false})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="WareHouse" label="WareHouse"
                                />}
                        />
                        <span className="text-danger">{this.state.Supplier_Err}</span>
                    </Col> */}
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            value={this.state.PurchaseDate}
                            name="PurchaseDate"
                            label="Purchase Date" 
                            type= 'date'
                            onChange= {e => this.setState({PurchaseDate:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="PaymentType"
                            id="PaymentType"
                            options={this._Options}
                            getOptionLabel={(option) => option.title ? option.title : ""}
                            value={this.state.SelectedPaymentType}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedPaymentType:value},()=>{
                                        if(value.value === 'Account Purchase'){
                                            this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                                        }
                                        else if (value.value === 'Account Credit Purchase'){
                                            this.setState({Bank_Disable_Prop:false,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                                        }
                                        else if (value.value === 'Cash Credit Purchase'){
                                            this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:false, Balance_Disable_Prop:false})
                                        }
                                        else if (value.value === 'Cash Purchase'){
                                            this.setState({Bank_Disable_Prop:true,Total_Disable_Prop:true, Balance_Disable_Prop:true})
                                        }
                                    })
                              
                                }
                            
                                else
                                {
                                    this.setState({SelectedPaymentType:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="PaymentType" label="Payment Type"
                                />}
                        />
                        <span className="text-danger">{this.state.PaymentType_Err}</span>
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <Autocomplete
                            name="BankName"
                            id="BankName"
                            options={this.state.BankList}
                            getOptionLabel={(option) => option.BankName ? option.BankName : ""}
                            value={this.state.SelectedBank}
                            disabled={this.state.Bank_Disable_Prop}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedBank:value})
                              
                                }
                                else
                                {
                                    this.setState({SelectedBank:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="BankName" label="BankName"
                                />}
                        />
                        <span className="text-danger">{this.state.Bank_Err}</span>
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalPaid"
                            label="TotalPaid"
                            type="number"
                            value={this.state.TotalPaid}
                            disabled={this.state.Total_Disable_Prop}
                            onChange={e=>this.setState({TotalPaid:e.target.value}
                                ,()=>{
                                    let bal =   this.state.TotalPaid - this.state.GrandTotal
                                    this.setState({Balance:bal})
                                })}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="Balance"
                            label="Balance" 
                            type="number"
                            value={this.state.Balance}
                            // onInput={this.toInputUppercase}
                            disabled={this.state.Balance_Disable_Prop}
                            onChange={e=>this.setState({Balance:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={6} xl={6} md={6} >
                        <TextField
                            name="Discription"
                            label="Discription" 
                            value={this.state.Discription}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({Discription:e.target.value})}
                            fullWidth
                        />
                    </Col> 
                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="CGID"
                            id="CGID"
                            options={this.state.CompanyGroup_Data}
                            getOptionLabel={(option) => option.CGName ? option.CGName : ""}
                            value={this.state.SelectedCompanyGroup}
                            onChange={(e, value) => {

                                if (value) {
                                    this.setState({SelectedCompanyGroup:value})
                                }
                                else {
                                    this.setState({SelectedCompanyGroup:value})
                                }

                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="CGID" id="CGID" label="Company Group"
                                />}

                        />
                        <span className="text-danger">{this.state.CG_Err}</span>
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            name="RefInvoiceNo"
                            label="Reference Invoice #" 
                            value={this.state.RefInvoiceNo}
                            onChange={e=>this.setState({RefInvoiceNo:e.target.value})}
                            fullWidth
                            />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            value={this.state.RefInvoiceDate}
                            name="RefInvoiceDate"
                            label="Ref Invoice Date" 
                            type= 'date'
                            onChange= {e => this.setState({RefInvoiceDate:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            name="PONo"
                            label="Purchase Order #" 
                            value={this.state.PONo}
                            disabled = {!this.props.isAddNew}
                            onChange={e=>{
                                this.setState({PONo:e.target.value})
                                if(e.target.value.length !== 6 ){
                                    this.setState({PI_Detail_Data:[],TotalQty:0,GrandTotal:0,PurchaseValue:0,TotalTaxAmt:0,TotalTaxAmt1:0,TotalDisAmt:0,SelectedMedium:null,TrackingNo:'',SelectedSupplier:null,WHTAmt:0,WHT:0,AddvTaxAmt:0,AddvTaxPer:0, VATAmt:0, VATPer:0})
                                }
                                if(e.target.value.length === 6){
                                    SelectPurchaseOrderWRTCode(this.Comp_Id,this.ProjectID,e.target.value,(mData)=>{

                                      if(mData.length > 0) {
                                        this.setState({VATPer: mData[0].VATPer})
                                       const Selectedsup =  this.state.SupplierList.find(x=> x.SupplierID === mData[0].SupplierID)
                                       if(Selectedsup){
                                        this.Select_Item_Details(Selectedsup.SupplierID)
                                        SelectCompanyGroupAgainstManufacturer(this.Comp_Id,this.ProjectID,Selectedsup.ManufacturedByID,(mDataManufacturer)=>{
                                            this.setState({CompanyGroup_Data:mDataManufacturer,SelectedSupplier:Selectedsup})
                                        })
                                        SelectManufacturer(this.Comp_Id,this.ProjectID,Selectedsup.ManufacturedByID,(manufacturer)=>{
                                            this.setState({WHT:manufacturer[0].WHT,AddvTaxPer:manufacturer[0].AddvTaxPer},()=>{
                                                this.CalculateWHT(mData[0].VATAmt)
                                            })
                                            
                                        })
                                       }
                        
                                        SelectPurchaseOrderDetailWRTCode(this.Comp_Id,this.ProjectID,mData[0].PurchaseOrderID,(mDetailData)=>{ 
                                            mDetailData.map((value,index)=>{
                                                value.SrNo = index + 1 
                                                value.BonusQty = 0
                                                value.BatchNo = '0001'
                                                value.ExpairyDate = GetCurrentDate()
                                                value.action = 'add'
                                                value.OriginalQty = value.Qty // keep tracking the original PO Qty
                                                value.Qty = value.BalancedQty // only get the last remaming Qty 
                                                value.TaxAmt1 = parseFloat((((value.BalancedQty * value.MRP) / 100) * value.TaxPer).toFixed(2))//    TaxAmt = parseFloat((((Qty * MRP) / 100) * TaxPer).toFixed(2))
                                                value.TaxPer1 =value.TaxPer
                                                value.DisAmt = parseFloat((( (value.Qty * value.PurchaseRate) / 100) * value.DisPer).toFixed(2))
                                              //  value.TaxAmt = value.TaxAmt
                                               // value.TaxAmt = parseFloat((((value.Qty * value.PurchaseRate) / 100) * value.TaxPer).toFixed(2))
                                                value.Value  =  parseFloat(((((value.Qty * value.PurchaseRate) - value.DisAmt) + value.TaxAmt1)).toFixed(2))
                                              })
                                             
                                            this.setState({PI_Detail_Data:mDetailData},()=>{
                                                this.Calculate_Values(mData[0].VATAmt)
                                            })
                                        })
                                      }
                                      else{
                                        Swal.fire({
                                            icon: 'info',
                                            title: 'Oops...',
                                            text: `No Data Found on this PO No`,
                                          })
                                      }
                                      
                                    })
                                }
                                
                            }}
                            fullWidth
                            />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={3} > </Col>
                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="Medium"
                            id="Medium"
                            options={this.state.MediumList}
                            getOptionLabel={(option) => option.MediumName ? option.MediumName : ""}
                            value={this.state.SelectedMedium}
                            onChange={(e, value) => {

                                if (value) {
                                    this.setState({SelectedMedium:value})
                                }
                                else {
                                    this.setState({SelectedMedium:value})
                                }

                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Medium" id="Medium" label="Medium"
                                />}

                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            name="TrackingNo"
                            label="Tracking #" 
                            value={this.state.TrackingNo}
                            onChange={e=>this.setState({TrackingNo:e.target.value})}
                            fullWidth
                            />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={3} >
                    <Autocomplete
                    name="StockType"
                    id="StockType"
                    options={this._TypeOptions}
                    getOptionLabel={(option) => option.title ? option.title : ""}
                    value={this.state.SelectedStockType}
                    onChange={(e, value) => {
                        if (value)
                        {
                            this.setState({SelectedStockType:value})   
                      
                        }
                    
                        else
                        {
                            this.setState({SelectedStockType:null})
                    
                        }
                    }}
                    renderInput={(params) =>
                        <TextField {...params} name="Stock" label="Stock Type"
                        />}
                />
                <span className="text-danger">{this.state.StockType_Err}</span> 
                </Col>
                </Row>
             
             
                <Row className="row_margin_top row_margin_left row_margin_right p-4 mt-2" >
                <Row>
                    <Col xs={7}></Col>
                    <Col sx={5} className='flex ml-auto'>
                    <FormControlLabel control={<Switch 
                    value={this.state.isGSTCalculateOnMRP}
                    checked={this.state.isGSTCalculateOnMRP}
                    onChange={()=>{
                        this.setState({isGSTCalculateOnDP: false, isGSTCalculateOnMRP: true}, ()=>{
                            this.updateGrid()
                        })
                    }}
                    
                    />} label="GST Calculate on MRP" />
                    <FormControlLabel control={<Switch
                    value={this.state.isGSTCalculateOnDP}
                    checked={this.state.isGSTCalculateOnDP}
                    onChange={()=>{
                        console.log("Switch toggled");
                        this.setState({isGSTCalculateOnMRP: false, isGSTCalculateOnDP: true}, ()=>{
                            console.log("State updated, calling updateGrid"); 
                            this.updateGrid()
                        })
                    }}
                    
                    />} label="GST Calculate on DP" />
                    </Col>
               
                </Row>
                    <div style={{display:"flex",width:"100%",height:"100%",overflow:"hidden"}}> 
                        <div style={{flex:"1",width:"100%",height:"100%"}}>
                            <GridComponent gridLines='Both' dataSource={this.state.PI_Detail_Data} actionBegin={ this.beforeRowInsert} ref={g => this.grid = g} actionComplete={this.afterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: true, allowDeleting: true, allowAdding: this.state.btn_AddNew, newRowPosition:'Bottom'  }} sortSettings={this.sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={this.toolbarOptions}>
                                <ColumnsDirective>
                                    <ColumnDirective width='120' field='SrNo' headerText='SrNo #' Format= "{0:n}" allowEditing={false} isIdentity={true}  textAlign='Left' headerTextAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                    <ColumnDirective width='250' field='ItemDiscription' headerText='Item Discription' editType= 'text'  editTemplate={this.ItemDiscription} edit={this.cbParams}  textAlign='Left' headerTextAlign='Left'/> {/*validationRules={true} */}
                                    <ColumnDirective width='170' field='Qty' headerText='Qty' editTemplate={this.Qty} textAlign='right' headerTextAlign='right'/>
                                    <ColumnDirective width='170' field='BonusQty' headerText='BonusQty' editType="number" editTemplate={this.BonusQty} textAlign='right' headerTextAlign='right'/>
                                    <ColumnDirective width='130' field='UOM' headerText='UOM'  editTemplate={this.UOM} />
                                    {/* <ColumnDirective width='170' field='Expairy' headerText='Expiry Date'  editTemplate={this.Expairy} textAlign='right'></ColumnDirective> */}
                                    <ColumnDirective width='150' field='PurchaseRate' headerText='PurchaseRate'   editTemplate={this.PurchaseRate} textAlign='right' headerTextAlign='right'></ColumnDirective>
                                    <ColumnDirective width='150' field='SaleRate' headerText='SaleRate'   editTemplate={this.SaleRate} textAlign='right' headerTextAlign='right'></ColumnDirective>
                                    <ColumnDirective width='130' field='MRP' headerText='MRP' editTemplate={this.MRP} textAlign='right' headerTextAlign='right' />
                                    <ColumnDirective width='250' field='BatchNo' headerText='BatchNo' editType= 'text'  editTemplate={this.BatchNo}   edit={this.cbParams}   textAlign='Left' headerTextAlign='Left' />
                                    <ColumnDirective width='250' field='ExpairyDate' headerText='ExpairyDate' editType= 'date'  editTemplate={this.ExpairyDate}   edit={this.cbParams}   textAlign='Left' headerTextAlign='Left' />
                                    <ColumnDirective width='150' field='DisPer' headerText='Dis%'   editTemplate={this.DisPer} visible={this.state.DisPer_Visible} textAlign='right' headerTextAlign='right' ></ColumnDirective>
                                    <ColumnDirective width='150' field='DisAmt' headerText='DisAmt'   editTemplate={this.DisAmt} visible={this.state.DisAmt_Visible} textAlign='right' headerTextAlign='right' ></ColumnDirective>
                                    {/*<ColumnDirective width='150' field='TaxPer' headerText='GST%'   editTemplate={this.TaxPer} visible={this.state.SaleTaxPer_Visible} textAlign='right'></ColumnDirective>*/}
                                     {/*<ColumnDirective width='150' field='TaxAmt' headerText='GSTAmt'   editTemplate={this.TaxAmt} visible={this.state.SaleTaxAmt_Visible} textAlign='right'></ColumnDirective>*/}
                                    <ColumnDirective  width='150' field='TaxPer1' headerText={this.Comp_Id === 6 ? 'VAT%' : 'GST%'}   editTemplate={this.TaxPer1} visible={this.state.SaleTaxPer_Visible} textAlign='right' headerTextAlign='right' ></ColumnDirective>
                                    <ColumnDirective  width='150' field='TaxAmt1' headerText={this.Comp_Id === 6 ? 'VAT Amt' : 'GST Amt'}    editTemplate={this.TaxAmt1} visible={this.state.SaleTaxAmt_Visible} textAlign='right' headerTextAlign='right' ></ColumnDirective>
                                    <ColumnDirective width='150' field='Value' headerText='Value'   editTemplate={this.Value} textAlign='right' headerTextAlign='right' ></ColumnDirective>
                                    
                                   {/* <ColumnDirective width='130' field='MRP' headerText='MRP' visible={false}/>*/}
                                    <ColumnDirective field='ItemID'  visible={false}  ></ColumnDirective>
                                    <ColumnDirective field='PurchaseInvoiceDetailID' headerText='PurchaseInvoiceDetailID'  visible={false}></ColumnDirective>
                                    <ColumnDirective field='PurchaseOrderDetailID' defaultValue={0}  visible={false}></ColumnDirective>
                                    <ColumnDirective field='PurchaseOrderID' defaultValue={0}  visible={false}></ColumnDirective>
                                    <ColumnDirective field='PurchaseInvoiceID' headerText='PurchaseInvoiceID'  width='150' hideAtMedia={true} ></ColumnDirective>
                                    <ColumnDirective field='UOMID' headerText='UOMID'  width='150' hideAtMedia={true} ></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]}   />
                            </GridComponent>
                        </div>
                    </div> 
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right p-4" >
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalQty"
                            label="TotalQty" 
                            value={this.state.TotalQty}
                            type="number"
                            InputProps={{
                                readOnly:true
                            }}
                            onChange={e=>this.setState({TotalQty:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalDisAmt"
                            label="TotalDisAmt" 
                            value={this.state.TotalDisAmt}
                            type="number"
                            InputProps={{
                                readOnly:true
                            }}
                            onChange={e=>this.setState({TotalDisAmt:e.target.value})}
                            fullWidth
                        />
                    </Col>
                 {/*   <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalTaxAmt"
                            label="Total GST Amt" 
                            value={this.state.TotalTaxAmt}
                            type="number"
                            InputProps={{
                                readOnly:true
                            }}
                            onChange={e=>this.setState({TotalTaxAmt:e.target.value})}
                            fullWidth
                        />
                    </Col>*/}
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="TotalTaxAmt1"
                            label={this.Comp_Id === 6 ? 'Total VAT Amt' : 'Total GST Amt'}  
                            value={this.state.TotalTaxAmt1}
                            type="number"
                            InputProps={{
                                readOnly:true
                            }}
                            // onChange={e=>this.setState({TotalTaxAmt:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="WHT"
                            label="WHT %" 
                            value={this.state.WHT}
                            type="number"
                            onChange={e=>this.setState({WHT:e.target.value},()=>{
                                this.CalculateWHT();
                            })}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="WHTAmt"
                            label="WHT Amt" 
                            value={this.state.WHTAmt}
                            type="number"
                            inputProps={
                                {readOnly: true}
                            }
                            // onChange={e=>this.setState({WHTAmt:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="AddvTaxPer"
                            label="Add Tax %" 
                            value={this.state.AddvTaxPer}
                            type="number"
                            onChange={e=>this.setState({AddvTaxPer:e.target.value},()=>{
                                this.CalculateWHT();
                            })}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="AddvTaxAmt"
                            label="Add Tax Amt" 
                            value={this.state.AddvTaxAmt}
                            type="number"
                            inputProps={
                                {readOnly: true}
                            }
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="PurchaseValue"
                            label="PurchaseValue" 
                            value={this.state.PurchaseValue}
                            type="number"
                            onChange={e=>this.setState({PurchaseValue:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    {/*if you need VAT Amt or VAT Per just comment below  code al work already done till db*}
               {/*     <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField 
                            name="VATPer"
                            label="VAT %"
                            type='number'
                            value={this.state.VATPer}
                            inputProps={{ style: { color: "red", fontWeight: 'bold', fontSize: '16px' } }}
                            onChange={e => {
                                const newVATPer = e.target.value;
                                this.setState({ VATPer: newVATPer });
                                const mAmt = (this.state.GrandTotal * newVATPer) / 100;
                                const roundedVATAmt = parseFloat(mAmt.toFixed(2));
                                this.CalculateWHT(roundedVATAmt);
                                
                                
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField 
                            name="VATAmt"
                            label="VAT Amount"
                            type='number'
                            value={this.state.VATAmt}
                            disabled={true}
                            inputProps={{ style: { color: "red", fontWeight: 'bold', fontSize: '16px' } }}
                            onChange={e=>{
                                this.setState({VATAmt:e.target.value})
                            }}
                            fullWidth
                        />
                    </Col>*/}
                    <Col sm={12} lg={4} xl={4} md={4} >
                        <TextField
                            name="GrandTotal"
                            label="GrandTotal" 
                            type="number"
                            value={this.state.GrandTotal}
                            inputProps={{ style: { color: "green", fontWeight: 'bold', fontSize: '20px' } }}
                            onChange={e=>this.setState({GrandTotal:e.target.value})}
                            fullWidth
                        />
                    </Col>
                  
                    </Row>
                  
                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                    <Col style={{textAlign: "right", marginTop: "1%"}} >
                             {/* <Link id="btnGetItems" className="btn btn-primary rounded-pill" style={{border: "none"}} to={'#'} onClick={this.Show_Min_Stock_Modal}> <ShowChart /><u>Get Items from Purchase Order</u></Link> */}
                            {this.state.btn_edit && (<button type="button" className="btn btn-primary rounded-pill"  style={{border: "none"}} onClick={this.SaveHandler}  disabled={this.state.btn_Disabled}>
                            {
                             this.state.btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                                }  </button>)}
                            <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{border: "none"}} to={'/PurchaseInvoiceList'} > <Cancel /> <u>Close</u></Link>
                            {this.state.btn_edit && (  <button
                             className="btn btn-primary rounded-pill" 
                             style={{border: "none"}} 
                             onClick={e =>{
                                     var obj = {
                                        PurchaseInvoiceID:this.state.PurchaseInvoiceID,
                                        InvoiceNo: this.state.PurchaseInvoiceNo,
                                        PurchaseDate:this.state.PurchaseDate,
                                        Discription:this.state.Discription,
                                        TotalDisAmt:this.state.TotalDisAmt,
                                        TotalTaxAmt:this.state.TotalTaxAmt,
                                        TotalTaxAmt1:this.state.TotalTaxAmt1,
                                        Medium: this.state.SelectedMedium ? this.state.SelectedMedium.MediumName : '',
                                        TrackingNo : this.state.TrackingNo,
                                        TotalQty:this.state.TotalQty,
                                        GrandTotal:this.state.GrandTotal,
                                        PurchaseValue:this.state.PurchaseValue,
                                        Supplier: this.state.SelectedSupplier,
                                        Warehouse: this.state.SelectedWareHouse,
                                        PaymentType: this.state.SelectedPaymentType,
                                        Bank: this.state.SelectedBank,
                                        Detail: this.state.PI_Detail_Data,
                                        CG: this.state.SelectedCompanyGroup,
                                        StockType: this.state.SelectedStockType,
                                        WHT: this.state.WHT,
                                        WHTAmt:this.state.WHTAmt,
                                        AddvTaxPer: this.state.AddvTaxPer,
                                        AddvTaxAmt:this.state.TotalTaxAmt
                                    }
                                    localStorage.setItem('token',sessionStorage.getItem('token'))
                                    localStorage.setItem("PurchaseInvoice", JSON.stringify(obj))
                                //    setTimeout(() => {
                                    this.SaveHandler(true)
                                //    }, 5000);
                              
                             }}
                             disabled={this.state.btn_Disabled} >{
                                this.state.btn_Disabled ?
                                (<><Spinner
                                     as="span"
                                     animation="grow"
                                     size="sm"
                                     role="status"
                                     aria-hidden="true"
                                     /> Saving... </>) : <><Print /> <u> Save &amp; Print</u></>
                             } </button>)}

                           
                        </Col>
                    </Row>
                    <PurchaseOrderModal 
                        show={this.state.Stock_Modal}
                        onHide = {this.Hide_Min_Stock_Modal}
                        mSaveStock = {this.LoadStockToGrid}
                        SupplierID={this.state.SelectedSupplier && this.state.SelectedSupplier.SupplierID}
                        projectID = { this.ProjectID }
                    />
                    <Loader
                    show={this.state.IsLoader}
                    hide={this.hideLoader}
                    />
                </Card>
            
        </>, document.getElementById('PageContents')
    )
}

}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    isAddNew: state.PI && state.PI.isAddNew,
    PurchaseInvoiceID:state.PI && state.PI.PurchaseInvoiceID,
    Project_ID:state.PI && state.PI.Project_ID,
    show_screen: state.SCREEN,
   ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig,
})
export default connect(mapStateToProps)(PurchaseInvoiceEntry)