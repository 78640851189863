import React, { useState, useEffect, useReducer } from "react";
import ReactDOM from "react-dom";
import { Row, Col, Card, Spinner } from "react-bootstrap";
import CheckTreePicker from 'rsuite/CheckTreePicker';
import { TextField, Popper, FormControlLabel, Button } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CircularProgress from "@mui/material/CircularProgress";
import { Chip } from "@material-ui/core";

import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { GetCurrentDate, preventMinus } from "../../Services/Common";
import { connect } from "react-redux";
import DiscountClaimTable from "./DiscountClaimTable";
import { GetMaxClaimNo, SelectClaimDropDowns } from "../../Services/ClaimAPI";
import { SelectManufacturedByList } from "../../Services/manufactureAPI";
import {
  SelectCompanyGroups,
  SelectCompanyGroupAgainstMultipleCompanys,
} from "../../Services/CompanyGroupAPI";
import {
  SelectActiveItems,
  SelectItemsAgainstMultipleCompanyGroup,
} from "../../Services/ItemsAPI";
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const initialState = {
  selectedOptionsManufacturer: [],
  selectedOptionsCG: [],
  SelectOptionsItem: [],
};
function reducer(state, action) {
  switch (action.type) {
    case "SET_SELECTED_COMPANY_OPTION":
      return { ...state, selectedOptionsManufacturer: action.payload.options };
    case "SET_SELECTED_CG_OPTION":
      return {
        ...state,
        selectedOptionsCG: action.payload.options,
      };
    case "SET_SELECTED_ITEM_OPTION":
      return {
        ...state,
        SelectOptionsItem: action.payload.options,
      };

    case "REMOVE_COMPANY_OPTION":
      return {
        ...state,
        selectedOptionsManufacturer: state.selectedOptionsManufacturer.filter(
          (option) =>
            option.ManufacturedByID !== action.payload.ManufacturedByID
        ),
      };
    case "REMOVE_COMPANY_GROUP_OPTION":
      return {
        ...state,
        selectedOptionsCG: state.selectedOptionsCG.filter(
          (option) => option.CGID !== action.payload.CGID
        ),
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
}
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
const DiscountClaim = ({ Comp_Id, ProjectID }) => {
  const [ClaimNo, setClaimNo] = useState("");
  const [ClaimDate, setClaimDate] = useState(GetCurrentDate());
  const [FromDate, setFromDate] = useState(GetCurrentDate());
  const [ToDate, setToDate] = useState(GetCurrentDate());
  const [Remarks, setRemarks] = useState("");
  const [IsClaimable, setIsClaimable] = useState(false);
  const [IsTPClaimable, setIsTPClaimable] = useState(false);
  const [IsDPClaimable, setIsDPClaimable] = useState(false);
  const [IsOurShare, setIsOurShare] = useState(false);
  const [OurShare, setOurShare] = useState(0.0);
  const [ManufacturedBy_List, setManufacturedBy_List] = useState([]);
  const [CompanyGroupList, setCompanyGroupList] = useState([]);
  const [ItemsList, setItemsList] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [Data, setData] = useState([])
  const [DDLoader, setDDLoader] = useState(true)
  // const data = [{
  //   label: "Factors",
  //   value: "1",
  //   children: [
  //     {
  //       label: "Engineer",
  //       value: "1-1",
  //       children: [
  //         {
  //           label: "Loyce",
  //           value: "1-1-1",
  //           visible: true
  //         },
  //         {
  //           label: "Harvey",
  //           value: "1-1-2",
  //           visible: true
  //         },
  //         {
  //           label: "Lukas",
  //           value: "1-1-3",
  //           visible: true
  //         },
  //         {
  //           label: "Consuelo",
  //           value: "1-1-4",
  //           visible: true
  //         }
  //       ],
  //       visible: true
  //     },
  //     {
  //       label: "Planner",
  //       value: "1-2",
  //       children: [
  //         {
  //           label: "Natasha",
  //           value: "1-2-1",
  //           visible: true
  //         },
  //         {
  //           label: "Lloyd",
  //           value: "1-2-2",
  //           visible: true
  //         },
  //         {
  //           label: "May",
  //           value: "1-2-3",
  //           visible: true
  //         },
  //         {
  //           label: "Tavares",
  //           value: "1-2-4",
  //           visible: true
  //         }
  //       ],
  //       visible: true
  //     },
  //     {
  //       label: "Coordinator",
  //       value: "1-3",
  //       children: [
  //         {
  //           label: "Fabian",
  //           value: "1-3-1",
  //           visible: true
  //         },
  //         {
  //           label: "Della",
  //           value: "1-3-2",
  //           visible: true
  //         },
  //         {
  //           label: "Alejandrin",
  //           value: "1-3-3",
  //           visible: true
  //         },
  //         {
  //           label: "Reymundo",
  //           value: "1-3-4",
  //           visible: true
  //         }
  //       ],
  //       visible: true
  //     }
  //   ]
  // }]


  const top100Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
    { title: "The Dark Knight", year: 2008 },
    { title: "12 Angry Men", year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: "Pulp Fiction", year: 1994 },
    {
      title: "The Lord of the Rings: The Return of the King",
      year: 2003,
    },
    { title: "The Good, the Bad and the Ugly", year: 1966 },
    { title: "Fight Club", year: 1999 },
    {
      title: "The Lord of the Rings: The Fellowship of the Ring",
      year: 2001,
    },
    {
      title: "Star Wars: Episode V - The Empire Strikes Back",
      year: 1980,
    },
    { title: "Forrest Gump", year: 1994 },
    { title: "Inception", year: 2010 },
    {
      title: "The Lord of the Rings: The Two Towers",
      year: 2002,
    },
    { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { title: "Goodfellas", year: 1990 },
    { title: "The Matrix", year: 1999 },
    { title: "Seven Samurai", year: 1954 },
    {
      title: "Star Wars: Episode IV - A New Hope",
      year: 1977,
    },
    { title: "City of God", year: 2002 },
    { title: "Se7en", year: 1995 },
    { title: "The Silence of the Lambs", year: 1991 },
    { title: "It's a Wonderful Life", year: 1946 },
    { title: "Life Is Beautiful", year: 1997 },
    { title: "The Usual Suspects", year: 1995 },
    { title: "Léon: The Professional", year: 1994 },
    { title: "Spirited Away", year: 2001 },
    { title: "Saving Private Ryan", year: 1998 },
    { title: "Once Upon a Time in the West", year: 1968 },
    { title: "American History X", year: 1998 },
    { title: "Interstellar", year: 2014 },
  ];

  useEffect(() => {
    document.title = "Discount Claim";
    dispatch({ type: "RESET", payload: { options: {} } }); // reset
    // GetMaxClaimNo(Comp_Id, ProjectID, "DiscountClaim", (mData) => {
    //   setClaimNo(mData);
    // });
    SelectManufacturedByList(Comp_Id, ProjectID, (mManufacture) => {
      mManufacture.unshift({
        ManufacturedByID: 0,
        ManufacturedBy: "SELECT ALL",
      });
      setManufacturedBy_List(mManufacture);
    });
    // SelectCompanyGroups(Comp_Id, ProjectID, (mCG) => {
    //   mCG.unshift({ CGID: 0, CGName: "SELECT ALL" });
    //   setCompanyGroupList(mCG);
    // });
    // SelectActiveItems(Comp_Id, ProjectID, (mItems) => {
    //   mItems.unshift({ ItemID: 0, ItemDiscription: "SELECT ALL" });
    //   setItemsList(mItems);
    // });
    async function fetchData() {
      const response = await SelectClaimDropDowns(Comp_Id, ProjectID)
      if (response.status) {
        setData(response.data)
      }
    }
    fetchData()
  }, []);
  const SelectCompanyGroupAgainst_MultipleCompanys = (selectedOptionsManufacturer) => {
    if (selectedOptionsManufacturer.length > 0) {
      SelectCompanyGroupAgainstMultipleCompanys(
        Comp_Id,
        ProjectID,
        selectedOptionsManufacturer,
        (mCG) => {
          if (mCG.length > 0) {
            mCG.unshift({ CGID: 0, CGName: "SELECT ALL" });
            setCompanyGroupList(mCG);
          } else {
            setCompanyGroupList([]);
          }
        }
      );
    } else {
      setCompanyGroupList([]);
    }
  };
  const CallCGAPI = (ManufacturedByID) => {
    if (ManufacturedByID) {
      const RemaningSelectedManufactures =
        state.selectedOptionsManufacturer.filter(
          (option) => option.ManufacturedByID !== ManufacturedByID
        );
      const RemainingSelectedCG = state.selectedOptionsCG.find(x => x.ManufacturedByID !== ManufacturedByID)

      SelectCompanyGroupAgainst_MultipleCompanys(RemaningSelectedManufactures);
      dispatch({
        type: "SET_SELECTED_CG_OPTION",
        payload: { options: [] },
      });
      SelectItemsAgainstMultiple_CompanyGroup(RemainingSelectedCG);
      dispatch({
        type: "SET_SELECTED_ITEM_OPTION",
        payload: { options: [] },
      });
    } else {
      if (state.selectedOptionsManufacturer.length > 0) {
        SelectCompanyGroupAgainst_MultipleCompanys(
          state.selectedOptionsManufacturer
        );
      } else {
        setCompanyGroupList([]);
        dispatch({
          type: "SET_SELECTED_CG_OPTION",
          payload: { options: [] },
        });
      }
    }
  };
  const SelectItemsAgainstMultiple_CompanyGroup = (selectedOptionsCG) => {
    if (selectedOptionsCG.length > 0) {
      SelectItemsAgainstMultipleCompanyGroup(
        Comp_Id,
        ProjectID,
        selectedOptionsCG,
        (mItems) => {
          if (mItems.length > 0) {
            mItems.unshift({ ItemID: 0, ItemDiscription: "SELECT ALL" });
            setItemsList(mItems);
          } else {
            setItemsList([]);
          }
        }
      );
    } else {
      setItemsList([]);
    }
  };
  const CallItemAPI = (CGID) => {
    if (CGID) {
      const RemaningSelectedCompanyGroup = state.selectedOptionsCG.filter(
        (option) => option.CGID !== CGID
      );
      SelectItemsAgainstMultiple_CompanyGroup(RemaningSelectedCompanyGroup);
      dispatch({
        type: "SET_SELECTED_ITEM_OPTION",
        payload: { options: [] },
      });
    } else {
      if (state.selectedOptionsCG.length > 0) {
        SelectItemsAgainstMultiple_CompanyGroup(state.selectedOptionsCG);
      } else {
        setItemsList([]);
        dispatch({
          type: "SET_SELECTED_ITEM_OPTION",
          payload: { options: [] },
        });
      }
    }
  };
  const handleChangeCompany = (event, values) => {
    let _SelectAllRow = values.find((x) => x.ManufacturedByID === 0); // select all for manufacturer
    if (_SelectAllRow) {
      dispatch({
        type: "SET_SELECTED_COMPANY_OPTION",
        payload: { options: [_SelectAllRow] },
      });
    } else
      dispatch({
        type: "SET_SELECTED_COMPANY_OPTION",
        payload: { options: values },
      });
  };

  const handleChangeCG = (event, values) => {
    // setErrfeilds({ ...Errfeilds, Godown: ' ' })
    let _SelectAllRow = values.find((x) => x.CGID === 0); // select all for manufacturer
    if (_SelectAllRow) {
      dispatch({
        type: "SET_SELECTED_CG_OPTION",
        payload: { options: [_SelectAllRow] },
      });
    } else
      dispatch({
        type: "SET_SELECTED_CG_OPTION",
        payload: { options: values },
      });
  };
  const handleChangeItem = (event, values) => {
    // setErrfeilds({ ...Errfeilds, Godown: ' ' })
    let _SelectAllRow = values.find((x) => x.ItemID === 0); // select all for manufacturer
    if (_SelectAllRow) {
      dispatch({
        type: "SET_SELECTED_ITEM_OPTION",
        payload: { options: [_SelectAllRow] },
      });
    } else
      dispatch({
        type: "SET_SELECTED_ITEM_OPTION",
        payload: { options: values },
      });
  };
  const GetHandler = () => {
    let obj = {
      ManufacturedBy_List: state.selectedOptionsManufacturer,
      CG_List: state.selectedOptionsCG,
    };
    console.log(obj);
  };

  const removeCompanyOption = (ManufacturedByID) => {
    dispatch({ type: "REMOVE_COMPANY_OPTION", payload: { ManufacturedByID } });
    CallCGAPI(ManufacturedByID);
  };
  const removeCompanyGroupOption = (CGID) => {
    dispatch({ type: "REMOVE_COMPANY_GROUP_OPTION", payload: { CGID } });
    CallItemAPI(CGID);
  };
  const handleDropdownChange = (dd) => {
    console.log(dd)
  }
  return ReactDOM.createPortal(
    <>
      <h1>Discount Claim</h1>
      <Card>
        <Row className="row_margin_top row_margin_left row_margin_right p-4">
          <Col sm={12} lg={3} xl={3} md={3}>
            <TextField
              name="ClaimNo"
              label="Claim No"
              value={ClaimNo}
              inputProps={{ readOnly: true }}
              fullWidth
            />
          </Col>
          <Col sm={12} lg={3} xl={3} md={3}>
            <TextField
              value={ClaimDate}
              name="ClaimDate"
              label="Claim Date"
              type="date"
              onChange={(e) => setClaimDate(e.target.value)}
              fullWidth
            />
          </Col>
          <Col sm={12} lg={3} xl={3} md={3}>
          <TextField
            value={FromDate}
            name="FromDate"
            label="From Date"
            type="date"
            onChange={(e) => setFromDate(e.target.value)}
            fullWidth
          />
        </Col>
        <Col sm={12} lg={3} xl={3} md={3}>
          <TextField
            value={ToDate}
            name="ToDate"
            label="To Date"
            type="date"
            onChange={(e) => setToDate(e.target.value)}
            fullWidth
          />
        </Col>
        <Col sm={12} lg={12} xl={12} md={12} className="mt-2">
        <CheckTreePicker
          defaultExpandAll
          data={Data}
          style={{borderBottom:'1px solid #656C74',width:'100% '}}
          appearance='subtle'
          menuStyle={{zIndex: 9999}}
          onChange={value => handleDropdownChange(value)}
          // onOpen={() => {setTimeout(() => setDDLoader(false), 1000)  }}
          // renderMenu={menu => {
          //   if (DDLoader) {
          //     return (
          //       <div style={{ padding: 10, color: '#999', textAlign: 'center' }}>
          //         <SpinnerIcon spin /> Loading...
          //       </div>
          //     );
          //   }
          //   return menu;
          // }} 
          />
        {/* <button className="btn btn-primary" onClick={GetHandler}>
          GET Claim
        </button> */}
      </Col>
        <Col sm={12} lg={12} xl={12} md={12}>
        <TextField
          name="Remarks"
          label="Remarks"
          value={Remarks}
          onChange={(e) => setRemarks(e.target.value)}
          fullWidth
        />
      </Col>
      <Col sm={12} lg={2} xl={2} md={2} className="m-2">
      <FormControlLabel
        control={
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={IsClaimable}
            id="IsClaimable"
            label="Including Claimable"
            onChange={(e) => setIsClaimable(e.target.checked)}
            name="Access"
            color="primary"
          />
        }
        label={"Including Non Claimable"}
        style={{
          padding: "0px",
          marginTop: "5%",
        }}
      />
    </Col>
    <Col sm={12} lg={1} xl={1} md={1} className="m-2">
      <FormControlLabel
        control={
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            checked={IsOurShare}
            id="IsOurShare"
            label="IsOurShare"
            onChange={(e) => setIsOurShare(e.target.checked)}
            name="Access"
            color="primary"
          />
        }
        label={"IsOurShare"}
        style={{
          padding: "0px",
          marginTop: "10%",
        }}
      />
    </Col>
    <Col sm={12} lg={1} xl={1} md={1} className="m-2">
    <FormControlLabel
      control={
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          checked={IsTPClaimable}
          id="IsTPClaimable"
          label="Calculate on TP"
          onChange={(e) => setIsTPClaimable(e.target.checked)}
          name="Access"
          color="primary"
        />
      }
      label={"Calculate on TP"}
      style={{
        padding: "0px",
        marginTop: "5%",
      }}
    />
  </Col>
  <Col sm={12} lg={1} xl={1} md={1} className="m-2">
  <FormControlLabel
    control={
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        checked={IsDPClaimable}
        id="IsDPClaimable"
        label="Calculate on DP"
        onChange={(e) => setIsDPClaimable(e.target.checked)}
        name="Access"
        color="primary"
      />
    }
    label={"Calculate on DP"}
    style={{
      padding: "0px",
      marginTop: "5%",
    }}
  />
</Col>
    <Col sm={12} lg={2} xl={2} md={2} className="m-2">
      <FormControl variant="standard">
        <Input
          id="standard-adornment-weight"
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          aria-describedby="standard-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
        />
        <FormHelperText id="standard-weight-helper-text">
          Our Share
        </FormHelperText>
      </FormControl>
    </Col>
    <Col sm={12} lg={2} xl={2} md={2} className="m-2">
          <button className="btn btn-primary mt-2">GET Claim</button>
    </Col>
        </Row>
       
        {/*<Row className="row_margin_top row_margin_left row_margin_right p-4">
         
          <Col sm={12} lg={3} xl={3} md={3}>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={ManufacturedBy_List}
              disableCloseOnSelect
              getOptionLabel={(option) => option.ManufacturedBy}
              value={state.selectedOptionsManufacturer}
              onChange={handleChangeCompany}
              onBlur={(e) => CallCGAPI()}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected}
                    />
                    {option.ManufacturedBy}
                  </li>
                );
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.ManufacturedBy}
                    label={option.ManufacturedBy}
                    {...getTagProps({ index })}
                    onDelete={() =>
                      removeCompanyOption(option.ManufacturedByID)
                    }
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Company"
                  placeholder="Company"
                />
              )}
            />
          </Col>
          <Col sm={12} lg={3} xl={3} md={3}>
           <Autocomplete
              multiple
              id="checkboxes-tags-demo-1"
              options={CompanyGroupList}
              disableCloseOnSelect
              getOptionLabel={(option) => option.CGName}
              value={state.selectedOptionsCG}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                  />
                  {option.CGName}
                </li>
              )}
              onChange={handleChangeCG}
              onBlur={(e) => CallItemAPI()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Company Groups"
                  placeholder="Company Groups"
                />
              )}
            />
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={CompanyGroupList}
              disableCloseOnSelect
              getOptionLabel={(option) => option.CGName}
              value={state.selectedOptionsCG}
              onChange={handleChangeCG}
              onBlur={(e) => CallItemAPI()}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected}
                    />
                    {option.CGName}
                  </li>
                );
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.CGName}
                    label={option.CGName}
                    {...getTagProps({ index })}
                    onDelete={() => removeCompanyGroupOption(option.CGID)}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Company Groups"
                  placeholder="Company Groups"
                />
              )}
            />
          </Col>
          <Col sm={12} lg={2} xl={2} md={2}>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo-2"
              options={ItemsList}
              disableCloseOnSelect
              getOptionLabel={(option) => option.ItemDiscription}
              value={state.SelectOptionsItem}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={selected}
                  />
                  {option.ItemDiscription}
                </li>
              )}
              onChange={handleChangeItem}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Items"
                  placeholder="Select Item"
                />
              )}
            />
          </Col>
        </Row>*/}
       
        <Row className="row_margin_left row_margin_right p-4">
          <DiscountClaimTable />
        </Row>
      </Card>
    </>,
    document.getElementById("PageContents")
  );
};
const mapStateToProps = (state) => ({
  Comp_Id:
    state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  ProjectID:
    state.Global_Projects && state.Global_Projects.GlobalProjects.Project_ID,
});
export default connect(mapStateToProps)(DiscountClaim);
