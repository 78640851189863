import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SaveMailData = (MailData,callback) =>{
    axios.post(`${domain}/AutoMail/SaveMailData`,{
        MailData: MailData
    })
      .then(response=>{
          toast.configure()
            toast.info(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          
          callback({err :false})
      })
      .catch(err=>{
        callback({err: true, message: err.message})

      })
  }
  export const SelectAutoMail =(Comp_Id,ProjectID,Callback) =>{
    
    axios.get(`${domain}/AutoMail/SelectAutoMail/${Comp_Id}/${ProjectID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const SelectAutoMailWRTMailDataID =(MailDataID,Callback) =>{
    axios.get(`${domain}/AutoMail/SelectAutoMailWRTMailDataID/${MailDataID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }