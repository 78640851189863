import React, { useState, useEffect } from 'react'
import { Modal, Card, Row, Col, Button,Spinner } from "react-bootstrap"
import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { Save, Cancel } from "@material-ui/icons"
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CheckTreePicker from 'rsuite/CheckTreePicker';
import { connect } from "react-redux"
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import { SelectCompanyGroupAgainstManufacturer  } from '../../Services/CompanyGroupAPI'
import { SelectProductGroupAgainstManufacturer } from '../../Services/ProductGroupAPI'
import { SelectAGM,SaveAGM  } from '../../Services/AGM_API'
import { SelectManufacturedByList  } from '../../Services/manufactureAPI'
import { SelectBranchUsers, SelectSecurityGroupsList, SelectSecurityGroupsListForManager } from '../../Services/SecurityAPI'
import { CreateManagerPortal, SelectMCPDD, SelectUserForEdit } from '../../Services/ClaimAPI'
import Swal from 'sweetalert2'
function AGM_Modal({ show, onHide, UserID, is_add_new, show_screen,Comp_Id,ProjectID }) {

   
    const [User_name, setUser_name] = useState('')
    const [User_Login, setUser_Login] = useState('')
    const [User_password, setUser_password] = useState('')
    const [isPasswordChanged, setIsPasswordChanged] = useState(false)
    const [GroupList, setGroupList] = useState([])
    const [SelectedGroup, setSelectedGroup] = useState(null)
    const [Email, setEmail] = useState('')
    const [PhoneNo, setPhoneNo] = useState('')
    const [Address, setAddress] = useState('')
    const [IsActive, setIsActive] = useState(true)
    const [DDLoader, setDDLoader] = useState(true)
    
   const [MCPData, setMCPData] = useState([])
   const [SelectedMCPValue, setSelectedMCPValue] = useState([])

   let CGOBJ = [];
    let PGOBJ = [];
    let User_Manufacturer = []
    const [Error, setError] = useState({
        UserName:'',
        LoginID: '',
        Password:'',
        Group:'',
        MCP: ''
    })
    const [btn_edit, setBtn_edit] = useState(true)
    const [OtherAccess, setOtherAccess] = useState(null)
    const [btn_Disabled, setBtn_Disabled] = useState(false)
    useEffect(() => {

        document.title = "Distribution / Assign Group"
        SelectSecurityGroupList()
        SelectMCP_DD()
        if(show){
            if(is_add_new === true)
            {    
                setError({UserName: '', Password:'', LoginID: '',User:'', Group:'', MCP:''})
                document.getElementById("User_name").focus()
            }
            else if(is_add_new === false)
            {
                setBtn_edit(show_screen.edit) 
                if(UserID){
                    UpdateUser()
                  
                }
            }
        }
    }, [show,is_add_new, UserID])
    const UpdateUser = async() =>{
        const res = await SelectUserForEdit(UserID)
        if(res.status){
            const _data = res.data
            setUser_name(_data[0].User_name)
            setUser_Login(_data[0].User_Login)
            setUser_password(_data[0].User_password)
            setPhoneNo(_data[0].PhoneNo)
            setEmail(_data[0].Email)
            setAddress(_data[0].Address)
            setIsActive(_data[0].Active)
            GroupList.map(x=>{
                if(x.Group_Id === _data[0].Group_Id){
                    setSelectedGroup(x)
                }
            })
           const  selectedVal = _data[0].SelectedValueForDD
             const SelectArray = selectedVal.split(',')
             setSelectedMCPValue(SelectArray)
            // setSelectedArray(selectedVal)

        }else{

        }
    }
    
    const SelectSecurityGroupList = () =>{
        SelectSecurityGroupsListForManager((mData)=>{
         setGroupList(mData)
        })
    }
    const SelectMCP_DD = async() =>{
       const mRes = await SelectMCPDD(Comp_Id,ProjectID)
       
       if(mRes.status){
        setMCPData(mRes.data)
       }else{
            Swal.fire({
                title:"Error",
                icon:'error',
                text:'Something went wrong'
            })
       }
    }
    
   
    const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }

    const validation = () =>{
         if(!User_name){
            setError({ Group:'', UserName:'User Name is Required', Password:'',LoginID:'',MCP:''})
            document.getElementById("User_name").focus()
            return false
        }
         if(!User_Login){
            setError({ Group:'', UserName:'', Password:'',LoginID:'Login id is required',MCP:''})
            document.getElementById("User_Login").focus()
            return false
        }
         if(!User_password){
            setError({ Group:'', UserName:'', Password:'Password is required',LoginID:'',MCP:''})
            document.getElementById("User_password").focus()
            return false
        }
       
         if(!SelectedGroup){
            setError({Group:'Group is Required', UserName:'', Password:'',LoginID:'',MCP:''})
            document.getElementById("Group").focus()
            return false
        }
        if(!SelectedMCPValue){
            setError({Group:'', UserName:'', Password:'',LoginID:'',MCP:'Company is Required'})
            document.getElementById("MCP").focus()
            return false
        }
        setError({Group:'', UserName:'', Password:'',LoginID:'',MCP:''})
            return true
    }

    const save  = async () =>{
        let CGArray = []
        let PGArray = []
        const Selected_Val = SelectedMCPValue.join(',')
        if(validation())
        {   
         if(SelectedMCPValue){
            for (let index = 0; index < SelectedMCPValue.length; index++) {
                const value = SelectedMCPValue[index];
                const mVal = value.split('-')
                const table = mVal[0]
                const IDLabel = mVal[1]
                const ID = mVal[2]
                const LastID = mVal[3]
                if(table === "manufacturer"){
                    await new Promise((resolve)=>{
                        SelectCompanyGroupAgainstManufacturer(Comp_Id, ProjectID, ID,(mCG)=>{
                            if(mCG.length > 0){
                                CGArray.push(mCG)
                            }
                            resolve()
                        })
                    })
                    await new Promise((resolve)=>{
                        SelectProductGroupAgainstManufacturer(Comp_Id, ProjectID, ID,(mPG)=>{
                            if(mPG.length > 0){
                                PGArray.push(mPG)
                            }
                            resolve()
                        })
                    })
                } else if(table === "companyGroups" && IDLabel === "ManufacturedByID") {
                    await new Promise((resolve)=>{
                        SelectCompanyGroupAgainstManufacturer(Comp_Id, ProjectID, ID,(mCG)=>{
                            if(mCG.length > 0){
                                CGArray.push(mCG)
                            }
                            resolve()
                        })
                    })
                }else if(table === "productGroups" && IDLabel === "ManufacturedByID"){
                    await new Promise((resolve)=>{
                        SelectProductGroupAgainstManufacturer(Comp_Id, ProjectID, ID,(mPG)=>{
                            if(mPG.length > 0){
                                PGArray.push(mPG)
                            }
                            resolve()
                        })
                    })
                } else if(table === "companyGroups" && IDLabel === "CGID"){
                    CGArray.push({CGID: ID, ManufacturedByID: LastID})
                }else if(table === "productGroups" && IDLabel === "PGID"){
                    PGArray.push({PGID: ID, ManufacturedByID: LastID})
                }
                
            }
         }
         for (let i = 0; i < CGArray.length; i++) {
            const innerArray = CGArray[i];
            const CGObjArray = [];
            if(Array.isArray(innerArray)){
                for (let j = 0; j < innerArray.length; j++) {
                    const cgData = innerArray[j];
                    const mCGObj = {
                      UCID: 0,
                      UserID: 0,
                      ManufacturedByID: parseInt(cgData.ManufacturedByID),
                      CGID: parseInt(cgData.CGID),
                      Comp_Id: Comp_Id,
                      ProjectID: ProjectID,
                    };
                
                    CGObjArray.push(mCGObj);
                  }
            } else{
                const mCGObj = {
                    UCID: 0,
                    UserID: 0,
                    ManufacturedByID: parseInt(innerArray.ManufacturedByID),
                    CGID: parseInt(innerArray.CGID),
                    Comp_Id: Comp_Id,
                    ProjectID: ProjectID,
                  };
              
                  CGObjArray.push(mCGObj);
            }
           
            CGOBJ = [...CGOBJ,...CGObjArray]
          } 
          for (let i = 0; i < PGArray.length; i++) {
            const innerArray = PGArray[i];
            const PGObjArray = [];
            if(Array.isArray(innerArray)){
            for (let j = 0; j < innerArray.length; j++) {
              const pgData = innerArray[j];
              const mPGObj = {
                UPID: 0,
                UserID: 0,
                ManufacturedByID: parseInt(pgData.ManufacturedByID),
                PGID: parseInt(pgData.PGID),
                Comp_Id: Comp_Id,
                ProjectID: ProjectID,
              };
          
              PGObjArray.push(mPGObj);
            }
        } else{
            const mPGObj = {
                UPID: 0,
                UserID: 0,
                ManufacturedByID: parseInt(innerArray.ManufacturedByID),
                PGID: parseInt(innerArray.PGID),
                Comp_Id: Comp_Id,
                ProjectID: ProjectID,
              };
          
              PGObjArray.push(mPGObj);
        }
            PGOBJ = [...PGOBJ,...PGObjArray];
          }
        const MergData = [...CGOBJ, ...PGOBJ]
        const TotalManufacturedIDs = MergData.map(val => val.ManufacturedByID)
        const ManufacturedIDsArray = [...new Set(TotalManufacturedIDs)]
       
            for(let i = 0; i < ManufacturedIDsArray.length; i++) {
                 const UserManufacturer = {
                   UserManufacturerID: 0,
                   UserID:0,
                   ManufacturedByID: ManufacturedIDsArray[i],
                   Comp_Id: Comp_Id,
                   ProjectID : ProjectID
        }
            User_Manufacturer.push(UserManufacturer)
            }
       
            const UserObj = {
                User_id: UserID,
                User_name: User_name,
                User_Login: User_Login,
                User_password: User_password,
                Active: IsActive,
                UserType : 'Manger Portal',
                Group_Id: SelectedGroup && SelectedGroup.Group_Id,
                hms_user_id: 0,
                PhoneNo: PhoneNo,
                Email: Email,
                UserPic: '',
                Address: Address,
                ManufacturedByID: 0,
                PGID: 0,
                SelectedValueForDD:Selected_Val,
                IsPasswordChanged: isPasswordChanged

            }
            const UserBranches = {
                User_Id: 0,
                Branch_Id: Comp_Id
            }
            const UserProjects ={
                User_Id: 0,
                Project_ID: ProjectID
            }
            const UserYear = {
                User_Id: 0,
                Branch_Id: Comp_Id,
                Year_Id: 5
            }
            setBtn_Disabled(true)
            const Res = await CreateManagerPortal(CGOBJ,PGOBJ, User_Manufacturer, UserObj, UserBranches, UserProjects,UserYear)
            setBtn_Disabled(false)
            if(!Res.isError){
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    text:'Portal Created Successfully'
                })
                clearFields()
                document.getElementById('btnCancle').click()
            }else{
                Swal.fire({
                    title: 'Error',
                    icon: 'error',
                    text:'Failed to create portal'
                })
            }
        }
        
       
    }
  
      
      
    const clearFields = () => {
        setUser_name('')
        setUser_Login('')
        setUser_password('')
        setSelectedMCPValue([])
        setSelectedGroup(null)
        setPhoneNo('')
        setEmail('')
        setAddress('')
        CGOBJ= []
        PGOBJ = []
        User_Manufacturer = []
        onHide()
    }
const Role_Data = [
    {value:'NSM',title:'NSM'},
    {value:'ZSM',title:'ZSM'},
    {value:'RSM',title:'RSM'},
    {value:'ASM',title:'ASM'},
    {value:'AFM',title:'AFM'},
    {value:'SSPO',title:'SSPO'},
    {value:'SPO',title:'SPO'},
]
//  const TestArray = [{
//     label: "Factors",
//     value: "47",
//     children: [
//       {
//         label: "Engineer",
//         value: "1-1",
//         children: [
//           {
//             label: "Loyce",
//             value: "34",
//             visible: true
//           },
         
//         ],
//         visible: true
//       },
     
//     ]
//   }]
  const handleDropdownChange = (dd) => {
    console.log(dd)
  }
    return (
        <>
            <Modal show={show} onHide={onHide} size="xl"  backdrop="static"  centered>
                <Modal.Header closeButton className="header_title " >
                    <Modal.Title > Create New User </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                   
                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                    <Col lg={4} xl={4} md={4} >
                    <TextField
                        name="User_name"
                        id="User_name"
                        label="User Name"
                        value={User_name}
                        onChange={(e)=> setUser_name(e.target.value)}
                        fullWidth
                    />
                    <span className='text-danger'>{Error.UserName}</span>
                </Col>
                <Col lg={4} xl={4} md={4} >
                <TextField
                    name="User_Login"
                    id="User_Login"
                    label="Login ID"
                    value={User_Login}
                    onChange={(e)=> setUser_Login(e.target.value)}
                    fullWidth
                />
                <span className='text-danger'>{Error.LoginID}</span>
            </Col>
            <Col lg={4} xl={4} md={4} >
            <TextField
                name="User_password"
                id="User_password"
                label="Password"
                type='password'
                value={User_password}
                onChange={(e)=> {
                    if(e.target.value){
                        setIsPasswordChanged(true)
                        setUser_password(e.target.value)
                    }else{
                        setIsPasswordChanged(false)
                    }
                  
                }}
                fullWidth
            />
            <span className='text-danger'>{Error.Password}</span>
        </Col>
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                    <Col sm={12} md={12} lg={12} xl={12}>
                    <CheckTreePicker
                    id='MCP'
                    data={MCPData}
                    appearance='subtle'
                    searchable={true}
                    style={{borderBottom:'1px solid #656C74',width:'100% '}}
                    menuStyle={{zIndex: 9999}}
                    value={SelectedMCPValue}
                    onChange={value => setSelectedMCPValue(value)}
                    onOpen={() => {setTimeout(() => setDDLoader(false), 1000)  }}
                    renderMenu={menu => {
                      if (DDLoader) {
                        return (
                          <div style={{ padding: 10, color: '#999', textAlign: 'center' }}>
                            <SpinnerIcon spin /> Loading...
                          </div>
                        );
                      }
                      return menu;
                    }} 
                    />
                    <span className='text-danger'>{Error.MCP}</span>
                    </Col>
                  
                    </Row>
                    {/*<Row className=" row_margin_top row_margin_left row_margin_right" >
                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="ManufacturedBy"
                            id="ManufacturedBy"
                            options={ManufactuererBy_Data}
                            getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""}
                            value={Selected_ManufactuererBy_Data}
                            onChange={(e, value) => {

                                if (value) {
                                    setSelected_ManufactuererBy_Data(value)
                                    SelectCompanyGroupAgainstManufacturer(Comp_Id,ProjectID,value.ManufacturedByID,(mData)=>{
                                        setCompanyGroup_Data(mData)
                                    })
                                }
                                else {
                                    setSelected_ManufactuererBy_Data(null)
                                    setCompanyGroup_Data([])
                                    setSelectedCompanyGroup(null)
                                    setProductGroup_Data([])
                                    setSelectedProductGroup(null)
                                }

                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="ManufacturedBy" id="ManufacturedBy" label="Manufacturerd By"
                                />}

                        />
                        <span style={{color: "red"}} >{Error.ManufacturedBy}</span>
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="CGID"
                            id="CGID"
                            options={CompanyGroup_Data}
                            getOptionLabel={(option) => option.CGName ? option.CGName : ""}
                            value={SelectedCompanyGroup}
                            onChange={(e, value) => {

                                if (value) {
                                    setSelectedCompanyGroup(value)
                                    SelectProductGroupAgainstManufacturer(Comp_Id,ProjectID,Selected_ManufactuererBy_Data.ManufacturedByID,(mData)=>{
                                        setProductGroup_Data(mData)
                                    })
                                }
                                else {
                                    setSelectedCompanyGroup(null)
                                    setProductGroup_Data([])
                                    setSelectedProductGroup(null)
                                }

                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="CGID" id="CGID" label="Company Group"
                                />}

                        />
                        <span style={{color: "red"}} >{Error.CG}</span>
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="PGID"
                            id="PGID"
                            options={ProductGroup_Data}
                            getOptionLabel={(option) => option.PGName ? option.PGName : ""}
                            value={SelectedProductGroup}
                            onChange={(e, value) => {

                                if (value) {
                                    setSelectedProductGroup(value)
                                }
                                else {
                                    setSelectedProductGroup(null)
                                }

                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="PGID" id="PGID" label="Product Group"
                                />}

                        />
                        <span style={{color: "red"}} >{Error.PG}</span>
                    </Col>
                   
                </Row>*/}
                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                        name="Group"
                        id="Group"
                        options={GroupList}
                        getOptionLabel={(option) => option.Description ? option.Description : ""}
                        value={SelectedGroup}
                        onChange={(e, value) => {
                            if (value) {
                                setSelectedGroup(value)
                            }
                            else {
                                setSelectedGroup(null)
                            }

                        }}
                        renderInput={(params) =>
                            <TextField {...params} name="Group" id="Group" label="Groups"
                            />}
                        />
                        <span className='text-danger'>{Error.Group}</span>
                </Col>
                <Col lg={4} xl={4} md={4} >
                <TextField
                    name="PhoneNo"
                    id="PhoneNo"
                    label="Phone No"
                    value={PhoneNo}
                    onChange = {(e)=> setPhoneNo(e.target.value)}
                    fullWidth
                />
            </Col>
            <Col lg={4} xl={4} md={4} >
            <TextField
                name="Email"
                id="Email"
                label="Email"
                value={Email}
                onChange = {(e)=> setEmail(e.target.value)}
                fullWidth
            />
        </Col>
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                    <Col lg={8} xl={8} md={8} >
                        <TextField
                            name="Address"
                            id="Address"
                            label="Address"
                            value={Address}
                            onChange = {(e)=> setAddress(e.target.value)}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                    <FormControlLabel
                            control={<Switch 
                                defaultChecked={IsActive}
                                onChange={(e)=> setIsActive(e.target.checked)}
                                />}
                            label="Publish"
                            sx={{mt: 3}}
                      />
                    </Col>
                    </Row>
                 
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                {btn_edit && (<Button variant="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={save} disabled={btn_Disabled}>                     
                                {
                             btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                                } </Button>)}
                                <Button id="btnCancle" variant="btn btn-primary rounded-pill" onClick={clearFields} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></Button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    show_screen: state.SCREEN,    
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,

})

export default connect(mapStateToProps)(AGM_Modal)
