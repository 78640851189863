import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectcustomersList =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/customers/SelectcustomersList/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectDSRData =(Comp_Id,ProjectID,FromDate,ToDate,ManufacturerID,Callback) =>{
  axios.get(`${domain}/customers/SelectDSRData/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${ManufacturerID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectDSRCustomerDataForSafron =(ManufacturedByID, Comp_Id, ProjectID, FromDate,ToDate,Callback) =>{
  axios.get(`${domain}/customers/SelectDSRCustomerDataForSafron/${ManufacturedByID}/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectDSRCustomerDataForGlacxy =(Comp_Id,ProjectID,FromDate,ToDate,ManufacturerID,Callback) =>{
  axios.get(`${domain}/customers/SelectDSRCustomerDataForGlacxy/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${ManufacturerID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectDSRDataForGlobalPharma =(Comp_Id,ProjectID,FromDate,ToDate,ManufacturerID,Callback) =>{
  axios.get(`${domain}/customers/SelectDSRDataForGlobalPharma/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${ManufacturerID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectDSRDataForShigan =(Comp_Id,ProjectID,FromDate,ToDate,ManufacturerID,Callback) =>{
  axios.get(`${domain}/customers/SelectDSRDataForShigan/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${ManufacturerID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectCustomers =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/customers/SelectCustomers/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectCustomersForExcel =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/customers/SelectCustomersForExcel/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const GetMaxCustomerCode =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/customers/GetMaxCustomerCode/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectCustomerForFlat =(Comp_Id,ProjectID,CustomerID,Callback) =>{
  axios.get(`${domain}/customers/SelectCustomerForFlat/${Comp_Id}/${ProjectID}/${CustomerID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectCustomerForDis =(Comp_Id,ProjectID,CustomerID,Callback) =>{
  axios.get(`${domain}/customers/SelectCustomerForDis/${Comp_Id}/${ProjectID}/${CustomerID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectCustomerForBonus =(Comp_Id,ProjectID,CustomerID,Callback) =>{
  axios.get(`${domain}/customers/SelectCustomerForBonus/${Comp_Id}/${ProjectID}/${CustomerID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectCustomerForDisCompanyAndGroup =(Comp_Id,ProjectID,CustomerID,Callback) =>{
  axios.get(`${domain}/customers/SelectCustomerForDisCompanyAndGroup/${Comp_Id}/${ProjectID}/${CustomerID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectCustomer =(Comp_Id,ProjectID,CustomerID,Callback) =>{
  axios.get(`${domain}/customers/SelectCustomer/${Comp_Id}/${ProjectID}/${CustomerID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SaveCustomer = (CustomerData,isAddNew,callback) =>{
  axios.post(`${domain}/customers/SaveCustomer`,{
    CustomerData,
     isAddNew
  })
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback()
      })
      .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
}



export const SelectCustomers_List =(Comp_Id,ProjectID,CityID,Callback) =>{
  axios.get(`${domain}/customers/SelectCustomersList/${Comp_Id}/${ProjectID}/${CityID}`)
       .then(response=>{
           Callback(response.data.CustomerData)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}