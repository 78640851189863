import React from 'react'
import ReactDOM from 'react-dom'
import { Row, Col, Card, Spinner, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Save, Cancel } from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';

//  import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
import { connect } from 'react-redux'
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import { Selectbank, SaveBank } from '../../Services/bankAPI'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { GetCurrentDate } from '../../Services/Common';
import Loader from '../Loader'

export class Bank extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            Bank: [],
            DeletedBank: [],
            btn_Add: true,
            btn_Edit: true,
            btn_Delete: true,
            Others: null,
            btn_Disabled: false,
            IsLoader: false,
        }
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
        this.validationRules = { required: true };
        this.bg_Ref = React.createRef()
        this.grid = null
        this.Comp_Id = this.props.Comp_Id
        this.ProjectID = this.props.Project_ID
        this.BGValidation = {
            required: [this.customBGValidation, 'Bank must be Entered'],
        };
    }
    sortingOptions = {
        columns: [{ field: 'SrNo', direction: 'Ascending' }]
    };

    customBGValidation(args) {
        return getValue('value', args) != ''
    }


    componentDidMount() {
        document.title = " Distribution / Bank"
        SelectGroupScreenAccess(this.props.Group_Id, 13, 87, (data) => {

            this.setState({
                btn_Add: parseInt(data.AddNew) === 0 ? false : true,
                btn_Edit: parseInt(data.Edit) === 0 ? false : true,
                btn_Delete: parseInt(data.Del) === 0 ? false : true,
                Others: data.OtherAccess
            })


        })

        this.Select_bank();
    }
    Select_bank = () => {
        this.showLoader()
        Selectbank(this.Comp_Id, this.ProjectID, (mBank) => {
            this.hideLoader()
            mBank.map((value, index) => {
                mBank[index].SrNo = index + 1
            })
            if (mBank.length > 0)
                this.setState({ Bank: mBank })
        })
    }


    BankName = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="text"
            id="BankName"
            className="textbox"
            name='BankName'
            onInput={this.toInputUppercase}
            defaultValue={rowValue.BankName}
        />)
    }




    SaveHandler = () => {
        if (this.state.Bank.length > 0 || this.state.DeletedBank.length > 0) {
            for (let i = 0; i < this.state.Bank.length; i++) {
                if (this.state.Bank[i].BankName === undefined || this.state.Bank[i].BankName === '') {
                    Swal.fire({
                        icon: 'error',
                        text: `Bank Name at Sr. No. ${this.state.Bank[i].SrNo} is required`
                    })
                    return
                }

            }
            this.setState({ btn_Disabled: true })
            SaveBank(this.state.Bank, this.state.DeletedBank, () => {
                this.setState({ btn_Disabled: false })
                this.Select_bank();
            })
        }
        else {
            Swal.fire({
                icon: 'info',
                text: `Bank Must be Entered...`,
            })
            return
        }
    }


    beforeRowInsert = (rowValue) => {

        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.SrNo) {
                    if (rowValue.requestType != "cancel") {
                        rowValue.data.Comp_Id = this.Comp_Id
                        rowValue.data.ProjectID = this.ProjectID
                        rowValue.data.action = rowValue.action
                    }
                }
                else {
                    rowValue.data.SrNo = this.state.Bank.length + 1

                }
            }
        }

    }
    afterRowInsert = (rowValue) => {

        if (rowValue.requestType === 'delete') {
            if (rowValue.data[0].BankID) {
                this.setState({ DeletedBank: this.state.DeletedBank.concat(rowValue.data) })

            }
        }
    }

    toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    showLoader = () => {
        this.setState({ IsLoader: true })

    }
    hideLoader = () => {
        this.setState({ IsLoader: false })
    }
    render() {
        if (this.grid)
            this.grid.autoFitColumns(['SrNo', 'BankName']);
        enableRipple(true);
        return ReactDOM.createPortal(
            <>
                <div className="block-header">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <h2>Bank
                            </h2>
                        </div>
                    </div>
                </div>
                <Card>
                    <Row className="row_margin_top row_margin_left row_margin_right pt-4" style={{ width: '80%', marginLeft: '10%' }} >
                        <div style={{ display: "flex", width: "100%", height: "100%", overflow: "hidden" }}>
                            <div style={{ flex: "1", width: "100%", height: "100%" }}>
                                <GridComponent dataSource={this.state.Bank} actionBegin={this.beforeRowInsert} ref={g => this.grid = g} actionComplete={this.afterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: this.state.btn_Edit, allowDeleting: this.state.btn_Delete, allowAdding: this.state.btn_Add, newRowPosition: 'Bottom' }} sortSettings={this.sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={this.toolbarOptions}>
                                    <ColumnsDirective>
                                        <ColumnDirective width='70' field='SrNo' headerText='SrNo #' Format="{0:n}" allowEditing={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                        <ColumnDirective width='250' field='BankName' headerText='Bank Name' editTemplate={this.BankName} textAlign='Left' />
                                        {/* <ColumnDirective width='200' field='b_no' headerText='Bed No'  editTemplate={this.b_no} textAlign='Left' /> */}


                                        <ColumnDirective field='BankID' headerText='BankID' visible={false}></ColumnDirective>
                                        <ColumnDirective field='Comp_Id' headerText='Comp_Id' hideAtMedia={true} ></ColumnDirective>
                                        <ColumnDirective field='ProjectID' headerText='ProjectID' hideAtMedia={true} ></ColumnDirective>
                                    </ColumnsDirective>
                                    <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
                                </GridComponent>
                            </div>
                        </div>
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right row_margin_bottom" >
                        <Col style={{ textAlign: "right", marginTop: "1%" }} >
                            <button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={this.SaveHandler} disabled={this.state.btn_Disabled}>                          {
                                this.state.btn_Disabled ?
                                    (<><Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                            } </button>
                            <Button variant="btn btn-primary rounded-pill" onClick={e =>{
                               
                                const ws = XLSX.utils.json_to_sheet(this.state.Bank);
                                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                                const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
                                FileSaver.saveAs(data, `Banks - ${GetCurrentDate()}`  +  ".xlsx");
                              }} style={{border: "none"}} > <CloudDownloadIcon />  Export to Excel</Button> 
                            <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{ border: "none" }} to={'#'} > <Cancel /> <u>Close</u></Link>
                        </Col>
                    </Row>
                    <Loader
                        show={this.state.IsLoader}
                        hide={this.hideLoader}
                    />
                </Card>

            </>, document.getElementById('PageContents')
        )
    }

}

const mapStateToProps = state => ({
    Comp_Id: state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Group_Id: state.Login_User.User_Data && state.Login_User.User_Data.Group_Id,
    Project_ID: state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID
})

export default connect(mapStateToProps)(Bank)