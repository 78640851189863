import {domain} from "./Common";
import axios from "axios"
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2"

toast.configure()

export const SelectExpencesList = (CompID,ProjectID,cb) =>{

    axios.get(`${domain}/Expense/SelectExpencesList/${CompID}/${ProjectID}`)
         .then(response =>{
            cb(response.data.Data)
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
}

export const SelectExpense = (CompID, ProjectID,e_id,cb) =>{

   axios.get(`${domain}/Expense/SelectExpense/${CompID}/${ProjectID}/${e_id}`)
        .then(response =>{
           cb(response.data.Data[0])
        })
        .catch(err =>{
           Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: `${err}`,
             })
        })
}

export const SaveExpences = (Expences, Comp_Id,ProjectID, IsAddNew,cb) =>{

   axios.post(`${domain}/Expense/SaveExpences`, 
   {Expences, Comp_Id,ProjectID, IsAddNew}
   )
        .then(response =>{
           
         toast.info(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
           cb()
        })
        .catch(err =>{
           Swal.fire({
               icon: 'error',
               title: 'Oops...',
               text: `${err}`,
             })
        })
}