import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const rptDailyActivity =(Comp_Id,ProjectID,FromDate,ToDate,Callback) =>{
  axios.get(`${domain}/PL/rptDailyActivity/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const rptPL =(Comp_Id,ProjectID,FromDate,ToDate,Callback) =>{
    axios.get(`${domain}/PL/rptPL/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const DirectExpenseForPL =(Comp_Id,ProjectID,FromDate,ToDate,Callback) =>{

    axios.get(`${domain}/PL/DirectExpenseForPL/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const OperativeExpenseForPL =(Comp_Id,ProjectID,FromDate,ToDate,Callback) =>{
 
    axios.get(`${domain}/PL/OperativeExpenseForPL/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }

  export const OtherIncomeForPL =(Comp_Id,ProjectID,FromDate,ToDate,Callback) =>{
 
    axios.get(`${domain}/PL/OtherIncomeForPL/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }

  export const OtherExpenseForPL =(Comp_Id,ProjectID,FromDate,ToDate,Callback) =>{
 
    axios.get(`${domain}/PL/OtherExpenseForPL/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }