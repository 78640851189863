import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'


export const rptCurrentStock =(Comp_Id,ProjectID,ManufacturedByID,Callback) =>{
  axios.get(`${domain}/Distribution/rptCurrentStock/${Comp_Id}/${ProjectID}/${ManufacturedByID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const rptMonthlySale =(Comp_Id,ProjectID,PGID,ManufacturedByID,FromDate,ToDate,CustomerID,Callback) =>{
  axios.get(`${domain}/Distribution/rptMonthlySale/${Comp_Id}/${ProjectID}/${PGID}/${ManufacturedByID}/${FromDate}/${ToDate}/${CustomerID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const getPG =(Comp_Id,ProjectID,ManufacturedByID,PGID,Callback) =>{
  axios.get(`${domain}/Distribution/getPG/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${PGID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const getItemsWRTPG =(Comp_Id,ProjectID,PGID,Callback) =>{
  axios.get(`${domain}/Distribution/getItemsWRTPG/${Comp_Id}/${ProjectID}/${PGID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const getCitySaleQty =(Comp_Id,ProjectID,ItemID,CityID,FromDate,ToDate,Callback) =>{
  axios.get(`${domain}/Distribution/getCitySaleQty/${Comp_Id}/${ProjectID}/${ItemID}/${CityID}/${FromDate}/${ToDate}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

  export const rptBatchwiseSale =(Comp_Id,ProjectID,ManufacturedByID,CityID,AreaID,ItemID,BatchNo,Callback) =>{
    axios.get(`${domain}/Distribution/rptBatchwiseSale/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${CityID}/${AreaID}/${ItemID}/${BatchNo}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const rptNearExpiryStock =(Comp_Id,ProjectID,ManufacturedByID,FromDate,ToDate,Callback) =>{
    axios.get(`${domain}/Distribution/rptNearExpiryStock/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const rptProductPurchaseLedger =(Comp_Id,ProjectID,ItemID,FromDate,ToDate,Callback) =>{
    axios.get(`${domain}/Distribution/rptProductPurchaseLedger/${Comp_Id}/${ProjectID}/${ItemID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }

  export const rptProductSaleLedgerInvoiceWise =(Comp_Id,ProjectID,FromDate,ToDate,CityID,CustomerID,ItemID,Callback) =>{
    axios.get(`${domain}/Distribution/rptProductSaleLedgerInvoiceWise/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${CityID}/${CustomerID}/${ItemID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }

  export const rptSaleSummary =(Comp_Id,ProjectID,FromDate,ToDate,DeliveryID,Callback) =>{
    axios.get(`${domain}/Distribution/rptSaleSummary/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}/${DeliveryID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  
  export const rptAccouontLedger =(Comp_Id,ProjectID,CGID,CustomerID,FromDate,ToDate,Callback) =>{
    axios.get(`${domain}/Distribution/rptAccouontLedger/${Comp_Id}/${ProjectID}/${CGID}/${CustomerID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }

  export const rptTownWiseSale =(Comp_Id,ProjectID,ManufacturedByID,CGID,FromDate,ToDate,Callback) =>{
    axios.get(`${domain}/Distribution/rptTownWiseSale/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${CGID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const rptAreaWiseSale =(Comp_Id,ProjectID,ManufacturedByID,PGID,FromDate,ToDate,Callback) =>{
    axios.get(`${domain}/Distribution/rptAreaWiseSale/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${PGID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const rptRecoveryAnalysis =(Comp_Id,ProjectID,CGID,FromDate,ToDate,Callback) =>{
    axios.get(`${domain}/Distribution/rptRecoveryAnalysis/${Comp_Id}/${ProjectID}/${CGID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
 
  export const rptSaleRegister =(Comp_Id,ProjectID,SaleInvNoFrom,SaleInvNoTo,TransactionType,Callback) =>{
    axios.get(`${domain}/Distribution/rptSaleRegister/${Comp_Id}/${ProjectID}/${SaleInvNoFrom}/${SaleInvNoTo}/${TransactionType}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  
  export const SelectInvoiceList =(Comp_Id,ProjectID,Tr_Type,FromDate,ToDate,Callback) =>{
    axios.get(`${domain}/Distribution/SelectInvoiceList/${Comp_Id}/${ProjectID}/${Tr_Type}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
 
  export const rptCustomerWiseRegister =(Comp_Id,ProjectID,ManufacturedByID,CGID,CustomerID,FromDate,ToDate,Callback) =>{
    axios.get(`${domain}/Distribution/rptCustomerWiseRegister/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${CGID}/${CustomerID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const rptCustomerWiseRegisterWithDis =(Comp_Id,ProjectID,ManufacturedByID,CGID,CustomerID,FromDate,ToDate,Callback) =>{
    axios.get(`${domain}/Distribution/rptCustomerWiseRegisterWithDis/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${CGID}/${CustomerID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const rptCustomerWiseRegisterWithBonus =(Comp_Id,ProjectID,ManufacturedByID,CGID,CustomerID,FromDate,ToDate,Callback) =>{
    axios.get(`${domain}/Distribution/rptCustomerWiseRegisterWithBonus/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${CGID}/${CustomerID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const rptGroupWiseSale =(Comp_Id,ProjectID,ManufacturedByID,PGID,FromDate,ToDate,Callback) =>{
    axios.get(`${domain}/Distribution/rptGroupWiseSale/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${PGID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const rptSaleAndStock =(Comp_Id,ProjectID,ManufacturedByID,CGID,FromDate,ToDate,SaleRate,Callback) =>{
    axios.get(`${domain}/Distribution/rptSaleAndStock/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${CGID}/${FromDate}/${ToDate}/${SaleRate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }

  export const rptEssentialProductsSale =(Comp_Id,ProjectID,FromDate,ToDate,Callback) =>{
    axios.get(`${domain}/Distribution/rptEssentialProductsSale/${Comp_Id}/${ProjectID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }