import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectBranchesList =(Callback) =>{
    
  axios.get(`${domain}/branches/SelectBranchesList`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectBranch =(Comp_Id,Callback) =>{
    
    axios.get(`${domain}/branches/SelectBranch/${Comp_Id}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
export const SaveBranch = (BranchData,IsAddNew,callback) =>{
 
  axios.post(`${domain}/branches/SaveBranch`,{
    BranchData,
    IsAddNew
  })
    .then(response=>{
        toast.configure()
        toast.info(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        callback()
    })
    .catch(err=>console.error(err))
}

export const DeleteBranch =(Comp_Id,Callback) =>{
    
    axios.delete(`${domain}/branches/DeleteBranch/${Comp_Id}`)
         .then(response=>{
            Callback(response.data.status , response.data.message)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }