import React, { useEffect, useState } from 'react'
import { Modal, Card, Row, Col } from "react-bootstrap"
import { Checkbox, TextField, FormControlLabel } from '@material-ui/core'
import { Loop, Cancel, CloudDownload } from "@material-ui/icons"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Autocomplete } from '@material-ui/lab'
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

import { SelectUserProjects } from '../../../Services/Projects'
import { GetCurrentDate , GetFirstDateofCurrentMonth} from '../../../Services/Common'
import { SelectcustomersList } from '../../../Services/CustomersAPI'
import { FinYear } from '../../../Services/FinYear'
import { SelectItemsListWRTProject } from '../../../Services/ItemsAPI'
import { SelectManufacturedByList } from '../../../Services/manufactureAPI'
import { SelectCityList } from '../../../Services/CityAPI'
import { SelectCityWiseAreas } from '../../../Services/AreasAPI'
import { SelectBatchDetails } from '../../../Services/StockTransferAPI'
import { SelectWareHouseList } from "../../../Services/WareHouseAPI";
import { SelectDeliveries } from '../../../Services/DeliveryAPI';
import { SelectInvoiceList, rptSaleSummary, rptGroupWiseSale, rptAreaWiseSale } from '../../../Services/Distribution_ReportAPI'
import { SelectCompanyGroups, SelectCompanyGroupAgainstManufacturer } from '../../../Services/CompanyGroupAPI'
import { SelectProductGroupAgainstManufacturer } from '../../../Services/ProductGroupAPI'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as XLSXStyle from "xlsx-js-style";
import { GetUserCG, GetUserManufacturer, GetUserPG } from '../../../Services/ClaimAPI'
import Loader from '../../Loader'
const ExcelJS = require("exceljs");

function DistributionReport_Modal({ show, onHide, Comp_Id, User_Id, toPath, readonlyProps, title, mTr_Type, Comp_Name,UserType }) {
    const [Project_List, setProject_List] = useState([])
    const [IsLoader, setIsLoader] = useState(false)
    var [SelectedProject, setSelectedProject] = useState(null)
    const [FromDate, setFromDate] = useState(GetFirstDateofCurrentMonth())
    const [ToDate, setToDate] = useState(GetCurrentDate())
    const [Customer_List, setCustomer_List] = useState([])
    const [SelectedCustomer, setSelectedCustomer] = useState(null)
    const [filter, setfilter] = useState(null)
    const [FinYearData, setFinYearData] = useState([])
    const [SelectedFinData, setSelectedFinData] = useState(null)
    const [ReportPath, setReportPath] = useState('#')
    const [ItemList, setItemList] = useState([])
    const [SelectedFromItem, setSelectedFromItem] = useState(null)
    const [SelectedToItem, setSelectedToItem] = useState(null)
    const [ManufactuererBy_Data, setManufactuererBy_Data] = useState([])
    const [Selected_ManufactuererBy_Data, setSelected_ManufactuererBy_Data] = useState(null)
    const [CityData, setCityData] = useState([])
    const [SelectedCity, setSelectedCity] = useState(null)
    const [SelectedArea, setSelectedArea] = useState(null)
    const [AreaData, setAreaData] = useState([])
    const [SelectedBatch, setSelectedBatch] = useState(null)
    const [BatchData, setBatchData] = useState([])
    const [SelectedWarehouse, setSelectedWarehouse] = useState(null)
    const [DeliveryList, setDeliveryList] = useState([])
    const [SelectedDelivery, setSelectedDelivery] = useState(null)
    const [CG_Data, setCG_Data] = useState([])
    const [Selected_CG_Data, setSelected_CG_Data] = useState(null)
    const [InvList, setInvList] = useState([])
    const [SelectedFromInvNo, setSelectedFromInvNo] = useState(null)
    const [SelectedToInvNo, setSelectedToInvNo] = useState(null)
    const [SelectedSaleRate, setSelectedSaleRate] = useState(null)
    const [PG_Data, setPG_Data] = useState([])
    const [Selected_PG_Data, setSelected_PG_Data] = useState(null)
    const [isDisPer, setIsDisPer] = useState(false)
    const [isWithBonus, setIsWithBonus] = useState(false)
    const [chkForDis, setChkForDis] = useState(false)
    const [chkForBonus, setChkForBonus] = useState(false)
    const [Err_state, setErr_state] = useState({
        CG_Err: '',
        Manufacturer_Err: '',
        CompanyGroup_Err: '',
        Project_Err: '',
        FromItem_Err: '',
        Batch_Err: '',
        City_Err: '',
        Area_Err: '',
        Customer_Err: '',
        DElivery_Err: '',
        FromInv_Err: '',
        ToInv_Err: '',
        SaleRate_Err:'',
    })
    var [mTarget, setMTarget] = useState('');
    const [CompanyGroup, setCompanyGroup] = useState('')
    var mArray = [], mGroups = [], ColArray = []

    useEffect(() => {
        if (show && toPath) {
            setFromDate(GetFirstDateofCurrentMonth())
            SelectUserProjects(Comp_Id, User_Id, (mProjects) => {
                setSelectedProject(mProjects[0])
                setProject_List(mProjects)
                SelectManufacturedBy_List(mProjects[0].Project_ID)
                Select_Item(mProjects[0].Project_ID)
                Select_Customer(mProjects[0].Project_ID)
                Select_City(mProjects[0].Project_ID)
                Select_WareHOuse(mProjects[0].Project_ID)
                Select_Delivery(mProjects[0].Project_ID)
                Select_CG(mProjects[0].Project_ID)
                Select_Invoices(mProjects[0].Project_ID, mTr_Type, FromDate, ToDate)
            })
            FinYear(Comp_Id, User_Id, (mData) => {
                setFinYearData(mData)
            })
        }
    }, [show])
    const handleFilterChange = event => {
        if (event) {
            setfilter(event.filter);
        }
    };
    const Fin_Columns = [
        {
            field: "fin_year",
            header: "fin_year",
            width: "150px",
        },
        {
            field: "StartYear",
            header: "StartYear",
            width: "150px",
        },
        {
            field: "EndYear",
            header: "EndYear",
            width: "150px",
        },
    ];
   const showLoader = () => {
       setIsLoader(true)
      }
     const hideLoader = () => {
        setIsLoader(false)
      }
    const Select_Item = (Project_ID) => {
        SelectItemsListWRTProject(Comp_Id, Project_ID, (mItems) => {
            setItemList(mItems)
        })
    }
    const Select_Customer = (Project_ID) => {
        SelectcustomersList(Comp_Id, Project_ID, (mCustomer) => {
            if (mCustomer.length > 0) {
                var FirstIndex = {
                    CustomerName: 'SELECT ALL',
                    CustomerID: 0
                }
                mCustomer.splice(0, 0, FirstIndex)
                setSelectedCustomer(FirstIndex)
            }
            setCustomer_List(mCustomer)
        })
    }
    const SelectManufacturedBy_List = (Project_ID) => {
        SelectManufacturedByList(Comp_Id, Project_ID, async(data) => {
            if (data.length > 0) {
                var FirstIndex = {
                    ManufacturedByID: 0,
                    ManufacturedBy: 'SELECT ALL'
                }
                data.splice(0, 0, FirstIndex)
                setSelected_ManufactuererBy_Data(FirstIndex)
            }
            if(UserType === 'Manger Portal'){
                const res = await GetUserManufacturer(User_Id)
                const UserAssignManufacturer = res.data
                const DisplayUserManufacturer = UserAssignManufacturer.map(val => {
                    return data.find(x => x.ManufacturedByID === val.ManufacturedByID);
                  }).filter(obj => obj !== undefined);
                  DisplayUserManufacturer.unshift(FirstIndex)
                  setManufactuererBy_Data(DisplayUserManufacturer)
            }else{
                setManufactuererBy_Data(data)
            }
            
        })
    }
    const Select_City = (Project_ID) => {
        SelectCityList(Comp_Id, Project_ID, (mCities) => {
            if (mCities.length > 0) {
                var FirstIndex = {
                    CityID: 0,
                    City: 'SELECT ALL'
                }
                var FirstIndex2 = {
                    AreaName: 'SELECT ALL',
                    AreaID: 0
                }

                mCities.splice(0, 0, FirstIndex)
                setSelectedArea(FirstIndex2)
                setSelectedCity(FirstIndex)
            }
            setCityData(mCities)
        })
    }
    const Select_Delivery = (Project_ID) => {
        SelectDeliveries(Comp_Id, Project_ID, (mDeliveries) => {
            if (mDeliveries.length > 0) {
                mDeliveries.map(value => {
                    value.DeliveryNameWithCode = value.DeliveryCode + "~" + value.DeliveryName
                })
                var FirstIndex = {
                    DeliveryID: 0,
                    DeliveryNameWithCode: 'SELECT ALL'
                }
                mDeliveries.splice(0, 0, FirstIndex)
                setSelectedDelivery(FirstIndex)
            }

            setDeliveryList(mDeliveries)
        })
    }
    const Select_WareHOuse = (ProjectID) => {
        SelectWareHouseList(Comp_Id, ProjectID, (mWareHouse) => {
            setSelectedWarehouse(mWareHouse[0])
        })
    }
    const Select_CG = (ProjectID) => {
        SelectCompanyGroups(Comp_Id, ProjectID, async(mCGData) => {
            if (mCGData.length > 0) {
                var FirstIndex = {
                    CGID: 0,
                    CGName: 'SELECT ALL'
                }
                mCGData.splice(0, 0, FirstIndex)
                setSelected_CG_Data(FirstIndex)
            }
            if(UserType === 'Manger Portal'){
                const res = await GetUserCG(User_Id)
                const UserAssignCG = res.data
                const DisplayUserCG = UserAssignCG.map(val => {
                    return mCGData.find(x => x.CGID === val.CGID);
                  }).filter(obj => obj !== undefined);
                  DisplayUserCG.unshift(FirstIndex)
                  setCG_Data(DisplayUserCG)
            }else{
                setCG_Data(mCGData)
            }
        })
    }
    const Select_Invoices = (ProjectID, Tr_Type, FromDate, ToDate) => {
        showLoader()
        SelectInvoiceList(Comp_Id, ProjectID, Tr_Type,FromDate, ToDate, (mInvoices) => {
            hideLoader()
            setInvList(mInvoices)
            setSelectedFromInvNo(mInvoices[0])
            setSelectedToInvNo(mInvoices[mInvoices.length - 1])
        })
    }
    const SelectProduct_GroupAgainstManufacturer = (ProjectID,ManufacturedByID) =>{
        SelectProductGroupAgainstManufacturer(Comp_Id, ProjectID,ManufacturedByID,async(mPG)=>{
            if (mPG.length > 0) {
                var FirstIndex = {
                    PGID: 0,
                    PGName: 'SELECT ALL'
                }
                mPG.splice(0, 0, FirstIndex)
                setSelected_PG_Data(FirstIndex)
            }
            if(UserType === 'Manger Portal'){
                const res = await GetUserPG(User_Id)
                const UserAssignPG = res.data
                const DisplayUserPG = UserAssignPG.map(val => {
                    return mPG.find(x => x.PGID === val.PGID);
                  }).filter(obj => obj !== undefined);
                  DisplayUserPG.unshift(FirstIndex)
                  setPG_Data(DisplayUserPG)
            }else{
                setPG_Data(mPG)
            }
           
        })
    }
    const NavigateToReport = () => {
        
        if (toPath == '/CurrentStockReport') {
            if (SelectedProject && Selected_ManufactuererBy_Data) {
                localStorage.setItem('token', sessionStorage.getItem('token'))
                window.open(`${toPath}/${SelectedProject.Project_ID}/${Selected_ManufactuererBy_Data.ManufacturedByID}`,'_blank')
            }
            else {
               
                if (!SelectedProject) {
                    setErr_state({ ...Err_state, Project_Err: 'required' })
                }
                else if (!Selected_ManufactuererBy_Data) {
                    setErr_state({ ...Err_state, Project_Err: '', Manufacturer_Err: 'required' })
                }
            }
        }
        else if (toPath == '/BatchWiseSaleReport') {
            if (SelectedProject && Selected_ManufactuererBy_Data && SelectedCity && SelectedArea && SelectedFromItem && SelectedBatch) {
                localStorage.setItem('token', sessionStorage.getItem('token'))
                localStorage.setItem('BatchWiseSaleReportParams', JSON.stringify(SelectedFromItem))
             
                window.open(`${toPath}/${SelectedProject.Project_ID}/${Selected_ManufactuererBy_Data.ManufacturedByID}/${SelectedCity.CityID}/${SelectedArea.AreaID}/${SelectedFromItem.ItemID}/${SelectedBatch.batchno}`,'_blank')
            }
            else {
               
                if (!SelectedProject) {
                    setErr_state({ ...Err_state, Project_Err: 'required' })
                }
                else if (!Selected_ManufactuererBy_Data) {
                    setErr_state({ ...Err_state, Project_Err: '', Manufacturer_Err: 'required' })
                }
                else if (!SelectedCity) {
                    setErr_state({ ...Err_state, Project_Err: '', Manufacturer_Err: '', City_Err: 'required' })
                }
                else if (!SelectedArea) {
                    setErr_state({ ...Err_state, Project_Err: '', Manufacturer_Err: '', City_Err: '', Area_Err: 'required', })

                }
                else if (!SelectedFromItem) {
                    setErr_state({ ...Err_state, Project_Err: '', Manufacturer_Err: '', City_Err: '', Area_Err: '', FromItem_Err: 'required', })

                }
                else if (!SelectedBatch) {
                    setErr_state({ ...Err_state, Project_Err: '', Manufacturer_Err: '', City_Err: '', Area_Err: '', FromItem_Err: '', Batch_Err: 'required', })

                }
            }
        }
        else if (toPath == '/NearExpiryStock') {
            if (SelectedProject && Selected_ManufactuererBy_Data) {
                localStorage.setItem('token', sessionStorage.getItem('token'))
                
                window.open(`${toPath}/${SelectedProject.Project_ID}/${Selected_ManufactuererBy_Data.ManufacturedByID}/${FromDate}/${ToDate}`,'_blank')
            }
            else {
                if (!SelectedProject) {
                    setErr_state({ ...Err_state, Project_Err: 'required' })
                }
                else if (!Selected_ManufactuererBy_Data) {
                    setErr_state({ ...Err_state, Project_Err: '', Manufacturer_Err: 'required' })
                }

            }
        }
        else if (toPath == '/ProductPurchaseLedgerReport') {
            if (SelectedProject && SelectedFromItem) {
                localStorage.setItem('token', sessionStorage.getItem('token'))
                
                window.open(`${toPath}/${SelectedProject.Project_ID}/${SelectedFromItem.ItemID}/${SelectedFromItem.ItemDiscription}/${FromDate}/${ToDate}`,'_blank' )
            }
            else {
                if (!SelectedProject) {
                    setErr_state({ ...Err_state, Project_Err: 'required' })
                }

                else if (!SelectedFromItem) {
                    setErr_state({ ...Err_state, Project_Err: '', FromItem_Err: 'required', })

                }
            }
        }
        else if (toPath == '/ProductSaleLedgerInvoiceWise') {
            if (SelectedProject && SelectedCity && SelectedCustomer && SelectedFromItem) {
                var mObj = {
                    ItemName: SelectedFromItem.ItemName,
                    City: SelectedCity.City,
                    CustomerName: SelectedCustomer.CustomerName
                }
                localStorage.setItem('ProductSaleLedgerInvoiceWise', JSON.stringify(mObj))
                localStorage.setItem('token', sessionStorage.getItem('token'))

                window.open(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}/${SelectedCity.CityID}/${SelectedCustomer.CustomerID}/${SelectedFromItem.ItemID}`,'_blank' )
            }
            else {
              
                if (!SelectedProject) {
                    setErr_state({ ...Err_state, Project_Err: 'required' })
                }

                else if (!SelectedCity) {
                    setErr_state({ ...Err_state, Project_Err: '', City_Err: 'required' })
                }
                else if (!SelectedCustomer) {
                    setErr_state({ ...Err_state, Project_Err: '', City_Err: '', Customer_Err: 'required', })

                }
                else if (!SelectedFromItem) {
                    setErr_state({ ...Err_state, Project_Err: '', City_Err: '', Customer_Err: '', FromItem_Err: 'required', })

                }
            }
        }
        else if (toPath == '/SaleSummaryReport') {
            if (SelectedProject && SelectedDelivery) {
                localStorage.setItem('token', sessionStorage.getItem('token'))
               
                window.open(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}/${SelectedDelivery.DeliveryID}/${SelectedDelivery.DeliveryNameWithCode}`,'_blank')
            }
            else {
               
                if (!SelectedProject) {
                    setErr_state({ ...Err_state, Project_Err: 'required' })
                }

                else if (!SelectedDelivery) {
                    setErr_state({ ...Err_state, Project_Err: '', DElivery_Err: 'required', })

                }
            }
        }
        else if (toPath == '/AccountLedgerRport') {
            if (SelectedProject && Selected_CG_Data && SelectedCustomer) {
                  
                if (SelectedCustomer.CustomerID !== 0) {
                
                    if (Selected_CG_Data.CGID !== 0) {
                        var Obj = {
                            CustomerName: SelectedCustomer.CustomerName,
                            CompanyGroup: Selected_CG_Data.CGName,
                            Address: SelectedCustomer.Address
                        }
                        localStorage.setItem("AccountLedgerRport", JSON.stringify(Obj))
                        localStorage.setItem('token', sessionStorage.getItem('token'))
                        
                        window.open(`${toPath}/${SelectedProject.Project_ID}/${Selected_CG_Data.CGID}/${SelectedCustomer.CustomerID}/${FromDate}/${ToDate}`,'_blank' )

                    }
                    else {
                        setErr_state({ ...Err_state, CompanyGroup_Err: 'PLease Select any Company Group' })
                    
                    }

                }
                else {
                    setErr_state({ ...Err_state, Customer_Err: 'PLease Select any Customer' })
                    
                }
               
            }
            else {
                 if(!SelectedProject){
                    setErr_state({...Err_state,Project_Err:'required'})
                 }
                 else if(!Selected_CG_Data){
                    setErr_state({...Err_state,Project_Err:'',CompanyGroup_Err:'required'})
                 }
                 else if(!SelectedCustomer){
                    setErr_state({...Err_state,Project_Err:'',CompanyGroup_Err:'',Customer_Err:'required'})
                 }
            }
        }
        else if (toPath == '/TownWiseReport') {
            if (SelectedProject && Selected_PG_Data && Selected_ManufactuererBy_Data) {
              
                if (Selected_ManufactuererBy_Data.ManufacturedByID !== 0) {
                        var Obj = {
                            ManufacturedBy: Selected_ManufactuererBy_Data.ManufacturedBy,
                            CompanyGroup: Selected_PG_Data.PGName,
                        }
                        localStorage.setItem("TownWiseReport", JSON.stringify(Obj))
                        localStorage.setItem('token', sessionStorage.getItem('token'))
                      
                        window.open(`${toPath}/${SelectedProject.Project_ID}/${Selected_ManufactuererBy_Data.ManufacturedByID}/${Selected_PG_Data.PGID}/${FromDate}/${ToDate}`,'_blank')


                }
                else {
                    setErr_state({ ...Err_state, Manufacturer_Err: 'PLease Select any Manufacturer' })
                   
                }
            }
            else {

                if (!SelectedProject) {
                    setErr_state({ ...Err_state, Project_Err: 'required' })
                }
                else if (!Selected_ManufactuererBy_Data) {
                    setErr_state({ ...Err_state, Project_Err: '', Manufacturer_Err: 'required' })
                }
                else if (!Selected_PG_Data) {
                    setErr_state({ ...Err_state, Project_Err: '', Manufacturer_Err: '', CompanyGroup_Err: 'required' })
                }
                
            }
        }
        else if (toPath == '/AreaWiseReport') {
            if (SelectedProject && Selected_PG_Data && Selected_ManufactuererBy_Data) {
              
                if (Selected_ManufactuererBy_Data.ManufacturedByID !== 0) {
                        var Obj = {
                            ManufacturedBy: Selected_ManufactuererBy_Data.ManufacturedBy,
                            CompanyGroup: Selected_PG_Data.PGName,
                        }
                        localStorage.setItem("AreanWiseReport", JSON.stringify(Obj))
                        localStorage.setItem('token', sessionStorage.getItem('token'))
                      
                        window.open(`${toPath}/${SelectedProject.Project_ID}/${Selected_ManufactuererBy_Data.ManufacturedByID}/${Selected_PG_Data.PGID}/${FromDate}/${ToDate}`,'_blank')


                }
                else {
                    setErr_state({ ...Err_state, Manufacturer_Err: 'PLease Select any Manufacturer' })
                   
                }
            }
            else {

                if (!SelectedProject) {
                    setErr_state({ ...Err_state, Project_Err: 'required' })
                }
                else if (!Selected_ManufactuererBy_Data) {
                    setErr_state({ ...Err_state, Project_Err: '', Manufacturer_Err: 'required' })
                }
                else if (!Selected_PG_Data) {
                    setErr_state({ ...Err_state, Project_Err: '', Manufacturer_Err: '', CompanyGroup_Err: 'required' })
                }
                
            }
        }
        else if (toPath == '/RecoveryAnalysisReport') {
            if (SelectedProject && Selected_CG_Data) {
                if (Selected_CG_Data.CGID !== 0) {
                    localStorage.setItem('token', sessionStorage.getItem('token'))
                   
                    window.open(`${toPath}/${SelectedProject.Project_ID}/${Selected_CG_Data.CGID}/${Selected_CG_Data.CGName}/${FromDate}/${ToDate}`,'_blank')

                }
                else {
                    setErr_state({ ...Err_state, CompanyGroup_Err: 'PLease Select any Company Group' })
                   
                }

            }
            else {
                if (!SelectedProject) {
                    setErr_state({ ...Err_state, Project_Err: 'required' })
                }
                else if (!Selected_CG_Data) {
                    setErr_state({ ...Err_state, Project_Err: '', CompanyGroup_Err: 'required' })
                }
             
            }
        }
        else if (toPath == '/SaleRegisterReport') {
            if (SelectedProject && SelectedFromInvNo && SelectedToInvNo) {
                localStorage.setItem('token', sessionStorage.getItem('token'))
               
                window.open(`${toPath}/${SelectedProject.Project_ID}/${SelectedFromInvNo.SaleInvoiceNo}/${SelectedToInvNo.SaleInvoiceNo}/INV`,'_blank')

            }
            else {
                if (!SelectedProject) {
                    setErr_state({ ...Err_state, Project_Err: 'required' })
                }
                else if (!SelectedFromInvNo) {
                    setErr_state({ ...Err_state, Project_Err: '', FromItem_Err: 'required', })
                }
                else if (!SelectedToInvNo) {
                    setErr_state({ ...Err_state, Project_Err: '', FromItem_Err: '', ToInv_Err: 'required', })

                }
               
            }
        }
        else if (toPath == '/SaleReturnRegisterReport') {
            if (SelectedProject && SelectedFromInvNo && SelectedToInvNo) {
                localStorage.setItem('token', sessionStorage.getItem('token'))
               
                window.open(`${toPath}/${SelectedProject.Project_ID}/${SelectedFromInvNo.SaleInvoiceNo}/${SelectedToInvNo.SaleInvoiceNo}/SR`,'_blank')

            }
            else {
                if (!SelectedProject) {
                    setErr_state({ ...Err_state, Project_Err: 'required' })
                }
                else if (!SelectedFromInvNo) {
                    setErr_state({ ...Err_state, Project_Err: '', FromInv_Err: 'required', })
                }
                else if (!SelectedToInvNo) {
                    setErr_state({ ...Err_state, Project_Err: '', FromInv_Err: '', ToInv_Err: 'required', })

                }
               
            }
        }
        else if (toPath == '/CustomerWiseSAleRegisterReport') {
            if (SelectedProject && Selected_ManufactuererBy_Data && Selected_CG_Data && SelectedCustomer) {
                if (Selected_ManufactuererBy_Data.ManufacturedByID !== 0) {
                    var Obj = {
                        ManufacturedBy: Selected_ManufactuererBy_Data.ManufacturedBy,
                        CompanyGroup: Selected_CG_Data.CGName,
                        CustomerName: SelectedCustomer.CustomerName,
                        isWithDis: isDisPer,
                        isWithBonus: isWithBonus
                    }
                    localStorage.setItem("CustomerWiseSAleRegisterReport", JSON.stringify(Obj))
                    localStorage.setItem('token', sessionStorage.getItem('token'))
                    
                    window.open(`${toPath}/${SelectedProject.Project_ID}/${Selected_ManufactuererBy_Data.ManufacturedByID}/${Selected_CG_Data.CGID}/${SelectedCustomer.CustomerID}/${FromDate}/${ToDate}`,'_blank')

                }
                else {
                    setErr_state({ ...Err_state, Manufacturer_Err: 'PLease Select any Manufacturer' })
                    
                }
            }
            else {
                if (!SelectedProject) {
                    setErr_state({ ...Err_state, Project_Err: 'required' })
                }
                else if(!Selected_ManufactuererBy_Data){
                    setErr_state({ ...Err_state, Project_Err: '',Manufacturer_Err:'required' })
                }
                else if(!Selected_CG_Data){
                    setErr_state({ ...Err_state, Project_Err: '',Manufacturer_Err:'',CompanyGroup_Err:'rquired' })
                }
                else if(!SelectedCustomer){
                    setErr_state({ ...Err_state, Project_Err: '',Manufacturer_Err:'',CompanyGroup_Err:'',Customer_Err:'required' })
                }
              
            }
        }
        else if (toPath == '/SaleAndStockReport') {
            if (SelectedProject && Selected_ManufactuererBy_Data && Selected_PG_Data && SelectedSaleRate) {
              
                    if (Selected_ManufactuererBy_Data.ManufacturedByID !== 0) {
                        // if (Selected_CG_Data.CGID !== 0) {
                            var Obj = {
                                ManufacturedBy: Selected_ManufactuererBy_Data.ManufacturedBy,
                                CompanyGroup: Selected_PG_Data.PGName,
                            }
                            localStorage.setItem("SaleAndStockReport", JSON.stringify(Obj))
                            localStorage.setItem('token', sessionStorage.getItem('token'))
                            window.open(`${toPath}/${SelectedProject.Project_ID}/${Selected_ManufactuererBy_Data.ManufacturedByID}/${Selected_PG_Data.PGID}/${FromDate}/${ToDate}/${SelectedSaleRate.title}`,'_blank')

                        // }
                        // else {
                        //     setErr_state({ ...Err_state, CompanyGroup_Err: 'PLease Select any Company Group' })
                            
                        // }

                    }
                    else {
                        setErr_state({ ...Err_state, Manufacturer_Err: 'PLease Select any Manufacturer' })
                       
                    }
               
            }
            else {
                if(!SelectedProject) {
                    setErr_state({ ...Err_state, Project_Err: 'required' })
                  
                }
                else if(!Selected_ManufactuererBy_Data){
                    setErr_state({ ...Err_state, Project_Err: '',Manufacturer_Err:'required' })
                }
                // else  if (Selected_ManufactuererBy_Data.ManufacturedByID == 0) {
                //     setErr_state({ ...Err_state, Manufacturer_Err: 'PLease Select any Manufacturer' })
                // }
                else if(!Selected_CG_Data){
                    setErr_state({ ...Err_state, Project_Err: '',Manufacturer_Err:'',CompanyGroup_Err:'rquired' })
                }
                else if (!SelectedSaleRate){
                    setErr_state({ ...Err_state, Project_Err: '',Manufacturer_Err:'',CompanyGroup_Err:'', SaleRate_Err:'required' })
                }
              
            }
        }
        else if (toPath == '/GroupWiseSale') {
            if (SelectedProject && Selected_ManufactuererBy_Data && Selected_PG_Data) {
                    if (Selected_ManufactuererBy_Data.ManufacturedByID !== 0) {
                        // if (Selected_CG_Data.CGID !== 0) {
                            var Obj = {
                                ManufacturedBy: Selected_ManufactuererBy_Data.ManufacturedBy,
                                ProductGroup: Selected_PG_Data.PGName,
                            }
                            localStorage.setItem("GroupWiseSale", JSON.stringify(Obj))
                            localStorage.setItem('token', sessionStorage.getItem('token'))
                           
                            window.open(`${toPath}/${SelectedProject.Project_ID}/${Selected_ManufactuererBy_Data.ManufacturedByID}/${Selected_PG_Data.PGID}/${FromDate}/${ToDate}`,'_blank')

                        // }
                        // else {
                        //     setErr_state({ ...Err_state, CompanyGroup_Err: 'PLease Select any Company Group' })
                            
                        // }

                    }
                    else {
                        setErr_state({ ...Err_state, Manufacturer_Err: 'PLease Select any Manufacturer' })
                       
                    }
               
            }
            else {
                if(!SelectedProject) {
                    setErr_state({ ...Err_state, Project_Err: 'required' })
                  
                }
                else if(!Selected_ManufactuererBy_Data){
                    setErr_state({ ...Err_state, Project_Err: '',Manufacturer_Err:'required' })
                }
                // else  if (Selected_ManufactuererBy_Data.ManufacturedByID == 0) {
                //     setErr_state({ ...Err_state, Manufacturer_Err: 'PLease Select any Manufacturer' })
                // }
                else if(!Selected_CG_Data){
                    setErr_state({ ...Err_state, Project_Err: '',Manufacturer_Err:'',CompanyGroup_Err:'rquired' })
                }
                else if (!SelectedSaleRate){
                    setErr_state({ ...Err_state, Project_Err: '',Manufacturer_Err:'',CompanyGroup_Err:'', SaleRate_Err:'required' })
                }
              
            }
        }
        else if (toPath == '/rptEssentialMedical'){
            if (SelectedProject) {
                localStorage.setItem('token', sessionStorage.getItem('token'))
                window.open(`${toPath}/${SelectedProject.Project_ID}/${FromDate}/${ToDate}`,'_blank')
            }
            else {
                if (!SelectedProject) {
                    setErr_state({ ...Err_state, Project_Err: 'required' })
                }
            }
        }
    }
    const ExportReport = () =>{
        
        if (toPath == '/SaleSummaryReport') {
            if (SelectedProject && SelectedDelivery) {
                
               // SelectedProject.Project_ID}/${FromDate}/${ToDate}/${SelectedDelivery.DeliveryID}/${SelectedDelivery.DeliveryNameWithCode
               rptSaleSummary(Comp_Id, SelectedProject.Project_ID, FromDate, ToDate, SelectedDelivery.DeliveryID,(mData)=>{
                   
                //    const mObj = {CustomerID: '0', CustomerCode: '', SaleInvoiceID:'', SaleInvoiceNo:'', InvoiceDate:Comp_Name,CustomerName: '',City: '', InvoiceAmount:'', TotalDiscount:'',AddTaxAmt:'',IncomeTaxAmt:'', GrandTotal: ''}
                //    mData.unshift(mObj)
                //    const ws = XLSX.utils.json_to_sheet(mData);
                //    const wb = { Sheets: { data: ws }, SheetNames: ["Sale Summary Report"] };
                //    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                //    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
                //    FileSaver.saveAs(data, `Sale Summary Report - ${GetCurrentDate()}`  +  ".xlsx");
                // first create woekbook
                const workbook = new ExcelJS.Workbook();
                // 2nd create sheet
                const sheet = workbook.addWorksheet("Sale Summary Report");
                // sheet.properties.defaultRowHeight = 80;
               
               
                // create columns for sheet
                sheet.columns = [
                    
                    {
                      header: "Customer ID",
                      key: "CustomerID",
                      width: 15,
                    },
                    { header: "Customer Code", key: "CustomerCode", width: 20 },
                    {
                      header: "SaleInvoice ID",
                      key: "SaleInvoiceID",
                      width: 20,
                    },
                    {
                        header: "SaleInvoice No",
                        key: "SaleInvoiceNo",
                        width: 20,
                      },
                    {
                      header: "Invoice Date",
                      key: "InvoiceDate",
                      width: 20,
                    },
                    {
                      header: "Customer Name",
                      key: "CustomerName",
                      width: 40,
                    },
                    {
                      header: "City",
                      key: "City",
                      width: 20,
                    },
                    {
                      header: "Invoice Amount",
                      key: "InvoiceAmount",
                      width: 20,
                    },
                     
                      {
                        header: "Total Discount",
                        key: "TotalDiscount",
                        width: 20,
                      },
                      {
                        header: "Add TaxAmt",
                        key: "AddTaxAmt",
                        width: 20,
                      },
                      {
                        header: "Income TaxAmt",
                        key: "IncomeTaxAmt",
                        width: 20,
                      },
                      {
                        header: "Grand Total",
                        key: "GrandTotal",
                        width: 20,
                      },
                  ];
                 
                  // create a new row
                  sheet.spliceRows(1, 0, [ Comp_Name]);
                  sheet.spliceRows(2, 0, [ Comp_Name]);
                  sheet.spliceRows(3, 0, [ "Sale Summary"]);
                  sheet.spliceRows(4, 0, [ "Project:   " + Comp_Name,null, null, null, null,null, null, "Delivery :  " + SelectedDelivery.DeliveryNameWithCode]);
                  sheet.spliceRows(5, 0, [ "From Date:   " + FromDate,null, null, null, null,null, null, "To Date :  " + ToDate]);
                  sheet.getRow(1).font = {
                    bold: true,
                    size: 34,
                  }
                  sheet.getRow(6).font = {
                    bold: true,
                    size: 14,
                    
                  }
                    const row = sheet.getRow(6);
                    row.height = 30
                       for (let i = 1; i <= 12; i++) {
                                   const cell = row.getCell(i);
                                   cell.border = {
                                   top: { style: "thick" },
                                   bottom: { style: "thick" },
                                  };
                                  cell.alignment = { horizontal: 'center'};
                                  cell.wrapText = true;
                              }
                 
                
                  // Merg Cell and Style
                  sheet.mergeCells('A1', 'L2');
                  sheet.getCell('A1').value = Comp_Name
                  const cell = sheet.getCell('A1');
                  cell.alignment = { horizontal: 'center' };
                 // Merge  2nd and style
                 sheet.mergeCells('A3', 'L3');
                 const cell2 = sheet.getCell('A3');
                 cell2.alignment = { horizontal: 'center' };
                 cell2.font = { size: 16, bold: true };
                 //4rth
                 sheet.mergeCells('A4', 'D4');
                 const cell3 = sheet.getCell('A4');
                 cell3.alignment = { horizontal: 'center' };
                 cell3.font = { size: 14, bold: true };
                 // delivery cell 
                 sheet.mergeCells('H4', 'J4');
                 const cell4 = sheet.getCell('I4')
                 cell4.alignment = { horizontal: 'center' };
                 cell4.font = { size: 14, bold: true };
                 // From Date
                 sheet.mergeCells('A5', 'D5');
                 const cell5 = sheet.getCell('A5');
                 cell5.alignment = { horizontal: 'center' };
                 cell5.font = { size: 14, bold: true };
                 // To Date 
                 sheet.mergeCells('H5', 'J5');
                 const cell6 = sheet.getCell('H5');
                 cell6.alignment = { horizontal: 'center' };
                 cell6.font = { size: 14, bold: true };
                 // merge cells 
                 sheet.mergeCells('E4', 'G4');
                 sheet.mergeCells('E5', 'G5');
                  // iterate on data 
                  mData.map((item,index)=>{
                    sheet.addRow({
                        CustomerID: item.CustomerID,
                        CustomerCode: item.CustomerCode,
                        SaleInvoiceID: item.SaleInvoiceID,
                        SaleInvoiceNo: item.SaleInvoiceNo,
                        InvoiceDate: item.InvoiceDate,
                        CustomerName: item.CustomerName,
                        City: item.City,
                        InvoiceAmount: item.InvoiceAmount,
                        TotalDiscount: item.TotalDiscount,
                        AddTaxAmt: item.AddTaxAmt,
                        IncomeTaxAmt: item.IncomeTaxAmt,
                        GrandTotal: item.GrandTotal
                    })
                  })
                  const lastrow = mData.length + 6
                  const row1 = sheet.getRow(lastrow);
                  row1.height = 30
                     for (let i = 1; i <= 12; i++) {
                                 const cell = row1.getCell(i);
                                 cell.border = {
                                 top: { style: "thick" },
                                 bottom: { style: "thick" },
                                };
                                cell.alignment = { horizontal: 'center'};
                                cell.wrapText = true;
                                cell.font = {bold: true, size: 14}
                            }
                           
                            let cell1 = row.getCell(3);
                            var currentValue = cell1.value;
                            var newValue = "Invoices" + currentValue ;
                            cell1.value = newValue
                  // End Iteration
                  workbook.xlsx.writeBuffer().then(function (mData) {
                    const blob = new Blob([mData], {
                      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                    const url = window.URL.createObjectURL(blob);
                    const anchor = document.createElement("a");
                    anchor.href = url;
                    anchor.download = "SaleSummaryReport.xlsx";
                    anchor.click();
                    window.URL.revokeObjectURL(url);
                  });

               })

            } else {
               
                if (!SelectedProject) {
                    setErr_state({ ...Err_state, Project_Err: 'required' })
                }

                else if (!SelectedDelivery) {
                    setErr_state({ ...Err_state, Project_Err: '', DElivery_Err: 'required', })

                }
            }
        }
        else if (toPath == '/GroupWiseSale') {
            if (SelectedProject && Selected_ManufactuererBy_Data && Selected_PG_Data) {
                if (Selected_ManufactuererBy_Data.ManufacturedByID !== 0) {
                    // if (Selected_CG_Data.CGID !== 0) {
                        // var Obj = {
                        //     ManufacturedBy: Selected_ManufactuererBy_Data.ManufacturedBy,
                        //     ProductGroup: Selected_PG_Data.PGName,
                        // }
                        // localStorage.setItem("GroupWiseSale", JSON.stringify(Obj))
                        // localStorage.setItem('token', sessionStorage.getItem('token'))
                       
                        // window.open(`${toPath}/${SelectedProject.Project_ID}/${Selected_ManufactuererBy_Data.ManufacturedByID}/${Selected_PG_Data.PGID}/${FromDate}/${ToDate}`,'_blank')
                    rptGroupWiseSale(Comp_Id, SelectedProject.Project_ID,Selected_ManufactuererBy_Data.ManufacturedByID,Selected_PG_Data.PGID,FromDate, ToDate, (mData)=>{
                        let subtotals = {};
                        // loop through the array and group by PGName
                        for (let i = 0; i < mData.length; i++) {
                          let item = mData[i];
                          if (subtotals[item.PGName] === undefined) {
                            subtotals[item.PGName] = {};
                          }
                          if (subtotals[item.PGName][item.GroupName] === undefined) {
                            subtotals[item.PGName][item.GroupName] = 0;
                          }
                          // calculate subtotal for this item
                          let subtotal = item.Qty * item.TP;
                          subtotals[item.PGName][item.GroupName] += subtotal;
                          // add to total Qty
                          if (subtotals[item.PGName].TotalQty === undefined) {
                            subtotals[item.PGName].TotalQty = 0;
                          }
                          subtotals[item.PGName].TotalQty += item.Qty;
                          // add to total value
                          if (subtotals[item.PGName].value === undefined) {
                            subtotals[item.PGName].value = 0;
                          }
                          subtotals[item.PGName].value += item.Value;
                        }
                        let SubTotalsresult = Object.entries(subtotals).map(([PGName, PGData]) => {
                            const subtotal = {};
                            Object.entries(PGData).forEach(([key, value]) => {
                              if (typeof value === 'number' && !isNaN(value)) {
                                subtotal[key] = (subtotal[key] || 0) + value;
                              }
                            });
                            return { PGName, ...subtotal };
                          });
                          
                         let GrandTotalsresult = Object.values(subtotals).reduce((acc, curr) => {
                             Object.entries(curr).forEach(([key, value]) => {
                                 if (typeof value === 'number' && !isNaN(value)) {
                                      acc[key] = (acc[key] || 0) + value;
                                                }
                                          });
                                     return acc;
                                } , {});
                        var _Header_Array = [], _Body_Array = [], _PG_Array = [];
     
                        mData.map((value, index) => {
           
           
            var _IsFind = _Header_Array.find(x => x.GroupID == value.GroupID)
            if (!_IsFind) {
                _Header_Array.push(value)
            }

            var __isFind = _Body_Array.find(x => x.ItemID == value.ItemID)
            if (!__isFind) {
                _Body_Array.push(value)
            }
            var __isPGFind = _PG_Array.find(x => x.PGID == value.PGID)
            if (!__isPGFind) {
                _PG_Array.push(value)
            }
          
                   })
        
        _Body_Array.sort((a,b)=> a.ItemID - b.ItemID)
        _Header_Array.sort((a,b)=> a.GroupID - b.GroupID)
        
        
        var _AreaWiseReportData = JSON.parse(localStorage.getItem("GroupWiseSale"))

        if (_AreaWiseReportData) {
            setCompanyGroup(_AreaWiseReportData.ProductGroup)
        }
      
                        const workbook = new ExcelJS.Workbook();
                        const worksheet = workbook.addWorksheet("Group Wise Sale");
                        worksheet.columns = [
                          {
                            header: "Product Name",
                            key: "productName",
                            width: 50,
                          },
                        ];
                        const headers = ["Product Name", "TP"];
                        _Header_Array.forEach((header) => {
                          headers.push(
                            header.GroupName,
                            `${header.GroupName} Total`
                          );
                        });
                        headers.push("Total Qty", "Value");
                        worksheet.getRow(1).values = headers;

                        // Setting border and text alignment for headers
                        worksheet.getRow(1).eachCell((cell) => {
                          cell.border = {
                            top: { style: "thin" },
                            left: { style: "thin" },
                            bottom: { style: "thin" },
                            right: { style: "thin" },
                          };
                          cell.alignment = {
                            vertical: "middle",
                            horizontal: "center",
                          };
                        });

                        worksheet.columns.forEach(column => {
                           
                            column.width = column.width ||  25;
                            });

                        worksheet.spliceRows(1, 0, [Comp_Name]);
                        worksheet.spliceRows(2, 0, [Comp_Name]);
                        worksheet.spliceRows(3, 0, ["Group Wise Sale"]);
                        worksheet.spliceRows(4, 0, ["Project:   " + Comp_Name,  null, null,null, null,   "Company :  " +  Selected_PG_Data.PGName, ]);
                        worksheet.spliceRows(5, 0, [ "From Date:   " + FromDate,  null,null,null, null,  "To Date :  " + ToDate ]);
                        worksheet.getRow(1).font = {
                          bold: true,
                          size: 34,
                        };
                        worksheet.getRow(6).font = {
                          bold: true,
                          size: 14,
                        };
                        const row = worksheet.getRow(6);
                        row.height = 30
                           for (let i = 1; i <= headers.length; i++) {
                                       const cell = row.getCell(i);
                                       cell.border = {
                                       top: { style: "thick" },
                                       bottom: { style: "thick" },
                                      };
                                      cell.alignment = { horizontal: 'center'};
                                      cell.wrapText = true;
                                  }

                                  let subTotalQty = 0;
                                  let subTotalValue = 0;
                                  let totalQty = 0;
                                  let totalValue = 0;
                                  _PG_Array.map(PGVal=>{
                                    const row = worksheet.addRow();
                                    const cell = row.getCell(1);
                                    cell.value = PGVal.PGName;
                                    cell.font = {bold: true, size: 26};
                                    cell.border = {
                                    top: {style: 'thin'},
                                    bottom: {style: 'thin'}
                                    };
                                    // Reset subtotals for each new PGVal
                                     subTotalQty = 0;
                                     subTotalValue = 0;
                                    _Body_Array.forEach((value, index) => {
                                        if (PGVal.PGID === value.PGID) {
                                          const row = worksheet.addRow();
                                      
                                          const cell = row.getCell(1);
                                          cell.value = value.ItemDiscription;
                                          cell.font = {bold: true};
                                          cell.border = {
                                            top: {style: 'thin'},
                                            bottom: {style: 'thin'},
                                            left: {style: 'thin'},
                                            right: {style: 'thin'}
                                          };
                                          cell.alignment = {wrapText: true, horizontal: 'left', vertical: 'top'};
                                          cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFFFFFFF'}, bgColor: {argb: 'FFFFFFFF'}};
                                      
                                          const cellTP = row.getCell(2);
                                          cellTP.value = value.TP;
                                          cellTP.font = {bold: true};
                                          cellTP.border = {
                                            top: {style: 'thin'},
                                            bottom: {style: 'thin'}
                                          };
                                          cellTP.alignment = {horizontal: 'center'};
                                      
                                          _Header_Array.forEach((TValue, TIndex) => {
             
                                            const headerMap = {};
                                            worksheet.getRow(6).eachCell((cell, colNumber) => {
                                                headerMap[cell.value] = colNumber;
                                            });
                                            const cell = TValue.GroupName
                                            const TotalCell = TValue.GroupName + " Total"
                                            const colNumber = headerMap[cell];
                                            const TotalColNumber =  headerMap[TotalCell];
                                            const cell1 = row.getCell(colNumber);
                                            const totQty = "Total Qty"
                                            const TotalQtyColNumber =  headerMap[totQty];
                                            const TotalQtyCell = row.getCell(TotalQtyColNumber)
                                            const totVal = "Value" 
                                            const TotalValue = headerMap[totVal];
                                            const valueCell = row.getCell(TotalValue)
                                            
                                          

                                            mData.map(mval =>{
                                                if(mval.ItemDiscription === value.ItemDiscription && mval.GroupName === cell){
                                                    cell1.value = mval.Qty === 0 || mval.Qty === null || mval.Qty === '' || mval.Qty === undefined ? 0 : mval.Qty
                                                      
                                                }
                                            })
                                           
                                            let totalQty = 0;
                                            mData.forEach(mval => {
                                              if (mval.ItemDiscription === value.ItemDiscription) {
                                                const qty = mval.Qty === 0 || mval.Qty === null || mval.Qty === '' || mval.Qty === undefined ? 0 : mval.Qty;
                                                totalQty += qty 
                                               
                                              }
                                            });

                                            let totalValue = 0;
                                            mData.forEach(mval => {
                                              if (mval.ItemDiscription === value.ItemDiscription) {
                                                const qty = mval.Qty === 0 || mval.Qty === null || mval.Qty === '' || mval.Qty === undefined ? 0 : mval.Qty;
                                                totalValue += qty * value.TP;
                                              }
                                            });
                                            
                                            TotalQtyCell.value = totalQty;
                                            valueCell.value = totalValue;

                                            cell1.border = {
                                                      top: {style: 'thin'},
                                                      bottom: {style: 'thin'},
                                                      left: {style: 'thin'},
                                                      right: {style: 'thin'}
                                                    };
                                                    cell1.alignment = {horizontal: 'center'};
                                                    TotalQtyCell.border = {
                                                        top: {style: 'thin'},
                                                        bottom: {style: 'thin'},
                                                        left: {style: 'thin'},
                                                        right: {style: 'thin'}
                                                      };
                                                      TotalQtyCell.alignment = {horizontal: 'center'};
                                          valueCell.font = {bold: true};
                                          valueCell.border = {
                                            top: {style: 'thin'},
                                            bottom: {style: 'thin'},
                                            left: {style: 'thin'},
                                            right: {style: 'thin'}
                                          };
                                          valueCell.alignment = {horizontal: 'center'};


                                                    const cellTotal = row.getCell(TotalColNumber); 
                                                    const CellWithoutTotal = TotalCell.replace("Total", "").trim();
                                                   
                                                    mData.map(mval =>{
                                                         if(mval.ItemDiscription === value.ItemDiscription && mval.GroupName === CellWithoutTotal){
                                                            let mQty =  mval.Qty === 0 || mval.Qty === null || mval.Qty === '' || mval.Qty === undefined ? 0 : mval.Qty  
                                                            cellTotal.value = mQty * value.TP
                                                             
                                                        }
                                                    }) 
                                                    cellTotal.border = {
                                                        top: {style: 'thin'},
                                                        bottom: {style: 'thin'},
                                                        left: {style: 'thin'},
                                                        right: {style: 'thin'}
                                                      };
                                                      cellTotal.alignment = {horizontal: 'center'};
                                                  
                                                
                                          });
                                      
                                        }
                                      }); 

                                      const row1 = worksheet.addRow([]);
                                      const cell1 = row1.getCell(1);
                                      cell1.style.font = {bold: true};
                                      cell1.style.border = {top: {style: 'thin'}, bottom: {style: 'thin'}, left: {style: 'thin'}, right: {style: 'thin'}};
                                      cell1.style.alignment = {horizontal: 'center'};
                                      cell1.value = PGVal.PGName + ' Total';
                                     for(let i=2; i<=headers.length; i++) {
                                        const cell = row1.getCell(i);
                                        cell.border = {
                                        top: { style: "thick" },
                                        bottom: { style: "thick" },
                                       };
                                       cell.alignment = { horizontal: 'center'};
                                       cell.wrapText = true;
                                       cell.font = {bold: true}
                                       let CellWithoutTotal
                                       if (headers[i-1].includes("Total") || headers[i-1].includes("Value")) {
                                        if (headers[i-1].endsWith("Total")) {
                                         CellWithoutTotal = headers[i-1].replace("Total", "").trim();
                                    }else{
                                         CellWithoutTotal = headers[i-1];
                                        if(CellWithoutTotal === "Total Qty"){
                                            CellWithoutTotal = CellWithoutTotal === "Total Qty" ? "TotalQty" : CellWithoutTotal;
                                        }else if(headers[i-1] === "Value"){
                                            CellWithoutTotal = CellWithoutTotal === "Value" ?  "value" : CellWithoutTotal;
                                        }  
                                    }
                                        SubTotalsresult.map(val => {
                                            debugger
                                         if (val.PGName === PGVal.PGName && CellWithoutTotal in val) {
                                                cell.value = val[CellWithoutTotal];
                                                 }
                                            });
                                      
                                      
                                      } else {
                                        cell.value = "";
                                      }
                                       
                                       } 
                                   
                                  })

                                  const row1 = worksheet.addRow([]);
                                  const cell1 = row1.getCell(1);
                                  cell1.style.font = {bold: true};
                                  cell1.style.border = {top: {style: 'thin'}, bottom: {style: 'thin'}, left: {style: 'thin'}, right: {style: 'thin'}};
                                  cell1.style.alignment = {horizontal: 'center'};
                                  cell1.value = 'Grand Total';
                                 for(let i=2; i<=headers.length; i++) {
                                    const cell = row1.getCell(i);
                                    cell.border = {
                                    top: { style: "thick" },
                                    bottom: { style: "thick" },
                                   };
                                   cell.alignment = { horizontal: 'center'};
                                   cell.wrapText = true;
                                   cell.font = {bold: 'true'}
                                   let CellWithoutTotal
                                   
                                   if (headers[i-1].includes("Total") || headers[i-1].includes("Value")) {
                                    if (headers[i-1].endsWith("Total")) {
                                        CellWithoutTotal = headers[i-1].replace("Total", "").trim();
                                   }else{
                                        CellWithoutTotal = headers[i-1];
                                       if(CellWithoutTotal === "Total Qty"){
                                           CellWithoutTotal = CellWithoutTotal === "Total Qty" ? "TotalQty" : CellWithoutTotal;
                                       }else if(headers[i-1] === "Value"){
                                           CellWithoutTotal = CellWithoutTotal === "Value" ?  "value" : CellWithoutTotal;
                                       }  
                                   }
                                   const mARray = [GrandTotalsresult];
                                   mARray.map(val => {
                                    if (CellWithoutTotal in val) {
                                           cell.value = val[CellWithoutTotal];
                                            }
                                       });
                                  } else {
                                    cell.value = "";
                                  }
                                   
                                   } 
                           
                                
                              
                              
                                  worksheet.mergeCells('A1', 'H2');
                                  worksheet.getCell('A1').value = Comp_Name
                                  const cell = worksheet.getCell('A1');
                                  cell.alignment = { horizontal: 'center' };
                                 // Merge  2nd and style
                                 worksheet.mergeCells('A3', 'H3');
                                 const cell2 = worksheet.getCell('A3');
                                 cell2.alignment = { horizontal: 'center' };
                                 cell2.font = { size: 16, bold: true };
                                 //4rth
                                 worksheet.mergeCells('A4', 'C4');
                                 const cell3 = worksheet.getCell('A4');
                                 cell3.alignment = { horizontal: 'center' };
                                 cell3.font = { size: 14, bold: true };
                                 // delivery cell 
                                 worksheet.mergeCells('F4', 'H4');
                                 const cell4 = worksheet.getCell('F4')
                                 cell4.alignment = { horizontal: 'center' };
                                 cell4.font = { size: 14, bold: true };
                                 // From Date
                                 worksheet.mergeCells('A5', 'C5');
                                 const cell5 = worksheet.getCell('A5');
                                 cell5.alignment = { horizontal: 'center' };
                                 cell5.font = { size: 14, bold: true };
                                 // To Date 
                                 worksheet.mergeCells('F5', 'H5');
                                 const cell6 = worksheet.getCell('F5');
                                 cell6.alignment = { horizontal: 'center' };
                                 cell6.font = { size: 14, bold: true };

                        workbook.xlsx.writeBuffer().then(function (mData) {
                            const blob = new Blob([mData], {
                              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            });
                            const url = window.URL.createObjectURL(blob);
                            const anchor = document.createElement("a");
                            anchor.href = url;
                            anchor.download = "RptGroupWiseSale.xlsx";
                            anchor.click();
                            window.URL.revokeObjectURL(url);
                          });
                        //   setTimeout(() => {
                        //     DestributeQty(mData)
                        // }, 1000);
                        // setTimeout(() => {
                        //     DestributeTotalAndSubTotal(_PG_Array)
                        // }, 4000);
                        })

                }
                else {
                    setErr_state({ ...Err_state, Manufacturer_Err: 'PLease Select any Manufacturer' })
                   
                }
           
        }
        else {
            if(!SelectedProject) {
                setErr_state({ ...Err_state, Project_Err: 'required' })
              
            }
            else if(!Selected_ManufactuererBy_Data){
                setErr_state({ ...Err_state, Project_Err: '',Manufacturer_Err:'required' })
            }
            // else  if (Selected_ManufactuererBy_Data.ManufacturedByID == 0) {
            //     setErr_state({ ...Err_state, Manufacturer_Err: 'PLease Select any Manufacturer' })
            // }
            else if(!Selected_CG_Data){
                setErr_state({ ...Err_state, Project_Err: '',Manufacturer_Err:'',CompanyGroup_Err:'rquired' })
            }
            else if (!SelectedSaleRate){
                setErr_state({ ...Err_state, Project_Err: '',Manufacturer_Err:'',CompanyGroup_Err:'', SaleRate_Err:'required' })
            }
          
        } 
        }   else if (toPath == '/AreaWiseReport') {
            if (SelectedProject && Selected_PG_Data && Selected_ManufactuererBy_Data) {
              
                if (Selected_ManufactuererBy_Data.ManufacturedByID !== 0) {
                    rptAreaWiseSale(Comp_Id, SelectedProject.Project_ID,Selected_ManufactuererBy_Data.ManufacturedByID,Selected_PG_Data.PGID,FromDate, ToDate,(mData)=>{
                        
                        let subtotals = {};
                        // loop through the array and group by PGName
                        for (let i = 0; i < mData.length; i++) {
                          let item = mData[i];
                          
                          if (subtotals[item.PGName] === undefined) {
                            subtotals[item.PGName] = {};
                          }
                          if (subtotals[item.PGName][item.AreaName] === undefined) {
                            subtotals[item.PGName][item.AreaName] = 0;
                          }
                          // calculate subtotal for this item
                          let subtotal = item.Qty * item.SaleRate;
                          subtotals[item.PGName][item.AreaName] += subtotal;
                          // add to total Qty
                          if (subtotals[item.PGName].TotalQty === undefined) {
                            subtotals[item.PGName].TotalQty = 0;
                          }
                          subtotals[item.PGName].TotalQty += item.Qty;
                          // add to total value
                          if (subtotals[item.PGName].value === undefined) {
                            subtotals[item.PGName].value = 0;
                          }
                          subtotals[item.PGName].value += item.Value;
                        }
                        let SubTotalsresult = Object.entries(subtotals).map(([PGName, PGData]) => {
                            debugger
                            const subtotal = {};
                            Object.entries(PGData).forEach(([key, value]) => {
                              if (typeof value === 'number' && !isNaN(value)) {
                                subtotal[key] = (subtotal[key] || 0) + value;
                              }
                            });
                            return { PGName, ...subtotal };
                          });
                          
                         let GrandTotalsresult = Object.values(subtotals).reduce((acc, curr) => {
                             Object.entries(curr).forEach(([key, value]) => {
                                 if (typeof value === 'number' && !isNaN(value)) {
                                      acc[key] = (acc[key] || 0) + value;
                                                }
                                          });
                                     return acc;
                                } , {});
                        var _Header_Array = [], _Body_Array = [], _PG_Array = [];
                        mData.map((value, index) => {
                            var _IsFind = _Header_Array.find(x => x.AreaID == value.AreaID)
                            if (!_IsFind) {
                                _Header_Array.push(value)
                            }
                
                            var __isFind = _Body_Array.find(x => x.ItemID == value.ItemID)
                            if (!__isFind) {
                                _Body_Array.push(value)
                            }
                            var __isPGFind = _PG_Array.find(x => x.PGID == value.PGID)
                            if (!__isPGFind) {
                                _PG_Array.push(value)
                            }
                          
                                   })
                        _Body_Array.sort((a,b)=> a.ItemID - b.ItemID)
                        _Header_Array.sort((a,b)=> a.AreaID - b.AreaID)
                        var _AreaWiseReportData = JSON.parse(localStorage.getItem("AreaWiseReportData"))
                        if (_AreaWiseReportData) {
                            setCompanyGroup(_AreaWiseReportData.ManufacturedBy)
                        }
                        const workbook = new ExcelJS.Workbook();
                        const worksheet = workbook.addWorksheet("Area Wise Sale");
                        worksheet.columns = [
                          {
                            header: "Product Name",
                            key: "productName",
                            width: 50,
                          },
                        ];
                        const headers = ["Product Name", "TP"];
                        _Header_Array.forEach((header) => {
                          headers.push(
                            header.AreaName
                          );
                        });
                        headers.push("Total Qty", "Value");
                        worksheet.getRow(1).values = headers;
                        worksheet.getRow(1).eachCell((cell) => {
                            cell.border = {
                              top: { style: "thin" },
                              left: { style: "thin" },
                              bottom: { style: "thin" },
                              right: { style: "thin" },
                            };
                            cell.alignment = {
                              vertical: "middle",
                              horizontal: "center",
                            };
                          });
  
                          worksheet.columns.forEach(column => {
                             
                              column.width = column.width ||  10;
                              });

                        worksheet.spliceRows(1, 0, [Comp_Name]);
                        worksheet.spliceRows(2, 0, [Comp_Name]);
                        worksheet.spliceRows(3, 0, ["Area Wise Sale"]);
                        worksheet.spliceRows(4, 0, ["Project:   " + Comp_Name,  null, null,null, null,   "Company :  " +  Selected_PG_Data.PGName, ]);
                        worksheet.spliceRows(5, 0, [ "From Date:   " + FromDate,  null,null,null, null,  "To Date :  " + ToDate ]);
                        worksheet.getRow(1).font = {
                          bold: true,
                          size: 34,
                        };
                        worksheet.getRow(6).font = {
                          bold: true,
                          size: 14,
                        };
                        const row = worksheet.getRow(6);
                        row.height = 30
                           for (let i = 3; i <= headers.length; i++) {
                                       const cell = row.getCell(i);
                                       cell.border = {
                                       top: { style: "thick" },
                                       bottom: { style: "thick" },
                                      };
                                      cell.alignment = { horizontal: 'center'};
                                      cell.wrapText = true;
                                      cell.alignment.textRotation = 90;
                                      row.height = undefined 
                                  }
                                  let subTotalQty = 0;
                                  let subTotalValue = 0;
                                  let totalQty = 0;
                                  let totalValue = 0;
                                  _PG_Array.map(PGVal=>{
                                    const row = worksheet.addRow();
                                    const cell = row.getCell(1);
                                    cell.value = PGVal.PGName;
                                    cell.font = {bold: true, size: 26};
                                    cell.border = {
                                    top: {style: 'thin'},
                                    bottom: {style: 'thin'}
                                    };
                                    // Reset subtotals for each new PGVal
                                     subTotalQty = 0;
                                     subTotalValue = 0;
                                    _Body_Array.forEach((value, index) => {
                                        if (PGVal.PGID === value.PGID) {
                                          const row = worksheet.addRow();
                                          const cell = row.getCell(1);
                                          cell.value = value.ItemDiscription;
                                          cell.font = {bold: true};
                                          cell.border = {
                                            top: {style: 'thin'},
                                            bottom: {style: 'thin'},
                                            left: {style: 'thin'},
                                            right: {style: 'thin'}
                                          };
                                          cell.alignment = {wrapText: true, horizontal: 'left', vertical: 'top'};
                                          cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFFFFFFF'}, bgColor: {argb: 'FFFFFFFF'}};
                                      
                                          const cellTP = row.getCell(2);
                                          cellTP.value = value.SaleRate;
                                          cellTP.font = {bold: true};
                                          cellTP.border = {
                                            top: {style: 'thin'},
                                            bottom: {style: 'thin'}
                                          };
                                          cellTP.alignment = {horizontal: 'center'};
                                      
                                          _Header_Array.forEach((TValue, TIndex) => {
             
                                            const headerMap = {};
                                            worksheet.getRow(6).eachCell((cell, colNumber) => {
                                                headerMap[cell.value] = colNumber;
                                            });
                                            const cell = TValue.AreaName
                                            const colNumber = headerMap[cell];
                                            const cell1 = row.getCell(colNumber);
                                            const totQty = "Total Qty"
                                            const TotalQtyColNumber =  headerMap[totQty];
                                            const TotalQtyCell = row.getCell(TotalQtyColNumber)
                                            const totVal = "Value" 
                                            const TotalValue = headerMap[totVal];
                                            const valueCell = row.getCell(TotalValue)
                                            mData.forEach(mval =>{
                                                if(mval.ItemDiscription === value.ItemDiscription && mval.AreaName === cell){
                                                    debugger
                                                    if(mval.Qty){
                                                        cell1.value = mval.Qty   
                                                    }else{
                                                        cell1.value = 0
                                                    }
                                                    // cell1.value = mval.Qty === 0 || mval.Qty === null || mval.Qty === '' || mval.Qty === undefined || isNaN(mval.Qty) ? '0' : mval.Qty
                                                      
                                                }
                                            })
                                            let totalQty = 0;
                                            mData.forEach(mval => {
                                              if (mval.ItemDiscription === value.ItemDiscription) {
                                                const qty = mval.Qty === 0 || mval.Qty === null || mval.Qty === '' || mval.Qty === undefined || isNaN(mval.Qty) ? '0' : mval.Qty;
                                                totalQty += qty 
                                              }
                                            });
                                            let totalValue = 0;
                                            mData.forEach(mval => {
                                              if (mval.ItemDiscription === value.ItemDiscription) {
                                                const qty = mval.Qty === 0 || mval.Qty === null || mval.Qty === '' || mval.Qty === undefined || isNaN(mval.Qty) ? '0' : mval.Qty;
                                                totalValue += qty * value.SaleRate;
                                              }
                                            });
                                            
                                            TotalQtyCell.value = totalQty;
                                            
                                            valueCell.value = totalValue;
                                            cell1.border = {
                                                      top: {style: 'thin'},
                                                      bottom: {style: 'thin'},
                                                      left: {style: 'thin'},
                                                      right: {style: 'thin'}
                                                    };
                                                    cell1.alignment = {horizontal: 'center'};
                                                    TotalQtyCell.border = {
                                                        top: {style: 'thin'},
                                                        bottom: {style: 'thin'},
                                                        left: {style: 'thin'},
                                                        right: {style: 'thin'}
                                                      };
                                                      TotalQtyCell.alignment = {horizontal: 'center'};
                                          valueCell.font = {bold: true};
                                          valueCell.border = {
                                            top: {style: 'thin'},
                                            bottom: {style: 'thin'},
                                            left: {style: 'thin'},
                                            right: {style: 'thin'}
                                          };
                                          valueCell.alignment = {horizontal: 'center'};       
                                                
                                          });
                                      
                                        }
                                      }); 

                                      const row1 = worksheet.addRow([]);
                                      const cell1 = row1.getCell(1);
                                      cell1.style.font = {bold: true};
                                      cell1.style.border = {top: {style: 'thin'}, bottom: {style: 'thin'}, left: {style: 'thin'}, right: {style: 'thin'}};
                                      cell1.style.alignment = {horizontal: 'center'};
                                      cell1.value = PGVal.PGName + ' Total';
                                     for(let i=2; i<=headers.length; i++) {
                                        const cell = row1.getCell(i);
                                        cell.border = {
                                        top: { style: "thick" },
                                        bottom: { style: "thick" },
                                       };
                                       cell.alignment = { horizontal: 'center'};
                                       cell.alignment.textRotation = 90
                                       row.height = undefined
                                       cell.wrapText = true;
                                       cell.font = {bold: true}
                                       let CellWithoutTotal
                                         CellWithoutTotal = headers[i-1];
                                        if(CellWithoutTotal === "Total Qty"){
                                            CellWithoutTotal = CellWithoutTotal === "Total Qty" ? "TotalQty" : CellWithoutTotal;
                                        }else if(headers[i-1] === "Value"){
                                            CellWithoutTotal = CellWithoutTotal === "Value" ?  "value" : CellWithoutTotal;
                                        }  
                                        SubTotalsresult.map(val => {
                                         if (val.PGName === PGVal.PGName && CellWithoutTotal in val) {
                                                cell.value = val[CellWithoutTotal];
                                                 }
                                            });
                                       
                                       } 
                                   
                                  })
                                  const row1 = worksheet.addRow([]);
                                  const cell1 = row1.getCell(1);
                                  cell1.style.font = {bold: true};
                                  cell1.style.border = {top: {style: 'thin'}, bottom: {style: 'thin'}, left: {style: 'thin'}, right: {style: 'thin'}};
                                  cell1.style.alignment = {horizontal: 'center'};
                                  cell1.value = 'Grand Total';
                                 for(let i=2; i<=headers.length; i++) {
                                    const cell = row1.getCell(i);
                                    cell.border = {
                                    top: { style: "thick" },
                                    bottom: { style: "thick" },
                                   };
                                   cell.alignment = { horizontal: 'center'};
                                   cell.alignment.textRotation = 90
                                   row.height = undefined
                                   cell.wrapText = true;
                                   cell.font = {bold: 'true'}
                                   let CellWithoutTotal
                              
                                        CellWithoutTotal = headers[i-1];
                                       if(CellWithoutTotal === "Total Qty"){
                                           CellWithoutTotal = CellWithoutTotal === "Total Qty" ? "TotalQty" : CellWithoutTotal;
                                       }else if(headers[i-1] === "Value"){
                                           CellWithoutTotal = CellWithoutTotal === "Value" ?  "value" : CellWithoutTotal;
                                       }  
                                   const mARray = [GrandTotalsresult];
                                   mARray.map(val => {
                                    if (CellWithoutTotal in val) {
                                           cell.value = val[CellWithoutTotal];
                                            }
                                       });
                                   
                                   } 
                           
                                
                              
                              
                                  worksheet.mergeCells('A1', 'T2');
                                  worksheet.getCell('A1').value = Comp_Name
                                  const cell = worksheet.getCell('A1');
                                  cell.alignment = { horizontal: 'center' };
                                 // Merge  2nd and style
                                 worksheet.mergeCells('A3', 'T3');
                                 const cell2 = worksheet.getCell('A3');
                                 cell2.alignment = { horizontal: 'center' };
                                 cell2.font = { size: 16, bold: true };
                                 //4rth
                                 worksheet.mergeCells('A4', 'C4');
                                 const cell3 = worksheet.getCell('A4');
                                 cell3.alignment = { horizontal: 'center' };
                                 cell3.font = { size: 14, bold: true };
                                 // delivery cell 
                                 worksheet.mergeCells('F4', 'H4');
                                 const cell4 = worksheet.getCell('F4')
                                 cell4.alignment = { horizontal: 'center' };
                                 cell4.font = { size: 14, bold: true };
                                 // From Date
                                 worksheet.mergeCells('A5', 'C5');
                                 const cell5 = worksheet.getCell('A5');
                                 cell5.alignment = { horizontal: 'center' };
                                 cell5.font = { size: 14, bold: true };
                                 // To Date 
                                 worksheet.mergeCells('F5', 'H5');
                                 const cell6 = worksheet.getCell('F5');
                                 cell6.alignment = { horizontal: 'center' };
                                 cell6.font = { size: 14, bold: true };

                        workbook.xlsx.writeBuffer().then(function (mData) {
                            const blob = new Blob([mData], {
                              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            });
                            const url = window.URL.createObjectURL(blob);
                            const anchor = document.createElement("a");
                            anchor.href = url;
                            anchor.download = "RptAreaWiseSale.xlsx";
                            anchor.click();
                            window.URL.revokeObjectURL(url);
                          });

                    })
                }
                else {
                    setErr_state({ ...Err_state, Manufacturer_Err: 'PLease Select any Manufacturer' })
                   
                }
            }
            else {

                if (!SelectedProject) {
                    setErr_state({ ...Err_state, Project_Err: 'required' })
                }
                else if (!Selected_ManufactuererBy_Data) {
                    setErr_state({ ...Err_state, Project_Err: '', Manufacturer_Err: 'required' })
                }
                else if (!Selected_PG_Data) {
                    setErr_state({ ...Err_state, Project_Err: '', Manufacturer_Err: '', CompanyGroup_Err: 'required' })
                }
                
            }
        }
    }
   
    const ResetCotrols = () => {
        setProject_List([])
        setSelectedProject(null)
        setFromDate(GetCurrentDate())
        setToDate(GetCurrentDate())
        setCustomer_List([])
        setSelectedCustomer(null)
        setfilter(null)
        setFinYearData([])
        setSelectedFinData(null)
        setReportPath('#')
        setItemList([])
        setSelectedFromItem(null)
        setSelectedToItem(null)
        setMTarget('')
        setManufactuererBy_Data([])
        setSelected_ManufactuererBy_Data(null)
        setCityData([])
        setSelectedCity(null)
        setAreaData([])
        setSelectedArea(null)
        setSelectedBatch(null)
        setBatchData([])
        setSelectedWarehouse(null)
        setDeliveryList([])
        setSelectedDelivery(null)
        setCG_Data([])
        setSelected_CG_Data(null)
        setInvList([])
        setSelectedFromInvNo(null)
        setSelectedToInvNo(null)
        setSelectedSaleRate(null)
        setPG_Data([])
        setSelected_PG_Data(null)
        setErr_state({
            CG_Err: '',
            Manufacturer_Err: '',
            CompanyGroup_Err: '',
            Project_Err: '',
            FromItem_Err: '',
            Batch_Err: '',
            City_Err: '',
            Area_Err: '',
            Customer_Err: '',
            DElivery_Err: '',
            FromInv_Err: '',
            ToInv_Err: '',
            SaleRate_Err:'',
        })
    }
    const SaleRateOptions = [
        {title : 'TP'},
        {title: 'DP'}
    ]

    return (
        <>
            <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" id="Purchase_Modal" >
                <Modal.Header  className="header_title" >
                    <Modal.Title> Distribution Reports / {title} </Modal.Title>
                </Modal.Header> 
                <Modal.Body id='mBody'>
                    <Card>
                        <Row className="row_margin_left row_margin_right" >
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <TextField
                                    name="FromDate"
                                    label="From Date"
                                    type='date'
                                    value={FromDate}
                                    onChange={e => {
                                        setFromDate(e.target.value)
                                        Select_Invoices(SelectedProject.Project_ID, mTr_Type, e.target.value, ToDate)
                                    }}
                                    fullWidth
                                    inputProps={{
                                        readOnly: readonlyProps.FromDate
                                    }
                                    }
                                />
                            </Col>
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <TextField
                                    name="ToDate"
                                    label="To Date"
                                    type='date'
                                    value={ToDate}
                                    onChange={e =>{
                                        setToDate(e.target.value)
                                        Select_Invoices(SelectedProject.Project_ID, mTr_Type, FromDate,e.target.value)
                                    } }
                                    fullWidth
                                    inputProps={{
                                        readOnly: readonlyProps.ToDate
                                    }
                                    }
                                />
                            </Col>
                            {/* <Col xl={4} lg={4} md={4} sm={12}>
                                    
                                    <Autocomplete
                                    name="FinYear"
                                    id="FinYear"
                                    options={FinYearData}
                                    getOptionLabel={(option) => option.fin_year ? option.fin_year : ""}
                                    value={SelectedFinData}
                                    disabled={readonlyProps.finYear}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedFinData(value)
                                        }
                                        else{
                                            setSelectedFinData(null)
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="FinYear" label="Fin Year"
                                        />
                                    }
                                />
                                </Col> */}
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="Project"
                                    id="Project"
                                    options={Project_List}
                                    getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                                    value={SelectedProject}
                                    disabled={readonlyProps.Project}
                                    onChange={(e, value) => {
                                        debugger
                                        if (value) {
                                            setSelectedProject(value)
                                            SelectManufacturedBy_List(value.Project_ID)
                                            Select_Item(value.Project_ID)
                                            Select_Customer(value.Project_ID)
                                            Select_City(value.Project_ID)
                                            Select_WareHOuse(value.Project_ID)
                                            Select_Delivery(value.Project_ID)
                                            Select_CG(value.Project_ID)
                                          
                                            if (mTr_Type) {
                                                debugger
                                                Select_Invoices(value.Project_ID, mTr_Type, FromDate, ToDate)
                                            }
                                        }
                                        else {
                                            setSelectedProject(null)
                                            setManufactuererBy_Data([])
                                            setSelected_ManufactuererBy_Data(null)
                                            setItemList([])
                                            setCustomer_List([])
                                            setSelectedCustomer(null)
                                            setSelectedFromItem(null)
                                            // setSelectedToItem(null)
                                            setCityData([])
                                            setSelectedCity(null)
                                            setAreaData([])
                                            setSelectedArea(null)
                                            setSelectedBatch(null)
                                            setBatchData([])
                                            setSelectedWarehouse(null)
                                            setDeliveryList([])
                                            setSelectedDelivery(null)
                                            setCG_Data([])
                                            setSelected_CG_Data(null)
                                            setInvList([])
                                            setSelectedFromInvNo(null)
                                            setSelectedToInvNo(null)
                                          
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="Project" label="Project"
                                        />
                                    }
                                />
                                <span className="text-danger">{Err_state.Project_Err}</span>
                            </Col>
                        </Row>
                        <Row className=" row_margin_left row_margin_right" >
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="ManufacturedBy"
                                    id="ManufacturedBy"
                                    options={ManufactuererBy_Data}
                                    getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""}
                                    value={Selected_ManufactuererBy_Data}
                                    disabled={readonlyProps.ManufacturedBy}
                                    onChange={(e, value) => {

                                        if (value) {
                                            setSelected_ManufactuererBy_Data(value)
                                            setCG_Data([])
                                            setSelected_CG_Data(null)
                                            SelectCompanyGroupAgainstManufacturer(Comp_Id, SelectedProject.Project_ID, value.ManufacturedByID, (CGData) => {
                                                if (CGData.length > 0) {
                                                    var FirstIndex = {
                                                        CGID: 0,
                                                        CGName: 'SELECT ALL'
                                                    }
                                                    CGData.splice(0, 0, FirstIndex)
                                                    setSelected_CG_Data(FirstIndex)
                                                }
                                                setCG_Data(CGData)
                                            })
                                            SelectProduct_GroupAgainstManufacturer( SelectedProject.Project_ID,value.ManufacturedByID)
                                        }
                                        else {
                                            setSelected_ManufactuererBy_Data(null)
                                            setCG_Data([])
                                            setSelected_CG_Data(null)
                                            setPG_Data([])
                                            setSelected_PG_Data(null)
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="ManufacturedBy" id="ManufacturedBy" label="Manufactured By"
                                        />}

                                />
                                <span className="text-danger">{Err_state.Manufacturer_Err}</span>
                            </Col>
                      
                            <Col sm={12} lg={4} xl={4} md={4} >
                            {toPath != '/TownWiseReport' && toPath != '/GroupWiseSale' && toPath != '/SaleAndStockReport' ? 
                            <>
                                <Autocomplete
                                    name="CG"
                                    id="CG"
                                    options={CG_Data}
                                    getOptionLabel={(option) => option.CGName ? option.CGName : ""}
                                    value={Selected_CG_Data}
                                    disabled={readonlyProps.CGID}
                                    onChange={(e, value) => {

                                        if (value) {
                                            setSelected_CG_Data(value)
                                        }
                                        else {
                                            setSelected_CG_Data(null)
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="CG" id="CG" label="Company Gruop"
                                        />}

                                />
                                <span className="text-danger">{Err_state.CompanyGroup_Err}</span>
                                </>
                            : 
                            <>
                            <Autocomplete
                                name="PG"
                                id="PG"
                                options={PG_Data}
                                getOptionLabel={(option) => option.PGName ? option.PGName : ""}
                                value={Selected_PG_Data}
                                onChange={(e, value) => {

                                    if (value) {
                                        setSelected_PG_Data(value)
                                    }
                                    else {
                                        setSelected_PG_Data(null)
                                    }

                                }}
                                renderInput={(params) =>
                                    <TextField {...params} name="PG" id="PG" label="Product Gruop"
                                    />}

                            />
                            <span className="text-danger">{Err_state.CompanyGroup_Err}</span>
                            </>

                        }

                            </Col>
                            <Col sm={12} lg={4} xl={4} md={4} >
                            {toPath !== '/CustomerWiseSAleRegisterReport' ? 
                            <>
                                <Autocomplete
                                    name="SaleRate"
                                    id="SaleRate"
                                    options={SaleRateOptions}
                                    getOptionLabel={(option) => option.title ? option.title : ""}
                                    value={SelectedSaleRate}
                                    disabled={readonlyProps.SaleRate}
                                    onChange={(e, value) => {

                                        if (value) {
                                            setSelectedSaleRate(value)
                                        }
                                        else {
                                            setSelectedSaleRate(null)
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="SaleRate" id="SaleRate" label="Sale Rate"
                                        />}

                                />
                                <span className="text-danger">{Err_state.SaleRate_Err}</span>
                                </>
                                : 
                              
                             
                                <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isDisPer}
                                    id="isDisPer"
                                    label="isDisPer"
                                    disabled = {chkForDis}
                                    onChange={e=> {setIsDisPer(e.target.checked)
                                    setChkForBonus(true)
                                    setChkForDis(false)
                                    if(!e.target.checked){
                                        setChkForBonus(false)
                                    }
                                    }}
                                    name="Access"
                                    color="primary"
                                />
                            }
                            label={"With Dis %"}
                            style={{
                                padding: "0px",
                                marginTop: "7%"
                            }}
                        />
                        
                      
              
                            }
                            </Col>
                        </Row>
                        <Row className=" row_margin_left row_margin_right" >
                            <Col sm={12} lg={4} xl={4} md={4} >
                            {toPath !== '/CustomerWiseSAleRegisterReport' ? 
                            <>
                                <Autocomplete
                                    name="FromItem"
                                    id="FromItem"
                                    options={ItemList}
                                    getOptionLabel={(option) => option.ItemName ? option.ItemName : ""}
                                    value={SelectedFromItem}
                                    disabled={readonlyProps.FromItem}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedFromItem(value)
                                            SelectBatchDetails(Comp_Id, SelectedProject.Project_ID, value.ItemID, SelectedWarehouse && SelectedWarehouse.WareHouseID, (mBathes) => {
                                                setBatchData(mBathes)
                                            })
                                        }
                                        else {
                                            setSelectedFromItem(null)
                                            setSelectedBatch(null)
                                            setBatchData([])
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="FromItem" label="Item"
                                        />
                                    }
                                />
                                <span className="text-danger">{Err_state.FromItem_Err}</span>
                                </>
                                : 
                            
                                <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isWithBonus}
                                        id="isWithBonus"
                                        label="isWithBonus"
                                        disabled = {chkForBonus}
                                        onChange={e=> {
                                            setIsWithBonus(e.target.checked)
                                            setChkForBonus(false)
                                    setChkForDis(true)
                                    if(!e.target.checked){
                                        setChkForDis(false)
                                    }
                                        }}
                                        name="Access"
                                        color="primary"
                                    />
                                }
                                label={"With Bonus"}
                                style={{
                                    padding: "0px",
                                    marginTop: "7%"
                                }}
                            />
                            }
                            </Col>
                            <Col>
                                <Autocomplete
                                    name="BatchNo"
                                    id="BatchNo"
                                    value={SelectedBatch}
                                    options={BatchData}
                                    disabled={readonlyProps.BatchNo}
                                    getOptionLabel={(option) => option.batchno}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedBatch(value)
                                        }
                                        else {
                                            setSelectedBatch(null)
                                        }
                                    }}
                                    renderInput={(params) =>
                                        <TextField  {...params} name="BatchNo" id='BatchNo' label="Batch No" />}
                                />
                                <span className="text-danger">{Err_state.Batch_Err}</span>

                            </Col>
                            <Col lg={4} xl={4} md={4} >
                                <Autocomplete
                                    id="City"
                                    name="City"
                                    options={CityData}
                                    value={SelectedCity}
                                    disabled={readonlyProps.City}
                                    getOptionLabel={(option) => option.City ? option.City : ""}
                                    onChange={(e, value) => {

                                        if (value) {
                                            setSelectedCity(value)
                                            SelectCityWiseAreas(Comp_Id, SelectedProject.Project_ID, value.CityID, (mareas) => {
                                                if (mareas.length > 0) {
                                                    var FirstIndex = {
                                                        AreaName: 'SELECT ALL',
                                                        AreaID: 0
                                                    }
                                                    mareas.splice(0, 0, FirstIndex)
                                                    setSelectedArea(FirstIndex)
                                                }
                                                setAreaData(mareas)
                                            })
                                        }
                                        else {
                                            setSelectedCity(null)
                                            setAreaData([])
                                            setSelectedArea(null)
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} name="City" label="City" />}
                                />
                                <span className="text-danger">{Err_state.City_Err}</span>

                            </Col>
                            <Col lg={4} xl={4} md={4} >
                                <Autocomplete
                                    id="Area"
                                    name="Area"
                                    options={AreaData}
                                    value={SelectedArea}
                                    disabled={readonlyProps.Area}
                                    getOptionLabel={(option) => option.AreaName ? option.AreaName : ""}
                                    onChange={(e, value) => {

                                        if (value) {
                                            setSelectedArea(value)
                                        }
                                        else {
                                            setSelectedArea(null)
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} name="Area" label="Area" />}
                                />
                                <span className="text-danger">{Err_state.Area_Err}</span>

                            </Col>
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="Customer"
                                    id="Customer"
                                    options={Customer_List}
                                    getOptionLabel={(option) => option.CustomerName ? option.CustomerName : ""}
                                    value={SelectedCustomer}
                                    disabled={readonlyProps.Customer}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedCustomer(value)
                                        }
                                        else
                                            setSelectedCustomer(null)

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="Customer" label="Customer"
                                        />
                                    }
                                />
                                <span className="text-danger">{Err_state.Customer_Err}</span>
                            </Col>
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="Delivery"
                                    id="Delivery"
                                    options={DeliveryList}
                                    getOptionLabel={(option) => option.DeliveryNameWithCode ? option.DeliveryNameWithCode : ""}
                                    value={SelectedDelivery}
                                    disabled={readonlyProps.Delivery}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedDelivery(value)
                                        }
                                        else
                                            setSelectedDelivery(null)

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="Delivery" label="Delivery"
                                        />
                                    }
                                />
                                <span className="text-danger">{Err_state.DElivery_Err}</span>

                            </Col>
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="FromInvNo"
                                    id="FromInvNo"
                                    options={InvList}
                                    getOptionLabel={(option) => option.SaleInvoiceNo ? option.SaleInvoiceNo : ""}
                                    value={SelectedFromInvNo}
                                    disabled={readonlyProps.SaleInvNoFrom}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedFromInvNo(value)
                                        }
                                        else
                                            setSelectedFromInvNo(null)

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="FromInvNo" label="FromInvNo"
                                        />
                                    }
                                />
                                <span className="text-danger">{Err_state.FromInv_Err}</span>
                            </Col>
                            <Col sm={12} lg={4} xl={4} md={4} >
                                <Autocomplete
                                    name="ToInvNo"
                                    id="ToInvNo"
                                    options={InvList}
                                    getOptionLabel={(option) => option.SaleInvoiceNo ? option.SaleInvoiceNo : ""}
                                    value={SelectedToInvNo}
                                    disabled={readonlyProps.SaleInvNoTo}
                                    onChange={(e, value) => {
                                        if (value) {
                                            setSelectedToInvNo(value)
                                        }
                                        else
                                            setSelectedToInvNo(null)

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="ToInvNo" label="ToInvNo"
                                        />
                                    }
                                />
                                <span className="text-danger">{Err_state.ToInv_Err}</span>
                            </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                <button className="btn btn-primary rounded-pill"  style={{ border: "none" }} onClick={NavigateToReport} > <Loop /> <u>Load</u></button>
                                <button className="btn btn-primary rounded-pill"  style={{ border: "none" }} disabled={readonlyProps.Export} onClick= {ExportReport}  > <CloudDownload /> <u>Export To Excel</u></button>
                                <button type="button" className="btn btn-primary rounded-pill" onClick={e => {
                                    ResetCotrols()
                                    onHide()
                                }} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
            </Modal>
            <Loader
            show={IsLoader}
            hide={hideLoader}
            />
        </>
    )
}

const mapStateToProps = state => ({
    Comp_Id: state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name: state.GlobalVariables.Company && state.GlobalVariables.Company.Name,
    Comp_Address: state.GlobalVariables.Company && state.GlobalVariables.Company.Address,
    User_Id: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    UserType: state.Login_User.User_Data && state.Login_User.User_Data.User_Type
})
export default connect(mapStateToProps)(DistributionReport_Modal)
