import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { GetFileFromLocalDirectory } from '../../Services/ControlPanelAPI';
import { SelectSaleInvoiceDetail, SelectSaleInvoiceMaster } from '../../Services/SaleInvoiceAPI';
import { GetCurrentDate, convertNumberToWords } from '../../Services/Common';
import './InvoicePrint.css';

const SaleInvA4UAE = ({UserPic,Comp_Name,Comp_Id,ProjectID,User_Name,User_Id,Comp_Phone,Comp_Address,PhoneNo,SaleInvoiceID, InvoiceType}) => {
        const [Logo, setLogo] = useState('')
        const [InvoiceNo, setInvoiceNo] = useState("")
            const [InvoiceDate, setInvoiceDate] = useState(GetCurrentDate())
            const [CreatedDate, setCreatedDate] = useState(GetCurrentDate())
            const [NTN, setNTN] = useState("")
            const [VATPer, setVATPer] = useState(0)
            const [VATAmt, setVATAmt] = useState(0)
            const [GrandTotal, setGrandTotal] = useState("")
            const [CustomerName, setCustomerName] = useState("")
            const [Total, setTotal] = useState(0)
            const [Phone_No, setPhone_No] = useState("")
            const [SaleInvoiceDetails, setSaleInvoiceDetails] = useState([])
            let TotalVal = 0;
            let TotalVATAmt = 0
            let TotalQty = 0
            let FinalAmt = 0
    useEffect(()=>{
        document.title = " Distribution / Sale Invoice"
        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('footer').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;
        FetchLogo()
        let InvType = InvoiceType
        if(InvType === "SaleInvoice"){
            InvType = "Sale Invoice"
        }else if(InvType === "EstimateInvoice"){
            InvType = "Estimate Invoice"
        }
        SelectSaleInvoiceMaster(Comp_Id, ProjectID,SaleInvoiceID,InvType, (mData)=>{
            
            setInvoiceNo(mData[0].SaleInvoiceNo)
            const formattedDate = new Date(mData[0].InvoiceDate).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              });
            setInvoiceDate(formattedDate)
            const formatted_Date = new Date(mData[0].CreatedDate).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              });
            setCreatedDate(formatted_Date)
            setTotal(mData[0].GrandTotal)
            setVATAmt(mData[0].VATAmt)
            let mVal = convertNumberToWords(mData[0].GrandTotal)
            setGrandTotal(mVal)
            setCustomerName(mData[0].CustomerName)
            setPhone_No(mData[0].PhoneNo)
            setNTN(mData[0].NTN)
        })
        SelectSaleInvoiceDetail(Comp_Id, ProjectID,SaleInvoiceID,InvType,(mDetailsData)=>{
            setVATPer(mDetailsData[0].TaxPer)
            setSaleInvoiceDetails(mDetailsData)
        })
    },[])
    const FetchLogo = async()=>{
        let marr =UserPic.split('/')
        UserPic = marr[marr.length - 1]
        const mPic = await GetFileFromLocalDirectory(UserPic)
        setLogo(mPic)
    }
    const styles = {
        MainContainer:{
            padding: 16,
            color: 'black',
            marginTop: 20,
        },
        CompanyInfo: {
          textAlign: 'right',
          fontSize: '1.4em',
          fontWeight: 'bold',
          whiteSpace: 'pre-line',
        },
        HeaderRow:{
            display: 'flex',
            flexDirection: 'row',
            justifyContent:'space-between',
            alignItems: 'center',
        },
        InvInfo:{
            marginTop: '10px',
            fontSize: '1.2em',
            fontWeight: 'bold',
        },
        BalanceDue:{
            fontSize: '1.4em',
            fontWeight: 'bold',

        },
        logoArea:{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            
        },
        box: {
            flex: 1,
            width: '100%',
          },
        TRNText:{
            flex: 1,
            fontWeight:'bold',
            textAlign: 'center',
            fontSize: '1em',
        },
        CustomerSections:{
            display: 'flex',
            flexDirection: 'row',
            justifyContent:'space-between',
        },
        DateSection:{
            marginTop: '10px',
            fontSize: '1.2em',
        },
        dateLabel: {
            marginRight: '30px',
            display: 'inline-block',
          },
          dateValue: {
            float: 'right',
            display: 'inline-block', 
          },
          itemsTable: {
            width: '100%',
            marginTop: '15px',
            borderCollapse: 'collapse',
          },
          tableHeader: {
            background: '#50927C',
            color: '#fff',
          },
          tableCell: {
              border: 'none',
              padding: '8px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'normal', // Ensure the text doesn't wrap to the next line
              borderBottom: '1px solid #c1c1c1'
            },
        itemCell:{
            width:'50%'
        },
        otherAllcells:{
            width:'10%'
        },
        SubTotal:{
            fontSize: '1em',
            fontWeight: 'bold',
        },
        TaxCell:{
            width:'60%'
        },
        otherAllTaxcells:{
            width:'10%'
        },
        paymentTerms: {
            marginTop: '10px',
            fontSize: '1.2em',
            fontStyle: 'italic',
          },
          footer:{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            justifyContent:'space-around',
            marginTop: 'auto',
            borderTop: '1px solid #c1c1c1',
            bottom: 0, 
            width: '100%', 
            padding: '10px', 
            background: 'white', 
          },
      };
    
  return (
   <div style={styles.MainContainer}>
    <div style={styles.HeaderRow}>
        <div style={{...styles.InvInfo, ...styles.box}}>
            <div>TAX INVOICE</div>
            <div>Invoice No : {InvoiceNo}</div>
            <div>Balance Due</div>
            <div style={styles.BalanceDue}>AED {Total}</div>
        </div>
        <div style={{...styles.logoArea, ...styles.box}}>
        <img src={Logo} width='150' height={150}
          alt='NO LOGO' />
        <span style={styles.TRNText}>{NTN}</span>
        </div>
        <div style={{...styles.CompanyInfo,...styles.box}}>
                {Comp_Id === 6 ? (
            <>
            بن سلمان للتجارة العامة {'\n'}
            {Comp_Name} {'\n'}
            United Arab Emirates {'\n'}
            الامارات العربية المتحدة {'\n'}
            </> 
        ) : (
            <>
            {Comp_Name} {'\n'}
            </>
        )}
        </div>
    </div>
    <div style={styles.CustomerSections}>
        <div style={styles.DateSection}>
            <div>
                <span style={styles.dateLabel}>Invoice Date:</span>
                <span style={styles.dateValue}>{InvoiceDate}</span>
            </div>
            <div>
                <span  style={styles.dateLabel}>Created Date:</span>
                <span style={styles.dateValue}>{CreatedDate}</span>
            </div>
        </div>
        <div style={styles.DateSection}>
                <span>Bill To,</span>
                <div><b>Customer AC No: {Phone_No}</b></div>
                <div>Customer Name:{CustomerName}</div>
                <div>TRN: {NTN}</div>
        </div>
    </div>
    <div style={styles.InvoiceTable}>
    <table style={styles.itemsTable}>
        <thead style={styles.tableHeader}>
          <tr>
            <th style={{...styles.tableCell, ...styles.itemCell}}>Item Discription</th>
            <th style={{...styles.tableCell, ...styles.otherAllcells}}>Qty</th>
            <th style={{...styles.tableCell, ...styles.otherAllcells}}>Rate</th>
            <th style={{...styles.tableCell, ...styles.otherAllcells}}>VAT %</th>
            <th style={{...styles.tableCell, ...styles.otherAllcells}}>VAT Amt</th>
            <th style={{...styles.tableCell, ...styles.otherAllcells}}>Amount</th>
          </tr>
        </thead>
        <tbody>
            {
                SaleInvoiceDetails.map((val)=>{
                        TotalVal += val.Qty * val.SaleRate
                        TotalVATAmt += val.TaxAmt
                        TotalQty +=  val.Qty
                    return (
                        <tr>
                           <td style={{...styles.tableCell, ...styles.otherAllcells}}>{val.ItemDiscription}</td>
                           <td style={{...styles.tableCell, ...styles.otherAllcells}}>{val.Qty}</td>
                           <td style={{...styles.tableCell, ...styles.otherAllcells}}>{val.SaleRate}</td>
                           <td style={{...styles.tableCell, ...styles.otherAllcells}}>{val.TaxPer}</td>
                           <td style={{...styles.tableCell, ...styles.otherAllcells}}>{val.TaxAmt}</td>
                           <td style={{...styles.tableCell, ...styles.otherAllcells}}>{val.Qty * val.SaleRate}</td>
                    </tr>
                            )
                })
            }
        
        
        <tr>
               <td style={styles.tableCell}></td>
               <td style={styles.tableCell}></td>
               <td style={styles.tableCell}></td>
               <td style={{...styles.tableCell, ...styles.SubTotal}}>Sub Total</td>
               <td style={{...styles.tableCell, ...styles.SubTotal}}>{TotalVATAmt}</td>
               <td style={{...styles.tableCell, ...styles.SubTotal}}>{TotalVal}</td>
        </tr>
         <span>Items in total {TotalQty}</span>
        </tbody>
      </table>
    </div>
    <div style={{ display: 'flex', flex: 1, textAlign: 'right', width: '80%' }}>
            <span style={{ width: '100%', overflowWrap: 'break-word', textAlign: 'right',fontSize:'1.2em' }}>
                Total in words: 
                <strong style={{fontSize:'1.2em'}}>UAE Dirham <br /> {GrandTotal}</strong>
            </span>
    </div>
    <span style={{fontSize:'1.2em',fontWeight:'bold', color:'#c1c1c1'}}>Tax Summary</span>
    <div style={styles.InvoiceTable}>
    <table style={styles.itemsTable}>
        <thead style={styles.tableHeader}>
          <tr>
            <th style={{...styles.tableCell, ...styles.TaxCell}}>Tax Details</th>
            <th style={{...styles.tableCell, ...styles.otherAllTaxcells}}>Taxable Amount (AED)</th>
            <th style={{...styles.tableCell, ...styles.otherAllTaxcells}}>Tax Amount (AED)</th>
            <th style={{...styles.tableCell, ...styles.otherAllTaxcells}}>Total Amount (AED)</th>
          </tr>
        </thead>
        <tbody>
        <tr>
               <td style={{...styles.tableCell, ...styles.otherAllTaxcells}}>Standard Tax ({VATPer}%)</td>
               <td style={{...styles.tableCell, ...styles.otherAllTaxcells}}>{TotalVal}</td>
               <td style={{...styles.tableCell, ...styles.otherAllTaxcells}}>{TotalVATAmt}</td>
               <td style={{...styles.tableCell, ...styles.otherAllTaxcells}}>{parseFloat(TotalVal + TotalVATAmt).toFixed(2)}</td>
        </tr>
        <tr>
               <td  style={{...styles.tableCell, ...styles.SubTotal}}>Total</td>
               <td style={{...styles.tableCell, ...styles.SubTotal}}>{TotalVal}</td>
               <td style={{...styles.tableCell, ...styles.SubTotal}}>{TotalVATAmt}</td>
               <td style={{...styles.tableCell, ...styles.SubTotal}}>{parseFloat(TotalVal + TotalVATAmt).toFixed(2)}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div style={styles.paymentTerms}>
             <b>Payment Methods:</b> 
             <ul>
                <li>Cash on delivery</li>
                <li>Cheques payable to <b> Bin Sulman General Trading </b>Fze </li>
                <li>Bank Name: Mashreq Bank</li>
                <li>Account number: 019101308725</li>
                <li>IBAN Number: AE490330000019101308725</li>
                <li>SWIFT Code: BOMLAEAD</li>
                <li><b> Credit Card Payments By POS Terminal</b></li>
                <li><b>Pay By NFC & Link ( Apple Pay , G Pay )</b></li>

             </ul>
      </div>
      <div style={styles.paymentTerms}>
        <ul>
            <li>No Returns Because Of Food Products Quality Assurance.</li>
            <li>Free Delivery</li>
        </ul>
        <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}>
            <div>Note:- This is System Generated invoice Doesn't Require
             Company Stamp and Signature</div>
             <div>
               <strong style={{borderTop:'2px solid black' }}>Reciver Signature and Stamp</strong> 
             </div>
        </div>
        
      </div>
      <div style={styles.footer}>
        <div><strong>Sales@BinsulmanGroup.com</strong></div>
        <div>Bin Sulman Group(Health is Wealth)</div>
        <div><strong>{PhoneNo}</strong></div>
    </div>
   </div>
  )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    Comp_Phone:state.GlobalVariables &&  state.GlobalVariables.Company.PhoneNo,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    UserPic: state.Login_User && state.Login_User.User_Data.UserPic,
    PhoneNo: state.Login_User && state.Login_User.User_Data.PhoneNo,
    ProjectID: state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
})

export default connect(mapStateToProps)(SaleInvA4UAE)