import React,{ useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import {Row, Col} from 'react-bootstrap'
import {connect} from 'react-redux'
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import { Edit, Add } from "@material-ui/icons";
import {Card, Typography} from '@material-ui/core';

import { SelectSecurityGroupsList } from '../../Services/SecurityAPI'
import { setUserAccessData } from "../../React_Redux/Actions"
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";

function UserAccessList({setUserAccessData}) {
    const [UserAccess, setUserAccess] = useState([])
     

    useEffect(() => {
      SelectSecurityGroupsList((mGroups)=>{
        setUserAccess(mGroups)
      })
    }, [])
    document.title = "Distribution / Users Access"
    const UserAccessColumns = [
        {
         name: "Group_Id",
         label: "Group ID",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "12px"}} >{columnMeta.label}</div>
            )
           }, 
         }
        },
        {
         name: "Description",
         label: "Description",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "12px"}} >{columnMeta.label}</div>
            )
           },
         }
        },
         {
          name: "Action",
          label: "Action",
          options: {
           filter: false,
           sort: false,
           customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "12px"}} >{columnMeta.name}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) => {
             var Group_Id = UserAccess
             if(Group_Id[rowIndex.rowIndex] != null)
            return (
              <Link to={"/UserAccess"} onClick={e=> edit_UserAccess(UserAccess[rowIndex.rowIndex].Group_Id)}> <Edit /> </Link> 
            );
          } 
          }
         },
         
       ];
const edit_UserAccess = (Group_Id) =>{
  setUserAccessData(null,Group_Id,false)
}

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
      }
    }
  }
});
    return ReactDOM.createPortal(
        <>
            <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2> User Access
                    </h2>
                </div>
            </div>
        </div>
          <Card>
              <Row className=" row_margin_left row_margin_right mt-4 mr-4 ml-4" >
                <Col xl={10} lg={10} md={8} sm={8} ></Col>
                <Col xl={2} lg={2} md={3} sm={4} style={{textAlign: "right"}} >
                    <Link to={"/UserAccess"} className="btn btn-primary waves-effect rounded-pill" onClick={e=>setUserAccessData(null,null,true)} ><Add/>Add New</Link>
                </Col>
            </Row>
            
              <Row className="row_margin_left row_margin_right " >
                <Col>
                  <div className="table-responsive" >
                  <MuiThemeProvider  theme={theme}>
                      <MUIDataTable
                          style={{size: "200px"}}
                          data={UserAccess}
                          columns={UserAccessColumns}
                          options={{
                          selectableRows: 'none',
                          print: false,
                          search: true,
                          download: false,
                          viewColumns: false,
                          filter: false
                          }}
                      />
                        </MuiThemeProvider>
                  </div>
                </Col>
              </Row>
        </Card>
                 
        </>, document.getElementById('PageContents')
    )
}

const mapStateToProps = state =>({
  CompID: state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Gl_year: state.GlobalVariables.Financial_Year && state.GlobalVariables.Financial_Year.fin_year,
  User: state.Login_User.User_Data,
})

export default connect(mapStateToProps,{ setUserAccessData })(UserAccessList)
