import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit } from '@material-ui/icons'
import  { connect } from 'react-redux'
import {TextField, Popper} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { SelectPendingPurchaseOrderList } from '../../Services/PurchaseOrderAPI'
import { SelectSupplierList } from '../../Services/SuppliersAPI'
import  { set_PO, SHOW_SCREEN } from '../../React_Redux/Actions'
import { SelectUserProjects } from "../../Services/Projects"
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import Loader from '../Loader';


const PendingPurchaseOrderList = (props) => {
    const {  set_PO,ItemCat,Purchase_Order , Comp_Id,p_userid, Group_Id, SHOW_SCREEN } = props;
    const [Supppliers_List, setSupppliers_List] = useState([])
    const [Selected_Sup, setSelected_Sup] = useState(null)
    const [PurchaseOrderList_Data, setPurchaseOrderList_Data] = useState([])
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [ProjectList, setProjectList] = useState([])
    const [SelectedProject, setSelectedProject] = useState(null)
    const [error_message, setError_message] = useState({
      Supplier: '',
      Project: ''
    })
    const [IsLoader, setIsLoader] = useState(false)

    useEffect(()=>{
        document.title = " Distribution / Purchase Order"
        // SelectGroupScreenAccess(Group_Id,15,99,(data)=>{
        //   setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
        //   setBtn_delete(parseInt(data.Del) === 0 ? false : true)
    
        //   SHOW_SCREEN(
        //   parseInt(data.AddNew) === 0 ? false : true,
        //   parseInt(data.Edit) === 0 ? false : true,
        //   parseInt(data.Del) === 0 ? false : true,
        //   data.OtherAccess
        //   )
          
        // })
    
    SelectUserProjects(Comp_Id,p_userid,(ProjectData) =>{
      setProjectList(ProjectData)
      if(Purchase_Order.Purchase_Order)
      {
    
        Select_SupplierList(Purchase_Order.Project_ID, false)
        Select_PurchaseOrderList(Purchase_Order.Purchase_Order.SupplierID, Purchase_Order.Project_ID)
        setSelected_Sup(Purchase_Order.Purchase_Order)
        setSelectedProject(ProjectData.find(x => x.Project_ID === Purchase_Order.Project_ID))
        set_PO(null,0,false, null)
    
      }
      else
      {
        setSelectedProject(ProjectData[0])
        Select_SupplierList(ProjectData[0].Project_ID, true)
      }
    })
    
    
        },[])

        const column_PurchaseOrder = [
          {
            name: "ManufacturedBy",
            label: "Supplier",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },  
           {
            name: "ItemDiscription",
            label: "Item Name",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
            {
                name: "PurchaseOrderNo",
                label: "Purchase Order Code",
                options: {
                 filter: true,
                 sort: true,
                 customHeadLabelRender: (columnMeta)=>{
                   return(
                     <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
                   )
                  },
                }
               },
            {
             name: "TotalQty",
             label: "Total Qty",
             options: {
              filter: true,
              sort: true,
            customHeadLabelRender: (columnMeta)=>{
                return(
                  <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
                )
               }, 
              }
            },
            
            {
             name: "ReceivedQty",
             label: "Received Qty",
             options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta)=>{
                return(
                  <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
                )
               },
             }
            },
            {
                name: "BalancedQty",
                label: "Pending Qty",
                options: {
                 filter: true,
                 sort: true,
                 customHeadLabelRender: (columnMeta)=>{
                   return(
                     <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
                   )
                  },
                }
               }
           
        ];
        const validation = () =>{
      
            if (!Selected_Sup)
            {
              setError_message({Supplier: 'must be required', Project: ''})
              return false
            }
            else if(!SelectedProject)
            {
              setError_message({Supplier: '', Project: 'must be required'})
              return false
            }
      
            setError_message({Supplier: '', Project: ''})
            return true
          }
          const Select_PurchaseOrderList = (SupplierID, ProjectID) =>{
            
            showLoader()
            SelectPendingPurchaseOrderList(Comp_Id,ProjectID,SupplierID,(mData)=>{
              hideLoader()
              setPurchaseOrderList_Data(mData)
            })
           }
           const showLoader = () => {
            setIsLoader(true)
           
          }
        const  hideLoader = () => {
          setIsLoader(false)
          }
           const Select_SupplierList = (ProjectID, IsSelected)=>{
            SelectSupplierList(Comp_Id,ProjectID,(mData)=>{
              const mObj = {SupplierID: 0, SupplierName: 'SELECT ALL'}
              mData.unshift(mObj)
              if(IsSelected)
              {
                if(mData.length > 0){
                  setSelected_Sup(mData[0])
                  Select_PurchaseOrderList(mData[0].SupplierID, ProjectID)
                }
        
              }
              setSupppliers_List(mData)
            })
        }
        const theme = createTheme({
          overrides: {
            MuiTableCell: {
              root: {
                paddingTop: 0,
                paddingBottom: 0,
              }
            }
          }
        });
        return ReactDOM.createPortal(
            <>
              <div className="block-header">
                  <div className="row">
                      <div className="col-lg-5 col-md-5 col-sm-12">
                          <h2> Pending Purchase Order List
                          </h2>
                      </div>
                  </div>
              </div>
            
            <Card>
            <Row className="row_margin_top row_margin_left row_margin_right m-4" >
      
      <Col lg={3} xl={3} md={3} >
          <Autocomplete
              name="SupplierName"
              id="SupplierName"
              options={Supppliers_List}
              getOptionLabel={(option) => option.SupplierName ? option.SupplierName : ""}
              value={Selected_Sup}
              onChange={(e, value) => {
                  if (value)
                  {
                      setSelected_Sup(value)
                      if(SelectedProject)
                      Select_PurchaseOrderList(value.SupplierID, SelectedProject.Project_ID)
                  }
                  else
                  {
                      setSelected_Sup(null)
                      setPurchaseOrderList_Data([])
                  }
              }}
              renderInput={(params) =>
                  <TextField {...params} name="SupplierName" label="Supplier"
                  />}
          />
          <span style={{ color: 'red' }} > { error_message.Supplier } </span>
      </Col>
      
      <Col lg={4} xl={4} md={4} >
                              <Autocomplete
                                  name="Project"
                                  id="Project"
                                  options={ProjectList}
                                  getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                                  value={SelectedProject}
                                  onChange={(e, value) => {
                                      if (value)
                                      {
                                          setSelectedProject(value)
      
                                          if(Selected_Sup)
                                          Select_PurchaseOrderList(Selected_Sup.SupplierID,value.Project_ID)
                                      }
                                      else
                                      {
                                        setSelectedProject(null)
                                          setPurchaseOrderList_Data([])
                                      }
                                  }}
                                  renderInput={(params) =>
                                      <TextField {...params} name="Project" label="Select Branch"
                                      />}
                              />
                              <span style={{ color: 'red' }} > { error_message.Project } </span>
                          </Col>
      
 
      </Row>
                <Row className="row_margin_top row_margin_left row_margin_right" >
                  {/* <Col  style={{ textAlign: "right" }} >
                    { btn_add &&(<Link type="button" className="btn btn-primary" to="/OpeningEntry" onClick={add_Opening} style={{ border: "none" }} > <Add />  Create New</Link>)}
                  </Col> */}
                </Row>
                <Row className="row_margin_left row_margin_right" >
                    <Col>              
                      <div className="table-responsive" >
                      <MuiThemeProvider theme={theme}>
                          <MUIDataTable
                              data={PurchaseOrderList_Data}
                              columns={column_PurchaseOrder}
                              options={{
                              selectableRows: 'none',
                              print: false,
                              search: true,
                              download: false,
                              viewColumns: false,
                              filter: false,
                              }}
                          />
                          </MuiThemeProvider>
      
                      </div>
                    </Col>
                </Row>
                <Loader
              show={IsLoader}
              hide={hideLoader}
              />
            </Card>
                              
            </>  , document.getElementById('PageContents')
          )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Purchase_Order: state.PO && state.PO,
    Group_Id : state.Login_User.User_Data.Group_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
  })
export default connect(mapStateToProps,{set_PO})(PendingPurchaseOrderList)