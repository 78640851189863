// For Developers Edition
import Swal from 'sweetalert2'
//  export const domain = "http://localhost:1330/api"
//  export const domain_for_image = "http://localhost:5555"
// export const domain_for_live_Notifications = "http://localhost:1330"

// // For Live Hostig Edition
  export const domain = "https://distribution.lthms.com/api"
  export const domain_for_image = "https://uploads.lthms.com/distribution"
// export const domain_for_live_Notifications = "https://distribution.lthms.com:1330"
var fileDownload = require('js-file-download');
var table = require('text-table');

export const GetCurrentDate = () => {
    var date = new Date();
    return `${date.getFullYear()}-${((date.getMonth()) + 1) <= 9 ? '0' + ((date.getMonth()) + 1).toString() : (date.getMonth()) + 1}-${date.getDate() <= 9 ? '0' + (date.getDate()).toString() : date.getDate()}`
}
export const GetFirstDateofCurrentMonth = () => {
    var date = new Date();
    date.setDate(1);
    return `${date.getFullYear()}-${((date.getMonth()) + 1) <= 9 ? '0' + ((date.getMonth()) + 1).toString() : (date.getMonth()) + 1}-${date.getDate() <= 9 ? '0' + (date.getDate()).toString() : date.getDate()}`
};
export const GetCurrentTime = () => {
    var today = new Date()
    return today.getHours() + ":" + today.getMinutes()
}
export const GetCurrentTimeAM_PM = () => {
    var date = new Date();
    var current_hour = date.getHours() <= 12 ? date.getHours() : date.getHours() % 12;
    var am_pm = date.getHours() <= 12 ? 'AM' : 'PM'
    var current_time = (current_hour <= 9 ? '0' + current_hour : current_hour) + ':' + (date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes()) + ':' + (date.getSeconds() <= 9 ? '0' + date.getSeconds() : date.getSeconds()) + am_pm;
    return current_time
}
export const ThousandSeparater = (Num) => { //function to add commas to textboxes
    var x, x1, x2
    Num += '';
    Num = Num.replace(',', ''); Num = Num.replace(',', ''); Num = Num.replace(',', '');
    Num = Num.replace(',', ''); Num = Num.replace(',', ''); Num = Num.replace(',', '');
    x = Num.split('.');
    x1 = x[0];
    x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1))
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    return x1 + x2;
}
export const ReverseDate = (Date) => {
    return ((((Date).split('-')).reverse()).join('-'))
}
export const setDateFormat = (date) => {
    return date.getFullYear() + "-" + ((date.getMonth()) + 1) + "-" + date.getDate()
}
export const numFormatter = (num) => {

    if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(2) + 'K'; // convert to K for number from > 1000 < 1 million 
    } else if (num > 1000000) {
        return (num / 1000000).toFixed(2) + 'M'; // convert to M for number from > 1 million 
    } else if (num < 900) {
        return num.toFixed(2); // if value < 1000, nothing to do
    }
    else {
        return num.toFixed(2);
    }
}
export const preventMinus = (e) => {
    if (e.code === 'Minus') {
        e.preventDefault();
    }
}
export const msg = (text) => {
    Swal.fire({
        title: 'Validation',
        text: `${text} `,
        icon: 'info'
    })
}
export const File_Download = (data, filename, comp) => {
    if (comp === 'MCOLSON') {
        const myData = data.map(o => Object.values(o).join("#$#")).join('\n');
        fileDownload(myData.trim(), filename)
    }
    else if (comp === 'GLOBAL') {
        var result = data.map(e => Object.keys(e).map(k => e[k]))
        var GLOBAL_DATA = table(result, { align: ['l', 'c', 'l'] });
        fileDownload(GLOBAL_DATA.trim(), filename)
    } else if (comp === 'SHAIGAN') {
        const SHAIGAN_DATA = data.map(o => Object.values(o).join("$SPL$")).join('\n');
        fileDownload(SHAIGAN_DATA.trim(), filename)
    }
}
export const Convert_Date_To_Local_Format = (mDate) => {
    const date = new Date(mDate);
    const formattedDate = date.toLocaleDateString('en-GB', {
        day: 'numeric', month: 'short', year: 'numeric'
    }).replace(/ /g, '-');
    return formattedDate;
}
export const _Years = [
    { value: '2022', title: '2022' },
    { value: '2023', title: '2023' },
    { value: '2024', title: '2024' }
]
export const convertNumberToWords=(number)=> {
    if (number === 0) {
        return 'zero';
    }

    const units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    const teens = ['', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    const tens = ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    function convertThreeDigit(num) {
        let result = '';
        if (num >= 100) {
            result += units[Math.floor(num / 100)] + ' hundred';
            num %= 100;
            if (num > 0) {
                result += ' and ';
            }
        }

        if (num >= 20) {
            result += tens[Math.floor(num / 10)];
            num %= 10;
            if (num > 0) {
                result += ' ';
            }
        }

        if (num > 0) {
            if (num < 10) {
                result += units[num];
            } else {
                result += teens[num - 10];
            }
        }

        return result;
    }

    let result = '';

    const isNegative = number < 0;
    number = Math.abs(number);

    if (number >= 1e9) {
        result += convertThreeDigit(Math.floor(number / 1e9)) + ' billion ';
        number %= 1e9;
    }

    if (number >= 1e6) {
        result += convertThreeDigit(Math.floor(number / 1e6)) + ' million ';
        number %= 1e6;
    }

    if (number >= 1e3) {
        result += convertThreeDigit(Math.floor(number / 1e3)) + ' thousand ';
        number %= 1e3;
    }

    if (number > 0) {
        result += convertThreeDigit(Math.floor(number));

        // Check if there's a decimal part
        const decimalPart = Math.round((number % 1) * 100);
        if (decimalPart > 0) {
            result += ' point';

            // Convert the decimal part to words
            result += ` ${convertThreeDigit(decimalPart)}`;
        }
    }

    return isNegative ? 'minus ' + result.trim() : result.trim();
}