import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col,Form } from "react-bootstrap";
import ReactDOM from 'react-dom'
import { TextField, FormControlLabel, Checkbox, Card } from '@material-ui/core' 
import { Save, Cancel } from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'
import { Link } from "react-router-dom";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { AccessibilityNew } from "@material-ui/icons";
import { connect } from "react-redux"

import { SelectGroupModuleList, SelectModulesList,SaveSecurityGroups,SelectGroup,SaveGroupModules } from '../../Services/SecurityAPI'
import { setUserAccessData } from "../../React_Redux/Actions"

function UserAccess({show, onHide,CompID,Group_Id,IsAddNew, setUserAccessData}) {

    const [ModuleAccessData, setModuleAccessData] = useState([])
    const [GroupID, setGroupID] = useState('')
    const [Description, setDescription] = useState('')
    const [DescErr, setDescErr] = useState('')
    const [readOnlyProp, setreadOnlyProp] = useState(false)

  useEffect(() => {
    if(IsAddNew)
    {
        setreadOnlyProp(true)
        SelectModulesList(mModule=>{
            setModuleAccessData(mModule)
        })

    }
    else
    {
        setreadOnlyProp(false)
        SelectGroup(Group_Id,(mGrp)=>{
            setGroupID(mGrp[0].Group_Id)
            setDescription(mGrp[0].Description)
        })
        SelectGroupModuleList(Group_Id,(mGrpModule)=>{
            mGrpModule.map(mod=>{
                if(mod.Allow_Access === 1)
                {
                    mod.Allow_Access = true
                    mod.disabled = false
                }
                else
                {
                    mod.Allow_Access = false
                    mod.disabled = true
                }
               
            })
            
            setModuleAccessData(mGrpModule)
        })
    } 
  }, [])

  document.title = "Distribution / Users Access"
    const columns = [
        {
         name: "Mod_Id",
         label: "Module ID",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "12px"}} >{columnMeta.label}</div>
            )
           },
         }
        },
        {
         name: "Mod_Name",
         label: "Module Name",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "12px"}} >{columnMeta.label}</div>
            )
           },
         }
        },
        {
            name: "Allow_Access",
            label: "Access",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "12px"}} >{columnMeta.label}</div>
               )
              },
              customBodyRender: (dataIndex, rowIndex) =>{
              
                const _ModuleAccessData = ModuleAccessData;
                if(_ModuleAccessData[rowIndex.rowIndex] != null)
                {
                    return(
                        <Checkbox
                            checked={_ModuleAccessData[rowIndex.rowIndex].Allow_Access}
                            onChange={e => {
                                let newModuleAccessData = [...ModuleAccessData]
                                newModuleAccessData[rowIndex.rowIndex].Allow_Access = e.target.checked 
                               setModuleAccessData(newModuleAccessData)
                            }}
                            disabled={readOnlyProp}
                            name="Access"
                            color="primary"
                            style={{height:'20%'}}
                        />
                    )      
                }
             }
            }
        },
        {
            name: "Action",
            label: "Action",
            options: {
             filter: false,
             sort: false,
             customHeadLabelRender: (columnMeta)=>{
              return(
                <div style={{fontSize: "12px"}} >{columnMeta.name}</div>
              )
             },
             customBodyRender: (dataIndex, rowIndex) => {
                const _ModuleAccessData = ModuleAccessData;
               if(_ModuleAccessData[rowIndex.rowIndex] != null)
              return (
               readOnlyProp ? 
                <Link to={"#"} > <AccessibilityNew /> </Link>  : _ModuleAccessData[rowIndex.rowIndex].disabled === true ? <Link to={"#"} > <AccessibilityNew /> </Link> : 
                <Link to={"/UserScreens"} onClick={e=> edit_Module(_ModuleAccessData[rowIndex.rowIndex].Group_Id,_ModuleAccessData[rowIndex.rowIndex].Mod_Id)}> <AccessibilityNew /> </Link> 
              );
            } 
            }
           },
       ];
   const edit_Module = (Group_Id,Mod_id) =>{
    setUserAccessData(Mod_id,Group_Id,false)
   }

   const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });

   const Save_Groups = () =>{
       if(!(Description).trim())
       {
           setDescErr('Description is required')
       }
       else
       {
        setDescErr('')
        if(IsAddNew === false)
        {  
            if(ModuleAccessData.length > 0)
            {
                ModuleAccessData.map(mod=>{
                    if(mod.Allow_Access === true)
                    mod.Allow_Access = 1
                    else
                    mod.Allow_Access = 0
                })  
                SaveGroupModules(ModuleAccessData,()=>{
                    // do nothing
                })
            }

        }
        SaveSecurityGroups(Group_Id,Description,IsAddNew,()=>{
            document.getElementById('cancel').click()
        })
       }

   }
   const toInputUppercase = (e) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  }   

   return ReactDOM.createPortal (
    <>
          <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2> User Access
                    </h2>
                </div>
            </div>
        </div>
      
       <Row>
            <Col lg={12} md={12}>
                <div className="ui-tab-card">
                    <Card>
                       <Row className="row-margin-left row-margin-right mt-4 mr-4 ml-4 mb-4">
                           {/*   <Col lg={1} xl={1} md={3}>
                                <TextField 
                                    name="GroupID"
                                    id="GroupID"
                                    label=" Group ID"
                                    value={GroupID}
                                    inputProps={{
                                        readOnly:true
                                    }}
                                    fullWidth
                                />
                            </Col> */}
                            <Col lg={6} xl={6} md={6}>
                                <TextField 
                                    name="Description"
                                    id="Description"
                                    label=" Description"
                                    value={Description}
                                    onInput={toInputUppercase}
                                    onChange={(e)=>{
                                        if(e.target.value)
                                        {
                                            setDescription(e.target.value)
                                        }
                                        else
                                        {
                                            setDescription('')
                                        }
                                    }}
                                    fullWidth
                                />
                                <span className="text-danger">{DescErr}</span>
                            </Col>
                            <Col lg={6} xl={6} md={6} style={{textAlign: "right"}} >
                            <Button type="button" className="btn btn-primary rounded-pill" onClick={Save_Groups}> <Save/> Save </Button>
                            <Link to={"/userAccessList"} id="cancel" className="btn btn-primary rounded-pill" ><Cancel/> Close </Link>
                            </Col>
                        </Row>
                        <Row className="row-margin-top row-margin-left row-margin-right mr-4 ml-4">
                            <Col>
                                <div className="table-responsive" >
                                    <MuiThemeProvider  theme={theme}>
                                        <MUIDataTable
                                            style={{size: "200px"}}
                                            data={ModuleAccessData}
                                            columns={columns}
                                            options={{
                                                selectableRows: false,
                                                search: false,
                                                download: false,
                                                print: false,
                                                viewColumns: false,
                                                filter: false,
                                                selectableRows: false,
                                                selection: true,
                                                toolbar: false,
                                                paging: false,
                                                headerStyle: {
                                                    fontSize: 12
                                                }}
                                            }
                                            /> 
                                    </MuiThemeProvider>
                                </div>
                            </Col>
                        </Row>    
                    </Card>
                </div>
            </Col>
        </Row>
    </>, document.getElementById('PageContents')
)
}


const mapStateToProps = state =>({
    CompID: state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Gl_year: state.GlobalVariables.Financial_Year && state.GlobalVariables.Financial_Year.fin_year,
    User: state.Login_User.User_Data,
    IsAddNew: state.UserAccess && state.UserAccess.isAddNew,
    Group_Id: state.UserAccess && state.UserAccess.Group_Id
  })
export default connect(mapStateToProps, { setUserAccessData })(UserAccess)




