import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit } from '@material-ui/icons'
import { connect } from 'react-redux'

import { SelectSaleOrderLists } from '../../Services/SaleOrderAPI'
import { set_SO, SHOW_SCREEN } from '../../React_Redux/Actions'
import { SelectUserProjects } from "../../Services/Projects"
import { Autocomplete } from '@material-ui/lab'
import { TextField } from "@material-ui/core"
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import Loader from '../Loader';

function SaleOrderList(props) {

  const { set_SO, ItemCat, Comp_Id, SaleOrder, p_userid, Group_Id, SHOW_SCREEN } = props;
  const [SaleOrderList_Data, setSaleOrderList_Data] = useState([])
  const [Selected_Month, setSelected_Month] = useState(null)
  const [btn_add, setBtn_add] = useState(true)
  const [btn_delete, setBtn_delete] = useState(true)
  const [ProjectList, setProjectList] = useState([])
  const [SelectedProject, setSelectedProject] = useState(null)
  const [error_message, setError_message] = useState({
    Project: '',
    Month: ''
  })
  const [IsLoader, setIsLoader] = useState(false)

  useEffect(() => {
    document.title = " Distribution / SaleOrder"

    SelectUserProjects(Comp_Id, p_userid, (ProjectData) => {

      setProjectList(ProjectData)
      if (SaleOrder.ProjectID) {
        Select_SaleOrderLists(SaleOrder.Sale_Order.value, SaleOrder.ProjectID)
        setSelectedProject(ProjectData.find(x => x.Project_ID === SaleOrder.ProjectID))
        setSelected_Month(SaleOrder.Sale_Order)
      }
      else {
        setSelectedProject(ProjectData[0])
        var _Date = new Date()
        setSelected_Month(Month_List.find(x => x.value === (_Date.getMonth()) + 1))
        Select_SaleOrderLists(((_Date.getMonth()) + 1), ProjectData[0].Project_ID)

      }
    })
    set_SO(null, null, null, null)
    SelectGroupScreenAccess(Group_Id, 16, 102, (data) => {

      setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
      setBtn_delete(parseInt(data.Del) === 0 ? false : true)

      SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
        parseInt(data.Edit) === 0 ? false : true,
        parseInt(data.Del) === 0 ? false : true,
        data.OtherAccess
      )

    })
  }, [])

  const validation = () => {

    if (!Selected_Month) {
      setError_message({ Month: 'must be selected', Project: '' })
      return false
    }
    if (!SelectedProject) {
      setError_message({ Project: 'must be selected' })
      return false
    }

    setError_message({ Project: '', Month: '' })
    return true
  }

  const column_SaleOrder = [
    {
      name: "SaleOrderID",
      label: "SaleOrderID",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },

      }
    },
    {
      name: "SaleOrderNo",
      label: "SaleOrderNo",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "CustomerName",
      label: "Customer Name",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "OrderDate",
      label: "OrderDate",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },

    {
      name: "action",
      label: "action",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold", textAlign: "right" }} >{columnMeta.label}</div>
          )
        },
        customBodyRender: (dataIndex, rowIndex) => {

          var SaleOrderID = SaleOrderList_Data
          if (SaleOrderID[rowIndex.rowIndex] != null)
            return (
              <Link to={"/SaleOrderEntry"} onClick={e => edit_Customer(SaleOrderList_Data[rowIndex.rowIndex].SaleOrderID)}> <Edit /></Link>
            );
        }
      }
    }
  ];
  const edit_Customer = (SaleOrderID) => {
    set_SO(Selected_Month, SaleOrderID, false, SelectedProject.Project_ID)
  }
  const add_Customer = () => {
    if (validation()) {
      set_SO(Selected_Month, null, true, SelectedProject.Project_ID)
      document.getElementById("btn_navigate").click()
    }
  }

  const Select_SaleOrderLists = (Month, Project_Id) => {
    showLoader()
    SelectSaleOrderLists(Comp_Id, Project_Id, Month, (mData) => {
      hideLoader()
      setSaleOrderList_Data(mData)
    })
  }
  const showLoader = () => {
    setIsLoader(true)

  }
  const hideLoader = () => {
    setIsLoader(false)
  }
  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });
  const Month_List = [
    { title: "JANUARY", value: 1 },
    { title: "FEBURARY", value: 2 },
    { title: "MARCH", value: 3 },
    { title: "APRIL", value: 4 },
    { title: "MAY", value: 5 },
    { title: "JUNE", value: 6 },
    { title: "JULY", value: 7 },
    { title: "AUGUST", value: 8 },
    { title: "SEPTEMBER", value: 9 },
    { title: "OCTOBER", value: 10 },
    { title: "NOVEMBER", value: 11 },
    { title: "DECEMBER", value: 12 },
  ]
  return ReactDOM.createPortal(
    <>
      <div className="block-header">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-12">
            <h2> Sale Order List
            </h2>
          </div>
        </div>
      </div>
      <Card>

        <Row className="row_margin_top row_margin_left row_margin_right m-4" >
          <Col lg={3} xl={3} md={3} >
            <Autocomplete
              name="Month"
              id="Month"
              options={Month_List}
              getOptionLabel={(option) => option.title ? option.title : ""}
              value={Selected_Month}
              onChange={(e, value) => {
                if (value) {
                  setSelected_Month(value)
                  if (SelectedProject)
                    Select_SaleOrderLists(value.value, SelectedProject.Project_ID)
                }
                else {
                  setSelected_Month(null)
                  setSaleOrderList_Data([])
                }
              }}
              renderInput={(params) =>
                <TextField {...params} name="Month" label="Month"
                />}
            />
            <span style={{ color: 'red' }} > {error_message.Month} </span>
          </Col>
          <Col lg={4} xl={4} md={4} >
            <Autocomplete
              name="Project"
              id="Project"
              options={ProjectList}
              getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
              value={SelectedProject}
              onChange={(e, value) => {
                if (value) {
                  setSelectedProject(value)
                  if (Selected_Month)
                    Select_SaleOrderLists(Selected_Month.value, value.Project_ID)
                }
                else {
                  setSelectedProject(null)
                  setSaleOrderList_Data([])
                }
              }}
              renderInput={(params) =>
                <TextField {...params} name="Project" label="Select Branch"
                />}
            />
            <span style={{ color: 'red' }} >{error_message.Project}</span>
          </Col>
          <Col style={{ textAlign: "right" }} >
            <Link to={"/SaleOrderEntry"} id='btn_navigate' ></Link>
            {btn_add && (<Link type="button" className="btn btn-primary rounded-pill" to="#" onClick={add_Customer} style={{ border: "none" }} > <Add />  Create New</Link>)}
          </Col>
        </Row>
        <Row className="row_margin_left row_margin_right" >
          <Col>
            <div className="table-responsive" >
              <MuiThemeProvider theme={theme}>
                <MUIDataTable
                  data={SaleOrderList_Data}
                  columns={column_SaleOrder}
                  options={{
                    selectableRows: 'none',
                    print: false,
                    search: true,
                    download: false,
                    viewColumns: false,
                    filter: false,
                  }}
                />
              </MuiThemeProvider>
            </div>
          </Col>
        </Row>
        <Loader
        show={IsLoader}
        hide={hideLoader}
        />
      </Card>

    </>, document.getElementById('PageContents')
  )
}

const mapStateToProps = state => ({
  Comp_Id: state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  ItemCat: state.Items && state.Items.ItemData,
  Group_Id: state.Login_User.User_Data.Group_Id,
  SaleOrder: state.SO,
  p_userid: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
})
// export default SaleOrderList
export default connect(mapStateToProps, { set_SO, SHOW_SCREEN })(SaleOrderList)
