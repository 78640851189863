import React from 'react'
import ReactDOM from 'react-dom'
import {Row, Col,Card,Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {TextField, Popper} from '@material-ui/core'
import { Save, Cancel,Print} from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import { Autocomplete } from '@material-ui/lab';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
import { connect} from 'react-redux'
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

import { GetCurrentDate, preventMinus } from '../../Services/Common'
import { Selectbank } from '../../Services/bankAPI'
import { GetMaxReceiptsNo, SelectSaleInvoices,SaveReceipts,SelectReceiptsMaster,SelectReceiptsDetail } from '../../Services/ReceiptsAPI'
import { SelectcustomersList } from '../../Services/CustomersAPI'

export class ReceiptEntry extends React.Component {
  constructor(props){
      super(props)

      this.state = {
        ReceiptNo:'',
        ReceiptDate:GetCurrentDate(),
        SelectedPaymentType:null,
        BankList:[],
        selectedBank:null,
        CustomersList:[],
        SelectedCustomer:null,
        InvData:[],
        filter:null,
        selectedInvData:null,
        Receipts_Detail_Data:[],
        DeletedReceipts_Detail_Data:[],
        TotalPaidAmount:0.00,
        TotalSaleAmount:0.00,
        Remarks:'',
        _GrandTotal:0,
        _AlreadyPaid:0,
        btn_edit : true,
        disabledProp:false,
        ReceiptTypeErr:'',
        BankErr:'',
        CustomerErr:'',
        MainDataPrint:[],
        btn_Disabled:false,
      }
      this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
      this.validationRules = { required: true };
      this.grid = null
    this.Comp_Id = this.props.Comp_Id
    this.UserID = this.props.p_userid
    this.ProjectID = this.props.Project && this.props.Project.Project_ID
    
  }
  sortingOptions = {
    columns: [{ field: 'SrNo', direction: 'Ascending' }]
  };

 
  componentDidMount(){

    document.title = " Distribution / Receipts"
    Selectbank(this.Comp_Id,this.ProjectID,(mBanks)=>{
        SelectcustomersList(this.Comp_Id,this.ProjectID,(mCustomers)=>{
            this.setState({BankList:mBanks,CustomersList:mCustomers})

            if(this.props.AddNew) 
            {
                this.GetMax_ReceiptNo() 
            }
            else 
            {
                SelectReceiptsMaster(this.Comp_Id,this.ProjectID,this.props.ReceiptID,(mData)=>{
                    this.Load_Invoices(mData[0].CustomerID)

                    this.setState({btn_edit: this.props.show_screen.edit})
                    this.ReceiptType_Options.map(value=>{
                        if((value.value).trim() === (mData[0].PaymentType).trim()){
                            this.setState({SelectedPaymentType:value})
                        }
                    })
                    if((mData[0].PaymentType).trim() === 'Account Receipt' ){
                        this.state.BankList.map(value=>{
                            if (value.BankID === mData[0].BankID){
                                this.setState({selectedBank:value,disabledProp:false})
                            }
                        })
                    }
                    else {
                        this.setState({disabledProp:true,selectedBank:null})
                    }
                    this.state.CustomersList.map(value=>{
                        if(value.CustomerID === mData[0].CustomerID){
                            this.setState({SelectedCustomer:value})
                        }
                    })
                   
                    SelectReceiptsDetail(this.props.ReceiptID,(mDetailData)=>{
                        var _totalPaidAmt = 0, _totalSaleAmt = 0,PrevInvID;

                        mDetailData.map((value,index)=>{
                            value.SrNo = index + 1 
                            _totalPaidAmt += value.PaidAmt

                            if(PrevInvID !== value.SaleInvoiceID) 
                            _totalSaleAmt+= value.GrandTotal 
                            else
                            _totalSaleAmt = value.GrandTotal 

                            PrevInvID = value.SaleInvoiceID
                        })
                        this.setState({
                            Receipts_Detail_Data:mDetailData,
                            TotalPaidAmount:_totalPaidAmt,
                            TotalSaleAmount:_totalSaleAmt,
                            ReceiptNo:mData[0].ReceiptNo,
                            ReceiptDate:mData[0].ReceiptDate,
                            Remarks:mData[0].Remarks,
                            MainDataPrint:mData
                        })
                    })
                })
            }
        })
     
    })
        
  
  }
  
  ReceiptType_Options =[
      {value:'Cash Receipt',title:'Cash Receipt'},
      {value:'Account Receipt',title:'Account Receipt'},
  ] 
  GetMax_ReceiptNo = () => {
    GetMaxReceiptsNo(this.Comp_Id,this.ProjectID, (mReceiptNo) => { 
            this.setState({ ReceiptNo: mReceiptNo })  
        })
  }
   
    PaidAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="Paid_Amt"
            defaultValue={rowValue.PaidAmt}
            className="textbox"
            name="PaidAmt"
            onChange={e=>this.Calculate_Balance()}
            min={1}
            onKeyPress={e=>preventMinus(e)}
        />)
    }
    Balance = (rowValue) => {
        
        return (<input
            style={{ textAlign: "right",fontWeight:'bold', width: "80%"}}
            type="text"
            id="blnc_Amt"
            defaultValue={rowValue.Balance}
            className="textbox text-danger"
            name="Balance"
            readOnly={true}
        />)
    }

    AlreadyPaidAmt = (rowValue) => {
        return (<input
            style={{ textAlign: "right",fontWeight:'bold', width: "80%"}}
            type="text"
            id="AlreadyPaidAmt"
            defaultValue={ typeof rowValue.AlreadyPaidAmt === 'string' ? parseFloat(rowValue.AlreadyPaidAmt) : rowValue.AlreadyPaidAmt}
            className="textbox text-success"
            name="AlreadyPaidAmt"
            readOnly={true}
        />)
    }
    validate = ()=>{
        var ReceiptType_err = '',bank_err ='',Customer_err = ''

        if(!this.state.SelectedPaymentType){
            ReceiptType_err = "required"
        }
        if (this.state.SelectedPaymentType) {
            if (this.state.SelectedPaymentType.value === 'Account Receipt') {
                if (!this.state.selectedBank)
                    bank_err = 'required'
            }
        }
        if(!this.state.SelectedCustomer){
            Customer_err = 'required'
        }
        if(ReceiptType_err){
            this.setState({ReceiptTypeErr:ReceiptType_err})
            return false
        }
        if (bank_err){
            this.setState({BankErr:bank_err})
            return false
        }
        if(Customer_err){
            this.setState({CustomerErr:Customer_err})
            return false
        }
        return true
    }
    SaveHandler = () => {
        var mValidate = true ;
        if(this.validate()){
            if(this.state.Receipts_Detail_Data.length > 0 || this.state.DeletedReceipts_Detail_Data.length > 0){
                
                for(let i = 0; i< this.state.Receipts_Detail_Data.length; i++)
                {
                    if(this.state.Receipts_Detail_Data[i].Balance < 0 )
                    {
                        Swal.fire({
                            icon: 'info',
                            text: ` Balance Amount ${this.state.Receipts_Detail_Data[i].Balance} must be greater than orr equal to  Paid Amount ${this.state.Receipts_Detail_Data[i].PaidAmt} at Sr. No. ${this.state.Receipts_Detail_Data[i].SrNo} ...`,
                        })
                        mValidate = false 
                        return ;
                    }
                    if(this.state.Receipts_Detail_Data[i].PaidAmt < 0 )
                    {
                        Swal.fire({
                            icon: 'info',
                            text: `Paid Amount must be greater than zero at Sr. No. ${this.state.Receipts_Detail_Data[i].SrNo} ...`,
                        })
                        mValidate = false 
                        return ;
                    }
                    // if(this.state.Receipts_Detail_Data[i].Balance <  this.state.Receipts_Detail_Data[i].PaidAmt)
                    // {
                    //     Swal.fire({
                    //         icon: 'info',
                    //         text: ` Balance Amount must be greater than orr equal to  Paid Amount  at Sr. No. ${this.state.Receipts_Detail_Data[i].SrNo} ...`,
                    //     })
                    //     mValidate = false 
                    //     return ;
                    // }
               
                }
                if(mValidate){
                    var ReceiptData = {
                        ReceiptID:this.props.ReceiptID,
                        ReceiptNo:this.state.ReceiptNo,
                        ReceiptDate:this.state.ReceiptDate,
                        PaymentType:this.state.SelectedPaymentType.value,
                        BankID:this.state.SelectedPaymentType.value === 'Account Receipt'  ? this.state.selectedBank.BankID : 0,
                        CustomerID:this.state.SelectedCustomer.CustomerID,
                        Remarks:this.state.Remarks,
                        UserID:this.UserID,
                        Comp_Id:this.Comp_Id,
                        ProjectID:this.ProjectID
                    }
                    this.setState({btn_Disabled:true})
                    SaveReceipts(ReceiptData,this.state.Receipts_Detail_Data,this.state.DeletedReceipts_Detail_Data,this.props.AddNew,()=>{
                        this.setState({btn_Disabled:false})
                        document.getElementById('btnCancel').click()
                    })
                }
           
            }
            else{
                Swal.fire({
                    icon: 'info',
                    text: `Receipt Details Must be Entered...`,
                })
                return
            }
        }
      
    }
    beforeRowInsert = (rowValue) => {

        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.SrNo) {
                    if (rowValue.requestType != "cancel") {
                        // rowValue.data.Balance = parseFloat(document.getElementById('blnc_Amt').value)

                        if(rowValue.data.Balance <  rowValue.data.PaidAmt)
                        {
                            Swal.fire({
                                icon: 'info',
                                text: ` Balance Amount must be greater than orr equal to  Paid Amount  ...`,
                            })
                            this.grid.closeEdit()
                        }
                        if (rowValue.data.ReceiptDID === undefined)
                            rowValue.data.action = 'add'
                        else
                            rowValue.data.action = rowValue.action

                    }
                }
            }
           
        }

    }
    afterRowInsert = (rowValue) => {
        if (rowValue.requestType == "beginEdit") {
            this.setState({ _GrandTotal:rowValue.rowData.GrandTotal,_AlreadyPaid:rowValue.rowData.AlreadyPaidAmt })
          
        }
        if (rowValue.requestType == "save") {
            this.setState({ _GrandTotal:0,_AlreadyPaid:0 },()=>{
                this.Calculate_Total()
            })
        }
        if (rowValue.requestType == "delete") {
            this.Calculate_Total() 
            if(rowValue.data[0].ReceiptDID){
                this.setState({DeletedReceipts_Detail_Data:this.state.DeletedReceipts_Detail_Data.concat(rowValue.data[0])})
            }

        }
    }
    Calculate_Balance = () =>{
        var _BalanceAmt = 0, _PaidAmount = document.getElementById('Paid_Amt').value;
        if( typeof _PaidAmount === 'string')
        _PaidAmount =   parseFloat((_PaidAmount).replace(/,/g, ''))
        _BalanceAmt = this.state._GrandTotal - (this.state._AlreadyPaid + _PaidAmount )
        document.getElementById('blnc_Amt').value = _BalanceAmt

    }

    Calculate_Total = () => {
        var _TotalGrandTotal = 0,_TotalPaidAmt=0,PrevInvID;

        for(let i = 0; i< this.state.Receipts_Detail_Data.length; i++)
        {
           if( typeof this.state.Receipts_Detail_Data[i].GrandTotal === 'string')
           {
            this.state.Receipts_Detail_Data[i].GrandTotal = parseFloat((this.state.Receipts_Detail_Data[i].GrandTotal).replace(/,/g, ''))
           }
           if( typeof this.state.Receipts_Detail_Data[i].PaidAmt === 'string' )
           {
            this.state.Receipts_Detail_Data[i].PaidAmt = parseFloat((this.state.Receipts_Detail_Data[i].PaidAmt).replace(/,/g, ''))
           }
           _TotalPaidAmt += this.state.Receipts_Detail_Data[i].PaidAmt

            if(PrevInvID !== this.state.Receipts_Detail_Data[i].SaleInvoiceID) 
           _TotalGrandTotal+=  this.state.Receipts_Detail_Data[i].GrandTotal 
           else
           _TotalGrandTotal =  this.state.Receipts_Detail_Data[i].GrandTotal 

           PrevInvID  = this.state.Receipts_Detail_Data[i].SaleInvoiceID 
        }
        this.setState({TotalSaleAmount:_TotalGrandTotal,TotalPaidAmount:_TotalPaidAmt})
     

    }
    toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    handleFilterChange = event => {
        if (event) {
          this.setState({ filter: event.filter });
        }
    };
    Inv_Columns = [
        {
            field: "SaleInvoiceNo",
            header: "SaleInvoiceNo",
            width: "150px",
        },
        {
            field: "InvoiceDate",
            header: "InvoiceDate",
            width: "150px",
        },
        {
            field: "TotalPaid",
            header: "TotalPaid",
            width: "150px",
        },
        {
            field: "Balance",
            header: "Balance",
            width: "150px",
        },
        {
            field: "GrandTotal",
            header: "GrandTotal",
            width: "150px",
        },
    ];
    Load_Invoices = (CustomerID) => {
        SelectSaleInvoices(this.Comp_Id, this.ProjectID, CustomerID, (mInvData) => {
            this.setState({ InvData: mInvData })
        })
    }
    toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    } 
render(){
    return ReactDOM.createPortal (
        <>
            <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2>Receipts
                    </h2>
                </div>
            </div>
        </div>
            <Card>
          
            <Row className="row_margin_top row_margin_left row_margin_right p-4" >
                    <Col sm={12} lg={4} xl={4} md={4} >
                        <TextField 
                            name="ReceiptNo"
                            label="Receipt No" 
                            value={this.state.ReceiptNo}
                            inputProps={
                                {readOnly: true}
                            }
                            fullWidth
                            />
                    </Col>
                    <Col sm={12} lg={4} xl={4} md={4} >
                        <TextField 
                            value={this.state.ReceiptDate}
                            name="ReceiptDate"
                            label="Receipt Date" 
                            type= 'date'
                            onChange= {e => this.setState({ReceiptDate:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="ReceiptType"
                            id="ReceiptType"
                            options={this.ReceiptType_Options}
                            value={this.state.SelectedPaymentType}
                            getOptionLabel={(option) => option.title ? option.title : ""}
                            getOptionSelected={(option, value) => option.title === value.title}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    var disableBank = true
                                    if((value.value).trim() === 'Account Receipt'){
                                        disableBank = false
                                    }
                                   
                                    this.setState({SelectedPaymentType:value,disabledProp:disableBank, ReceiptTypeErr:'',BankErr:'',selectedBank:disableBank && null})
                              
                                }
                                else
                                {
                                    this.setState({SelectedPaymentType:null,disabledProp:false})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="ReceiptType" label="Receipt Type"
                                />}
                        />
                        <span className="text-danger">{this.state.ReceiptTypeErr}</span>
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="Bank"
                            id="Bank"
                            options={this.state.BankList}
                            value={this.state.selectedBank}
                            getOptionLabel={(option) => option.BankName ? option.BankName : ""}
                            getOptionSelected={(option, value) => option.BankID === value.BankID}
                            disabled = {this.state.disabledProp}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({selectedBank:value,BankErr:''})
                                }
                                else
                                {
                                    this.setState({selectedBank:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Bank" label="Select Bank"
                                />}
                        />
                        <span className="text-danger">{this.state.BankErr}</span>
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="Customer"
                            id="Customer"
                            options={this.state.CustomersList}
                            value={this.state.SelectedCustomer}
                            getOptionLabel={(option) => option.CustomerName ? option.CustomerName : ""}
                            getOptionSelected={(option, value) => option.CustomerID === value.CustomerID}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedCustomer:value,CustomerErr:''},()=>{
                                        this.Load_Invoices(value.CustomerID)
                                    })
                                }
                                else
                                {
                                    this.setState({SelectedCustomer:null,selectedInvData:null,InvData:[],filter:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Customer" label="Select Customer"
                                />}
                        />
                        <span className="text-danger">{this.state.CustomerErr}</span>
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                    <MultiColumnComboBox
                      data={ this.state.filter ? filterBy(this.state.InvData, this.state.filter) : this.state.InvData}
                      value={this.state.selectedInvData}
                      style={{ width: "100%", fontSize: "12px" }}
                      columns={this.Inv_Columns}
                      textField={"SaleInvoiceNo"}
                      filterable={true}
                      label='Select Invoice'
                      onFilterChange={this.handleFilterChange}
                      onChange={e => {

                        if (e.value) {
                            var GridValue = {
                                SrNo: this.state.Receipts_Detail_Data.length +1,
                                SaleInvoiceID:e.value.SaleInvoiceID,
                                SaleInvoiceNo:e.value.SaleInvoiceNo,
                                InvoiceDate:e.value.InvoiceDate,
                                GrandTotal:e.value.GrandTotal,
                                AlreadyPaidAmt:e.value.TotalPaid,
                                PaidAmt:0,
                                Balance:e.value.Balance,
                                action:'add'
                            }
                            this.setState({
                                selectedInvData: e.value,
                                Receipts_Detail_Data: this.state.Receipts_Detail_Data.concat(GridValue),
                                _GrandTotal: 0,
                                _AlreadyPaid: 0
                            })
                        }
                        else {
                          this.setState({ selectedInvData: null })
                        }
                      }}
                    />
                   </Col>
                   <Col sm={6} lg={2} xl={2} md={2} >
                        <TextField 
                            name="TotalSaleAmount"
                            label="Sale Amount" 
                            value={this.state.TotalSaleAmount}
                            inputProps={
                                {readOnly: true}
                            }
                            fullWidth
                            />
                    </Col>
                    <Col sm={6} lg={2} xl={2} md={2} >
                        <TextField 
                            name="TotalPaidAmount"
                            label="Total Paid Amount" 
                            value={this.state.TotalPaidAmount}
                            inputProps={
                                {readOnly: true}
                            }
                            fullWidth
                            />
                    </Col>
                    <Col sm={12} lg={8} xl={8} md={8} >
                        <TextField 
                            name="Remarks"
                            label="Remarks" 
                            value={this.state.Remarks}
                            onInput={this.toInputUppercase}
                            onChange={e=>this.setState({Remarks:e.target.value})}
                            fullWidth
                            />
                    </Col>
                </Row>
             
                    
                <Row className="row_margin_top row_margin_left row_margin_right p-4 mt-2" >
                    <div style={{display:"flex",width:"100%",height:"100%",overflow:"hidden"}}> 
                        <div style={{flex:"1",width:"100%",height:"100%"}}>
                            <GridComponent dataSource={this.state.Receipts_Detail_Data} actionBegin={ this.beforeRowInsert} ref={g => this.grid = g} actionComplete={this.afterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: true, allowDeleting: true, allowAdding: false, newRowPosition:'Bottom'  }} sortSettings={this.sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={this.toolbarOptions}>
                                <ColumnsDirective>
                                    <ColumnDirective width='120' field='SrNo' headerText='SrNo #' Format= "{0:n}" allowEditing={false} isIdentity={true}  textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                    <ColumnDirective width='150' field='SaleInvoiceNo'  headerText='Invoice No' allowEditing={false}  textAlign='Left'  /> 
                                     <ColumnDirective width='150' field='InvoiceDate' headerText='Invoice Date'  allowEditing={false}  /> 
                                    <ColumnDirective width='250' field='GrandTotal' headerText='GrandTotal' allowEditing={false} format=",.00" editType='numericedit'  textAlign='Left'/> 
                                    <ColumnDirective width='170' field='AlreadyPaidAmt' headerText='AlreadyPaidAmt' allowEditing={false} format=",.00" editType='numericedit' editTemplate={this.AlreadyPaidAmt} template={this.AlreadyPaidAmt} textAlign='right'></ColumnDirective>  {/* */}
                                    <ColumnDirective width='150' field='PaidAmt' headerText='PaidAmt'  id='myid' editTemplate={this.PaidAmt}  textAlign='right'></ColumnDirective> {/* */}
                                    <ColumnDirective width='170' field='Balance'  headerText='Balance' format=",.00" editType='numericedit'  allowEditing={false} editTemplate={this.Balance} textAlign='right'/> {/*  */}

                                    <ColumnDirective field='SaleInvoiceID'  visible={false}  ></ColumnDirective>
                                    <ColumnDirective field='ReceiptDID' headerText='ReceiptDID'  visible={false}></ColumnDirective>
                                    <ColumnDirective field='ReceiptID' headerText='ReceiptID'   hideAtMedia={true} ></ColumnDirective>
                                    
                                </ColumnsDirective>
                                <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]}   />
                            </GridComponent>
                        </div>
                    </div> 
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                    <Col style={{textAlign: "right", marginTop: "1%"}} >
                             
                            {this.state.btn_edit && (<button type="button" className="btn btn-primary rounded-pill"  style={{border: "none"}} onClick={this.SaveHandler}  disabled={this.state.btn_Disabled}> 
                            
                            {
                             this.state.btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                                } 
                            </button>)}
                            <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{border: "none"}} to={'/Receipts'} > <Cancel /> <u>Close</u></Link>
                            <Link target={"_blank"}
                             className="btn btn-primary rounded-pill" 
                             style={{border: "none"}} 
                             to={'/ReceiptPrint'} 
                             onClick={e =>{

                                var obj = {
                                   Main:this.state.MainDataPrint,
                                   Detail:this.state.Receipts_Detail_Data
                                }
                                localStorage.setItem('token',sessionStorage.getItem('token'))
                                localStorage.setItem("Receipt", JSON.stringify(obj))
                             }}
                             > <Print /> <u>Print</u></Link>
                        </Col>
                    </Row>
                  
                </Card>
            
        </>, document.getElementById('PageContents')
    )
}

}

const mapStateToProps = state=>({
//     StockTransferID:state.StockTransfer && state.StockTransfer.StockTransferID,
    show_screen: state.SCREEN,
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    Month:state.Receipts.Receipt_data && state.Receipts.Receipt_data.SelectedMonth_Option,
    Project:state.Receipts.Receipt_data && state.Receipts.Receipt_data.SelectedProject,
    AddNew: state.Receipts && state.Receipts.isAddNew,
    ReceiptID:state.Receipts && state.Receipts.ReceiptID,

})
export default connect(mapStateToProps)(ReceiptEntry)