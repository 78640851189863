import React, { useEffect, useState } from 'react'
import { Modal, Card, Row, Col } from "react-bootstrap"
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Loop, Cancel, CloudDownload } from "@material-ui/icons"
import { Autocomplete } from '@material-ui/lab'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { connect } from 'react-redux'
import { GetCurrentDate, GetFirstDateofCurrentMonth } from '../../../Services/Common'
import { SelectManufacturedByList } from '../../../Services/manufactureAPI'
import { SelectUserProjects } from '../../../Services/Projects'
import { SelectCityList } from '../../../Services/CityAPI'
import { SelectCustomer, SelectCustomerForDis, SelectCustomers, SelectCustomers_List } from '../../../Services/CustomersAPI'
const TaxReportsModal = ({show, onHide,toPath, readonlyProps, title, Comp_Id,User_Id}) => {
    const [Project_List, setProject_List] = useState([])
    var [SelectedProject, setSelectedProject] = useState(null)
    const [ManufacturedByList, setManufacturedByList] = useState([])
    const [SelectedManufacturedBy, setSelectedManufacturedBy] = useState(null)
    const [CityList, setCityList] = useState([])
    const [SelectedCity, setSelectedCity] = useState(null)
    const [CustomerList, setCustomerList] = useState([])
    const [SelectedCustomer, setSelectedCustomer] = useState(null)
    const [FromDate, setFromDate] = useState(GetFirstDateofCurrentMonth())
    const [ToDate, setToDate] = useState(GetCurrentDate())
    const [FilerTax, setFilerTax] = useState(true)
    const [GST, setGST] = useState(true)
    const [FTax, setFtax] = useState(true)

    
    const [Err_state, setErr_state] = useState({
        Project_Err : ''
    })
  
    useEffect(() => {
        if(show && toPath){
            SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
                setSelectedProject(mProjects[0])
                setProject_List(mProjects)
                SelectManufacturedBy_List(mProjects[0].Project_ID)
                City_List(mProjects[0].Project_ID)
                Customer_List(mProjects[0].Project_ID, 0)
                
            })  
        }
    }, [show])
    const SelectManufacturedBy_List = (Project_ID) =>{
        SelectManufacturedByList(Comp_Id,Project_ID,async(mManufacturer)=>{
           let mObj = {
            ManufacturedByID : 0,
            ManufacturedBy : 'SELECT ALL'
           }
           mManufacturer.unshift(mObj)
           setManufacturedByList(mManufacturer)
           setSelectedManufacturedBy(mManufacturer[0])
        })   
    }
    const City_List = (Project_ID) => {
            SelectCityList(Comp_Id, Project_ID, (mCity)=>{
                let mObj = {
                    CityID : 0,
                    City : 'SELECT ALL'
                }
                mCity.unshift(mObj)
                setCityList(mCity)
                setSelectedCity(mCity[0])
            })
    }
    const Customer_List = (Project_ID, CityID) => {
        if(CityID === 0){
            SelectCustomers(Comp_Id, Project_ID, (mCustomers)=>{
                let mObj = {
                    CustomerID : 0,
                    CustomerName : 'SELECT ALL'
                }
                mCustomers.unshift(mObj)
                setCustomerList(mCustomers)
                setSelectedCustomer(mCustomers[0])
            })
        }else{
            SelectCustomers_List(Comp_Id, Project_ID,CityID, (mCustomers)=>{
                let mObj = {
                    CustomerID : 0,
                    CustomerName : 'SELECT ALL'
                }
                mCustomers.unshift(mObj)
                setCustomerList(mCustomers)
                setSelectedCustomer(mCustomers[0])
            })
        }
      
       
}
    const ExportReport = ()=>{}
    const NavigateToReport = () =>{
        if(SelectedProject){
            if(toPath === '/CompanyTaxes'){
                window.open(`${toPath}/${SelectedProject.Project_ID}/${SelectedManufacturedBy.ManufacturedByID}/${FromDate}/${ToDate}`,'_blank')
            }else if(toPath === '/CustomerWiseTaxes'){
                window.open(`${toPath}/${SelectedProject.Project_ID}/${SelectedManufacturedBy.ManufacturedByID}/${SelectedCity.CityID}/${SelectedCustomer.CustomerID}/${FromDate}/${ToDate}/${FilerTax}/${GST}/${FTax}`,'_blank')
            }
        }else{
            setErr_state({...Err_state,Project_Err:'required'})
        }
       
    }
    return (
    <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" id="Purchase_Modal" >
    <Modal.Header  className="header_title" >
        <Modal.Title> Tax Reports / {title} </Modal.Title>
    </Modal.Header> 
    <Modal.Body id='mBody'>
        <Card>
            <Row className="row_margin_left row_margin_right" >
                <Col sm={12} lg={4} xl={4} md={4} >
                    <TextField
                        name="FromDate"
                        label="From Date"
                        type='date'
                        value={FromDate}
                        onChange={e => {
                            setFromDate(e.target.value)
                        }}
                        fullWidth
                        inputProps={{
                            readOnly: readonlyProps.FromDate
                        }
                        }
                    />
                </Col>
                <Col sm={12} lg={4} xl={4} md={4} >
                    <TextField
                        name="ToDate"
                        label="To Date"
                        type='date'
                        value={ToDate}
                        onChange={e =>{
                            setToDate(e.target.value)
                           
                        } }
                        fullWidth
                        inputProps={{
                            readOnly: readonlyProps.ToDate
                        }
                        }
                    />
                </Col>
              
                <Col sm={12} lg={4} xl={4} md={4} >
                    <Autocomplete
                        name="Project"
                        id="Project"
                        options={Project_List}
                        getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                        value={SelectedProject}
                        disabled={readonlyProps.Project}
                        onChange={(e, value) => {
                            if (value) {
                                setSelectedProject(value)
                                SelectManufacturedBy_List(value.Project_ID)
                                City_List(value.Project_ID)
                                Customer_List(value.Project_ID, 0)
                            }
                            else {
                                setSelectedProject(null)
                            }

                        }}
                        renderInput={(params) =>
                            <TextField {...params} name="Project" label="Project"
                            />
                        }
                    />
                    <span className="text-danger">{Err_state.Project_Err}</span>
                </Col>
            </Row>
            <Row className='row_margin_left row_margin_right'>
               <Col sm={12} lg={4} xl={4} md={4} >
                    <Autocomplete
                        name="ManufacturedBy"
                        id="ManufacturedBy"
                        options={ManufacturedByList}
                        getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""}
                        value={SelectedManufacturedBy}
                        disabled={readonlyProps.ManufacturedBy}
                        onChange={(e, value) => {
                            if (value) {
                                setSelectedManufacturedBy(value)
                            }
                            else {
                                setSelectedManufacturedBy(null)
                            }

                        }}
                        renderInput={(params) =>
                            <TextField {...params} name="ManufacturedBy" label="ManufacturedBy"
                            />
                        }
                    />
                    <span className="text-danger">{Err_state.Project_Err}</span>
                </Col>
                <Col sm={12} lg={4} xl={4} md={4} >
                    <Autocomplete
                        name="City"
                        id="City"
                        options={CityList}
                        getOptionLabel={(option) => option.City ? option.City : ""}
                        value={SelectedCity}
                        disabled={readonlyProps.City}
                        onChange={(e, value) => {
                            if (value) {
                                setSelectedCity(value)
                                Customer_List(SelectedProject.Project_ID, value.CityID)
                            }
                            else {
                                setSelectedCity(null)
                            }

                        }}
                        renderInput={(params) =>
                            <TextField {...params} name="City" label="City"
                            />
                        }
                    />
                    <span className="text-danger">{Err_state.Project_Err}</span>
                </Col>
                <Col sm={12} lg={4} xl={4} md={4} >
                    <Autocomplete
                        name="CustomerName"
                        id="CustomerName"
                        options={CustomerList}
                        getOptionLabel={(option) => option.CustomerName ? option.CustomerName : ""}
                        value={SelectedCustomer}
                        disabled={readonlyProps.Customer}
                        onChange={(e, value) => {
                            if (value) {
                                setSelectedCustomer(value)
                            }
                            else {
                                setSelectedCustomer(null)
                            }

                        }}
                        renderInput={(params) =>
                            <TextField {...params} name="Customers" label="Customers"
                            />
                        }
                    />
                    <span className="text-danger">{Err_state.Project_Err}</span>
                </Col>
            </Row>
            <Row className='row_margin_left row_margin_right'>
                    <Col sm={3} md={3} lg={3} xl={3}>
                    <FormControlLabel disabled={readonlyProps.Filer} control={<Switch 
                        checked={FilerTax} 
                        onChange={e=> setFilerTax(e.target.checked)}
                        />} label="Tax U/S 236" />
                    </Col>
                    <Col sm={3} md={3} lg={3} xl={3}>
                    <FormControlLabel disabled={readonlyProps.GS} control={<Switch
                        checked = {GST}
                        onChange={e=> setGST(e.target.checked)}
                        />} label="GST" />
                    </Col>
                    <Col sm={3} md={3} lg={3} xl={3}>
                     <FormControlLabel disabled={readonlyProps.Further} control={<Switch
                        checked = {FTax}
                        onChange={e=> setFtax(e.target.checked)}
                        />} label="Further Tax" />
                  </Col>
            </Row>               
            <Row className=" row_margin_top row_margin_left row_margin_right" >
                <Col style={{ textAlign: "right", marginTop: "1%" }} >
                    <button className="btn btn-primary rounded-pill"  style={{ border: "none" }} onClick={NavigateToReport} > <Loop /> <u>Load</u></button>
                    <button className="btn btn-primary rounded-pill"  style={{ border: "none" }} disabled={readonlyProps.Export} onClick= {ExportReport}  > <CloudDownload /> <u>Export To Excel</u></button>
                    <button type="button" className="btn btn-primary rounded-pill" onClick={e => {
                        // ResetCotrols()
                        onHide()
                    }} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></button>
                </Col>
            </Row>
        </Card>
    </Modal.Body>
</Modal>
  )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables.Company && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables.Company &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    UserType: state.Login_User.User_Data && state.Login_User.User_Data.User_Type
})
export default connect(mapStateToProps)(TaxReportsModal)