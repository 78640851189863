import React, { useState, useEffect } from 'react'
import  ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { Row, Col, Card,Spinner ,Tabs, Tab  } from "react-bootstrap"
import { Cancel, Save, Print } from '@material-ui/icons'
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { connect } from 'react-redux'
import { enableRipple } from '@syncfusion/ej2-base';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import { getValue } from '@syncfusion/ej2-base';
import Swal from 'sweetalert2'

import { SelectUOMList } from '../../Services/UOMAPI'
import { SelectitemmaintypeList } from '../../Services/ItemMainTypeAPI'
import { SelectItemSubTypeList } from '../../Services/ItemSubTypeAPI'
import {SelectManufacturedByList} from '../../Services/manufactureAPI'
import { GetMaxItemCode, SaveItems,SelectItem,SaveScheme,SelectItemForOpenFlat,SelectItemForOpenDis,SelectItemForOpenBonus } from '../../Services/ItemsAPI'
import { GetCurrentDate, msg } from '../../Services/Common'
import { SelectConfigurationData } from '../../Services/ControlPanelAPI'
import { SelectCompanyGroupAgainstManufacturer  } from '../../Services/CompanyGroupAPI'
import { SelectProductGroupAgainstManufacturer } from '../../Services/ProductGroupAPI'

function ItemEntry({ Comp_Id, isAddNew, ItemID,UserID,Comp_Address, show_screen,ProjectID }) {

    const [isExpire, setisExpire] = useState(true)
    const [isBarcode, setisBarcode] = useState(true)
    const [isPack, setisPack] = useState(true)
   const [ManufacturedBy_List, setManufacturedBy_List] = useState([])
    const [SelectedManufacturedBy_ListOption, setSelectedManufacturedBy_ListOption] = useState(null)
    const [ItemMainType_List, setItemMainType_List] = useState([])
    const [SelectedItemMainTypeOption, setSelectedItemMainTypeOption] = useState(null)
    const [UOM_List, setUOM_List] = useState([])
    const [SelectedUOM, setSelectedUOM] = useState(null)
    const [ItemSubType_List, setItemSubType_List] = useState([])
    const [SelectedItemSubTypeOption, setSelectedItemSubTypeOption] = useState(null)
    const [PackUnit_List, setPackUnit_List] = useState([])
    const [SelectedPackUnitOption, setSelectedPackUnitOption] = useState(null)
     const [btn_edit, setBtn_edit] = useState(true)
     const [btnFlatAdd, setbtnFlatAdd] = useState(true)
     const [btnFlatEdit, setbtnFlatEdit] = useState(true)
     const [btnFlatDelete, setbtnFlatDelete] = useState(true)
     const [btnDiscountAdd, setbtnDiscountAdd] = useState(true)
     const [btnDiscountEdit, setbtnDiscountEdit] = useState(true)
     const [btnDiscountDelete, setbtnDiscountDelete] = useState(true)
     const [btnBonusAdd, setbtnBonusAdd] = useState(true)
     const [btnBonusEdit, setbtnBonusEdit] = useState(true)
     const [btnBonusDelete, setbtnBonusDelete] = useState(true)
    

    const [Item_Data,setItem_Data] = useState({
        ItemID:ItemID,
        Comp_Id:Comp_Id,
        ProjectID:ProjectID,
        UserID:UserID,
        ItemCode:'',
        ItemDiscription:'',
        PurchaseRate:0,
        IsPack:0,
        PackQty:0,  
        StockQty:0,
        IsActive:0,
        SaleRate:0,
        MinStockLevel:0,
        MaxStockLevel:0,
        ReorderStockLevel:0,
        ManufactureID:0,
        CreatedDate:GetCurrentDate(),
        LastSaleRate:0,
        LastPurRate:0,
        IsExpair:0,
        ExpairyDate:GetCurrentDate(),
        IsBarcode:0,
        Barcode:'',
        Remarks:'',
        PackPurRate:0,
        PackSaleRate: 0,
        ProductCode:' ',
        GenericName:'',
        DisPer:0,
        MRP:0,
        TP:0,
        ScheduleGP:false,
        AlternativeItemCode:' ',
        FlatRate:0,
        MinFlatRate:0,
        MaxFlatRate: 0,
        IsFlatRate: false,
        IsEssential: false,
        MaxSaleQty:0,
        GST: 0,
    })
    const [Item_Err, setItem_Err] = useState({
        Name_Err: '',
        PurPrice_Err: '',
        SellPrice_Err: '',
        Cat_Err: '',
        UOM_Err: '',
        SUBUOM_Err: '',
        ManufacturedBy_Err: '',
        CG_Err:'',
        PG_Err:''
    })
    const bg_Ref = React.createRef()
   var grid = null
   const toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
   const validationRules = { required: true };
   const customBGValidation =(args)=> {
    return getValue('value', args) != ''
}
  const BGValidation = {
    required: [customBGValidation, 'Flat Policy must be Entered'],
};
const sortingOptions = {
    columns: [{ field: 'SrNo', direction: 'Ascending' }]
};


    const [disabledProp, setDisabledProp] = useState(false);
    const [PurRatePer_Config, setPurRatePer_Config] = useState(0)
    const [CG_List, setCG_List] = useState([])
    const [SelectedCG, setSelectedCG] = useState(null)
    const [PG_List, setPG_List] = useState([])
    const [SelectedPG, setSelectedPG] = useState(null)
    const [btn_Disabled, setbtn_Disabled] = useState(false)
    const [SelectedTab,setSelectedTab] = useState('')
    const [FlatRatePolicy,setFlatRatePolicy] = useState([])
    const [DeletedFlatRate, setDeletedFlatRate] = useState([])
    const [DiscountPolicy, setDiscountPolicy] = useState([])
    const [DeletedDiscountPolicy, setDeletedDiscountPolicy] = useState([])
    const [BonusPolicy, setBonusPolicy] = useState([])
    const [DeletedBonusPolicy, setDeletedBonusPolicy] = useState([])
    
  
   

    useEffect(() => {
        document.title = " Distribution / Items"

        SelectUOMList(Comp_Id, ProjectID, (mUOM) => {

            setUOM_List(mUOM)
            setPackUnit_List(mUOM)

            SelectitemmaintypeList(Comp_Id, ProjectID, (mCategory) => {
                setItemMainType_List(mCategory)
                SelectItemSubTypeList(Comp_Id, ProjectID, (mSubType) => {
                    setItemSubType_List(mSubType)
                    SelectManufacturedByList(Comp_Id, ProjectID, (mManufacture) => {
                        setManufacturedBy_List(mManufacture)
                        SelectConfigurationData(Comp_Id, (mConfig) => {
                            if (mConfig.length > 0) {
                                setPurRatePer_Config(mConfig[0].PurRatePer)
                                // var isAddNew = true
                                if (isAddNew) {
                                    GetMaxItemCode(Comp_Id, ProjectID, (mCode) => {
                                        setItem_Data({ ...Item_Data, ItemCode: mCode })
                                    })
                                }
                                else {
                                    setBtn_edit(show_screen.edit)
                                    SelectItem(Comp_Id, ProjectID, ItemID, (mData) => {
                                        if (mData[0].IsActive === 1)
                                            mData[0].IsActive = true
                                        else
                                            mData[0].IsActive = false
                                        if (mData[0].isExpire == 1)
                                            mData[0].isExpire = true
                                        else
                                            mData[0].isExpire = false
                                        if (mData[0].IsBarcode == 1)
                                            mData[0].IsBarcode = true
                                        else
                                            mData[0].IsBarcode = false
                                        if (mData[0].IsPack == 1)
                                            mData[0].IsPack = true
                                        else
                                            mData[0].IsPack = false
                                        setItem_Data({
                                            ItemID: ItemID,
                                            Comp_Id: mData[0].Comp_Id,
                                            ProjectID: mData[0].ProjectID,
                                            UserID: mData[0].UserID,
                                            ItemCode: mData[0].ItemCode,
                                            ItemDiscription: mData[0].ItemDiscription,
                                            SaleRate: mData[0].SaleRate,
                                            PurchaseRate: mData[0].PurchaseRate,
                                            StockQty: mData[0].StockQty,
                                            IsPack: mData[0].IsPack,
                                            PackQty: mData[0].PackQty,
                                            MaxStockLevel: mData[0].MaxStockLevel,
                                            MinStockLevel: mData[0].MinStockLevel,
                                            ReorderStockLevel: mData[0].ReorderStockLevel,
                                            IsActive: mData[0].IsActive,
                                            CreatedDate: mData[0].CreatedDate,
                                            IsExpair: mData[0].IsExpair,
                                            ExpairyDate: mData[0].ExpairyDate,
                                            IsBarcode: mData[0].IsBarcode,
                                            Barcode: mData[0].Barcode,
                                            Remarks: mData[0].Remarks,
                                            PackPurRate: mData[0].PackPurRate,
                                            PackSaleRate: mData[0].PackSaleRate,
                                            IsPack: mData[0].IsPack,
                                            ProductCode:mData[0].ProductCode,
                                            GenericName:mData[0].GenericName,
                                            DisPer:mData[0].DisPer,
                                            MRP:mData[0].MRP,
                                            TP:mData[0].TP,
                                            ScheduleGP:mData[0].ScheduleGP === 1 ? true : false,
                                            AlternativeItemCode:mData[0].AlternativeItemCode,
                                            FlatRate:mData[0].FlatRate,
                                            MaxSaleQty:mData[0].MaxSaleQty,
                                            GST: mData[0].GST,
                                            MinFlatRate: mData[0].MinFlatRate,
                                            MaxFlatRate: mData[0].MaxFlatRate,
                                            IsFlatRate: mData[0].IsFlatRate,
                                            IsEssential: mData[0].IsEssential
                                        })

                                        if (mData[0].IsPack) {
                                            setDisabledProp(true)
                                            setisPack(false)
                                        }
                                        mCategory.map(value => {
                                            if (value.ItemMainTypeID === mData[0].ItemMainTypeID)
                                                setSelectedItemMainTypeOption(value)
                                        })

                                        mUOM.map(value => {
                                            if (value.UOMID === mData[0].UOMID) {
                                                setSelectedUOM(value)
                                            }
                                            if (value.UOMID === mData[0].PackUnit) {
                                                setSelectedPackUnitOption(value)
                                            }
                                        })
                                        mSubType.map(value => {
                                            if (value.ItemSubTypeID === mData[0].ItemSubTypeID)
                                                setSelectedItemSubTypeOption(value)
                                        })
                                        mManufacture.map(value => {
                                            if (value.ManufacturedByID === mData[0].ManufacturedByID)
                                                setSelectedManufacturedBy_ListOption(value)
                                        })
                                        SelectCompanyGroupAgainstManufacturer(Comp_Id,ProjectID,mData[0].ManufacturedByID,(data)=>{
                                            var Selected_CG = data.find(x=> x.CGID === mData[0].CGID)
                                            setSelectedCG(Selected_CG)
                                            setCG_List(data)
                                        })
                                        SelectProductGroupAgainstManufacturer(Comp_Id,ProjectID,mData[0].ManufacturedByID,(PGdata)=>{
                                            var Selected_PG = PGdata.find(x=> x.PGID === mData[0].PGID)
                                            setSelectedPG(Selected_PG)
                                            setPG_List(PGdata)
                                        })
                                       fetchOpenFlat()
                                       fetchOpenBonus()
                                       fetchOpenDis()
                                      
                                      
                                    })
                                }
                            }
                        })
                })
                })
            })
        })
        setbtn_Disabled(false)
       
    }, [])

   const fetchOpenFlat = () =>{
    SelectItemForOpenFlat(Comp_Id,ProjectID,ItemID,(mData)=>{
        if(mData.length > 0){
           mData.map((value,index1)=>{
               value.SrNo = index1 + 1
           })
           setFlatRatePolicy(mData)
           setDeletedFlatRate([])
        }
           
       })
   }
   const fetchOpenDis = () =>{
    SelectItemForOpenDis(Comp_Id,ProjectID,ItemID,(mData)=>{
        if(mData.length > 0){
           mData.map((value,index1)=>{
               value.SrNo = index1 + 1
           })
           setDiscountPolicy(mData)
           setDeletedDiscountPolicy([])
        }
        
       })
   }
   const fetchOpenBonus = () =>{
    SelectItemForOpenBonus(Comp_Id,ProjectID,ItemID,(mData)=>{
        if(mData.length > 0){
           mData.map((value,index1)=>{
               value.SrNo = index1 + 1
           })
           if(mData.length > 0){
            setBonusPolicy(mData)
           }
           
        }
       })
   }
   const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
  const validate =() =>{
        var  NameErr=""
        var CatErr = ""
        var UOMErr = ""
        var SubUOMErr = ""
        var PurPriceErr = ""
        var SellPriceErr = ""
        var ManufacturedBy = ""
        var CGErr = ""
        var PGErr = ""
        
        if(!Item_Data.ItemDiscription)
        {
         NameErr = "Name is required"
        }
        if(!SelectedItemMainTypeOption)
        {
        CatErr = "Item Main Type is required"
        }
        
        if(!SelectedUOM)
        {
            UOMErr = "required"
        }
     
        if(!SelectedItemSubTypeOption)
        {
            SubUOMErr="required"
        }
        if(Item_Data.PurchaseRate === 0)
        {
            PurPriceErr="required"
        }
        if(Item_Data.SaleRate === 0)
        {
            SellPriceErr="required"
        }
        if(!SelectedManufacturedBy_ListOption)
        {
            ManufacturedBy = "required"
        }
        if(!SelectedCG){
            CGErr = "required"
        }
        if(!SelectedPG){
            PGErr = "required"
        }
        if(NameErr)
        {
           setItem_Err({...Item_Err,Name_Err:NameErr})
           document.getElementById('ItemDiscription').focus()
            return false
        }
        
        if(CatErr)
        {
            setItem_Err({...Item_Err,Name_Err:'',Cat_Err:CatErr})
            document.getElementById('ItemMainType').focus()
             return false
        }
     if(UOMErr)
     {
        setItem_Err({...Item_Err,Name_Err:'',Cat_Err:'',UOM_Err:UOMErr})
        document.getElementById('UOM').focus()
         return false
     }
    //  if(PurPriceErr)
    //  {
    //     setItem_Err({...Item_Err,Name_Err:'',Cat_Err:'',UOM_Err:'',PurPrice_Err:PurPriceErr})
    //     document.getElementById('PurchaseRate').focus()
    //     return false
    //  }
    //  if(SellPriceErr)
    //  {
    //     setItem_Err({...Item_Err,Name_Err:'',Cat_Err:'',UOM_Err:'',PurPrice_Err:'',SellPrice_Err:SellPriceErr})
    //     document.getElementById('SaleRate').focus()
    //     return false
    //  }
     if(SubUOMErr)
     {
        setItem_Err({...Item_Err,Name_Err:'',Cat_Err:'',UOM_Err:'',PurPrice_Err:'',SellPrice_Err:'',SUBUOM_Err:SubUOMErr})
        document.getElementById('ItemSubType').focus()
         return false
     }
    if(ManufacturedBy)
    {
        setItem_Err({...Item_Err,Name_Err:'',Cat_Err:'',UOM_Err:'',PurPrice_Err:'',SellPrice_Err:'',SUBUOM_Err:'',ManufacturedBy_Err:ManufacturedBy})
        document.getElementById('ManufacturedBy').focus()
         return false

    }
     if(CGErr){
        setItem_Err({...Item_Err,Name_Err:'',Cat_Err:'',UOM_Err:'',PurPrice_Err:'',SellPrice_Err:'',SUBUOM_Err:'',ManufacturedBy_Err:'',CG_Err:CGErr})
        document.getElementById('CG').focus()
         return false
     }
     if(PGErr){
        setItem_Err({...Item_Err,Name_Err:'',Cat_Err:'',UOM_Err:'',PurPrice_Err:'',SellPrice_Err:'',SUBUOM_Err:'',ManufacturedBy_Err:'',CG_Err:'',PG_Err:PGErr})
        document.getElementById('PG').focus()
         return false
     }
     setItem_Err({...Item_Err,Name_Err:'',Cat_Err:'',UOM_Err:'',PurPrice_Err:'',SellPrice_Err:'',SUBUOM_Err:'',ManufacturedBy_Err:'',CG_Err:'',PG_Err:''})

      return true
    
    }
     const Save_Handler = () =>{
        try{
            if(validate())
                {
                    var ItemData = {
                        ItemID: Item_Data.ItemID,
                        Comp_Id:Comp_Id,
                        ProjectID:ProjectID,
                        UserID:UserID,
                        ItemCode:Item_Data.ItemCode,
                        ItemDiscription:Item_Data.ItemDiscription,
                        SaleRate:parseFloat(Item_Data.SaleRate), 
                        PurchaseRate:parseFloat(Item_Data.PurchaseRate),
                        UOMID:SelectedUOM.UOMID,
                        IsPack: Item_Data.IsPack ? 1 : 0,
                        PackQty:parseFloat(Item_Data.PackQty),
                        PackUnit:SelectedUOM.UOMID,
                        ItemMainTypeID:SelectedItemMainTypeOption.ItemMainTypeID,
                        ItemSubTypeID:SelectedItemSubTypeOption.ItemSubTypeID,
                        ManufacturedByID:SelectedManufacturedBy_ListOption.ManufacturedByID,
                        StockQty:parseFloat(Item_Data.StockQty),
                        MinStockLevel:parseFloat(Item_Data.MinStockLevel),
                        MaxStockLevel:parseFloat(Item_Data.MaxStockLevel),
                        ReorderStockLevel:parseFloat(Item_Data.ReorderStockLevel),
                        IsActive: Item_Data.IsActive ? 1 : 0,
                        CreatedDate:Item_Data.CreatedDate,
                        LastSaleRate:0,
                        LastPurRate:0,
                        IsExpair: Item_Data.IsExpair ? 1 : 0,
                        ExpairyDate:Item_Data.ExpairyDate,
                        IsBarcode: Item_Data.IsBarcode ? 1 : 0,
                        Barcode:Item_Data.Barcode,
                        Remarks:Item_Data.Remarks,
                        PackPurRate:Item_Data.PackPurRate , 
                        PackSaleRate:Item_Data.PackSaleRate,
                        CGID:SelectedCG.CGID,
                        PGID:SelectedPG.PGID,
                        ProductCode:Item_Data.ProductCode,
                        GenericName:Item_Data.GenericName,
                        DisPer:Item_Data.DisPer == "" ? 0 : Item_Data.DisPer,
                        MRP:Item_Data.MRP == "" ? 0 : Item_Data.MRP,
                        TP:Item_Data.TP == "" ? 0 : Item_Data.TP,
                        ScheduleGP:Item_Data.ScheduleGP  ? 1 : 0,
                        AlternativeItemCode:Item_Data.AlternativeItemCode,
                        FlatRate:Item_Data.FlatRate,
                        MaxSaleQty:Item_Data.MaxSaleQty,
                        GST: Item_Data.GST,
                        MinFlatRate: Item_Data.MinFlatRate,
                        MaxFlatRate:Item_Data.MaxFlatRate,
                        IsFlatRate: Item_Data.IsFlatRate,
                        IsEssential: Item_Data.IsEssential
                    }
                    
                  
                    setbtn_Disabled(true)
                    SaveItems(ItemData, isAddNew,()=>{
                        setbtn_Disabled(false)
                        document.getElementById('btnCancel').click()
                    })
                }
        }catch(err){
            console.log(err)
        }finally{
            setbtn_Disabled(false)
        }
        
   
    }
    const FlatRate_Handler = () =>{
        if(FlatRatePolicy.length > 0 || DeletedFlatRate.length > 0){
           for(let i =0; i<FlatRatePolicy.length; i++){
            if(typeof FlatRatePolicy[i].TargetFromQty === 'undefined' || (FlatRatePolicy[i].TargetFromQty) == 0 || FlatRatePolicy[i].TargetFromQty == null )
            {
             Swal.fire({
                 icon: 'info',
                 text: `TargetFromQty is required at Sr No. ${FlatRatePolicy[i].SrNo}...`,
             })
             return
            }
            if(typeof FlatRatePolicy[i].Criteria === 'undefined' || (FlatRatePolicy[i].Criteria) == 0 || FlatRatePolicy[i].Criteria == null )
            {
             Swal.fire({
                 icon: 'info',
                 text: `Criteria is required at Sr No. ${FlatRatePolicy[i].SrNo}...`,
             })
             return
            }
            if(typeof FlatRatePolicy[i].FlatRate === 'undefined' || (FlatRatePolicy[i].FlatRate) == 0 || FlatRatePolicy[i].FlatRate == null )
            {
             Swal.fire({
                 icon: 'info',
                 text: `Flat Rate is required at Sr No. ${FlatRatePolicy[i].SrNo}...`,
             })
             return
            }
           }
            setbtn_Disabled(true)
            SaveScheme(FlatRatePolicy,DeletedFlatRate,(mStatus)=>{
                setbtn_Disabled(false)
                if(mStatus){
                    fetchOpenFlat()
                }
            })
        }
    }
    const DiscountPolicy_Handler = () =>{
        if(DiscountPolicy.length > 0 || DeletedDiscountPolicy.length > 0){
          
            for(let  i=0; i<DiscountPolicy.length; i++){
                if(typeof DiscountPolicy[i].TargetFromQty === 'undefined' || (DiscountPolicy[i].TargetFromQty) == 0 || DiscountPolicy[i].TargetFromQty == null )
                {
                 Swal.fire({
                     icon: 'info',
                     text: `TargetFromQty is required at Sr No. ${DiscountPolicy[i].SrNo}...`,
                 })
                 return
                }
                if(typeof DiscountPolicy[i].Criteria === 'undefined' ||  DiscountPolicy[i].Criteria == null )
                {
                 Swal.fire({
                     icon: 'info',
                     text: `Criteria is required at Sr No. ${DiscountPolicy[i].SrNo}...`,
                 })
                 return
                } 
                if(typeof DiscountPolicy[i].Discount1Per === 'undefined' || (DiscountPolicy[i].Discount1Per) == 0 || DiscountPolicy[i].Discount1Per == null )
                {
                 Swal.fire({
                     icon: 'info',
                     text: `Discount 1 % is required at Sr No. ${DiscountPolicy[i].SrNo}...`,
                 })
                 return
                }
            }
            setbtn_Disabled(true)
            
            SaveScheme(DiscountPolicy,DeletedDiscountPolicy,(mStatus)=>{
                setbtn_Disabled(false)
                if(mStatus){
                    fetchOpenDis()
                }
            })
        }
    }
    const BonusPolicy_Handler = () =>{
        if(BonusPolicy.length > 0 || DeletedBonusPolicy.length > 0){
            for(let  i=0; i<BonusPolicy.length; i++){
                if(typeof BonusPolicy[i].TargetFromQty === 'undefined' || (BonusPolicy[i].TargetFromQty) == 0 || BonusPolicy[i].TargetFromQty == null )
                {
                 Swal.fire({
                     icon: 'info',
                     text: `TargetFromQty is required at Sr No. ${BonusPolicy[i].SrNo}...`,
                 })
                 return
                }  
            }
            setbtn_Disabled(true)
            SaveScheme(BonusPolicy,DeletedBonusPolicy,(mStatus)=>{
                setbtn_Disabled(false)
                if(mStatus){
                    setDeletedBonusPolicy([])
                    fetchOpenBonus()
                }
            })
        }
    }
   const FlatbeforeRowInsert = (rowValue) => {

        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.SrNo) {
                    if (rowValue.requestType != "cancel") {
                        if(parseFloat(rowValue.data.FlatRate) < rowValue.data.MinFlatRate || parseFloat(rowValue.data.FlatRate) > rowValue.data.MaxFlatRate )
                        {
                            msg(`Flat Rate is out of Range`)
                            rowValue.data.FlatRate = 0
                        }
                        if(rowValue.data.SchemeID)
                        rowValue.data.action = rowValue.action
                        else
                        rowValue.data.action = 'add'
                        
                    }
                }
                else {
                    rowValue.data.SrNo = FlatRatePolicy.length + 1

                }
            }
        }

    }
  const FlatafterRowInsert = (rowValue) => {

        if (rowValue.requestType === 'delete') {
            if (rowValue.data[0].SchemeID) {
                setDeletedFlatRate(DeletedFlatRate.concat(rowValue.data))
            }
        }
    }
    const DiscountbeforeRowInsert = (rowValue) => {

        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.SrNo) {
                    if (rowValue.requestType != "cancel") {
                        if(rowValue.data.SchemeID)
                         {rowValue.data.action = rowValue.action}
                        else
                      {
                        rowValue.data.action = 'add'
                        rowValue.data.IsStopDiscount =  rowValue.data.IsStopDiscount ?  1 : 0
                        rowValue.data.IsClaimable =  rowValue.data.IsClaimable ?  1 : 0
                        // rowValue.data.StartDate = GetCurrentDate()
                        // rowValue.data.EndDate = GetCurrentDate()
                        // rowValue.data.RefrenceDate = GetCurrentDate()
                      }

                    }
                }
                else {
                    rowValue.data.SrNo = DiscountPolicy.length + 1
                   
                }
            }
        }

    }
    const DiscountafterRowInsert = (rowValue) => {

        if (rowValue.requestType === 'delete') {
            if (rowValue.data[0].SchemeID) {
               
                 setDeletedDiscountPolicy(DeletedDiscountPolicy.concat(rowValue.data))
            }
        }
       
    }
  
    const BonusbeforeRowInsert = (rowValue) => {

        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.SrNo) {
                    if (rowValue.requestType != "cancel") {
                        if(rowValue.data.SchemeID)
                        {

                            rowValue.data.action = rowValue.action
                        }
                        else{
                            rowValue.data.action = 'add'
                            rowValue.data.IsClaimable =  rowValue.data.IsClaimable ?  1 : 0
                            // rowValue.data.StartDate = GetCurrentDate()
                            // rowValue.data.EndDate = GetCurrentDate()
                            // rowValue.data.RefrenceDate = GetCurrentDate()
                        }
                  

                    }
                }
                else {
                    rowValue.data.SrNo = BonusPolicy.length + 1
                   
                }
            }
        }

    }
    const BonusafterRowInsert = (rowValue) => {

        if (rowValue.requestType === 'delete') {
            if (rowValue.data[0].SchemeID) {
                
                 setDeletedBonusPolicy(DeletedBonusPolicy.concat(rowValue.data))
            }
        }
       
    }
   const ItemDiscription = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="text"
            id="ItemDiscription"
            className="textbox"
            name='ItemDiscription'
            disabled= {true}
            onInput={toInputUppercase}
            defaultValue={rowValue.ItemDiscription}
        />)
    }
    const ManufacturerDD = (rowValue) => {
  
        return (
            <Autocomplete
                name="ManufacturedBy"
                id="ManufacturedBy"
                defaultValue={ManufacturedBy_List.find(v => v.ManufacturedBy === rowValue.ManufacturedBy)}
                options={ManufacturedBy_List}
                getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""}
                onChange={(e, value) => {
                    if (value) {
                     
                        setSelectedManufacturedBy_ListOption(value)
                      
                    }
                    else {
                        setSelectedManufacturedBy_ListOption(null)
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="ManufacturedBy" />}
            />
        )
    }
    const TargetFromQty = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"
            id="TargetFromQty"
            className="textbox"
            name='TargetFromQty'
            onInput={toInputUppercase}
            defaultValue={rowValue.TargetFromQty}
        />)
    }
    const Discount1Per = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"   
            id="Discount1Per"
            className="textbox"
            name='Discount1Per'
            onInput={toInputUppercase}
            defaultValue={rowValue.Discount1Per}
        />)
    }
    const Discount2Per = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"
            id="Discount2Per"
            className="textbox"
            name='Discount2Per'
            onInput={toInputUppercase}
            defaultValue={rowValue.Discount2Per}
        />)
    }
    const BonusQty = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"
            id="BonusQty"
            className="textbox"
            name='BonusQty'
            onInput={toInputUppercase}
            defaultValue={rowValue.BonusQty}
        />)
    }

    const IsClaimable = (rowValue) => {
        return (<input
            style={{ width: "40%" }}
            type="checkbox"
            id="IsClaimable"
            className="textbox"
            name='IsClaimable'
            onInput={toInputUppercase}
            defaultValue={rowValue.IsClaimable}
        />)
    }
    const OurSharePer = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"
            id="OurSharePer"
            className="textbox"
            name='OurSharePer'
            onInput={toInputUppercase}
            defaultValue={rowValue.OurSharePer}
        />)
    }
    const StartDate = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="date"
            id="StartDate"
            className="textbox"
            name='StartDate'
            onInput={toInputUppercase}
            defaultValue={rowValue.StartDate}
        />)
    }
    const EndDate = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="date"
            id="EndDate"
            className="textbox"
            name='EndDate'
            onInput={toInputUppercase}
            defaultValue={rowValue.EndDate}
        />)
    }
    const Criteria = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"
            id="Criteria"
            className="textbox"
            name='Criteria'
            onInput={toInputUppercase}
            defaultValue={rowValue.Criteria}
        />)
    }
    const FlatRate = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="number"
            id="FlatRate"
            className="textbox"
            name='FlatRate'
            onInput={toInputUppercase}
            defaultValue={rowValue.FlatRate}
          
        />)
    }
    const Remarks = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="text"
            id="Remarks"
            className="textbox"
            name='Remarks'
            onInput={toInputUppercase}
            defaultValue={rowValue.Remarks}
        />)
    }
    const RefrenceNo = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="text"
            id="RefrenceNo"
            className="textbox"
            name='RefrenceNo'
            onInput={toInputUppercase}
            defaultValue={rowValue.RefrenceNo}
        />)
    }
    const RefrenceDate = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="date"
            id="RefrenceDate"
            className="textbox"
            name='RefrenceDate'
            defaultValue={rowValue.RefrenceDate}
        />)
    }
    return ReactDOM.createPortal (
        <>
      <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2>Items
                    </h2>
                </div>
            </div>
        </div>
            <Card> 
            <Row className="row_margin_top row_margin_left row_margin_right m-4" >
            <Tabs
                                    defaultActiveKey="home"
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="mb-3 ml-3 mr-3 row-margin-left row-margin-right row-margin-top"
                                    onSelect={e => {
                                        setSelectedTab(e)
                                    }}
                                >
                                    <Tab eventKey="home" title="Items">
                                    <Row className="row_margin_top row_margin_left row_margin_right m-4" >
                                    <Col lg={4} xl={4} md={4} >
                                            <TextField
                                                name="ItemCode"
                                                id="ItemCode"
                                                label="Code"
                                                value={Item_Data.ItemCode}
                                                inputProps={{
                                                    readOnly:true
                                                }}
                                                fullWidth
                                            />
                                        </Col>
                                        <Col lg={4} xl={4} md={4} >
                                            <TextField
                                                name="ItemDiscription"
                                                id="ItemDiscription"
                                                label="Item Name"
                                                value={Item_Data.ItemDiscription}
                                                onChange={e=>setItem_Data({...Item_Data,ItemDiscription:e.target.value})}
                                                onInput={toInputUppercase}
                                                fullWidth
                                            />
                                            <span className="text-danger">{Item_Err.Name_Err}</span>
                                        </Col>
                                        <Col lg={4} xl={4} md={4} >
                                            <TextField
                                                name="GenericName"
                                                id="GenericName"
                                                label="Generic Name"
                                                value={Item_Data.GenericName}
                                                onChange={e=>setItem_Data({...Item_Data,GenericName:e.target.value})}
                                                onInput={toInputUppercase}
                                                fullWidth
                                            />
                                            <span className="text-danger">{Item_Err.Name_Err}</span>
                                        </Col>
                                        <Col lg={4} xl={4} md={4} >
                                                <Autocomplete
                                                    name="UOM"
                                                    id="UOM"
                                                    options={UOM_List}
                                                    getOptionLabel={(option) => option.UOM ? option.UOM : ""} 
                                                    value={SelectedUOM}
                                                    onChange={(e,value) =>{
                                                    if(value)
                                                    {
                                                        setSelectedUOM(value)
                                                       
                                                    }
                                                    else
                                                    setSelectedUOM(null)
                                                        
                                                    }}
                                                    renderInput={(params) => 
                                                    <TextField {...params} name="UOM" label="UOM"
                                                    />
                                                }
                                                />
                                                <span className="text-danger">{Item_Err.UOM_Err}</span>
                                            </Col>
                                       
                                            <Col lg={4} xl={4} md={4} >
                                                <Autocomplete
                                                    name="ManufacturedBy"
                                                    id="ManufacturedBy"
                                                    options={ManufacturedBy_List}
                                                    getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""} 
                                                    value={SelectedManufacturedBy_ListOption}
                                                    onChange={(e,value) =>{
                                                    if(value){
                                                        setSelectedManufacturedBy_ListOption(value)
                                                        SelectCompanyGroupAgainstManufacturer(Comp_Id,ProjectID,value.ManufacturedByID,(mData)=>{
                                                            setCG_List(mData)
                                                        })
                                                        SelectProductGroupAgainstManufacturer(Comp_Id,ProjectID,value.ManufacturedByID,(mData)=>{
                                                            
                                                            setPG_List(mData)
                                                        })
                                                    }
                                                    else{
                                                        setSelectedManufacturedBy_ListOption(null)
                                                        setCG_List([])
                                                        setSelectedCG(null)
                                                        setPG_List([])
                                                        setSelectedPG(null)
                                                    }
                                                        
                                                    }}
                                                    renderInput={(params) => 
                                                    <TextField {...params} name="ManufacturedBy" label="ManufacturedBy"
                                                    />}
                                                />
                                                <span className="text-danger">{Item_Err.ManufacturedBy_Err}</span>
                                            </Col>
                                            <Col lg={4} xl={4} md={4} >
                                                <Autocomplete
                                                    name="CG"
                                                    id="CG"
                                                    options={CG_List}
                                                    getOptionLabel={(option) => option.CGName ? option.CGName : ""} 
                                                    value={SelectedCG}
                                                    onChange={(e,value) =>{
                                                    if(value){
                                                       
                                                        setSelectedCG(value)
                                                    }
                                                    else{
                                                        setSelectedCG(null)
                                                       
                                                    }
                                                        
                                                    }}
                                                    renderInput={(params) => 
                                                    <TextField {...params} name="CG" label="Company Group"
                                                    />}
                                                />
                                                <span className="text-danger">{Item_Err.CG_Err}</span>
                                            </Col>
                                            <Col lg={4} xl={4} md={4} >
                                                <Autocomplete
                                                    name="PG"
                                                    id="PG"
                                                    options={PG_List}
                                                    getOptionLabel={(option) => option.PGName ? option.PGName : ""} 
                                                    value={SelectedPG}
                                                    onChange={(e,value) =>{
                                                    if(value)
                                                    setSelectedPG(value)
                                                    else
                                                    setSelectedPG(null)
                                                        
                                                    }}
                                                    renderInput={(params) => 
                                                    <TextField {...params} name="PG" label="Product Group"
                                                    />}
                                                />
                                                <span className="text-danger">{Item_Err.PG_Err}</span>
                                            </Col>
                                            <Col lg={4} xl={4} md={4} >
                                                <Autocomplete
                                                    name="ItemMainType"
                                                    id="ItemMainType"
                                                    options={ItemMainType_List}
                                                    getOptionLabel={(option) => option.ItemMainType ? option.ItemMainType : ""} 
                                                    value={SelectedItemMainTypeOption}
                                                    onChange={(e,value) =>{
                                                    if(value)
                                                    setSelectedItemMainTypeOption(value)
                                                    else
                                                    setSelectedItemMainTypeOption(null)
                                                        
                                                    }}
                                                    renderInput={(params) => 
                                                    <TextField {...params} name="ItemMainType" label="ItemMainType"
                                                    />
                                                }
                                                />
                                                <span className="text-danger">{Item_Err.Cat_Err}</span>
                                            </Col>
                                            <Col lg={4} xl={4} md={4} >
                                                <Autocomplete
                                                    name="ItemSubType"
                                                    id="ItemSubType"
                                                    options={ItemSubType_List}
                                                    getOptionLabel={(option) => option.ItemSubType ? option.ItemSubType : ""} 
                                                    value={SelectedItemSubTypeOption}
                                                    onChange={(e,value) =>{
                                                    if(value)
                                                    {
                                                        setSelectedItemSubTypeOption(value)
                                                    }
                                                    else
                                                    setSelectedItemSubTypeOption(null)
                                                        
                                                    }}
                                                    renderInput={(params) => 
                                                    <TextField {...params} name="ItemSubType" label="ItemSubType"
                                                    />}
                                                />
                                                <span className="text-danger">{Item_Err.SUBUOM_Err}</span>
                                            </Col>
                                            <Col lg={6} xl={6} md={6} >
                                                <TextField
                                                    name="Remarks"
                                                    id="Remarks"
                                                    label="Packing Size"
                                                    value={Item_Data.Remarks}
                                                    onChange={e=>setItem_Data({...Item_Data,Remarks:e.target.value})}
                                                    fullWidth
                                                />
                                            </Col>
                                            
                                        <Col lg={2} xl={2} md={2} >
                                            <TextField
                                                name="PackQty"
                                                id="PackQty"
                                                label="PackingQty"
                                                type="number"
                                                // disabled={isPack}
                                                value={Item_Data.PackQty}
                                                onChange={e=>{
                                                    if(e.target.value){
                                                        // var mRate = parseFloat(Item_Data.PackSaleRate)
                                                        // var mQty = parseFloat(e.target.value)
                                                        // var mSaleRate =  parseFloat(mRate/mQty)
                                                        // var mPackPurRate = parseFloat((mRate - mRate * (PurRatePer_Config / 100)).toFixed(2))
                                                        // var PurRate = parseFloat( (mPackPurRate / mQty ).toFixed(2))
                                                        setItem_Data({...Item_Data,PackQty:e.target.value,
                                                            // PackSaleRate:mRate,
                                                            // SaleRate:mSaleRate,
                                                            // PackPurRate:mPackPurRate,
                                                            // PurchaseRate:PurRate
                                                        })
                    
                                                    }
                                                    else{
                                                        setItem_Data({...Item_Data,PackQty:0
                                                            // ,PackSaleRate:0,SaleRate:0,PackPurRate:0,PurchaseRate:0
                                                        })
                                                    }
                                                }}
                                                fullWidth
                                            />
                                        </Col>
                                        <Col lg={2} xl={2} md={2} >
                                            <TextField
                                                name="PackPurRate"
                                                id="PackPurRate"
                                                label="Pur Rate"
                                                type="number"
                                                // disabled={true}
                                                value={Item_Data.PackPurRate}
                                                onChange={e=>{
                                                    if(e.target.value){
                                                        // var mRate = parseFloat(e.target.value)
                                                        // var mQty = parseFloat(Item_Data.PackQty)
                    
                                                    setItem_Data({...Item_Data,PackPurRate:e.target.value,
                                                        // PurchaseRate:parseFloat((mRate/mQty).toFixed(2))
                                                    })
                                                    }
                                                    else{
                                                        setItem_Data({...Item_Data,PackPurRate:0,
                                                            // PurchaseRate:0
                                                        })
                                                    }
                                                }}
                                                fullWidth
                                            />
                                        </Col>
                                        <Col lg={2} xl={2} md={2} >
                                            <TextField
                                                name="PackSaleRate"
                                                id="PackSaleRate"
                                                label="Packing Sale Rate"
                                                type="number"
                                                // disabled={isPack}
                                                value={Item_Data.PackSaleRate}
                                                onChange={e=>{
                                                    if(e.target.value){
                                                    // var mRate = parseFloat(e.target.value)
                                                    // var mQty = parseFloat(Item_Data.PackQty)
                                                    // var mSaleRate =  parseFloat(mRate/mQty)
                                                    // var mPackPurRate = parseFloat((mRate - mRate * (PurRatePer_Config / 100)).toFixed(2))
                                                    // debugger
                                                    // var PurRate = parseFloat( (mPackPurRate / mQty ).toFixed(2))
                                                    setItem_Data({...Item_Data,
                                                        PackSaleRate:e.target.value,
                                                        // SaleRate:parseFloat((mRate/mQty).toFixed(2)),
                                                        // PackPurRate:mPackPurRate,
                                                        // PurchaseRate:PurRate
                                                    })
                                                    }
                                                    else{
                                                        setItem_Data({...Item_Data,PackSaleRate:0,
                                                            // SaleRate:0,PackPurRate:0,PurchaseRate:0
                                                        })
                                                    }
                                                }}
                                                fullWidth
                                            />
                                        </Col>
                                            <Col lg={2} xl={2} md={2} >
                                                <TextField
                                                    name="MinStockLevel"
                                                    id="MinStockLevel"
                                                    label="MinStockLevel"
                                                    type="number"
                                                    value={Item_Data.MinStockLevel}
                                                    onChange={e=>setItem_Data({...Item_Data,MinStockLevel:e.target.value})}
                                                    fullWidth
                                                />
                                            </Col>
                                                               
                                        <Col lg={2} xl={2} md={2} >
                                                <TextField
                                                    name="MaxStockLevel"
                                                    id="MaxStockLevel"
                                                    label="MaxStockLevel"
                                                    type="number"
                                                    value={Item_Data.MaxStockLevel}
                                                    onChange={e=>setItem_Data({...Item_Data,MaxStockLevel:e.target.value})}
                                                    fullWidth
                                                />
                                            </Col>
                                        <Col lg={2} xl={2} md={2} >
                                                <TextField
                                                    name="ReorderStockLevel"
                                                    id="ReorderStockLevel"
                                                    label="ReorderStockLevel"
                                                    type="number"
                                                    value={Item_Data.ReorderStockLevel}
                                                    onChange={e=>setItem_Data({...Item_Data,ReorderStockLevel:e.target.value})}
                                                   
                                                    fullWidth
                                                />
                                            </Col>
                                            <Col lg={2} xl={2} md={2} >
                                                <TextField
                                                    name="CreatedDate"
                                                    id="CreatedDate"
                                                    label="CreatedDate"
                                                    type="date"
                                                     value={Item_Data.CreatedDate}
                                                    onChange={e=>setItem_Data({...Item_Data,CreatedDate:e.target.value})}
                                                    fullWidth
                                                />
                                            </Col>
                                            <Col lg={2} xl={2} md={2} >
                                                <TextField
                                                    name="ProductCode"
                                                    id="ProductCode"
                                                    label="Product Code"
                                                     value={Item_Data.ProductCode}
                                                    onChange={e=>setItem_Data({...Item_Data,ProductCode:e.target.value})}
                                                    fullWidth
                                                />
                                            </Col>
                                            <Col lg={2} xl={2} md={2} className={"p-0"} >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={Item_Data.IsActive}
                                                        id="IsActive"
                                                        label="IsActive"
                                                        onChange={e => setItem_Data({ ...Item_Data, IsActive: e.target.checked })}
                                                      
                                                        name="Access"
                                                        color="primary"
                                                    />
                                                }
                                                label={"IsActive"}
                                                style={{
                                                    padding: "0px",
                                                    marginTop: "10%"
                                                }}
                                            />
                    
                                        </Col>
                                        <Col lg={2} xl={2} md={2} >
                                            <TextField
                                                name="DisPer"
                                                id="DisPer"
                                                label="Discount Per (on Purchase)"
                                                type="number"
                                                value={Item_Data.DisPer}
                                                onChange={e=>{
                                                    setItem_Data({...Item_Data,DisPer:e.target.value, })
                                                }}
                                                fullWidth
                                            />
                                        </Col>
                                        <Col lg={2} xl={2} md={2} >
                                            <TextField
                                                name="MRP"
                                                id="MRP"
                                                label="MRP"
                                                type="number"
                                                value={Item_Data.MRP}
                                                onChange={e=>{
                                                    setItem_Data({...Item_Data,MRP:e.target.value,
                                                    })
                                                }}
                                                fullWidth
                                            />
                                        </Col>
                                        <Col lg={2} xl={2} md={2} >
                                            <TextField
                                                name="TP"
                                                id="TP"
                                                label="TP"
                                                type="number"
                                                value={Item_Data.TP}
                                                onChange={e=>{
                                                    setItem_Data({...Item_Data,TP:e.target.value, })
                                                   
                                                }}
                                                fullWidth
                                            />
                                        </Col>
                                        <Col lg={2} xl={2} md={2} className={"p-0"} >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={Item_Data.ScheduleGP}
                                                        id="ScheduleGP"
                                                        label="ScheduleGP"
                                                        onChange={e => setItem_Data({ ...Item_Data, ScheduleGP: e.target.checked })}
                                                      
                                                        name="Access"
                                                        color="primary"
                                                    />
                                                }
                                                label={"Schedule-G Product"}
                                                style={{
                                                    padding: "0px",
                                                    marginTop: "7%"
                                                }}
                                            />
                    
                                        </Col>
                                        <Col lg={2} xl={2} md={2} className={"p-0"} >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={Item_Data.IsFlatRate}
                                                    id="IsFlatRate"
                                                    label="IsFlatRate"
                                                    onChange={e => setItem_Data({ ...Item_Data, IsFlatRate: e.target.checked })}
                                                  
                                                    name="Access"
                                                    color="primary"
                                                />
                                            }
                                            label={"Is Flat Rate"}
                                            style={{
                                                padding: "0px",
                                                marginTop: "7%"
                                            }}
                                        />
                    
                                       </Col>
                                        <Col lg={2} xl={2} md={2} >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={Item_Data.IsEssential}
                                                    id="IsEssential"
                                                    label="IsEssential"
                                                    onChange={e => setItem_Data({ ...Item_Data, IsEssential: e.target.checked })}
                                                  
                                                    name="Access"
                                                    color="primary"
                                                />
                                            }
                                            label={"Is Essential"}
                                            style={{
                                                padding: "0px",
                                                marginTop: "7%"
                                            }}
                                        />
                                        </Col>
                                       
                                        <Col lg={2} xl={2} md={2} >
                                            <TextField
                                                name="AlternativeItemCode"
                                                id="AlternativeItemCode"
                                                label="Alt. Item Code"
                                                value={Item_Data.AlternativeItemCode}
                                                onChange={e=>setItem_Data({...Item_Data,AlternativeItemCode:e.target.value})}
                                                onInput={toInputUppercase}
                                                fullWidth
                                            />
                                        </Col>
                                        <Col lg={2} xl={2} md={2} >
                                                <TextField
                                                    name="FlatRate"
                                                    id="FlatRate"
                                                    label="Flat Rate"
                                                    type="number"
                                                    value={Item_Data.FlatRate}
                                                    onChange={e=>setItem_Data({...Item_Data,FlatRate:e.target.value})}
                                                    fullWidth
                                                />
                                                </Col>
                                                <Col lg={2} xl={2} md={2} >
                                                <TextField
                                                    name="MinFlatRate"
                                                    id="MinFlatRate"
                                                    label="Min Flat Rate"
                                                    type="number"
                                                    value={Item_Data.MinFlatRate}
                                                    onChange={e=>setItem_Data({...Item_Data,MinFlatRate:e.target.value})}
                                                    fullWidth
                                                />
                                            </Col>
                                            <Col lg={2} xl={2} md={2} >
                                                <TextField
                                                    name="MaxFlatRate"
                                                    id="MaxFlatRate"
                                                    label="Max Flat Rate"
                                                    type="number"
                                                    value={Item_Data.MaxFlatRate}
                                                    onChange={e=>setItem_Data({...Item_Data,MaxFlatRate:e.target.value})}
                                                    fullWidth
                                                />
                                            </Col>
                                            <Col lg={2} xl={2} md={2} >
                                                <TextField
                                                    name="MaxSaleQty"
                                                    id="MaxSaleQty"
                                                    label="Max Sale Qty"
                                                    type="number"
                                                    value={Item_Data.MaxSaleQty}
                                                    onChange={e=>setItem_Data({...Item_Data,MaxSaleQty:e.target.value})}
                                                    fullWidth
                                                />
                                            </Col>
                                            <Col lg={2} xl={2} md={2} >
                                                <TextField
                                                    name="GST"
                                                    id="GST"
                                                    label={Comp_Id === 6 ? "VAT% " : "GST%"}
                                                    type="number"
                                                    value={Item_Data.GST}
                                                    onChange={e=>setItem_Data({...Item_Data,GST:e.target.value})}
                                                    fullWidth
                                                />
                                            </Col>
                                            </Row>
                                            <Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'2%'}} >
                    <Col style={{ textAlign: "right" }}>
                        {btn_edit && (
                        <button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={Save_Handler} disabled={btn_Disabled}> 
                           {
                             btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                                }  </button>)}
                      
                        <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{ border: "none" }} to='/ItemList' > <Cancel /> <u>Close</u></Link>

                    </Col>
                </Row>
                  </Tab>
                                    <Tab eventKey="FinYears" title="Flat Rate Policy"  disabled={isAddNew}>
                                        <Row className="row-margin-left row-margin-right">
                                        <Col lg={12} xl={12} sm={12}>
                                        <Row className="row_margin_top row_margin_left row_margin_right pt-4" style={{ width: '80%', marginLeft: '10%' }} >
                        <div style={{ display: "flex", width: "100%", height: "100%", overflow: "hidden" }}>
                            <div style={{ flex: "1", width: "100%", height: "100%" }}>
                                <GridComponent dataSource={FlatRatePolicy} actionBegin={FlatbeforeRowInsert} ref={g => grid = g} actionComplete={FlatafterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: btnFlatEdit, allowDeleting: btnFlatDelete, allowAdding: btnFlatAdd, newRowPosition: 'Bottom' }} sortSettings={sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={toolbarOptions}>
                                    <ColumnsDirective>
                                        <ColumnDirective width='70' field='SrNo' headerText='SrNo #' Format="{0:n}" allowEditing={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                        <ColumnDirective width='250' field='ItemDiscription' headerText='ItemDiscription' defaultValue={Item_Data.ItemDiscription} allowEditing={false} textAlign='Left' />
                                        <ColumnDirective width='250' field='TargetFromQty' headerText='From Target' editTemplate={TargetFromQty} textAlign='Left' />
                                        <ColumnDirective width='250' field='Criteria' headerText='Criteria' editTemplate={Criteria} textAlign='Left' />
                                        <ColumnDirective width='250' field='FlatRate' headerText='FlatRate' defaultValue={Item_Data.FlatRate}  editTemplate={FlatRate} textAlign='Left' />
                                        <ColumnDirective width='250' field='Remarks' headerText='Remarks' editTemplate={Remarks} textAlign='Left' />

                                        <ColumnDirective field='SchemeID' headerText='SchemeID' visible={false}></ColumnDirective>
                                        <ColumnDirective field='ItemID' headerText='ItemID' visible={false} defaultValue={Item_Data.ItemID}></ColumnDirective>
                                        <ColumnDirective field='CustomerID' visible={false} defaultValue={0}></ColumnDirective>
                                        <ColumnDirective field='BonusQty' visible={false} defaultValue={0}></ColumnDirective>
                                        <ColumnDirective field='QtyLimit' visible={false} defaultValue={0}></ColumnDirective>
                                        <ColumnDirective field='AmountLimit' visible={false} defaultValue={0}></ColumnDirective>
                                        <ColumnDirective field='IsStopDiscount' visible={false} defaultValue={0}></ColumnDirective>
                                        <ColumnDirective field='IsFlatScheme' visible={false} defaultValue={1}></ColumnDirective>
                                        <ColumnDirective field='IsBonusScheme' visible={false} defaultValue={0}></ColumnDirective>
                                        <ColumnDirective field='IsDiscountScheme' visible={false} defaultValue={0}></ColumnDirective>
                                        <ColumnDirective field='IsAllForCompany' visible={false} defaultValue={0}></ColumnDirective>
                                        <ColumnDirective field='IsAllForPG' visible={false} defaultValue={0}></ColumnDirective>
                                        <ColumnDirective field='ManufacturedByID' visible={false} defaultValue={0}></ColumnDirective>
                                        <ColumnDirective field='PGID' visible={false} defaultValue={0}></ColumnDirective>
                                        <ColumnDirective field='Discount1Per' visible={false} defaultValue={0}></ColumnDirective>
                                        <ColumnDirective field='Discount2Per' visible={false} defaultValue={0}></ColumnDirective>
                                        <ColumnDirective field='OurSharePer' visible={false} defaultValue={0}></ColumnDirective>
                                        <ColumnDirective field='IsClaimable' visible={false} defaultValue={0}></ColumnDirective>
                                        <ColumnDirective field='RefrenceNo' visible={false} defaultValue={'0'}></ColumnDirective>
                                        <ColumnDirective field='RefrenceDate' visible={false} defaultValue={GetCurrentDate()}></ColumnDirective>
                                        <ColumnDirective field='StartDate' visible={false} defaultValue={GetCurrentDate()}></ColumnDirective>
                                        <ColumnDirective field='EndDate' visible={false} defaultValue={GetCurrentDate()}></ColumnDirective>
                                        <ColumnDirective field='MaxFlatRate' headerText='MaxFlatRate' visible={false} defaultValue={Item_Data.MaxFlatRate}></ColumnDirective>
                                        <ColumnDirective field='MinFlatRate' headerText='MinFlatRate' visible={false} defaultValue={Item_Data.MinFlatRate}></ColumnDirective>
                                        <ColumnDirective field='Comp_Id' headerText='MinFlatRate' visible={false} defaultValue={Comp_Id}></ColumnDirective>
                                        <ColumnDirective field='ProjectID' headerText='MinFlatRate' visible={false} defaultValue={ProjectID}></ColumnDirective>
                                        <ColumnDirective field='UserID' headerText='MinFlatRate' visible={false} defaultValue={UserID}></ColumnDirective>
                                        <ColumnDirective field='IsCancle' visible={false} defaultValue={0}></ColumnDirective>
                                        </ColumnsDirective>
                                    <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
                                </GridComponent>
                            </div>
                        </div>
                    </Row>
                               </Col>
                                        </Row>
                                        <Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'1%',marginTop:'1%', marginRight:'10%'}} >
                                        <Col style={{ textAlign: "right" }}>
                                            {btn_edit && (
                                            <button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={FlatRate_Handler} disabled={btn_Disabled}> 
                                               {
                                                 btn_Disabled ?
                                                   (<><Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        /> Saving... </>) : <><Save /> Save</>
                                                    }  </button>)}
                                          
                                            <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{ border: "none" }} to='/ItemList' > <Cancel /> <u>Close</u></Link>
                    
                                        </Col>
                                    </Row>
                                    </Tab>
                                    <Tab eventKey="UserProjects" title="Discount Policy"  disabled={isAddNew}>
                                    <Row className="row-margin-left row-margin-right">
                                    <Col lg={12} xl={12} sm={12}>
                                    <Row className="row_margin_top row_margin_left row_margin_right pt-4" style={{ width: '80%', marginLeft: '10%' }} >
                    <div style={{ display: "flex", width: "100%", height: "100%", overflow: "hidden" }}>
                        <div style={{ flex: "1", width: "100%", height: "100%" }}>
                            <GridComponent dataSource={DiscountPolicy} actionBegin={DiscountbeforeRowInsert} ref={g => grid = g} actionComplete={DiscountafterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: btnDiscountDelete, allowDeleting: btnFlatDelete, allowAdding: btnDiscountAdd, newRowPosition: 'Bottom' }} sortSettings={sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={toolbarOptions}>
                                <ColumnsDirective>
                                    <ColumnDirective width='70' field='SrNo' headerText='SrNo #' Format="{0:n}" allowEditing={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                    <ColumnDirective width='250' field='ItemDiscription' headerText='ItemDiscription' defaultValue={Item_Data.ItemDiscription} allowEditing={false} textAlign='Left' />
                                    <ColumnDirective width='150' field='TargetFromQty' headerText='From Target' editTemplate={TargetFromQty} textAlign='Left' />
                                    <ColumnDirective width='150' field='Criteria' headerText='Criteria' editTemplate={Criteria} textAlign='Left' />
                                    <ColumnDirective width='150' field='Discount1Per'  headerText='Discount 1 %' editTemplate={Discount1Per} textAlign='Left'></ColumnDirective>
                                    <ColumnDirective width='150' field='Discount2Per' defaultValue='0'  headerText='Discount 2 %' editTemplate={Discount2Per} textAlign='Left'></ColumnDirective> 
                                    {/*<ColumnDirective width='200' field='IsStopDiscount'   headerText='Stop Discount' editTemplate={IsStopDiscount} template = {IsStopDiscount} ></ColumnDirective>*/} 
                                    <ColumnDirective width='200' field='IsStopDiscount' type = "boolean" displayAsCheckBox = {true} editType = "booleanedit"  headerText='Stop Discount' ></ColumnDirective>
                                    <ColumnDirective width='150' field='OurSharePer' defaultValue='0' headerText='Our Share %' editTemplate={OurSharePer} textAlign='Left'></ColumnDirective>
                                    <ColumnDirective width='150' field='IsClaimable' type = "boolean" displayAsCheckBox = {true} editType = "booleanedit"  headerText='Claimable'></ColumnDirective>
                                    <ColumnDirective width='200' field='StartDate' defaultValue={GetCurrentDate()} headerText='Start Date' editTemplate={StartDate} textAlign='Left'></ColumnDirective>
                                    <ColumnDirective width='200' field='EndDate' defaultValue={GetCurrentDate()}  headerText='End Date' editTemplate={EndDate} textAlign='Left'></ColumnDirective>
                                    <ColumnDirective width='150' field='RefrenceNo' defaultValue='' headerText='Refrence No' editTemplate={RefrenceNo} textAlign='Left'></ColumnDirective>
                                    <ColumnDirective width='150' field='RefrenceDate' defaultValue={GetCurrentDate()} editTemplate={RefrenceDate}  textAlign='Left'></ColumnDirective>
                                    <ColumnDirective width='250' field='Remarks' defaultValue='' headerText='Remarks' editTemplate={Remarks} textAlign='Left' />

                                    <ColumnDirective field='SchemeID' headerText='SchemeID' visible={false}></ColumnDirective>
                                    <ColumnDirective field='ItemID' headerText='ItemID' visible={false} defaultValue={Item_Data.ItemID}></ColumnDirective>
                                    <ColumnDirective field='CustomerID' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='BonusQty' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='QtyLimit' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='AmountLimit' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='IsFlatScheme' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='IsBonusScheme' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='IsDiscountScheme' visible={false} defaultValue={1}></ColumnDirective>
                                    <ColumnDirective field='IsAllForCompany' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='IsAllForPG' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='ManufacturedByID' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='PGID' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective width='250' field='FlatRate' visible={false} defaultValue={0} />
                                    <ColumnDirective field='MaxFlatRate' headerText='MaxFlatRate' visible={false} defaultValue={Item_Data.MaxFlatRate}></ColumnDirective>
                                    <ColumnDirective field='MinFlatRate' headerText='MinFlatRate' visible={false} defaultValue={Item_Data.MinFlatRate}></ColumnDirective>
                                    <ColumnDirective field='Comp_Id'  visible={false} defaultValue={Comp_Id}></ColumnDirective>
                                    <ColumnDirective field='ProjectID' visible={false} defaultValue={ProjectID}></ColumnDirective>
                                    <ColumnDirective field='UserID' visible={false} defaultValue={UserID}></ColumnDirective>
                                    <ColumnDirective field='IsCancle' visible={false} defaultValue={0}></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
                            </GridComponent>
                        </div>
                    </div>
                </Row>
                                    
                                    </Col>
                                    </Row>
                                    <Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'1%',marginTop:'1%', marginRight:'10%'}} >
                                    <Col style={{ textAlign: "right" }}>
                                        {btn_edit && (
                                        <button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={DiscountPolicy_Handler} disabled={btn_Disabled}> 
                                           {
                                             btn_Disabled ?
                                               (<><Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    /> Saving... </>) : <><Save /> Save</>
                                                }  </button>)}
                                      
                                        <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{ border: "none" }} to='/ItemList' > <Cancel /> <u>Close</u></Link>
                
                                    </Col>
                                   </Row>
                                    </Tab>
                                    <Tab eventKey="UserProjects1" title="Bonus Policy"  disabled={isAddNew}>
                                    <Row className="row-margin-left row-margin-right">
                                    <Col lg={12} xl={12} sm={12}>
                                    <Row className="row_margin_top row_margin_left row_margin_right pt-4" style={{ width: '80%', marginLeft: '10%' }} >
                    <div style={{ display: "flex", width: "100%", height: "100%", overflow: "hidden" }}>
                        <div style={{ flex: "1", width: "100%", height: "100%" }}>
                            <GridComponent dataSource={BonusPolicy} actionBegin={BonusbeforeRowInsert} ref={g => grid = g} actionComplete={BonusafterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: btnBonusEdit, allowDeleting: btnBonusDelete, allowAdding: btnBonusAdd, newRowPosition: 'Bottom' }} sortSettings={sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={toolbarOptions}>
                                <ColumnsDirective>
                                    <ColumnDirective width='70' field='SrNo' headerText='SrNo #' Format="{0:n}" allowEditing={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                    <ColumnDirective width='250' field='ItemDiscription' headerText='ItemDiscription' defaultValue={Item_Data.ItemDiscription} allowEditing={false} textAlign='Left' />
                                    <ColumnDirective width='150' field='TargetFromQty' headerText='From Target' editTemplate={TargetFromQty} textAlign='Left' />
                                    <ColumnDirective width='150' field='Criteria' headerText='Criteria' editTemplate={Criteria} textAlign='Left' />
                                    <ColumnDirective width='150' field='BonusQty' headerText='Bonus Qty' editTemplate={BonusQty} textAlign='Left' ></ColumnDirective>
                                    <ColumnDirective width='150' field='OurSharePer' defaultValue='0' headerText='Our Share %' editTemplate={OurSharePer} textAlign='Left'></ColumnDirective>
                                    <ColumnDirective width='150' field='IsClaimable' type = "boolean" displayAsCheckBox = {true} editType = "booleanedit"  headerText='Claimable'></ColumnDirective>
                                    <ColumnDirective width='200' field='StartDate' defaultValue={GetCurrentDate()}  headerText='Start Date' editTemplate={StartDate} textAlign='Left'></ColumnDirective>
                                    <ColumnDirective width='200' field='EndDate' defaultValue={GetCurrentDate()}  headerText='End Date' editTemplate={EndDate} textAlign='Left'></ColumnDirective>
                                    <ColumnDirective width='150' field='RefrenceNo' defaultValue='' headerText='Refrence No' editTemplate={RefrenceNo} textAlign='Left'></ColumnDirective>
                                    <ColumnDirective width='150' field='RefrenceDate' defaultValue={GetCurrentDate()}  editTemplate={RefrenceDate}></ColumnDirective>
                                    <ColumnDirective width='250' field='Remarks' defaultValue='' headerText='Remarks' editTemplate={Remarks} textAlign='Left' />
                                    <ColumnDirective field='SchemeID' headerText='SchemeID' visible={false}></ColumnDirective>
                                    <ColumnDirective field='ItemID' headerText='ItemID' visible={false} defaultValue={Item_Data.ItemID}></ColumnDirective>
                                    <ColumnDirective field='CustomerID' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='QtyLimit' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='AmountLimit' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='Discount1Per' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='Discount2Per' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='IsFlatScheme' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='IsBonusScheme' visible={false} defaultValue={1}></ColumnDirective>
                                    <ColumnDirective field='IsStopDiscount' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='IsDiscountScheme' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='IsAllForCompany' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='IsAllForPG' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='ManufacturedByID' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective field='PGID' visible={false} defaultValue={0}></ColumnDirective>
                                    <ColumnDirective width='250' field='FlatRate' visible={false} defaultValue={0} />
                                    <ColumnDirective field='MaxFlatRate' headerText='MaxFlatRate' visible={false} defaultValue={Item_Data.MaxFlatRate}></ColumnDirective>
                                    <ColumnDirective field='MinFlatRate' headerText='MinFlatRate' visible={false} defaultValue={Item_Data.MinFlatRate}></ColumnDirective>
                                    <ColumnDirective field='Comp_Id'  visible={false} defaultValue={Comp_Id}></ColumnDirective>
                                    <ColumnDirective field='ProjectID' visible={false} defaultValue={ProjectID}></ColumnDirective>
                                    <ColumnDirective field='UserID' visible={false} defaultValue={UserID}></ColumnDirective>
                                    <ColumnDirective field='IsCancle' visible={false} defaultValue={0}></ColumnDirective>
                                </ColumnsDirective>
                                <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
                            </GridComponent>
                        </div>
                    </div>
                </Row>
                                    
                                    </Col>
                                    </Row>
                                    <Row className="row_margin_top row_margin_left row_margin_right" style={{marginBottom:'1%',marginTop:'1%', marginRight:'10%'}} >
                                    <Col style={{ textAlign: "right" }}>
                                        {btn_edit && (
                                        <button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={BonusPolicy_Handler} disabled={btn_Disabled}> 
                                           {
                                             btn_Disabled ?
                                               (<><Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    /> Saving... </>) : <><Save /> Save</>
                                                }  </button>)}
                                      
                                        <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{ border: "none" }} to='/ItemList' > <Cancel /> <u>Close</u></Link>
                
                                    </Col>
                                   </Row>
                                </Tab>
                               
                                </Tabs>
            </Row> 
            </Card>
        </> , document.getElementById('PageContents')
    )

 
}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    isAddNew:state.Items && state.Items.isAddNew,
    ItemID: state.Items && state.Items.ItemID,
    UserID: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    show_screen: state.SCREEN,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

  })
export default connect(mapStateToProps)(ItemEntry)
