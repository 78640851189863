import React from 'react'
import ReactDOM from 'react-dom'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
const Tracking = () => {
  return ReactDOM.createPortal (
    <>
    <h1>This Feature will introduce Comming soon</h1>
    </>, document.getElementById('PageContents')
  )
}

export default Tracking