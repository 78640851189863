import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "../SaleReports/Table.css"
import { rptDailyActivity } from '../../../Services/PL_ReportsAPI'
import { SelectUserProjects } from '../../../Services/Projects'

function DailyActivityReport({Comp_Name,Comp_Id,User_Name,User_Id,ConfigObj}) {
    const { ProjectID, FromDate, ToDate } = useParams();

    const [ItemData, setItemData] = useState([]);
    const [SelectedProject, setSelectedProject] = useState(null)

    useEffect(() => {
        document.title = "Distribution / Daily Activity Report" 

        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('footer').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;

        rptDailyActivity(Comp_Id,ProjectID,FromDate,ToDate,(mData)=>{
            var mArray = []
            mData[0].map((value,index)=>{
              
                if(value.InventoryID === "Total")
                {
                    if(value.Transaction_Type === 'OP' || value.Transaction_Type === 'PUR' || value.Transaction_Type === 'PR' || value.Transaction_Type === 'INV' || value.Transaction_Type === 'SR')
                    {
                        value.Qty = value.Qty.split('~')[1]
                        value.PurchaseRate = value.PurchaseRate.split('~')[1]
                        value.WareHouse = ''
                    }
                    else if(value.Transaction_Type === 'Stock Transfer' || value.Transaction_Type === 'Stock Adjustment')
                    {
                        value.Qty = value.Qty.split('~')[1]
                        value.PurchaseRate = ''
                        value.WareHouse = ''
                    }
                    else if(value.Transaction_Type === 'Payment' || value.Transaction_Type === 'Receipts')
                    {
                        value.Qty = ''
                        value.PurchaseRate = value.PurchaseRate.split('~')[1]
                        value.WareHouse = value.WareHouse.split('~')[1]
                    }
                    else if(value.Transaction_Type === 'Expence')
                    {
                        value.Qty = ''
                        value.PurchaseRate = value.PurchaseRate.split('~')[1]
                        value.WareHouse = ''
                    }
                }
                if(value.Transaction_Type === 'OP')
                value.Transaction_Type = 'Opening'
                else if(value.Transaction_Type === 'PUR')
                value.Transaction_Type = 'Purchase'
                else if(value.Transaction_Type === 'PR')
                value.Transaction_Type = 'Purchase Return' 
                else if(value.Transaction_Type === 'INV')
                value.Transaction_Type = 'Sale' 
                else if(value.Transaction_Type === 'SR')
                value.Transaction_Type = 'Sale Return' 


                if(mData[0][index - 1]){
                    if(value.Transaction_Type !== mData[0][index - 1].Transaction_Type){
                        mArray.push({TrType:value.Transaction_Type,title:true})
                    }
                }
               else{
                mArray.push({TrType:value.Transaction_Type,title:true})
               }
                mArray.push(value)
            })
            setItemData(mArray)
        
        })
     
        
        SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
        mProjects.map(value=>{
            if(value.Project_ID == ProjectID){
                setSelectedProject(value)
            }
        })
        })
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
            <Row style={{ textAlign: 'center', color: "black" }}>
            <Col sm={3} md={3} xl={3}><img src={ConfigObj.Logo} width="100" height="100" /></Col>
            <Col sm={6} md={6} xl={6}>
                    <h2> {Comp_Name} </h2> <br /> <h3>Daily Activity Report</h3>
                </Col>
                </Row>
                <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Project:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                         {SelectedProject && SelectedProject.ProjectName}
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'5%', }} >
                    <Row>
                        <Col lg={6} md={6} xl={6} >
                         <b>From Date:</b>
                        </Col>
                        <Col lg={6} md={6} xl={6} >
                         {ReverseDate(FromDate)}
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} md={4} xl={4} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'5%', }} >
                    <Row>
                        <Col lg={6} md={6} xl={6} >
                         <b>To Date:</b>
                        </Col>
                        <Col lg={6} md={6} xl={6} >
                         {ReverseDate(ToDate)}
                        </Col>
                    </Row>
                </Col>
              
            </Row>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                    <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'left', fontWeight: "bold" }}   >Date</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >No.</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Type</th>
                            <th style={{ width: '20%', textAlign: 'left', fontWeight: "bold" }} >Item</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Qty</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Rate</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Warehouse</th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                         
                        {
                           ItemData.map((Itemdata, index) => {

                            return(
                                                               
                                <>
                                {
                                    Itemdata.title === true ? 
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none',borderBottom:'none'}}>
                                        <td colSpan={7} style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.TrType}</td>
                                       </tr>
                                    :
                                    Itemdata.InventoryID === "Total" ? 
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none'}}>
                                        <td colSpan={4} style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold',borderRight:'2px solid black' }}  >Total</td>
                                       {Itemdata.Qty !== '' ? <td colSpan={1} style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  > {Itemdata.Qty} </td> :  <td colSpan={1} style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  ></td>}
                                      {Itemdata.PurchaseRate !== '' ?  <td colSpan={1} style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  > {Itemdata.PurchaseRate} </td> :  <td colSpan={1} style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  ></td>} 
                                      {Itemdata.WareHouse !== '' ? <td colSpan={1} style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  > {Itemdata.Qty} </td> : <td colSpan={1} style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  ></td>}  
                                    </tr>
                                    :
                                    <tr key={index} >
                                        <td style={{ width: '10%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{Itemdata.Transaction_Date || Itemdata.Transaction_Date != '' && ReverseDate(Itemdata.Transaction_Date)}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.OpeningNo === "" ? "-" : Itemdata.OpeningNo}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Transaction_Type}</td>

                                        <td style={{ width: '20%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.ItemDiscription}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.Qty} </td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.PurchaseRate}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.WareHouse} </td>
                                    </tr>
                                }
                                </>
                               
                            )}
                            )
                        }
                   
                    </tbody>


                </table>

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig
})

export default connect(mapStateToProps)(DailyActivityReport)
