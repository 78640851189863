import React, { useEffect, useState } from 'react'
import { Modal, Card, Row, Col } from "react-bootstrap"
import { Cancel,Add } from "@material-ui/icons"
import { Link } from "react-router-dom";
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { connect } from 'react-redux'
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { set_OB_TO_SI,set_FormDirection,set_SI } from "../../React_Redux/Actions"

function OrderBookingDetailModal({show, onHide,customer,mData,set_OB_TO_SI,set_FormDirection,projectData,set_SI}) {
    const [DetailData, setDetailData] = useState([])
    useEffect(()=>{
        if(show){
            setDetailData(mData)
        }
    },[show])

    const column_OBDetail = [
        {
          name: "ItemDiscription",
          label: "Item Description",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
            name: "Qty",
            label: "Qty",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
              customBodyRender: (dataIndex, rowIndex) => {
                var OrderBookingID = DetailData
                if (OrderBookingID[rowIndex.rowIndex] != null)
                       return(
                            <TextField
                                type='number'
                                value={DetailData[rowIndex.rowIndex].Qty}
                                id={'Qty'+rowIndex.rowIndex}
                                name={'Qty'}
                                disabled={DetailData[rowIndex.rowIndex].Status === 'Pending' ? false : true}
                                onChange={(e) => {
                                 let newLevelData = [...DetailData]
                                   if(!Number.isNaN(e.target.valueAsNumber))
                                   {
                                    newLevelData[rowIndex.rowIndex].Qty = e.target.valueAsNumber
                                    setDetailData(newLevelData)
                                   } 
                                   else
                                   {
                                    newLevelData[rowIndex.rowIndex].Qty = 0.00
                                    setDetailData(newLevelData)
                                   }
                                }}
                                style={{
                                    width:'30%'
                                }}
                            />
                  );
              },
            }
          },
        {
            name: "Value",
            label: "Value",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
            }
          },
           {
            name: "Status",
            label: "Status",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },customBodyRender: (dataIndex, rowIndex) => {
                var OrderBookingID = DetailData
                
                var mClass = ''
                if(DetailData[rowIndex.rowIndex].Status === 'Pending'){
                  mClass = 'danger'
                }
                if(DetailData[rowIndex.rowIndex].Status === 'Approved'){
                  mClass = 'info'
                }
                if(DetailData[rowIndex.rowIndex].Status === 'Dispatched'){
                  mClass = 'success'
                }
                if (OrderBookingID[rowIndex.rowIndex] != null)
                  return (
                    <>
                      <div class={`badge badge-${mClass}-lighten`}>{DetailData[rowIndex.rowIndex].Status}</div>
                    </>
                  );
              },
            }
           }
    ];
    const theme = createTheme({
        overrides: {
          MuiTableCell: {
            root: {
              paddingTop: 0,
              paddingBottom: 0,
            }
          }
        }
      });
      const Create_invoice = ()=>{
        var mArray = []
        if(mData.length > 0){
          mData.map(value=>{
            debugger
            if(value.Status === 'Pending'){
              mArray.push(value)
            }
          })
        }
        if(mArray.length > 0 ){
          set_OB_TO_SI(mArray,customer);
          set_FormDirection('OrderBooking')
          set_SI(null,0,true, projectData.Project_ID)
          document.getElementById("btn_invoice").click();
        }
      }
      
    return (
        <>
        <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" >
            <Modal.Header closeButton className="header_title" >
            <Modal.Title> Order Booking Detail / {customer && customer.CustomerName} </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Row className=" row_margin_left row_margin_right" >
                            <Col>
                                <div className="table-responsive" >
                                <MuiThemeProvider theme={theme}>
                                    <MUIDataTable
                                        name="DetailDatatables"
                                        data={DetailData}
                                        columns={column_OBDetail}
                                        options={{
                                            selectableRows: 'none',
                                            print: false,
                                            search: false,
                                            download: false,
                                            viewColumns: false,
                                            filter: false,
                                        }}
                                    />
                                    </MuiThemeProvider>
                                </div>
                            </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                            <Link   to="/SaleInvoiceEntry" id='btn_invoice' ></Link>
                            <button type="button" className="btn btn-primary rounded-pill" onClick={Create_invoice} style={{ border: "none" }} > <Add /> <u>Create Invoice</u></button>
                            
                                <button type="button" className="btn btn-primary rounded-pill" onClick={onHide} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
        </Modal>
        </>
    )
}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
  })
export default connect(mapStateToProps,{set_OB_TO_SI,set_FormDirection,set_SI})(OrderBookingDetailModal)
