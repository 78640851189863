import React, { useState, useEffect } from 'react'
import { Modal, Card, Row, Col, ProgressBar } from "react-bootstrap"
import { Watch } from  'react-loader-spinner'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import './Loader.css'
function Loader(props) {


    return (
        <>
            <Modal
                show={props.show}
                onHide={props.hide}
                backdrop="static"
                dialogClassName="modal-90w"
                size="sm"
                centered
                id='myModal'
            >
                <Modal.Body >
                    {
                        !props.isProgressBar ? (
                            <div style={{ marginLeft: '38%' }}>
                                <Watch
                                    height="80"
                                    width="80"
                                    radius="48"
                                    color="#313A46"
                                    ariaLabel="watch-loading"
                                    wrapperStyle={{}}
                                    wrapperClassName=""
                                    visible={true}
                                />
                            </div>
                        ) : (
                            <ProgressBar animated now={props.value} />
                        )
                    }

                </Modal.Body>

            </Modal>
        </>
    )
}



export default Loader
