import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectArea =(Comp_Id,ProjectID,Callback) =>{
    
  axios.get(`${domain}/Area/SelectAreaList/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectAreaDSRForShaigan =(Comp_Id,ProjectID,ManufacturedByID,FromDate,ToDate,Callback) =>{

  axios.get(`${domain}/Area/SelectAreaDSRForShaigan/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${FromDate}/${ToDate}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectAreaDSRForGlacxy =(Comp_Id,ProjectID,ManufacturedByID,FromDate,ToDate,Callback) =>{

  axios.get(`${domain}/Area/SelectAreaDSRForGlacxy/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${FromDate}/${ToDate}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SaveArea = (Area,DeletedArea,Comp_Id,ProjectID,callback) =>{
 
  axios.post(`${domain}/Area/SaveArea`,{
    area:Area,
    Deletedarea:DeletedArea,Comp_Id,ProjectID
  })
    .then(response=>{
        toast.configure()
          toast.info(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        
        callback()
    })
    .catch(err=>console.error(err))
}
export const SelectCityWiseAreas =(Comp_Id,ProjectID,CityID,Callback) =>{
    
  axios.get(`${domain}/Area/SelectCityWiseAreas/${Comp_Id}/${ProjectID}/${CityID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectDataForBackup =(Comp_Id,ProjectID,Callback) =>{
    
  axios.get(`${domain}/Area/SelectDataForBackup/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}