import React, { useState, useEffect } from 'react'
import { Modal, Card, Row, Col, Button ,Spinner } from "react-bootstrap"
import { Checkbox, TextField, FormControlLabel } from '@material-ui/core'
import { Autocomplete } from "@material-ui/lab"
import { Save, Cancel } from "@material-ui/icons"
import { connect } from "react-redux"   
import { GetCurrentDate } from '../../../Services/Common'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { File_Download,msg } from '../../../Services/Common'
import { SelectManufacturedByList } from '../../../Services/manufactureAPI'
import { SelectProductGroupAgainstManufacturer } from '../../../Services/ProductGroupAPI'
import { SelectcustomersList } from '../../../Services/CustomersAPI'
import { SelectUserProjects } from '../../../Services/Projects'
import Loader from '../../Loader';
import Swal from 'sweetalert2'
import JSZip from "jszip";
import { saveAs } from "file-saver";
function ComparisionReports_Modal({show, onHide,Comp_Id,ProjectID,toPath,User_Id}) {
 const [SelectedReport, setSelectedReport] = useState(null)
 const [Manufacturer_list,setManufacturer_List] = useState([])
 const [SelctedManufacturer, setSelectedManufacturer] = useState(null)
 const [ComparisionSelctedManufacturer, setComparisionSelectedManufacturer] = useState(null)
 const [PG_List, setPG_List] = useState([])
 const [SelectedPG, setSelectedPG] = useState(null)
 const [ComparisionPG_List, setComparisionPG_List] = useState([])
 const [ComparisionSelectedPG, setComparisionSelectedPG] = useState(null)
 const [FromDate, setFromDate] = useState(GetCurrentDate())
 const [ToDate, setToDate] = useState(GetCurrentDate())
 const [ComparisonFromDate, setComparisionFromDate] = useState(GetCurrentDate())
 const [ComparisionToDate, setComparisionToDate] = useState(GetCurrentDate())
 const [Customer_List, setCustomer_List] = useState([])
 const [SelectedCustomer, setSelectedCustomer] = useState(null)
//  const [ComparisionCustomer_List, setComparisionCustomer_List] = useState([])
 const [ComparisionSelectedCustomer, setComparisionSelectedCustomer] = useState(null)
    const [ForCompany, setForCompany] = useState(false)
    const [ForCustomer, setForCustomer] = useState(false)
    const [ForSingleCustomer, setForSingleCustomer] = useState(false)
    const [CompanyDisabled, setCompanyDisabled] = useState(true)
    const [PGDisabled, setPGDisabled] = useState(true)
    const [CustomerDisabled, setCustomerDisabled] = useState(true)
    const [ComparisionCustomerDisabled, setComparisionCustomerDisabled] = useState(true)
    const [Project_List, setProject_List] = useState([])
    var [SelectedProject, setSelectedProject] = useState(null)
 const [IsLoader, setIsLoader] = useState(false)
 const zip = new JSZip();
    useEffect(() => {
        document.title = "Distribution / Sale Comparison"
        setSelectedCustomer(null)
        setSelectedPG(null)
        setSelectedManufacturer(null)
        if (show && toPath) {
            SelectUserProjects(Comp_Id, User_Id, (mProjects) => {
                setProject_List(mProjects)
            })
            // SelectManufacturedBy_List()
            // Select_Customers_List()
        }
      
    }, [show])
    const SelectManufacturedBy_List = (ProjectID)=>{
        SelectManufacturedByList(Comp_Id,ProjectID,(mData)=>{
            setManufacturer_List(mData)
        })
    }
    const Select_ProductGroupAgainstManufacturer = (MID, chk) =>{
        SelectProductGroupAgainstManufacturer(Comp_Id,ProjectID,MID,(mData)=>{
            var mObj = {PGID:0, PGName: 'SELECT ALL'}
            mData.unshift(mObj)
            chk ? setPG_List(mData) : setComparisionPG_List(mData)
        })
    }

    const Select_Customers_List = (ProjectID) =>{
        SelectcustomersList(Comp_Id,ProjectID,(mCustomer)=>{
            // var mObj = {CustomerID: 0, CustomerName: 'SELECT ALL'}
            // mCustomer.unshift(mObj)
           setCustomer_List(mCustomer)
        })
    }
    const clearFields = () =>{
        onHide()
        setSelectedReport(null)
        setSelectedManufacturer(null)
        setFromDate(GetCurrentDate())
        setToDate(GetCurrentDate())
    }
    
  
    const showLoader = () => {
        setIsLoader(true)
      }
      const  hideLoader = () => {
        setIsLoader(false)
        }
    const NavigateToReport = () =>{
        if(toPath=== '/SaleComparision'){
           if(SelectedProject){
                   if(SelctedManufacturer && SelectedPG ){
             
                    var mObj = {
                        ManufacturedByID: SelctedManufacturer.ManufacturedByID,
                        ManufacturedBy:SelctedManufacturer.ManufacturedBy,
                        // CManufacturedByID: ComparisionSelctedManufacturer.ManufacturedByID,
                        // CManufacturedBy: ComparisionSelctedManufacturer.ManufacturedBy,
                        PGID: SelectedPG.PGID,
                        PGName: SelectedPG.PGName,
                        // CPGID: ComparisionSelectedPG.PGID,
                        // CPGName: ComparisionSelectedPG.PGName,
                        FromDate: FromDate,
                        ToDate: ToDate,
                        // CFromDate: ComparisonFromDate,
                        // CToDate: ComparisionToDate,
                        ProjectID: SelectedProject.Project_ID,
                        CustomerID: SelectedCustomer ? SelectedCustomer.CustomerID : 0,
                        CustomerName: SelectedCustomer ? SelectedCustomer.CustomerName : ''
                    }
                    
                    localStorage.setItem('SaleComparision', JSON.stringify(mObj))
                    window.open(`${toPath}`,'_blank')
                   }
                   else{
                    msg(`Required Field Must be Selected`)
                }
                
                
               
           }
        }
    }   
    return (    
        <>
            <Modal show={show} size="lg" centered onHide={clearFields} backdrop="static" >
                <Modal.Header closeButton className="header_title " >
                    <Modal.Title > Mont Wise Sale Reports </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                <Row>
                <Col sm={12} lg={3} xl={3} md={3} >
                <FormControlLabel
                control={
                    <Checkbox
                        checked={ForCompany}
                        id="ForCompany"
                        label="For Company"
                         disabled = {ForCompany}
                        onChange={e=> {setForCompany(e.target.checked)            
                        setCompanyDisabled(false)
                        setPGDisabled(false)
                       setForCustomer(false)
                       setForSingleCustomer(false)
                       setCustomerDisabled(true)
                       setComparisionCustomerDisabled(true)
                       setSelectedCustomer(null)
                        }}
                        name="Access"
                        color="primary"
                    />
                }
                label={"For Company"}
                style={{
                    padding: "0px",
                    marginLeft:'3px'
                  
                }}
            />
                </Col>
                <Col sm={12} lg={3} xl={3} md={3} >
                <FormControlLabel
                control={
                    <Checkbox
                        checked={ForCustomer}
                        id="ForCustomer"
                        label="For Customer"
                         disabled = {ForCustomer}
                        onChange={e=> {setForCustomer(e.target.checked)
                            setForCompany(false)
                            setCompanyDisabled(false)
                            setPGDisabled(false)
                            setCustomerDisabled(false)
                            setComparisionCustomerDisabled(false)
                            setForSingleCustomer(false)

                        }}
                        name="Access"
                        color="primary"
                    />
                }
                label={"For Customer"}
                style={{
                    padding: "0px",
                    marginLeft:'3px'
                  
                }}
            />
                </Col>
             
               {/*  <Col sm={12} lg={4} xl={4} md={4} >
                <FormControlLabel
                control={
                    <Checkbox
                        checked={ForSingleCustomer}
                        id="ForSingleCustomer"
                        label="For Single Customer"
                       disabled = {ForSingleCustomer}
                        onChange={e=> {setForSingleCustomer(e.target.checked)
                        setForCustomer(false)
                         setForCompany(false)
                         setCompanyDisabled(true)
                         setPGDisabled(true)
                         setCustomerDisabled(false)
                         setComparisionCustomerDisabled(true)
                        }}
                        name="Access"
                        color="primary"
                    />
                }
                label={"For Single Customer"}
                style={{
                    padding: "0px",
                    marginLeft:'3px'
                  
                }}
            />
                </Col>*/}
        
                </Row>
                <Row style={{marginLeft:'3px', marginBottom:'8px'}}>
                <Col sm={12} lg={4} xl={4} md={4} >
                <Autocomplete
                    name="Project"
                    id="Project"
                    options={Project_List}
                    getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                    value={SelectedProject}
                    // disabled={readonlyProps.Project}
                    onChange={(e, value) => {
                        if (value) {
                            setSelectedProject(value)
                            SelectManufacturedBy_List(value.Project_ID) 
                            Select_Customers_List(value.Project_ID) 
                        }
                        else {
                            setSelectedProject(null)
                            setManufacturer_List([])
                            setSelectedManufacturer(null) 
                        }

                    }}
                    renderInput={(params) =>
                        <TextField {...params} name="Project" label="Branch"
                        error = {!(SelectedProject)}
                        fullWidth
                        helperText={!(SelectedProject) ? 'Required' : ''}
                        />
                    }
                />
               
            </Col>
                </Row>
                <Row>
                <Col sm={12} lg={12} xl={12} md={12} >
                <Card style={{borderRadius:'10px', boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}>
                      
                <Row className=" row_margin_top row_margin_left row_margin_right p-2" >
                <Col sm={12} lg={6} xl={6} md={6} >
                <TextField 
                    value={FromDate}
                    name="FromDate"
                    label="From Date" 
                    type= 'date'
                    onChange= {e => setFromDate(e.target.value)}
                    fullWidth
                />
            </Col>
            <Col sm={12} lg={6} xl={6} md={6} >
            <TextField 
                value={ToDate}
                name="ToDate"
                label="To Date" 
                type= 'date'
                onChange= {e => setToDate(e.target.value)}
                fullWidth
            />
        </Col>
                </Row>
                <Row className=" row_margin_top row_margin_left row_margin_right p-2" >
             
                <Col lg={6} xl={6} md={6} >
                <Autocomplete
                    error
                    name="ManufacturedBy"
                    id="ManufacturedBy"
                    options={Manufacturer_list}
                    getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""}
                    value={SelctedManufacturer}
                    disabled = {CompanyDisabled}
                    onChange={(e, value) => {
                        if (value)
                        {
                            
                            setSelectedManufacturer(value)
                            Select_ProductGroupAgainstManufacturer(value.ManufacturedByID,true)
                          
                        }
                        else
                        {
                            setSelectedManufacturer(null) 
                        }
                    }}
                    renderInput={(params) =>
                        <TextField {...params} name="ManufacturedBy" label="Manufacturer"
                           error = {!(SelctedManufacturer || CompanyDisabled)}
                            fullWidth
                            helperText={!(SelctedManufacturer || CompanyDisabled) ? 'Required' : ''}
                        />}
                        fullWidth
                />
            </Col>
            <Col lg={6} xl={6} md={6} >
            <Autocomplete
                error
                name="PGName"
                id="PGName"
                options={PG_List}
                getOptionLabel={(option) => option.PGName ? option.PGName : ""}
                value={SelectedPG}
                disabled = {PGDisabled}
                onChange={(e, value) => {
                    if (value)
                    {
                        setSelectedPG(value)
                       
                    }
                    else
                    {
                        setSelectedPG(null) 
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="PGName" label="Product Group"
                       error = {!(SelectedPG || PGDisabled)}
                        fullWidth
                        helperText={!(SelectedPG || PGDisabled)? 'Required' : ''}
                    />}
                    fullWidth
            />
        </Col>
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right p-2" > 
            <Col lg={12} xl={12} md={12} >
            <Autocomplete
                error
                name="CustomerName"
                id="CustomerName"
                options={Customer_List}
                getOptionLabel={(option) => option.CustomerName ? option.CustomerName : ""}
                value={SelectedCustomer}
                disabled = {CustomerDisabled}
                onChange={(e, value) => {
                    if (value)
                    {
                        setSelectedCustomer(value)
                       
                    }
                    else
                    {
                        setSelectedCustomer(null) 
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="CustomerName" label="Select Customer"
                       error = {!(SelectedCustomer || CustomerDisabled)}
                        fullWidth
                        helperText={!(SelectedCustomer || CustomerDisabled)  ? 'Required' : ''}
                    />}
                    fullWidth
            />
        </Col>
                    </Row>
               
            </Card>
                </Col>
               {/* <Col sm={12} lg={6} xl={6} md={6} >
                <Card style={{borderRadius:'10px', boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}}>
                      
                <Row className=" row_margin_top row_margin_left row_margin_right p-2" >
                <Col sm={12} lg={6} xl={6} md={6} >
                <TextField 
                    value={ComparisonFromDate}
                    name="FromDate"
                    label="From Date" 
                    type= 'date'
                    disabled = {ForSingleCustomer}
                    onChange= {e => setComparisionFromDate(e.target.value)}
                    fullWidth
                />
            </Col>
            <Col sm={12} lg={6} xl={6} md={6} >
            <TextField 
                value={ComparisionToDate}
                name="ToDate"
                label="To Date" 
                type= 'date'
                disabled = {ForSingleCustomer}
                onChange= {e => setComparisionToDate(e.target.value)}
                fullWidth
            />
        </Col>
                </Row>
                <Row className=" row_margin_top row_margin_left row_margin_right p-2" >
             
                <Col lg={6} xl={6} md={6} >
                <Autocomplete
                    error
                    name="ManufacturedBy"
                    id="ManufacturedBy"
                    options={Manufacturer_list}
                    getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""}
                    value={ComparisionSelctedManufacturer}
                    disabled = {CompanyDisabled}
                    onChange={(e, value) => {
                        if (value)
                        {
                            
                            setComparisionSelectedManufacturer(value)
                            Select_ProductGroupAgainstManufacturer(value.ManufacturedByID, false)
                        }
                        else
                        {
                            setSelectedManufacturer(null) 
                        }
                    }}
                    renderInput={(params) =>
                        <TextField {...params} name="ManufacturedBy" label="Manufacturer"
                           error = {!(ComparisionSelctedManufacturer || CompanyDisabled)}
                            fullWidth
                            helperText={!(ComparisionSelctedManufacturer || CompanyDisabled) ? 'Required' : ''}
                        />}
                        fullWidth
                />
            </Col>
            <Col lg={6} xl={6} md={6} >
            <Autocomplete
                error
                name="PGName"
                id="PGName"
                options={ComparisionPG_List}
                getOptionLabel={(option) => option.PGName ? option.PGName : ""}
                value={ComparisionSelectedPG}
                disabled = {PGDisabled}
                onChange={(e, value) => {
                    if (value)
                    {
                        setComparisionSelectedPG(value)
                       
                    }
                    else
                    {
                        setComparisionSelectedPG(null) 
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="PGName" label="Product Group"
                       error = {!(ComparisionSelectedPG || PGDisabled)}
                        fullWidth
                        helperText={!(ComparisionSelectedPG || PGDisabled)  ? 'Required' : ''}
                    />}
                    fullWidth
            />
        </Col>
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right p-2" > 
                    <Col lg={12} xl={12} md={12} >
                    <Autocomplete
                        error
                        name="CustomerName"
                        id="CustomerName"
                        options={Customer_List}
                        getOptionLabel={(option) => option.CustomerName ? option.CustomerName : ""}
                        value={ComparisionSelectedCustomer}
                        disabled = {ComparisionCustomerDisabled}
                        onChange={(e, value) => {
                            if (value)
                            {
                                setComparisionSelectedCustomer(value)
                               
                            }
                            else
                            {
                                setComparisionSelectedCustomer(null) 
                            }
                        }}
                        renderInput={(params) =>
                            <TextField {...params} name="CustomerName" label="Select Customer"
                               error = {!(ComparisionSelectedCustomer || ComparisionCustomerDisabled)}
                                fullWidth
                                helperText={!(ComparisionSelectedCustomer || ComparisionCustomerDisabled) ? 'Required' : ''}
                            />}
                            fullWidth
                    />
                </Col>
                            </Row>
            </Card>
                </Col>*/}
                </Row>
                <Row className=" row_margin_top row_margin_left row_margin_right" >
                <Col style={{ textAlign: "right", marginTop: "1%" }} className='m-2' >
                    <Button variant="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={NavigateToReport}>
                  <CloudDownloadIcon />  Load
                    </Button>
                    <Button variant="btn btn-primary rounded-pill"  style={{ border: "none" }} onClick={clearFields} > <Cancel /> <u>Cancel</u></Button>
                </Col>
            </Row>
                </Modal.Body>
            </Modal>
            <Loader
            show={IsLoader}
            hide={hideLoader}
            />
        </>
    )
}
const mapStateToProps = state => ({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    show_screen: state.SCREEN,    
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
    Comp_Name: state.GlobalVariables.Company && state.GlobalVariables.Company.Name,
    Comp_Address: state.GlobalVariables.Company && state.GlobalVariables.Company.Address,
    User_Id: state.Login_User.User_Data && state.Login_User.User_Data.User_id
})

export default connect(mapStateToProps)(ComparisionReports_Modal)
