import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Row, Col, Card, Button } from 'react-bootstrap'
import { Add, Edit,Loop,Details ,ViewAgenda} from '@material-ui/icons'
import {TextField,FormControlLabel,Checkbox} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import  { connect } from 'react-redux'

import  { SelectBooking,SelectBookingDetail } from '../../Services/OrderBookingAPI'
import { SelectUserProjects } from "../../Services/Projects"
import  { SHOW_SCREEN, set_FormDirection,set_OB_TO_SI,set_SI } from '../../React_Redux/Actions'
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import { GetCurrentDate } from '../../Services/Common'
import { SelectArea } from '../../Services/AreasAPI';
import { SelectCityList } from '../../Services/CityAPI';
import OrderBookingDeatilModal from './OrderBookingDeatilModal';
import Loader from '../Loader';

import socketIOClient from "socket.io-client";

function OrderBooking(props) {

    const {  Comp_Id,ProjectID, SHOW_SCREEN, Group_Id,set_FormDirection,set_OB_TO_SI,set_SI,p_userid } = props;

    const [Booking_Data, setBooking_Data] = useState([])
    const [FromDate, setFromDate] = useState(GetCurrentDate())
    const [ToDate, setToDate] = useState(GetCurrentDate())
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [LoadAlll, setLoadAlll] = useState(false)
    const [OrderBookingDetail, setOrderBookingDetail] = useState([])
    const [CustomerName, setCustomerName] = useState(null)
    const [Show_OBDetailModal, setShow_OBDetailModal] = useState(false)
    const [Area_List, setArea_List] = useState([])
    const [SelectedAreaOption, setSelectedAreaOption] = useState(null)
    const [ProjectList, setProjectList] = useState([])
    const [SelectedProject, setSelectedProject] = useState(null)
    const [error_State, seterror_State] = useState({
      Project_Err: '',
      Area_Err : ''
    });
    const [IsLoader, setIsLoader] = useState(false)

    useEffect(()=>{
      document.title = " Distribution / Order Booking"
    //   debugger
    // if(NotificationData !== ""){
    //   debugger
    // }
    //   SelectGroupScreenAccess(Group_Id,11,91,(data)=>{
            
    //     setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
    //     setBtn_delete(parseInt(data.Del) === 0 ? false : true)
  
    //     SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
    //     parseInt(data.Edit) === 0 ? false : true,
    //     parseInt(data.Del) === 0 ? false : true,
    //     data.OtherAccess
    //     )
        
    // })
   
    Areas_List()
    Projects_List()
    set_FormDirection(null)
    set_OB_TO_SI(null,null)
    set_SI(null,0,false, null)

    // const socket = socketIOClient(domain_for_live_Notifications);
    // socket.on("chat message", data => {
    //   setResponse(data);
    // });
    },[])

    const Projects_List = () =>{
      SelectUserProjects(Comp_Id,p_userid,(ProjectData) =>{
        setProjectList(ProjectData)
        setSelectedProject(ProjectData[0])
        Select_Booking(FromDate,ToDate,0,ProjectData[0].Project_ID)
      })
    }
    const Booking_Items = [
    
        {
         name: "BookingCode",
         label: "Booking Code",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
        {
         name: "CustomerName",
         label: "Customer Name",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
         name: "BookingDate",
         label: "Booking Date",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },
         }
        },
        {
            name: "booking_time",
            label: "Booking Time",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
        {
          name: "User_name",
          label: "Booker Name",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
             return(
               <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
             )
            },
          }
         },
         {
          name: "City",
          label: "City",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
             return(
               <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
             )
            },
          }
         },
           {
            name: "AreaName",
            label: "Area",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
           {
            name: "GrandTotal",
            label: "Grand Total",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           }, 
              {
            name: "IsCompleted",
            label: "Summarized",
            options: {
             filter: true,
             sort: true,
             
             customHeadLabelRender: (columnMeta)=>{
              return(
                <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "center"}} >{columnMeta.label}</div>
              )
             },
              customBodyRender: (dataIndex, rowIndex) => {
                var OrderBookingID = Booking_Data
                if (OrderBookingID[rowIndex.rowIndex] != null)
                  return (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Booking_Data[rowIndex.rowIndex].IsCompleted}
                            id={`chk${rowIndex.rowIndex}`}
                            disabled={ true}
                            onChange={e => {
                            }}
                            color="primary"
                          />
                        }
                        label=""
                        style={{
                          // padding: "0px",
                          // height:'2px'
                        }}
                      />
                    </>
                  );
              }
            }
           },  {
            name: "IsInvoice",
            label: "Invoice Made",
            options: {
             filter: true,
             sort: true,
             
             customHeadLabelRender: (columnMeta)=>{
              return(
                <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "center"}} >{columnMeta.label}</div>
              )
             },
              customBodyRender: (dataIndex, rowIndex) => {
                var OrderBookingID = Booking_Data
                if (OrderBookingID[rowIndex.rowIndex] != null)
                  return (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Booking_Data[rowIndex.rowIndex].IsInvoice}
                            id={`chk${rowIndex.rowIndex}`}
                            disabled={ true}
                            onChange={e => {
                            }}
                            color="primary"
                          />
                        }
                        label=""
                        style={{
                          // padding: "0px",
                          // height:'2px'
                        }}
                      />
                    </>
                  );
              }
            }
           },
              {
            name: "Details",
            label: "Details",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },customBodyRender: (dataIndex, rowIndex) => {
                var OrderBookingID = Booking_Data
                if (OrderBookingID[rowIndex.rowIndex] != null)
                  return (
                    <>
                      <Link to="#" onClick={e=>{View_details(Booking_Data[rowIndex.rowIndex].OBID,Booking_Data[rowIndex.rowIndex].CustomerName,Booking_Data[rowIndex.rowIndex].CustomerID)}}><ViewAgenda/></Link>
                    </>
                  );
              },
            }
           },
        
    ];
    const showLoader = () => {
      setIsLoader(true)
     
    }
  const  hideLoader = () => {
    setIsLoader(false)
    }
   const Select_Booking = (fromdate,todate,AreaID,ProjectID) =>{
    showLoader()
    SelectBooking(Comp_Id,ProjectID,fromdate,todate,AreaID,(mData)=>{
      hideLoader()    
      mData.map(value=>{
            if(value.IsCompleted === 1){
              value.IsCompleted = true
            }
            else{
              value.IsCompleted = false
            }

            if(value.IsInvoice === 1)
            value.IsInvoice = true;
            else
            value.IsInvoice = false;
          })
        setBooking_Data(mData)
    })
   }
   const View_details = (OBID,CustomerName,CustomerID) =>{
    SelectBookingDetail(Comp_Id,ProjectID,OBID,(mData)=>{
    
      setOrderBookingDetail(mData)
      var obj = { CustomerID,CustomerName} 
      setCustomerName(obj)
      showDetail_Modal()
    })
   }
   const Areas_List= () =>{
    SelectCityList(Comp_Id,ProjectID,(mCity)=>{
      var FirstIndex = { 
        CityID:0,
        City:'SELECT ALL'
    }
    mCity.splice(0,0,FirstIndex)
    setSelectedAreaOption(FirstIndex)
    setArea_List(mCity)
    })
   }
   const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });
  const showDetail_Modal = ()=>{
    setShow_OBDetailModal(true)
  }
  const hideDetail_Modal = () =>{
    setShow_OBDetailModal(false)
  }
  const Reload = () =>{
    if(SelectedAreaOption && SelectedProject){
      Select_Booking(FromDate,ToDate,SelectedAreaOption.CityID,SelectedProject.Project_ID)
    }
    else{
      if(!SelectedProject){
        seterror_State({...error_State, Project_Err:'required'})
      }
      else if(!SelectedAreaOption){
        seterror_State({...error_State, Project_Err:'',Area_Err:'required'})
      }
    }
    
  }
    return ReactDOM.createPortal(
      <>
        <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2>Orders
                    </h2>
                </div>
            </div>
        </div>
      <Card>
        
          <Row className="row_margin_left row_margin_right pt-4 pr-4 pl-4" >
   
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            type={'date'}
                            name="FromDate"
                            value={FromDate}
                            label="From Date"
                            onChange={e=>{
                                if(e.target.value){
                                    Select_Booking(e.target.value,ToDate,SelectedAreaOption.CityID,SelectedProject.Project_ID)
                                    setFromDate(e.target.value)
                                }
                               
                            }}
                        />
                    </Col>
                    <Col lg={2} xl={2} md={2} >
                        <TextField
                            type={'date'}
                            name="ToDate"
                            value={ToDate}
                            label="To Date"
                            onChange={e=>{
                                if(e.target.value){
                                    Select_Booking(FromDate,e.target.value,SelectedAreaOption.CityID,SelectedProject.Project_ID)
                                    setToDate(e.target.value)
                                }
                            }}
                        />
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                        <Autocomplete
                            name="Project"
                            id="Project"
                            options={ProjectList}
                            getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                            value={SelectedProject}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    setSelectedProject(value)
                                    seterror_State({...error_State, Project_Err:''})

                                      if(SelectedAreaOption)
                                      Select_Booking(FromDate,ToDate,SelectedAreaOption.CityID,value.Project_ID)
                                }
                                else
                                {
                                  setSelectedProject(null)
                                  setBooking_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Project" label="Select Branch"
                                />}
                        />
                         <span className="text-danger">{error_State.Project_Err}</span>
                    </Col>
                    <Col lg={4} xl={4} md={4} >
                            <Autocomplete
                                name="Area"
                                id="Area"
                                options={Area_List}
                                getOptionLabel={(option) => option.City ? option.City : ""} 
                                value={SelectedAreaOption}
                                onChange={(e,value) =>{
                                if(value)
                                {
                                    setSelectedAreaOption(value)
                                    seterror_State({...error_State,Area_Err:''})
                                    Select_Booking(FromDate,ToDate,value.CityID,SelectedProject.Project_ID)
                                }
                                else{
                                  setSelectedAreaOption(null)
                                  setBooking_Data([])

                                }
                                }}
                                renderInput={(params) => 
                                <TextField {...params} name="Area" label="City"
                                />}
                            />
                             <span className="text-danger">{error_State.Area_Err}</span>
                    </Col>
                    <Col style={{ textAlign: "right", marginTop: "1%" }} >
                    <button className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={Reload} > <Loop /> <u>Reload</u></button>
                    </Col>
          </Row>
          <Row className="row_margin_left row_margin_right" > 
              <Col>              
                <div className="table-responsive" >
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        name = "IPD_Datatables"
                        data={Booking_Data}
                        columns={Booking_Items}
                        options={{
                          selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                    </MuiThemeProvider>
                </div>
              </Col>
          </Row>
          <OrderBookingDeatilModal 
            show={Show_OBDetailModal}
            onHide={hideDetail_Modal}
            customer={CustomerName}
            mData = {OrderBookingDetail}
            projectData={SelectedProject}
          />
            <Loader
        show={IsLoader}
        hide={hideLoader}
        />
      </Card>
                        
      </>  , document.getElementById('PageContents')
    )
}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  ItemCat: state.Items && state.Items.ItemData,
  Group_Id : state.Login_User.User_Data.Group_Id,
  ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
  p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
})
 export default connect(mapStateToProps,{ SHOW_SCREEN,set_FormDirection,set_OB_TO_SI,set_SI})(OrderBooking)
