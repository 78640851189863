import React from 'react'
import ReactDOM from 'react-dom'
import { Row, Col, Card, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Save, Cancel, FileCopy } from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import {
    GridComponent, ColumnsDirective, ColumnDirective,
    Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar
} from '@syncfusion/ej2-react-grids';
import { TextField, FormControlLabel, Checkbox } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
//  import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
import { connect } from 'react-redux'
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import { SaveScheme, SelectSchemeMaster, SelectScheme_Detail, SelectCustForScheme, SelectItemsWRTManufacturer } from '../../Services/SchemeAPI'
import { SelectWareHouseList } from "../../Services/WareHouseAPI";
import { SelectItemsWRTWareHouse } from '../../Services/StockTransferAPI'
import { SelectcustomersList } from "../../Services/CustomersAPI";
import { SelectManufacturedByList } from "../../Services/manufactureAPI";
import { SelectActiveItems } from "../../Services/ItemsAPI"
import { SelectCompanyGroupAgainstManufacturer } from '../../Services/CompanyGroupAPI'
import { GetCurrentDate, preventMinus, msg } from '../../Services/Common'

export class Scheme extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            Scheme: [],
            DeletedScheme: [],
            Item_List: [],
            ItemWRTManufacturer: [],
            Selected_item: null,
            CustomerList: [],
            SelectedCustomer: null,
            ManufacturerList: [],
            SelectedManufacturer: null,
            WHTPer: 0,
            IncomeTaxPer: 0,
            AddTaxPer: 0,
            CustName: ' ',
            ReferenceNo: '',
            CancelScheme: false,
            btn_Add: true,
            btn_Edit: true,
            btn_Delete: true,
            Others: null,
            Manufacturer_Err: '',
            CG_Err: '',
            IncomeTaxPer_Err: '',
            AddTaxPer_Err: '',
            WHTPer_Err: '',
            btn_Disabled: false,
            Bonus_Qty: 0,
            Bonus_Criteria: 0,
            DisPer_1: 0,
            DisPer_2: 0,
            Tax_Per: 0,
            Start_Date: GetCurrentDate(),
            End_Date: GetCurrentDate(),
            CG_List: [],
            SelectedCG: null,
            SchemeName: '',
            CreatedDate: GetCurrentDate(),
            UserName: ''
        }

        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
        this.validationRules = { required: true };
        this.bg_Ref = React.createRef()
        this.grid = null
        this.Comp_Id = this.props.Comp_Id
        this.ProjectID = this.props.Project_ID
        this.BGValidation = {
            required: [this.customBGValidation, 'Scheme must be Entered'],
        };
    }
    sortingOptions = {
        columns: [{ field: 'SrNo', direction: 'Ascending' }]
    };

    customBGValidation(args) {
        return getValue('value', args) != ''
    }


    componentDidMount() {
        document.title = " Distribution / Scheme"

        if (this.props.Project_ID) {
            // this.Select_customers_List(this.props.Project_ID)
            this.Select_Item_Details(this.props.Project_ID)
            SelectcustomersList(this.Comp_Id, this.ProjectID, (mCustomer) => {
                var mObj = { CustomerID: 0, CustomerName: 'SELECT ALL', Comp_Id: this.Comp_Id, ProjectID: this.ProjectID }
                mCustomer.splice(0, 0, mObj)
                this.setState({ CustomerList: mCustomer })
                SelectManufacturedByList(this.Comp_Id, this.ProjectID, (mManufacturer) => {

                    this.setState({ ManufacturerList: mManufacturer })
                    if (!this.props.isAddNew) {
                        SelectSchemeMaster(this.props.SchemeID, (mData) => {

                            mData[0].Cancel = mData[0].Cancel === 1 ? true : false
                            this.setState({
                                WHTPer: mData[0].WHTPer,
                                IncomeTaxPer: mData[0].IncomeTaxPer,
                                AddTaxPer: mData[0].AddTaxPer,
                                CancelScheme: mData[0].Cancel,
                                ReferenceNo: mData[0].ReferenceNo,
                                btn_Edit: this.props.show_screen.edit
                            })
                            SelectScheme_Detail(this.Comp_Id, this.props.Project_ID, this.props.SchemeID, (mDetailData) => {
                                if (mDetailData.length > 0) {
                                    mDetailData.map((value, index) => {
                                        value.SrNo = index + 1;
                                    })
                                    this.setState({
                                        Scheme: mDetailData
                                    })
                                }

                            })

                        })
                    }
                    else {
                        // SelectCustForScheme(this.Comp_Id,this.props.Project_ID,(mCustomerlist)=>{
                        //     var mObj = {CustomerID:0,CustomerName:'SELECT ALL',Comp_Id:this.Comp_Id,ProjectID:this.ProjectID}
                        //     mCustomerlist.splice(0,0,mObj)
                        //     this.setState({ CustomerList: mCustomerlist })
                        // })
                    }
                })
            })

        }
    }

    Select_Item_Details = (ProjectID) => {
        // SelectWareHouseList(this.Comp_Id, ProjectID, (mWareHouse) => { //by default first godown
        // SelectItemsWRTWareHouse(this.Comp_Id,ProjectID,mWareHouse[0].WareHouseID,(mItems)=>{ 
        //     this.setState({Item_List:mItems})
        // })
        // })
        SelectActiveItems(this.Comp_Id, ProjectID, (mItems) => {
            this.setState({ Item_List: mItems })
        })
    }

    SelectItems_WRTManufacturer = (manufacturerID, CGID) => {

        if (manufacturerID && CGID != undefined)
        
            SelectItemsWRTManufacturer(this.Comp_Id, this.ProjectID, manufacturerID, CGID, (mData) => {
               
                if (mData.length > 0) {
                    const mArr = []
                    mData.map((i, index) => {
                        const myData = {}
                        myData.SrNo = index + 1
                        myData.ItemID = i.ItemID
                        myData.ItemDiscription = i.ItemCode + "~" + i.ItemDiscription
                        myData.BonusQty = this.state.Bonus_Qty
                        myData.BonusCriteria = this.state.Bonus_Criteria
                        myData.DisPer = this.state.DisPer_1
                        myData.DisPer1 = this.state.DisPer_2
                        myData.TaxPer = this.state.Tax_Per
                        myData.StartDate = this.state.Start_Date
                        myData.EndDate = this.state.End_Date
                        myData.action = 'add'
                        mArr.push(myData)
                    })
                    this.setState({ Scheme: mArr })
                } else {
                    Swal.fire({
                        title: 'NOT FOUND',
                        text: 'Data Not found',
                        icon: 'info'
                    })
                }

            })
    }

    ItemDiscription = (rowValue) => {
        return (
            <Autocomplete
                name="ItemDiscription"
                id="ItemDiscription"
                defaultValue={this.state.Item_List.find(v => v.ItemID === rowValue.ItemID)}
                options={this.state.Item_List}
                getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : ""}
                onChange={(e, value) => {
                    if (value) {
                        this.setState({ Selected_item: value })

                    }
                    else {
                        this.setState({ Selected_item: null })
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="ItemDiscription" />}
            />
        )
    }
    BonusCriteria = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="BonusCriteria"
            className="textbox"
            name='BonusCriteria'
            defaultValue={rowValue.BonusCriteria}
            onChange={{

            }}
        />)
    }
    BonusQty = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="BonusQty"
            className="textbox"
            name='BonusQty'
            defaultValue={rowValue.BonusQty}
            onChange={e => {
                // this.Calculate_Total()
            }}
            min={0}
            onKeyPress={e => preventMinus(e)}
        />)
    }
    DisPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisPer"
            defaultValue={rowValue.DisPer}
            className="textbox"
            name="DisPer"
            onChange={e => {
                //  this.Calculate_Total()
            }}
        />)
    }
    DisPer1 = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="DisPer1"
            defaultValue={rowValue.DisPer1}
            className="textbox"
            name="DisPer1"
            onChange={e => {
                //  this.Calculate_Total()
            }}
        />)
    }
    TaxPer = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="number"
            id="TaxPer"
            defaultValue={rowValue.TaxPer}
            className="textbox"
            name="TaxPer"
            onChange={e => {
                //  this.Calculate_Total()
            }}
        />)
    }
    StartDate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="date"
            id="StartDate"
            defaultValue={rowValue.StartDate}
            className="textbox"
            name="StartDate"
            onChange={e => {
                //  this.Calculate_Total()
            }}
        />)
    }
    EndDate = (rowValue) => {
        return (<input
            style={{ textAlign: "right", width: "80%" }}
            type="date"
            id="EndDate"
            defaultValue={rowValue.EndDate}
            className="textbox"
            name="EndDate"
            onChange={e => {
                //  this.Calculate_Total()
            }}
        />)
    }
    validate = () => {
        // if(parseFloat(this.state.WHTPer)<0){
        //     this.setState({WHTPer_Err:'greater than zero'})
        //     return false
        // }
        // if(parseFloat(this.state.IncomeTaxPer) < 0){
        //     this.setState({IncomeTaxPer_Err:'greater than zero'})
        //     return false
        // }
        // if(parseFloat(this.state.AddTaxPer) < 0){
        //     this.setState({AddTaxPer_Err:'greater than zero'})
        //     return false
        // }

        this.setState({ WHTPer_Err: '', IncomeTaxPer_Err: '', AddTaxPer_Err: '' })
        return true
    }
    SaveHandler = () => {
        var PrevSrNo, SrNo;
        let resultToReturn = false;

        if (this.state.SelectedCustomer) {
            this.setState({ Customerr_Err: ' ' })
            if (this.state.SelectedManufacturer) {
                this.setState({ Manufacturer_Err: ' ' })
                if (this.state.SelectedCG) {
                    this.setState({ CG_Err: '' })
                    if (this.validate()) {
                        if (this.state.Scheme.length > 0 || this.state.DeletedScheme.length > 0) {
                            for (let i = 0; i < this.state.Scheme.length; i++) {

                                if (this.state.Scheme[i].ItemDiscription === undefined || this.state.Scheme[i].ItemDiscription === '') {
                                    Swal.fire({
                                        icon: 'error',
                                        text: `Item Discription at Sr. No. ${this.state.Scheme[i].SrNo} is required`
                                    })
                                    return
                                }
                                if (typeof this.state.Scheme[i].BonusQty === 'undefined' || this.state.Scheme[i].BonusQty == '' || this.state.Scheme[i].BonusQty == null)
                                    this.state.Scheme[i].BonusQty = 0
                                else if (typeof this.state.Scheme[i].BonusQty === 'string')
                                    this.state.Scheme[i].BonusQty = parseFloat(this.state.Scheme[i].BonusQty)

                                if (typeof this.state.Scheme[i].BonusCriteria === 'undefined' || this.state.Scheme[i].BonusCriteria == '' || this.state.Scheme[i].BonusCriteria == null)
                                    this.state.Scheme[i].BonusCriteria = 0
                                else if (typeof this.state.Scheme[i].BonusCriteria === 'string')
                                    this.state.Scheme[i].BonusCriteria = parseFloat(this.state.Scheme[i].BonusCriteria)

                                if (typeof this.state.Scheme[i].TaxPer === 'undefined' || this.state.Scheme[i].TaxPer == '' || this.state.Scheme[i].TaxPer == null)
                                    this.state.Scheme[i].TaxPer = 0
                                else if (typeof this.state.Scheme[i].TaxPer === 'string')
                                    this.state.Scheme[i].TaxPer = parseFloat(this.state.Scheme[i].TaxPer)

                                if (typeof this.state.Scheme[i].DisPer === 'undefined' || this.state.Scheme[i].DisPer == '' || this.state.Scheme[i].DisPer == null)
                                    this.state.Scheme[i].DisPer = 0
                                else if (typeof this.state.Scheme[i].DisPer === 'string')
                                    this.state.Scheme[i].DisPer = parseFloat(this.state.Scheme[i].DisPer)

                                if (typeof this.state.Scheme[i].DisPer1 === 'undefined' || this.state.Scheme[i].DisPer1 == '' || this.state.Scheme[i].DisPer1 == null)
                                    this.state.Scheme[i].DisPer1 = 0
                                else if (typeof this.state.Scheme[i].DisPer1 === 'string')
                                    this.state.Scheme[i].DisPer1 = parseFloat(this.state.Scheme[i].DisPer1)
                                if (typeof this.state.Scheme[i].StartDate !== 'undefined' || this.state.Scheme[i].StartDate != '' || this.state.Scheme[i].StartDate != null || typeof this.state.Scheme[i].EndDate !== 'undefined' || this.state.Scheme[i].EndDate != '' || this.state.Scheme[i].EndDate != null) {
                                    if (this.state.Scheme[i].StartDate > this.state.Scheme[i].EndDate) {
                                        Swal.fire({
                                            icon: 'error',
                                            text: `End Date must be Greater than Start date at Sr. No. ${this.state.Scheme[i].SrNo}`
                                        })
                                        return
                                    }
                                }
                                if (this.state.Scheme[i].BonusQty < 0) {
                                    Swal.fire({
                                        icon: 'error',
                                        text: `Bonus Qty must be Greater than zero at Sr. No. ${this.state.Scheme[i].SrNo}`
                                    })
                                    return
                                }

                                if (this.state.Scheme[i].BonusCriteria < 0) {
                                    Swal.fire({
                                        icon: 'error',
                                        text: `Bonus Criteria must be Greater than zero at Sr. No. ${this.state.Scheme[i].SrNo}`
                                    })
                                    return
                                }
                                if (this.state.Scheme[i].TaxPer < 0) {
                                    Swal.fire({
                                        icon: 'error',
                                        text: `Tax Percentage must be Greater than zero at Sr. No. ${this.state.Scheme[i].SrNo}`
                                    })
                                    return
                                } if (this.state.Scheme[i].DisPer < 0) {
                                    Swal.fire({
                                        icon: 'error',
                                        text: `Discount 1 must be Greater than zero at Sr. No. ${this.state.Scheme[i].SrNo}`
                                    })
                                    return
                                }
                                if (this.state.Scheme[i].DisPer1 < 0) {
                                    Swal.fire({
                                        icon: 'error',
                                        text: `Discount 2 must be Greater than zero at Sr. No. ${this.state.Scheme[i].SrNo}`
                                    })
                                    return
                                }
                                if (typeof this.state.Scheme[i].StartDate === 'undefined' || this.state.Scheme[i].StartDate == '' || this.state.Scheme[i].StartDate == null)
                                    this.state.Scheme[i].StartDate = GetCurrentDate()

                                if (typeof this.state.Scheme[i].EndDate === 'undefined' || this.state.Scheme[i].EndDate == '' || this.state.Scheme[i].EndDate == null)
                                    this.state.Scheme[i].EndDate = GetCurrentDate()


                            }
                            for (let i = 0; i < this.state.Scheme.length; i++) {
                                for (let j = 0; j < this.state.Scheme.length; j++) {
                                    if (i !== j) {
                                        if (this.state.Scheme[i].ItemID === this.state.Scheme[j].ItemID) {
                                            PrevSrNo = this.state.Scheme[i].SrNo;
                                            SrNo = this.state.Scheme[j].SrNo;
                                            resultToReturn = true;
                                            break;
                                        }

                                    }
                                }
                                if (resultToReturn) {
                                    break;
                                }
                            }

                            if (resultToReturn) {
                                Swal.fire({
                                    icon: 'info',
                                    text: `Same Item found at Sr No: ${PrevSrNo} and Sr No: ${SrNo}, Please Remove one of them`,
                                })
                                return
                            }
                            else {

                                var SchemeData = {
                                    SchemeID: this.props.SchemeID,
                                    CustomerID: this.state.SelectedCustomer && this.state.SelectedCustomer.CustomerID,
                                    SchemeName: this.state.SchemeName,
                                    WHTPer: parseFloat(this.state.WHTPer),
                                    IncomeTaxPer: parseFloat(this.state.IncomeTaxPer),
                                    AddTaxPer: parseFloat(this.state.AddTaxPer),
                                    Comp_Id: this.Comp_Id,
                                    ProjectID: this.props.Project_ID,
                                    Cancel: this.state.CancelScheme ? 1 : 0,
                                    ReferenceNo: this.state.ReferenceNo,
                                    ManufacturedByID: this.state.SelectedManufacturer && this.state.SelectedManufacturer.ManufacturedByID,
                                    CGID: this.state.SelectedCG && this.state.SelectedCG.CGID,
                                    UserName: this.props.UserName,
                                    CreatedDate: this.state.CreatedDate,
                                    IsCustSelectAll: this.state.SelectedCustomer && this.state.SelectedCustomer.CustomerID === 0 ? 1 : 0,
                                    IsCGSelectAll: this.state.SelectedCG && this.state.SelectedCG.CGID === 0 ? 1 : 0

                                }
                                this.setState({ btn_Disabled: true })

                                SaveScheme(SchemeData, this.state.Scheme, this.state.DeletedScheme, this.props.isAddNew, () => {
                                    this.setState({ btn_Disabled: false })
                                    document.getElementById('btnCancel').click()
                                })
                            }
                        }
                        else {
                            Swal.fire({
                                icon: 'info',
                                text: `Scheme Must be Entered...`,
                            })
                            return
                        }
                    }
                } else {
                    this.setState({ CG_Err: 'Company Group is required' })
                }

            } else {
                this.setState({ Manufacturer_Err: 'Manufacturer is required' })
            }
        }
        else {
            this.setState({ Customerr_Err: 'Customer is required' })
        }
    }
    beforeRowInsert = (rowValue) => {

        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.SrNo) {
                    if (rowValue.requestType != "cancel") {
                        if (this.state.Selected_item) {
                            rowValue.data.ItemID = this.state.Selected_item.ItemID;
                            rowValue.data.ItemDiscription = this.state.Selected_item.ItemDiscription;
                        }
                        rowValue.data.action = 'add'
                    }
                }
                else {
                    rowValue.data.SrNo = this.state.Scheme.length + 1
                    rowValue.data.BonusQty = 1
                    rowValue.data.BonusCriteria = 1
                    rowValue.data.DisPer = 1
                    rowValue.data.DisPer1 = 1
                    rowValue.data.TaxPer = 1
                    rowValue.data.StartDate = GetCurrentDate();
                    rowValue.data.EndDate = GetCurrentDate();
                    this.setState({ Selected_item: null })

                }
            }
        }

    }
    afterRowInsert = (rowValue) => {

        if (rowValue.requestType === 'delete') {
            if (rowValue.data[0].SchemeID) {
                this.setState({ DeletedScheme: this.state.DeletedScheme.concat(rowValue.data) })

            }
        }
    }
    toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
    //  msg = (text) =>{
    //     Swal.fire({
    //         title:'Validation',
    //         text:`${text} `,
    //         icon:'info'
    //     })
    // }
    render() {
        if (this.grid)
            this.grid.autoFitColumns(['SrNo', 'AreaName']);
        enableRipple(true);
        return ReactDOM.createPortal(
            <>
                <div className="block-header">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <h2>Scheme
                            </h2>
                        </div>
                    </div>
                </div>
                <Card>
                    <Row className="mt-2 ml-4 mr-4" >
                        {
                            //      this.props.isAddNew ? 

                            <Col lg={3} xl={3} md={3} >
                                <Autocomplete
                                    name="CustomerName"
                                    id="CustomerName"
                                    options={this.state.CustomerList}
                                    getOptionLabel={(option) => option.CustomerName ? option.CustomerName : ""}
                                    value={this.state.SelectedCustomer}
                                    onChange={(e, value) => {
                                        if (value) {
                                            this.setState({ SelectedCustomer: value })

                                        }
                                        else {
                                            this.setState({ SelectedCustomer: null })

                                        }
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="CustomerName" label="Customer"
                                        />}
                                />
                                <span className="text-danger">{this.state.Customerr_Err}</span>
                            </Col>
                            //     :

                            // <Col lg={3} xl={3} md={3} >
                            //     <TextField
                            //         name="SchemeName"
                            //         label="Scheme Name"
                            //         value={this.state.SchemeName}
                            //         onChange={e => this.setState({ SchemeName: e.target.value })}
                            //         onInput={this.toInputUppercase}
                            //         fullWidth
                            //     />
                            // </Col>
                        }
                        {/* <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="WHTPer"
                            label="WHT %" 
                            value={this.state.WHTPer}
                            onChange={e=>this.setState({WHTPer:e.target.value})}
                            fullWidth
                        />
                        <span className="text-danger">{this.state.WHTPer_Err}</span>
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="IncomeTaxPer"
                            label="G S T %" 
                            value={this.state.IncomeTaxPer}
                            onChange={e=>this.setState({IncomeTaxPer:e.target.value})}
                            fullWidth
                        />
                        <span className="text-danger">{this.state.IncomeTaxPer_Err}</span>

                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="AddTaxPer"
                            label="AddTax %" 
                            value={this.state.AddTaxPer}
                            onChange={e=>this.setState({AddTaxPer:e.target.value})}
                            fullWidth
                        />
                        <span className="text-danger">{this.state.AddTaxPer_Err}</span>

                    </Col> */}
                        <Col sm={12} lg={3} xl={3} md={3} >
                            <TextField
                                name="ReferenceNo"
                                label="Reference No"
                                value={this.state.ReferenceNo}
                                onChange={e => this.setState({ ReferenceNo: e.target.value })}
                                fullWidth
                            />
                        </Col>
                        <Col lg={3} xl={3} md={3} >
                            <Autocomplete
                                name="ManufacturedBy"
                                id="ManufacturedBy"
                                options={this.state.ManufacturerList}
                                getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""}
                                value={this.state.SelectedManufacturer}
                                onChange={(e, value) => {
                                    if (value) {
                                        this.setState({ SelectedManufacturer: value })
                                        SelectCompanyGroupAgainstManufacturer(this.Comp_Id, this.ProjectID, value.ManufacturedByID, (mData) => {
                                            var mObj = { CGID: 0, CGName: 'SELECT ALL', Comp_Id: this.Comp_Id, Description: '', ManufacturedByID: value.ManufacturedByID, ProjectID: this.ProjectID }
                                            mData.splice(0, 0, mObj)
                                            this.setState({ CG_List: mData })
                                        })
                                    }
                                    else {
                                        this.setState({ SelectedManufacturer: null,SelectedCG: null, CG_List: [],Scheme: []})
                                       

                                    }
                                }}
                                renderInput={(params) =>
                                    <TextField {...params} name="ManufacturedBy" label="Manufacturer"
                                    />}
                            />
                            <span className="text-danger">{this.state.Manufacturer_Err}</span>
                        </Col>
                        <Col lg={3} xl={3} md={3} >
                            <Autocomplete
                                name="CG"
                                id="CG"
                                options={this.state.CG_List}
                                getOptionLabel={(option) => option.CGName ? option.CGName : ""}
                                value={this.state.SelectedCG}
                                onChange={(e, value) => {
                                    if (value) {
                                        this.setState({ SelectedCG: value })
                                    }
                                    else {
                                        this.setState({ SelectedCG: null })
                                        this.setState({ Scheme: [] })
                                    }

                                }}
                                renderInput={(params) =>
                                    <TextField {...params} name="CG" label="Company Group"
                                    />}
                            />
                            <span className="text-danger">{this.state.CG_Err}</span>
                        </Col>
                    </Row>
                    <Row className="mt-2 ml-4 mr-4" >
                       
                       
                        <Col sm={12} lg={2} xl={2} md={2} >
                            <TextField
                                name="BonusQty"
                                label="Bonus Qty"
                                value={this.state.Bonus_Qty}
                                onChange={e => this.setState({ Bonus_Qty: e.target.value })}
                                fullWidth
                            />
                        </Col>
                        <Col sm={12} lg={2} xl={2} md={2} >
                            <TextField
                                name="Bonus_Criteria"
                                label="Bonus Criteria"
                                value={this.state.Bonus_Criteria}
                                onChange={e => this.setState({ Bonus_Criteria: e.target.value })}
                                fullWidth
                            />

                        </Col>
                        <Col sm={12} lg={2} xl={2} md={2} >
                            <TextField
                                name="DisPer_1"
                                label="Dis% 1"
                                value={this.state.DisPer_1}
                                onChange={e => this.setState({ DisPer_1: e.target.value })}
                                fullWidth
                            />

                        </Col>
                        <Col sm={12} lg={2} xl={2} md={2} >
                            <TextField
                                name="DisPer_2"
                                label="Dis% 2"
                                value={this.state.DisPer_2}
                                onChange={e => this.setState({ DisPer_2: e.target.value })}
                                fullWidth
                            />

                        </Col>
                        <Col sm={12} lg={2} xl={2} md={2} >
                            <TextField
                                name="Tax_Per"
                                label="Tax Per"
                                value={this.state.Tax_Per}
                                onChange={e => this.setState({ Tax_Per: e.target.value })}
                                fullWidth
                            />

                        </Col>
                        <Col sm={12} lg={2} xl={2} md={2} ></Col>
                        <Col sm={12} lg={2} xl={2} md={2} >
                            <TextField
                                name="Start_Date"
                                type={'date'}
                                label="Start Date"
                                value={this.state.Start_Date}
                                onChange={e => this.setState({ Start_Date: e.target.value })}
                                fullWidth
                            />

                        </Col>
                        <Col sm={12} lg={2} xl={2} md={2} >
                            <TextField
                                name="End_Date"
                                type={'date'}
                                label="End Date"
                                value={this.state.End_Date}
                                onChange={e => this.setState({ End_Date: e.target.value })}
                                fullWidth
                            />

                        </Col>
                       
                        <Col lg={2} xl={2} md={2} className={"p-0"} >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.CancelScheme}
                                        id="CancelScheme"
                                        onChange={e => this.setState({ CancelScheme: e.target.checked })}

                                        name="Access"
                                        color="primary"
                                    />
                                }
                                label={"Cancel"}
                                style={{
                                    padding: "0px",
                                    marginTop: "10%"
                                }}
                            />

                        </Col>

                        <Col sm={12} lg={4} xl={4} md={4} ></Col>
                        <Col lg={2} xl={2} md={2}  style={{textAlign:'right'}}>
                            <button id="btnFetch" className="btn btn-primary rounded-pill w-100" style={{ border: "none" }} onClick={() => {
                                if (this.state.SelectedCustomer) {
                                    if (this.state.SelectedManufacturer) {
                                        if (this.state.SelectedCG) {
                                            if (this.state.Start_Date < this.state.End_Date) {
                                                this.SelectItems_WRTManufacturer(this.state.SelectedManufacturer.ManufacturedByID, this.state.SelectedCG.CGID)
                                            } else {
                                                msg('End Date must be greater then Start Date')
                                            }

                                        } else {
                                            msg('Company Group Required')
                                        }
                                    } else {
                                        msg('Manufacturer Required')
                                    }
                                } else {
                                    msg('Customer Required')
                                }

                            }}  > <FileCopy /> <u>GET DATA</u></button>
                        </Col>


                    </Row>
                    
                    <Row className="row_margin_left row_margin_right m-2"  >
                        <div style={{ display: "flex", width: "100%", height: "100%", overflow: "hidden" }}>
                            <div style={{ flex: "1", width: "100%", height: "100%" }}>
                                <GridComponent dataSource={this.state.Scheme} actionBegin={this.beforeRowInsert} ref={g => this.grid = g} actionComplete={this.afterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: false, allowDeleting: this.state.btn_Delete, allowAdding: this.state.btn_Add, newRowPosition: 'Bottom' }} sortSettings={this.sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={this.toolbarOptions}>
                                    <ColumnsDirective>
                                        <ColumnDirective width='70' field='SrNo' headerText='SrNo #' Format="{0:n}" allowEditing={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                        <ColumnDirective width='250' field='ItemDiscription' headerText='ItemDiscription' editType='dropdownedit' editTemplate={this.ItemDiscription} edit={this.cbParams} validationRules={this.NAmeValidation} textAlign='Left' />
                                        <ColumnDirective width='170' field='BonusQty' headerText='Bonus Qty' editTemplate={this.BonusQty} textAlign='right' />
                                        <ColumnDirective width='150' field='BonusCriteria' headerText='Bonus Criteria' editTemplate={this.BonusCriteria} textAlign='right'></ColumnDirective>
                                        <ColumnDirective width='150' field='DisPer' headerText='Dis1%' editTemplate={this.DisPer} textAlign='right'></ColumnDirective>
                                        <ColumnDirective width='150' field='DisPer1' headerText='Dis2%' editTemplate={this.DisPer1} textAlign='right'></ColumnDirective>
                                        <ColumnDirective width='150' field='TaxPer' headerText='Tax%' editTemplate={this.TaxPer} textAlign='right'></ColumnDirective>
                                        <ColumnDirective width='150' field='StartDate' headerText='Start Date' editTemplate={this.StartDate}></ColumnDirective>
                                        <ColumnDirective width='150' field='EndDate' headerText='End Date' editTemplate={this.EndDate}></ColumnDirective>


                                        <ColumnDirective field='SchemeID' headerText='SchemeID' visible={false}></ColumnDirective>
                                        <ColumnDirective field='ItemID' headerText='ItemID' hideAtMedia={true} ></ColumnDirective>
                                    </ColumnsDirective>
                                    <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
                                </GridComponent>
                            </div>
                        </div>
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right row_margin_bottom" >
                        <Col style={{ textAlign: "right", marginTop: "1%" }} >
                            {this.state.btn_Edit && <button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={this.SaveHandler} disabled={this.state.btn_Disabled}>

                                {
                                    this.state.btn_Disabled ?
                                        (<><Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> Saving... </>) : <><Save /> Save</>
                                }
                            </button>}
                            <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{ border: "none" }} to={'/Scheme'} > <Cancel /> <u>Close</u></Link>
                        </Col>
                    </Row>
                </Card>

            </>, document.getElementById('PageContents')
        )
    }

}

const mapStateToProps = state => ({
    Comp_Id: state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Group_Id: state.Login_User.User_Data && state.Login_User.User_Data.Group_Id,
    // Project_ID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID
    Project_ID: state.Scheme.mData && state.Scheme.mData.Project_ID,
    SchemeID: state.Scheme.mData && state.Scheme.SchemeID,
    UserName: state.Login_User.User_Data && state.Login_User.User_Data.User_name,
    isAddNew: state.Scheme.mData && state.Scheme.isAddNew,
    show_screen: state.SCREEN,

})

export default connect(mapStateToProps)(Scheme)