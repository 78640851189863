
import React, { useEffect, useState, createRef } from 'react'
import ReactDOM from 'react-dom'
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Link } from "react-router-dom"
import { Autocomplete } from '@material-ui/lab'
import {TextField, Popper} from '@material-ui/core'
import { Row, Col, Card, Button } from 'react-bootstrap'
import { Add, Edit, Delete } from '@material-ui/icons'
import { connect } from "react-redux"
import ProjectModal from './ProjectModal';
import { SelectProjects , DeleteProject} from '../../Services/Projects'
import { SelectBranchesList } from '../../Services/BranchAPI'
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import { SHOW_SCREEN } from "../../React_Redux/Actions"
import Swal from 'sweetalert2'
import Loader from '../Loader';

function ProjectsList(props) {
    const [Project_Modal, setProject_Modal] = useState(false)
    const [IsAddNew, setIsAddNew] = useState(null)
    const [ProjectData, setProjectData] = useState([])
    const [Project_ID, setProject_ID] = useState(0)
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [Comp_List, setComp_List] = useState([])
    const [Selected_Comp, setSelected_Comp] = useState(null)
    const [IsLoader, setIsLoader] = useState(false)
   
    useEffect(() => {
        document.title = "Distribution / Project"
        //      SelectGroupScreenAccess(props.Group_Id,13,138,(data)=>{

        //       setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
        //       setBtn_delete(parseInt(data.Del) === 0 ? false : true)

        //       props.SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
        //       parseInt(data.Edit) === 0 ? false : true,
        //       parseInt(data.Del) === 0 ? false : true,
        //       data.OtherAccess
        //       )

        //   })    
         Select_BranchesList()
    }, [])
    const Select_BranchesList = ()=>{
        SelectBranchesList(mBranches=>{
            setComp_List(mBranches)
        })
    }
    const Select_Projects = (Comp_Id) => {
        showLoader()
        SelectProjects(Comp_Id, data => {
            hideLoader()
            setProjectData(data)
        })
    }
    const theme = createTheme({
        overrides: {
            MuiTableCell: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                }
            }
        }
    });
    const showLoader = () => {
        setIsLoader(true)
       
      }
    const  hideLoader = () => {
      setIsLoader(false)
      }
    const show_Modal = () => {
        setProject_Modal(true);
    }
    const hide_Modal = () => {
        setProject_Modal(false)
        setIsAddNew(false)
        if(Selected_Comp){
            Select_Projects(Selected_Comp.Branch_Id)

        }
    }
    const column = [
        {
            name: "Project_ID",
            label: "Project ID",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "ProjectName",
            label: "Project Name",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "Abbr",
            label: "Abbrevation",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold", textAlign: "right" }} >{columnMeta.label}</div>
                    )
                },
                customBodyRender: (dataIndex, rowIndex) => {

                    const _ProjectData = ProjectData
                    if (_ProjectData[rowIndex.rowIndex] != null)
                        return (
                            <>
                                <Link to={"#"} onClick={e => {
                                    setIsAddNew(false)
                                    setProject_ID(_ProjectData[rowIndex.rowIndex].Project_ID)
                                    show_Modal()
                                }} > <Edit /> </Link> {
                                    btn_delete ?
                                        <Link to={"#"} onClick={e => {
                                            Delete_Project(_ProjectData[rowIndex.rowIndex].Project_ID)

                                        }} > | <Delete /> </Link> : null
                                }
                            </>
                        );
                }
            }
        }
    ];

    const Delete_Project = (Project_ID) => {
        if (Project_ID) {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: `Yes, Delete it`,
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {

                    await DeleteProject(Project_ID, (status, message) => {
                        if(Selected_Comp){
                            Select_Projects(Selected_Comp.Branch_Id)

                        }
                        if (status) {
                            swalWithBootstrapButtons.fire(
                                `Ooppsss`,
                                `${message}`,
                                'error'
                            )
                           
                        }
                        else {
                            swalWithBootstrapButtons.fire(
                                `Deleted`,
                                `${message}`,
                                'success'
                            )
                        }

                    })
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire(
                        'Cancelled',
                        `Your record is safe :)`,
                        'error'
                    )
                }
            })
        }


    }
    return ReactDOM.createPortal(
        <>
            <Row>
                <Col lg={6} xl={6} md={6} className="header_title" >
                    <h2> Projects  </h2>
                </Col>
            </Row>
            <Card>
                <Row className=" row_margin_right pt-2 pl-4 pr-4"  >
                    <Col xl={5} lg={5} md={5} sm={5}>
                        <Autocomplete
                            name="Comp"
                            id="Comp"
                            options={Comp_List}
                            getOptionLabel={(option) => option.Name ? option.Name : ""}
                            value={Selected_Comp}
                            onChange={(e, value) => {
                                if (value) {
                                    setSelected_Comp(value)
                                    Select_Projects(value.Branch_Id)
                                }
                                else {
                                    setSelected_Comp(null)
                                    setProjectData([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Comp" label="Select Branch"
                                />}
                        />
                    </Col>
                    <Col  xl={7} lg={7} md={7} sm={7} style={{ textAlign: "right" }} >
                        {btn_add && (<Button variant="btn btn-primary rounded-pill" onClick={e => {
                            show_Modal()
                            setIsAddNew(true)
                        }} style={{ border: "none" }} > <Add />  Create New</Button>)}
                    </Col>
                </Row>
                <Row className="row_margin_top row_margin_left row_margin_right" >
                    <Col>
                        <div className="table-responsive" >
                            <MuiThemeProvider theme={theme}>
                                <MUIDataTable
                                    data={ProjectData}
                                    columns={column}
                                    options={{
                                        selectableRows: 'none',
                                        print: false,
                                        search: true,
                                        download: false,
                                        viewColumns: false,
                                        filter: false,
                                    }}
                                />
                            </MuiThemeProvider>
                        </div>
                    </Col>
                </Row>

                <ProjectModal
                    show={Project_Modal}
                    onHide={hide_Modal}
                    is_add_new={IsAddNew}
                    Project_ID={Project_ID}
                />
                <Loader
                show={IsLoader}
                hide={hideLoader}
                />
            </Card>


        </>, document.getElementById('PageContents')
    )
}

const mapStateToProps = state => ({
    // Comp_Id: state.GlobalVariables.Company && state.GlobalVariables.Company.Project_ID,
    Group_Id: state.Login_User.User_Data.Group_Id,
    // ProjectID: state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

})

export default connect(mapStateToProps, { SHOW_SCREEN })(ProjectsList)

