
import React, { useEffect, useState, createRef } from 'react'
import ReactDOM from 'react-dom'
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {Link} from "react-router-dom"
import { Row, Col, Card, Button } from 'react-bootstrap'
import { Add, Edit, Delete } from '@material-ui/icons'
import { connect } from "react-redux"
 import AGM_Modal from './AGM_Modal';
 import { SelectAGM_List, DeleteAGM } from '../../Services/AGM_API'
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import { SHOW_SCREEN } from "../../React_Redux/Actions"
import Swal from 'sweetalert2'
import Loader from '../Loader';
import { SelectUsersListForManagerPortal } from '../../Services/ClaimAPI';

function AGMList(props) {
    const { Comp_Id,ProjectID } = props;
    const [AssignGroup_Modal, setAssignGroup_Modal] = useState(false)
    const [IsAddNew, setIsAddNew] = useState(null)
    const [AGM_Data, setAGM_Data] = useState([])
    const [UserID, setUserID] = useState(0)
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete]= useState(true)
    const [IsLoader, setIsLoader] = useState(false)
    useEffect(() => {
     document.title = "Distribution / Assign Group"
     
     SelectGroupScreenAccess(props.Group_Id,13,138,(data)=>{
            
      setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
      setBtn_delete(parseInt(data.Del) === 0 ? false : true)

      props.SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
      parseInt(data.Edit) === 0 ? false : true,
      parseInt(data.Del) === 0 ? false : true,
      data.OtherAccess
      )
      
  })    
     Select_AGM_List()
    }, [])

    const Select_AGM_List = async() =>{
      showLoader()
      const res = await SelectUsersListForManagerPortal(Comp_Id,ProjectID)
      let data = res.data
      for(let i = 0; i < data.length; i++){
        data[i].SrNo = i + 1
    }
      hideLoader()
      if(res.status){
        setAGM_Data(res.data)
      }else{
        Swal.fire({
          title: 'API Error',
          icon: 'info',
          text: 'Something went wrong with API'
        })
        setAGM_Data([])
      }
   
    }
  const showLoader = () => {
      setIsLoader(true)
     
    }
  const  hideLoader = () => {
    setIsLoader(false)
    }
    const theme = createTheme({
      overrides: {
        MuiTableCell: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
          }
        }
      }
    });
    const show_Modal = () =>{
        setAssignGroup_Modal(true);
    }
    const hide_Modal = () =>{

        setAssignGroup_Modal(false)
        setIsAddNew(false)
        Select_AGM_List()
    }
    const column = [
        {
            name: "SrNo",
            label: "Sr No.",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
           {
            name: "User_name",
            label: "User Name",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
           {
            name: "User_Login",
            label: "Login ID",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
      {
        name: "PhoneNo",
        label: "Phone No",
        options: {
         filter: true,
         sort: true,
         customHeadLabelRender: (columnMeta)=>{
           return(
             <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
           )
          },
        }
       },
       {
         name: "Email",
         label: "Email",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }
         }
        },
        {
          name: "Address",
          label: "Address",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
             return(
               <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
             )
            }
          }
         },
        {
            name: "actions",
            label: "Actions",
            options: {
             customHeadLabelRender: (columnMeta)=>{
              
              return(
                  <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
              )
             },
             customBodyRender: (dataIndex, rowIndex) => {
              
                const _AGM_Data = AGM_Data
                if(_AGM_Data[rowIndex.rowIndex] != null)
                  return (
                      <>
                        <Link to={"#"} onClick={e =>{
                           setIsAddNew(false)
                           setUserID(_AGM_Data[rowIndex.rowIndex].User_id)
                            show_Modal()
                         
                          }} > <Edit/> </Link>
                         
                      </>
                  );
              }
            }
        }
    ];

    const Delete_AGM_Group = (UserID) =>{
        if(UserID){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
            })
    
            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: `Yes, Delete it`,
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
            }).then(async (result) => {
                if (result.isConfirmed) {
    
                    await DeleteAGM(UserID,(message) => {
                        Select_AGM_List()
                        swalWithBootstrapButtons.fire(
                            `Deleted`,
                            `${message}`,
                            'success'
                        )
                    })
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire(
                        'Cancelled',
                        `Your record is safe :)`,
                        'error'
                    )
                }
            })
        }
      
     
    }

    return ReactDOM.createPortal(
      <>
      <Row>
      <Col lg={6} xl={6} md={6} className="header_title" >
              <h2> Assign Groups to Marketing Staff </h2>
        </Col>
      </Row>
      <Card>
    
          <Row className="row_margin_left row_margin_right pt-2" style={{width:'80%', marginLeft:'17%'}} >
         
            <Col style={{textAlign: "right"}} >
             {btn_add && (<Button variant="btn btn-primary rounded-pill" onClick={e =>{
                show_Modal()
                setIsAddNew(true)
              }} style={{border: "none"}} > <Add />  Create New</Button> )}
            </Col>
          </Row>
          <Row className="row_margin_top row_margin_left row_margin_right" >
              <Col>              
                <div className="table-responsive" >
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        name = "OPD_Datatables"
                         data={AGM_Data}
                        columns={column}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                    </MuiThemeProvider>
                </div>
              </Col>
          </Row>
          
      <AGM_Modal 
        show = { AssignGroup_Modal }
        onHide = { hide_Modal }
        is_add_new = { IsAddNew }
        UserID = { UserID }
      />
       <Loader
        show={IsLoader}
        hide={hideLoader}
        />
      </Card>

                        
      </>  , document.getElementById('PageContents')
    )
}

const mapStateToProps = state =>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  Group_Id : state.Login_User.User_Data.Group_Id,
  ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID

})

export default connect(mapStateToProps, {SHOW_SCREEN})(AGMList)

