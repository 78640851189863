import {domain} from "./Common";
import axios from "axios"
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from "sweetalert2"

export const FinYear = (CompID,UserID,cb) =>{

    axios.get(`${domain}/FinYears/SelectFinYears/${CompID}/${UserID}`)
         .then(response =>{
            cb(response.data.Data)
         })
         .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
              cb(err)
         })
}
export const SelectFinYearList =(Callback) =>{
   axios.get(`${domain}/FinYears/SelectFinYearList`)
        .then(response=>{
            Callback(response.data.Data)
        })
        .catch(err =>{
         Swal.fire({
             icon: 'error',
             title: 'Oops...',
             text: `${err}`,
           })
      })
}
export const SelectFinYear =(Year_Id,Callback) =>{
    
   axios.get(`${domain}/FinYears/SelectFinYear/${Year_Id}`)
        .then(response=>{
            Callback(response.data.Data)
        })
        .catch(err =>{
         Swal.fire({
             icon: 'error',
             title: 'Oops...',
             text: `${err}`,
           })
      })
}
export const SaveFinYear = (FinYearData,IsAddNew,callback) =>{

 axios.post(`${domain}/FinYears/SaveFinYear`,{
   FinYearData,
   IsAddNew
 })
   .then(response=>{
       toast.configure()
       toast.info(response.data.message, {
           position: "top-right",
           autoClose: 5000,
           hideProgressBar: false,
           closeOnClick: true,
           pauseOnHover: true,
           draggable: true,
           progress: undefined,
       });
       callback()
   })
   .catch(err=>console.error(err))
}
export const DeleteFinYear =(Year_Id,Callback) =>{
   
   axios.delete(`${domain}/FinYears/DeleteFinYear/${Year_Id}`)
        .then(response=>{
           Callback(response.data.status , response.data.message)
        })
        .catch(err =>{
         Swal.fire({
             icon: 'error',
             title: 'Oops...',
             text: `${err}`,
           })
      })
}