import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import './InvoicePrint.css'
// import './style.css'
import {ThousandSeparater} from '../../Services/Common'

function setPageSize(cssPageSize) {

    const style = document.createElement('style');
    style.innerHTML = `@page {size: ${cssPageSize}}`;
    style.id = 'page-orientation';
    document.head.appendChild(style);
}

function SaleInvoiceReport({ CompName,ConfigObj }) {
    const { Comp_Id, InvID } = useParams()
    const [InvoiceTableData, setInvoiceTableData] = useState([])
    const [Inv, setInv] = useState('')
    const [InvDate, setInvDate] = useState('')
    const [Supplier, setSupplier] = useState('')
    const [Godown, setGodown] = useState('')
    const [PaymentType, setPaymentType] = useState('')
    const [BankName, setBankName] = useState('')
    var [TotalQty, setTotalQty] = useState(0.00)
    var [TotalNetAmt, setTotalNetAmt] = useState(0.00)
    var [Discount, setDiscount] = useState(0.00);


    useEffect(() => {

        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('footer').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;


       var _Data = JSON.parse(localStorage.getItem("SaleInvoice"))
       debugger
       setInv(_Data.InvoiceNo)
       setInvDate(_Data.InvoiceDate)
       setSupplier(_Data.Supplier.CustomerName)
       setGodown(_Data.Warehouse.WareHouse)
       setPaymentType(_Data.PaymentType.value)
       setBankName(_Data.Bank && _Data.Bank.BankName)
       setInvoiceTableData(_Data.Detail)


       
       
    }, [])

    return (   
        <div id = 'main_div_invprntthrml' style={{fontSize: '14px', marginLeft: '14%'}} >
        <div id="invoice-POS">

            <center id="top">
                <div className="info">
                    <h2 style={{fontSize: '24px',fontWeight:'bold'}}><b>{CompName}</b></h2>
                </div>
            </center>

            <div id="mid" style={{marginTop: '-20%'}}>
                <div className="info">
                    <p>
                        <Row>
                            <Col lg={12} md={12} xl={12} sm={12} style={{fontSize: '16px'}}><b>Inv NO. : {Inv}</b> </Col>
                            <Col lg={12} md={12} xl={12} sm={12} style={{fontSize: '16px'}}><b>Inv Date : {InvDate}</b> </Col>
                            <Col lg={12} md={12} xl={12} sm={12} style={{fontSize: '16px'}}><b>Customer. : {Supplier}</b></Col>
                            <Col lg={12} md={12} xl={12} sm={12} style={{fontSize: '16px'}}><b>Godown : {Godown}</b></Col>
                            <Col lg={12} md={12} xl={12} sm={12} style={{fontSize: '16px'}}><b>Payment Type. : {PaymentType}</b></Col>
                            <Col lg={12} md={12} xl={12} sm={12} style={{fontSize: '16px'}}><b>Bank Name : {BankName}</b></Col>
                        </Row>
                    </p>
                </div>
            </div>

            <div id="bot">

                <div id="table_invtherprnt">
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Item Name</th>
                                <th style={{ textAlign: 'right' }} >Qty</th>
                                <th style={{ textAlign: 'right' }} >Rate</th>
                                <th style={{ textAlign: 'right' }} >Amount</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                InvoiceTableData.map((invoicetabledata, index) => {
                                    debugger
                                    TotalQty += invoicetabledata.Qty
                                    TotalNetAmt += invoicetabledata.Value
                                    Discount += invoicetabledata.DisAmt
                                    return(
                                    <>
                                        <tr id='data_values_invprntathrml' key={index} style={{ background: '' }}>
                                            <td style={{ textAlign: 'center' }}> {index + 1} </td>
                                            <td style={{ textAlign: 'left' }}> {invoicetabledata.ItemDiscription} </td>
                                            <td style={{ textAlign: 'right' }}> {invoicetabledata.Qty != null ? ThousandSeparater((invoicetabledata.Qty).toFixed(2)) : invoicetabledata.Qty} </td>
                                            <td style={{ textAlign: 'right' }}> {((invoicetabledata.SaleRate).toFixed(2))} </td>
                                            <td style={{ textAlign: 'right' }}> {ThousandSeparater((invoicetabledata.Value).toFixed(2))} </td>
                                        </tr>

                                    </>
                                )
                            })
                            }
                            <tr id='ttl_dis_fr_invprntthrml'>
                                <td colSpan="4" style={{ textAlign: 'right' }}>
                                    Total Qty:</td>
                                <td style={{ textAlign: 'right' }}><b>{ThousandSeparater(parseFloat(TotalQty).toFixed(2))}</b></td>
                                {/* <td colSpan="2" style={{ textAlign: 'right' }}><b>{TotalNetAmt}</b></td> */}
                            </tr>
                            <tr id='dis_invprntthrml' >
                                <td colSpan="4" style={{ textAlign: 'right' }}>Total Discount:</td>
                                <td colSpan="1" style={{ textAlign: 'right' }}>{ThousandSeparater(parseFloat(Discount).toFixed(2))}</td>
                            </tr>


                        </tbody>
                        <tfoot>                

                            <tr id='netblnc_invprntthrml' >
                                <td colSpan='4' style={{ textAlign: 'right' }}>Grand Total:</td>
                                <td colSpan='1' style={{ textAlign: 'right' }}><b>{ThousandSeparater((parseFloat(TotalNetAmt) - parseFloat(Discount)).toFixed(2))}</b></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div id="legalcopy">
                    <br/>
                    <p className="legal"><strong>Thank you!</strong></p>
                    <br/>
                    {/* <p><b>User:</b> {UserName}</p> */}
                </div>

            </div>
        </div>

    </div>

    )
}

{/* <div className='size' >
            <Row className='between-rows-margin between-left-margin between-right-margin'>
                <Col sm={3} md={3} xl={3}>
                <img src={ConfigObj.Logo} width="100" height="100" />
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'center' }}>
                    <h3 style={{ textAlign: 'center' }}> {CompName} </h3>
                </Col>
            </Row>

            <Row className='between-rows-margin between-left-margin between-right-margin' >

                <Col lg={8} md={8} lg={8}>
                    <Row lg={12} md={12} xl={12} >
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            Inv.#
                        </Col>
                        <Col sm={9} md={9} xl={9}>
                            { Inv }
                        </Col>
                    </Row>

                    <Row lg={12} md={12} xl={12}>
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            Inv Date:
                        </Col>
                        <Col sm={9} md={9} xl={9}>
                            { InvDate }
                        </Col>
                    </Row>

                    <Row lg={12} md={12} xl={12}>
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            Customer:
                        </Col>
                        <Col sm={9} md={9} xl={9}>
                            { Supplier }
                        </Col>
                        <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
                            Godown:
                        </Col>
                        <Col sm={9} md={9} xl={9}>
                            { Godown }
                        </Col>
                    </Row>
                </Col>

                <Col lg={4} md={4} lg={4}>

                    <Row sm={12} md={12} xl={12}>
                        
                        <Col sm={4} md={4} xl={4} style={{ fontWeight: "bold" }}>
                            Payment Type:
                        </Col>
                        <Col sm={8} md={8} xl={8}>
                            { PaymentType }
                        </Col>
                        <Col sm={4} md={4} xl={4} style={{ fontWeight: "bold" }}>
                            Bank Name:
                        </Col>
                        <Col sm={8} md={8} xl={8}>
                            { BankName }
                        </Col>


                    </Row>
                </Col>
            </Row>


            <Row className='between-rows-margin between-left-margin between-right-margin' >
                <Col>
                    <div id='table_sec_invprt'>
                        <table id='main_table_invoicepr' style={{ marginBottom: "20px" }}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}> Sr # </th>
                            <th style={{ textAlign: 'left' }}> Item Description </th>
                            <th style={{ textAlign: 'center' }}> Qty </th>
                            <th style={{ textAlign: 'right' }}> Rate </th>
                            <th style={{ textAlign: 'right' }}> Amount </th>
                        </tr>
                    </thead>

                    <tbody >
                        {
                            InvoiceTableData.map((invoicetabledata, index) => {
                                TotalQty += invoicetabledata.Qty
                                TotalNetAmt += invoicetabledata.Value
                                return(                               
                                    <tr key={index}>
                                        <td style={{ textAlign: 'center' }}> {index + 1} </td>
                                        <td style={{ textAlign: 'left' }}> {invoicetabledata.ItemDiscription} </td>
                                        <td style={{ textAlign: 'center' }}> {invoicetabledata.Qty} </td>
                                        <td style={{ textAlign: 'right' }}> {invoicetabledata.SaleRate} </td>
                                        <td style={{ textAlign: 'right' }}> {invoicetabledata.Value} </td>
                                    </tr>                                
                            )
                        })
                        }

                    </tbody>
                    <tfoot>
                        <tr>
                            <td style={{ textAlign: 'right' }} colSpan='2'  > Total:</td>
                            <td style={{ textAlign: 'center' }}>{TotalQty}</td>
                            <td style={{ textAlign: 'right' }}></td>
                            <td style={{ textAlign: 'right' }}>{TotalNetAmt}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
                </Col>
            </Row>

            <Row className="divFooter" style={{ marginTop: "3%" }} >
               <p>{ConfigObj && ConfigObj.SaleInvoiceFooterLine1 && ConfigObj.SaleInvoiceFooterLine1 !== '' && ConfigObj.SaleInvoiceFooterLine1}
               <br />
               {ConfigObj && ConfigObj.SaleInvoiceFooterLine2 && ConfigObj.SaleInvoiceFooterLine2 !== '' && ConfigObj.SaleInvoiceFooterLine2 }
            </p>
            </Row>
        </div> */}

const mapStateToProps = state => ({
    CompName: state.GlobalVariables.Company.Name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig,
})

export default connect(mapStateToProps)(SaleInvoiceReport)

// export default InvoicePrint