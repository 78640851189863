import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';


import { Provider } from 'react-redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducers from './React_Redux/Reducers'
import { createStore } from 'redux'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'

const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducers)
const store = createStore(persistedReducer, composeWithDevTools())
let persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store} >
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate> 
  </Provider>,
  document.getElementById('root')
);


