import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "./Table.css"
import { rptReciveables, rptReciveablesssss } from '../../../Services/Sale_ReportAPI'

function Receiveable({Comp_Name,Comp_Id,User_Name,ConfigObj}) {

    const { ProjectID,FromDate,ToDate } = useParams();
    const [ItemData, setItemData] = useState([])
    const [Balance, setBalance] = useState(0)
    const [GrandTotal, setGrandTotal] = useState(0)
    const [TotalPaid, setTotalPaid] = useState(0)

    useEffect(() => {
        document.title = "Distribution / Recieveable" 
     
        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('footer').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;
        
        rptReciveables(Comp_Id,ProjectID,FromDate,(mData)=>{
           mData.unshift({PARTYNAME:'CUSTOMERS LEDGER SUMMARY',BALANCE:'', CREDIT: '',DEBIT:'', CUSTOMERLEDGERSSUMMARY:'0'})
           var mIndex =  mData.findIndex(x=> x.CUSTOMERLEDGERSSUMMARY === 'TOTAL CUSTOMERS LEDGER SUMMARY')
           mData.splice(mIndex+1, 0, {PARTYNAME:'COMPANY GROUP LEDGER SUMMARY',BALANCE:'', CREDIT: '',DEBIT:'', CUSTOMERLEDGERSSUMMARY:'0'});
          // mData[mIndex] = {CUSTOMERLEDGERSSUMMARY:'COMPANY GROUP LEDGER SUMMARY',BALANCE:'', CREDIT: '',DEBIT:'', PARTYNAME:''}
           debugger
            var mCompanyTotals = mData.find(x=> x.CUSTOMERLEDGERSSUMMARY === 'TOTAL COMPANY GROUP LEDGER SUMMARY')
            //var _receivables =  numFormatter(parseFloat(mCompanyTotals[0].Balance))
            const CompanyTotal_Debit = parseFloat(mCompanyTotals.DEBIT).toFixed(2)
            const CompanyTotal_Credit = parseFloat(mCompanyTotals.CREDIT).toFixed(2)
            const CompanyTotal_Balance = parseFloat(mCompanyTotals.BALANCE).toFixed(2)
    
          var mCustomerTotal =   mData.find(x=> x.CUSTOMERLEDGERSSUMMARY === 'TOTAL CUSTOMERS LEDGER SUMMARY')
        
          const CustomerTotal_Debit =  parseFloat(mCustomerTotal.DEBIT).toFixed(2)
          const CustomerTotal_Credit =  parseFloat(mCustomerTotal.CREDIT).toFixed(2)
          const CustomerTotal_Balance =  parseFloat(mCustomerTotal.BALANCE).toFixed(2)
    
          const mGrandDebitTotal = parseFloat(CompanyTotal_Debit) + parseFloat(CustomerTotal_Debit)
          const mGrandCreditTotal =  parseFloat(CompanyTotal_Credit) + parseFloat(CustomerTotal_Credit)
          const mGrandBalanceTotal =  (parseFloat(CompanyTotal_Balance) + parseFloat(CustomerTotal_Balance))
          
          setBalance(mGrandBalanceTotal)
            setGrandTotal(mGrandDebitTotal)
            setTotalPaid(mGrandCreditTotal)
            setItemData(mData)
        })
        // rptReciveablesssss(Comp_Id,ProjectID,(mData)=>{
        //     if(mData.length > 0){
        //     var Totals = mData.splice(-1);
        //     setBalance(Totals[0].Balance)
        //     setGrandTotal(Totals[0].InvoiceAmt)
        //     setTotalPaid(Totals[0].RecoverdAmt)
        //     setItemData(mData)
        //     }
        
        // })
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
            <Row  style={{ textAlign: 'center', color: "black" }}>
            <Col sm={3} md={3} xl={3}><img src={ConfigObj.Logo} width="100" height="100" /></Col>
            <Col sm={6} md={6} xl={6}>
                    <h2> {Comp_Name} </h2> <br /> <h3>Recievable</h3>
            </Col>
            </Row>
            {/*<Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}> 
            <Col sm={6} md={6} xl={6} style={{ textAlign: 'left' }} >
                    <Row >
                        <Col lg={3} md={3} xl={3} style={{ borderLeft: '5px solid black', height: '20px', position: 'absolute', left: '10%' }}>
                            <b>From Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} style={{marginLeft:'26%'}}>
                            {ReverseDate(FromDate)}
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'right' }}>
                     <Row >
                            <Col lg={3} md={3} xl={3} style={{ borderLeft: '5px solid black', height: '20px',left:'60%',textAlign:'left'}}>
                            <b>To Date:</b>
                            </Col>
                            <Col lg={9} md={9} xl={9} style={{marginLeft:'-8%'}}>
                            {ReverseDate(ToDate)}
                            </Col>
                    </Row>
                </Col>

            </Row>*/}
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                        
                            <th style={{ width: '25%', textAlign: 'left', fontWeight: "bold" }} >Party Name</th>
                            <th style={{ width: '25%', textAlign: 'center', fontWeight: "bold" }} >Debit</th>
                            <th style={{ width: '25%', textAlign: 'center', fontWeight: "bold" }} >Credit</th>
                            <th style={{ width: '25%', textAlign: 'center', fontWeight: "bold" }} >Balance</th>
                            <th ></th>
                            <th ></th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                    
                        {
                            ItemData.map((Itemdata, index) => (
                               
                                <tr key={index}>
                                <td style={{ width: '25%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px', fontWeight:Itemdata.PARTYNAME && Itemdata.PARTYNAME !== 'CUSTOMERS LEDGER SUMMARY' && Itemdata.PARTYNAME !== 'COMPANY GROUP LEDGER SUMMARY' ? '' : 'bold',borderTop:Itemdata.PARTYNAME ? '' : '2px solid black',borderBottom:Itemdata.PARTYNAME ? '' : '2px solid black'   }}  >{Itemdata.PARTYNAME ? Itemdata.PARTYNAME : 'Total Amount'}</td>
                                <td style={{ width: '25%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px', fontWeight:Itemdata.PARTYNAME ? '' : 'bold',borderTop:Itemdata.PARTYNAME ? '' : '2px solid black',borderBottom:Itemdata.PARTYNAME ? '' : '2px solid black' }}  > {Itemdata.DEBIT ? parseFloat(Itemdata.DEBIT).toFixed(2) : ''} </td>
                                <td style={{ width: '25%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px', fontWeight:Itemdata.PARTYNAME ? '' : 'bold',borderTop:Itemdata.PARTYNAME ? '' : '2px solid black',borderBottom:Itemdata.PARTYNAME ? '' : '2px solid black' }}  >{Itemdata.CREDIT ? parseFloat(Itemdata.CREDIT).toFixed(2) : ''}</td>
                                <td style={{ width: '25%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px', fontWeight:Itemdata.PARTYNAME ? '' : 'bold',borderTop:Itemdata.PARTYNAME ? '' : '2px solid black',borderBottom:Itemdata.PARTYNAME ? '' : '2px solid black' }}  >{Itemdata.BALANCE ? parseFloat(Itemdata.BALANCE).toFixed(2) : ''}</td>
                                <td></td>
                                <td></td>
                                </tr>
                               
                                   
                                  
                               
                            ))
                           
                        }
                        
                        <tr id='footer_balance' style={{border:'2px solid black',borderLeft:'none',borderRight:'none'}}>
                        <td id='footer_td_cb' style={{ width: '25%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px', fontWeight: 'bold', fontSize: '20px' }}  >Grand Totals</td>
                        <td style={{ width: '25%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px', fontWeight: 'bold', fontSize: '20px' }}  > {parseFloat(GrandTotal).toFixed(2)} </td>
                        <td style={{ width: '25%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px', fontWeight: 'bold', fontSize: '20px' }}  >{parseFloat(TotalPaid).toFixed(2)}</td>
                        <td style={{ width: '25%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px', fontWeight: 'bold', fontSize: '20px' }}  >{parseFloat(Balance).toFixed(2)}</td>
                        <td></td>
                        <td></td>
                           
                        </tr>
                    </tbody>
                       

                </table>
                {/* </div> */}

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
    
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig
})

export default connect(mapStateToProps)(Receiveable)
