import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectBooking =(Comp_Id,ProjectID,fromdate,todate,AreaID,Callback) =>{
    
  axios.get(`${domain}/OrderBooking/SelectBooking/${Comp_Id}/${ProjectID}/${fromdate}/${todate}/${AreaID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}
export const SelectBookingDetail =(Comp_Id,ProjectID,OBID,Callback) =>{
    axios.get(`${domain}/OrderBooking/SelectBookingDetail/${Comp_Id}/${ProjectID}/${OBID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
export const ApproveOrders = (Booking_Data,callback) =>{
 
    axios.post(`${domain}/OrderBooking/ApproveOrders`,{
        Booking_Data
    })
        .then(response=>{
            toast.configure()
            toast.info(response.data.message, {
                theme:'light',
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            callback()
        })
        .catch(err=>console.error(err))
  }
  export const SelectApprovedOrdersList =(Comp_Id,ProjectID,fromdate,todate,Callback) =>{
    
    axios.get(`${domain}/OrderBooking/SelectApprovedOrdersList/${Comp_Id}/${ProjectID}/${fromdate}/${todate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  export const SelectBookingAgainstCustomer =(CustomerID,Callback) =>{
    
    axios.get(`${domain}/OrderBooking/SelectBookingAgainstCustomer/${CustomerID}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }
  

  export const SaveBooking = (bookingMasterData,bookingDetailData,deletedbookingDetailData,isAddNew,callback) =>{

    axios.post(`${domain}/OrderBooking/SaveBooking`,{
      bookingMasterData,bookingDetailData,deletedbookingDetailData,
       isAddNew
    })
        .then(response=>{
            toast.configure()
            toast.info(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            callback()
        })
        .catch(err =>{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${err}`,
              })
         })
  }