import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Row, Col, Card, Button, Spinner } from 'react-bootstrap'
import { Add, Edit, Loop, Details, ViewAgenda, Save, Delete, } from '@material-ui/icons'
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { connect } from 'react-redux'

import { SelectCityList } from '../../Services/CityAPI'
import { SelectBooking, SelectBookingDetail , SaveBooking} from '../../Services/OrderBookingAPI'
import { SelectUserProjects } from "../../Services/Projects"
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import { SelectSchemeFromCustItem,SelectDataForApp } from '../../Services/SchemeAPI';
import { GetCurrentDate , GetCurrentTime,msg} from '../../Services/Common'
import { SelectCustomers_List } from '../../Services/CustomersAPI'
import { SelectItemsListWRTProject } from '../../Services/ItemsAPI'

function BookOrder(props) {

    const { Comp_Id, ProjectID, SHOW_SCREEN, Group_Id, p_userid } = props;

    const [Booking_Data, setBooking_Data] = useState([])
    const [Customer_List, setCustomer_List] = useState([])
    const [SelectedCustomer, setSelectedCustomer] = useState(null)
    const [Item_List, setItem_List] = useState([])
    const [Selected_item, setSelected_item] = useState(null)
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [LoadAlll, setLoadAlll] = useState(false)
    const [OrderBookingDetail, setOrderBookingDetail] = useState([])
    const [CustomerName, setCustomerName] = useState(null)
    const [Show_OBDetailModal, setShow_OBDetailModal] = useState(false)
    const [CityData, setCityData] = useState([])
    const [SelectedCity, setSelectedCity] = useState(null)
    const [ProjectList, setProjectList] = useState([])
    const [SelectedProject, setSelectedProject] = useState(null)
    const [PackSize, setPackSize] = useState(' ')
    const [Price, setPrice] = useState(0)
    const [Selected_Type, setSelected_Type] = useState(null)
    const [Qty, setQty] = useState(1)
    const [GrandTotal, setGrandTotal] = useState(0)
    const [BonusCriteria, setBonusCriteria] = useState('')
    const [BonusQty, setBonusQty] = useState(0)
    const [btn_Disabled, setBtn_Disabled] = useState(false)
    const [ErrObj, setErrObj] = useState({
        ProjectErr :'',
        CityErr :'',
        CustomerErr :'',
        TypeErr :'',
        GrandTotalErr :'',
        ItemErr:'',
    })
    const [response, setResponse] = useState("");
    const [CustomerSchemeID, setCustomerSchemeID] = useState(0)
    const [IsLoader, setIsLoader] = useState(false)

    useEffect(() => {
        document.title = " Distribution / Order Booking"
        //   debugger
        // if(NotificationData !== ""){
        //   debugger
        // }
          SelectGroupScreenAccess(Group_Id,18,144,(data)=>{

            setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
            setBtn_delete(parseInt(data.Del) === 0 ? false : true)

            // SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
            // parseInt(data.Edit) === 0 ? false : true,
            // parseInt(data.Del) === 0 ? false : true,
            // data.OtherAccess
            // )

        })

        Projects_List()
        
    }, [])

    const Projects_List = () => {
        SelectUserProjects(Comp_Id, p_userid, (ProjectData) => {
            setProjectList(ProjectData)
            if (ProjectData.length > 0) {
                setSelectedProject(ProjectData[0])
                Select_City(ProjectData[0].Project_ID)
                SelectItems_List(ProjectData[0].Project_ID)
            }

        })
    }
    const Booking_Items = [

        {
            name: "ItemDiscription",
            label: "Name",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },

            }
        },
        {
            name: "Qty",
            label: "Qty",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "value",
            label: "Price",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
       
      
        {
            name: "Action",
            label: "Action",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                    )
                },
                  customBodyRender: (dataIndex, rowIndex) => {
                    var OrderBookingID = Booking_Data
                    if (OrderBookingID[rowIndex.rowIndex] != null)
                      return (
                        <>
                          <Link to="#" onClick={e=>{delete_item(rowIndex.rowIndex)}}><Delete/></Link>
                        </>
                      );
                  },
            }
        },

    ];
    const delete_item = (DeletedObj) =>{
        Booking_Data.splice(DeletedObj,1)
        Calculate_GrandTotal(Booking_Data)
    }
    const addToCart = ()=>{
        if(Selected_item){
            var mObj = {
                action:'add',
                ItemID : Selected_item && Selected_item.ItemID,
                ItemDiscription : Selected_item && Selected_item.ItemDiscription,
                Qty : Qty,
                value : parseFloat((parseFloat(Qty) * parseFloat(Price)).toFixed(2))
            }
            var mArray = Booking_Data.concat(mObj)
            setBooking_Data(mArray)
            Clear_Controls()
            Calculate_GrandTotal(mArray)
        }
        else{
           setErrObj({...ErrObj,ItemErr:'required'})
        }
        
    }
    const Calculate_GrandTotal = (mArray)=>{
        let _grandTotal = 0
        mArray.map(value=>{
            _grandTotal += value.value
        })
        setGrandTotal(_grandTotal)
    }
    const Clear_Controls = ()=>{
        setSelected_item(null)
        setPackSize('')
        setBonusQty(0)
        setBonusCriteria(' ')
        setPrice(0)
        setQty(1)
    }
    const Type_List = [
        { title: "Shop Open" },
        { title: "Shop Closed" },
        { title: "No Order" }
    ]
    const Select_City = (Project_ID) => {
        SelectCityList(Comp_Id, Project_ID, (mCities) => {

            setCityData(mCities)
        })
    }
    const Select_Customers = (Project_ID, CityID) => {
        SelectCustomers_List(Comp_Id, Project_ID, CityID, (mData) => {
            setCustomer_List(mData)
        })
    }
    const SelectItems_List = (ProjectID) => {
        SelectItemsListWRTProject(Comp_Id, ProjectID, (mItems) => {
            setItem_List(mItems)
        })
    }
    const theme = createTheme({
        overrides: {
            MuiTableCell: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                }
            }
        }
    });
    const validate = () =>{
        if(!SelectedProject){
           setErrObj({...ErrObj, ProjectErr: 'required'})
           return false
        }
        if(!SelectedCity){
            setErrObj({...ErrObj, ProjectErr: '',CityErr:'required'})
            return false
        }
        if(!SelectedCustomer){
            setErrObj({...ErrObj, ProjectErr: '',CityErr:'',CustomerErr:'required'})
            return false
        }
        if(!Selected_Type){
            setErrObj({...ErrObj, ProjectErr: '',CityErr:'',CustomerErr:'',TypeErr:'required'})
            return false
         }
         if(GrandTotal === 0){
            setErrObj({...ErrObj, ProjectErr: '',CityErr:'',CustomerErr:'',TypeErr:'',GrandTotalErr:'required'})
            return false
         }
         setErrObj({ProjectErr: '',CityErr:'',CustomerErr:'',TypeErr:'',GrandTotalErr:'',ItemErr:''})
         return true
 
    }
    const SaveHandler = () => {
        if(validate()){
            var bookingMasterData = {
                OBID : 0,
                userID:p_userid,
                customerID: SelectedCustomer.CustomerID,
                bookingDate:GetCurrentDate(),
                bookingTime:GetCurrentTime(),
                shopStatus:Selected_Type.title,
                areaID:SelectedCity.CityID,
                grandTotal:GrandTotal,
                compId:Comp_Id,
                projectID:SelectedProject.Project_ID,
            }
            setBtn_Disabled(true)
            SaveBooking(bookingMasterData,Booking_Data,[],true,()=>{
                // clear 
                setBtn_Disabled(false)
                Reset_Control()
            })
        }
    }
    const Reset_Control = () =>{
        Clear_Controls()
        setBooking_Data([])
        setSelectedCustomer(null)
        setSelectedCity(null)
        setSelected_Type(null)
        setGrandTotal(0)
        setErrObj({
            ProjectErr :'',
            CityErr :'',
            CustomerErr :'',
            TypeErr :'',
            GrandTotalErr :'',
            ItemErr:'',
        })
    }
    const SelectScheme_FromCustItem = (CustomerID,ItemID)=>{
        
        SelectDataForApp(Comp_Id,ProjectID,ItemID,CustomerID,(mData)=>{
          
            if(mData.length > 0 ){
                setBonusCriteria(mData[0].BonusCriteria)
                setBonusQty(mData[0].BonusQty)
                setPackSize(mData[0].PackSize)
                setPrice(mData[0].SalePrice)
            }
            else{
                setBonusCriteria('')
                setBonusQty(0)
                setPackSize('')
                setPrice(0)
            }
           
        })
    }
    return ReactDOM.createPortal(
        <>
            <div className="block-header">
                <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <h2>Order Booking
                        </h2>
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-12" style={{textAlign:'right',marginTop:'2%'}}>
                       {btn_add && <button  className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={Reset_Control} > <Add /> Create New </button>} 
                    </div>
                </div>
            </div>
            <Card>

                <Row className="row_margin_left row_margin_right pt-4 pr-4 pl-4" >


                    <Col lg={4} xl={4} md={6} sm={12} >
                        <Autocomplete
                            name="Project"
                            id="Project"
                            options={ProjectList}
                            getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
                            value={SelectedProject}
                            onChange={(e, value) => {
                                if (value) {
                                    setSelectedProject(value)
                                    Select_City(value.Project_ID)
                                    SelectItems_List(value.Project_ID)
                                }
                                else {
                                    setSelectedProject(null)
                                    setCityData([])
                                    setSelectedCity(null)
                                    setCustomer_List([])
                                    setSelectedCustomer(null)
                                   
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Project" label="Select Branch"
                                />}
                        />
                        <span className="text-danger">{ErrObj.ProjectErr}</span>
                    </Col>
                    <Col lg={4} xl={4} md={6} sm={12} >
                        <Autocomplete
                            id="City"
                            name="City"
                            options={CityData}
                            value={SelectedCity}
                            getOptionLabel={(option) => option.City ? option.City : ""}
                            onChange={(e, value) => {

                                if (value) {
                                    setSelectedCity(value)
                                    if (SelectedProject)
                                        Select_Customers(SelectedProject.Project_ID, value.CityID)
                                }
                                else {
                                    setSelectedCity(null)
                                    setSelectedCustomer(null)
                                    setCustomer_List([])
                                  

                                }
                            }}
                            renderInput={(params) => <TextField {...params} name="City" label="City" />}
                        />
                        <span className="text-danger">{ErrObj.CityErr}</span>
                    </Col>
                    <Col lg={4} xl={4} md={6} sm={12} >
                        <Autocomplete
                            name="Customer"
                            id="Customer"
                            options={Customer_List}
                            getOptionLabel={(option) => option.CustomerName ? option.CustomerName : ""}
                            value={SelectedCustomer}
                            onChange={(e, value) => {
                                if (value) {
                                    setSelectedCustomer(value)
                                    // if(Selected_item)
                                    // {
                                    //     SelectScheme_FromCustItem(value.CustomerID,Selected_item.ItemID)
                                    // }
                                    // else{
                                    //     setBonusCriteria(' ')
                                    //     setBonusQty(0)
                                    // }
                                }
                                else{
                                    // setBonusCriteria(' ')
                                    // setBonusQty(0)
                                    setSelectedCustomer(null)
                                }
                                   

                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Customer" label="Customer"
                                />
                            }
                        />
                        <span className="text-danger">{ErrObj.CustomerErr}</span>
                    </Col>

                </Row>
                <Row className="row_margin_left row_margin_right pr-4 pl-4" >
                    <Col lg={4} xl={4} md={6} sm={12}>
                        <Autocomplete
                            name="Item"
                            id="Item"
                            options={Item_List}
                            getOptionLabel={(option) => option.ItemName ? option.ItemName : ""}
                            value={Selected_item}
                            onChange={(e, value) => {
                                if (value) {
                                    setSelected_item(value)
                                    setPackSize(value.Remarks)
                                    setPrice(value.PackSaleRate)
                                    if(SelectedCustomer)
                                    {
                                        
                                        SelectScheme_FromCustItem(SelectedCustomer.CustomerID,value.ItemID)
                                    }
                                    else{
                                        msg('Customer Required')
                                        setBonusCriteria(' ')
                                        setBonusQty(0)
                                        setPackSize(' ')
                                        setPrice(0)

                                    }

                                }
                                else {
                                    setSelected_item(null)
                                    setPackSize(' ')
                                    setPrice(0)
                                    setBonusCriteria(' ')
                                    setBonusQty(0)
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Item" label="Select Item"
                                />}
                        />
                        <span className="text-danger">{ErrObj.ItemErr}</span>
                    </Col>
                    <Col lg={4} xl={4} md={6} sm={6}>
                        <TextField
                            name="PackSize"
                            label="Pack Size"
                            value={PackSize}
                            inputProps={
                                { readOnly: true }
                            }
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={6} sm={6}>
                        <TextField
                            name="Price"
                            label="Price"
                            value={Price}
                           onChange={e=> setPrice(e.target.value)}
                            fullWidth
                        />
                    </Col>

                </Row>
                <Row className="row_margin_left row_margin_right pr-4 pl-4" >
                    <Col lg={2} xl={2} md={3} sm={3}>
                        <TextField
                            name="BonusCriteria"
                            label="Bonus Criteria"
                            value={BonusCriteria}
                            inputProps={
                                { readOnly: true }
                            }
                            fullWidth
                        />
                    </Col>
                    <Col lg={2} xl={2} md={3} sm={3}>
                        <TextField
                            name="BonusQty"
                            label="Bonus Qty"
                            value={BonusQty}
                            inputProps={
                                { readOnly: true }
                            }
                            fullWidth
                        />
                    </Col>
                    <Col lg={4} xl={4} md={6} sm={12}>
                        <Autocomplete
                            name="Type"
                            id="Type"
                            options={Type_List}
                            getOptionLabel={(option) => option.title}
                            value={Selected_Type}
                            onChange={(e, value) => {
                                if (value) {
                                    setSelected_Type(value)

                                }
                                else {
                                    setSelected_Type(null)
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Item" label="Select Type"
                                />}
                        />
                        <span className="text-danger">{ErrObj.TypeErr}</span>
                    </Col>
                    <Col lg={4} xl={4} md={6} sm={6}>
                        <TextField
                            name="Qty"
                            label="Qty"
                            value={Qty}
                            onChange={e => setQty(e.target.value)}
                            fullWidth
                        />
                    </Col>

                </Row>
                <Row className="row_margin_left row_margin_right pr-4 pl-4" >
                    <Col lg={4} xl={4} md={6} sm={6}>
                        <TextField
                            name="GrandTotal"
                            label="Grand Total"
                            value={GrandTotal}
                            inputProps={
                                { readOnly: true }
                            }
                            fullWidth
                        />
                        <span className="text-danger">{ErrObj.GrandTotalErr}</span>
                    </Col>
                    <Col style={{ textAlign: "right", marginTop: "1%" }} >
                        <button id="btnCancel" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={addToCart} > <Loop /> <u>Add to Cart</u></button>
                        <button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={SaveHandler} disabled={btn_Disabled}>
                            {
                                btn_Disabled ?
                                    (<>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> Saving... </>) : <><Save /> Save</>
                            }  </button>
                    </Col>
                </Row>
                <Row className="row_margin_left row_margin_right" >
                    <Col>
                        <div className="table-responsive" >
                            <MuiThemeProvider theme={theme}>
                                <MUIDataTable
                                    name="IPD_Datatables"
                                    data={Booking_Data}
                                    columns={Booking_Items}
                                    options={{
                                        selectableRows: 'none',
                                        print: false,
                                        search: true,
                                        download: false,
                                        viewColumns: false,
                                        filter: false,
                                    }}
                                />
                            </MuiThemeProvider>
                        </div>
                    </Col>
                </Row>

            </Card>

        </>, document.getElementById('PageContents')
    )
}

const mapStateToProps = state => ({
    Comp_Id: state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    ItemCat: state.Items && state.Items.ItemData,
    Group_Id: state.Login_User.User_Data.Group_Id,
    ProjectID: state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
    p_userid: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
})
export default connect(mapStateToProps)(BookOrder)
