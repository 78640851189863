import React, { useState, useEffect } from 'react'
import { Modal, Card, Row, Col, Button ,Spinner } from "react-bootstrap"
import { TextField } from "@material-ui/core"
import { Save, Cancel } from "@material-ui/icons"
import { Autocomplete } from '@material-ui/lab'
import { connect } from "react-redux"
import { SelectBranchesList } from '../../Services/BranchAPI'
import { SelectProjectForModal,SaveProject  } from '../../Services/Projects'
import { GetCurrentDate } from '../../Services/Common'

function ProjectModal({ show, onHide, Project_ID, is_add_new, show_screen,}) {

    const [btn_edit, setBtn_edit] = useState(true)
    const [OtherAccess, setOtherAccess] = useState(null)
    const [ProjectName, setProjectName] = useState('')
    const [Abbr, setAbbr] = useState('')
    const [Address, setAddress] = useState('')
    const [NTNNo, setNTNNo] = useState('')
    const [SaleTaxNo, setSaleTaxNo] = useState('')
    const [Email, setEmail] = useState('')
    const [PhoneNo, setPhoneNo] = useState('')

   const [SelectedBranch, setSelectedBranch] = useState(null)
   const [BranchesList, setBranchesList] = useState([])
    const [BranchError, setBranchError] = useState('')
    const [ProjectNameError, setProjectNameError] = useState('')
    const [btn_Disabled, setBtn_Disabled] = useState(false)
    useEffect(() => {
        if(show){
            SelectBranchesList(mBranches=>{
                setBranchesList(mBranches)
                setBranchError('')

                if(is_add_new === true)
                {
                    setProjectName('')
                    setAbbr('')
                    setProjectNameError('')
                    setAddress('')
                    setNTNNo('')
                    setSaleTaxNo('')
                    setEmail('')
                    setPhoneNo('')
                    setSelectedBranch(null)
                   
                }
                else if(is_add_new === false)
                {
                    // setBtn_edit(show_screen.edit) 
                    if(Project_ID){
                        SelectProjectForModal(Project_ID,(data)=>{
                            setProjectName(data[0].ProjectName)
                            setAbbr(data[0].Abbr)
                            setAddress(data[0].Address)
                            setNTNNo(data[0].NTNNo)
                            setSaleTaxNo(data[0].SaleTaxNo)
                            setEmail(data[0].Email)
                            setPhoneNo(data[0].PhoneNo)
                           var _mSelectedBranch = mBranches.find(x=> x.Branch_Id === data[0].Branch_Id)
                           if(_mSelectedBranch){
                            setSelectedBranch(_mSelectedBranch)
                           }
                        })
                    }
                }
            })
           
        }
    }, [show,is_add_new, Project_ID])

   
    const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }

   
    const save  = () =>{
        if(ProjectName.trim() !== ""){
            if(SelectedBranch)
            {
                var obj_save = {
                    Project_ID,
                    Branch_Id: SelectedBranch.Branch_Id,
                    ProjectName,Abbr,PhoneNo,Email,NTNNo,SaleTaxNo,Address
                }
                setBtn_Disabled(true)
                SaveProject(obj_save,  is_add_new,(message) =>{
                    setBtn_Disabled(false)
                    clearFields()
                })
            }
            else{
                setBranchError('required')
            }
        }
        else{
            setProjectNameError('required')
        }

    }



    const clearFields = () => {

        onHide()
        setProjectName('')
        setAbbr('')
        setBranchError('')
        setProjectNameError('')
        setAddress('')
        setNTNNo('')
        setSaleTaxNo('')
        setEmail('')
        setPhoneNo('')
        setSelectedBranch(null)
    }

    return (
        <>
            <Modal show={show} onHide={onHide} size="lg"   backdrop="static"  centered>
                <Modal.Header closeButton className="header_title " >
                    <Modal.Title >  Project </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                        <Col lg={6} xl={6} md={6} >
                            <TextField
                                name="ProjectName"
                                id="ProjectName"
                                label="Project Name"
                                type="text"
                                value={ProjectName}
                                onInput={toInputUppercase}
                                onChange={e=> setProjectName(e.target.value)}
                                fullWidth
                            />
                            <span style={{color: "red"}} >{ProjectNameError}</span>
                            </Col>
                        <Col lg={6} xl={6} md={6} >
                            <Autocomplete
                                name="Branches"
                                id="Branches"
                                options={BranchesList}
                                getOptionLabel={(option) => option.Name ? option.Name : ""} 
                                value={SelectedBranch}
                                onChange={(e,value) =>{
                                if(value)
                                {
                                    setSelectedBranch(value)
                                }
                                else
                                setSelectedBranch(null)
                                    
                                }}
                                renderInput={(params) => 
                                <TextField {...params} name="Branches" label="Select Branch "
                                />
                            }
                            />                                
                            <span style={{color: "red"}} >{BranchError}</span>

                        </Col>
                           
                            <Col lg={6} xl={6} md={6} >
                                <TextField
                                    name="Abbr"
                                    id="Abbr"
                                    label="Abbrevation"
                                    type="text"
                                    value={Abbr}
                                    onChange={e=> setAbbr(e.target.value)}
                                    onInput={toInputUppercase}
                                    fullWidth
                                />
                            </Col>
                            <Col lg={6} xl={6} md={6} >
                                <TextField
                                    name="PhoneNo"
                                    id="PhoneNo"
                                    label="Phone No"
                                    type="text"
                                    value={PhoneNo}
                                    onChange={e=> setPhoneNo(e.target.value)}
                                   
                                    fullWidth
                                />
                            </Col>
                            <Col lg={6} xl={6} md={6} >
                                <TextField
                                    name="Email"
                                    id="Email"
                                    label="Email"
                                    type="text"
                                    value={Email}
                                    onChange={e=> setEmail(e.target.value)}
                                    fullWidth
                                />
                            </Col>
                            <Col lg={3} xl={3} md={3} >
                                <TextField
                                    name="SaleTaxNo"
                                    id="SaleTaxNo"
                                    label="Sale Tax No"
                                    type="text"
                                    value={SaleTaxNo}
                                    onChange={e=> setSaleTaxNo(e.target.value)}
                                    fullWidth
                                />
                            </Col>
                            <Col lg={3} xl={3} md={3} >
                                <TextField
                                    name="NTNNo"
                                    id="NTNNo"
                                    label="NTN No"
                                    type="text"
                                    value={NTNNo}
                                    onChange={e=> setNTNNo(e.target.value)}
                                    fullWidth
                                />
                            </Col>
                            <Col lg={12} xl={12} md={12} >
                                <TextField
                                    name="Address"
                                    id="Address"
                                    label="Address"
                                    type="text"
                                    value={Address}
                                    onInput={toInputUppercase}
                                    onChange={e=> setAddress(e.target.value)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                {btn_edit && (<Button variant="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={save}  disabled={btn_Disabled}> 
                                
                                {
                             btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                                }
                                </Button>)}
                                <Button variant="btn btn-primary rounded-pill" onClick={clearFields} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></Button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Project_ID,
    show_screen: state.SCREEN,    
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,

})

export default connect(mapStateToProps)(ProjectModal)
