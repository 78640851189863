import React from "react";
import { Route, withRouter } from "react-router-dom";
import { connect } from 'react-redux'

const ProtectedRoute = ({ component: Component, ...rest }) => {

  return (
    <Route
      {...rest}
      render={(props) => {
        let token = null
        const _localStorageToken = localStorage.getItem("token");
        if (_localStorageToken) {
          token = _localStorageToken
          localStorage.removeItem('token')
          sessionStorage.setItem('token', _localStorageToken)
        }
        else {
          token = sessionStorage.getItem('token')
        }
          if (token) {
          return <Component {...props}  NotificationData = {rest.NotificationData} />;
        } else {
          props.history.push("/");
        }
      }}
    />
  );
};


// const mapStateToProps =  state =>({
//     currentToken : state.UserLogin.loginToken
// })
export default (withRouter(ProtectedRoute));
