import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const SelectPayments =(Comp_Id,ProjectID,Month,Callback) =>{
  axios.get(`${domain}/Payments/SelectPayments/${Comp_Id}/${ProjectID}/${Month}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const GetMaxPaymentNo =(Comp_Id,ProjectID,Callback) =>{
  axios.get(`${domain}/Payments/GetMaxPaymentNo/${Comp_Id}/${ProjectID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectInvoices =(Comp_Id,ProjectID,SupplierID,Callback) =>{
  axios.get(`${domain}/Payments/SelectInvoices/${Comp_Id}/${ProjectID}/${SupplierID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SavePayment = (PaymentMasterData,PaymentDetailData,DeletedPaymentDetailData,isAddNew,callback) =>{
 
  axios.post(`${domain}/Payments/SavePayment`,{
    PaymentMasterData,
    PaymentDetailData,
    DeletedPaymentDetailData,
    isAddNew
  })
      .then(response=>{
          toast.configure()
          toast.info(response.data.message, {
              theme:'light',
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          });
          callback()
      })
      .catch(err=>console.error(err))
}

export const SelectPaymentMaster =(Comp_Id,ProjectID,PaymentID,Callback) =>{
  axios.get(`${domain}/Payments/SelectPaymentMaster/${Comp_Id}/${ProjectID}/${PaymentID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}

export const SelectPaymentDetail =(PaymentID,Callback) =>{
  axios.get(`${domain}/Payments/SelectPaymentDetail/${PaymentID}`)
       .then(response=>{
           Callback(response.data.Data)
       })
       .catch(err =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${err}`,
          })
     })
}