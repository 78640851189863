import React from 'react'
import ReactDOM from 'react-dom'
import {Row, Col,Card, Spinner} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {TextField, Popper} from '@material-ui/core'
import { Save, Cancel,ShowChart} from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab';
import { connect} from 'react-redux'

import { GetCurrentDate } from '../../Services/Common'
import { SelectWareHouseList } from "../../Services/WareHouseAPI";
import { SaveOpening,SelectOpeningList,GetMaxOpeningNo, SelectOpening,SelectItemDetails} from "../../Services/OpeningAPI";


export class OpeningEntry extends React.Component {
  constructor(props){
      super(props)
    
      this.state = {
        OpeningNo:'',
        Transaction_Date:GetCurrentDate(),
        Transaction_Type:'',
        PurchaseRate:0,
        SaleRate:0,
        MRP: 0,
        GST: 0,
        Qty:1,
        Item_List:[],
        Selected_item:null,
        BatchNo:'0001',
        ExpairyDate:GetCurrentDate(),
        // Discription:'',
        UserID:0,
        Amount:0,
        UOM:'',
        Warehouse_List:[],
        SelectedWareHouse:null,
        CreatedDate:GetCurrentDate(),
        Approved_By:0,
        Approved_Date:GetCurrentDate(),
        Item_Err:'',
        WareHouse_Err:'',
        Qty_Err:'',
        Batch_Err:'',
        btn_edit: true,
        btn_Disabled:false
      }
      this.ProjectID = this.props.ProjectID
      this.UserID = this.props.p_userid
      this.Comp_Id = this.props.Comp_Id && this.props.Comp_Id

  }

  componentDidMount(){
    document.title = "Distribution / Opening"
    SelectItemDetails(this.Comp_Id,this.ProjectID,(mItems)=>{
        this.setState({Item_List:mItems,btn_Disabled:false})
        SelectWareHouseList(this.Comp_Id,this.ProjectID,(mWareHOuse)=>{ 
            this.setState({Warehouse_List:mWareHOuse,SelectedWareHouse:mWareHOuse[0]}) // auto select warehouse
            if(this.props.AddNew) // save
            { 
                this.GetMax_OpeningNo() // new reciver NO genrate
            }
            else // update
            { 
                SelectOpening(this.Comp_Id,this.ProjectID,this.props.InventoryID,(mData)=>{
                    this.setState({
                        OpeningNo:mData[0].OpeningNo,
                        Transaction_Date:mData[0].Transaction_Date,
                        Transaction_Type:mData[0].Transaction_Type,
                        PurchaseRate:mData[0].PurchaseRate,
                        SaleRate:mData[0].SaleRate,
                        Qty:mData[0].Qty,
                        Amount:mData[0].Amount,
                        BatchNo:mData[0].BatchNo,
                        ExpairyDate:mData[0].ExpairyDate,
                        MRP: mData[0].MRP,
                        GST: mData[0].GST,
                        btn_edit: this.props.show_screen.edit

                    })
                    mItems.map(val=>{
                        if(val.ItemID === mData[0].ItemID)
                        {
                            this.setState({Selected_item:val,UOM:val.UOM})
                        }
                    })
                    
                    mWareHOuse.map(val=>{
                        if(val.WareHouseID === mData[0].WareHouseID)
                        {
                            this.setState({SelectedWareHouse:val})
                        }
                    })
                })
            }

        })
    })
      

    // this.Select_Item_Details()
    // this.Select_WareHouse_List()
  }
  
 
  
    GetMax_OpeningNo = () => {
    GetMaxOpeningNo(this.Comp_Id,this.ProjectID, (mOpeningNo) => { // API Function
            this.setState({ OpeningNo: mOpeningNo })  // set state here 
        })
    }
    validate = () =>{
        var ItemErr = "", QtyErr = "",WareHouseErr = "", BatchErr = ''

        if(!this.state.Selected_item)
        {
            ItemErr = "Item is required"
        }
        if(!this.state.SelectedWareHouse)
        {
            WareHouseErr = "WareHouse is required"
        }
        if( this.state.Qty == '0' || this.state.Qty === "" || this.state.Qty < 0)
        {
            QtyErr = "Qty Must be Greater than 0"
        }
        if((this.state.BatchNo).trim() === ''){
            BatchErr = 'required'
        }
        if(ItemErr)
        {
            this.setState({Item_Err:ItemErr})
            document.getElementById('Item').focus()
            return false
        }
        if(WareHouseErr)
        {
            this.setState({Item_Err:'',WareHouse_Err:WareHouseErr})
            document.getElementById('WareHouse').focus()
            return false
        }
        if(QtyErr)
        {
            this.setState({Item_Err:'',WareHouse_Err:'',Qty_Err:QtyErr})
            document.getElementById('Qty').focus()
            return false
        }
        if(BatchErr){
            this.setState({Item_Err:'',WareHouse_Err:'',Qty_Err:'',Batch_Err:BatchErr})
            return false
        }
        this.setState({Item_Err:'',WareHouse_Err:'',Qty_Err:'',Batch_Err:''})
        return true
    }
    calculateAmount = () =>{
        
        var Amount = (parseFloat(this.state.Qty) * parseFloat(this.state.PurchaseRate))
        this.setState({Amount})
    }
    SaveHandler = () => {
            if(this.validate())
            {
                var OpeningData = {
                    InventoryID:this.props.InventoryID,
                    Comp_Id:this.Comp_Id,
                    ProjectID:this.ProjectID,
                    UserID:this.UserID,
                    OpeningNo:this.state.OpeningNo,
                    Transaction_Date:this.state.Transaction_Date,
                    TransactionType:'OP',
                    ItemID:this.state.Selected_item.ItemID,
                    Qty:this.state.Qty,
                    PurchaseRate:this.state.PurchaseRate,
                    SaleRate:this.state.SaleRate,
                    Amount:this.state.Amount,
                    Posted:0,
                    WareHouseID:this.state.SelectedWareHouse.WareHouseID,
                    CreatedDate:GetCurrentDate(),
                    Approved_By:this.UserID,
                    Approved_Date:GetCurrentDate(),
                    BatchNo:this.state.BatchNo,
                    ExpairyDate:this.state.ExpairyDate,
                    MRP: this.state.MRP,
                    GST: this.state.GST
                }
                this.setState({btn_Disabled:true})
                SaveOpening(OpeningData,this.props.AddNew,()=>{
                    this.setState({btn_Disabled:false})
                    document.getElementById('btnCancel').click()
                })
            }
         
    }
 
render(){
    return ReactDOM.createPortal (
        <>
            <Row className="row_margin_top row_margin_left row_margin_right" >
            <Col lg={6} xl={6} md={6} className="header_title" >
              <h2> Inventory Opening</h2>
            </Col>
          </Row>
            <Card>
          
            <Row className="row_margin_top row_margin_left row_margin_right m-2" >
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            name="OpeningNo"
                            label="Opening No" 
                            value={this.state.OpeningNo}
                            inputProps={
                                {readOnly: true}
                            }
                            fullWidth
                            />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={3} >
                        <TextField 
                            value={this.state.Transaction_Date}
                            name="Transaction_Date"
                            label="Transaction Date" 
                            type= 'date'
                            onChange= {e => this.setState({Transaction_Date:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    <Col lg={6} xl={6} md={6} >
                        <Autocomplete
                            name="Item"
                            id="Item"
                            options={this.state.Item_List}
                            getOptionLabel={(option) => option.ItemDiscription ? option.ItemDiscription : ""}
                            value={this.state.Selected_item}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({
                                        Selected_item:value,
                                        UOM:value.UOM,
                                        PurchaseRate:value.PackPurRate,
                                        Amount:value.PackPurRate,
                                        SaleRate:value.PackSaleRate,
                                        MRP: value.MRP,
                                        GST: value.GST
                                    })
                              
                                }
                                else
                                {
                                    this.setState({
                                        Selected_item:null, 
                                        UOM:'',
                                        PurchaseRate:'',
                                        Amount:''
                                    })
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="Item" label="Select Item"
                                />}
                        />
                        <span className="text-danger">{this.state.Item_Err}</span>
                    </Col>
                    
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="UOM"
                            label="UOM" 
                            value={this.state.UOM}
                            onChange={e=>this.setState({UOM:e.target.value})}
                            fullWidth
                            inputProps={
                                {readOnly: true}
                            }
                        />
                    </Col> 
                </Row>
                <Row className="row_margin_top row_margin_left row_margin_right m-2" >
            
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField 
                            name="BatchNo"
                            label="Batch No" 
                            value={this.state.BatchNo}
                            onChange = {e =>this.setState({BatchNo: e.target.value })}
                            // inputProps={
                            //     {readOnly: false}
                            // }
                            fullWidth
                            />
                              <span className="text-danger">{this.state.Batch_Err}</span>
                    </Col>
                   
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField 
                            value={this.state.ExpairyDate}
                            name="ExpairyDate"
                            label="Expairy Date" 
                            type= 'date'
                            onChange= {e => this.setState({ExpairyDate:e.target.value})}
                            fullWidth
                        />
                    </Col>
                    {/* <Col lg={6} xl={6} md={6} >
                        <Autocomplete
                            name="WareHouse"
                            id="WareHouse"
                            options={this.state.Warehouse_List}
                            getOptionLabel={(option) => option.WareHouse ? option.WareHouse : ""}
                            value={this.state.SelectedWareHouse}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    this.setState({SelectedWareHouse:value})
                              
                                }
                                else
                                {
                                    this.setState({SelectedWareHouse:null})
                            
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="WareHouse" label="Select WareHouse"
                                />}
                        />
                        <span className="text-danger">{this.state.WareHouse_Err}</span>
                    </Col> */}
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="Qty"
                            id="Qty"
                            label="Qty"
                            type="number" 
                            value={this.state.Qty}
                            onChange={e=>{
                                
                                this.setState({Qty:e.target.value},()=>{
                                    if(this.state.PurchaseRate !==0 && this.state.PurchaseRate !== "")
                                    this.calculateAmount()
                                })
                            }}
                            fullWidth
                        
                        />
                        <span className="text-danger">{this.state.Qty_Err}</span>

                    </Col>
               
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="PurchaseRate"
                            label="Purchase Rate"
                            type="number" 
                            value={this.state.PurchaseRate}
                            onChange={e=>{
                                
                                this.setState({PurchaseRate:e.target.value},()=>{
                                    if(this.state.PurchaseRate !==0 && this.state.PurchaseRate !== "")
                                    this.calculateAmount()
                                })
                            }}
                            fullWidth
                        
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="SaleRate"
                            label="Sale Rate"
                            type="number" 
                            value={this.state.SaleRate}
                            onChange={e=>{
                                
                                this.setState({SaleRate:e.target.value})
                            }}
                            fullWidth
                        
                        />
                    </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                    <TextField
                        name="MRP"
                        label="MRP"
                        type="number" 
                        value={this.state.MRP}
                        onChange={e=>{
                            
                            this.setState({MRP:e.target.value})
                        }}
                        fullWidth
                    
                    />
                    
                </Col>
                <Col sm={12} lg={2} xl={2} md={2} >
                <TextField
                    name="GST"
                    label="GST"
                    type="number" 
                    value={this.state.GST}
                    onChange={e=>{
                        
                        this.setState({GST:e.target.value})
                    }}
                    fullWidth
                
                />
                
            </Col>
                    <Col sm={12} lg={2} xl={2} md={2} >
                        <TextField
                            name="Amount"
                            label="Amount"
                            type="number" 
                            value={this.state.Amount}
                            inputProps={{
                                readOnly:true
                            }}
                            fullWidth
                        
                        />
                    </Col>
                    </Row>
      
                    <Row className=" row_margin_top row_margin_left row_margin_right" >
                    <Col style={{textAlign: "right", marginTop: "1%"}} >
                            {this.state.btn_edit &&(<button type="button" className="btn btn-primary rounded-pill"  style={{border: "none"}} onClick={this.SaveHandler} disabled={this.state.btn_Disabled}>
                            {
                             this.state.btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                                }  </button>)}
                            <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{border: "none"}} to={'/OpeningList'} > <Cancel /> <u>Close</u></Link>
                        </Col>
                    </Row>
              
                </Card>
            
        </>, document.getElementById('PageContents')
    )
}

}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
    AddNew: state.Opening && state.Opening.isAddNew,
    InventoryID:state.Opening && state.Opening.OPening_ID,
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
    show_screen: state.SCREEN
})
export default connect(mapStateToProps)(OpeningEntry)