import React, { useState, useEffect } from 'react'
import { Modal, Card, Row, Col, Button,Spinner  } from "react-bootstrap"
import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { Save, Cancel } from "@material-ui/icons"
import { connect } from "react-redux"
// import { SelectCompanyGroupAgainstManufacturer  } from '../../Services/CompanyGroupAPI'
import { SelectProductGroup,SaveProductGroup  } from '../../Services/ProductGroupAPI'
import { SelectManufacturedByList  } from '../../Services/manufactureAPI'

function PG_Modal({ show, onHide, PGID, is_add_new, show_screen,Comp_Id,ProjectID }) {

    const [ManufactuererBy_Data, setManufactuererBy_Data] = useState([])
    const [Selected_ManufactuererBy_Data, setSelected_ManufactuererBy_Data] = useState(null)
    // const [CompanyGroup_Data, setCompanyGroup_Data] = useState([])
    // const [SelectedCompanyGroup, setSelectedCompanyGroup] = useState(null)
    const [PGName, setPGName] = useState('')
    const [Discription, setDiscription] = useState('')
    const [Error, setError] = useState({
        ManufacturedBy: '',
        PGName: '',
        CG:''
    })
    const [btn_edit, setBtn_edit] = useState(true)
    const [OtherAccess, setOtherAccess] = useState(null)
    const [btn_Disabled, setBtn_Disabled] = useState(false)
    useEffect(() => {

        document.title = "Distribution / Product Group"
        SelectManufacturedBy_List()
         
        if(show){
            if(is_add_new === true)
            {
                setManufactuererBy_Data([])
                setSelected_ManufactuererBy_Data(null)
                //setSelectedCompanyGroup(null)
               // setCompanyGroup_Data([])
                setPGName('')
                setDiscription('')
                setError({ManufacturedBy: "", PGName: "",CG:''})
                document.getElementById("PGName").focus()
            }
            else if(is_add_new === false)
            {
                setBtn_edit(show_screen.edit) 
                if(PGID){
                    SelectProductGroup(Comp_Id,ProjectID, PGID,(data)=>{
                        var Selected_MB = ManufactuererBy_Data.find(x => x.ManufacturedByID === data[0].ManufacturedByID)
                       
                        // SelectCompanyGroupAgainstManufacturer(Comp_Id,ProjectID,data[0].ManufacturedByID,(mData)=>{
                        //     var Selected_CG = mData.find(x=> x.CGID === data[0].CGID)
                        //    // setSelectedCompanyGroup(Selected_CG)
                        //     //setCompanyGroup_Data(mData)
                        // })

                        setSelected_ManufactuererBy_Data(Selected_MB)
                        setPGName(data[0].PGName)
                        setDiscription(data[0].Description)
                    })
                }
            }
        }
    }, [show,is_add_new, PGID])

    const SelectManufacturedBy_List = () =>{
        SelectManufacturedByList(Comp_Id,ProjectID,(data) =>{
            setManufactuererBy_Data(data)
        })
    }
   
    const toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }

    const validation = () =>{
        if (!PGName){
            setError({PGName: "Must be Enterted"})
            document.getElementById("PGName").focus()
            return false
        } 
      else if(!Selected_ManufactuererBy_Data)
        {
            setError({ManufacturedBy: "Must be Required", PGName: "",CG:''})
            document.getElementById("ManufacturedBy").focus()
            return false
        } 
        // else if(!SelectedCompanyGroup){
        //     setError({ManufacturedBy: "", PGName: "",CG:'Must be Selected'})
        //     document.getElementById("CGID").focus()
        //     return false
        // }

        setError({ManufacturedBy: "", PGName: ""})
            return true
    }

    const save  = () =>{

        if(validation())
        {
           
            var obj_save = {
                PGID: PGID,
                PGName: PGName,
                ManufacturedByID: Selected_ManufactuererBy_Data.ManufacturedByID,
                CGID:0,
                Description: Discription,
                Comp_Id:Comp_Id,
                ProjectID:ProjectID
            }
            debugger
            setBtn_Disabled(true)
            SaveProductGroup(obj_save,  is_add_new,(message) =>{
                debugger
                setBtn_Disabled(false)
                clearFields()
            })
        }

    }


    const clearFields = () => {

        onHide()
        setManufactuererBy_Data([])
        setSelected_ManufactuererBy_Data(null)
        //setSelectedCompanyGroup(null)
        //setCompanyGroup_Data([])
        setPGName('')
        setDiscription('')
        setError({ManufacturedBy: "", PGName: "",CG:''})
    }

    return (
        <>
            <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" >
                <Modal.Header closeButton className="header_title " >
                    <Modal.Title > Product Group </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                        <Col lg={6} xl={6} md={6} >
                        <Autocomplete
                            name="ManufacturedBy"
                            id="ManufacturedBy"
                            options={ManufactuererBy_Data}
                            getOptionLabel={(option) => option.ManufacturedBy ? option.ManufacturedBy : ""}
                            value={Selected_ManufactuererBy_Data}
                            onChange={(e, value) => {

                                if (value) {
                                    setSelected_ManufactuererBy_Data(value)
                                    // SelectCompanyGroupAgainstManufacturer(Comp_Id,ProjectID,value.ManufacturedByID,(mData)=>{
                                    //     //setCompanyGroup_Data(mData)
                                    // })
                                }
                                else {
                                    setSelected_ManufactuererBy_Data(null)
                                    //setCompanyGroup_Data([])
                                    //setSelectedCompanyGroup(null)
                                }

                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="ManufacturedBy" id="ManufacturedBy" label="Manufactured By"
                                />}

                        />
                        <span style={{color: "red"}} >{Error.ManufacturedBy}</span>
                    </Col>
                        <Col lg={6} xl={6} md={6} >
                                <TextField
                                    name="PGName"
                                    id="PGName"
                                    label="Product Group"
                                    value={ PGName }
                                    onInput={ toInputUppercase }
                                    onChange = {e => { setPGName(e.target.value) }}
                                    fullWidth
                                />
                                <span style={{color: "red"}} >{Error.PGName}</span>
                            </Col>
                          
                         {/*  <Col lg={6} xl={6} md={6} >
                                <Autocomplete
                                    name="CGID"
                                    id="CGID"
                                    options={CompanyGroup_Data}
                                    getOptionLabel={(option) => option.CGName ? option.CGName : ""}
                                    value={SelectedCompanyGroup}
                                    onChange={(e, value) => {

                                        if (value) {
                                            setSelectedCompanyGroup(value)
                                        }
                                        else {
                                            setSelectedCompanyGroup(null)
                                        }

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} name="CGID" id="CGID" label="Company Group"
                                        />}

                                />
                                <span style={{color: "red"}} >{Error.CG}</span>
                            </Col>*/} 
                         
                        </Row>
                        <Row className="row_margin_top row_margin_left row_margin_right" >
                        <Col lg={12} xl={12} md={12} style={{marginTop:'4%'}}>
                                <textarea placeholder="Enter Description" onInput={ toInputUppercase } onChange={e => setDiscription(e.target.value)} name="Note" value={Discription} rows={4} cols={63} ></textarea>
                        </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                {btn_edit && (<Button variant="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={save}  disabled={btn_Disabled}>

                                {
                             btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                                }

                                </Button>)}
                                <Button variant="btn btn-primary rounded-pill" onClick={clearFields} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></Button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    show_screen: state.SCREEN,    
    ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,

})

export default connect(mapStateToProps)(PG_Modal)
