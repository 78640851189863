import React, { useState, useEffect } from 'react'
import  ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { Row, Col, Card,Spinner   } from "react-bootstrap"
import { Cancel, Save, Print,Delete, FormatListBulleted } from '@material-ui/icons'
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { connect } from 'react-redux'
import MUIDataTable from "mui-datatables";   
import Swal from "sweetalert2"
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { SelectDeliveryForDD,SelectRecoveryAgainstDelivery } from '../../Services/DeliveryAPI'
import { GetMaxRecoveryNo,SaveRecovery,SelectRecoveryForEdit } from '../../Services/ReceiptsAPI'
import { msg, GetCurrentDate } from '../../Services/Common'
import Loader from '../Loader'
const RecoveryEntry = (props) => {
    const [DeliveryList, setDeliveryList] = useState([])
    const [SelectedDelivery, setSelectedDelivery] = useState(null)
    const [RecoveryData, setRecoveryData] = useState([])
    const [RecoveryDate, setRecoveryDate] = useState(GetCurrentDate)
    const [RecoveryNo, setRecoveryNo] = useState('')
    const [RecoverdAmt, setRecoverdAmt] = useState(0)
    const [DeliveryName, setDeliveryName] = useState('')
    const [RecAmt, setRecAmt] = useState(0)
    const [IsLoader, setIsLoader] = useState(false)
    const Comp_Id = props.Comp_Id
    const ProjectID =props.Project && props.Project.Project_ID
    const isAddNew = props.AddNew
    const RecoveryID = props.ReceiptID
    useEffect(()=>{
      Select_DeliveryForDD()
      if(isAddNew){
        GetMaxNo()
      }else{
        SelectRecovery_ForEdit()
      }
       
    },[])
   const showLoader = () => {
      setIsLoader(true)
    }
    const hideLoader = () => {
      setIsLoader(false)
    }
    const SelectRecovery_ForEdit=()=>{
      showLoader()
      SelectRecoveryForEdit(Comp_Id,ProjectID,RecoveryID,(mData)=>{
       
      hideLoader()
        setRecoveryData(mData)
        setRecoveryNo(mData[0].RecoveryNo)
        setDeliveryName(mData[0].DeliveryName)
        setRecAmt(mData[0].RecivedAmt)
        setRecoverdAmt(mData[0].RecivedAmt)
      })
    }
    const theme = createTheme({
      overrides: {
        MuiTableCell: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
          }
        }
      }
    });
    const Select_DeliveryForDD = () =>{
        SelectDeliveryForDD(Comp_Id,ProjectID,(mDelivery)=>{
           
            setDeliveryList(mDelivery)
        })
    }
    const CalculateTotal = (mData)=>{
      var Total =0;
      mData.map(x=> {
        Total+= x.RecivedAmt
      })
      setRecoverdAmt(parseFloat(Total).toFixed(2))
    }
    const SelectRecovery_AgainstDelivery = (DeliveryID) =>{
 
      showLoader()
        SelectRecoveryAgainstDelivery(Comp_Id,ProjectID,DeliveryID,(mData)=>{
          hideLoader()
            setRecoveryData(mData)
            CalculateTotal(mData)
        })
    }
    const GetHandler = () =>{
        if(SelectedDelivery){
            SelectRecovery_AgainstDelivery(SelectedDelivery.DeliveryID)
        }else{
            msg(`Delivery Required`)
        }
    }
   const GetMaxNo = () =>{
    showLoader()
    GetMaxRecoveryNo(Comp_Id,ProjectID,(mNo)=>{
      hideLoader()
      setRecoveryNo(mNo)
    })
   }
    const columns = [
      {
        name: "SaleInvoiceID",
        label: "ID",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
            )
          },
        }
      },
      {
        name: "SaleInvoiceNo",
        label: "SaleInvoice No",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
            )
          },
        }
      },
      {
        name: "CustomerName",
        label: "Customer Name",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
            )
          },
        }
      },
      {
        name: "FullInvoiceAmt",
        label: "Invoice Amt",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
            )
          },
        }
      },
      {
        name: "InvoiceAmt",
        label: "Remaining Invoice Amt",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
            )
          },
        }
      },
      {
        name: "RecivedAmt",
        label: "Recived Amt",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
            )
          },
          customBodyRender: (dataIndex, rowIndex) => {
            var OrderBookingID = RecoveryData
            if (OrderBookingID[rowIndex.rowIndex] != null)
                   return(
                        <TextField
                            type='number'
                            value={RecoveryData[rowIndex.rowIndex].RecivedAmt}
                            id={'RecivedAmt'+rowIndex.rowIndex}
                            name={'RecivedAmt'}
                            onChange={(e) => {
                         
                             var newLevelData = [...RecoveryData]
                              
                              if(isAddNew){
                                if(!Number.isNaN(e.target.valueAsNumber))
                             {
                                if(e.target.valueAsNumber   > RecoveryData[rowIndex.rowIndex].InvoiceAmt){
                                  msg(`Recived Amount must be smaller or equal to Invoice Amt`)
                                }else{
                                  newLevelData[rowIndex.rowIndex].RecivedAmt = e.target.valueAsNumber
                                  newLevelData[rowIndex.rowIndex].Balance = parseFloat(RecoveryData[rowIndex.rowIndex].InvoiceAmt) - parseFloat(e.target.valueAsNumber)
                                  setRecoveryData(newLevelData)
                                  CalculateTotal(newLevelData)
                                }  
                              } 
                              else
                              {
                               newLevelData[rowIndex.rowIndex].RecivedAmt = 0.00
                               newLevelData[rowIndex.rowIndex].Balance =  newLevelData[rowIndex.rowIndex].FullInvoiceAmt
                               setRecoveryData(newLevelData)
                               CalculateTotal(newLevelData)
                              
                              }
                              }
                              
                              else{
                               const RecoverdAmt = newLevelData[rowIndex.rowIndex].RecoverdAmt
                               const RecivedAmt = RecAmt
                               const Reminder = RecoverdAmt - RecivedAmt
                               const RAmt = Reminder + e.target.valueAsNumber
                                if(RAmt > RecoveryData[rowIndex.rowIndex].FullInvoiceAmt){
                                  msg(`Recived Amount must be smaller or equal to Invoice Amt`)
                                }else{
                                  newLevelData[rowIndex.rowIndex].RecivedAmt = e.target.valueAsNumber
                                  newLevelData[rowIndex.rowIndex].Balance =  RecoveryData[rowIndex.rowIndex].FullInvoiceAmt - RAmt
                                  setRecoveryData(newLevelData)
                                  CalculateTotal(newLevelData)
                                }  
                              }
                             
                               
                               
                            }}
                            style={{
                                width:'30%'
                            }}
                        />
              );
          },
        }
      },
      {
        name: "Balance",
        label: "Balance",
        options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta) => {
            return (
              <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
            )
          },
        }
      }
        
      ];
      const SaveHandler = () =>{
        if(SelectedDelivery || !isAddNew){
          if(RecoveryData.length > 0){
            var mObj = {
              RecoveryID : props.ReceiptID,
              RecoveryDate: RecoveryDate,
              RecoveryNo: RecoveryNo,
              DeliveryName: SelectedDelivery ? SelectedDelivery.DeliveryName : DeliveryName
            }
          showLoader()
            SaveRecovery(mObj,RecoveryData,isAddNew,()=>{
              hideLoader()
              document.getElementById('btnNavigate').click()
            })
          }else{
            msg(`No Data Found`)
          }

        }else{
          msg(`Delivery Must be Selected`)
        }
       
    }
  return ReactDOM.createPortal (
    <>
    <Link id = 'btnNavigate' to='/RecoveryList'></Link>
    <div className="block-header">
    <div className="row">
        <div className="col-lg-5 col-md-5 col-sm-12">
            <h2>Recovery / Receipt
            </h2>
        </div>
    </div>
</div>
<Card> 
<Row className="row_margin_top row_margin_left row_margin_right m-4" >
<Col lg={3} xl={3} md={3} >
        <TextField
            name="RecoveryNo"
            id="RecoveryNo"
            label="Recovery No"
          value={RecoveryNo}
            inputProps={{
                readOnly:true
            }}
            fullWidth
        />
    </Col>
    <Col lg={3} xl={3} md={3} >
        <TextField
            name="RecoveryDate"
            id="RecoveryDate"
            label="Recovery Date"
            type='date'
          value={RecoveryDate}
           onChange = {e=> setRecoveryDate(e.target.value)}
            fullWidth
        />
    </Col>
    <Col lg={3} xl={3} md={3} >
    { isAddNew ?
    <Autocomplete
    id="Delivery"
    options={DeliveryList}
    value={SelectedDelivery}
    getOptionLabel={(option) => option.DeliveryName ? option.DeliveryName : ""}
    getOptionSelected={(option, value) => option.DeliveryID === value.DeliveryID}
    onChange={(e, option) => {
      if (option) {
        setSelectedDelivery(option)
    }
      else {
        setSelectedDelivery(null)
        setRecoveryData([])
        
      }
    }}

    renderInput={(params) => <TextField {...params} label="Select Delivery" />}
  />
  : 
  <TextField
  name="DeliveryName"
  id="DeliveryName"
  label="DeliveryName"
value={DeliveryName}
  inputProps={{
      readOnly:true
  }}
  disabled={true}
  fullWidth
/>

}
    </Col>
    <Col lg={3} xl={3} md={3} >
    <button type="button" className="btn btn-primary rounded-pill mt-3" onClick={GetHandler} style={{ border: "none" }}>
    GET INVOICES
    </button>
    </Col>
    <Col lg={3} xl={3} md={3} >
        <TextField
            name="RecoverdAmt"
            id="RecoverdAmt"
            label="Total Recoverd Amount"
          value={RecoverdAmt}
            inputProps={{
                readOnly:true
            }}
            fullWidth
        />
    </Col>
    </Row>

    
    <Row className=" row_margin_left row_margin_right" >
    <Col>
        <div className="table-responsive" >
        <MuiThemeProvider theme={theme}>
            <MUIDataTable
                name="RecoveryData"
                data={RecoveryData}
                columns={columns}
                options={{
                    selectableRows: 'none',
                    print: false,
                    search: true,
                    download: false,
                    viewColumns: false,
                    filter: false,
                }}
            />
            </MuiThemeProvider>
        </div>
    </Col>
</Row>
  <Row className="row_margin_left row_margin_right m-4 justify-content-end mr-0">
 
  <Col lg={3} xl={3} md={3}> <Link
    type="button"
    className="btn btn-primary rounded-pill float-right"
    to="#"
    style={{ border: "none", width:'100px'}}
    onClick= {SaveHandler}
  >
    {" "}
    SAVE
  </Link>
  <Link
  type="button"
  className="btn btn-primary rounded-pill float-right"
  to="/RecoveryList"
  style={{ border: "none", width:'100px'}}
>
  {" "}
  Close
</Link>
  {/*<Bounce color="#727981" size={32} speed={1} animating={true} />*/}
</Col>
  </Row>
  <Loader
  show={IsLoader}
  hide={hideLoader}
  />
</Card>
    </>, document.getElementById('PageContents') 
  )
}
const mapStateToProps = state=>({
  show_screen: state.SCREEN,
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  p_userid:state.Login_User.User_Data && state.Login_User.User_Data.User_id,
  Month:state.Receipts.Receipt_data && state.Receipts.Receipt_data.SelectedMonth_Option,
  Project:state.Receipts.Receipt_data && state.Receipts.Receipt_data.SelectedProject,
  AddNew: state.Receipts && state.Receipts.isAddNew,
  ReceiptID:state.Receipts && state.Receipts.ReceiptID,

  })
export default connect(mapStateToProps)(RecoveryEntry)
