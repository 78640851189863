import React from 'react'
import ReactDOM from 'react-dom'
import { Row, Col, Card, Spinner ,Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Save, Cancel } from '@material-ui/icons'
import { enableRipple } from '@syncfusion/ej2-base';
import {
    GridComponent, ColumnsDirective, ColumnDirective,
    Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar
} from '@syncfusion/ej2-react-grids';
import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
//  import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import Swal from 'sweetalert2'
import { getValue } from '@syncfusion/ej2-base';
import { connect } from 'react-redux'
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import { SelectArea, SaveArea } from '../../Services/AreasAPI'
import { SelectCityList } from '../../Services/CityAPI'
import { SelectGroups } from '../../Services/GroupsAPI'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { GetCurrentDate } from '../../Services/Common';
import Loader from '../Loader'

export class Areas extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            Areas: [],
            DeletedAreas: [],
            CityData: [],
            SelectedCity: null,
            SelectedGroup:null,
            GroupData:[],
            btn_Add: true,
            btn_Edit: true,
            btn_Delete: true,
            Others: null,
            btn_Disabled: false,
            IsLoader:false,
        }
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
        this.validationRules = { required: true };
        this.bg_Ref = React.createRef()
        this.grid = null
        this.Comp_Id = this.props.Comp_Id
        this.ProjectID = this.props.Project_ID
        this.BGValidation = {
            required: [this.customBGValidation, 'Areas must be Entered'],
        };
    }
    sortingOptions = {
        columns: [{ field: 'SrNo', direction: 'Ascending' }]
    };

    customBGValidation(args) {
        return getValue('value', args) != ''
    }


    componentDidMount() {
        document.title = " Distribution / Areas"
        SelectGroupScreenAccess(this.props.Group_Id, 13, 135, (data) => {

            this.setState({
                btn_Add: parseInt(data.AddNew) === 0 ? false : true,
                btn_Edit: parseInt(data.Edit) === 0 ? false : true,
                btn_Delete: parseInt(data.Del) === 0 ? false : true,
                Others: data.OtherAccess
            })
        })
        this.Select_CityList();
        this.Select_GroupList();
        this.Select_Areas();
    }
    Select_Areas = () => {
        this.showLoader()
        SelectArea(this.Comp_Id, this.ProjectID, (mAreas) => {
            this.hideLoader()
            mAreas.map((value, index) => {
                mAreas[index].SrNo = index + 1
            })
            if (mAreas.length > 0)
                this.setState({ Areas: mAreas })
        })
    }
    Select_CityList = () => {

        SelectCityList(this.Comp_Id, this.ProjectID, (mCity) => {

            mCity.map((value, index) => {
                mCity[index].SrNo = index + 1
            })
            if (mCity.length > 0)
                this.setState({ CityData: mCity })
        })
    }
    Select_GroupList = () => {

        SelectGroups(this.Comp_Id, this.ProjectID, (mGroup) => {

            mGroup.map((value, index) => {
                mGroup[index].SrNo = index + 1
            })
            if (mGroup.length > 0)
                this.setState({ GroupData: mGroup })
        })
    }
    AreaName = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="text"
            id="AreaName"
            className="textbox"
            name='AreaName'
            onInput={this.toInputUppercase}
            defaultValue={rowValue.AreaName}
        />)
    }
    AreaCode = (rowValue) => {
        return (<input
            style={{ width: "80%" }}
            type="text"
            id="AreaCode"
            className="textbox"
            name='AreaCode'
            onInput={this.toInputUppercase}
            defaultValue={rowValue.AreaCode}
        />)
    }
    City = (rowValue) => {

        return (
            <Autocomplete
                id="City"
                name="City"
                options={this.state.CityData}
                defaultValue={this.state.CityData.find(x => x.CityID === rowValue.CityID)}
                getOptionLabel={(option) => option.City ? option.City : ""}
                onChange={(e, value) => {

                    if (value) {
                        this.setState({ SelectedCity: value })
                    }
                    else {
                        this.setState({ SelectedCity: null })
                    }
                }}
                renderInput={(params) => <TextField {...params} />}
                fullWidth
            />
        )
    }
    GroupName = (rowValue) => {

        return (
            <Autocomplete
                id="Group"
                name="Group"
                options={this.state.GroupData}
                defaultValue={this.state.GroupData.find(x => x.GroupID === rowValue.GroupID)}
                getOptionLabel={(option) => option.GroupName ? option.GroupName : ""}
                onChange={(e, value) => {

                    if (value) {
                        this.setState({ SelectedGroup: value })
                    }
                    else {
                        this.setState({ SelectedGroup: null })
                    }
                }}
                renderInput={(params) => <TextField {...params} />}
                fullWidth
            />
        )
    }

    SaveHandler = () => {
        if (this.state.Areas.length > 0 || this.state.DeletedAreas.length > 0) {
            for (let i = 0; i < this.state.Areas.length; i++) {
                if (this.state.Areas[i].AreaName === undefined || this.state.Areas[i].AreaName === '') {
                    Swal.fire({
                        icon: 'error',
                        text: `Area Name at Sr. No. ${this.state.Areas[i].SrNo} is required`
                    })
                    return
                }
                if (this.state.Areas[i].City === undefined || this.state.Areas[i].City === '') {
                    Swal.fire({
                        icon: 'error',
                        text: `City at Sr. No. ${this.state.Areas[i].SrNo} is required`
                    })
                    return
                }
               

            }
            this.setState({ btn_Disabled: true })
            SaveArea(this.state.Areas, this.state.DeletedAreas, this.Comp_Id, this.ProjectID, () => {
             
                this.setState({ btn_Disabled: false })
                this.Select_Areas();
            })
        }
        else {
            Swal.fire({
                icon: 'info',
                text: `Areas Must be Entered...`,
            })
            return
        }
    }


    beforeRowInsert = (rowValue) => {

        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.SrNo) {
                    if (rowValue.requestType != "cancel") {
                        rowValue.data.Comp_Id = this.Comp_Id
                        rowValue.data.ProjectID = this.ProjectID
                        if (this.state.SelectedCity) {
                            rowValue.data.CityID = this.state.SelectedCity.CityID;
                            rowValue.data.City = this.state.SelectedCity.City;
                        }
                        if(this.state.SelectedGroup){
                            rowValue.data.GroupID = this.state.SelectedGroup.GroupID
                            rowValue.data.GroupName = this.state.SelectedGroup.GroupName
                        }else{
                            rowValue.data.GroupID = 0
                            rowValue.data.GroupName = ''
                        }
                        if (rowValue.data.AreaID === undefined) {
                            rowValue.data.action = 'add'
                        }
                        else {
                            rowValue.data.action = rowValue.action
                        }
                    }
                }
                else {
                    rowValue.data.SrNo = this.state.Areas.length + 1
                    this.setState({ SelectedCity: null, SelectedGroup:null })

                }
            }
        }

    }
    afterRowInsert = (rowValue) => {

        if (rowValue.requestType === 'delete') {
            if (rowValue.data[0].AreaID) {
                this.setState({ DeletedAreas: this.state.DeletedAreas.concat(rowValue.data) })

            }
        } else if (rowValue.requestType == "beginEdit") {

            document.getElementById('AreaName').focus()
            let _CityData = this.state.CityData.find(x => x.CityID === rowValue.rowData.CityID)
            let _GRoupData = this.state.GroupData.find(y=> y.GroupID === rowValue.rowData.GroupID)
            this.setState({ SelectedCity: _CityData,SelectedGroup:_GRoupData  })
        }
    }

    toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    }
     showLoader = () => {
        this.setState({IsLoader:true})
       
      }
       hideLoader = () => {
        this.setState({IsLoader:false})
      }
    render() {
        if (this.grid)
            this.grid.autoFitColumns(['SrNo', 'AreaName']);
        enableRipple(true);
        return ReactDOM.createPortal(
            <>
                <div className="block-header">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <h2>Areas
                            </h2>
                        </div>
                    </div>
                </div>
                <Card>
                    <Row className="row_margin_top row_margin_left row_margin_right pt-4" style={{ width: '80%', marginLeft: '10%' }} >
                        <div style={{ display: "flex", width: "100%", height: "100%", overflow: "hidden" }}>
                            <div style={{ flex: "1", width: "100%", height: "100%" }}>
                                <GridComponent dataSource={this.state.Areas} actionBegin={this.beforeRowInsert} ref={g => this.grid = g} actionComplete={this.afterRowInsert} allowPaging={true} allowFiltering={true} allowSorting={true} editSettings={{ allowEditing: this.state.btn_Edit, allowDeleting: this.state.btn_Delete, allowAdding: this.state.btn_Add, newRowPosition: 'Bottom' }} sortSettings={this.sortingOptions} filterSettings={{ type: 'Menu' }} toolbar={this.toolbarOptions}>
                                    <ColumnsDirective>
                                        <ColumnDirective width='70' field='SrNo' headerText='SrNo #' Format="{0:n}" allowEditing={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                        <ColumnDirective width='250' field='AreaName' headerText='Areas Name' editTemplate={this.AreaName} textAlign='Left' />
                                        <ColumnDirective width='250' field='AreaCode' headerText='Areas Code' editTemplate={this.AreaCode} textAlign='Left' />
                                        <ColumnDirective width='200' field='City' headerText='City Name' editTemplate={this.City} textAlign='Left' />
                                        <ColumnDirective width='200' field='GroupName' headerText='Group Name' editTemplate={this.GroupName} textAlign='Left' />


                                        <ColumnDirective field='AreaID' headerText='AreaID' visible={false}></ColumnDirective>
                                        <ColumnDirective field='CityID' headerText='CityID' hideAtMedia={true} ></ColumnDirective>
                                        <ColumnDirective field='GroupID'  hideAtMedia={true} ></ColumnDirective>
                                        <ColumnDirective field='Comp_Id' headerText='Comp_Id' hideAtMedia={true} ></ColumnDirective>
                                        <ColumnDirective field='ProjectID' headerText='ProjectID' hideAtMedia={true} ></ColumnDirective>
                                    </ColumnsDirective>
                                    <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
                                </GridComponent>
                            </div>
                        </div>
                    </Row>
                    <Row className=" row_margin_top row_margin_left row_margin_right row_margin_bottom" >
                        <Col style={{ textAlign: "right", marginTop: "1%" }} >
                            <button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={this.SaveHandler} disabled={this.state.btn_Disabled}>
                                {
                                    this.state.btn_Disabled ?
                                        (<><Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        /> Saving... </>) : <><Save /> Save</>
                                } </button>
                                <Button variant="btn btn-primary rounded-pill" onClick={e =>{
                               
                                    const ws = XLSX.utils.json_to_sheet(this.state.Areas);
                                    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                                    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                                    const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
                                    FileSaver.saveAs(data, `Areas - ${GetCurrentDate()}`  +  ".xlsx");
                                  }} style={{border: "none"}} > <CloudDownloadIcon />  Export to Excel</Button> 
                            <Link id="btnCancel" className="btn btn-primary rounded-pill" style={{ border: "none" }} to={'#'} > <Cancel /> <u>Close</u></Link>
                        </Col>
                    </Row>
                    <Loader
                        show={this.state.IsLoader}
                        hide={this.hideLoader}
                    />
                </Card>

            </>, document.getElementById('PageContents')
        )
    }

}

const mapStateToProps = state => ({
    Comp_Id: state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Group_Id: state.Login_User.User_Data && state.Login_User.User_Data.Group_Id,
    Project_ID: state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID
})

export default connect(mapStateToProps)(Areas)