import React, { Component } from 'react'
import { Modal, Button, Row, Col, Form, Tabs, Tab,Spinner  } from "react-bootstrap";
import { TextField, FormControlLabel, Checkbox, Card } from '@material-ui/core' 
import { Save, Cancel } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { enableRipple } from '@syncfusion/ej2-base';
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Filter, Inject, Edit, Sort, ForeignKey, Toolbar } from '@syncfusion/ej2-react-grids';
import "@syncfusion/ej2-react-grids/styles/material.css";
import { getValue } from '@syncfusion/ej2-base';
import { Link } from "react-router-dom";
import ReactDOM from 'react-dom'
import { connect } from "react-redux"
import Swal from 'sweetalert2'

import { SelectSecurityGroupsList, SelectFinYearList, SelectBranchesList, SaveUser, SelectUser, SelectUserBranches, SelectUserFinYear,SelectProjectUser } from "../../Services/SecurityAPI"
import { SelectProjects} from '../../Services/Projects'
// import { SelectGodownsList } from '../../Services/GodownsAPI'

export class AddUser extends Component {
        constructor(props) {
            super(props)

            this.state = {
                UserName:'',
                LoginID:'',
                Password:'',
                SelectedUserType:null,
                UserGroup:[],
                SelectedUserGroup:null,
                Active:false,
                FinYearModal:false,
                PhoneNo:'',
                Email:'',
                Address:'',
                FinData:[],
                FinYearGridData:[],
                DeletedFinYearData:[],
                BranchData:[],
                BranchGridData:[],
                DeletedBranchesData:[],
                UserName_Err : "",
                LoginID_Err :"",
                Password_Err : "",
                UserType_Err : "",
                Group_Err : "",
                SelectedBranch: null,
                SelectedFinYear: null,
                SelectedTab: 'Fin Years',
                isPasswordChanged: false,
                BranchFinData: [],
                SelectedFinBranch: null,

                // USER PROJECTS TAB
                UserProjectsData: [],
                DeletedUserProjectsData: [],
                ProjectList: [],
                SelectedUserProject: null,
                BranchData_UserProject: [],
                SelectedBranch_UserProject: null,
                _AccessUserProjects: false,

                //USER GODOWNS TAB 
                UserGodownsData: [],
                DeletedUserGodownsData: [],
                GodownList: [],
                SelectedUserGodown: null,
                BranchData_UserGodown: [],
                SelectedBranch_UserGodown: null,
                _AccessUserGodowns: false,
                btn_Disabled:false,

            }
            this.Comp_Id = this.props.CompID && this.props.CompID.Branch_Id
            this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
            this.toolbarOptions1 = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
            this.validationRules = { required: true, number:true };
            this.grid = this.grid
            this.grid1 = this.grid1
            this.Amount_Object_Ref = React.createRef()
            this.ItemDescription_Ref = React.createRef()
            this.customBranchesValidation = this.customBranchesValidation.bind(this)
            this.customFinYearValidation = this.customFinYearValidation.bind(this)
            this.customBranches_FinValidation = this.customBranches_FinValidation.bind(this)
            this.maarray = []
            this.BranchesValidation = {
                required: [this.customBranchesValidation, 'Branch must be selected'],
              };
            this.Branches_FinValidation = {
                required: [this.customBranches_FinValidation, 'Branch must be selected'],
            };
              this.FinYearValidation = {
                required: [this.customFinYearValidation, 'Fin Year must be selected'],
              };
        }
        customBranchesValidation(args) {
            args.value = this.state.SelectedBranch ? args.value = this.state.SelectedBranch.Branch_Id : ''
            var tst = getValue('value', args)
            return getValue('value', args) != ''
        };
    customBranches_FinValidation(args) {
        args.value = this.state.SelectedFinBranch ? args.value = this.state.SelectedFinBranch.Branch_Id : ''
        return getValue('value', args) != ''
    };
        customFinYearValidation(args) {
            args.value = this.state.SelectedFinYear ? args.value = this.state.SelectedFinYear.Year_Id : ''
            
            return getValue('value', args) != ''
        };
    
    componentDidMount(){
        document.title = "DIstribution / Users"
        this.Select_Security_GroupsList()
        this.Select_FinYear_List()
        this.Select_Branches_List()
        if(!this.props.isAddNew)
        {
            SelectUser(this.props.User_Id,(mUser)=>{
                if(mUser[0].Active === 1)
                mUser[0].Active = true
                else
                mUser[0].Active = false
                this.setState({
                    UserName: mUser[0].User_name,
                    LoginID: mUser[0].User_Login,
                    Password: mUser[0].User_password,
                    Active: mUser[0].Active,
                    BranchFinData: this.maarray,
                    BranchData_UserProject: this.maarray,
                    BranchData_UserGodown: this.maarray,
                    Address: mUser[0].Address,
                    Email: mUser[0].Email,
                    PhoneNo: mUser[0].PhoneNo
                })
                this.state.UserGroup.find(value=>{
                    if(value.Group_Id === mUser[0].Group_Id)
                    {
                        this.setState({SelectedUserGroup:value})
                    }
                })
                this.UserType.find(value=>{
                    if(value.value === mUser[0].User_Type)
                    {
                        this.setState({SelectedUserType:value})
                    }
                })

            })
            SelectUserBranches(this.props.User_Id,(mBranches)=>{

                var mArray = [];
                for(let i = 0; i<mBranches.length; i++)
                {
                    mArray.push({
                        sNo: i+1,
                        Name:mBranches[i].Name,
                        Abbr:mBranches[i].Abbr,
                        Branch_Id:mBranches[i].Branch_Id
                    })
                    this.state.BranchData.map((value,Index)=>{
                        if(value.Branch_Id === mBranches[i].Branch_Id)
                        {
                            this.state.BranchData.splice(Index,1)
                        }
                    })
                }
                this.setState({BranchGridData:mArray})
            })
            SelectUserFinYear(this.props.User_Id,(mFinYear)=>{
                var mArray = []
                for(let i = 0; i<mFinYear.length; i++)
                {
                    mArray.push({
                        sNo: i+1,
                        FinYear:mFinYear[i].FinYear,
                        StartYear:mFinYear[i].StartYear,
                        EndYear:mFinYear[i].EndYear,
                        Year_Id:mFinYear[i].Year_Id,
                        Branch_Id: mFinYear[i].Branch_Id,
                        BranchName: mFinYear[i].Name
                    })
                    // this.state.FinData.map((value,Index)=>{
                    //     if(value.Year_Id === mFinYear[i].Year_Id)
                    //     {
                    //         this.state.FinData.splice(Index,1)
                    //     }
                    // })
                }

                this.setState({ FinYearGridData: mArray })
            })
            SelectProjectUser(this.props.User_Id, (mUserProjects) => {
                if (mUserProjects.length > 0) {
                    mUserProjects.map((value, index) => {
                        value.sNo = index + 1
                    })
                    this.setState({ UserProjectsData: mUserProjects })
                }
            })
            // SelectUserGodowns(this.props.User_Id, (mUserGodowns) => {
            //     if (mUserGodowns.length > 0) {
            //         mUserGodowns.map((value, index) => {
            //             value.sNo = index + 1
            //         })
            //         this.setState({ UserGodownsData: mUserGodowns })
            //     }
            // })
        }
    }
     UserType = [
        {value:"Admin",title:"Admin"},
        {value:"Supervisor",title:"Supervisor"},
        {value:"User",title:"User"},
        {value:'SALESMAN',title:'SALESMAN'}
    ]
    columns = [
        {
            name: "StartYear",
            label: "Start Date",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "EndYear",
            label: "End Date",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "FinYear",
            label: "Financial Year",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
            }
        },
        {
            name: "Access",
            label: "Access",
            options: {
                filter: true,
                sort: true,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
                    )
                },
                customBodyRenderLite: (dataIndex, rowIndex) => {

                    const _FinData = this.state.FinData;
                    if (_FinData[dataIndex] != null) {
                        return (
                            <Checkbox
                                checked={_FinData[dataIndex].Access}
                                onChange={e => {

                                    let newFinData = [...this.state.FinData]
                                    newFinData[dataIndex].Access = e.target.checked

                                    //setFinData(newFinData)
                                    this.setState({ FinData: newFinData })

                                }}
                                name="Access"
                                color="primary"
                            />
                        )
                    }
                }
            }
        },
    ];

    Select_Security_GroupsList =()=>{
        SelectSecurityGroupsList( mGroups => {
            this.setState({UserGroup:mGroups})
        })
    }
    Select_FinYear_List = (Comp_Id) =>{
        SelectFinYearList(Comp_Id, (mFinYears) => {
            this.setState({FinData:mFinYears})
        })
    }
    Select_Branches_List = () =>{
        SelectBranchesList( mBranches => {
            this.maarray = [...mBranches] // for using it on FinYear tab
        this.setState({BranchData:mBranches,BranchData_UserProject:mBranches,BranchFinData:mBranches})
    })
    }
    BranchName = (rowValue) => {
        return(
            <Autocomplete
                name="BranchName"
                id="BranchName"
                defaultValue={this.maarray.find(v => v.Name === rowValue.BranchName)}
                options={this.maarray}
                getOptionLabel={(option) => option.Name ? option.Name : ""}
                onChange={(e, value) => {
                    if (value) {
                        SelectFinYearList(value.Branch_Id, (mFinYears) => {
                            this.setState({ FinData: mFinYears, SelectedFinBranch: value }, () => {
                                this.FinYear_dropDown.props.options.splice(0, this.FinYear_dropDown.props.options.length)

                                for (var i = 0; i < mFinYears.length; i++) {
                                    this.FinYear_dropDown.props.options.push(mFinYears[i])
                                }
                            })
                        })
                    }
                    else {
                        this.setState({ SelectedFinBranch: null, SelectedFinYear: null, FinData: [] })
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="BranchName" />}
            />
        )
    }
    FinYear = (rowValue) => {
        this.FinYear_dropDown = <Autocomplete
            name="FinYear"
            id="FinYear"
            defaultValue={this.state.FinData.find(v => v.FinYear === rowValue.FinYear)}
            options={this.state.FinData}
            getOptionLabel={(option) => option.FinYear ? option.FinYear : ""}
            onChange={(e, value) => {
                if (value) {
                    document.getElementById('StartYear').value = value.StartYear
                    document.getElementById('EndYear').value = value.EndYear
                    this.setState({ SelectedFinYear: value })
                }
                else {
                    document.getElementById('StartYear').value = ''
                    document.getElementById('EndYear').value = ''
                    this.setState({ SelectedFinYear: null })
                }
            }}
            renderInput={(params) =>
                <TextField {...params} name="FinYear" />}
        />
        return (this.FinYear_dropDown)
    }
    StartYear = (rowvalue) =>{
        return (
            <input 
            className="textbox"
            defaultValue={rowvalue.StartYear}
            name="StartYear"
                id="StartYear"
            readOnly={true}
            />
        )
    }
    EndYear = (rowvalue) =>{
        return (
            <input 
            className="textbox"
            defaultValue={rowvalue.EndYear}
            name="EndYear"
                id="EndYear"
            readOnly={true}
            />
        )
    }

    beforeRowInsert = (rowValue) =>{

        if(rowValue.rowData)
        {
            if(rowValue.data)
            {
                if(rowValue.data.FinYear)
                {
                    if(rowValue.requestType != "cancel")
                    {
                        for(let i= this.state.FinData.length - 1; i>=0; i--)
                        {
                            if(this.state.FinData[i].FinYear === rowValue.data.FinYear)
                            {
                                rowValue.data.Year_Id = this.state.SelectedFinYear.Year_Id
                                rowValue.data.Branch_Id = this.state.SelectedFinBranch.Branch_Id
                                // this.state.FinData.slice(i,this.state.FinData[i])
                            }
                        }
                        rowValue.data.action = rowValue.action
                    }
                }
                else
                {
                    rowValue.data.sNo = this.state.FinYearGridData.length + 1  
                    this.setState({ SelectedFinYear: null, SelectedFinBranch: null })
                }
            }
        }

    }

    afterRowInsert = (rowValue) =>{  
        if(rowValue.requestType == "beginEdit")
        {
                
        }
        else if(rowValue.requestType == "delete")
        {
            if(rowValue.data[0].Year_Id && rowValue.data[0].action != 'add')
            {
                this.setState({DeletedFinYearData: this.state.DeletedFinYearData.concat(rowValue.data)})
            }
            else
            {
                this.state.FinData.push(rowValue.data[0])
            }
        }
    }

    //Branches Grid Columns
    Name = (rowValue)=>{
        return(
            <Autocomplete
                name="Name"
                id="Name"
                defaultValue={this.state.BranchData.find(v => v.Name === rowValue.Name)}
                options={this.state.BranchData}
                getOptionLabel={(option) => option.Name ? option.Name : "" } 
                onChange = {(e,value) =>{
                    if(value)
                    {
                        this.setState({SelectedBranch: value})
                        document.getElementById('Abbr').value = value.Abbr
                    }
                    else
                    {
                        this.setState({SelectedBranch: null})
                        document.getElementById('Abbr').value  = ''
                    }
                }}
                renderInput={(params) => 
                <TextField {...params} name="Name" />}
            />
        )
    }
    Abbr = (rowvalue) =>{
        return (
            <input 
            className="textbox"
            defaultValue={rowvalue.Abbr}
            name="Abbr"
            id="Abbr"
            readOnly={true}
            />
        )
    }
    beforeRowInsert1 = (rowValue) =>{
       
        if(rowValue.rowData)
        {
            if(rowValue.data)
            {
                if(rowValue.data.Name)
                {
                    if(rowValue.requestType != "cancel")
                    {

                        for(let i= this.state.BranchData.length - 1; i>=0; i--)
                        {
                            if(this.state.BranchData[i].Name === rowValue.data.Name)
                            {
                                rowValue.data.Branch_Id = this.state.BranchData[i].Branch_Id
                                this.state.BranchData.splice(i,1)
                            }
                        }
                    
                        rowValue.data.action = rowValue.action
                    }
                }
                else
                {
                    rowValue.data.sNo = this.state.BranchGridData.length + 1  
                    this.setState({SelectedBranch: null})
                }
            }
        }

    }
    afterRowInsert1 = (rowValue) =>{  
        if(rowValue.requestType == "beginEdit")
        {
                
        }
        else if(rowValue.requestType == "delete")
        {
            if(rowValue.data[0].Branch_Id && rowValue.data[0].action != 'add')
            {
                this.setState({DeletedBranchesData: this.state.DeletedBranchesData.concat(rowValue.data)})
            }
            else
            {
                this.state.BranchData.push(rowValue.data[0])
            }
        }
    }
    // End Branches Grid Columns


    //  // USER PROJECTS TAB
    BranchNameUserProjects = (rowValue) => {
        return (
            <Autocomplete
                name="BranchNameUserProjects"
                id="BranchNameUserProjects"
                defaultValue={this.maarray.find(v => v.Name === rowValue.BranchName)}
                options={this.maarray}
                getOptionLabel={(option) => option.Name ? option.Name : ""}
                onChange={(e, value) => {
                    if (value) {
                        SelectProjects(value.Branch_Id, (mProjectList) => {
                            this.setState({ ProjectList: mProjectList, SelectedBranch_UserProject: value }, () => {
                                this.ProjectName_dropDown.props.options.splice(0, this.ProjectName_dropDown.props.options.length)

                                for (var i = 0; i < mProjectList.length; i++) {
                                    this.ProjectName_dropDown.props.options.push(mProjectList[i])
                                }
                            })
                        })
                    }
                    else {
                        this.ProjectName_dropDown.props.options.splice(0, this.ProjectName_dropDown.props.options.length)
                        this.setState({ SelectedBranch_UserProject: null, SelectedUserProject: null, ProjectList: [] })
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="BranchNameUserProjects" />}
            />
        )
    }
    ProjectName = (rowValue) => {
        this.ProjectName_dropDown = <Autocomplete
            name="ProjectName"
            id="ProjectName"
            defaultValue={this.state.ProjectList.find(v => v.ProjectName === rowValue.ProjectName)}
            options={this.state.ProjectList}
            getOptionLabel={(option) => option.ProjectName ? option.ProjectName : ""}
            onChange={(e, value) => {
                if (value) {
                    this.setState({ SelectedUserProject: value })
                }
                else {
                    this.setState({ SelectedUserProject: null })
                }
            }}
            renderInput={(params) =>
                <TextField {...params} name="ProjectName" />}
        />
        return (this.ProjectName_dropDown)
    }

    AccessUserProjects = (rowValue) => {
        var disabledProp;
        if (typeof rowValue.index === 'string') {
            disabledProp = true;
        }
        else {
            disabledProp = false
        }
        return (<input
            type="checkbox"
            id="AccessUserProjects"
            defaultChecked={rowValue.Access}
            name="AccessUserProjects"
            disabled={disabledProp}
            onChange={(e, value) => {
                {
                    // var chkd = e.target.checked ===true ? 1 : 0
                    this.setState({ _AccessUserProjects: e.target.checked })
                }
            }}
        />)
    }

    beforeRowInsertUserProjects = (rowValue) => {
        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.ProjectName) {
                    if (rowValue.requestType != "cancel") {
                        rowValue.data.BranchName = this.state.SelectedBranch_UserProject.Name
                        rowValue.data.Branch_Id = this.state.SelectedBranch_UserProject.Branch_Id
                        rowValue.data.Project_ID = this.state.SelectedUserProject.Project_ID
                        rowValue.data.Access = this.state._AccessUserProjects ? 1 : 0
                        rowValue.data.action = rowValue.action
                    }
                }
                else {
                    rowValue.data.sNo = this.state.UserProjectsData.length + 1
                    this.setState({ SelectedUserProject: null, SelectedBranch_UserProject: null, _AccessUserProjects: false })
                }
            }
        }

    }
    afterRowInsertUserProjects = (rowValue) => {
        if (rowValue.requestType == "delete") {
            if (rowValue.data[0].Branch_Id && rowValue.data[0].Project_ID && rowValue.data[0].User_id && rowValue.data[0].action != 'add') {
                this.setState({ DeletedUserProjectsData: this.state.DeletedUserProjectsData.concat(rowValue.data) })
            }
        }
    }

    //  // END  USER PROJECTS TAB


    //  // USER GODOWNS TAB
    BranchNameUserGodowns = (rowValue) => {
        return (
            <Autocomplete
                name="BranchNameUserGodowns"
                id="BranchNameUserGodowns"
                defaultValue={this.maarray.find(v => v.Name === rowValue.BranchName)}
                options={this.state.BranchData_UserGodown}
                getOptionLabel={(option) => option.Name ? option.Name : ""}
                onChange={(e, value) => {
                    if (value) {
                        // SelectGodownsList(value.Branch_Id, (mGodownsList) => {
                        //     this.setState({ GodownList: mGodownsList, SelectedBranch_UserGodown: value }, () => {
                        //         this.GodownName_dropDown.props.options.splice(0, this.GodownName_dropDown.props.options.length)

                        //         for (var i = 0; i < mGodownsList.length; i++) {
                        //             this.GodownName_dropDown.props.options.push(mGodownsList[i])
                        //         }
                        //     })
                        // })
                    }
                    else {
                        this.GodownName_dropDown.props.options.splice(0, this.GodownName_dropDown.props.options.length)
                        this.setState({ SelectedBranch_UserGodown: null, SelectedUserGodown: null, GodownList: [] })
                    }
                }}
                renderInput={(params) =>
                    <TextField {...params} name="BranchNameUserGodowns" />}
            />
        )
    }
    GodownName = (rowValue) => {
        this.GodownName_dropDown = <Autocomplete
            name="GodownName"
            id="GodownName"
            defaultValue={this.state.GodownList.find(v => v.GodDesc === rowValue.GodownName)}
            options={this.state.GodownList}
            getOptionLabel={(option) => option.GodDesc ? option.GodDesc : ""}
            onChange={(e, value) => {
                if (value) {
                    this.setState({ SelectedUserGodown: value })
                }
                else {
                    this.setState({ SelectedUserGodown: null })
                }
            }}
            renderInput={(params) =>
                <TextField {...params} name="GodownName" />}
        />
        return (this.GodownName_dropDown)
    }
    AccessUserGodowns = (rowValue) => {
        var disabledProp;
        if (typeof rowValue.index === 'string') {
            disabledProp = true;
        }
        else {
            disabledProp = false
        }
        return (<input
            type="checkbox"
            id="AccessUserGodowns"
            defaultChecked={rowValue.Access}
            name="AccessUserGodowns"
            disabled={disabledProp}
            onChange={(e, value) => {
                {
                    // var chkd = e.target.checked ===true ? 1 : 0
                    this.setState({ _AccessUserGodowns: e.target.checked })
                }
            }}
        />)
    }
    beforeRowInsertUserGodowns = (rowValue) => {
        if (rowValue.rowData) {
            if (rowValue.data) {
                if (rowValue.data.GodownName) {
                    if (rowValue.requestType != "cancel") {
                        rowValue.data.BranchName = this.state.SelectedBranch_UserGodown.Name
                        rowValue.data.Branch_Id = this.state.SelectedBranch_UserGodown.Branch_Id
                        rowValue.data.God_ID = this.state.SelectedUserGodown.God_ID
                        rowValue.data.Access = this.state._AccessUserGodowns ? 1 : 0
                        rowValue.data.action = rowValue.action
                    }
                }
                else {
                    rowValue.data.sNo = this.state.UserGodownsData.length + 1
                    this.setState({ SelectedUserGodown: null, SelectedBranch_UserGodown: null, _AccessUserGodowns: false })
                }
            }
        }

    }
    afterRowInsertUserGodowns = (rowValue) => {
        if (rowValue.requestType == "delete") {
            if (rowValue.data[0].Branch_Id && rowValue.data[0].God_ID && rowValue.data[0].User_id && rowValue.data[0].action != 'add') {
                this.setState({ DeletedUserGodownsData: this.state.DeletedUserGodownsData.concat(rowValue.data) })
            }
        }
    }

    //  // END  USER GODOWNS TAB

    validate (){
        let UserNameErr = ""
        let LoginIDErr = ""
        let PasswordErr = ""
        let UserTypeErr = ""
        let GroupErr = ""

        if(!(this.state.UserName).trim())
        {
            UserNameErr = "User Name is required"
        }
        if(!(this.state.LoginID).trim())
        {
            LoginIDErr = "Login ID is required"
        }
        if(!(this.state.Password).trim())
        {
            PasswordErr = "Password is required"
        }
        if(!this.state.SelectedUserType)
        {
            UserTypeErr = "User Type is required"
        }
        if(!this.state.SelectedUserGroup)
        {
            GroupErr = "User Group is required"
        }

        if(UserNameErr)
        {
            this.setState({ UserName_Err : UserNameErr})
            return false
        }
        if(LoginIDErr)
        {
            this.setState({ UserName_Err : '', LoginID_Err :LoginIDErr})
            return false
        }
        if(PasswordErr)
        {
            this.setState({ UserName_Err : '', LoginID_Err :'', Password_Err:PasswordErr})
            return false
        }
        if(UserTypeErr)
        {
            this.setState({ UserName_Err : '', LoginID_Err :'', Password_Err:'', UserType_Err:UserTypeErr})
            return false
        }
        if(GroupErr)
        {
            this.setState({ UserName_Err : '', LoginID_Err :'', Password_Err:'', UserType_Err:'', Group_Err:GroupErr})
            return false
        }

        this.setState({ UserName_Err : '', LoginID_Err :'', Password_Err:'', UserType_Err:'', Group_Err:''})
        return true

    }
    Save_User = () =>{
        if(this.validate())
        {
            if(this.state.FinYearGridData.length > 0)
            {
                if(this.state.BranchGridData.length > 0)
                {
                    let isDuplicate = false, isUserProject_Duplicate = false, isUserGodowns_Duplicate = false,
                        UserProject_Name, UserProject_BranchName, UserGodown_Name, UserGodown_BranchName, YearName, Comp_Name;
                    // Outer for loop
                    for (let i = 0; i < this.state.FinYearGridData.length; i++) {
                        // Inner for loop
                        for (let j = 0; j < this.state.FinYearGridData.length; j++) {
                            // Skip self comparison
                            if (i !== j) {
                                // Check for duplicate
                                if (this.state.FinYearGridData[i].Year_Id === this.state.FinYearGridData[j].Year_Id) {
                                    isDuplicate = true;
                                    YearName = this.state.FinYearGridData[i].FinYear
                                    Comp_Name = this.state.FinYearGridData[i].BranchName
                                    // Terminate inner loop
                                    break;
                                }
                            }
                            // Terminate outer loop
                            if (isDuplicate) {
                                break;
                            }
                        }
                    }
                    // For USER PROJECTS
                    for (let i = 0; i < this.state.UserProjectsData.length; i++) {
                        for (let j = 0; j < this.state.UserProjectsData.length; j++) {
                            if (i !== j) {
                                if (this.state.UserProjectsData[i].Branch_Id === this.state.UserProjectsData[j].Branch_Id && this.state.UserProjectsData[i].Project_ID === this.state.UserProjectsData[j].Project_ID) {
                                    isUserProject_Duplicate = true;
                                    UserProject_Name = this.state.UserProjectsData[i].ProjectName
                                    UserProject_BranchName = this.state.UserProjectsData[i].BranchName
                                    break;
                                }
                            }
                            if (isUserProject_Duplicate) {
                                break;
                            }
                        }
                    }
                    // For USER GODOWNS
                    // for (let i = 0; i < this.state.UserGodownsData.length; i++) {
                    //     for (let j = 0; j < this.state.UserGodownsData.length; j++) {
                    //         if (i !== j) {
                    //             if (this.state.UserGodownsData[i].Branch_Id === this.state.UserGodownsData[j].Branch_Id && this.state.UserGodownsData[i].God_ID === this.state.UserGodownsData[j].God_ID) {
                    //                 isUserGodowns_Duplicate = true;
                    //                 UserGodown_Name = this.state.UserGodownsData[i].GodownName
                    //                 UserGodown_BranchName = this.state.UserGodownsData[i].BranchName
                    //                 break;
                    //             }
                    //         }
                    //         if (isUserGodowns_Duplicate) {
                    //             break;
                    //         }
                    //     }
                    // }
                    if (isDuplicate) {
                        Swal.fire({
                            icon: 'info',
                            text: `${YearName} is duplicated in ${Comp_Name}...`,
                        })
                        return

                    }
                    else if (isUserProject_Duplicate) {
                        Swal.fire({
                            icon: 'info',
                            text: `Project : ${UserProject_Name} &  Branch: ${UserProject_BranchName} is duplicated on User Projects Tab...`,
                        })
                        return
                    }
                    // else if (isUserGodowns_Duplicate) {
                    //     Swal.fire({
                    //         icon: 'info',
                    //         text: `Godown : ${UserGodown_Name} & Branch: ${UserGodown_BranchName} is duplicated on User Godowns Tab...`,
                    //     })
                    //     return
                    // }
                    else {
                        var UserData = {
                            User_Id: this.props.User_Id ? this.props.User_Id : 0,
                            User_name: this.state.UserName,
                            User_Login: this.state.LoginID,
                            User_password: this.state.Password,
                            Active: this.state.Active ? 1 : 0,
                            User_Type: this.state.SelectedUserType.value,
                            Group_Id: this.state.SelectedUserGroup.Group_Id,
                            IsPasswordChanged: this.state.isPasswordChanged,
                            Address:this.state.Address,
                            Email:this.state.Email,
                            PhoneNo:this.state.PhoneNo
                        }
                        this.setState({btn_Disabled:true})
                        SaveUser(UserData, this.state.BranchGridData, this.state.DeletedBranchesData,
                            this.state.FinYearGridData, this.state.DeletedFinYearData,
                            this.state.UserProjectsData, this.state.DeletedUserProjectsData,
                            this.props.isAddNew, () => {
                                this.setState({btn_Disabled:false})
                                document.getElementById('cancel').click()
                            })
                    }
                }
                else
                {
                    Swal.fire({
                        icon: 'info',
                        text: `Branch must be Entered...`,
                        })
                        return
                }
            }
            else
            {
                Swal.fire({
                    icon: 'info',
                    text: `Fin Year must be Entered...`,
                    })
                    return
            }
            
        }

    }
    toInputUppercase = (e) => {
        e.target.value = ("" + e.target.value).toUpperCase();
    } 
    sortingOptions = {
        columns: [{ field: 'sNo', direction: 'Ascending' }]
      };
    render() {
        if(this.grid)
        this.grid.autoFitColumns(['sNo','FinYear','StartYear','EndYear','Year_Id','Branch_Id'])
        if(this.grid1)
        this.grid1.autoFitColumns(['sNo','Name','Abbr','Branch_Id'])
        return ReactDOM.createPortal (
            <>
                <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2>Users
                    </h2>
                </div>
            </div>
        </div>
               <Row>
                    <Col lg={12} md={12}>
                        <div className="ui-tab-card">
                            <Card>
                                <Row className="row_margin_left row_margin_right  ml-4 mr-4">
                                    <Col lg={4} xl={4} md={4}>
                                        <TextField 
                                            name="UserName"
                                            id="UserName"
                                            label=" User Name"
                                            value={this.state.UserName}
                                            onInput={this.toInputUppercase}
                                            onChange={e=>this.setState({UserName:e.target.value})}
                                            fullWidth
                                        />
                                        <span className="text-danger">{this.state.UserName_Err}</span>
                                    </Col>
                                    <Col lg={4} xl={4} md={4}>
                                        <TextField 
                                            name="LoginID"
                                            id="LoginID"
                                            label="Login ID"
                                            value={this.state.LoginID}
                                            onChange={e=>this.setState({LoginID:e.target.value})}
                                            fullWidth
                                        />
                                         <span className="text-danger">{this.state.LoginID_Err}</span>
                                    </Col>
                                    <Col lg={4} xl={4} md={4}>
                                        <TextField 
                                            name="Password"
                                            type="Password"
                                            id="Password"
                                            label="Password"
                                            value={this.state.Password}
                                            onChange={e => {
                                                this.setState({ Password: e.target.value, isPasswordChanged: true })
                                            }}
                                            fullWidth
                                        />
                                         <span className="text-danger">{this.state.Password_Err}</span>
                                    </Col>
                                </Row>
                                <Row className="row_margin_top row_margin_left row_margin_right ml-4 mr-4">
                                    <Col lg={4} xl={4} md={4}>
                                        <Autocomplete
                                                id="UserType"
                                                options={ this.UserType }
                                                value={ this.state.SelectedUserType}
                                                getOptionLabel={(option) => option.title ? option.title : ""} 
                                                getOptionSelected={(option, value) => option.value === value.value}
                                                onChange={(e,value)=>{
                                                    if(value)
                                                    {
                                                        this.setState({SelectedUserType:value})
                                                    }
                                                    else
                                                    {
                                                        this.setState({SelectedUserType:null})
                                                    }
                                                }}
                                                renderInput={(params) => <TextField {...params}  label="Select UserType" />}
                                            />
                                             <span className="text-danger">{this.state.UserType_Err}</span>
                                    </Col>
                                    <Col lg={4} xl={4} md={4}>
                                        <Autocomplete
                                                id="UserGroup"
                                                options={ this.state.UserGroup }
                                                value={ this.state.SelectedUserGroup }
                                                getOptionLabel={(option) => option.Description ? option.Description : ""} 
                                                getOptionSelected={(option, value) => option.Group_Id === value.Group_Id}
                                                onChange={(e,value)=>{
                                                    if(value)
                                                    {
                                                        this.setState({SelectedUserGroup:value})
                                                    }
                                                    else
                                                    {
                                                        this.setState({SelectedUserGroup:null})
                                                    }
                                                }}
                                                renderInput={(params) => <TextField {...params}  label="Select User Group" />}
                                            />
                                             <span className="text-danger">{this.state.Group_Err}</span>
                                    </Col>
                                    
                                    <Col lg={4} xl={4} md={4}>
                                    <TextField 
                                            name="PhoneNo"
                                            type="text"
                                            id="PhoneNo"
                                            label="PhoneNo"
                                            value={this.state.PhoneNo}
                                            onChange={e => {
                                                this.setState({ PhoneNo: e.target.value })
                                            }}
                                            fullWidth
                                        />
                                    </Col>
                                </Row>
                                <Row className="row_margin_top row_margin_left row_margin_right ml-4 mr-4">
                                    
                                    <Col lg={4} xl={4} md={4}>
                                        <TextField 
                                            name="Email"
                                            type="text"
                                            id="Email"
                                            label="Email"
                                            value={this.state.Email}
                                            onChange={e => {
                                                this.setState({ Email: e.target.value })
                                            }}
                                            fullWidth
                                        />
                                    </Col>
                                    <Col lg={8} xl={8} md={8}  >
                                       <TextField 
                                            name="Address"
                                            type="text"
                                            id="Address"
                                            label="Address"
                                            value={this.state.Address}
                                            onChange={e => {
                                                this.setState({ Address: e.target.value })
                                            }}
                                            fullWidth
                                        />
                                    </Col>
                                   
                                </Row>
                                <Row className="row_margin_left row_margin_right  ml-4 mr-4 mt-4">
                                <Col lg={1} xl={1} md={1}  >
                                        <FormControlLabel
                                        control={
                                            <Checkbox
                                            checked={this.state.Active}
                                            onChange={e => this.setState({Active: e.target.checked})}
                                            name="Active"
                                            color="primary"
                                            />
                                        }
                                        // style={{marginTop:'-1%'}}
                                        label="Active"
                                        />
                                    </Col>
                                    <Col lg={3} xl={3} md={3}>
                                    <Autocomplete
                                            id="UserGroup"
                                            options={ this.state.UserGroup }
                                            value={ this.state.SelectedUserGroup }
                                            getOptionLabel={(option) => option.Description ? option.Description : ""} 
                                            getOptionSelected={(option, value) => option.Group_Id === value.Group_Id}
                                            onChange={(e,value)=>{
                                                if(value)
                                                {
                                                    this.setState({SelectedUserGroup:value})
                                                }
                                                else
                                                {
                                                    this.setState({SelectedUserGroup:null})
                                                }
                                            }}
                                            renderInput={(params) => <TextField {...params}  label="Select User Group" />}
                                        />
                                         <span className="text-danger">{this.state.Group_Err}</span>
                                </Col>
                                <Col lg={3} xl={3} md={3}>
                                <Autocomplete
                                        id="UserGroup"
                                        options={ this.state.UserGroup }
                                        value={ this.state.SelectedUserGroup }
                                        getOptionLabel={(option) => option.Description ? option.Description : ""} 
                                        getOptionSelected={(option, value) => option.Group_Id === value.Group_Id}
                                        onChange={(e,value)=>{
                                            if(value)
                                            {
                                                this.setState({SelectedUserGroup:value})
                                            }
                                            else
                                            {
                                                this.setState({SelectedUserGroup:null})
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params}  label="Select User Group" />}
                                    />
                                     <span className="text-danger">{this.state.Group_Err}</span>
                            </Col>
                                <Col  lg={3} xl={3} md={3} style={{textAlign:'right'}}>
                                    <Button type="button" className="btn btn-primary rounded-pill" onClick={this.Save_User}  disabled={this.state.btn_Disabled}> 
                                    {
                             this.state.btn_Disabled ?
                               (<><Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    /> Saving... </>) : <><Save /> Save</>
                                } 
                                    
                                    
                                     </Button>
                                     <Link   to={"/Users"} id="cancel" className="btn btn-primary rounded-pill" ><Cancel/> Close </Link>
                                    </Col>
                                    </Row>
                                <Tabs
                                    defaultActiveKey="home"
                                    transition={false}
                                    id="noanim-tab-example"
                                    className="mb-3 ml-3 mr-3 row-margin-left row-margin-right row-margin-top"
                                    onSelect={e => {
                                        this.setState({ SelectedTab: e })
                                    }}
                                >
                                    <Tab eventKey="home" title="Branches">
                                        <Col lg={12} xl={12} sm={12}>
                                        <div style={{display:"flex",width:"100%",overflow:"hidden"}}> 
                                            <div style={{flex:"1",width:"100%"}}>
                                                    <GridComponent dataSource={this.state.BranchGridData} width="100%" height="100%" actionBegin={this.beforeRowInsert1} ref={g => this.grid1 = g} allowSorting={true} allowPaging={true}  actionComplete={this.afterRowInsert1} allowFiltering={true} editSettings={{ allowEditing: false, allowDeleting: true, allowAdding: true, newRowPosition: 'Bottom' }} filterSettings={{ type: 'Menu' }} sortSettings={this.sortingOptions} toolbar={this.toolbarOptions1}>
                                                    <ColumnsDirective>
                                                            <ColumnDirective width='70' field='sNo' headerText='Sr#' Format="{0:n}" allowEditing={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                                            <ColumnDirective width='250' field='Name' headerText='Branch Name' editType='dropdownedit' validationRules={this.BranchesValidation} editTemplate={this.Name} edit={this.cbParams} textAlign='Left' />
                                                    
                                                            <ColumnDirective width='190' field='Abbr' headerText='Abbr' editTemplate={this.Abbr} />
                                                        <ColumnDirective field='Branch_Id' headerText='Branch_Id'  width='150' hideAtMedia={true} ></ColumnDirective>
                                                    </ColumnsDirective>
                                                    <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]}  />
                                                </GridComponent>
                                            </div>
                                        </div>
                                    </Col>
                                    </Tab>
                                    <Tab eventKey="FinYears" title="Fin Years">
                                        <Row className="row-margin-left row-margin-right">
                                        <Col lg={12} xl={12} sm={12}>
                                            <div style={{ display: "flex", width: "100%", overflow: "hidden" }}>
                                                <div style={{ flex: "1", width: "100%" }}>
                                                    <GridComponent dataSource={(this.state.FinYearGridData)} width="100%" height="100%" actionBegin={this.beforeRowInsert} ref={g => this.grid = g} allowSorting={true} allowPaging={true}  actionComplete={this.afterRowInsert} allowFiltering={true} editSettings={{ allowEditing: false, allowDeleting: true, allowAdding: true, newRowPosition: 'Bottom' }} filterSettings={{ type: 'Menu' }} sortSettings={this.sortingOptions} toolbar={this.toolbarOptions}>
                                                        <ColumnsDirective>
                                                            <ColumnDirective width='100' field='sNo' headerText='Sr#' Format="{0:n}" allowEditing={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                                            <ColumnDirective width='250' field='BranchName' headerText='Branch Name' editType='dropdownedit' validationRules={this.Branches_FinValidation} editTemplate={this.BranchName} edit={this.cbParams} textAlign='Left' />

                                                            <ColumnDirective width='250' field='FinYear' headerText='Financial Year' editType='dropdownedit' validationRules={this.FinYearValidation} editTemplate={this.FinYear} edit={this.cbParams} textAlign='Left' />
                                                            <ColumnDirective width='190' field='StartYear' headerText='StartYear' editTemplate={this.StartYear} />
                                                            <ColumnDirective width='190' field='EndYear' headerText='EndYear' editTemplate={this.EndYear}></ColumnDirective>
                                                            <ColumnDirective field='Year_Id' headerText='Year_Id' width='150' hideAtMedia={true} ></ColumnDirective>
                                                            <ColumnDirective field='Branch_Id' headerText='Branch_Id' width='150' hideAtMedia={true} ></ColumnDirective>
                                                        </ColumnsDirective>
                                                        <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
                                                    </GridComponent>
                                                </div>
                                            </div>
                                        </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="UserProjects" title="User Projects">
                                        <Col lg={12} xl={12} sm={12}>
                                            <div style={{ display: "flex", width: "100%", overflow: "hidden" }}>
                                                <div style={{ flex: "1", width: "100%" }}>
                                                    <GridComponent dataSource={(this.state.UserProjectsData)} width="100%" height="100%" actionBegin={this.beforeRowInsertUserProjects} ref={g => this.grid = g} allowSorting={true} actionComplete={this.afterRowInsertUserProjects} allowFiltering={true} allowPaging={true}  editSettings={{ allowEditing: false, allowDeleting: true, allowAdding: true, newRowPosition: 'Bottom' }} filterSettings={{ type: 'Menu' }} sortSettings={this.sortingOptions} toolbar={this.toolbarOptions}>
                                                        <ColumnsDirective>
                                                            <ColumnDirective width='100' field='sNo' headerText='Sr#' Format="{0:n}" allowEditing={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                                            <ColumnDirective width='250' field='BranchName' headerText='Branch Name' editType='dropdownedit' editTemplate={this.BranchNameUserProjects} edit={this.cbParams} textAlign='Left' />
                                                            <ColumnDirective width='250' field='ProjectName' headerText='Project Name' editType='dropdownedit' editTemplate={this.ProjectName} edit={this.cbParams} textAlign='Left' />
                                                            {/* <ColumnDirective width='190' field='Access' headerText='Access' editTemplate={this.AccessUserProjects} template={this.AccessUserProjects} /> */}

                                                            <ColumnDirective field='User_id' defaultValue={this.props.User_Id} hideAtMedia={true}></ColumnDirective>
                                                            <ColumnDirective field='Project_ID' hideAtMedia={true} ></ColumnDirective>
                                                            <ColumnDirective field='Branch_Id' hideAtMedia={true} ></ColumnDirective>
                                                        </ColumnsDirective>
                                                        <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
                                                    </GridComponent>
                                                </div>
                                            </div>
                                        </Col>
                                    </Tab>
                                    {/* <Tab eventKey="UserGodowns" title="User Godowns">
                                        <Col lg={12} xl={12} sm={12}>
                                            <div style={{ display: "flex", width: "100%", overflow: "hidden" }}>
                                                <div style={{ flex: "1", width: "100%" }}>
                                                    <GridComponent dataSource={(this.state.UserGodownsData)} width="100%" height="100%" actionBegin={this.beforeRowInsertUserGodowns} ref={g => this.grid = g} allowPaging={true}  allowSorting={true} actionComplete={this.afterRowInsertUserGodowns} allowFiltering={true} editSettings={{ allowEditing: false, allowDeleting: true, allowAdding: true, newRowPosition: 'Bottom' }} filterSettings={{ type: 'Menu' }} sortSettings={this.sortingOptions} toolbar={this.toolbarOptions}>
                                                    <ColumnsDirective>
                                                            <ColumnDirective width='100' field='sNo' headerText='Sr#' Format="{0:n}" allowEditing={false} isIdentity={true} textAlign='Left' isPrimaryKey={true}></ColumnDirective>
                                                            <ColumnDirective width='250' field='BranchName' headerText='Branch Name' editType='dropdownedit' editTemplate={this.BranchNameUserGodowns} edit={this.cbParams} textAlign='Left' />

                                                            <ColumnDirective width='250' field='GodownName' headerText='Godown Name' editType='dropdownedit' editTemplate={this.GodownName} edit={this.cbParams} textAlign='Left' />
                                                            <ColumnDirective width='190' field='Access' headerText='Access' template={this.AccessUserGodowns} editTemplate={this.AccessUserGodowns} />

                                                            <ColumnDirective field='User_id' defaultValue={this.props.User_Id} hideAtMedia={true}></ColumnDirective>
                                                            <ColumnDirective field='God_ID' hideAtMedia={true} ></ColumnDirective>
                                                            <ColumnDirective field='Branch_Id' hideAtMedia={true} ></ColumnDirective>
                                                    </ColumnsDirective>
                                                        <Inject services={[Filter, Page, Edit, Sort, ForeignKey, Toolbar]} />
                                                </GridComponent>
                                            </div>
                                        </div>
                                        </Col>
                                    </Tab> */}
                                </Tabs>
                                <Row className="row-margin-top "></Row>
                                <Row className="row-margin-top "></Row>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </>, document.getElementById('PageContents')
        )
    }
}

 const mapStateToProps = state =>({
    CompID:  state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    // Gl_year:state.Global_Variables.GLYear && state.Global_Variables.GLYear.fin_year,
    // User: state.UserLogin.loginData,
    User_Id: state.User && state.User.User_Id,
    //  Admin_User_Id: state.UserLogin.loginData && state.UserLogin.loginData.User_id,
    //  Admin_Group_Id: state.UserLogin.loginData && state.UserLogin.loginData.Group_Id,
    isAddNew: state.User && state.User.isAddNew
  })
export default connect(mapStateToProps)(AddUser)
