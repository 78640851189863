import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../Services/Common'
import "../Delivery/DeliveryReports.css"
import { SelectrptSaleInvoiceMaster,SelectSaleInvoiceDetail} from '../../Services/SaleInvoiceAPI'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import BusinessIcon from '@mui/icons-material/Business';
import img from '../Images/AlQuraishLogo.jpeg'
import SaleInvA4UAE from './SaleInvA4UAE';
function setPageSize(cssPageSize) {
    const style = document.createElement('style');
    style.innerHTML = `@media print {
        .pagebreak {clear: both;
            page-break-after: always;}
        .pagebreak:last-child {
                page-break-after: auto;
           }
    }
    `;
    style.id = 'page-orientation';
    document.head.appendChild(style);
}

function SaleInvA4({Comp_Name,Comp_Id,User_Name,User_Id,Comp_Phone,Comp_Address, ProjectData}) {

    var { SaleInvoiceID, InvoiceType } = useParams();
    const [InvoiceMainData, setInvoiceMainData] = useState([])
    const [ItemData, setItemData] = useState([]);
    useEffect(() => {
        
        document.title = " Distribution / Sale Invoice"
        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('footer').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;
        setPageSize('landscape')
        var InvoiceID = localStorage.getItem("SaleInvID")
        if(SaleInvoiceID == "0" && InvoiceID == null){
            Select_Invoices_List()
        }else{
            var mInvId ;
            if(SaleInvoiceID == '0'){
                mInvId = InvoiceID
            }
            else{
                mInvId = SaleInvoiceID
            }
            SelectrptSaleInvoiceMaster(mInvId,InvoiceType,(MasterData)=>{
                if(MasterData.length > 0){
                    var mdis1 = 0, mdis2 = 0
                    SelectSaleInvoiceDetail(MasterData[0].Comp_Id, MasterData[0].ProjectID, mInvId,InvoiceType,(mDetailData) => {
                        
                        mDetailData.map(value=>{
                            mdis1 += value.DisAmt
                            mdis2 += value.DisAmt1
                        })
                        MasterData[0].LessDis1 = mdis1
                        MasterData[0].LessDis2 = mdis2
                        MasterData[0].TotalItems = mDetailData.length 
                        MasterData[0].AdjCrNote = 0
                        setItemData(mDetailData)
                    })

                    setInvoiceMainData(MasterData)
                }
            })
        }
        localStorage.removeItem("token")
    }, [])

    const Select_Invoices_List = async ()=>{
        const _Invoice_data = JSON.parse(localStorage.getItem("DeliveryDetailData"))
        if(_Invoice_data){
                var array = [];
                var _mData = [...InvoiceMainData];
                for (let j = 0; j < _Invoice_data.length; j++) {
                    await new Promise(resolve => {
                        SelectrptSaleInvoiceMaster(_Invoice_data[j].SaleInvoiceID,InvoiceType, (VMainData) => {
                            SelectSaleInvoiceDetail(VMainData[0].Comp_Id, VMainData[0].ProjectID,_Invoice_data[j].SaleInvoiceID,InvoiceType, async (vDetailData) => {
                                var mdis1 = 0, mdis2 = 0
                                for (let i = 0; i < vDetailData.length; i++) {
                                    array.push(vDetailData[i])
                                    mdis1 += vDetailData[i].DisAmt
                                    mdis2 += vDetailData[i].DisAmt1
                                }
                                VMainData[0].LessDis1 = mdis1
                                VMainData[0].LessDis2 = mdis2
                                VMainData[0].TotalItems = vDetailData.length;
                                VMainData[0].AdjCrNote=0
                                _mData.push(VMainData[0])
                                resolve()
                            })
    
                        })
                    })
            }

        }
        setItemData(ItemData.concat(array))
        setInvoiceMainData(_mData)
    }
    return (
           
        <>
            {   Comp_Id !== 6 ?
                InvoiceMainData.map((mValue, mIndex) => {
                    var liscenseNo = ''
                    if(mValue.Lis9 === 1 ){
                        liscenseNo = mValue.Lis9No
                    }
                    else if(mValue.Lis10 === 1){
                        liscenseNo = mValue.Lis10No
                    }
                    else if(mValue.Lis11 === 1){
                        liscenseNo = mValue.Lis11No
                    }
                    return (
                      <div className="pagebreak" style={{backgroundColor:'white', color: 'black', margin: "16px"}}>
                        <div style={{display: 'flex',  justifyContent:'right'}}>
                        <button style={{ margin: '20px'}} className="btn btn-primary rounded-pill hide-on-print" onClick={() => window.print()}>Print</button>

                        </div>
                            {/* <div  > */}
                                { InvoiceType === 'SaleInvoice' && <Row sm={12} md={12} xl={12} >
                                <Col sm={4} md={4} xl={4} className="inline">
                                <div style={{ textAlign: 'start', color: "black" }}>
                                {Comp_Id === 5 ? 
                                <img src={img} width='150' height={150} style={{borderRadius:'10%', marginLeft:'20px'}} alt='NO LOGO' />
                            : ''
                            }
                                </div>
                              
                                 </Col>
                                <Col sm={8} md={8} xl={8}  className='d-flex justify-content-start pl-5'  >
                              <div style={{ textAlign: 'center', color: "black" }}>
                                <h3>{ProjectData.ProjectName}</h3>
                                <h6> {Comp_Id === 5 ? '' : 'Medicine Distributor' } </h6>
                                <h6><BusinessIcon className='mr-2' />{ProjectData.Address}</h6>
                                <h6><CallIcon  className='mr-2' /> {ProjectData.PhoneNo} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                               {Comp_Id === 5 ?<><WhatsAppIcon  className='mr-2' /> 03336207308</>  : ''}
                                </h6> 
                                </div>
                              
                                </Col>
                               </Row>}
                               <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                                   
                                    <Col sm={12} md={12} xl={12} style={{ borderTop: '1px solid black', marginBottom: '1%' }}> </Col>
                                   
                                    <Col sm={6} md={6} xl={6} style={{ textAlign: 'left', }} >
                                        <Row>
                                            <Col lg={3} md={3} xl={3} style={{ textAlign: 'right', }}>
                                                <b>Customer :</b>
                                            </Col>
                                            <Col lg={6} md={6} xl={6} style={{ textAlign: 'left', }}>
                                                {mValue.CustomerCode} ~ {mValue.CustomerName}
                                            </Col>
                                            <Col lg={3} md={3} xl={3} ></Col>
                                        </Row>
                                    </Col>
    
                                    <Col sm={6} md={6} xl={6} style={{ textAlign: 'right', }} >
                                        <Row>
                                            <Col lg={6} md={6} xl={6} >
                                                <b>Invoice No:</b>
                                            </Col>
                                            <Col lg={6} md={6} xl={6} style={{ textAlign: 'left', }}>
                                                {mValue.SaleInvoiceNo}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={6} md={6} xl={6} style={{ textAlign: 'left', }} >
                                        <Row>
                                            <Col lg={3} md={3} xl={3} style={{ textAlign: 'right', }}>
                                                <b>Address:</b>
                                            </Col>
                                            <Col lg={6} md={6} xl={6} style={{ textAlign: 'left', }}>
                                                {mValue.Address}
                                            </Col>
                                            <Col lg={3} md={3} xl={3} ></Col>
                                        </Row>
                                    </Col>
                                    <Col sm={6} md={6} xl={6} style={{ textAlign: 'right', }} >
                                       {InvoiceType === 'SaleInvoice' &&  <Row>
                                            <Col lg={6} md={6} xl={6} >
                                                <b>Delivery No:</b>
                                            </Col>
                                            <Col lg={6} md={6} xl={6} style={{ textAlign: 'left', }}>
                                                {mValue.DeliveryCode}
                                            </Col>
                                        </Row>}
                                    </Col>
                                    <Col sm={6} md={6} xl={6} style={{ textAlign: 'left', }} >
                                        <Row>
                                            <Col lg={3} md={3} xl={3} style={{ textAlign: 'right', }}>
                                                <b>Registration:</b>
                                            </Col>
                                            <Col lg={6} md={6} xl={6} style={{ textAlign: 'left', }}>
                                                {mValue.SaleTaxType}
                                            </Col>
                                            <Col lg={3} md={3} xl={3} ></Col>
                                        </Row>
                                    </Col>
                                    <Col sm={6} md={6} xl={6} style={{ textAlign: 'right', }} >
                                        <Row>
                                            <Col lg={6} md={6} xl={6} >
                                                <b>Invoice Date:</b>
                                            </Col>
                                            <Col lg={6} md={6} xl={6} style={{ textAlign: 'left', }}>
                                                {mValue.InvoiceDate}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={6} md={6} xl={6} style={{ textAlign: 'left', }} >
                                        <Row>
                                            <Col lg={3} md={3} xl={3} style={{ textAlign: 'right', }}>
                                                <b>CNIC:</b>
                                            </Col>
                                            <Col lg={6} md={6} xl={6} style={{ textAlign: 'left', }}>
                                                {mValue.CNIC} ({mValue.AdvanceTaxType}) 
                                            </Col>
                                            <Col lg={3} md={3} xl={3} ></Col>
                                        </Row>
                                    </Col>
                                    <Col sm={6} md={6} xl={6} style={{ textAlign: 'right', }} >
                                        <Row>
                                            <Col lg={6} md={6} xl={6} >
                                                <b>Sales Man:</b>
                                            </Col>
                                            <Col lg={6} md={6} xl={6} style={{ textAlign: 'left', }}>
                                                {mValue.User_name}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={4} md={4} xl={4} style={{ textAlign: 'left', }} >
                                        <Row>
                                            <Col lg={4} md={4} xl={4} style={{ textAlign: 'right', left: '4%' }}>
                                                <b>License No:</b>
                                            </Col>
                                            <Col lg={6} md={6} xl={6} style={{ textAlign: 'left', }}>
                                                {liscenseNo}
                                            </Col>
                                            {/* <Col lg={3} md={3} xl={3} ></Col> */}
                                        </Row>
                                    </Col>
                                  
                                    <Col sm={3} md={3} xl={3} style={{ textAlign: 'center', }} >
                                        <Row>
                                            <Col >
                                                <h4><u>{InvoiceType}</u></h4>
                                                <h6>Normal</h6>
                                            </Col>
    
                                        </Row>
                                    </Col>
                                    <Col sm={4} md={4} xl={4} style={{ textAlign: 'right', }} >
                                        <Row>
                                            <Col lg={6} md={6} xl={6} >
                                                <b>Customer Class:</b>
                                            </Col>
                                            <Col lg={6} md={6} xl={6} style={{ textAlign: 'left', }}>
                                            {mValue.CustomerClass}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div id='table_div' className="row-margin-top" >
                                    <table id='report_table' className='report_table' width="100%">
                                        <thead id="thead" style={{ color: "black" }}>
                                            <tr style={{ border: "none" }}>
                                                <th style={{ width: '15%', textAlign: 'left', fontWeight: "bold" }} >Product Name</th>
                                                <th id='date_td' style={{ width: '5%', textAlign: 'center', fontWeight: "bold" }}   >Qty</th>
                                                <th style={{ width: '5%', textAlign:  'center', fontWeight: "bold" }} >Bonus</th>
                                                <th style={{ width: '5%', textAlign: 'center', fontWeight:   "bold" }} >Packing</th>
                                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Batch No</th>
                                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Exp Date</th>
                                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Price</th>
                                                <th style={{ width: '5%', textAlign: 'center', fontWeight: "bold" }} >Dis1%</th>
                                                <th style={{ width: '5%', textAlign: 'center', fontWeight: "bold"}} >Dis2%</th>
                                                <th style={{ width: '5%', textAlign: 'center', fontWeight: "bold"}} >Gst%</th>
                                                <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Value</th>
                                            </tr>
                                        </thead>
    
                                        <tbody
                                            style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                                            {
                                                ItemData.map((Itemdata, index) => {
                                                    if(mValue.SaleInvoiceID === Itemdata.SaleInvoiceID){
                                                        return (
                                                            <>
                                                                {
                                                                    Itemdata.title === true ?
                                                                        <tr key={index} style={{ border: '2px Solid Black', borderLeft: 'none', borderRight: 'none', borderBottom: 'none' }}>
                                                                            <td colSpan={7} style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px', fontWeight: 'bold' }}  >{Itemdata.Manufacture}</td>
                                                                        </tr>
                                                                        :
                                                                        Itemdata.SaleInvoiceID === "Total" ?
                                                                            <tr key={index} style={{ border: '2px Solid Black', borderLeft: 'none', borderRight: 'none' }}>
                                                                                <td colSpan={6} style={{ width: '10%', textAlign: 'right', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px', paddingRight: '10px', fontWeight: 'bold', borderRight: '2px solid black' }}  >Company Total :</td>
                                                                                <td colSpan={1} style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px', fontWeight: 'bold' }}  > {Itemdata.VALUE} </td>
                                                                            </tr>
                                                                            :
                                                                            <tr key={index} >
                                                                               
                                                                                <td style={{ width: '15%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.ItemName}</td> {/* product name */}
                                                                                <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{Itemdata.Qty}</td> {/* qty */}
                                                                                <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}   >{Itemdata.BonusQty}</td>
                                                                                <td style={{ width: '15%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Remarks}</td> {/* PAcking */}
                                                                                <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.BatchNo} </td> {/* batch no */}
                                                                                <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Expairy_Date}</td> {/* ExpairyDate */}
                                                                                <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.SaleRate}</td>
                                                                                <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.DisPer}</td>
                                                                                <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.DisPer1}</td>
                                                                                <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.TaxPer}</td>
                                                                                <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.Value} </td>
                                                                            </tr>
                                                                }
                                                            </>
        
                                                        )
                                                    }
                                                }
                                                )
                                            }
                                            <tr style={{ borderTop: '2px solid black' }}>
                                           
                                            <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                            <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >Gross Value:</td>
                                            <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >{mValue.InvoiceAmount}</td>  
                                            
                                            </tr>
                                             <tr>
                                            <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                            <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >Adjust Cr Note:</td>
                                            <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >{mValue.AdjCrNote}</td>
                                            </tr>
                                            <tr>
                                            <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                            <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >Less Dis 1:</td>
                                            <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >{mValue.LessDis1}</td>
                                            </tr>
                                            <tr>
                                            <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                            <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >Less Dis 2:</td>
                                            <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >{mValue.LessDis2}</td>
                                            </tr> 
                                            <tr>
                                            <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                            <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >Add Gst:</td>
                                            <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >{mValue.TotalGST}</td>
                                            </tr>
                                            <tr>
                                            <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                            <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >Add Further GST Tax:</td>
                                            <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >{mValue.IncomeTaxAmt}</td>
                                            </tr>
                                            <tr>
                                            <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                            <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >Advance Tax U/s 236H:</td>
                                            <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >{mValue.AddTaxAmt}</td>
                                            </tr>
    
                                            
                                            <tr>
                                            <td colSpan="6" style={{ fontWeight: 'bold', textAlign: 'right', }}  ></td>
                                            <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >Invoice Payable:</td>
                                            <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  >{mValue.GrandTotal}</td>
                                            </tr>
                                          
                                          
                                        
                                           { InvoiceType==='SaleInvoice' &&  <>  <tr style={{ borderBottom: '2px solid black' }}>
                                            <td colSpan="4" style={{ fontWeight: 'bold', textAlign: 'left', }}  ></td>
                                                <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'left', }}  ></td>
                                                
                                                <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'right' }}  ></td>
                                            </tr>
                                            <tr style={{ borderBottom: '2px solid black' }}>
                                                <td colSpan="4" style={{ fontWeight: 'bold', textAlign: 'center', }} >
                                                    <b>FORM 2 "A" WARRANTY</b>
                                                   {
    
                                                    Comp_Id === 2 ? 
                                        
                                                        <>
                                                  
                                                        <p style={{ alignContent: 'justify' }}>Warranty under section 23(1)(i) of the Drug Act 1976, I M Zafar iqbal beign a person resident in Pakistan carrying on business at T.D.A Colony Layyah under the name of Muslim Traders and being an authorised,do hereby give this Warranty that the drugs sold by me do not contravene in anyway the provisions of section 23 of drug Act 1976. </p>
                                                           <p style={{ alignContent: 'justify' }}><b>Note:</b> This warranty is not valid for any Neutraceuticals, Cosmaceuticals,  Alternative Medicines, Herbal Products mentioned in this invoice.</p>
                                                           </>
                                                   
                                                 
                                                    :  Comp_Id === 1 ?
                                                    <>
                                                  
                                                    <p style={{ alignContent: 'justify' }}>Warranty under section 23(1)(i) of the Drug Act 1976, I M Zafar iqbal beign a person resident in Pakistan carrying on business at T.D.A Colony Layyah under the name of Muslim Traders and being an authorised,do hereby give this Warranty that the drugs sold by me do not contravene in anyway the provisions of section 23 of drug Act 1976. </p>
                                                       <p style={{ alignContent: 'justify' }}><b>Note:</b> This warranty is not valid for any Neutraceuticals, Cosmaceuticals,  Alternative Medicines, Herbal Products mentioned in this invoice.</p>
                                                       </>
                                                    :  Comp_Id === 5 || ProjectData.Project_ID === 6 ? 
                                                    <>
                                                    <p style={{ alignContent: 'justify' }}>Warranty under section 23(1)(i) of the Drug Act 1976, I Sheraz Shabbir beign a person resident in Pakistan carrying on business at West Bank Canal Road Layyah under the name of Al-Quraish Pharma Distributor and being an authorised,do hereby give this Warranty that the drugs sold by me do not contravene in anyway the provisions of section 23 of drug Act 1976. </p>
                                                    <p style={{ alignContent: 'justify' }}><b>Note:</b> This warranty is not valid for any Neutraceuticals, Cosmaceuticals,  Alternative Medicines, Herbal Products mentioned in this invoice.</p>
                                                    </>
                                                    : Comp_Id === 5 || ProjectData.Project_ID === 8 ?  
                                                    <>
                                                    <p style={{ alignContent: 'justify' }}>Warranty under section 23(1)(i) of the Drug Act 1976, I Muhammad Ahmad beign a person resident in Pakistan carrying on business at Girls Collage Road Back side of Muncipal statdium  under the name of Al-Quraish Pharma Distributor and being an authorised,do hereby give this Warranty that the drugs sold by me do not contravene in anyway the provisions of section 23 of drug Act 1976. </p>
                                                    <p style={{ alignContent: 'justify' }}><b>Note:</b> This warranty is not valid for any Neutraceuticals, Cosmaceuticals,  Alternative Medicines, Herbal Products mentioned in this invoice.</p>
                                                    </>
                                                    : 'NO WARRANTY'
                                                   } 
                                                    </td>
                                               
                                               { Comp_Id === 5  ? 
                                                <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'center', }} >
                                                    <label style={{ borderTop: '2px solid black', width: '80%', marginTop: '80%' }}>For Al-Quraish Pharma Distributor</label>
                                                    </td> : <td colSpan="2" style={{ fontWeight: 'bold', textAlign: 'center', }} >
                                                    <label style={{ borderTop: '2px solid black', width: '80%', marginTop: '80%' }}>For Muslim Trader</label>
                                                    </td>
                                                } 
                                                   
                                                <td colSpan="4" style={{ fontWeight: 'bold', textAlign: 'right', }}  >
    
                                                    <p><b>- نوٹ </b></p>
                                                    <p style={{ alignContent: 'justify' }}>
                                                        کمپنی سیل مین اور آپ کے ذاتی لین دین کی ذمہ دار نہ ہوگی،مال تسلی سے وصول کر لیں بعد میں کمپنی کسی لیکج بریکج اور کمی بیشی کی ذمہ دار نہ ہو گی ،ادویات کی میعاد ختم ہونے سے چھ ماہ پہلے بذریعہ بل اطلاع دیں ورنہ کلیم قابل قبول نہ ہوگا - شکریہ</p>
                                                </td>
                                            </tr> </>}
                                        </tbody>
                                    </table>
    
                                </div>
                                
                            {/* </div> */}
                              <Row className="divFooter" style={{ marginTop: "3%" }} >
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
                                
                            </Row> 
                         </div>  
                    )
                })
                : <SaleInvA4UAE SaleInvoiceID={SaleInvoiceID} InvoiceType={InvoiceType} />
            }
           
        </>
       
       
        
      
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    Comp_Phone:state.GlobalVariables &&  state.GlobalVariables.Company.PhoneNo,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ProjectData: state.Global_Projects && state.Global_Projects.GlobalProjects
})

export default connect(mapStateToProps)(SaleInvA4)
