import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit } from '@material-ui/icons'
import BlockIcon from '@mui/icons-material/Block';
import {TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import  { connect } from 'react-redux'
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";


import  { SelectDelivery, UpdateDeliveryBlock  } from '../../Services/DeliveryAPI'
import  { set_Delivery, SHOW_SCREEN } from '../../React_Redux/Actions'
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import Loader from '../Loader';
import { IconButton } from '@mui/material';
import { msg } from '../../Services/Common';

function DeliveryList(props) {

    const {  set_Delivery,month ,Comp_Id,ProjectID, SHOW_SCREEN, Group_Id } = props;
    
    // const { set_Delivery,ItemCat  } = props;
    const [Delivery_Data, setDelivery_Data] = useState([])
     const [SelectedMonth, setSelectedMonth] = useState(null)
    const [btn_add, setBtn_add] = useState(true)
    const [btn_delete, setBtn_delete] = useState(true)
    const [IsLoader, setIsLoader] = useState(false)
    const [Selected_Year, setSelected_Year] = useState(null)
    const _Years= [
      {value:'2022',title:'2022'},
      {value:'2023',title:'2023'},
      {value:'2024',title:'2024'},
    ]
    useEffect(()=>{
      document.title = " Distribution / Delivery"
      var _mdate = new Date()
    var cYear = _mdate.getFullYear()
    var mData = _Years.find(x => x.value == cYear)
    setSelected_Year(mData)
      SelectGroupScreenAccess(Group_Id,17,142,(data)=>{
            
        setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
        
        setBtn_delete(parseInt(data.Del) === 0 ? false : true)
  
        SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
        parseInt(data.Edit) === 0 ? false : true,
        parseInt(data.Del) === 0 ? false : true,
        data.OtherAccess
        )
        
    })
    // Select_itemmaintypeList()

        if(month)
        {
          Select_Delivery(month.value,cYear)
          setSelectedMonth(month)
            set_Delivery(null,0,false)
        }
        else
        {
          var _Date = new Date()
          setSelectedMonth(ComboMonths.find(x => x.value === (_Date.getMonth())+1))
          Select_Delivery((_Date.getMonth())+1, cYear)
        }
    },[])

    const column_Delivery = [
        {
         name: "DeliveryCode",
         label: "Delivery Code",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },  
          
         }
        },
        {
         name: "DeliveryName",
         label: "Delivery Name",
         options: {
          filter: true,
          sort: true,
        customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           }, 
          }
        },
        {
         name: "DeliveryDate",
         label: "Delivery Date",
         options: {
          filter: true,
          sort: true,
          customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
            )
           },
         }
        },
        {
            name: "User_name",
            label: "Sales Man Name",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
               return(
                 <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
               )
              },
            }
           },
           {
            name: "action",
            label: "Block Delivery",
            options: {
             filter: true,
             sort: true,
             customHeadLabelRender: (columnMeta)=>{
              return(
                <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
              )
             },
             customBodyRender: (dataIndex, rowIndex) => {
               
               var DeliveryID = Delivery_Data
               if (DeliveryID[rowIndex.rowIndex] != null)
                 return (
                <IconButton onClick={()=>Block_Handler(Delivery_Data[rowIndex.rowIndex].DeliveryID,Delivery_Data[rowIndex.rowIndex].IsBlock ? 0 : 1 )}>
                   <BlockIcon 
                   sx={{color:Delivery_Data[rowIndex.rowIndex].IsBlock ? 'red' :  'green'}}
                    />
                  </IconButton>
                 );
             }
            }
           },
         {
          name: "action",
          label: "action",
          options: {
           filter: true,
           sort: true,
           customHeadLabelRender: (columnMeta)=>{
            return(
              <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
            )
           },
           customBodyRender: (dataIndex, rowIndex) => {
             
             var DeliveryID = Delivery_Data
             if (DeliveryID[rowIndex.rowIndex] != null)
               return (
              <IconButton disabled={Delivery_Data[rowIndex.rowIndex].IsBlock}>
                  <Link to={"/DeliveryEntry"} onClick={e => edit_Delivery(Delivery_Data[rowIndex.rowIndex].DeliveryID)}> <Edit  /></Link>
              </IconButton>
               );
           }
          }
         }
    ];
    const Block_Handler = (DeliveryID, IsBlock)=>{
         UpdateDeliveryBlock(DeliveryID, IsBlock, (res)=>{
          if(IsBlock){
            msg(`Delivery Block Successfully`)
          }else{
            msg(`Delivery UnBlock Successfully`)
           
          }
          
        })
        var _mdate = new Date()
        var cYear = _mdate.getFullYear()
        var _Date = new Date()
        Select_Delivery((_Date.getMonth())+1, cYear)
    }
    const edit_Delivery = (DeliveryID) =>{
      
        set_Delivery(SelectedMonth,DeliveryID,false)
    }
    const add_Delivery = () =>{
        set_Delivery(SelectedMonth,0,true)
    }
  
   const Select_Delivery = (month,Year) =>{
    showLoader()
    SelectDelivery(Comp_Id,ProjectID,month,Year,(mData)=>{
      hideLoader()
      setDelivery_Data(mData)
    })
   }
   const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });
  const showLoader = () => {
    setIsLoader(true)
   
  }
const  hideLoader = () => {
  setIsLoader(false)
  }
   const ComboMonths = [
    {value: 1, title: "JANUARY"},
    {value:2 , title: "FEBRUARY"}, 
    {value:3 , title: "MARCH"},
    {value:4 , title: "APRIL"},
    {value: 5, title: "MAY"},
    {value: 6, title: "JUNE"}, 
    {value: 7, title: "JULY"}, 
    {value: 8, title: "AUGUST"},
    {value: 9, title: "SEPTEMBER"},
    {value: 10, title: "OCTOBER"},
    {value: 11, title: "NOVEMBER"}, 
    {value: 12, title: "DECEMBER"} 
  ]
    return ReactDOM.createPortal(
      <>
        <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2>Delivery
                    </h2>
                </div>
            </div>
        </div>
      <Card>
        
          <Row className="row_margin_top row_margin_left row_margin_right pt-4 pl-4 pr-4" >
          <Col sm={1} lg={1} md={1} >
          <Autocomplete
              id="Year"
              value={Selected_Year && Selected_Year}
              options={_Years}
              getOptionLabel={(option) => option.title}
              getOptionSelected={(option, value) => { return option.value === value.value }}
              onChange={(e, value) => {

                  if (value) {
                      setSelected_Year(value)
                       Select_Delivery(SelectedMonth.value,value.value)
                  }
                  else {
                    setSelected_Year(null)
                      setDelivery_Data([])
                  }
              }}
              renderOption={(option) => (
                  <Typography style={{ fontSize: "12px" }}>{option.title}</Typography>
              )}
              renderInput={(params) => <TextField {...params} label="Select Year" />}
          />
      </Col>
            <Col sm={2} lg={2} md={2} >
                <Autocomplete
                    id="Months"
                    value={SelectedMonth && SelectedMonth}
                    options={ComboMonths}
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option, value) => { return option.value === value.value }}
                    onChange={(e, value) => {

                        if (value) {
                            setSelectedMonth(value)
                             Select_Delivery(value.value,Selected_Year.value)
                        }
                        else {
                            setSelectedMonth(null)
                            setDelivery_Data([])
                        }
                    }}
                    renderOption={(option) => (
                        <Typography style={{ fontSize: "12px" }}>{option.title}</Typography>
                    )}
                    renderInput={(params) => <TextField {...params} label="Select Month" />}
                />
            </Col>

            <Col lg={9} xl={9} md={9} style={{ textAlign: "right" }} >
              { btn_add && (<Link type="button" className="btn btn-primary rounded-pill" to="/DeliveryEntry" onClick={add_Delivery} style={{ border: "none" }} > <Add />  Create New</Link>)}
            </Col>
          </Row>
          <Row className="row_margin_left row_margin_right" >
              <Col>              
                <div className="table-responsive" >
                <MuiThemeProvider theme={theme}>
                    <MUIDataTable
                        name = "IPD_Datatables"
                        data={Delivery_Data}
                        columns={column_Delivery}
                        options={{
                        selectableRows: 'none',
                        print: false,
                        search: true,
                        download: false,
                        viewColumns: false,
                        filter: false,
                        }}
                    />
                     </MuiThemeProvider>
                </div>
              </Col>
          </Row>
          <Loader
        show={IsLoader}
        hide={hideLoader}
        />
      </Card>
                        
      </>  , document.getElementById('PageContents')
    )
}

const mapStateToProps = state=>({
  Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  month: state.Delivery && state.Delivery.Delivery_data,
  Group_Id : state.Login_User.User_Data.Group_Id,
  ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID
})
 export default connect(mapStateToProps,{set_Delivery, SHOW_SCREEN})(DeliveryList)
