import React, { useEffect, useState } from 'react'
import { Modal, Card, Row, Col } from "react-bootstrap"
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Loop, Cancel } from "@material-ui/icons"
import { connect } from 'react-redux'
import MUIDataTable from "mui-datatables";
import { SelectItemsWRTPO } from '../../Services/PurchaseInvoiceAPI' 
import { SelectPOWRTINV } from '../../Services/PurchaseOrderAPI'
import { Autocomplete } from '@material-ui/lab';
import { MultiColumnComboBox } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { SelectPurchaseOrderDetail } from '../../Services/PurchaseOrderAPI'

function PurchaseOrderModal({show, onHide, mSaveStock,SupplierID, Comp_Id, projectID}) {

    const [PO_Data, setPO_Data] = useState([])
    const [PO_WRT_Inv_Data, setPO_WRT_Inv_Data] = useState([])
    const [Selected_PO_WRT_Inv, setSelected_PO_WRT_Inv] = useState(null)
    const [filter, setFilter] = useState(null)
    
    const [LoadAlll, setLoadAlll] = useState(false)
    useEffect(()=>{
        // SelectItemsWRTMinStock(Comp_Id,ProjectID,(mStockData)=>{
        //     mStockData.map(value=>{
        //         value.chkForSelect = false
        //     })
        //     setPO_Data(mStockData)
        // })

if(SupplierID)
{
  SelectPOWRTINV(Comp_Id, projectID, SupplierID,(data)=>{
    setPO_WRT_Inv_Data(data)
  })
}

    },[show, SupplierID])

    const column_PO = [
        {
            name: "Select",
            label: "Select",
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.name}</div>
                    )
                },
                customBodyRender: (dataIndex, rowIndex) => {
                    var dopt_id = PO_Data
                    if (dopt_id[rowIndex.rowIndex] != null)
                        return (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={PO_Data[rowIndex.rowIndex].chkForSelect}
                                            id={`chk${rowIndex.rowIndex}`}
                                            onChange={e => {
                                                let newPO_Data = [...PO_Data]
                                                newPO_Data[rowIndex.rowIndex].chkForSelect = e.target.checked 
                                               setPO_Data(newPO_Data)
                                            }}

                                            // name="Access"
                                            color="primary"
                                        />
                                    }
                                    label=""
                                    style={{
                                        padding: "0px",
                                    }}
                                />
                            </>
                        );
                }
            }
        },
        {
          name: "ItemID",
          label: "ItemID",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
          name: "ItemDiscription",
          label: "ItemDiscription",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
            name: "UOM",
            label: "UOM",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
            }
          },
      
        {
          name: "PurchaseRate",
          label: "PurchaseRate",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
            name: "Qty",
            label: "Qty",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
            }
          },
    ];
    const save_Handler = ()=>{
      var  mArray = []
        for(let i=0; i<PO_Data.length; i++ )
        {
            if(PO_Data[i].chkForSelect)
            {
                PO_Data[i].Qty = PO_Data[i].Qty
                PO_Data[i].PurchaseRate =  PO_Data[i].PurchaseRate
                PO_Data[i].DisPer = 0 
                PO_Data[i].DisAmt = 0 
                PO_Data[i].TaxPer = 0 
                PO_Data[i].TaxAmt = 0 
                PO_Data[i].Value = PO_Data[i].PurchaseRate
                PO_Data[i].Value = parseFloat(PO_Data[i].Qty) * parseFloat(PO_Data[i].PurchaseRate)
                PO_Data[i].PurchaseOrderDetailID = Selected_PO_WRT_Inv.PurchaseOrderID
                PO_Data[i].action = 'add'
                mArray.push(PO_Data[i])
            }
            
        }
        if(mArray.length > 0)
        mSaveStock(mArray)
        else
        onHide()

    }
    const handleFilterChange = event => {
      if (event) {
        setFilter(event.filter);
      }
    };

    const columnsGLYear = [
      {
        field: "RemainingInv",
        header: "Invoice No",
        width: "250px",
      },
      {
        field: "Qty",
        header: "Qty",
        width: "150px",
      },
      {
        field: "GrandTotal",
        header: "Total",
        width: "150px",
      },
    ];


    return (
        <>
        <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" >
            <Modal.Header closeButton className="header_title" >
            <Modal.Title> Purchase Order Items </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <Card>
                    <Row className=" row_margin_left row_margin_right" >
                    <Col sm={12} lg={4} xl={4} md={4} >
                    <Autocomplete
                            name="WareHouse"
                            id="WareHouse"
                            options={PO_WRT_Inv_Data}
                            getOptionLabel={(option) => option.RemainingInv ? option.RemainingInv : ""}
                            value={Selected_PO_WRT_Inv}
                            onChange={(e, value) => {
                                
                              if (value) {
                                setSelected_PO_WRT_Inv(value)
                                SelectPurchaseOrderDetail(Comp_Id, projectID,value.PurchaseOrderID,(data) =>{
                                  setPO_Data(data)
                                })
                              }
                              else {
                                setSelected_PO_WRT_Inv(null)
                                setPO_Data([])
                              }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="WareHouse" label="Select Invoice No"
                                />}
                        />
                    {/* <MultiColumnComboBox
                      data={filter ? filterBy(PO_WRT_Inv_Data, filter) : PO_WRT_Inv_Data}
                      value={Selected_PO_WRT_Inv}
                      style={{ marginTop: "5%", width: "100%", fontSize: "12px" }}
                      columns={columnsGLYear}
                      textField={"RemainingInv"}
                      filterable={true}
                      onFilterChange={handleFilterChange}
                      onChange={e => {

                        if (e.value) {
                          setSelected_PO_WRT_Inv(e.value)
                          SelectPurchaseOrderDetail(Comp_Id, projectID,e.value.PurchaseOrderID,(data) =>{
                            setPO_Data(data)
                          })
                        }
                        else {
                          setSelected_PO_WRT_Inv(null)
                          setPO_Data(null)
                        }
                      }}
                    // fullWidth
                    /> */}
                    </Col>
                        </Row>
                        <Row className=" row_margin_left row_margin_right" >
                            <Col>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={LoadAlll}
                                            id="LoadAlll"
                                            label="LoadAlll"
                                            onChange={e => {
                                                setLoadAlll(e.target.checked)
                                                PO_Data.map(value=>{
                                                    if(e.target.checked)
                                                    value.chkForSelect = true
                                                    else
                                                    value.chkForSelect = false
                                                })
                                            }}

                                            // name="Access"
                                            color="primary"
                                        />
                                    }
                                    label="Select All"
                                    style={{
                                        padding: "0px",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className=" row_margin_left row_margin_right" >
                            <Col>
                                <div className="table-responsive" >
                                    <MUIDataTable
                                        name="PO_Datatables"
                                        data={PO_Data}
                                        columns={column_PO}
                                        options={{
                                            selectableRows: 'none',
                                            print: false,
                                            search: false,
                                            download: false,
                                            viewColumns: false,
                                            filter: false,
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                <button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={save_Handler} > <Loop /> <u>Load</u></button>
                                <button type="button" className="btn btn-primary rounded-pill" onClick={onHide} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
        </Modal>
        </>
    )
}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address
  })
export default connect(mapStateToProps)(PurchaseOrderModal)
