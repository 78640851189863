import { domain } from "../Services/Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const CompanywiseTaxes =(Comp_Id,ProjectID,ManufacturedByID,FromDate,ToDate,Callback) =>{
    axios.get(`${domain}/TaxReports/CompanywiseTaxes/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }

  export const CustomerwiseTaxes =(Comp_Id, ProjectID, ManufacturedByID,CityID, CustomerID, FromDate, ToDate,Callback) =>{
    axios.get(`${domain}/TaxReports/CustomerwiseTaxes/${Comp_Id}/${ProjectID}/${ManufacturedByID}/${CityID}/${CustomerID}/${FromDate}/${ToDate}`)
         .then(response=>{
             Callback(response.data.Data)
         })
         .catch(err =>{
          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `${err}`,
            })
       })
  }