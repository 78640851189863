import React,{ useEffect,useState } from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import {GetCurrentDate ,ReverseDate,GetCurrentTimeAM_PM} from '../../../Services/Common'
import "../SaleReports/Table.css"
import { rptNearExpiryStock } from '../../../Services/Distribution_ReportAPI'
import { SelectUserProjects } from '../../../Services/Projects'
import { SelectManufacturedByList  } from '../../../Services/manufactureAPI'

function NearExpiryStockReport({Comp_Name,Comp_Id,User_Name,User_Id,ConfigObj}) {

    const { ProjectID,ManufactureByID,FromDate,ToDate } = useParams();

    const [ItemData, setItemData] = useState([]);
    const [GrandTotal, setGrandTotal] = useState(0);
    const [SelectedProject, setSelectedProject] = useState(null)
    const [Manufacturer,setManufacturer] = useState('')

    useEffect(() => {
        document.title = "Distribution / Near Expiry Stock" 

        document.getElementById('topBar').hidden = true;
        document.getElementById('leftside').hidden = true;
        document.getElementById('footer').hidden = true;
        document.getElementById('content_Page').hidden = true;
        document.getElementById('RSidebar').hidden = true;

        rptNearExpiryStock(Comp_Id,ProjectID,ManufactureByID,FromDate,ToDate,(mData)=>{
            if(mData.length > 0){

                var mArray = [], mSubTotal = 0,mGrandTotal = 0,mcount = 0;
                mData.map((value, index) =>{

                    mGrandTotal +=  value.VALUE

                    if(mData[index - 1]){
                        if(value.ManufacturedBy !== mData[index - 1].ManufacturedBy){
                            mArray.push({total: (mSubTotal).toFixed(2), title: 'CompanyTotal'})
                            mArray.push({ManufacturedBy: value.ManufacturedBy, title: true})
                            mSubTotal = 0
                        }
                        else{
                            mSubTotal += value.VALUE
                        }
                    }
                    else {
                        mArray.push({ManufacturedBy: value.ManufacturedBy, title: true})
                        mSubTotal += value.VALUE
                    }
                    value.PurchaseRate = (value.PurchaseRate).toFixed(2)
                    value.VALUE = (value.VALUE).toFixed(2)
                    mArray.push(value)
                })

                mArray.map(value=>{
                    if(value.title){
                        mcount ++;
                    }
                })

                if(mcount > 2 || mcount === 1){  //for last company
                    mArray.push({total: (mSubTotal).toFixed(2), title: 'CompanyTotal'})
                }

                setItemData(mArray)
                
                setGrandTotal((mGrandTotal).toFixed(2))
            }
        
        })
     
        if(ManufactureByID == "0"){
            setManufacturer('ALL COMPANIES')
        }
        else{
            SelectManufacturedByList(Comp_Id,ProjectID,(data) =>{
                data.map(mvalue=>{
                    if(mvalue.ManufacturedByID == ManufactureByID ){
                        setManufacturer(mvalue.ManufacturedBy)
                    }
                })
            })

        }
        
        SelectUserProjects(Comp_Id,User_Id,(mProjects)=>{
        mProjects.map(value=>{
            if(value.Project_ID == ProjectID){
                setSelectedProject(value)
            }
        })
        })
    }, [])
    return (
        <>
        
        <div id="main_div_report" >
            <Row  style={{ textAlign: 'center', color: "black" }}>
            <Col sm={3} md={3} xl={3}><img src={ConfigObj.Logo} width="100" height="100" /></Col>
            <Col sm={6} md={6} xl={6}>
                    <h2> {Comp_Name} </h2> <br /> <h3>Near Expiry Stock</h3>
                </Col>
               </Row>
                <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Project:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{SelectedProject && SelectedProject.ProjectName}</b>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>Company:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{Manufacturer}</b>
                        </Col>
                    </Row>
                </Col>
            </Row>
            
            <Row sm={12} md={12} xl={12} style={{ textAlign: 'center', color: "black" }}>
            <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>From Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{FromDate}</b>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} md={6} xl={6} style={{ textAlign: 'left',borderLeft:'2px solid black',height:'20px',left:'10%', }} >
                    <Row>
                        <Col lg={3} md={3} xl={3} >
                         <b>To Date:</b>
                        </Col>
                        <Col lg={9} md={9} xl={9} >
                        <b>{ToDate}</b>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div id='table_div' className="row-margin-top" >
                <table id='report_table' className='report_table' width="100%">
                    <thead id="thead" style={{ color: "black" }}>
                        <tr style={{ border: "none" }}>
                            <th id='date_td' style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Qty</th>
                            <th style={{ width: '20%', textAlign: 'left', fontWeight: "bold" }} >Product Name</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Remarks</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >T.P</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Batch No</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >Exp Date</th>
                            <th style={{ width: '10%', textAlign: 'center', fontWeight: "bold" }} >VALUE</th>
                        </tr>
                    </thead>

                    <tbody
                        style={{ border: "#FFFFFF", borderCollapse: "collapse" }}>
                         
                        {
                            ItemData.map((Itemdata, index) => {
                            return(
                                                               
                                <>
                                {
                                    Itemdata.title === true ? 
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none',borderBottom:'none'}}>
                                        <td colSpan={7} style={{ width: '10%', borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  >{Itemdata.ManufacturedBy}</td>
                                       </tr>
                                    :
                                    Itemdata.title === "CompanyTotal" ? 
                                    <tr key={index} style={{border:'2px Solid Black',borderLeft:'none',borderRight:'none'}}>
                                        <td colSpan={6} style={{ width: '10%', textAlign: 'right', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',paddingRight:'15px',fontWeight:'bold',borderRight:'2px solid black' }}  >Company Total :</td>
                                        <td colSpan={1} style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px',fontWeight:'bold' }}  > {Itemdata.total} </td>
                                    </tr>
                                    :
                                    <tr key={index} >
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Qty}</td>
                                        <td style={{ width: '20%', textAlign: 'left', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}    >{Itemdata.ItemDiscription}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.Remarks}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.PurchaseRate} </td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.BatchNo}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  >{Itemdata.ExpairyDate}</td>
                                        <td style={{ width: '10%', textAlign: 'center', border: "#FFFFFF", borderCollapse: "collapse", verticalAlign: 'top', paddingBottom: '5px', paddingTop: '5px' }}  > {Itemdata.VALUE} </td>
                                    </tr>
                                }
                                </>
                               
                            )}
                            )
                        }
                        <tr id='footer_balance'>
                            <td id='footer_td_cb' colSpan="5" style={{ textAlign: 'right', fontWeight: 'bold', paddingTop: '15px', paddingBottom: '15px' }}  ></td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}  >Grand Total :</td>
                            <td id='footer_td' colSpan="1" style={{ fontWeight: 'bold', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px' }}  >{GrandTotal}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div className='page-break-ledger'>
              
            </div>
        </div>
        <Row className="divFooter" style={{ marginTop: "3%" }} >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Username: {User_Name}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date: {`${GetCurrentDate()} ${GetCurrentTimeAM_PM()}`}
        </Row>
       
    </>
    )
}
const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address,
    User_Id: state.Login_User && state.Login_User.User_Data.User_id,
    User_Name: state.Login_User && state.Login_User.User_Data.User_name,
    ConfigObj:state.Global_Configs && state.Global_Configs.GlobalConfig
})

export default connect(mapStateToProps)(NearExpiryStockReport)
