import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import "./InvoicePrint.css";

function setPageSize(cssPageSize) {
  const style = document.createElement("style");
  style.innerHTML = `@page {size: ${cssPageSize}}`;
  style.id = "page-orientation";
  document.head.appendChild(style);
}

function PurchaseOrderReport({ CompName, ConfigObj }) {
  const { Comp_Id, InvID } = useParams();
  const [InvoiceTableData, setInvoiceTableData] = useState([]);
  const [Inv, setInv] = useState("");
  const [InvDate, setInvDate] = useState("");
  const [Supplier, setSupplier] = useState("");
  const [Godown, setGodown] = useState("");
  const [PaymentType, setPaymentType] = useState("");
  const [BankName, setBankName] = useState("");

  const [from1Date, setFrom1Date] = useState("");
  const [from2Date, setFrom2Date] = useState("");
  const [from3Date, setFrom3Date] = useState("");

  const [to1Date, setTo1Date] = useState("");
  const [to2Date, setTo2Date] = useState("");
  const [to3Date, setTo3Date] = useState("");

  var [TotalQty, setTotalQty] = useState(0.0);
  var [TotalNetAmt, setTotalNetAmt] = useState(0.0);

  var TotalTax = 0;
  var TotalDiscount = 0;

  useEffect(() => {
    document.title = "Distribution / Purchase Order Report";
    document.getElementById("topBar").hidden = true;
    document.getElementById("leftside").hidden = true;
    document.getElementById("footer").hidden = true;
    document.getElementById("content_Page").hidden = true;
    document.getElementById("RSidebar").hidden = true;

    var _Data = JSON.parse(localStorage.getItem("PurchaseOrder"));

    setInv(_Data.PurchaseOrderNo);
    setInvDate(_Data.PurchaseOrderDate);
    setSupplier(_Data.Supplier.SupplierName);
    setGodown(_Data.Warehouse.WareHouse);
    setInvoiceTableData(_Data.Detail);
    setFrom1Date(_Data.from1Date);
    setFrom2Date(_Data.from2Date);
    setFrom3Date(_Data.from3Date);

    setTo1Date(_Data.to1Date);
    setTo2Date(_Data.to2Date);
    setTo3Date(_Data.to3Date);
  }, []);

  return (
    <div className="size" style={{ color: "black", backgroundColor: "#fff" }}>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <button
          style={{ margin: "20px" }}
          className="btn btn-primary rounded-pill hide-on-print"
          onClick={() => window.print()}
        >
          Print
        </button>
      </div>
      <Row className="between-rows-margin between-left-margin between-right-margin">
        <Col sm={3} md={3} xl={3}>
          <img src={ConfigObj.Logo} width="100" height="100" />
        </Col>
        <Col sm={6} md={6} xl={6} style={{ textAlign: "center" }}>
          <h3 style={{ textAlign: "center" }}> {CompName} </h3> <br />{" "}
          <h4>Purchase Order</h4>
        </Col>
      </Row>

      <Row className="between-rows-margin between-left-margin between-right-margin">
        <Col lg={8} md={8}>
          <Row lg={12} md={12} xl={12}>
            <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
              Inv.#
            </Col>
            <Col sm={9} md={9} xl={9}>
              {Inv}
            </Col>
          </Row>

          <Row lg={12} md={12} xl={12}>
            <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
              Sales Date:
            </Col>
            <Col sm={9} md={9} xl={9}>
                1st Month: From {from1Date} to {to1Date} <br />
                2nd Month: From {from2Date} to {to2Date} <br />
                3rd Month: From {from3Date} to {to3Date}
            </Col>
          </Row>
        </Col>

        <Col lg={4} md={4}>
          <Row lg={12} md={12} xl={12}>
            <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
              Supplier:
            </Col>
            <Col sm={9} md={9} xl={9}>
              {Supplier}
            </Col>
            <Col sm={3} md={3} xl={3} style={{ fontWeight: "bold" }}>
              Godown:
            </Col>
            <Col sm={9} md={9} xl={9}>
              {Godown}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="between-rows-margin between-left-margin between-right-margin">
        <Col>
        <span> This is an Order for the listed below Products.Please Deliver this Order as soon as possible.</span>
          <div id="table_sec_invprt">
            <table id="main_table_invoicepr" style={{ marginBottom: "20px" }}>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}> Sr # </th>
                  <th style={{ textAlign: "left" }}> Item Description </th>
                  <th style={{ textAlign: "left" }}> Packing </th>
                  <th style={{ textAlign: "right" }}> Rate </th>
                  <th style={{ textAlign: "right" }}> 1st Sale </th>
                  <th style={{ textAlign: "right" }}> 2nd Sale </th>
                  <th style={{ textAlign: "right" }}> 3rd Sale </th>
                  <th style={{ textAlign: "right" }}> Net Sale </th>
                  <th style={{ textAlign: "right" }}> Req Qty </th>
                  <th style={{ textAlign: "right" }}> Cls Qty </th>
                  <th style={{ textAlign: "center" }}> Order Qty </th>
                  <th style={{ textAlign: "center" }}> UOM </th>
                 
                  <th style={{ textAlign: "right" }}> Discount </th>
                  <th style={{ textAlign: "right" }}> Tax </th>
                  <th style={{ textAlign: "right" }}> Amount </th>
                </tr>
              </thead>

              <tbody>
                {InvoiceTableData.map((invoicetabledata, index) => {
                  TotalQty += parseFloat(invoicetabledata.Qty);
                  TotalNetAmt += parseFloat(invoicetabledata.Value);
                  TotalTax += parseFloat(invoicetabledata.TaxAmt);
                  TotalDiscount += parseFloat(invoicetabledata.DisAmt);

                  return (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}> {index + 1} </td>
                      <td style={{ textAlign: "left" }}>
                        {" "}
                        {invoicetabledata.ItemDiscription}{" "}
                      </td>
                      <td style={{ textAlign: "left" }}>
                        {" "}
                        {invoicetabledata.packSize}{" "}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {" "}
                        {invoicetabledata.PurchaseRate}{" "}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {" "}
                        {invoicetabledata.fSale}{" "}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {" "}
                        {invoicetabledata.sSale}{" "}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {" "}
                        {invoicetabledata.tSale}{" "}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {" "}
                        {invoicetabledata.NETSale}{" "}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {" "}
                        {invoicetabledata.ReqQty}{" "}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {" "}
                        {invoicetabledata.ClosingQTy}{" "}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {" "}
                        {invoicetabledata.Qty}{" "}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {" "}
                        {invoicetabledata.UOM}{" "}
                      </td>
                     
                      <td style={{ textAlign: "right" }}>
                        {" "}
                        {invoicetabledata.DisAmt}{" "}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {" "}
                        {invoicetabledata.TaxAmt}{" "}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {" "}
                        {invoicetabledata.Value}{" "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td style={{ textAlign: "right" }} colSpan="9">
                    {" "}
                    Total:
                  </td>
                  <td style={{ textAlign: "center" }}>{TotalQty}</td>
                  <td style={{ textAlign: "right" }}></td>
                  <td style={{ textAlign: "right" }}></td>
                  <td style={{ textAlign: "right" }}>
                    {parseFloat(TotalDiscount).toFixed(2)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {parseFloat(TotalTax).toFixed(2)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {parseFloat(TotalNetAmt).toFixed(2)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  CompName: state.GlobalVariables.Company.Name,
  ConfigObj: state.Global_Configs && state.Global_Configs.GlobalConfig,
});

export default connect(mapStateToProps)(PurchaseOrderReport);

// export default InvoicePrint
