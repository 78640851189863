import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Row, Col, Card } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import { Save, Cancel } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { Selectbank } from "../../Services/bankAPI";
import { connect } from "react-redux";
import { SaveBankOpening, getSingleBankOpening } from "../../Services/OpeningAPI";

const BankOpeningBalance = (props) => {
  const { Comp_Id, ProjectID, isAddNew, BOID } = props;
  const [bankList, setbankList] = useState([]);
  const [selectedBank, setselectedBank] = useState(null);
  const [address, setAddress] = useState("");
  const [debitBalance, setdebitBalance] = useState(0);
  const [creditBalance, setcreditBalance] = useState(0);
  const [err, setErr] = useState({
    bankErr: false,
    creditErr: false,
    debitErr: false,
  });
  useEffect(() => {

    Selectbank(Comp_Id, ProjectID, (banks) => {
      setbankList(banks);
      if(isAddNew){

      }else{
        getSingleBankOpening(BOID, (mData)=>{
          const selectedBank = banks.find(x=> x.BankID === mData[0].BankID)
          setselectedBank(selectedBank)
          setAddress(mData[0].Address)
          setdebitBalance(mData[0].Debit)
          setcreditBalance(mData[0].Credit)
        })
      }
    });
  }, [Comp_Id, ProjectID]);

  const validate = () => {
    let isValid = true;
    if (!selectedBank) {
      setErr({ ...err, bankErr: true });
      isValid = false;
      return isValid;
    }
    if (debitBalance < 0) {
      setErr({ ...err, debitErr: true });
      isValid = false;
      return isValid;
    }
    if (creditBalance < 0) {
      setErr({ ...err, creditErr: true });
      isValid = false;
      return isValid;
    }
    setErr({
      bankErr: false,
      creditErr: false,
      debitErr: false,
    });
    return isValid;
  };

  const SaveHandler = () => {
    const isValid = validate();
    if (isValid) {
      const BankOpening ={
        BOID: BOID,
        BankID: selectedBank?.BankID,
        Address: address,
        Debit: debitBalance,
        Credit: creditBalance,
        Comp_Id: Comp_Id,
        ProjectID: ProjectID,
      } 
      SaveBankOpening(BankOpening, isAddNew, (res)=>{
        document.getElementById("btnCancel").click();
      })
    }
  };
  return ReactDOM.createPortal(
    <>
      <Row className="row_margin_top row_margin_left row_margin_right">
        <Col lg={6} xl={6} md={6} className="header_title">
          <h2>Bank Opening Balance</h2>
        </Col>
      </Row>

      <Card>
        <Row className="row_margin_top row_margin_left row_margin_right m-2 g-3">
          <Col sm={12} lg={6} xl={6} md={6}>
            <Autocomplete
              name="bank"
              id="bank"
              options={bankList}
              value={selectedBank}
              getOptionLabel={(option) => option.BankName ?? ""}
              onChange={(e, value) => {
                if (value) {
                  setselectedBank(value);
                  setErr({ ...err, bankErr: false });
                } else {
                  setselectedBank(null);
                  setErr({ ...err, bankErr: true });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="Bank"
                  label="Bank"
                  onError={err.bankErr}
                  required
                  helperText={
                    err.bankErr && (
                      <span className="text-danger">Bank is required</span>
                    )
                  }
                />
              )}
            />
          </Col>
          <Col sm={12} lg={6} xl={6} md={6}>
            <TextField
              name="Address"
              label="Address"
              type="text"
              fullWidth
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Col>
          <Col sm={12} lg={6} xl={6} md={6}>
            <TextField
              name="DebitBalance"
              label="Debit Balance"
              type="number"
              value={debitBalance}
              onChange={(e) => {
                setErr({ ...err, debitErr: false });
                setdebitBalance(e.target.value);
              }}
              fullWidth
              required
              onError={err.debitErr}
              helperText={
                err.debitErr && (
                  <span className="text-danger">Debit Balance is required</span>
                )
              }
            />
          </Col>
          <Col sm={12} lg={6} xl={6} md={6}>
            <TextField
              name="CredtBalance"
              label="Credt Balance"
              type="number"
              required
              fullWidth
              value={creditBalance}
              onChange={(e) => {
                setErr({ ...err, creditErr: false });
                setcreditBalance(e.target.value);
              }}
              onError={err.creditErr}
              helperText={
                err.creditErr && (
                  <span className="text-danger">
                    Credit Balance is required
                  </span>
                )
              }
            />
          </Col>
        </Row>
        <Row className=" row_margin_top row_margin_left row_margin_right">
          <Col style={{ textAlign: "right", marginTop: "1%" }}>
            <button
              type="button"
              className="btn btn-primary rounded-pill"
              style={{ border: "none" }}
              onClick={SaveHandler}
            >
              {" "}
              <Save /> <u>Save</u>
            </button>
            <Link
              id="btnCancel"
              className="btn btn-primary rounded-pill"
              style={{ border: "none" }}
              to={"/bank-opening"}
            >
              {" "}
              <Cancel /> <u>Close</u>
            </Link>
          </Col>
        </Row>
      </Card>
    </>,
    document.getElementById("PageContents")
  );
};
const mapStateToProps = (state) => ({
  Comp_Id:state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  p_userid: state.Login_User.User_Data && state.Login_User.User_Data.User_id,
  ProjectID : state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,
  isAddNew: state.BankOpening && state.BankOpening.isAddNew,
  BOID: state.BankOpening && state.BankOpening.OPening_ID,
});

export default connect(mapStateToProps)(BankOpeningBalance);
