import { domain } from "./Common"
import axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

export const Login = (username, password, props, callback) =>{
    axios.post(`${domain}/Login/Login/${username}/${password}`)
         .then(response =>{
             callback(response.data.token)
         }).catch(err=>{
            return err
         })
}
export const VerifySoftwareExpairy = (Comp_Id, ProjectID, callback) =>{
    axios.post(`${domain}/Login/VerifySoftwareExpairy/${Comp_Id}/${ProjectID}`)
    .then(response =>{
        callback(response.data)
    })
    .catch(err=>{
        toast.configure()
            toast.error(err, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
    })
 }
 export const GetSoftwareInvoice = (Comp_Id, ProjectID, callback) =>{
    axios.get(`${domain}/Login/GetSoftwareInvoice/${Comp_Id}/${ProjectID}`)
    .then(response =>{
        callback(response.data.Data)
    })
    .catch(err=>{
        callback(err.response.data);
    })
 }
export const LoginVerifyToken = (token,props, cb) =>{
    
    axios.post(`${domain}/Login/LoginVerifyToken`,null,{headers:{'Authorization': `bearer ${token}`}})
         .then(response =>{
            toast.configure();
             if(response.data.status)
             {
                //  props.history.push('/AdminDashboard');
                // // <Redirect to='/ChartOfAccount' />
                // document.getElementById("sidebar").hidden = false;
                // document.getElementById("navbar").hidden = false;
                // document.getElementById('page_content').hidden = false;
                // document.getElementById('page_wrapper').hidden = false;

                cb(response)
             }
             else
             {
                toast.info('Incorrect Username or Password', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
             }
             cb(response)
         })
         .catch(err=> console.error(err))
}