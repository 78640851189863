import React, { useEffect, useState } from 'react'
import { Modal, Card, Row, Col } from "react-bootstrap"
import { Checkbox, TextField,FormControlLabel } from '@material-ui/core'
import { Loop, Cancel } from "@material-ui/icons"
import { connect } from 'react-redux'
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Autocomplete } from '@material-ui/lab';

import {SelectItemsAgainstBooking } from '../../Services/SaleInvoiceAPI'
import { SelectBookingAgainstCustomer } from '../../Services/OrderBookingAPI'



function OrderBookingModal({show, onHide, mSaveStock,customer, Comp_Id, projectID, isCancel, setIsCancel}) {
    const [BO_Data, setBO_Data] = useState([])
    const [SelectedBooking, setSelectedBooking] = useState(null)
    const [BookingList, setBookingList] = useState([])
    
    const [LoadAlll, setLoadAlll] = useState(false)
    useEffect(()=>{
        if(show){
            setSelectedBooking(null)
            setBO_Data([])
            setLoadAlll(false)
            SelectBookingAgainstCustomer(customer.CustomerID,(mData)=>{
                setBookingList(mData)
            })
        }

      if(isCancel)
      {
        setBO_Data([])
        setIsCancel()
      }

    },[show])

    const column_OB = [
        {
            name: "Select",
            label: "Select",
            options: {
                filter: false,
                sort: false,
                customHeadLabelRender: (columnMeta) => {
                    return (
                        <div style={{ fontSize: "12px" }} >{columnMeta.name}</div>
                    )
                },
                customBodyRender: (dataIndex, rowIndex) => {
                    var dopt_id = BO_Data
                    if (dopt_id[rowIndex.rowIndex] != null)
                        return (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={BO_Data[rowIndex.rowIndex].chkForSelect}
                                            id={`chk${rowIndex.rowIndex}`}
                                            onChange={e => {
                                                let newBO_Data = [...BO_Data]
                                                newBO_Data[rowIndex.rowIndex].chkForSelect = e.target.checked 
                                               setBO_Data(newBO_Data)
                                            }}

                                            // name="Access"
                                            color="primary"
                                        />
                                    }
                                    label=""
                                    style={{
                                        padding: "0px",
                                    }}
                                />
                            </>
                        );
                }
            }
        },
        {
          name: "ItemID",
          label: "ItemID",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
          name: "ItemDiscription",
          label: "Item Description",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
            name: "UOM",
            label: "UOM",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
            }
          },
      
        {
          name: "SaleRate",
          label: "SaleRate",
          options: {
            filter: true,
            sort: true,
            customHeadLabelRender: (columnMeta) => {
              return (
                <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
              )
            },
          }
        },
        {
            name: "Qty",
            label: "Qty",
            options: {
              filter: true,
              sort: true,
              customHeadLabelRender: (columnMeta) => {
                return (
                  <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
                )
              },
            }
          },
    ];
    const theme = createTheme({
        overrides: {
          MuiTableCell: {
            root: {
              paddingTop: 0,
              paddingBottom: 0,
            }
          }
        }
      });
    const save_Handler = ()=>{
      var  mArray = []
        for(let i=0; i<BO_Data.length; i++ )
        {
            if(BO_Data[i].chkForSelect)
            {
                BO_Data[i].action = 'add'
                mArray.push(BO_Data[i])
            }
            
        }
        if(mArray.length > 0){
            mSaveStock(mArray)
            setSelectedBooking(null)
            setBookingList([])
            setBO_Data([])
            setLoadAlll(false)
        }
        else{
            onHide()
        }
    }

    return (
        <>
        <Modal show={show} size="lg" centered onHide={onHide} backdrop="static" >
            <Modal.Header closeButton className="header_title" >
            <Modal.Title> Order Booking / {customer && customer.CustomerName} </Modal.Title>
            </Modal.Header>
                <Modal.Body>
                    <Card>
                    <Row className=" row_margin_left row_margin_right" >
                    <Col sm={12} lg={8} xl={8} md={12} >
                    <Autocomplete
                            name="OrderBooking"
                            id="OrderBooking"
                            options={BookingList}
                            getOptionLabel={(option) => option.BookingDesc ? option.BookingDesc : ""}
                            value={SelectedBooking}
                            onChange={(e, value) => {
                                if (value)
                                {
                                    setSelectedBooking(value)
                                    SelectItemsAgainstBooking(Comp_Id,projectID,value.OBID,(mData)=>{
                                        mData.map((value,index)=>{
                                            value.SrNo = index + 1;
                                            value.chkForSelect = false;
                                        })
                                        setBO_Data(mData)
                                    })
                                }
                                else
                                {
                                    setSelectedBooking(null)
                                    setBO_Data([])
                                }
                            }}
                            renderInput={(params) =>
                                <TextField {...params} name="OrderBooking" label="Order"
                                />}
                        />
                    </Col>
                        </Row>
                        <Row className=" row_margin_left row_margin_right" >
                            <Col>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={LoadAlll}
                                            id="LoadAlll"
                                            label="LoadAlll"
                                            onChange={e => {
                                                setLoadAlll(e.target.checked)
                                                BO_Data.map(value=>{
                                                    if(e.target.checked)
                                                    value.chkForSelect = true
                                                    else
                                                    value.chkForSelect = false
                                                })
                                            }}

                                            // name="Access"
                                            color="primary"
                                        />
                                    }
                                    label="Select All"
                                    style={{
                                        padding: "0px",
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className=" row_margin_left row_margin_right" >
                            <Col>
                                <div className="table-responsive" >
                                <MuiThemeProvider theme={theme}>
                                    <MUIDataTable
                                        name="BO_Datatables"
                                        data={BO_Data}
                                        columns={column_OB}
                                        options={{
                                            selectableRows: 'none',
                                            print: false,
                                            search: false,
                                            download: false,
                                            viewColumns: false,
                                            filter: false,
                                        }}
                                    />
                                    </MuiThemeProvider>
                                </div>
                            </Col>
                        </Row>
                        <Row className=" row_margin_top row_margin_left row_margin_right" >
                            <Col style={{ textAlign: "right", marginTop: "1%" }} >
                                <button type="button" className="btn btn-primary rounded-pill" style={{ border: "none" }} onClick={save_Handler} > <Loop /> <u>Load</u></button>
                                <button type="button" className="btn btn-primary rounded-pill" onClick={onHide} style={{ border: "none" }} > <Cancel /> <u>Cancel</u></button>
                            </Col>
                        </Row>
                    </Card>
                </Modal.Body>
        </Modal>
        </>
    )
}

const mapStateToProps = state=>({
    Comp_Id : state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
    Comp_Name:state.GlobalVariables && state.GlobalVariables.Company.Name,
    Comp_Address:state.GlobalVariables &&  state.GlobalVariables.Company.Address
  })
export default connect(mapStateToProps)(OrderBookingModal)
