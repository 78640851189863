import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Row, Col, Card } from 'react-bootstrap'
import { Add, Edit } from '@material-ui/icons'
import { connect } from 'react-redux'

import { SelectStockAdjustmentList } from '../../Services/StockAdjustmentAPI'
import { set_StockAdjustment, SHOW_SCREEN } from '../../React_Redux/Actions'
import { SelectGroupScreenAccess } from '../../Services/SecurityAPI'
import Loader from '../Loader';
function StockAdjustmentList(props) {

  const { set_StockAdjustment, ItemCat, Comp_Id, ProjectID, SHOW_SCREEN, Group_Id } = props;
  const [StockAdjustmentList_Data, setStockAdjustmentList_Data] = useState([])
  const [btn_add, setBtn_add] = useState(true)
  const [btn_delete, setBtn_delete] = useState(true)
  const [IsLoader, setIsLoader] = useState(false)

  useEffect(() => {
    document.title = " Distribution / Stock Adjustment"
    SelectGroupScreenAccess(Group_Id, 11, 95, (data) => {

      setBtn_add(parseInt(data.AddNew) === 0 ? false : true)
      setBtn_delete(parseInt(data.Del) === 0 ? false : true)

      SHOW_SCREEN(parseInt(data.AddNew) === 0 ? false : true,
        parseInt(data.Edit) === 0 ? false : true,
        parseInt(data.Del) === 0 ? false : true,
        data.OtherAccess
      )

    })

    Select_StockAdjustmentList()
  }, [])

  const column_StockAdjustment = [
    {
      name: "StockAdjustmentID",
      label: "Stock Adjustment ID",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },

      }
    },
    {
      name: "AdjustmentNo",
      label: "Adjustment Code",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "AdjustmentDate",
      label: "Adjustment Date",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    // {
    //   name: "WareHouse",
    //   label: "WareHouse",
    //   options: {
    //    filter: true,
    //    sort: true,
    //    customHeadLabelRender: (columnMeta)=>{
    //      return(
    //        <div style={{fontSize: "15px", fontWeight:"Bold"}} >{columnMeta.label}</div>
    //      )
    //     },
    //   }
    //  },
    {
      name: "TotalQty",
      label: "TotalQty",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "15px", fontWeight: "Bold" }} >{columnMeta.label}</div>
          )
        },
      }
    }
    //  {
    //   name: "action",
    //   label: "action",
    //   options: {
    //    filter: true,
    //    sort: true,
    //    customHeadLabelRender: (columnMeta)=>{
    //     return(
    //       <div style={{fontSize: "15px", fontWeight:"Bold" , textAlign: "right"}} >{columnMeta.label}</div>
    //     )
    //    },
    //    customBodyRender: (dataIndex, rowIndex) => {

    //      var StockAdjustmentID = StockAdjustmentList_Data
    //      if (StockAdjustmentID[rowIndex.rowIndex] != null)
    //        return (
    //          <Link to={"/"} onClick={e => edit_StockAdjustment(StockAdjustmentList_Data[rowIndex.rowIndex].StockAdjustmentID)}> <Edit /></Link>
    //        );
    //    }
    //   }
    //  }
  ];
  // const edit_StockAdjustment = (StockAdjustmentID) =>{
  //    set_StockAdjustment(StockAdjustmentID,false)
  // }
  const add_StockAdjustment = () => {
    set_StockAdjustment(0, true)
  }

  const Select_StockAdjustmentList = () => {
    showLoader()
    SelectStockAdjustmentList(Comp_Id, ProjectID, (mData) => {
      hideLoader()
      setStockAdjustmentList_Data(mData)
    })
  }
  const showLoader = () => {
    setIsLoader(true)

  }
  const hideLoader = () => {
    setIsLoader(false)
  }
  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });
  return ReactDOM.createPortal(
    <>
      <div className="block-header">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-12">
            <h2>Stock Adjustment
            </h2>
          </div>
        </div>
      </div>
      <Card>

        <Row className="row_margin_top row_margin_left row_margin_right m-4" >
          <Col style={{ textAlign: "right" }} >
            {btn_add && (<Link type="button" className="btn btn-primary rounded-pill" to="/StockAdjustmentEntry" onClick={add_StockAdjustment} style={{ border: "none" }} > <Add />  Create New</Link>)}
          </Col>
        </Row>
        <Row className="row_margin_top row_margin_left row_margin_right" >
          <Col>
            <div className="table-responsive" >
            <MuiThemeProvider theme={theme}>
              <MUIDataTable
                data={StockAdjustmentList_Data}
                columns={column_StockAdjustment}
                options={{
                  selectableRows: 'none',
                  print: false,
                  search: true,
                  download: false,
                  viewColumns: false,
                  filter: false,
                }}
              />
              </MuiThemeProvider>
            </div>
          </Col>
        </Row>
        <Loader
          show={IsLoader}
          hide={hideLoader}
        />
      </Card>

    </>, document.getElementById('PageContents')
  )
}

const mapStateToProps = state => ({
  Comp_Id: state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  ItemCat: state.StockAdjustment && state.StockAdjustment.ItemData,
  Group_Id: state.Login_User.User_Data.Group_Id,
  ProjectID: state.Global_Projects.GlobalProjects && state.Global_Projects.GlobalProjects.Project_ID,

})
export default connect(mapStateToProps, { set_StockAdjustment, SHOW_SCREEN })(StockAdjustmentList)
