import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Row, Col, Card } from 'react-bootstrap'
import MUIDataTable from 'mui-datatables'
import { Link } from 'react-router-dom'
import { Edit, Delete, Add } from '@material-ui/icons'
import { connect } from 'react-redux'

import AddUser from './AddUser'
import { SelectUsersList, SelectGroupScreenAccess } from "../../Services/SecurityAPI"
import { setUserData } from "../../React_Redux/Actions"
import {
  createTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import Loader from '../Loader';
function Users({ CompID, setUserData, GroupID }) {

  const [UserGrid, setUserGrid] = useState([])
  const [UserModal, setUserModal] = useState(false)
  const [AddNew, setAddNew] = useState(true);
  const [IsLoader, setIsLoader] = useState(false)

  const Comp_Id = CompID && CompID.Branch_Id

  useEffect(() => {
    Select_UsersList()

    // SelectGroupScreenAccess(GroupID, 12, 42, (data) => {
    //   setAddNew(parseInt(data.AddNew) === 0 ? false : true)
    // })
  }, [])
  document.title = "Distribution / Users"

  const Select_UsersList = () => {
    showLoader()
    SelectUsersList(mUsersList=> {
      hideLoader()
      setUserGrid(mUsersList)
    })
  }
  const showLoader = () => {
    setIsLoader(true)
   
  }
const  hideLoader = () => {
  setIsLoader(false)
  }

  const columns = [
    {
      name: "User_id",
      label: "User ID",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "User_name",
      label: "User Name",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
          )
        },
      }
    },
    {
      name: "Detail",
      label: "Detail",
      options: {
        filter: true,
        sort: true,
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ fontSize: "12px" }} >{columnMeta.label}</div>
          )
        },
        customBodyRender: (dataIndex, rowIndex) => {

          const _UserData = UserGrid;

          if (_UserData[rowIndex.rowIndex] != null) {
            return (
              <>
                <Link to={"/AddUser"} name="Edit" onClick={e => edit_User(_UserData[rowIndex.rowIndex].User_id)}> <Edit /> </Link>
              </>
            )
          }
        }
      }
    },
  ];
  const edit_User = (User_Id) => {
    setUserData(User_Id, false)
  }

  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        root: {
          paddingTop: 0,
          paddingBottom: 0,
        }
      }
    }
  });

  return ReactDOM.createPortal(
    <>
       <div className="block-header">
            <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                    <h2>Users
                    </h2>
                </div>
            </div>
        </div>
      <Card>
        <Row className=" row-margin-left row-margin-top row-margin-right mt-4 mr-4 ml-4" style={{ textAlign: "right" }} >
          <Col>
            {/* commenting add new user for security purpose of USER */}
            {AddNew && (<Link to={"/AddUser"} className="btn btn-primary rounded-pill" onClick= {e=> setUserData(null,true) } > <Add/> Add New</Link>)}  
          </Col>
        </Row>
        <Row className=" row-margin-left row-margin-right" >
          <Col>
            <div className="table-responsive" >
              <MuiThemeProvider theme={theme}>
                <MUIDataTable
                  style={{ size: "200px" }}
                  data={UserGrid}
                  columns={columns}
                  options={{
                    selectableRows: false,
                    search: true,
                    download: false,
                    print: false,
                    viewColumns: false,
                    filter: false,
                    selectableRows: false,
                    selection: true,
                    toolbar: false,
                    paging: false,
                    headerStyle: {
                      fontSize: 12
                    }
                  }
                  }
                />
              </MuiThemeProvider>
            </div>
          </Col>
        </Row>
        <Loader
        show={IsLoader}
        hide={hideLoader}
        />
      </Card>

    </>, document.getElementById('PageContents')
  )
}
const mapStateToProps = state => ({
  CompID: state.GlobalVariables.Company && state.GlobalVariables.Company.Branch_Id,
  // Gl_year: state.Global_Variables && state.Global_Variables.GLYear.fin_year,
  // User: state.UserLogin && state.UserLogin.loginData,
  GroupID:  state.UserLogin && state.UserLogin.loginData.Group_Id,
})
export default connect(mapStateToProps, { setUserData })(Users)